import React, { useCallback, useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { saveAs } from "file-saver";

//Importing drag and select Module
import {
	Box,
	boxesIntersect,
	useSelectionContainer,
} from "@air/react-drag-to-select";

import Loader from "../../components/Loader";
import { imageURL } from "../../data/filepath";
import DragSelect from "dragselect";
import ImageModal from "./Modal/ImageModal";
import MyVerticallyCenteredModal from "../../components/Modal";
import RemoveModalConfirmation from "../../components/Modal/ConfirmBox";

// import uploaded from "../../assets/Images/uploaded.svg";
// import download from "../../assets/Images/download.svg";

import submitted from "../../assets/Images/checkmark-round.svg";
import uploaded from "../../assets/Images/checkmark-round-blue.svg";
import upload from "../../assets/Images/upload-round.svg";
import psd_final_upload from "../../assets/Images/psd-final-upload.svg";
import cross from "../../assets/Images/cross-circle.svg";

import psd from "../../assets/Images/psd.svg";
import { useOutsideAlerter } from "../../utils/OutSideDivDeselect/EditorOutsideSelect";

// Importing API related files and routes
import { client } from "../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../data/filepath";

import {
	EDITOR_DOWNLOAD_IMAGE,
	EDITOR_UPLOAD_IMAGE,
	EDITOR_REMOVE_PSD,
} from "../../config/apiRoutes.api";
// import { forEach } from "core-js/core/array";

const EditorOrderDetails = ({
	show,
	images,
	selectedImagesId,
	setSelectedImagesID,
	setSelectAll,
	selectAll,
	token,
	idSelected,
	selectedStaff,
	activeIndex,
	getEditorImages,
	setImageID,
	imageID,
	selectedAssigntoQCImagesId,
	setSelectedAssigntoQCImagesID,
	setReloadBatchImages,
	setClosePopup,
	reloadBatchImages,
	setResponseHeading,
	responseHeading,
	setting_profile,
}) => {
	// Create a FormData object for request payload
	const form = new FormData();
	// Create a FormData object for request payload
	const bodyFormData = new FormData();
	const request = client(token);
	const isEmpty = images.length === 0;
	const [clickSelect, setClickSelect] = useState(false);
	const [ctrlClicked, setCtrlClicked] = useState(false);
	const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
	const [currentDownloadId, setCurrentDownloadId] = useState(0);
	const [currentUploadId, setCurrentUploadId] = useState(0);

	const [dragAndSelectEnabled, setDragAndSelectEnabled] = useState(false);
	const [firstSelectedIndex, setFirstSelectedIndex] = useState(0);
	const [secondSelectedIndex, setSecondSelectedIndex] = useState(0);
	const [showModal, setShowModal] = useState(false); // Image Modal
	const [image_details_view, setImageDetailsView] = useState({});
	const [image_details_view_id, setImageDetailsViewId] = useState("");
	const dsRef = useRef(null);
	const wrapper = useRef(null);
	const [selectionBox, setSelectionBox] = useState();
	const [selectedIndexes, setSelectedIndexes] = useState([]);
	const selectableItems = useRef([]);
	const elementsContainerRef = useRef(null);
	// const [responseHeading, setResponseHeading] = useState("Error");
	const [responseMessage, setResponseMessage] = useState("Error");
	const [showUploadModal, setShowUploadModal] = useState(false); // Upload Modal
	const [removePSD, setRemovePSD] = useState(false); // Confirm Remove PSD Modal
	const [removePSDid, setRemovePSDID] = useState(""); // Image id to remove PSD

	useOutsideAlerter(
		wrapper,
		selectedImagesId,
		setClickSelect,
		setCtrlClicked,
		images,
		setSelectAll,
		setSelectedImagesID,
		setImageDetailsViewId,
		setImageDetailsView,
		setShowModal,
		setSelectedAssigntoQCImagesID,
		setSelectedIndexes
	); //Outside clickable wrapper
	/**
	 * Handles set class after select/un select of box.
	 * @param {Object} selected_items - An object array.
	 * @param {Number} id - An image id.
	 */
	const seledtedClass = (selected_items, id) => {
		return selected_items.length &&
			selected_items.find((selectedRow) => selectedRow == id)
			? "box exclude-click border-image--card layer-image--selector "
			: "box exclude-click";
	};

	/**
	 * Handles set class after select/un select of box.
	 * @param {Object} selected_items - An object array.
	 * @param {Number} id - An image id.
	 */
	const setCheckboxSelected = (selected_items, id) => {
		return selected_items.length &&
			selected_items.find((selectedRow) => selectedRow == id)
			? true
			: false;
	};

	// Define the logic  for drag and select

	const { DragSelection } = useSelectionContainer({
		eventsElement: document.getElementById("root"),
		onSelectionChange: (box) => {
			/**
			 * Here we make sure to adjust the box's left and top with the scroll position of the window
			 * @see https://github.com/AirLabsTeam/react-drag-to-select/#scrolling
			 */
			const scrollAwareBox = {
				...box,
				top: box.top + window.scrollY,
				left: box.left + window.scrollX,
			};

			setSelectionBox(scrollAwareBox);
			const indexesToSelect = [];
			selectableItems.current.forEach((item, index) => {
				if (boxesIntersect(scrollAwareBox, item)) {
					indexesToSelect.push(Number(index));
				}
			});

			setSelectedIndexes(indexesToSelect);
		},
		onSelectionStart: () => {
			console.log("OnSelectionStart");
		},
		onSelectionEnd: () => {
			console.log("OnSelectionEnd");
			const imagesIds = [];
			const folderIds = [];
			const intersected = document.querySelectorAll(".selected");
			intersected.forEach((element) => {
				console.log(element.classList);
				if (element.classList.contains("order_images")) {
					imagesIds.push(Number(element.getAttribute("id")));
				} else {
					if (
						element.classList.contains("folder") &&
						element.hasAttribute("id")
					) {
						folderIds.push(element.getAttribute("id"));
					}
				}
			});
			setSelectedImagesID(imagesIds);
			setAssgintoQCIds(imagesIds);
		},
		selectionProps: {
			style: {
				border: "1px solid #c1c1c1",
				borderRadius: 2,
				// backgroundColor: "rgba(20, 137, 189, 0.5)",
				backgroundColor: "#c1c1c1",
				opacity: 0.6,
				zIndex: 99,
			},
		},
		isEnabled: dragAndSelectEnabled,
	});

	// Define the logic to set the images in ref
	useEffect(() => {
		if (elementsContainerRef.current && images.length > 0) {
			Array.from(elementsContainerRef.current.children).forEach((item) => {
				if (item.innerHTML) {
					console.log(" item = ", item);
					const { left, top, width, height } = item.getBoundingClientRect();
					selectableItems.current.push({
						left,
						top,
						width,
						height,
					});
				}
			});
		}
	}, [images]);
	useEffect(() => {
		if (!selectedImagesId.length) {
			setDragAndSelectEnabled(true);
		}
	}, [selectedAssigntoQCImagesId]);
	// Function to handle the mousedown event
	const handleMouseDown = (event) => {
		setDragAndSelectEnabled(true);
	};
	// Function to handle the mouseup event
	const handleMouseUp = (event) => {
		setDragAndSelectEnabled(false);
	};

	const handleChangeSelected = useCallback(
		(changeEvent, item, id, index, itemList) => {
			setDragAndSelectEnabled(false);
			setCtrlClicked(false);
			setClickSelect(false);

			let itemId = [];
			const selectedItemCheck = changeEvent.currentTarget.id;
			if (changeEvent.ctrlKey || changeEvent.metaKey) {
				console.log("metaKey =>", changeEvent.metaKey);
				setSelectedIndexes([]);
				// Ctrl key is pressed
				setCtrlClicked(true);
				localStorage.setItem("ctrlClicked", true);

				const selectedItemsArray = [...selectedImagesId];
				const selectedQCmages = [...selectedAssigntoQCImagesId];
				if (selectedQCmages.indexOf(item.id) > -1) {
					setAssgintoQCIds(selectedItemsArray.filter((id) => id !== item.id));
				} else {
					setAssgintoQCIds([...selectedQCmages, id]);
				}

				if (selectedItemsArray.indexOf(item.id) > -1) {
					// Item is already selected
					setSelectedImagesID((prevSelectedItems) =>
						prevSelectedItems.filter((itemId) => itemId !== id)
					);
				} else {
					setSelectedImagesID([...selectedImagesId, id]);
				}
				// break;
			} else if (changeEvent.shiftKey) {
				setSelectedIndexes([]);
				setAssgintoQCIds([]);
				itemId.push(...selectedItemCheck);
				if (itemId.length === 0) {
					setSelectedImagesID([id]);
					setAssgintoQCIds([id]);
				}
				// Shift key is pressed
				else {
					const selectedItemsArray = Array.from(selectedImagesId);
					const selectedIndicesArray = selectedItemsArray.map((itemId) =>
						itemList.findIndex((item) => item.id === itemId)
					);

					const currentIndex = index;
					const minSelectedIndex = Math.min(...selectedIndicesArray);
					const maxSelectedIndex = Math.max(...selectedIndicesArray);

					const start = Math.min(minSelectedIndex, currentIndex);
					const end = Math.max(maxSelectedIndex, currentIndex);

					const rangeIndices = Array.from(
						{ length: end - start + 1 },
						(_, i) => start + i
					);
					const rangeIds = rangeIndices.map((idx) => itemList[idx].id);

					setSelectedImagesID(rangeIds);
					setAssgintoQCIds(rangeIds);
				}
			} else {
				// ... (other parts of the code)
				if (changeEvent.type === "click") {
					setSelectedIndexes([]);
					setSelectedImagesID([]);
					setAssgintoQCIds([]);

					// const newClickSelect = !clickSelect;
					const newClickSelect = true;
					if (
						// changeEvent.currentTarget.classList.contains("ds-selectable") ||
						!changeEvent.currentTarget.classList.contains("border-image--card")
					) {
						setSelectedImagesID([id]);
						setImageID(id);
						setAssgintoQCIds([id]);
					}
					setClickSelect(newClickSelect);
					localStorage.setItem("clickSelect", true);
				}
			}
			// }
		},
		[
			firstSelectedIndex,
			lastSelectedIndex,
			selectedImagesId,
			// handleKeyUp,
			// activeSelected,
			selectedImagesId,

			images,
			// selectedItemId,
			setClickSelect,
			clickSelect,
			wrapper,

			// ref,
		]
	);

	//Set The image details
	const handleClickImageDetails = (items, id) => {
		setImageDetailsViewId(id);
		setImageDetailsView(items);
		setShowModal(true);
		let order_images = document.querySelectorAll(".order_images");
		for (var i = 0; i < order_images.length; i++) {
			order_images[i].style.zIndex = 1;
			document.body.classList.add("modal-open");
		}
		// setImageDetailsModal(true);
	};

	/**
	 * Function to be called after download click.
	 *  * @param {Object} event -  Event Object.
	 *  * @param {Object} item -  item Object.
	 *
	 */
	const downloadDocument = (event, item) => {
		event.preventDefault();
		setCurrentDownloadId(item.id);
		let fileName = item.image_path;
		const headers = {
			Authorization: token
				? "Bearer " + `${token.slice(1, token.length - 1)}`
				: "",
		};

		form.append("editor_id", selectedStaff.value);
		form.append("image_ids", item.id);
		axios({
			url: apiURL + EDITOR_DOWNLOAD_IMAGE,
			method: "POST",
			headers: headers,
			data: form,
			responseType: "blob", // Important: Ensure the API response is Blob
		})
			.then((response) => {
				saveAs(response.data, fileName);
				setCurrentDownloadId(0);
			})
			.catch((error) => {
				setCurrentDownloadId(0);
				console.error("Error downloading file:", error);
			});
	};

	/**
	 * Handle the show the comment status icon.
	 * @param {Object} item - item Object.
	 */
	const getStatusImage = (item) => {
		return item.editor_status === "upload"
			? psd_final_upload
			: item.editor_status === "uploaded"
			? uploaded
			: submitted;
	};

	/**
	 * Handle the image upload.
	 * @param {Object} event - event Object.
	 * @param {Number} image_id - An image id.
	 */

	const uploadImage = (event, image_id) => {
		event.preventDefault();
		if (event.target.files && event.target.files.length > 0) {
			setCurrentUploadId(image_id);
			bodyFormData.append("editor_id", selectedStaff.value);
			bodyFormData.append("image_id", image_id);
			for (let i = 0; i < event.target.files.length; i++) {
				bodyFormData.append("order_images[]", event.target.files[i]);
			}
			// return false;
			request
				.post(apiURL + EDITOR_UPLOAD_IMAGE, bodyFormData)
				.then((response) => {
					//Console the  response
					console.log("response", response.data);
					setResponseMessage(response.data.message);
					setReloadBatchImages(response.data.status);
					setShowUploadModal(true);
					setResponseHeading(response.data.status ? "Success" : "Error");
					setCurrentUploadId(0);
					for (let key of bodyFormData.keys()) {
						bodyFormData.delete(key);
					}
					const fileInput = document.getElementById(`file_${image_id}`);
					if (fileInput) {
						fileInput.value = null;
					}
					//Set the success/error response
				})
				.catch((err) => {
					console.log("error response", err);
					setCurrentUploadId(0);
					setResponseHeading("Error");
					setResponseMessage("Oops! something went wrong!");
					setShowUploadModal(true);
					setReloadBatchImages(false);
					for (let key of bodyFormData.keys()) {
						bodyFormData.delete(key);
					}
					const fileInput = document.getElementById(`file_${image_id}`);
					if (fileInput) {
						fileInput.value = null;
					}
				});
		}
	};

	/**
	 * Handles to set the Assign to QC state from the object.
	 * @param {Object} object - An array.
	 */
	const setAssgintoQCIds = (object) => {
		let ids = [];
		if (object && object.length > 0) {
			object.forEach((id) => {
				let imageIndex = images.findIndex((d) => d.id == id);
				if (images[imageIndex].editor_status !== "submitted") {
					ids.push(id);
				}
			});
		}
		setSelectedAssigntoQCImagesID(ids);
	};

	/**
	 * Handles to make the http call for delete the final PSD and image.
	 * @param {Object} e - An Object.
	 */

	const handlePSDDelete = (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append("editor_id", selectedStaff.value);
		formData.append("image_id", removePSDid);
		request
			.post(apiURL + EDITOR_REMOVE_PSD, formData)
			.then((response) => {
				//Console the  response
				console.log("response", response.data);
				setRemovePSD(false);
				setResponseHeading(response.data.status ? "Success" : "Error");
				setResponseMessage(response.data.message);
				setReloadBatchImages(response.data.status);
				setShowUploadModal(true);
			})
			.catch((err) => {
				console.log("error response", err);
				setRemovePSD(false);
				setResponseHeading("Error");
				setResponseMessage("Oops! something went wrong!");
				setReloadBatchImages(false);
				setShowUploadModal(true);
			});
	};

	/**
	 * Handles to return the image name without extensions.
	 * @param {String} image - An image string.
	 */

	const removeImageExtensions = (image) => {
		let lastDotPosition = image.lastIndexOf(".");
		if (lastDotPosition === -1) {
			return image;
		} else {
			return image.substr(0, lastDotPosition);
		}
	};
	const svgCode = (count) => {
		return `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20" fill="none">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M6 4.5V0H10.5C10.8978 0 11.2794 0.158035 11.5607 0.43934C11.842 0.720644 12 1.10218 12 1.5V3H9L3 9V16H1.5C1.10218 16 0.720644 15.842 0.43934 15.5607C0.158035 15.2794 0 14.8978 0 14.5V6H4.5C4.89782 6 5.27936 5.84196 5.56066 5.56066C5.84196 5.27936 6 4.89782 6 4.5ZM5 4.5V0.25L0.25 5H4.5C4.63261 5 4.75979 4.94732 4.85355 4.85355C4.94732 4.75979 5 4.63261 5 4.5Z" fill="white"/>
		<path d="M10 4V8.5C10 8.89782 9.84196 9.27936 9.56066 9.56066C9.27936 9.84196 8.89782 10 8.5 10H4V18.5C4 18.8978 4.15804 19.2794 4.43934 19.5607C4.72064 19.842 5.10218 20 5.5 20H14.5C14.8978 20 15.2794 19.842 15.5607 19.5607C15.842 19.2794 16 18.8978 16 18.5V5.5C16 5.10218 15.842 4.72064 15.5607 4.43934C15.2794 4.15804 14.8978 4 14.5 4H10ZM9 4.25V8.5C9 8.63261 8.94732 8.75979 8.85355 8.85355C8.75979 8.94732 8.63261 9 8.5 9H4.25L9 4.25Z" fill="white"/>
		<!-- Use a <style> element to set the font size -->
			<style>
			  text {
				font-size: 9px;
				font-weight:500;
			  }
			</style>
			<!-- Adjust the y attribute to add margin on the top -->
			<text x="6" y="18" fill="green">${count}</text>
	  </svg>`;
	};
	const renderContent = useCallback(
		(item) => {
			return (
				<div key={item.id}>
					{item.duplication_settings.length !== 0 && (
						<span className="checkbox-order--duplicate">
							<div
								dangerouslySetInnerHTML={{
									__html: svgCode(
										(item.duplication_settings || []).length.toString()
									),
								}}
							/>
						</span>
					)}
				</div>
			);
		},
		[images]
	);
	// check if to show the loader
	if (show) return <Loader />;
	return (
		<>
			<DragSelection />
			<div
				// className="editororderdetails editor editororderdetails-new image noselect"
				// // ref={wrapper}
				// style={{
				// 	overflowY: "auto",
				// }}
				className="editororderdetails_editor editororderdetails-new-editor"
				style={{
					overflowY: isEmpty ? "scroll" : null,
					paddingLeft: isEmpty ? "100%" : null,
				}}
			>
				<div
					className="detailslist_editor coordibox"
					ref={elementsContainerRef}
				>
					{Array.isArray(images) &&
						images.map((items, index) => {
							return (
								<div
									// onKeyUp={(e) => handleKeyUp(e)}
									className={
										"cardsContainer order_images" +
										// (selectedImagesId.indexOf(Number(items.id)) >= 0
										(selectedIndexes.indexOf(Number(index)) >= 0
											? " selected"
											: "")
									}
									data-data="111"
									style={{ zIndex: 1 }}
									id={items.id}
									key={items.id}
									// style={{ zIndex: "unset!important" }}
								>
									<Card
										id={items.id}
										key={index}
										className={seledtedClass(selectedImagesId, items.id)}
										ref={wrapper}
										onDoubleClick={() =>
											handleClickImageDetails(items, items.id)
										}
										onClick={(event) => {
											handleChangeSelected(
												event,
												items,
												items.id,
												index,
												images
											);
											selectedImagesId.length === 0
												? setFirstSelectedIndex(items.id)
												: setSecondSelectedIndex(items.id);
										}}
									>
										<div
											style={{
												height: "auto",
												background: "#ffff",
												overflow: "hidden",
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
											}}
											id={items.id}
										>
											<img
												id={items.id}
												variant="top"
												width={200}
												height={100}
												className="image-rendering"
												loading="lazy"
												alt="Mindreach"
												// layout="fill"
												src={`${imageURL}/${items.customer.email}/${items.batch_number}/${items.order.order_number}/images/${items.image_path}`}
											/>
										</div>
										<input
											type="checkbox"
											className="checkbox-order--sub"
											value={items.id}
											id={items.id}
											checked={setCheckboxSelected(selectedImagesId, items.id)}
											key={index}
										/>
										{renderContent(items)}
										<Card.Body>
											<Card.Text>
												{removeImageExtensions(items.image_path)}
											</Card.Text>

											<div>
												<div
													style={{
														display: "flex",
														gap: "10px",
														justifyContent: "center",
													}}
												>
													<div
														className={
															currentDownloadId !== items.id ? "bothbtn" : ""
														}
														style={{
															marginRight:
																(currentDownloadId === items.id ? "7" : "0") +
																"px",
														}}
														onClick={(e) => {
															e.stopPropagation();
															downloadDocument(e, items);
														}}
													>
														{currentDownloadId !== items.id ? (
															<>
																<span>
																	<img
																		src={psd}
																		className="img-fluid"
																		alt="mindreach images"
																		style={{
																			margin: "0px 5px 0px 5px",
																			width: "17px",
																		}}
																	/>
																</span>
																{/* PSD */}
															</>
														) : (
															<span>
																<Loader
																	id={`loader_${items.id}`}
																	className={"image_loader"}
																/>
															</span>
														)}
													</div>
													{/* upload uploaded submitted*/}
													<div
														className={
															" task_status " +
															`${items.editor_status}` +
															(currentUploadId !== items.id ? " bothbtn" : "")
														}
														onClick={(e) => {
															e.stopPropagation();
															if (
																items.editor_status === "upload" ||
																items.editor_status === "uploaded"
															) {
																document
																	.getElementById(`file_${items.id}`)
																	.click();
															}
														}}
													>
														<>
															{/* Condition to check the status and show only for uploaded status */}
															{items.editor_status === "uploaded" && (
																<img
																	src={cross}
																	className="img-fluid remove_psd_image"
																	alt="mindreach images"
																	style={{
																		width: "22px",
																		top: "-14px",
																		right: "-13px",
																	}}
																	onClick={(e) => {
																		e.stopPropagation();
																		setRemovePSD(true);
																		setRemovePSDID(items.id);
																	}}
																/>
															)}
														</>
														<input
															type="file"
															style={{ display: "none" }}
															accept="image/png, image/gif, image/jpeg,.psd"
															id={`file_${items.id}`}
															multiple={true}
															onChange={(e) => {
																uploadImage(e, items.id);
															}}
														/>
														{currentUploadId !== items.id ? (
															<>
																{items.editor_status === "upload" ? (
																	<div
																		className="ml-2"
																		style={{ fontWeight: "bold" }}
																	>
																		{"PSD + Final"}
																		<span className="ml-2">
																			<img
																				src={getStatusImage(items)}
																				className="img-fluid"
																				alt="mindreach images"
																				style={{
																					width: 17,
																				}}
																			/>
																		</span>
																	</div>
																) : (
																	<>
																		<span>
																			<img
																				src={getStatusImage(items)}
																				className="img-fluid"
																				alt="mindreach images"
																				style={{
																					width: 17,
																				}}
																			/>
																		</span>
																		{items.editor_status}
																	</>
																)}
															</>
														) : (
															<span>
																<Loader
																	id={`loader_${items.id}`}
																	className={"upload_loader"}
																/>
															</span>
														)}
													</div>
												</div>
											</div>
										</Card.Body>
									</Card>
								</div>
							);
						})}

					{/*********************************Image Modal******************************/}
					<ImageModal
						setShowModal={setShowModal}
						showModal={showModal}
						image_details_view={image_details_view}
						setImageDetailsView={setImageDetailsView}
						setImageDetailsViewId={setImageDetailsViewId}
						// editTypes={editTypes}
						// editGroups={editGroups}
						setSelectedImagesID={setSelectedImagesID}
						selectImageName={""}
						token={token}
						idSelected={idSelected}
						setClickSelect={setClickSelect}
						images={images}
						setImageID={setImageID}
						imageID={imageID}
						setting_profile={setting_profile}
					/>
					<RemoveModalConfirmation
						show={removePSD}
						onHide={() => {
							setRemovePSD(false);
						}}
						title={"Remove uploaded PSD and image"}
						message={"Are you sure want to remove uploaded PSD and image?"}
						handleConfirm={(e) => handlePSDDelete(e)}
					/>
					<MyVerticallyCenteredModal
						show={showUploadModal}
						onHide={() => {
							setShowUploadModal(false);
							if (reloadBatchImages) {
								setClosePopup(true);
							} else {
								setClosePopup(false);
							}
						}}
						heading={responseHeading}
						message={responseMessage}
						className="modal-body-body--success"
					/>
				</div>
			</div>
		</>
	);
};

export default EditorOrderDetails;
