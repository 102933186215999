import React, { useState } from "react";
import { useParams } from "react-router-dom";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Invoices from "./Invoices";
import Orders from "./Orders";
import Customeroverview from "./Customeroverview";
import Customersettings from "./Customersettings";
import CustomerChat from "./CustomerChat";
import menudot from "../../assets/Images/menu-dot.svg";

const CustomerDetails = () => {
	const { id } = useParams();
	const [company_name, setCompanyNameDetails] = useState("");
	return (
		<>
			<div className="rightbar pr-11 overviewstab customrightbar">
				<div className="detailstab">
					<div className="section-title">
						<h2>Customers Details - {company_name}</h2>
					</div>
					<Tabs
						defaultActiveKey="Overview"
						id="justify-tab-example"
						className="stafftab"
						justify
					>
						<Tab eventKey="Overview" title="Overview">
							<Customeroverview
								id={id}
								setCompanyNameDetails={setCompanyNameDetails}
							/>
						</Tab>
						<Tab eventKey="Orders" title="Orders">
							<Orders id={id} setCompanyNameDetails={setCompanyNameDetails} />
						</Tab>
						<Tab eventKey="Chats" title="Chats">
							<CustomerChat />
						</Tab>
						<Tab eventKey="Settings" title="Settings">
							<Customersettings />
						</Tab>
						<Tab eventKey="Invoices" title="Invoices">
							<Invoices />
						</Tab>
					</Tabs>

					<div className="doticon">
						<img src={menudot} className="img-fluid" alt="mindreach images" />
					</div>
				</div>
			</div>
		</>
	);
};

export default CustomerDetails;
