/********************************************
    ****Admin endpoints**********************
        1.Branch as a admin end point
        2.Work shift as a admin end point
        3.Holiday as a admin end point
        4.Staffs as a admin end point
        5.Google Map Api as a admin end point
        6.Customers as a admin end point
        7.Edit Type as a admin end point
        8.Coordinators as a admin end point
    ******End*****************
******************************/
//Branch end point
export const ADMIN_GET_BRANCHE = "/admin/get-branches"; // Get branches for admin
export const ADMIN_ADD_BRANCHE = "/admin/store-branch"; // Add a branch as admin
export const VIEW_BRANCHE = "/admin/view-branch"; // View details of a branch as admin
export const UPDATE_BRANCHE = "/admin/update-branch"; // Update a branch as admin
export const DELETE_BRANCHE = "/admin/delete-branch"; // Delete a branch as admin
export const BRANCHES_LIST = "/admin/get-branch-lists"; // Get a list of branches for admin
export const BRANCHES_SHIFT_BYBRANCHID = "/admin/get-branch-shift"; // Get shifts for a specific branch as admin
export const DELETE_BRANCHE_BULK = "/admin/delete-bulk-branch"; // Delete multiple branches as admin
export const ACTIVATE_BRANCH_BY_ID = "/admin/activate-branch"; // Activate branch by id as a admin
export const DEACTIVATE_BRANCH_BY_ID = "/admin/deactivate-branch"; // Deactivate branch by id as a admin
export const ACTIVATE_BULK_BRANCH = "/admin/activate-bulk-branch"; //Activate bulk branche as a admin
export const DEACTIVATE_BULK_BRANCH = "/admin/deactivate-bulk-branch"; // Deactivate bulk branch as a admin
export const DUPLICATE_BULK_BRANCH = "/admin/duplicate-bulk-branch"; // Duplicate bulk branch as a admin

//Work shift end point
export const ADD_WORK_SHIFTS = "/admin/store-shift"; // Add work shifts as admin
export const WORK_SHIFTS_LISTS = "/admin/get-shifts"; // Get a list of work shifts as admin
export const WORK_SHIFTS_VIEW_ID = "/admin/view-shift"; // View details of a work shift as admin
export const WORK_SHIFTS_EDIT_BY_ID = "/admin/update-shift"; // Update a work shift as admin
export const DELETE_WORK_SHIFT_BY_ID = "/admin/delete-shift"; // Delete a work shift as admin
export const DELETE_BULK_WORK_SHIFTS = "/admin/delete-bulk-shift"; // Delete multiple work shifts as admin
export const ACTIVATE_WORK_SHIFT_BY_ID = "/admin/activate-shift"; // Activate shift by id as a admin
export const DEACTIVATE_WORK_SHIFT_BY_ID = "/admin/deactivate-shift"; //Deactivate shift by id as a admin
export const ACTIVATE_BULK_WORK_SHIFT = "/admin/activate-bulk-shift"; //Activate bulk shift as a admin
export const DEACTIVATE_BULK_WORK_SHIFT = "/admin/deactivate-bulk-shift"; //Deactivate bulk shift as a admin
export const DUPLICATE_BULK_WORK_SHIFT = "/admin/duplicate-bulk-shift"; // Duplicate bulk shift as a admin

// Holidays end point
export const LISTS_HOLIDAYS = "/admin/get-holidays"; // Get a list of holidays as admin
export const ADD_HOLIDAY = "/admin/store-holiday"; // Add a holiday as admin
export const VIEW_HOLIDAY = "/admin/view-holiday"; // View details of a holiday as admin
export const HOLIDAY_YEAR_LISTS = "/admin/get-holiday-years"; // Get the list of years as a admin
export const SEARCH_HOLIDAY = "/admin/get-holiday-list"; // Search for holidays as admin
export const UPDATE_HOLIDAY_BY_ID = "/admin/update-holiday"; // Update a holiday as admin
export const ACTIVATE_BULK_HOLIDAY = "/admin/activate-bulk-holiday"; // Activate holiday as a bulk as a admin
export const ACTIVATE_HOLIDAY_BY_ID = "/admin/activate-holiday"; // Activate holiday by id
export const DEACTIVATE_HOLIDAY_BY_ID = "/admin/deactivate-holiday"; //Deactivate holiday by id
export const DEACTIVATE_BULK_HOLIDAY = "/admin/deactivate-bulk-holiday"; // Deactivate holiday as a bulk as a admin
export const DUPLICATE_BULK_HOLIDAY = "/admin/duplicate-bulk-holiday"; // Duplicate holiday as a bulk as a admin
export const DELETE_HOLIDAY_BY_ID = "/admin/delete-holiday"; // Delete a holiday by ID as admin
export const DELETE_HOLIDAYs_BULK = "/admin/delete-bulk-holiday"; // Delete multiple holidays as admin

//Staffs end point
export const VIEW_STAFFS = "/admin/get-staffs"; // Get a list of staff members as admin
export const ADMIN_STAFF_ROLES = "/admin/get-admin-roles"; // Get a list of roles of staff members
export const ADMIN_ADD_STAFF = "/admin/store-staff"; //Add staff as a admin
export const VIEW_EDIT_STAFF = "/admin/edit-staff"; //View staff member by id
export const UPDATE_STAFF = "/admin/update-staff"; // Update a staff as a admin
export const ADMIN_STAFF_ROLE_LIST = "/admin/get-admin-role-list"; // Get a list of role list of staff members
export const DELETE_STAFF_BY_ID = "/admin/delete-staff"; // Delete a staff by id as a admin
export const DELETE_STAFF_BULK = "/admin/delete-bulk-staff"; // Delete bulk as a staff as a admin
export const ACTIVATE_STAFF_BY_ID = "/admin/staff-active"; // Activate staff  by id as a admin
export const ACTIVATE_BULK_STAFF = "/admin/activate-bulk-staff"; // Activate staff as a bulk as a admin
export const DEACTIVATE_STAFF_BY_ID = "/admin/staff-deactive"; // Deactive staff by id as a admin
export const DEACTIVATE_BULK_STAFF = "/admin/deactivate-bulk-staff"; // Deactivate staff as a bulk as a admin

//Staff Details View End Point
export const VIEW_STAFF_DETAILS = "/admin/view-staff"; // To the view the staff details page

//Staff Details Batch Details View End Point
export const VIEW_STAFF_BATCH_HISTORY = "/admin/view-batch-history"; // To the view batch history for staff

//Google Map Api end point
export const GOOGLE_MAP_SEARCH = "/get-places"; // Get a list of places from Google map Api (third party)
export const GOOGLE_MAP_PLACE_ID = "/get-place-gmt"; // Get a list of places  by place_id from Google map Api (third party)

//Customers end point
export const VIEW_CUSTOMERS = "/admin/get-customers"; // Get a list of customers
export const ADD_CUSTOMER = "/admin/store-customer"; // Get a list of customers
export const VIEW_CUSTOMER = "/admin/view-customer"; // Get a list of customers
export const UPDATE_CUSTOMER = "/admin/update-customer"; // Get a list of customers
export const ACTIVATE_CUSTOMER_BULK = "/admin/activate-bulk-customer"; // Activate bulk customer as a admin
export const DEACTIVATE_CUSTOMER_BULK = "/admin/deactivate-bulk-customer"; //Deactivate bulk customer as a admin
export const ACTIVATE_CUSTOMER_BY_ID = "/admin/activate-customer"; // Activate customer by id
export const DEACTIVATE_CUSTOMER_BY_ID = "/admin/deactivate-customer"; // Deactivate customer by id
export const DELETE_CUSTOMER_BY_ID = "/admin/delete-customer"; // Delete a Customer  as admin by id
export const DELETE_BULK_CUSTOMER = "/admin/delete-bulk-customer"; // Delete customer as a bulk as a admin
export const VIEW_CUSTOMER_ORDER_OVERVIEW = "/admin/view-customer-details"; // Get a list of customers
export const VIEW_CUSTOMER_ORDER_HISTORY = "/admin/view-customer-order-history"; // Get a list of customers
export const VIEW_CUSTOMER_SETTINGS = "/admin/view-customer-settings"; // View customer settings
export const VIEW_CUSTOMER_FTP = "/admin/get-customer-settings-ftp"; // View customer FTP
export const VIEW_CUSTOMER_EDIT_TYPE =
	"/admin/get-customer-settings-edit-types"; // View customer edit type
export const VIEW_CUSTOMER_EDIT_GROUP =
	"/admin/get-customer-settings-edit-groups"; // View customer edit group
export const VIEW_CUSTOMER_ASSIGN_TEAM =
	"/admin/get-customer-settings-assign-teams"; // View customer assign team
export const DELETE_CUSTOMER_FTP = "/admin/delete-customer-ftp"; // Delete customer FTP
export const ADD_CUSTOMER_FTP = "/admin/store-customer-ftp"; // Add customer FTP
export const VIEW_CUSTOMER_FTP_ID = "/admin/view-customer-ftp"; // View customer FTP
export const UPDATE_CUSTOMER_FTP = "/admin/update-customer-ftp"; // View customer FTP
export const UPDATE_CUSTOMER_DISTRIBUTION_BATCH =
	"/admin/update-customer-batch-image-number"; // View customer Distribution Batch
export const VIEW_CUSTOMER_EDIT_TYPE_BY_ID = "/admin/view-custom-edit-type"; // View customer edit type
export const UPDATE_CUSTOMER_EDIT_TYPE = "/admin/edit-custom-edit-type"; // Update customer edit type
export const GET_TEAM_ROLE_LIST = "/admin/get-team-member-role-list"; // Get Team members Role List
export const GET_TEAM_LIST = "/admin/get-team-member-list"; // Get Team members Role List
export const ADD_TEAM_MEMBER = "/admin/add-team-member"; // Get Team members Role List
export const DELETE_TEAM_MEMBER = "/admin/unassign-team-member"; // Get Team members Role List

//Edit Type end point
export const ADD_EDIT_TYPE = "/admin/store-edit-type"; // Add edit Types as admin
export const EDIT_TYPES_LISTS = "/admin/get-edit-types"; // Get a list of edit Types as admin
export const EDIT_TYPE_VIEW_ID = "/admin/view-edit-type"; // View details of a edit Type as admin
export const EDIT_TYPE_EDIT_BY_ID = "/admin/update-edit-type"; // Update a edit Type as admin
export const DELETE_EDIT_TYPE_BY_ID = "/admin/delete-edit-type"; // Delete a edit Type as admin
export const DELETE_EDIT_TYPE_BULK = "/admin/delete-bulk-edit-type"; // Delete a edit Type Bulk as admin
export const ACTIVATE_BULK_EDIT_TYPE = "/admin/activate-bulk-edit-type"; // Activate a edit type Bulk as a admin
export const DEACTIVATE_BULK_EDIT_TYPE = "/admin/deactivate-bulk-edit-type"; //Deactivate a edit type bulk as a admin
export const DUPLICATE_BULK_EDIT_TYPE = "/admin/duplicate-bulk-edit-type"; // Duplicate a edit type bulk as a admin

// Customer Edit Groups
export const CUSTOMERS_GROUP_TYPE_LIST = "/admin/get-edit-groups"; // To get the customer Edit Groups list as a admin
export const CUSTOMERS_STORE_GROUP_TYPE = "/admin/store-custom-edit-group"; // To store the customer Edit Groups as a admin
export const CUSTOMERS_VIEW_GROUP_TYPE = "/admin/view-custom-edit-group"; // To view the customer Edit Groups as a admin
export const CUSTOMERS_UPDATE_GROUP_TYPE = "/admin/update-custom-edit-group"; // To update the customer Edit Groups as a admin
export const CUSTOMERS_DELETE_GROUP_TYPE_BY_ID =
	"/admin/delete-custom-edit-group"; // Delete customer Edit Groups by Group  id as a admin
export const CUSTOMERS_ALL_EDIT_TYPE_LIST = "/admin/get-edit-types-list"; // Delete customer edit types list

// Terms and Conditions end points
export const ADMIN_GET_TERMS_AND_CONDITION = "/admin/get-terms-and-condition"; // Get Terms and conditions
export const ADMIN_UPDATE_TERMS_AND_CONDITION = "/admin/update-terms-and-condition"; // Get Terms and conditions

//Become Enterprise Form Fields end point
export const ADMIN_GET_ENTERPRISE_FIELDS =
	"/admin/get-become-enterprise-form-fields"; // Get Enterprise Form Fields for admin
export const UPDATE_ENTERPRISE_FIELD =
	"/admin/update-become-enterprise-form-field"; // Update a Enterprise Form Field as admin
export const ADD_ENTERPRISE_FIELD = "/admin/store-become-enterprise-form-field"; // Add a Enterprise Form Field as admin
export const ADMIN_VIEW_ENTERPRISE_FIELD =
	"/admin/view-become-enterprise-form-field"; // View details of a Enterprise Form Field as admin
export const DELETE_ENTERPRISE_FIELD =
	"/admin/delete-become-enterprise-form-field"; // Delete a Enterprise Form Field as admin
export const DELETE_BULK_ENTERPRISE_FIELD =
	"/admin/delete-bulk-become-enterprise-form-field"; // Bulk delete Enterprise Form Fields as admin
export const DUPLICATE_ENTERPRISE_FIELD =
	"/admin/duplicate-become-enterprise-form-field"; //  Duplicate Enterprise Form Fields as admin
export const DUPLICATE_BULK_ENTERPRISE_FIELD =
	"/admin/duplicate-bulk-become-enterprise-form-field"; //  Duplicate Enterprise Form Fields as admin
export const ADMIN_PREVIEW_ENTERPRISE_FIELD =
	"/admin/get-become-enterprise-form-field-all-list"; //  Preview Enterprise Form Fields as admin
export const ADMIN_SET_ENTERPRISE_FIELD_PRIORITY =
	"/admin/set-priority-become-enterprise-form-field"; //  Set Enterprise Form Fields priority as admin
export const ADMIN_GET_ENTERPRISE_REQUESTS = "/admin/get-enterprises"; //  Get Enterprise customer requests as admin
export const ADMIN_VIEW_ENTERPRISE_REQUEST_DETAILS = "/admin/view-enterprise"; //  View Enterprise customer request details as admin
export const ADMIN_DOWNLOAD_ENTERPRISE_DOCUMENT =
	"/admin/download-enterprise-document"; //  Download Enterprise  request documentas admin

//Coordinator Workspace api endpoint
export const GET_ALL_COORDINATORS = "/admin/get-all-coordinators"; // To get the all the coordinators
export const ADMIN_GET_COORDINATORS_DETAILS =
	"/admin/get-coordinator-workspace"; // To get coordinator workspace as a super admin
export const ADMIN_UPDATE_COMMENTS_TO_POST_COORDINATOR_IMAGE =
	"/admin/update-image-comment"; // To post comments in Coordinator Image
export const ADMIN_GET_COMMENTS_TO_VIEW_COORDINATOR_IMAGE =
	"/admin/get-image-comments"; // To View the comments in Coordinating Image
export const ADMIN_DELETE_COMMENTS_COORDINATOR_IMAGE =
	"/admin/delete-image-comment"; // To Delete the comment in Coordinating Image
export const ADMIN_GET_IMAGES_SETTINGS = "/admin/get-image-details"; // To view the image details settings
export const CO_ORDINATOR_DUPLICATE_IMAGES = "/admin/update-duplicate-images"; // To duplicate the images as a image coordinator
export const CO_ORDINATOR_LOGGED_TIME = "/admin/log-coordinating-time"; //To log the time of image co-ordinator
export const CO_ORDINATOR_ASSIGNED_TO_EDITOR = "/admin/assign-images-to-editor"; // To assigned the images to editor
export const CO_ORDINATOR_UPDATE_EDIT_GROUP = "/admin/update-images-edit-group"; // To update the edit groupas image co-ordinator
export const CO_ORDINATOR_VIEW_PROFILE_SETTINGS = "/admin/get-image-details"; //To view the settings profile  as image co-ordinator
export const CO_ORDINATOR_ORIGINAL_IMAGE_RENAME = "/admin/rename-image"; // To rename the original image as a image coordinator
export const CO_ORDINATOR_DUPLICATE_IMAGE_RENAME =
	"/admin/rename-duplicate-image"; // To rename the image the duplicate image
export const CO_ORDINATOR_DELETE_DUPLICATE_IMAGE =
	"/admin/delete-image-duplication-setting"; // To delete the duplicate image as a image coordinator
export const CO_ORDINATOR_FETCH_DUPLICATE_IMAGE = "/admin/get-duplicate-images"; // To fetch the duplicate settings as a image coordinator
export const CO_ORDINATOR_DELETED_IMAGE_BY_ID = "/admin/delete-order-image"; // To delete the image by id as a image coordinator
export const CO_ORDINATOR_DUPLICATE_SETTINGS_BY_ID =
	"/admin/get-duplicate-setting"; //To fetch the duplicate settings based on duplicate image id
export const CO_ORDINATOR_VIEW_BASED_BY_FOLDER_ID =
	"/admin/get-coordinator-workspace-by-folder-id"; //To fetch the folder and images on folder id

//Editor Workspace api endpoints
export const GET_ALL_EDITORS = "/admin/get-all-editors"; // To get the all the editors
export const ADMIN_REASSIGNED_COORDINATOR = "/admin/reassign-coordinator"; // To reassigned the coordinator as a super admin
export const GET_EDITOR_WORK_SPACE = "/admin/get-editor-workspace"; // To get the all the batches
export const GET_EDITOR_BATCH_IMAGES = "/admin/get-editor-images"; // To get all the images by batch id
export const ADMIN_REASSIGN_EDITOR_IMAGES = "/admin/reassign-editor"; // To reassign the images by images and editor id
export const CO_ORDINATOR_MERGED_IMAGES = "/admin/update-merge-images"; // To merged the image as a coordinator
export const CO_ORDINATOR_VIEW_EDIT_GROUP = "/admin/view-edit-group"; // To view the edit group based on id

//Image Setting Page Edit Type
export const CREATE_EDIT_GROUP = "/customer/create-edit-group"; // To create edit group
export const CUSTOMER_VIEW_EDIT_GROUP = "/customer/view-edit-group"; // To view the edit group based on id
export const CUSTOMER_IMAGES_EDIT_GROUP = "/customer/update-images-edit-group"; // To update the images edit group in Edit Type
export const CUSTOMER_EDIT_GROUP = "/customer/update-edit-group"; // To update the edit group
export const CUSTOMER_DUPLICATE_IMAGES = "/customer/update-duplicate-images"; // To update the duplicate images
export const CUSTOMER_MERGED_IMAGES = "/customer/update-merge-images"; // To update the merged images
export const CUSTOMER_LINKED_IMAGES = "/customer/update-link-images"; // To update the linked images
export const CUSTOMER_SEND_MESSAGES = "/customer/order-image-comment-update"; //To send the comment on the image
export const CUSTOMER_COMMENTS_MESSAGES = "/customer/get-order-image-comments"; // To the fetch the comments

//QC Features Edit type
export const QC_VIEW = "/admin/get-all-qcs"; // To get the all qcs
export const QC_DOWNLOAD_PSD_FILE = "/admin/download-psd"; // To download the psd file in QC
export const QC_VIEW_IMAGES = "/admin/get-qc-workspace"; // To get the qc images
export const QC_BULK_APPROVED = "/admin/bulk-approve"; // To bulk Approved the images as a QC
export const QC_BULK_REJECTS = "/admin/bulk-reject"; // To bulk Reject the images as a QC
export const QC_REVISIONS_BY_IMAGE_ID = "/admin/image-revision"; // To revison of the images by image-id

//Editor Workspace
export const EDITOR_DOWNLOAD_IMAGE = "/admin/download-editor-images"; //  Download editor image
export const EDITOR_UPLOAD_IMAGE = "/admin/upload-editor-images"; //  Upload editor image
export const EDITOR_REMOVE_PSD = "/admin/remove-psd"; //  Remove PSD and image
export const EDITOR_UPLOAD_BULK_IMAGE = "/admin/upload-editor-bulk-images"; //  Upload editor bulk image
export const EDITOR_ASSIGN_QC_IMAGE = "/admin/assign-images-to-qc"; //  Assign images to QC

export const QC_BACKROUND_REMOVAL = "/admin/remove-bg"; // To view the image

// QC Dashboard
export const QC_DASHBOARD_WORSPACE = "/admin/workspace-queue"; // To view the dashboard table in qc
export const QC_TIME_LOG_WORKSPACE = "/admin/log-qcing-time"; // To Qc time log Workspace

//Image Coordinator Supervisor
export const IMAGE_COORDINATOR_SUPERVISOR_STATE =
	"/admin/coordinator-supervisor-state"; // To View the state of the image coordinatore supervisor state
export const IMAGE_COORDINATOR_SUPERVISOR_TO_COORDINATOR =
	"/admin/supervisor-to-coordinator"; // To change the state of the image coordinator workspace

//Image Editor Supervisor
export const EDITOR_SUPERVISOR_STATE = "/admin/editor-supervisor-state"; // To View the state of the image editor supervisor state
export const EDITOR_SUPERVISOR_TO_EDITOR = "/admin/supervisor-to-editor"; // To change the state of the image edior supervisor to the editor

// QC Supervisor Workspace
export const QC_SUPERVISOR_STATE = "/admin/qc-supervisor-state"; // To View the state of the image QC supervisor state
export const QC_SUPERVISOR_TO_QC = "/admin/supervisor-to-qc"; // To change the state of the image QC supervisor to the QC
