import React, { useCallback, useEffect, useMemo, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { isEqual } from "lodash";

import Loader from "../../components/Loader";
import folder from "../../assets/Images/folder-coordinator.svg";
import folderblue from "../../assets/Images/folder-blue.svg";
import { useNavigate, useParams } from "react-router-dom";

import { flushSync } from "react-dom";
import DeleteFolder from "../../assets/Images/delete_1.svg";
import RenameFolder from "../../assets/Images/rename_folder.svg";
// Importing API related files and routes
import { client } from "../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../data/filepath";
import {
	GET_ALL_COORDINATORS,
	ADMIN_GET_COORDINATORS_DETAILS,
	CO_ORDINATOR_VIEW_BASED_BY_FOLDER_ID,
} from "../../config/apiRoutes.api";

import { VIEW_IMAGE_COORDINATOR_WORKSPACE } from "../../config/routes";
import { object } from "prop-types";

// import { currentUserId } from "../../utils/Coordinator/getCurrentUserId";
const RightSidebar = ({
	// folders,
	// // id,
	// setImages,
	// mainImageCount,
	// mainImageSettingsCount,
	// setItemList,
	// imagesMainFolder,
	// folderMainFolder,
	// setFolder,
	// setParentFolderDetails,
	// list_display,
	// setDisplay,
	// setFolderList,
	// folder_list,
	// folder_name,
	// setFolderName,
	// folder_list_menu,
	// setFolderListMenu,
	// loader_sub_menu,
	// setLoaderSubMenu,
	// clickSelect,
	// setClickSelect,
	// hide_list_menu,
	// setHideListMenu,
	// batchId,
	// token,
	// selectOptions,
	// list_sub_display,
	// setDisplaySub,
	// setFolderListName,
	// setMultipleImagesId,
	// setFoldersPath,
	// fetchDataImages,
	// setHasMore,
	// // folder_id_query,
	// defaultFolder,
	// setFolderIndex,
	// folderIndex,
	// setFoldersListSub,
	// folders_list_sub,
	// setFoldersId,
	// folder_id,
	folders,
	// id,
	setImages,
	mainImageCount,
	mainImageSettingsCount,
	setItemList,
	imagesMainFolder,
	folderMainFolder,
	setFolder,
	setParentFolderDetails,
	list_display,
	setDisplay,
	setFolderList,
	folder_list,
	folder_name,
	setFolderName,
	folder_list_menu,
	setFolderListMenu,
	loader_sub_menu,
	setLoaderSubMenu,
	clickSelect,
	setClickSelect,
	hide_list_menu,
	setHideListMenu,
	batchId,
	token,
	selectOptions,
	list_sub_display,
	setDisplaySub,
	setFolderListName,
	setMultipleImagesId,
	setFoldersPath,
	// fetchDataImages,
	// folder_id_query,
	defaultFolder,
	setFolderIndex,
	folderIndex,
	setFoldersListSub,
	folders_list_sub,
	setFoldersId,
	folder_id,
	setPage,
	setHasMore,
	setLastPage,
	setSelectAll,
	setAllImages,
	folderList,
}) => {
	/**
	 * Renders a Tooltip component with the specified text as its content.
	 * @param {string} text - The text to be displayed in the Tooltip.
	 * @returns {JSX.Element} The Tooltip component.
	 */
	const renderTooltip = (text) => <Tooltip id="button-tooltip">{text}</Tooltip>;
	const { id, batch_id_query, folder_id_query } = useParams();
	// Get the navigation function from the useNavigate hook
	const navigate = useNavigate();

	// Create an HTTP client with the token
	const request = client(token);

	// const router = useRouter();
	// const { id } = router.query;

	const [folder_files_sub, setFolderListSub] = useState([]);
	const [total_folder_set, setFolderTotalSet] = useState([]);
	const [rightClickMenu, setRightClickMenu] = useState(false);

	const [pathFolder, setPathFolder] = useState("");
	const [pathSubFolder, setSubPathFoder] = useState("");
	const [deleteId, setDeleteId] = useState("");
	const [background, setBackgroundColor] = useState("");
	const [background_rename, setBackgroundColorRename] = useState("");
	const [parent_id, setParentId] = useState("");
	const [folder_selectNameSub, setFolderNameSub] = useState("");
	const [error, setError] = useState("");

	let folders_path_set = sessionStorage.getItem("folder");
	let folder_path_id = sessionStorage.getItem("folder_path_id");
	let folder_path =
		sessionStorage.getItem("folder_path") !== null &&
		sessionStorage.getItem("folder_path");

	let folder_sub_path =
		sessionStorage.getItem("folder_path_sub") !== null &&
		sessionStorage.getItem("folder_path_sub");

	let folder_list_Sub =
		sessionStorage.getItem("folder_list_1") !== null &&
		sessionStorage.getItem("folder_list_1");

	let main_folder_click = sessionStorage.getItem("main");
	let main_folder_id =
		sessionStorage.getItem("main_folder") !== null &&
		sessionStorage.getItem("main_folder");

	let folder_Sub_Path =
		sessionStorage.getItem("folder_set") !== null &&
		sessionStorage.getItem("folder_set");

	const handleChangeMainFolder = () => {
		// setFolderIndex(0);
		setFoldersId("");
		setPage(1);
		setImages([]);
		sessionStorage.setItem("folderIndex", 0);
		sessionStorage.setItem("folder_path_sub_left", "");
		setParentFolderDetails(null);
		sessionStorage.setItem("doubleClickCount", 0);

		sessionStorage.setItem("main", 1);
		sessionStorage.setItem("folderIndex", 0);
		sessionStorage.setItem("folder_path_id", main_folder_id);
		sessionStorage.setItem("folder_path_sub_left", "");
		console.log("main_folder_id", main_folder_id);
		const isFolderId = id[0] !== "" ? true : false;
		const isMainFolderId = main_folder_id !== "" ? true : false;
		if (isMainFolderId && isFolderId) {
			const url = `${VIEW_IMAGE_COORDINATOR_WORKSPACE}/${id}/${batch_id_query}/${main_folder_id}`;
			navigate(url);
		}
		setParentFolderDetails(null);
		setFolderName("");

		setClickSelect(false);
		setHideListMenu(true);
		setDisplaySub(true);
		setFolderListName([]);
		setMultipleImagesId([]);
		setFoldersPath([]);
		setHasMore(true);
		setSelectAll(false);
	};

	const handleChange = useCallback((item, path, parent_id, folder_id) => {
		setMultipleImagesId([]);
		setLoaderSubMenu(true);
		setParentId(parent_id);
		setFoldersId(folder_id);
		// setImages([]);
		// setPage(1);
		// setLastPage(2);

		// setDisplaySub((val) => !val);

		// sessionStorage.setItem("folder_path", path);
		// sessionStorage.setItem("folder_path_id", item);
		// sessionStorage.setItem("folder_path_sub", "");
		// sessionStorage.setItem("folder_path_sub_left", "");
		sessionStorage.setItem("main", 0);
		// sessionStorage.setItem("folderIndex", 1);

		sessionStorage.setItem("folder_path", path);
		sessionStorage.setItem("folder_path_id", item);
		// sessionStorage.setItem("folder_path_sub", "");
		sessionStorage.setItem("folder_path_sub_left", path);

		if (token) {
			request

				.get(
					apiURL +
						CO_ORDINATOR_VIEW_BASED_BY_FOLDER_ID +
						`${id}` +
						`${batch_id_query}` +
						`/${item}`
				)
				.then((response) => {
					const url = `${CO_ORDINATOR_VIEW_BASED_BY_FOLDER_ID}/${id}/${batch_id_query}/${item}`;
					navigate(url);
					setSelectAll(false);
					setAllImages(response.data.data.currentFolderImageIds);
					// setPage(1);
					const { data, last_page, current_page } = response.data.data.images;
					setItemList(data);
					setImages(data);
					setFolder(response.data.data.folders);
					// setHasMore(true);
					if (current_page <= last_page) {
						setLastPage(last_page);
						setPage(current_page);
						setHasMore(true);
					}
					setFolderList(response.data.data.folders);

					setFolderName(path);
					// if (current_page <= last_page) {
					// 	flushSync(() => {
					// 		setItemList((prevItems) => {
					// 			const items = Array.isArray(prevItems) ? prevItems : [];

					// 			// Step 1: Log the existing items for reference
					// 			console.log("Existing items:", items);

					// 			// Step 2: Create a Set of existing folder IDs from prevItems
					// 			const existingFolderIds = new Set(
					// 				items.map((item) => item.folder_id)
					// 			);
					// 			console.log("Existing folder IDs:", existingFolderIds);

					// 			// Step 3: Filter data based on folder_id and id, ensuring folder_id exists in prevItems
					// 			const newItems = data.filter((item) => {
					// 				const isFolderIdExists = existingFolderIds.has(
					// 					item.folder_id
					// 				);
					// 				const isDuplicate = items.some(
					// 					(prevItem) =>
					// 						prevItem.folder_id === item.folder_id &&
					// 						prevItem.id === item.id
					// 				);

					// 				// Log each check for debugging purposes
					// 				console.log(
					// 					`Checking item with ID ${item.id} in folder ${item.folder_id}`
					// 				);
					// 				console.log(
					// 					"Folder ID exists:",
					// 					isFolderIdExists,
					// 					"Is duplicate:",
					// 					isDuplicate
					// 				);

					// 				// Only include if folder_id exists in prevItems and it’s not a duplicate
					// 				return isFolderIdExists && !isDuplicate;
					// 			});

					// 			console.log("New items to add:", newItems);

					// 			// Step 4: Return the updated list
					// 			return [...items, ...newItems];
					// 		});
					// 	});
					// }

					setHideListMenu(true);
					setFoldersPath([]);
					const newFolder = {
						id: item,
						name: path,
						parent_id: parent_id,
						otherKey: response.data.data.folders,
						otherKey_1: response.data.data.folders,
					};

					// Add the new folder object to the state, ensuring no duplicates based on name or id
					setFoldersListSub((prevFoldersListSub) => {
						// Check if a folder with the same name or id already exists
						const folderExists = prevFoldersListSub.some(
							(folder) =>
								folder.name === newFolder.name || folder.id === newFolder.id
						);

						// If it exists, return the previous state; otherwise, add the new folder
						if (folderExists) {
							return prevFoldersListSub;
						}

						// Combine the existing folders and the new folder
						const updatedFolders = [...prevFoldersListSub, newFolder];

						// Create a Map to deduplicate folders based on their id
						const deduplicatedFolders = updatedFolders.reduce((acc, folder) => {
							acc.set(folder.id, folder); // Use the folder's id as the key
							return acc;
						}, new Map());

						// Convert the Map values back to an array
						return Array.from(deduplicatedFolders.values());
					});

					// const newFolder = {
					// 	id: item,
					// 	name: path,
					// 	otherKey: response.data.data.folders,
					// };

					// // Add the new folder object to the state
					// setFoldersListSub((prevFoldersListSub) => [
					// 	...prevFoldersListSub,
					// 	newFolder,
					// ]);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	}, []);
	const handleChangeSubFolder = useCallback(
		(items, item, path, batchId) => {
			setLoaderSubMenu(true);
			// setItemList([]);
			// sessionStorage.setItem("folder_path_sub_left", item);
			// sessionStorage.setItem("folder_path", path);
			// sessionStorage.setItem("folder_path_id", items);
			// sessionStorage.setItem("folder_path_sub", items);
			sessionStorage.setItem("main", 0);
			sessionStorage.setItem("folder_path_sub_left", item);
			sessionStorage.setItem("folder_path", path);
			sessionStorage.setItem("folder_path_id", items);
			sessionStorage.setItem("folder_path_sub", items);
			console.log("folder_path_sub check", item);
			// if (items !== id[1]) {
			// 	Router.push(`${ORDERS_DRAFT_PAGE}/${id[0]}/${items}`);
			// }
			if (items !== folder_id_query) {
				const url = `${VIEW_IMAGE_COORDINATOR_WORKSPACE}/${id}/${batch_id_query}/${items}`;
				navigate(url);
			}

			if (token) {
				request

					.get(apiURL + CO_ORDINATOR_VIEW_BASED_BY_FOLDER_ID + `/${items}`)
					.then((response) => {
						setParentFolderDetails(null);
						setHasMore(true);
						setSelectAll(false);
						setAllImages(response.data.data.currentFolderImageIds);

						setFolderList(response.data.data.folders);
						const { data, last_page, current_page } = response.data.data.images;

						if (current_page <= last_page) {
							flushSync(() => {
								setItemList((prevItems) => {
									const items = Array.isArray(prevItems) ? prevItems : [];
									// Filter out items that already exist in prevItems based on folder_id and id
									const newItems = data.filter(
										(item) =>
											!items.some(
												(prevItem) =>
													prevItem.folder_id === item.folder_id &&
													prevItem.id === item.id
											)
									);
									return [...items, ...newItems]; // Append the new items (filtering already done)
								});

								setImages((prevImages) => {
									const images = Array.isArray(prevImages) ? prevImages : [];
									// Filter out images that already exist in prevImages based on folder_id and id
									const newImages = data.filter(
										(item) =>
											!images.some(
												(prevImage) =>
													prevImage.folder_id === item.folder_id &&
													prevImage.id === item.id
											)
									);
									return [...images, ...newImages]; // Append the new images (filtering already done)
								});
							});
						}

						setFolderTotalSet([folder_list, folder_list_menu]);
						setLoaderSubMenu(false);
						setClickSelect(false);
						const newFolder = {
							id: item,
							name: path,
							otherKey: response.data.data.folders,
						};
						// Add the new folder object to the state, ensuring no duplicates based on name
						setFoldersListSub((prevFoldersListSub) => {
							// Check if a folder with the same name already exists
							const folderExists = prevFoldersListSub.some(
								(folder) => folder.name === newFolder.name
							);

							// If it exists, return the previous state; otherwise, add the new folder
							if (folderExists) {
								return prevFoldersListSub;
							}

							// Combine the existing folders and the new folder
							const updatedFolders = [...prevFoldersListSub, newFolder];

							// Create a Map to deduplicate folders based on their id
							const deduplicatedFolders = updatedFolders.reduce(
								(acc, folder) => {
									acc.set(folder.id, folder); // Use the folder's id as the key
									return acc;
								},
								new Map()
							);

							// Convert the Map values back to an array
							return Array.from(deduplicatedFolders.values());
						});

						// Create a new folder object with the specified keys

						// Add the new folder object to the state
						setFoldersListSub((prevFoldersListSub) => [
							...prevFoldersListSub,
							newFolder,
						]);
					})
					.catch((err) => {
						let message =
							typeof err.response !== "undefined"
								? err.response.data.message
								: err.message;
						setError(message);
					});
			}
		},
		[folder_id_query]
	);

	// Helper function to check if a folder is selected
	const isFolderSelected = useCallback(
		(folderPath, selectedFolderPath, path) => {
			return folderPath === selectedFolderPath;
		},
		[folder_sub_path]
	);
	const handleRightSideMenuOpen = (event, path, id, folder_name) => {
		event.preventDefault();
		setDeleteId(path);
		setRightClickMenu((val) => !val);
		if (folder_name !== "") {
			setPathFolder(path);
			setSubPathFoder(folder_name);
		}
		setPathFolder(path);
	};
	useEffect(() => {
		const handleContextMenu = (e) => {
			if (e.target.nodeName === "SPAN") {
				e.preventDefault();
				setRightClickMenu(true);
			} else {
				setRightClickMenu(false);
				setPathFolder("");
				setSubPathFoder("");
			}
		};

		const handleClickOutside = (e) => {
			if (!e.target.closest(".list-group-flush")) {
				setDeleteId("");
				setPathFolder("");
				setSubPathFoder("");
			}
		};

		document.addEventListener("contextmenu", handleContextMenu);
		document.addEventListener("click", handleClickOutside);

		return () => {
			document.removeEventListener("contextmenu", handleContextMenu);
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);
	const handleDeleteFolder = () => {
		if (token) {
			// Append the folder's name to the FormData object
			// if (pathSubFolder === "") {
			// 	// Send a POST request to delete the selected folder
			// 	request
			// 		.get(
			// 			apiURL +
			// 				CUSTOMERS_DELETE_FOLDER_BY_ID +
			// 				`/${id}` +
			// 				`?folder_path=${pathFolder}`
			// 		)
			// 		.then((response) => {
			// 			console.log("add", response.data.data);
			// 			setItemList([]);
			// 			setImages([]);
			// 			setFolderList([]);
			// 			setFolder([]);
			// 			setRightClickMenu(false);
			// 			setPathFolder("");
			// 			setSubPathFoder("");
			// 			fetchDataImages();
			// 			setFolderList([]);
			// 		})
			// 		.catch((err) => {
			// 			let message =
			// 				typeof err.response !== "undefined"
			// 					? err.response.data.message
			// 					: err.message;
			// 			setError(message);
			// 			setHasMore(false);
			// 		});
			// } else {
			// 	request
			// 		.get(
			// 			apiURL +
			// 				CUSTOMERS_DELETE_FOLDER_BY_ID +
			// 				`/${id}?folder_path=${pathSubFolder}/${pathFolder}`
			// 		)
			// 		.then((response) => {
			// 			setItemList([]);
			// 			setImages([]);
			// 			setFolderList([]);
			// 			setRightClickMenu(false);
			// 			setPathFolder("");
			// 			setSubPathFoder("");
			// 			fetchDataImages();
			// 		})
			// 		.catch((err) => {
			// 			let message =
			// 				typeof err.response !== "undefined"
			// 					? err.response.data.message
			// 					: err.message;
			// 			setError(message);
			// 		});
			// }
		}

		// If none of the conditions match, do nothing or handle the case as needed
	};
	useEffect(() => {
		let parsedFolderList;

		try {
			parsedFolderList = JSON.parse(folder_list_Sub);
		} catch (e) {
			console.error("Failed to parse folder_list_Sub:", e);
			return;
		}

		if (Array.isArray(parsedFolderList) && parsedFolderList.length > 0) {
			setFolderListSub((prev) => {
				// Only update state if the new value is different from the current state
				if (!isEqual(prev, parsedFolderList)) {
					return parsedFolderList;
				}
				return prev; // No update needed
			});
		}
	}, [folder_list_Sub !== null, folder_id_query !== null, list_sub_display]);

	const folderSelectHighlighter = (folder_name) => {
		try {
			// Check if any folder has a matching path in `otherKey`
			return JSON.parse(folder_Sub_Path).some((item) => {
				// Check if `otherKey` exists and is an array
				if (Array.isArray(item.otherKey)) {
					// Find if any `checkName.path` matches `folder_name`
					return item.otherKey.some(
						(checkName) => checkName.path === folder_name
					);
				}
				return false;
			});
		} catch (error) {
			console.error("Invalid JSON format:", error);
			return false; // Return false in case of JSON error
		}
	};
	function processFolderPaths(foldersPathSet) {
		// Validate and parse the foldersPathSet
		if (foldersPathSet === "") {
			console.error("Input is an empty string");
			return [];
		}

		let parsedFoldersPathSet;
		try {
			parsedFoldersPathSet =
				foldersPathSet.length > 0 && JSON.parse(foldersPathSet);
		} catch (error) {
			console.error("Invalid JSON:", error);
			return [];
		}

		// Ensure parsedFoldersPathSet is an array
		if (!Array.isArray(parsedFoldersPathSet)) {
			console.error("Parsed value is not an array:", parsedFoldersPathSet);
			return [];
		}

		// Use .map to process the array
		return parsedFoldersPathSet.map((item, index) => {
			// Replace this with your processing logic
			console.log(`Processing item at index ${index}:`, item);

			return item;
		});
	}
	console.log("folder_list", folder_files_sub);
	return (
		<>
			<div className="editororderdetails">
				<ul className="treeviewlist custom-viewlist">
					<li>
						<li
							onClick={handleChangeMainFolder}
							className={
								Number(main_folder_click) === 1 ? "order-folder--hover" : ""
							}
						>
							<i className="fa fa-angle-down" aria-hidden="true"></i>
							<span>
								<img
									src={Number(main_folder_click) === 1 ? folderblue : folder}
									className="img-fluid"
									alt="mindreach images"
									style={{
										width: Number(main_folder_click) === 1 ? "24px" : "29px",
									}}
								/>
							</span>
							<span className="catgory-name">
								Main Folder
								<span className="order-folder--count">
									({mainImageSettingsCount}/{mainImageCount})
								</span>
							</span>
						</li>

						{Array.isArray(folderList) &&
							folderList.map((item, index) => (
								<ul className="first-treeview">
									<li
										className={
											// folder_path_id === item.folder_details.encrypted_id
											// 	? "order-folder--hover"
											// 	: ""
											// !sessionStorage.getItem("folder_path_sub_left") &&
											// folder_path_id === folder_id_query &&
											// Number(main_folder_click) === 0
											item.folder_details.id === folder_id
												? "order-folder--hover"
												: ""
										}
										onContextMenu={(e) =>
											handleRightSideMenuOpen(e, item.path, item.id, "")
										}
									>
										<span style={{ marginLeft: "1.5rem" }}>
											<i
												onClick={() => {
													// setDisplaySub((val) => !val);
													// // setHideListMenu((val) => !val);
													// // handleChangeFolder(
													// // 	item.folder_details.encrypted_id,
													// // 	item.path
													// // );
													// setFolderIndex(index);
													handleChange(
														item.folder_details.encrypted_id,
														item.path,
														item.folder_details.id,
														item.folder_details.id
													);
													// setDisplaySub(true);
													setItemList([]);
													sessionStorage.setItem("folderIndex", index);
												}}
												className={
													list_sub_display
														? "fa fa-angle-down"
														: "fa fa-angle-right"
												}
												aria-hidden="true"
											></i>
											<span
												onClick={() => {
													handleChange(
														item.folder_details.encrypted_id,
														item.path,
														item.folder_details.id,
														item.folder_details.id
													);
													// setDisplaySub(true);
													// setFolderIndex(index);
													sessionStorage.setItem("folderIndex", index);
												}}
											>
												<img
													src={
														item.folder_details.id === folder_id
															? folderblue
															: folder
													}
													style={{
														width:
															item.folder_details.id === folder_id
																? "24px"
																: "29px",
													}}
													className="img-fluid"
													alt="mindreach images"
												/>
											</span>
											<span
												className="catgory-name"
												onClick={() => {
													setDisplaySub(true);
													handleChange(
														item.folder_details.encrypted_id,
														item.path,
														item.folder_details.id,
														item.folder_details.id
													);
													// setFolderIndex(index);
													sessionStorage.setItem("folderIndex", index);
													// setHideListMenu(true);
												}}
											>
												{item.path}
												{/* <span className="order-folder--count">
													({item.imageSettingsCount}/{item.imageCount})
												</span> */}
											</span>
										</span>
									</li>
									{/* {item.path === deleteId && (
										<div key={index} className="delete--folder-list">
											<li
												style={{
													margin: "0 0 6px 0",
													width: "14.5em",
												}}
											>
												<span
													className={"order-folder-delete--hover"}
													style={{ background: `${background}` }}
													onMouseEnter={() => setBackgroundColor("#ecf1f7")}
													onMouseLeave={() => setBackgroundColor("#ffff")}
												>
													<span
														onClick={handleDeleteFolder}
														className="list-group--select"
													>
														<img
															src={DeleteFolder}
															className="img-fluid"
															alt="mindreach images"
														/>
													</span>
													<span
														className="list-group--delete"
														style={{ marginLeft: "4px" }}
													>
														Delete
													</span>
												</span>
											</li>
											<li
												style={{
													margin: "16px 0px 0px 0px",
													width: "14.5em",
													pointerEvents: "none", // Disables pointer events on the element
													opacity: "0.5", // Optionally reduce opacity to indicate disabled state
												}}
											>
												<span
													className={"order-folder-rename--hover"}
													style={{
														background: `${background_rename}`,
													}}
													onMouseEnter={() =>
														setBackgroundColorRename("#ecf1f7")
													}
													onMouseLeave={() => setBackgroundColorRename("#ffff")}
												>
													<span
														// onClick={handleDeleteFolder}
														className="list-group--select"
														style={{
															marginLeft: "21px",
															marginTop: "8px",
														}}
													>
														<img
															src={RenameFolder}
															className="img-fluid"
															alt="mindreach images"
														/>
													</span>
													<span className="list-group--delete">Rename</span>
												</span>
											</li>
										</div>
									)} */}
									{Array.isArray(folders_list_sub) &&
										folders_list_sub.map((item_1, index) => {
											let isCheckMain =
												item.folder_details.parent_id === item_1.parent_id;
											let checkName = item_1.name === item.path;

											return (
												<>
													{checkName &&
														// !isCheckMain &&
														item_1.otherKey.map((name, id) => {
															let check =
																name.folder_details.parent_id ===
																item_1.parent_id;

															// item?.length > 0 &&
															// name.name === item_1?.name;

															if (check) {
																return (
																	<ul
																		className="first-treeview first-treeview--inner"
																		key={id}
																	>
																		<li
																			className={
																				// folder_path_id === item.folder_details.encrypted_id
																				// 	? "order-folder--hover"
																				// 	: ""
																				// !sessionStorage.getItem("folder_path_sub_left") &&
																				// folder_path_id === folder_id_query &&
																				// Number(main_folder_click) === 0
																				name.folder_details.id === folder_id
																					? "order-folder--hover"
																					: ""
																			}
																			// onContextMenu={(e) =>
																			// 	handleRightSideMenuOpen(e, item.path, item.id, "")
																			// }
																		>
																			<span style={{ marginLeft: "1.5rem" }}>
																				<i
																					onClick={() => {
																						// setDisplaySub((val) => !val);
																						// // setHideListMenu((val) => !val);
																						// // handleChangeFolder(
																						// // 	item.folder_details.encrypted_id,
																						// // 	item.path
																						// // );
																						// setFolderIndex(index);
																						handleChange(
																							item.folder_details.encrypted_id,
																							item.path,
																							item.folder_details.parent_id,
																							item.folder_details.id
																						);
																						// setDisplaySub(true);
																						sessionStorage.setItem(
																							"folderIndex",
																							index
																						);
																					}}
																					className={
																						list_sub_display
																							? "fa fa-angle-down"
																							: "fa fa-angle-right"
																					}
																					aria-hidden="true"
																				></i>
																				<span
																					onClick={() => {
																						handleChange(
																							name.folder_details.encrypted_id,
																							name.path,
																							name.folder_details.parent_id,
																							name.folder_details.id
																						);
																						// setDisplaySub(true);
																						// setFolderIndex(index);
																						sessionStorage.setItem(
																							"folderIndex",
																							index
																						);
																					}}
																				>
																					<img
																						src={
																							name.folder_details.id ===
																							folder_id
																								? folderblue
																								: folder
																						}
																						style={{
																							width:
																								name.folder_details.id ===
																								folder_id
																									? "24px"
																									: "29px",
																						}}
																						className="img-fluid"
																						alt="mindreach images"
																					/>
																				</span>
																				<span
																					className="catgory-name"
																					onClick={() => {
																						setDisplaySub(true);
																						handleChange(
																							name.folder_details.encrypted_id,
																							name.path,
																							name.folder_details.id,
																							name.folder_details.id
																						);
																						// setFolderIndex(index);
																						sessionStorage.setItem(
																							"folderIndex",
																							index
																						);
																						// setHideListMenu(true);
																					}}
																				>
																					{name.path}
																					<span className="order-folder--count">
																						({name.imageSettingsCount}/
																						{name.imageCount})
																					</span>
																				</span>
																			</span>
																		</li>
																		{name.path === deleteId && (
																			<div
																				key={index}
																				className="delete--folder-list"
																			>
																				<li
																					style={{
																						margin: "0 0 6px 0",
																						width: "14.5em",
																					}}
																				>
																					<span
																						className={
																							"order-folder-delete--hover"
																						}
																						style={{
																							background: `${background}`,
																						}}
																						onMouseEnter={() =>
																							setBackgroundColor("#ecf1f7")
																						}
																						onMouseLeave={() =>
																							setBackgroundColor("#ffff")
																						}
																					>
																						<span
																							onClick={handleDeleteFolder}
																							className="list-group--select"
																						>
																							<img
																								src={DeleteFolder}
																								className="img-fluid"
																								alt="mindreach images"
																							/>
																						</span>
																						<span
																							className="list-group--delete"
																							style={{ marginLeft: "4px" }}
																						>
																							Delete
																						</span>
																					</span>
																				</li>
																				<li
																					style={{
																						margin: "16px 0px 0px 0px",
																						width: "14.5em",
																						pointerEvents: "none", // Disables pointer events on the element
																						opacity: "0.5", // Optionally reduce opacity to indicate disabled state
																					}}
																				>
																					<span
																						className={
																							"order-folder-rename--hover"
																						}
																						style={{
																							background: `${background_rename}`,
																						}}
																						onMouseEnter={() =>
																							setBackgroundColorRename(
																								"#ecf1f7"
																							)
																						}
																						onMouseLeave={() =>
																							setBackgroundColorRename("#ffff")
																						}
																					>
																						<span
																							// onClick={handleDeleteFolder}
																							className="list-group--select"
																							style={{
																								marginLeft: "21px",
																								marginTop: "8px",
																							}}
																						>
																							<img
																								src={RenameFolder}
																								className="img-fluid"
																								alt="mindreach images"
																							/>
																						</span>
																						<span className="list-group--delete">
																							Rename
																						</span>
																					</span>
																				</li>
																			</div>
																		)}

																		{/* {list_sub_display && */}
																		{folder_list_menu.map((items, idKey) => {
																			const selected =
																				sessionStorage.getItem(
																					"folder_path_sub_left"
																				) === items.path ||
																				(sessionStorage.getItem(
																					"folder_path_sub_left"
																				) === "" &&
																					"" &&
																					isFolderSelected(
																						items.folder_details.encrypted_id,
																						// id[1]
																						folder_sub_path
																					));
																			console.log(
																				" folder selected ",
																				selected
																			);
																			return (
																				<>
																					{name.folder_details.perant_id !==
																						name.folder_details.perant_id &&
																						name.path === folder_path && (
																							<ul
																								className="second-treeview"
																								key={index}
																							>
																								<li
																									className={
																										// isFolderSelected(
																										// 	items.folder_details.encrypted_id,
																										// 	folder_sub_path
																										// )
																										selected
																											? //
																											  "order-folder--hover"
																											: ""
																									}
																									onContextMenu={(e) =>
																										idKey &&
																										handleRightSideMenuOpen(
																											e,
																											name.path,
																											name.path
																										)
																									}
																									onClick={() => {
																										// if (id[0] !== undefined) {
																										// sessionStorage.setItem(
																										// 	"folder_path_sub",
																										// 	items.folder_details.encrypted_id
																										// );
																										handleChangeSubFolder(
																											// items.path,
																											// item.path,
																											// batchId
																											name.folder_details
																												.encrypted_id,
																											name.path,
																											name.path
																										);
																										// setHideListMenu(true);
																										// setDisplaySub(true);
																										// }
																									}}
																								>
																									<span
																										style={{
																											marginLeft: "2.1rem",
																										}}
																									>
																										<i
																											className="fa fa-angle-right"
																											aria-hidden="true"
																										></i>
																										<span>
																											<img
																												src={
																													selected
																														? folderblue
																														: folder
																												}
																												style={{
																													width:
																														// (folder_list_menu.length ||
																														// 	folder_selectNameSub) &&
																														// isFolderSelected(
																														// 	items.path,
																														// 	folder_path
																														// )
																														isFolderSelected(
																															name
																																.folder_details
																																.encrypted_id,
																															folder_sub_path
																														)
																															? "24px"
																															: "29px",
																												}}
																												className="img-fluid"
																												alt="mindreach images"
																											/>
																										</span>
																										<span className="catgory-name">
																											{name.path}
																											<span className="order-folder--count">
																												((
																												{
																													name.imageSettingsCount
																												}
																												/{name.imageCount}))
																											</span>
																										</span>
																									</span>
																								</li>
																								{name.path === deleteId && (
																									<div
																										key={id}
																										className="delete--folder-list"
																									>
																										<li
																											style={{
																												margin: "0 0 6px 0",
																												width: "14.5em",
																											}}
																										>
																											<span
																												className={
																													"order-folder-delete--hover"
																												}
																												style={{
																													background: `${background}`,
																												}}
																												onMouseEnter={() =>
																													setBackgroundColor(
																														"#ecf1f7"
																													)
																												}
																												onMouseLeave={() =>
																													setBackgroundColor(
																														"#ffff"
																													)
																												}
																											>
																												<span
																													onClick={
																														handleDeleteFolder
																													}
																													className="list-group--select"
																												>
																													<img
																														src={DeleteFolder}
																														className="img-fluid"
																														alt="mindreach images"
																													/>
																												</span>
																												<span
																													className="list-group--delete"
																													style={{
																														marginLeft: "4px",
																													}}
																												>
																													Delete
																												</span>
																											</span>
																										</li>
																										<li
																											style={{
																												margin:
																													"16px 0px 0px 0px",
																												width: "14.5em",
																												pointerEvents: "none", // Disables pointer events on the element
																												opacity: "0.5", // Optionally reduce opacity to indicate disabled state
																											}}
																										>
																											<span
																												className={
																													"order-folder-rename--hover"
																												}
																												style={{
																													background: `${background_rename}`,
																												}}
																												onMouseEnter={() =>
																													setBackgroundColorRename(
																														"#ecf1f7"
																													)
																												}
																												onMouseLeave={() =>
																													setBackgroundColorRename(
																														"#ffff"
																													)
																												}
																											>
																												<span
																													// onClick={handleDeleteFolder}
																													className="list-group--select"
																													style={{
																														marginLeft: "21px",
																														marginTop: "8px",
																													}}
																												>
																													<img
																														src={RenameFolder}
																														className="img-fluid"
																														alt="mindreach images"
																													/>
																												</span>
																												<span className="list-group--delete">
																													Rename
																												</span>
																											</span>
																										</li>
																									</div>
																								)}
																							</ul>
																						)}
																					{!!list_display &&
																						items === folder_selectNameSub &&
																						folder_files_sub.map(
																							(items_sub) => {
																								return (
																									<>
																										<ul className="third-treeview">
																											<li>
																												<i
																													className="fa fa-angle-right"
																													aria-hidden="true"
																												></i>
																												<span>
																													<img
																														src={folder}
																														style={{
																															width: "28px",
																														}}
																														className="img-fluid"
																														alt="mindreach images"
																													/>
																												</span>
																												<span className="catgory-name">
																													{items_sub.path.path}
																													<span className="order-folder--count">
																														(
																														{
																															items_sub.path
																																.imageSettingsCount
																														}
																														{
																															items_sub.path
																																.imageCount
																														}
																														)
																													</span>
																												</span>
																											</li>
																										</ul>
																									</>
																								);
																							}
																						)}
																				</>
																			);
																		})}
																	</ul>
																);
															}
														})}
													{checkName &&
														Array.isArray(item_1) &&
														item_1.otherKey_1.length !== 1 &&
														item.folder_details.path !== item.path &&
														item_1.otherKey_1.map((name_1, id) => {
															let check =
																name_1.folder_details.parent_id ===
																item_1.parent_id;

															return (
																<ul className="first-treeview first-treeview--inner">
																	{!check && (
																		<li
																			className={
																				name_1.folder_details.id === folder_id
																					? "order-folder--hover"
																					: ""
																			}

																			// onContextMenu={(e) =>
																			// 	handleRightSideMenuOpen(e, item.path, item.id, "")
																			// }
																		>
																			<span style={{ marginLeft: "1.5rem" }}>
																				<i
																					onClick={() => {
																						// setDisplaySub((val) => !val);
																						// // setHideListMenu((val) => !val);
																						// // handleChangeFolder(
																						// // 	item.folder_details.encrypted_id,
																						// // 	item.path
																						// // );
																						// setFolderIndex(index);
																						handleChange(
																							name_1.folder_details
																								.encrypted_id,
																							name_1.path,
																							name_1.folder_details.parent_id,
																							name_1.folder_details.id
																						);
																						// setDisplaySub(true);
																						sessionStorage.setItem(
																							"folderIndex",
																							index
																						);
																					}}
																					className={
																						list_sub_display
																							? "fa fa-angle-down"
																							: "fa fa-angle-right"
																					}
																					aria-hidden="true"
																				></i>
																				<span
																					onClick={() => {
																						handleChange(
																							name_1.folder_details
																								.encrypted_id,
																							name_1.path,
																							name_1.folder_details.parent_id,
																							name_1.folder_details.id
																						);
																						// setDisplaySub(true);
																						// setFolderIndex(index);
																						sessionStorage.setItem(
																							"folderIndex",
																							index
																						);
																					}}
																				>
																					<img
																						src={
																							name_1.folder_details.id ===
																							folder_id
																								? folderblue
																								: folder
																						}
																						style={{
																							width:
																								sessionStorage.getItem(
																									"folder_path_sub_left"
																								) === item_1.path &&
																								Number(main_folder_click) === 0
																									? "24px"
																									: "29px",
																						}}
																						className="img-fluid"
																						alt="mindreach images"
																					/>
																				</span>
																				<span
																					className="catgory-name"
																					// onClick={() => {
																					// 	setDisplaySub(true);
																					// 	handleChange(
																					// 		item.folder_details.encrypted_id,
																					// 		item.name
																					// 	);
																					// 	// setFolderIndex(index);
																					// 	sessionStorage.setItem("folderIndex", index);
																					// 	// setHideListMenu(true);
																					// }}
																				>
																					{name_1.path}
																					<span className="order-folder--count">
																						({name_1.imageSettingsCount}/
																						{name_1.imageCount})
																					</span>
																				</span>
																			</span>
																		</li>
																	)}
																	{item_1.path === deleteId && (
																		<div
																			key={index}
																			className="delete--folder-list"
																		>
																			<li
																				style={{
																					margin: "0 0 6px 0",
																					width: "14.5em",
																				}}
																			>
																				<span
																					className={
																						"order-folder-delete--hover"
																					}
																					style={{
																						background: `${background}`,
																					}}
																					onMouseEnter={() =>
																						setBackgroundColor("#ecf1f7")
																					}
																					onMouseLeave={() =>
																						setBackgroundColor("#ffff")
																					}
																				>
																					<span
																						onClick={handleDeleteFolder}
																						className="list-group--select"
																					>
																						<img
																							src={DeleteFolder}
																							className="img-fluid"
																							alt="mindreach images"
																						/>
																					</span>
																					<span
																						className="list-group--delete"
																						style={{ marginLeft: "4px" }}
																					>
																						Delete
																					</span>
																				</span>
																			</li>
																			<li
																				style={{
																					margin: "16px 0px 0px 0px",
																					width: "14.5em",
																					pointerEvents: "none", // Disables pointer events on the element
																					opacity: "0.5", // Optionally reduce opacity to indicate disabled state
																				}}
																			>
																				<span
																					className={
																						"order-folder-rename--hover"
																					}
																					style={{
																						background: `${background_rename}`,
																					}}
																					onMouseEnter={() =>
																						setBackgroundColorRename("#ecf1f7")
																					}
																					onMouseLeave={() =>
																						setBackgroundColorRename("#ffff")
																					}
																				>
																					<span
																						// onClick={handleDeleteFolder}
																						className="list-group--select"
																						style={{
																							marginLeft: "21px",
																							marginTop: "8px",
																						}}
																					>
																						<img
																							src={RenameFolder}
																							className="img-fluid"
																							alt="mindreach images"
																						/>
																					</span>
																					<span className="list-group--delete">
																						Rename
																					</span>
																				</span>
																			</li>
																		</div>
																	)}
																</ul>
															);
														})}
													{Array.isArray(item_1) &&
														item_1.otherKey_1.length === 1 &&
														// !checkName &&
														// item_1?.name === item.path &&
														// item?.folder_details?.length === 1 &&
														item_1.otherKey_1.map((name_1, id) => {
															let check = item_1.name.includes(
																name_1.folder_details.folder_name
															);
															console.log(
																"item called rgt",
																name_1.folder_details.folder_name,
																item_1.name
															);
															return (
																<ul className="first-treeview first-treeview--inner">
																	{!check && (
																		<li
																			className={
																				name_1.folder_details.id === folder_id
																					? "order-folder--hover"
																					: ""
																			}

																			// onContextMenu={(e) =>
																			// 	handleRightSideMenuOpen(e, item.path, item.id, "")
																			// }
																		>
																			<span style={{ marginLeft: "1.5rem" }}>
																				<i
																					onClick={() => {
																						// setDisplaySub((val) => !val);
																						// // setHideListMenu((val) => !val);
																						// // handleChangeFolder(
																						// // 	item.folder_details.encrypted_id,
																						// // 	item.path
																						// // );
																						// setFolderIndex(index);
																						handleChange(
																							name_1.folder_details
																								.encrypted_id,
																							name_1.path,
																							name_1.folder_details.parent_id,
																							name_1.folder_details.id
																						);
																						// setDisplaySub(true);
																						sessionStorage.setItem(
																							"folderIndex",
																							index
																						);
																					}}
																					className={
																						list_sub_display
																							? "fa fa-angle-down"
																							: "fa fa-angle-right"
																					}
																					aria-hidden="true"
																				></i>
																				<span
																					onClick={() => {
																						handleChange(
																							name_1.folder_details
																								.encrypted_id,
																							name_1.path,
																							name_1.folder_details.parent_id,
																							name_1.folder_details.id
																						);
																						// setDisplaySub(true);
																						// setFolderIndex(index);
																						sessionStorage.setItem(
																							"folderIndex",
																							index
																						);
																					}}
																				>
																					<img
																						src={
																							name_1.folder_details.id ===
																							folder_id
																								? folderblue
																								: folder
																						}
																						style={{
																							width:
																								sessionStorage.getItem(
																									"folder_path_sub_left"
																								) === item_1.path &&
																								Number(main_folder_click) === 0
																									? "24px"
																									: "29px",
																						}}
																						className="img-fluid"
																						alt="mindreach images"
																					/>
																				</span>
																				<span
																					className="catgory-name"
																					// onClick={() => {
																					// 	setDisplaySub(true);
																					// 	handleChange(
																					// 		item.folder_details.encrypted_id,
																					// 		item.name
																					// 	);
																					// 	// setFolderIndex(index);
																					// 	sessionStorage.setItem("folderIndex", index);
																					// 	// setHideListMenu(true);
																					// }}
																				>
																					{name_1.path}
																					<span className="order-folder--count">
																						({name_1.imageSettingsCount}/
																						{name_1.imageCount})
																					</span>
																				</span>
																			</span>
																		</li>
																	)}
																	{item_1.path === deleteId && (
																		<div
																			key={index}
																			className="delete--folder-list"
																		>
																			<li
																				style={{
																					margin: "0 0 6px 0",
																					width: "14.5em",
																				}}
																			>
																				<span
																					className={
																						"order-folder-delete--hover"
																					}
																					style={{
																						background: `${background}`,
																					}}
																					onMouseEnter={() =>
																						setBackgroundColor("#ecf1f7")
																					}
																					onMouseLeave={() =>
																						setBackgroundColor("#ffff")
																					}
																				>
																					<span
																						onClick={handleDeleteFolder}
																						className="list-group--select"
																					>
																						<img
																							src={DeleteFolder}
																							className="img-fluid"
																							alt="mindreach images"
																						/>
																					</span>
																					<span
																						className="list-group--delete"
																						style={{ marginLeft: "4px" }}
																					>
																						Delete
																					</span>
																				</span>
																			</li>
																			<li
																				style={{
																					margin: "16px 0px 0px 0px",
																					width: "14.5em",
																					pointerEvents: "none", // Disables pointer events on the element
																					opacity: "0.5", // Optionally reduce opacity to indicate disabled state
																				}}
																			>
																				<span
																					className={
																						"order-folder-rename--hover"
																					}
																					style={{
																						background: `${background_rename}`,
																					}}
																					onMouseEnter={() =>
																						setBackgroundColorRename("#ecf1f7")
																					}
																					onMouseLeave={() =>
																						setBackgroundColorRename("#ffff")
																					}
																				>
																					<span
																						// onClick={handleDeleteFolder}
																						className="list-group--select"
																						style={{
																							marginLeft: "21px",
																							marginTop: "8px",
																						}}
																					>
																						<img
																							src={RenameFolder}
																							className="img-fluid"
																							alt="mindreach images"
																						/>
																					</span>
																					<span className="list-group--delete">
																						Rename
																					</span>
																				</span>
																			</li>
																		</div>
																	)}
																</ul>
															);
														})}
												</>
											);
										})}
								</ul>
							))}
					</li>
				</ul>
			</div>
		</>
	);
};

export default RightSidebar;
