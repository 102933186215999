import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
//Importing the image components
import order_settings from "./../../assets/Images/settings-white.svg";
import { VIEW_IMAGE_COORDINATOR_WORKSPACE } from "../../config/routes";
import { currentUserId } from "../../utils/Coordinator/getCurrentUserId";
import { useParams } from "react-router-dom";

const BatchDetailsSidebar = ({
	batchNumber,
	currentUserId,
	batchClick,
	setBatchID,
	batchId,
	setOrderNumber,
	setFolderName,
	timer,
	setTimer,
	elapsedTime,
	setElapsedTime,
	startTimer,
	stopTimer,
	startTime,
	setStartTime,
	handleCustomerLogged,
	activeIndex,
	setHideListMenu,
	setLoaderSideBar,
	...props
}) => {
	// Get the navigation function from the useNavigate hook
	const navigate = useNavigate();
	const { id, batch_id_query, folder_id_query } = useParams();

	//Declarations of state
	const [currentCardIndex, setCurrentCardIndex] = useState(null);

	function formatDateFromISO(isoDateString) {
		const dateObject = new Date(isoDateString);
		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1; // Months are 0-indexed
		const year = dateObject.getUTCFullYear();

		const formattedDate = `${day.toString().padStart(2, "0")}-${month
			.toString()
			.padStart(2, "0")}-${year}`;
		return formattedDate;
	}
	const handleCardClick = (item, index) => {
		if (currentCardIndex !== null) {
			handleCustomerLogged();
			stopTimer(); // Stop the timer for the previous card
		}

		setCurrentCardIndex(index); // Set the current card index
		setElapsedTime(0); // Reset elapsed time
		startTimer(); // Start a new timer for the clicked card
		setStartTime(Date.now()); // Capture the start time

		// Your other logic here
		setBatchID(item.id);
		setOrderNumber(item.order_id);
		setFolderName("");
		let allImages = document.querySelectorAll(".allimages");
		for (var i = 0; i < allImages.length; i++) {
			allImages[i].style.zIndex = 1;
		}
	};

	return (
		<>
			<div className="ordercard coordinator">
				{batchNumber !== undefined &&
					batchNumber.map((item, index) => (
						<Card
							className={item.id === Number(batch_id_query) ? "activecard" : ""}
							key={index}
							onClick={() => {
								props.resetActiveIndex(item, item.id, item.batch_number);
								navigate(
									`${VIEW_IMAGE_COORDINATOR_WORKSPACE}/${currentUserId}/${item.id}`
								);

								props.setDisplaySub(false);
								setBatchID(item.id);
								sessionStorage.setItem("batch_id", item.id);
								setLoaderSideBar(true);
								// props.setFolderListMenu([]);
								// props.setFolderList([]);
								batchClick(item.id, currentUserId);
								sessionStorage.setItem("main", 1);
								// props.fetchFolder();

								// if (item.id !== activeIndex) {
								// 	setHideListMenu(true);
								// }
							}}
						>
							<Card.Header>
								<div className="d-sm-flex flex-sm-wrap justify-content-between align-items-center">
									<div className="">
										<p className="ordertitle ">Batch ID: {item.batch_number}</p>
										<p className="ordertitle">
											Order ID: {item.order_id}
											<br />
											{item.customer.full_name}
										</p>
									</div>
									<div className="">
										<p className="orderdate">
											{item.updated_at !== null &&
												formatDateFromISO(item.updated_at)}
										</p>
									</div>
								</div>
								{item.order.image_upload_type === "1" && (
									<div class="client-order-settings">
										<img
											src={order_settings}
											className="right_img_corner"
											alt="mindreach images"
										/>
									</div>
								)}
							</Card.Header>
							<Card.Body className="ml-2">
								<div className="order-details">
									<p>Image Count: {item.count}</p>
									<p>
										Status: <span className="orange">{item.status}</span>
									</p>
								</div>
								<Link>
									<p className="viewguide">View Style Guide</p>
								</Link>
							</Card.Body>
						</Card>
					))}
			</div>
		</>
	);
};

export default BatchDetailsSidebar;
