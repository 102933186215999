import React from "react";

export const svgCode = (count) => {
	return `
<svg 
  width="28" 
  height="23" 
  viewBox="0 0 20 20" 
  fill="none" 
  xmlns="http://www.w3.org/2000/svg"
>
  <path 
    fill-rule="evenodd" 
    clip-rule="evenodd" 
    d="M6 4.5V0H10.5C10.8978 0 11.2794 0.158035 11.5607 0.43934C11.842 0.720644 12 1.10218 12 1.5V3H9L3 9V16H1.5C1.10218 16 0.720644 15.842 0.43934 15.5607C0.158035 15.2794 0 14.8978 0 14.5V6H4.5C4.89782 6 5.27936 5.84196 5.56066 5.56066C5.84196 5.27936 6 4.89782 6 4.5ZM5 4.5V0.25L0.25 5H4.5C4.63261 5 4.75979 4.94732 4.85355 4.85355C4.94732 4.75979 5 4.63261 5 4.5Z" 
    fill="white" 
    stroke="#474747" 
    stroke-width="1"
  />
  <path 
    d="M10 4V8.5C10 8.89782 9.84196 9.27936 9.56066 9.56066C9.27936 9.84196 8.89782 10 8.5 10H4V18.5C4 18.8978 4.15804 19.2794 4.43934 19.5607C4.72064 19.842 5.10218 20 5.5 20H14.5C14.8978 20 15.2794 19.842 15.5607 19.5607C15.842 19.2794 16 18.8978 16 18.5V5.5C16 5.10218 15.842 4.72064 15.5607 4.43934C15.2794 4.15804 14.8978 4 14.5 4H10" 
    fill="white" 
    stroke="#474747" 
    stroke-width="1"
  />
  <style>
    text {
      font-size: 8px;
      font-weight: 500;
    }
  </style>
  <text 
    x="6" 
    y="18" 
    fill="green"
  >
    ${count}
  </text>
</svg>
`;
};
