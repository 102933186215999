import React, { useState, useEffect, useRef, useCallback } from "react";

//Importing the bootstrap components
import { Button, Row, Col, Form, Tab, Tabs, Dropdown } from "react-bootstrap";
//Importing the react select components
import Select from "react-select";
import Marker from "./Marker";
import Loader from "./../../../../components/Loader";
import DeleteModalConfirmation from "./../../../../components/Modal/DeleteModal";
import ToastAlert from "../../../../components/ToastAlert";

//Importing the images
import cross from "./../../../../assets/Images/cross-3.svg";
import PreviousIcon from "./../../../../assets/Images/coordinator_actions_icon/prev-icons.svg";
import EditImageOriginal from "./../../../../assets/Images/coordinator_actions_icon/Edit_Original.svg";
import NextIcon from "./../../../../assets/Images/coordinator_actions_icon/next-icons.svg";
import duplicate from "./../../../../assets/Images/coordinator_actions_icon/Doc_Duplicate.svg";
import Pencil from "./../../../../assets/Images/coordinator_actions_icon/pencil_edit.svg";
import DeleteIcon from "./../../../../assets/Images/coordinator_actions_icon/white_delete.svg";
import BlackDelete from "./../../../../assets/Images/coordinator_actions_icon/black_delete.svg";
import BlackEdit from "./../../../../assets/Images/coordinator_actions_icon/pencil_edit.svg";

import ChatIcons from "./../../../../assets/Images/coordinator_actions_icon/Chat.svg";
import BlueChatIcon from "./../../../../assets/Images/coordinator_actions_icon/BlueChatIcon.svg";

import CancelIcons from "./../../../../assets/Images/coordinator_actions_icon/cancel_image.svg";
import Send from "./../../../../assets/Images/coordinator_actions_icon/Send.svg";
import ArrowDown from "./../../../../assets/Images/coordinator_actions_icon/White_Arrow_Down.svg";
import RenameModal from "../RenameModal";
import SettingsModal from "../../../../components/Modal/StatusModal";

// Importing API related files and routes

import { client } from "./../../../../data/API/GobalClientAxiosRequest";
import { imageURL, apiURL } from "../../../../data/filepath";

import {
	CO_ORDINATOR_VIEW_PROFILE_SETTINGS,
	CO_ORDINATOR_VIEW_EDIT_GROUP,
	CO_ORDINATOR_UPDATE_EDIT_GROUP,
	CO_ORDINATOR_DUPLICATE_IMAGE_RENAME,
	CO_ORDINATOR_DELETE_DUPLICATE_IMAGE,
	CO_ORDINATOR_ORIGINAL_IMAGE_RENAME,
	CO_ORDINATOR_ASSIGNED_TO_EDITOR,
	ADMIN_GET_COORDINATORS_DETAILS,
} from "./../../../../config/apiRoutes.api";

// Check all the status of editor assigned
const editorWorkingStatus = [
	"assign_to_editor",
	"assigned",
	"started",
	"hold",
	"qc",
	"error",
	"completed",
	"finished",
	"reassigned",
	"temporary-reassigned",
	"temporary-completed",
];

const customSelectStyles = {
	menuList: (provided) => {
		return {
			...provided,
			"& :first-child": {
				fontWeight: "bold",
			},
		};
	},
};
const Modal = ({
	showModal,
	setShowModal,
	image_details_view,
	setImageDetailsView,
	editGroups,
	editTypes,
	customer_id,
	setMultipleImagesId,
	selectImageName,
	token,
	id,
	idSelected,
	setClickSelect,
	setSelectAll,
	customer_status,
	images,
	modalRef,
	setting_profile,
	handleFetchProfileSettings,
	setFolderListName,
	setFolderSelect,
	setFoldersPath,
	setDuplicateImageModal,
	setDuplicateAmount,
	setAmount,
	setImageID,
	imageID,
	setParentFolderDetails,
	setFolderName,
	setBatchNumber,
	setImages,
	setFolderList,
	setFolder,
	setImageMainFolder,
	setFolderMainFolder,
	setMainImageCount,
	setMainImageSettingsCount,
	setOrderNumber,
	setSubFolderOpen,
	setDisplay,
	setClickSelectParent,
	multiple_images_id,
	statusNew,
	setImageEditorResponse,
	setStatusModal,
	batchId,
	setStatusNew,
	...props
}) => {
	// Create a FormData object for request payload
	const bodyFormData = new FormData();

	const roleJson = sessionStorage.getItem("role");

	const role = roleJson ? JSON.parse(roleJson) : null;
	const currentUserRoles = [role !== null ? role.role : "login"];
	const currentUserId = [role !== null ? role.pivot.user_id : ""];

	// Create an HTTP client with the token
	const request = client(token);
	const modalRefImage = useRef(null);

	//Declarations of state
	const [isEditorAssigend, setEditorAssigned] = useState(false);
	const [viewDuplicateModal, setViewDuplicateModal] = useState(false);
	const [duplicateImageId, setDuplicateImageId] = useState("");
	const [error, setError] = useState("");
	const [status, setStatus] = useState("");
	const [loader, setLoader] = useState(false); //Loader State
	const [groupSelect, setGroupSelect] = useState(false);
	const [checked, setChecked] = useState(false);
	const [checked1, setChecked1] = useState(false);
	const [checked2, setChecked2] = useState(false);
	const [checked3, setChecked3] = useState(false);
	const [selected, setSelected] = useState(false);
	const [selected1, setSelected1] = useState(false);
	const [selected2, setSelected2] = useState(false);
	const [selected3, setSelected3] = useState(false);
	const [customizedSettings, customizedSettingsEnabled] = useState(false);
	const [edit_select, setEditorType] = useState("");
	const [edit_id, setEditId] = useState([]);
	const [previousSelectedValue, setPreviousSelectedValue] = useState("");
	const [currentAnnotation, setCurrentAnnotation] = useState(null);
	const [annotations, setAnnotations] = useState([]);
	const [edit_type_toggle, setToggleEditType] = useState(false);
	//To update the select group
	const [group_name_select, setEditGroupNameSelect] = useState("");
	const [imageExtensions, setImageExtensions] = useState("");
	const [imageName, setImageName] = useState("");
	// To set the state for add
	const [background_removal_color_type, setBackgroundRemovalColorType] =
		useState("");
	const [background_removal_color_code, setBackgroundRemovalColorCode] =
		useState("");
	const [background_adjustment_color_code, setBackgroundAdjustmentColorCode] =
		useState("");
	const [background_adjustment_color_type, setBackgroundAdjustmentColorType] =
		useState("");
	const [view_edit_group, setViewEditGroup] = useState({});
	const [group_name, setGroupName] = useState("");
	const [new_edit_group_name, setNewEditGroupName] = useState("");
	const [toggleMessage, setToggleMessage] = useState(false);
	// const [edit_select, setEditorType] = useState("");
	// const [edit_group_name_select, setEditGroupNameSelect] = useState("");
	const [resizing_file_format, setResizingFileFormat] = useState("");
	const [resizing_height, setResizingHeight] = useState("");
	const [resizing_width, setResizingWidth] = useState("");
	const [resizing_dpi, setResizingDpi] = useState("");
	const [resizing_dpi_type, setResizingDpiType] = useState("");
	const [resizing_color_mode, setResizingColorMode] = useState("");
	const [resizing_alignment, setResizingAlignment] = useState("");
	const [resizing_margin_format, setResizingMarginFormat] = useState("");
	const [resizing_margin_top, setResizingMarginTop] = useState("");
	const [resizing_margin_bottom, setResizingMarginBottom] = useState("");
	const [resizing_margin_right, setResizingMarginRight] = useState("");
	const [resizing_margin_left, setResizingMarginLeft] = useState("");
	const [edit_group_name, setEditGroupName] = useState("");
	const [edit_type_ids, setEditTypeId] = useState("");
	const [image_file_format, setImageFileFormat] = useState("");
	const [image_height, setImageHeight] = useState("");
	const [image_width, setImageWidth] = useState("");
	const [image_dpi, setImageDpi] = useState("");
	const [image_dpi_type, setImageDpiType] = useState("");
	const [image_color_mode, setImageColorMode] = useState("");
	const [image_alignment, setImageAlignment] = useState("");
	const [image_margin_format, setImageMarginFormat] = useState("");
	const [image_margin_top, setImageMarginTop] = useState("");
	const [image_margin_bottom, setImageMarginBottom] = useState("");
	const [image_margin_right, setImageMarginRight] = useState("");
	const [image_margin_left, setImageMarginLeft] = useState("");
	const [group, setGroup] = useState([]);
	const [message, setMessage] = useState("");
	const [comments, setComments] = useState([]);
	const [isDropdownOpen, setDropdownOpen] = useState(false);
	const [renameModalShow, setRenameModalShow] = useState(false);
	const [renameTextDuplicate, setRenameTextDuplicate] = useState("");
	const [renameId, setRenameId] = useState("");
	const [showModalDelete, setShowModalDelete] = useState(false);
	const [hoveredItems, setHoveredItems] = useState(false);
	const [hoveredItemId, setHoveredItemId] = useState(null);
	const [showCommentList, setCommentsList] = useState(false);
	const [annotationsComments, setAnnotationsComments] = useState([]);
	const [previousImageSelected, previousImageID] = useState("");

	const handleMouseEnter = (itemId) => {
		// Set the ID of the item to be hovered
		setHoveredItemId(itemId);
	};

	const handleMouseLeave = () => {
		// Clear the hovered item when leaving the element
		setHoveredItemId(null);
	};

	const toggleDropdown = () => {
		setDropdownOpen(!isDropdownOpen);
	};

	/**
	 * Transform an array of customer edit types into an array of objects with label, value, and additional properties.
	 * @param {Array} customerEditTypes - Array of customer edit types.
	 * @returns {Array} - Transformed array of objects with label, value, and additional properties.
	 */
	const selectLabel = (customerEditTypes) =>
		customerEditTypes.map(({ group_name: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of array as label and value return a new array of object like [{value:"",label:""}]

	function LocalImageLoader({ src, width, quality }) {
		return <Loader />;
	}
	const file = [
		{ label: "JPEG", value: 355 },
		{ label: "PNG", value: 54 },
		{ label: "JPG", value: 54 },
	];
	const selectBackgrondRemoval = [
		{ label: "Custom", value: 355 },
		{ label: "Transparent", value: 54 },
	];
	const selectedAmount = [
		{ label: "1", value: 355 },
		{ label: "2", value: 54 },
		{ label: "3", value: 54 },
	];
	const dpi = [
		{ label: "JPEG", value: 355 },
		{ label: "PNG", value: 54 },
		{ label: "JPG", value: 54 },
	];

	const ColorMode = [
		{ label: "Original", value: 355 },
		{ label: "Edited", value: 54 },
	];
	const Align = [
		{ label: "center", value: 355 },
		{ label: "start", value: 54 },
		{ label: "end", value: 55 },
	];

	const Margin = [
		{ label: "pixel", value: 355 },
		{ label: "em", value: 54 },
		{ label: "rem", value: 55 },
		{ label: "%", value: 56 },
	];
	const handleTextChange = (updatedText) => {
		if (currentAnnotation) {
			setCurrentAnnotation({
				...currentAnnotation,
				text: updatedText,
			});

			// Create a copy of annotations with the updated text
			const updatedAnnotations = annotations.map((annotation) =>
				annotation === currentAnnotation
					? { ...annotation, text: updatedText }
					: annotation
			);

			setAnnotations(updatedAnnotations);
		}
	};
	/**
	 * Handle the selection of checkboxes and update the `editId` state accordingly.
	 * @param {ChangeEvent} changeEvent - The change event object.
	 */
	const handleAddCheckboxSelect = (changeEvent) => {
		changeEvent.persist();
		setEditId((prevState) => {
			const editItem = changeEvent.target.value;

			if (prevState.includes(editItem)) {
				// If the selected editItem is already in the prevState array,
				// remove it from the array and return the updated array.
				return prevState.filter((el) => el !== editItem);
			}
			// If the selected editItem is not in the prevState array,
			// add it to the array and return the updated array.
			return [...prevState, editItem];
		});
	};
	
	/**
	 * Handles to check the option value has changed and reset the state for all the checkboxes.
	 * @param {Number} id - The id to determine the checkbox dependent field which to reset.
	 * @returns {void}
	 */
	const resetFields = (id)=>{
		if(id===1) {
			setBackgroundRemovalColorType("")
			setBackgroundRemovalColorCode("")
		} else if(id===2) {
			setBackgroundAdjustmentColorCode("")
			setBackgroundAdjustmentColorType("")
		} else if(id===3){
			setResizingFileFormat("")
			setResizingHeight("")
			setResizingWidth("")
			setResizingDpi("")
			setResizingDpiType("")
			setResizingColorMode("")
			setResizingAlignment("")
			setResizingMarginFormat("")
			setResizingMarginTop("")
			setResizingMarginBottom("")
			setResizingMarginRight("")
			setResizingMarginLeft("")
		}
	}

	/* Handle the checkbox state and perform actions based on the given id.
	* @param {Number} id - The id to determine the checkbox action.
	* @param {Boolean} checked - The checked/unchecked after action of checkbox clicked.
	*/
	const handleBackGroundRemovalCheckbox = (id,checked=false) => {
		if (id === 1) {
			setSelected(checked);
			setChecked((val) => !val);
		}
		if (id === 2) {
			setSelected1(checked);
			setChecked1((val) => !val);
		}
		if (id === 3) {
			setSelected2(checked);
			setChecked2((val) => !val);
		}
		if (id === 6 || id === 15) {
			setSelected3(checked);
			setChecked3((val) => !val);
		}
		// if(action!=="Edit"){
			resetFields(id);
		// }
	};
	/**
	 * Handles to get all the checked values of checkbox by class name.
	 * @returns {void}
	 */
	const getEditTypeValues = () => {
		const checkedValues = document.querySelectorAll(".et_options");
		const checkedValue = [];
		checkedValues.forEach((element) => {
			if (element.checked) {
				checkedValue.push(element.value);
			}
		});
		return checkedValue.length > 0 ? checkedValue.join(",") : "";
	};
	
	/**
	 * Handles the update of an image edit group by sending a POST request to the server.
	 * @returns {void}
	 */
	const handleUpdateImageEditGroup = () => {
		if (token) {
			setLoader(true);
			// Append form data with the necessary parameters
			const editTypeId = getEditTypeValues();
			bodyFormData.append("customer_id", customer_id);
			bodyFormData.append(
				"image_ids",
				image_details_view !== undefined &&
					JSON.stringify(image_details_view.id)
			);

			if (edit_select > 0) {
				bodyFormData.append("edit_group_id", edit_select);
			} else {
				bodyFormData.append(
					"extra_edit_type_ids",
					group_name_select === "New Settings" ||
						group_name_select === "Customized Settings"
						? editTypeId
						: ""
				);
			}

			bodyFormData.append("is_overright", 1);
			bodyFormData.append(
				"background_removal_color_type",
				background_removal_color_type
			);
			bodyFormData.append(
				"background_removal_color_code",
				background_removal_color_code
			);
			bodyFormData.append(
				"background_adjustment_color_type",
				background_adjustment_color_type
			);
			bodyFormData.append(
				"background_adjustment_color_code",
				background_adjustment_color_code
			);
			bodyFormData.append("resizing_height", resizing_height);
			bodyFormData.append("resizing_width", resizing_width);
			{
				/* We might require this code again in future keep it commented */
			}
			// bodyFormData.append("resizing_file_format", resizing_file_format);
			// bodyFormData.append("resizing_dpi", resizing_dpi);
			// bodyFormData.append("resizing_dpi_type", resizing_dpi_type);
			// bodyFormData.append("resizing_color_mode", resizing_color_mode);
			// bodyFormData.append("resizing_alignment", resizing_alignment);
			// bodyFormData.append("resizing_margin_format", resizing_margin_format);
			// bodyFormData.append("resizing_margin_top", resizing_margin_top);
			// bodyFormData.append("resizing_margin_bottom", resizing_margin_bottom);
			// bodyFormData.append("resizing_margin_right", resizing_margin_right);
			// bodyFormData.append("resizing_margin_left", resizing_margin_left);
			bodyFormData.append("image_file_format", image_file_format);
			bodyFormData.append("image_height", image_height);
			bodyFormData.append("image_width", image_width);
			bodyFormData.append("image_dpi", image_dpi);
			bodyFormData.append("image_dpi_type", image_dpi_type);
			bodyFormData.append("image_color_mode", image_color_mode);
			bodyFormData.append("image_alignment", image_alignment);
			bodyFormData.append("image_margin_format", image_margin_format);
			bodyFormData.append("image_margin_top", image_margin_top);
			bodyFormData.append("image_margin_bottom", image_margin_bottom);
			bodyFormData.append("image_margin_right", image_margin_right);
			bodyFormData.append("image_margin_left", image_margin_left);

			request
				.post(apiURL + CO_ORDINATOR_UPDATE_EDIT_GROUP, bodyFormData)
				.then((response) => {
					//Set the success from the response

					setStatus("Successfully Group updated");
					setLoader(false);
					ToastAlert({
						title: response.data.status ? "Success" : "Error",
						message: response.data.status ? "Settings saved" : "Error",
						autoClose: 2000,
						messageType: response.data.status ? "success" : "error",
					});

					if (response.data.status) {
						request
							.get(
								apiURL +
									ADMIN_GET_COORDINATORS_DETAILS +
									`/${currentUserId}` +
									`?batch_number=${batchId}`
							)
							.then((response) => {
								setParentFolderDetails(null);
								setFolderName("");
								// setEditGroups(response.data.data.editGroups);
								// setEditTypes(response.data.data.editTypes);
								setBatchNumber(response.data.data.batchNumbers);
								setImages(response.data.data.images);
								setFolderList(response.data.data.folders);
								setFolder(response.data.data.folders);
								setImageMainFolder(response.data.data.images);
								setFolderMainFolder(response.data.data.folders);
								setMainImageCount(response.data.data.mainImageCount);
								setMainImageSettingsCount(
									response.data.data.mainImageSettingsCount
								);
								setOrderNumber(response.data.data.batchNumbers[0].order_id);
								setSubFolderOpen(false); // Clear subFolderDetails as we are at the parent level
								setDisplay(false);
								setMultipleImagesId([]);
								setClickSelect(false);
							})
							.catch((err) => console.log(err));
					}
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	const handleUpdateEditGroup = () => {
		if (token) {
			setLoader(true);
			// Append form data with the necessary parameters
			const editTypeId = getEditTypeValues();
			bodyFormData.append("customer_id", customer_id);
			bodyFormData.append(
				"image_ids",
				image_details_view !== undefined &&
					JSON.stringify(image_details_view.id)
			);
			bodyFormData.append("is_overright", 1);
			bodyFormData.append("extra_edit_type_ids", editTypeId);

			bodyFormData.append(
				"background_removal_color_type",
				background_removal_color_type
			);
			bodyFormData.append(
				"background_removal_color_code",
				background_removal_color_code
			);
			bodyFormData.append(
				"background_adjustment_color_type",
				background_adjustment_color_type
			);
			bodyFormData.append(
				"background_adjustment_color_code",
				background_adjustment_color_code
			);
			bodyFormData.append("resizing_height", resizing_height);
			bodyFormData.append("resizing_width", resizing_width);
			{
				/* We might require this code again in future keep it commented */
			}
			// bodyFormData.append("resizing_file_format", resizing_file_format);
			// bodyFormData.append("resizing_dpi", resizing_dpi);
			// bodyFormData.append("resizing_dpi_type", resizing_dpi_type);
			// bodyFormData.append("resizing_color_mode", resizing_color_mode);
			// bodyFormData.append("resizing_alignment", resizing_alignment);
			// bodyFormData.append("resizing_margin_format", resizing_margin_format);
			// bodyFormData.append("resizing_margin_top", resizing_margin_top);
			// bodyFormData.append("resizing_margin_bottom", resizing_margin_bottom);
			// bodyFormData.append("resizing_margin_right", resizing_margin_right);
			// bodyFormData.append("resizing_margin_left", resizing_margin_left);
			bodyFormData.append("image_file_format", image_file_format);
			bodyFormData.append("image_height", image_height);
			bodyFormData.append("image_width", image_width);
			bodyFormData.append("image_dpi", image_dpi);
			bodyFormData.append("image_dpi_type", image_dpi_type);
			bodyFormData.append("image_color_mode", image_color_mode);
			bodyFormData.append("image_alignment", image_alignment);
			bodyFormData.append("image_margin_format", image_margin_format);
			bodyFormData.append("image_margin_top", image_margin_top);
			bodyFormData.append("image_margin_bottom", image_margin_bottom);
			bodyFormData.append("image_margin_right", image_margin_right);
			bodyFormData.append("image_margin_left", image_margin_left);

			request
				.post(apiURL + CO_ORDINATOR_UPDATE_EDIT_GROUP, bodyFormData)
				.then((response) => {
					//Set the success from the response

					setStatus("Successfully Group updated");
					setLoader(false);
					ToastAlert({
						title: response.data.status ? "Success" : "Error",
						message: response.data.status ? "Settings saved" : "Error",
						autoClose: 2000,
						messageType: response.data.status ? "success" : "error",
					});
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	useEffect(() => {
		if (idSelected) {
			sessionStorage.setItem("selectImageID", idSelected);
			setEditorAssigned(false);
		}
	}, [idSelected]);
	useEffect(() => {
		if (editGroups !== undefined) {
			// Create a new array by combining existing group values and the new object
			const updatedGroup = [
				{ id: "New Settings", group_name: "New Settings" },
				...editGroups,
			];
			setGroup(updatedGroup);
			setSelected(false);
			setSelected1(false);
			setSelected3(false);
		}
	}, [editGroups]);

	useEffect(() => {
		// Fetch data from the API endpoint
		if (edit_select > 0 ) {
			request
				.get(apiURL + CO_ORDINATOR_VIEW_EDIT_GROUP + `/${edit_select}`)
				.then((response) => {
					//Set View  for edit group from the response
					let data = response.data;
					setViewEditGroup(data);
					setBackgroundRemovalColorCode(
						data.edit_group.background_settings[0].color_code
					);
					setBackgroundAdjustmentColorCode(
						data.edit_group.background_settings[1].color_code
					);
					setBackgroundRemovalColorType(
						data.edit_group.background_settings[0] !== undefined
							? data.edit_group.background_settings[0].color_type
							: "Select Color Type"
					);
					setBackgroundAdjustmentColorType(
						data.edit_group.background_settings[1] !== undefined
							? data.edit_group.background_settings[1].color_type
							: "Select color type"
					);
					setResizingFileFormat(
						data.edit_group.resizing_settings[0].file_format !== undefined
							? data.edit_group.resizing_settings[0].file_format
							: "Select File Format"
					);
					setResizingDpiType(
						data.edit_group.resizing_settings[0] !== undefined
							? data.edit_group.resizing_settings[0].dpi_type
							: "Select File Format"
					);
					setResizingColorMode(
						data.edit_group.resizing_settings[0] !== undefined
							? data.edit_group.resizing_settings[0].color_mode
							: "Select File Format"
					);
					setResizingAlignment(
						data.edit_group.resizing_settings[0] !== undefined
							? data.edit_group.resizing_settings[0].alignment
							: "Select File Format"
					);
					setResizingMarginFormat(
						data.edit_group.resizing_settings[0] !== undefined
							? data.edit_group.resizing_settings[0].margin_format
							: "Select File Format"
					);
					setImageFileFormat(
						data.edit_group.setting.file_format !== undefined
							? data.edit_group.setting.file_format
							: "Select file format"
					);
					setImageDpiType(
						data.edit_group.setting.dpi_type !== null
							? data.edit_group.setting.dpi_type
							: "Select Dpi type"
					);
					setImageColorMode(
						data.edit_group.setting !== null
							? data.edit_group.setting.color_mode
							: "Select color mode"
					);
					setImageAlignment(
						data.edit_group.setting !== null
							? data.edit_group.setting.alignment
							: "Select Align"
					);
					setImageMarginFormat(
						data.edit_group.setting !== null
							? data.edit_group.setting.margin_format
							: "Margin"
					);
					if (data.edit_group.background_settings.length) {
						setSelected(true);
						setSelected1(true);
					}
					if (data.edit_group.resizing_settings.length) {
						setSelected2(true);
					}
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		} else {
			if (!customizedSettings) {
				setViewEditGroup({});
				setBackgroundRemovalColorCode("");
				setBackgroundAdjustmentColorCode("");
				setBackgroundRemovalColorType("");
				setBackgroundAdjustmentColorType("");
				setResizingFileFormat("");
				setResizingDpiType("");
				setResizingColorMode("");
				setResizingAlignment("");
				setResizingMarginFormat("");
				setImageFileFormat("");
				setImageDpiType("");
				// setImageColorMode(
				// 	data.edit_group.setting !== null
				// 		? data.edit_group.setting.color_mode
				// 		: "Select color mode"
				// );
				// setImageAlignment(
				// 	[]
				// );
				// setImageMarginFormat(
				// 	[]
				// );
			}
		}
	}, [edit_select, token]);

	useEffect(() => {
		if (
			setting_profile &&
			setting_profile.id > 0 &&
			setting_profile.edit_group_id === null && 
			setting_profile.extra_edit_types
		) {
			// Create a new array by combining existing group values and the new object
			if(editGroups || props.editGroups){
				const copygGroup = editGroups ? editGroups : props.editGroups;
				const updatedGroup = [
					{ id: "Customized Settings", group_name: "Customized Settings" },
					{ id: "New Settings", group_name: "New Settings" },
					...copygGroup
				];
				setGroup(updatedGroup);
			}
			
			setEditGroupNameSelect("Customized Settings");
			setEditorType("Customized Settings");
			setViewEditGroup(setting_profile);
			if (setting_profile && setting_profile.extra_edit_types && setting_profile.extra_edit_types.indexOf("1") >= 0) {
				setSelected(true);
			} else {
				setSelected(false);
			}
			if (setting_profile && setting_profile.extra_edit_types && setting_profile.extra_edit_types.indexOf("2") >= 0) {
				setSelected1(true);
			} else {
				setSelected1(false);
			}
			if (setting_profile && setting_profile.extra_edit_types && setting_profile.extra_edit_types.indexOf("3") >= 0) {
				setSelected2(true);
			} else {
				setSelected2(false);
			}
			if (
				setting_profile && setting_profile.extra_edit_types && 
				(setting_profile.extra_edit_types.indexOf("6") >= 0 ||
				setting_profile.extra_edit_types.indexOf("15") >= 0)
			) {
				setSelected3(true);
			} else{
				setSelected3(false);
			}
			customizedSettingsEnabled(true);
			setBackgroundRemovalColorCode(
				setting_profile.background_settings[0] &&
					setting_profile.background_settings[0].edit_type_id === 1
					? setting_profile.background_settings[0].color_code
					: ""
			);
			setBackgroundAdjustmentColorCode(
				setting_profile.background_settings[1] &&
					setting_profile.background_settings[1].edit_type_id === 2
					? setting_profile.background_settings[1].color_code
					: ""
			);
			setBackgroundRemovalColorType(
				setting_profile.background_settings[0] &&
					setting_profile.background_settings[0].edit_type_id === 1
					? setting_profile.background_settings[0].color_type
					: ""
			);
			setBackgroundAdjustmentColorType(
				setting_profile.background_settings[1] &&
					setting_profile.background_settings[1].edit_type_id === 2
					? setting_profile.background_settings[1].color_type
					: ""
			);
			// setResizingFileFormat("");
			// setResizingDpiType("");
			// setResizingColorMode("");
			// setResizingAlignment("");
			// setResizingMarginFormat("");
			// setImageFileFormat("");
			// setImageDpiType("");
			setResizingHeight(
				setting_profile.resizing_settings[0] &&
					setting_profile.resizing_settings[0].height
					? setting_profile.resizing_settings[0].height
					: ""
			);
			setResizingWidth(
				setting_profile.resizing_settings[0] &&
					setting_profile.resizing_settings[0].width
					? setting_profile.resizing_settings[0].width
					: ""
			);
			// Output specification fields
			setImageFileFormat(
				setting_profile.file_format ? setting_profile.file_format : ""
			);
			setImageHeight(setting_profile.height ? setting_profile.height : "");
			setImageWidth(setting_profile.width ? setting_profile.width : "");
			setImageDpi(setting_profile.dpi ? setting_profile.dpi : "");
			setImageDpiType(setting_profile.dpi_type ? setting_profile.dpi_type : "");
			setImageColorMode(
				setting_profile.color_mode ? setting_profile.color_mode : ""
			);
			setImageAlignment(
				setting_profile.alignment ? setting_profile.alignment : ""
			);
			setImageMarginFormat(
				setting_profile.margin_format ? setting_profile.margin_format : ""
			);
			setImageMarginTop(
				setting_profile.margin_top ? setting_profile.margin_top : ""
			);
			setImageMarginBottom(
				setting_profile.margin_bottom ? setting_profile.margin_bottom : ""
			);
			setImageMarginRight(
				setting_profile.margin_right ? setting_profile.margin_right : ""
			);
			setImageMarginLeft(
				setting_profile.margin_left ? setting_profile.margin_left : ""
			);
		} 
	}, [setting_profile]);

	useEffect(() => {
		if (
			image_details_view !== undefined &&
			image_details_view.status !== undefined &&
			// image_details_view.status === "assign_to_editor" &&
			image_details_view.edit_group_id !== null
			// images !== undefined
		) {
			setEditorType(image_details_view.edit_group_id);
		} else {
			setViewEditGroup({});
			setBackgroundRemovalColorCode("");
			setBackgroundAdjustmentColorCode("");
			setBackgroundRemovalColorType("");
			setBackgroundAdjustmentColorType("");
			setResizingFileFormat("");
			setResizingDpiType("");
			setResizingColorMode("");
			setResizingAlignment("");
			setResizingMarginFormat("");
			setImageFileFormat("");
			setImageDpiType("");
			// setImageColorMode(
			// 	data.edit_group.setting !== null
			// 		? data.edit_group.setting.color_mode
			// 		: "Select color mode"
			// );
			// setImageAlignment(
			// 	[]
			// );
			// setImageMarginFormat(
			// 	[]
			// );
		}
	}, [image_details_view, setEditorType, setViewEditGroup]);
	

	/**
	 * Handles to check the option value has changed and reset the state for all the checkboxes.
	 * @returns {void}
	*/

	useEffect(() => {
		if (edit_select > 0) {
			customizedSettingsEnabled(false);
		} else {
			if (!customizedSettings) {
				setSelected(false);
				setSelected1(false);
				setSelected2(false);
				setSelected3(false);
			}
			const et_head = document.getElementById("et_head");
			if(et_head) {
				et_head.scrollIntoView();
			}
		}
	}, [edit_select]);

	/**
	 * Create an array of checked values for the edit_types in view_edit_group
	 */
	function getEditTypesIds(view_edit_group) {
		if (
			view_edit_group &&
			view_edit_group.edit_group &&
			view_edit_group.edit_group.edit_types &&
			Array.isArray(view_edit_group.edit_group.edit_types)
		) {
			return view_edit_group.edit_group.edit_types.map((items, index) => {
				return items.id;
			});
		} else {
			// Handle the case where any of the properties is null or not an array
			// You can return a default value or handle the error appropriately.
			return []; // Returning an empty array as a default value
		}
	}

	// Usage
	let updateChecked = getEditTypesIds(view_edit_group);

	const toggleModal = () => {
		setShowModal(!showModal);
	};
	// const fetchComments = () => {
	// 	if (token) {
	// 		request
	// 			.get(apiURL + CUSTOMER_COMMENTS_MESSAGES + `/${image_details_view.id}`)
	// 			.then((response) => {
	// 				//Set the success from the response

	// 				setComments(response.data.data);
	// 			})
	// 			.catch((err) => {
	// 				let message =
	// 					typeof err.response !== "undefined"
	// 						? err.response.data.message
	// 						: err.message;
	// 				setError(message);
	// 			});
	// 	}
	// };
	// useEffect(() => {
	// 	fetchComments();
	// }, [image_details_view.id]);
	// const SendMessageAsaCustomer = () => {
	// 	if (token) {
	// 		bodyFormData.append("image_id", image_details_view.id);
	// 		bodyFormData.append("comment", message);

	// 		request
	// 			.post(apiURL + CUSTOMER_SEND_MESSAGES, bodyFormData)
	// 			.then((response) => {
	// 				//Set the success from the response

	// 				setStatus("Successfully Group updated");
	// 				fetchComments();
	// 				setMessage("");
	// 			})
	// 			.catch((err) => {
	// 				let message =
	// 					typeof err.response !== "undefined"
	// 						? err.response.data.message
	// 						: err.message;
	// 				setError(message);
	// 			});
	// 	}
	// };
	function parseDateString(dateString) {
		const dateObj = new Date(dateString);

		const day = dateObj.toLocaleString("en-US", { weekday: "long" });
		const date = dateObj.toLocaleDateString("en-US", {
			year: "numeric",
			month: "long",
			day: "numeric",
		});
		const time = dateObj.toLocaleTimeString("en-US", {
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
			timeZoneName: "short",
		});

		return {
			day,
			date,
			time,
		};
	}
	// Listen for the "keydown" event
	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.keyCode === 27) {
				// Escape key is pressed
				// Update state variables here
				localStorage.setItem("clickSelect", false);
				setShowModal(false);
				setClickSelect(false);
				setSelectAll(false);
				setEditorType(0);
				setViewEditGroup({});
				setBackgroundRemovalColorCode("");
				setBackgroundAdjustmentColorCode("");
				setBackgroundRemovalColorType("");
				setBackgroundAdjustmentColorType("");
				setResizingFileFormat("");
				setResizingDpiType("");
				setResizingColorMode("");
				setResizingAlignment("");
				setResizingMarginFormat("");
				setImageFileFormat("");
				setImageDpiType("");
				setImageDetailsView({});
				// setMultipleImagesId([]);
				// setFolderNameFirst([]);
				setFolderListName([]);

				setFolderSelect([]);

				setFoldersPath([]);
				setDuplicateImageModal(false);
				setDropdownOpen(false);
				setDuplicateAmount([]);
				setAmount(0);
			}
		};

		// Add event listener when the component mounts
		window.addEventListener("keydown", handleKeyDown);

		// Remove event listener when the component unmounts
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [modalRefImage, images]); // Empty dependency array means this effect runs once, like componentDidMount
	// Function to close the modal
	const closeModal = useCallback(() => {
		// Update state variables here
		localStorage.setItem("clickSelect", false);
		setShowModal(false);
		setClickSelect(false);
		setSelectAll(false);
		setEditorType(0);
		setViewEditGroup({});
		setBackgroundRemovalColorCode("");
		setBackgroundAdjustmentColorCode("");
		setBackgroundRemovalColorType("");
		setBackgroundAdjustmentColorType("");
		setResizingFileFormat("");
		setResizingDpiType("");
		setResizingColorMode("");
		setResizingAlignment("");
		setResizingMarginFormat("");
		setImageFileFormat("");
		setImageDpiType("");
		setImageDetailsView({});
		// setMultipleImagesId([]);
		// setFolderNameFirst([]);
		setFolderListName([]);

		setFolderSelect([]);

		setFoldersPath([]);
		setDuplicateImageModal(false);
		setDropdownOpen(false);
		setDuplicateAmount([]);
		setAmount(0);
		document.body.classList.remove("modal-open");
	}, [modalRefImage, images]);

	const handleOverlayClick = (e) => {
		if (e.target.classList.contains("modal-container--image")) {
			closeModal();
		} else if (e.target.classList.contains("close-icons-order--image")) {
			closeModal();
		}
	};
	const onChange = (text) => {
		setRenameTextDuplicate(text !== undefined && text);
	};
	const onSubmitDublicateImage = () => {
		if (token && renameTextDuplicate !== "" && renameId !== "") {
			bodyFormData.append("image_id", renameId);
			bodyFormData.append("name", renameTextDuplicate);
			request
				.post(apiURL + CO_ORDINATOR_DUPLICATE_IMAGE_RENAME, bodyFormData)
				.then((response) => {
					handleFetchProfileSettings(setting_profile.id);
					setRenameModalShow(false);
					//Set the success from the response
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	const onClickDuplicateImage = (id, imageName, imageExtensions) => {
		setRenameId(id);
		setImageName(imageName);
		setImageExtensions(imageExtensions);
		setRenameModalShow((val) => !val);
	};
	const svgCode = (count) => {
		return `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20" fill="none">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M6 4.5V0H10.5C10.8978 0 11.2794 0.158035 11.5607 0.43934C11.842 0.720644 12 1.10218 12 1.5V3H9L3 9V16H1.5C1.10218 16 0.720644 15.842 0.43934 15.5607C0.158035 15.2794 0 14.8978 0 14.5V6H4.5C4.89782 6 5.27936 5.84196 5.56066 5.56066C5.84196 5.27936 6 4.89782 6 4.5ZM5 4.5V0.25L0.25 5H4.5C4.63261 5 4.75979 4.94732 4.85355 4.85355C4.94732 4.75979 5 4.63261 5 4.5Z" fill="white"/>
		<path d="M10 4V8.5C10 8.89782 9.84196 9.27936 9.56066 9.56066C9.27936 9.84196 8.89782 10 8.5 10H4V18.5C4 18.8978 4.15804 19.2794 4.43934 19.5607C4.72064 19.842 5.10218 20 5.5 20H14.5C14.8978 20 15.2794 19.842 15.5607 19.5607C15.842 19.2794 16 18.8978 16 18.5V5.5C16 5.10218 15.842 4.72064 15.5607 4.43934C15.2794 4.15804 14.8978 4 14.5 4H10ZM9 4.25V8.5C9 8.63261 8.94732 8.75979 8.85355 8.85355C8.75979 8.94732 8.63261 9 8.5 9H4.25L9 4.25Z" fill="white"/>
		<!-- Use a <style> element to set the font size -->
			<style>
			  text {
				font-size: 9px;
				font-weight:500;
			  }
			</style>
			<!-- Adjust the y attribute to add margin on the top -->
			<text x="6" y="18" fill="green">${count}</text>
	  </svg>`;
	};
	const getImageExtension = (imageName) => {
		const lastDotIndex = imageName.lastIndexOf(".");
		if (lastDotIndex !== -1) {
			const extension = imageName.substring(lastDotIndex + 1);
			return extension;
		}
		return "No extension found";
	};
	const handleDelete = () => {
		if (!token) {
			return null;
		}
		// Make an HTTP POST request to delete the selected duplicate image
		request
			.delete(apiURL + CO_ORDINATOR_DELETE_DUPLICATE_IMAGE + `/${renameId}`)
			.then((response) => {
				// Log the response to the console
				handleFetchProfileSettings(setting_profile.id);

				// Close the delete confirmation modal
				setShowModalDelete(false);
			})
			.catch((err) => console.log(err));
	};
	const handleRenameOriginalImage = () => {
		if (token && renameTextDuplicate !== "" && renameId !== "") {
			bodyFormData.append("image_id", setting_profile.id);
			bodyFormData.append("name", renameTextDuplicate);
			request
				.post(apiURL + CO_ORDINATOR_ORIGINAL_IMAGE_RENAME, bodyFormData)
				.then((response) => {
					handleFetchProfileSettings(setting_profile.id);
					setRenameModalShow(false);
					//Set the success from the response
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	const handleRenameImage = () => {
		if (renameId === setting_profile.id) {
			return handleRenameOriginalImage();
		} else {
			onSubmitDublicateImage();
		}
	};

	//handled next item
	function getNextPage(data, currentId, itemsPerPage) {
		let startIndex = currentId
			? data.findIndex((item) => item.id === currentId)
			: -1;

		if (startIndex === -1) {
			// If the current ID is not found or not provided, start from the beginning.
			startIndex = 0;
		} else {
			startIndex += 1;
		}

		const endIndex = startIndex + itemsPerPage;
		const nextPage = data.slice(startIndex, endIndex);

		// Determine the next ID or wrap around to the first ID if necessary.
		let nextPageId = null;

		if (nextPage.length > 0) {
			nextPageId = nextPage[nextPage.length - 1].id;
		} else if (data.length > 0) {
			nextPageId = data[0].id;
		}

		return { nextPage, nextPageId };
	}
	function getPreviousPage(data, currentId, itemsPerPage) {
		let startIndex = currentId
			? data.findIndex((item) => item.id === currentId)
			: -1;
		setCommentsList(false);

		if (startIndex === -1) {
			// If the current ID is not found or not provided, start from the last item.
			startIndex = data.length - itemsPerPage;
		} else {
			startIndex = Math.max(0, startIndex - itemsPerPage);
		}

		const endIndex = startIndex + itemsPerPage;
		const previousPage = data.slice(startIndex, endIndex);

		// Determine the previous ID or wrap around to the last ID if necessary.
		let previousPageId = null;

		if (previousPage.length > 0) {
			previousPageId = previousPage[0].id;
		} else if (data.length > 0) {
			previousPageId = data[data.length - 1].id;
		}

		return { previousPage, previousPageId };
	}

	function showNextPage() {
		const { nextPage, nextPageId } = getNextPage(
			images,
			imageID,
			images.length
		);
		setCommentsList(false);

		if (nextPage.length > 0) {
			// Display the nextPage data

			// Update the current page ID
			setImageID(nextPageId);
			const filterImages =
				images !== undefined &&
				images.filter((obj) => obj.id === nextPageId)[0];
			setImageDetailsView(filterImages);
		} else {
			// No more data to show
			console.log("No more data to display.");
		}
	}
	function showPreviousPage() {
		// if (images && images.length > 0) {
		const { previousPage, previousPageId } = getPreviousPage(
			images,
			imageID,
			images.length
		);

		setCommentsList(false);

		if (previousPage.length > 0) {
			// Display the previousPage data

			// Update the current page ID
			setImageID(previousPageId);
			const filterImages =
				images !== undefined &&
				images.filter((obj) => obj.id === previousPageId)[0];
			setImageDetailsView(filterImages);
		} else {
			// No more data to show
			console.log("No more data to display.");
		}
	}

	/**
	 * Handle the to assign the editor .
	 * @param {Number} idSelected - selected image id.
	 */

	const handleAssignedImagesToEditor = (idSelected) => {
		if (token) {
			const assignEditorFormData = new FormData();
			assignEditorFormData.append("image_ids", idSelected);
			request
				.post(apiURL + CO_ORDINATOR_ASSIGNED_TO_EDITOR, assignEditorFormData)
				.then((response) => {
					ToastAlert({
						title: response.data.status ? "Success" : "Error",
						message: response.data.status ? "Successfully assigned" : "Error",
						autoClose: 2000,
						messageType: response.data.status ? "success" : "error",
					});
					if (response.data.status) {
						setEditorAssigned(true);
						request
							.get(
								apiURL +
									ADMIN_GET_COORDINATORS_DETAILS +
									`/${currentUserId}` +
									`?batch_number=${batchId}`
							)
							.then((response) => {
								setParentFolderDetails(null);
								setFolderName("");
								// setEditGroups(response.data.data.editGroups);
								// setEditTypes(response.data.data.editTypes);
								setBatchNumber(response.data.data.batchNumbers);
								setImages(response.data.data.images);
								setFolderList(response.data.data.folders);
								setFolder(response.data.data.folders);
								setImageMainFolder(response.data.data.images);
								setFolderMainFolder(response.data.data.folders);
								setMainImageCount(response.data.data.mainImageCount);
								setMainImageSettingsCount(
									response.data.data.mainImageSettingsCount
								);
								setOrderNumber(response.data.data.batchNumbers[0].order_id);
								setSubFolderOpen(false); // Clear subFolderDetails as we are at the parent level
								setDisplay(false);
								setMultipleImagesId([]);
								setClickSelect(false);
							})
							.catch((err) => console.log(err));
					}
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};

	/**
	 * Handles to check the editor assign status/progress for task status.
	 * @param {String} status - The status String image status.
	 * @returns {} return the matching string or undefined if not matched.
	 */

	const checkEditorWorkProgress = (status) => {
		return editorWorkingStatus.find((item) => item == status);
	};

	/**
	 * Handles to disable/enable item based on condition.
	 * @returns {} return true/false based on condition.
	 */

	const checkDisableInput = () => {
		return (
			isEditorAssigend || checkEditorWorkProgress(image_details_view.status)
		);
	};

	/**
	 * Handles to set the state.
	 * @param {Boolean} checked - The checked/unchecked after action of checkbox clicked.
	 */
	const toggleFieldEditing = (checked = false) => {
		customizedSettingsEnabled(checked);
		if (checked) {
			if (edit_select > 0) {
				setPreviousSelectedValue(edit_select);
			}
			const updatedGroup = [
				{ id: "Customized Settings", group_name: "Customized Settings" },
				{ id: "New Settings", group_name: "New Settings" },
				...editGroups,
			];
			setGroup(updatedGroup);
			setEditGroupNameSelect("Customized Settings");
      		setEditorType("Customized Settings");
		} else {
			setEditGroupNameSelect("");
			setEditorType(previousSelectedValue);
			setImageFileFormat("");
			setImageHeight("");
			setImageWidth("");
			setImageDpi("");
			setImageDpiType("");
			setImageColorMode("");
			setImageAlignment("");
			setImageMarginFormat("");
			setImageMarginTop("");
			setImageMarginBottom("");
			setImageMarginRight("");
			setImageMarginLeft("");
		}
	};

	/**
	 * Handles to return the checkbox field editbale state is set or not.
	 */
	const checkisEditableField = () => {
		return !customizedSettings;
	};
	
	/**
	 * Handles profile change event
	 * @param {Event} e - The changed event object.
	 */
	const handleProfileChange = (e) => {
		setEditGroupNameSelect(e.label);
		setEditorType(e.value);
		setGroupSelect((val) => !val);
		if (
			group[0] &&
			group[0].group_name === "Customized Settings" &&
			e.label === "New Settings"
		) {
			const updatedGroup = [
				{ id: "New Settings", group_name: "New Settings" },
				...editGroups,
			];
			setGroup(updatedGroup);
			customizedSettingsEnabled(false);
		} else if (e.label === "Customized Settings") {
			setImageID("");
			setTimeout(() => {
				setImageID(previousImageSelected);
			}, 20);
		}
	};

	/**
	 * Handles image change  to add in the prev selected image state
	 */
	useEffect(() => {
		if (imageID !== "") {
			previousImageID(imageID);
		}
	}, [imageID]);

	if (image_details_view.length === null) return <Loader />;
	else {
		return (
			<div>
				{showModal && (
					<div className="modal-overlay--order">

						<div className="nim">
							<div className="nim__icon-area">
								<div
									// className="modal-image-icons"
									onClick={() => {
										setToggleMessage((val) => !val);
										setCommentsList((val) => !val);
										if (!showCommentList) {
											setAnnotationsComments([]);
										}
									}}
									style={{
										//  background: showCommentList ? "#f5f5f5" : "#fff" 
										}}
								>
									{/* <img
										src={showCommentList ? BlueChatIcon : ChatIcons}
										className="img-fluid image--comment"
										alt="mindreach images"
									/> */}




									<div className="modal-image-icons__area">
									  <div className="modal-image-icons__each modal-image-icons__each--active">
											<svg
												width="19"
												height="19"
												viewBox="0 0 23 23"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M15.8777 21.2582C16.2126 21.2612 16.5388 21.152 16.8045 20.9481C17.0702 20.7442 17.26 20.4573 17.3437 20.133L22.2389 1.91716C22.3129 1.6422 22.308 1.35199 22.2249 1.07965C22.1418 0.807313 21.9838 0.563829 21.769 0.376995C21.5541 0.19016 21.291 0.0675049 21.0098 0.0230314C20.7286 -0.0214422 20.4405 0.0140584 20.1785 0.12548L2.81819 7.49792C2.50996 7.62901 2.25373 7.85833 2.08939 8.15018C1.92505 8.44204 1.86182 8.78004 1.90956 9.11156C1.95729 9.44308 2.1133 9.74952 2.3533 9.98316C2.5933 10.2168 2.90381 10.3645 3.2365 10.4033L12.1463 11.4404L14.4087 20.1194C14.4897 20.4458 14.678 20.7355 14.9435 20.942C15.2089 21.1486 15.5361 21.2599 15.8724 21.2582H15.8777ZM20.7722 1.51934L15.8769 19.7398L13.6161 11.0608C13.5405 10.7632 13.376 10.4958 13.1444 10.2942C12.9129 10.0926 12.6253 9.96646 12.3201 9.93263L3.41035 8.89558L20.7722 1.51934Z"
													fill="#5A616B"
												/>
												<path
													d="M12.5181 11.0483L1.89453 21.6719"
													stroke="#5A616B"
													stroke-width="2"
													stroke-linecap="round"
												/>
											</svg>
										</div>
										<div className="modal-image-icons__each">
											<img
												src={showCommentList ? BlueChatIcon : ChatIcons}
												alt="mindreach images"
										  />
										</div>
									</div>






								</div>
							</div>
							<div className="nim__header-area">





								<div
								className="d-flex justify-content-between w-full"
								onClick={handleOverlayClick}
								ref={modalRefImage}
						   	>
								<div className="d-flex align-items-center" style={{gap:'30px'}}>
									{/* <Button
										variant="outline-secondary"
										className="btn-image-order--outlined"
									>
										<span className="order-text--outlined">
											{image_details_view.batch_number}
										</span>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										<span className="order-text-outlined--name">
											{image_details_view.path}
										</span>
									</Button> */}
									<div
										className={`dropdown-duplicate-image--modal dropdown-duplicate-image--newmodal  ${
											isDropdownOpen ? "open" : ""
										}`}
									>
										<div
											className="dropdown-button--duplicate"
											onClick={toggleDropdown}
										>
											<span>
												<div
													dangerouslySetInnerHTML={{
														__html: svgCode(
															setting_profile !== null &&
																setting_profile !== undefined &&
																setting_profile.duplication_settings !==
																	undefined &&
																setting_profile.duplication_settings !== null &&
																setting_profile.duplication_settings.length !==
																	undefined
																? parseInt(
																		setting_profile.duplication_settings.length
																			.toString()
																			.substr(0, 2)
																	)
																: "0"
														),
													}}
													style={{ marginLeft: "8px" }}
												/>
											</span>
											<span className="order-text--outlined">
												{image_details_view.batch_number}
											</span>
											<span
												className="order-text--outlined"
												style={{ marginLeft: "42px" }}
											>
												{image_details_view.path}
											</span>
											<span style={{ marginLeft: "auto" }}>
												{setting_profile !== undefined &&
													setting_profile.duplication_settings !== undefined &&
													setting_profile.duplication_settings.length !== 0 && (
														<div
															style={{ marginRight: "18px", marginLeft: "18px" }}
															onClick={() =>
																onClickDuplicateImage(
																	setting_profile.id,
																	setting_profile.path.substring(
																		0,
																		setting_profile.path.lastIndexOf(".")
																	)
																	// getImageExtension(image_details_view.path)
																)
															}
														>
															<img src={EditImageOriginal} alt="Pencil Icon" />{" "}
														</div>
													)}
											</span>
											<span className="mr-25px">
												{setting_profile !== undefined &&
													setting_profile.duplication_settings !== undefined &&
													setting_profile.duplication_settings.length !== 0 && (
														<img src={ArrowDown} alt="Arrow Down" />
													)}
											</span>
										</div>
										{isDropdownOpen &&
											setting_profile.duplication_settings.length !== 0 && (
												<div className="dropdown-content-duplicate-image--modal">
													{setting_profile !== undefined &&
														setting_profile.duplication_settings !== undefined &&
														setting_profile.duplication_settings.map(
															(_items, index) => {
																// const isItemHovered =
																// 	hoveredItems[_items.id] || false;
																return (
																	<span
																		key={_items.id}
																		onMouseEnter={() =>
																			handleMouseEnter(_items.id)
																		}
																		onMouseLeave={handleMouseLeave}
																		onClick={() => {
																			setDuplicateImageId(_items.id);
																			setViewDuplicateModal(true);
																			setDropdownOpen(false);
																		}}
																	>
																		<h2
																			className="dropdown-duplicate-image--text"
																			onClick={() =>
																				onClickDuplicateImage(
																					_items.id,
																					_items.image_name.substring(
																						0,
																						_items.image_name.lastIndexOf(".")
																					)
																					// getImageExtension(image_details_view.path)
																				)
																			}
																		>
																			{_items.image_name}
																		</h2>
																		<div className="d-flex">
																			<div
																				style={{ marginRight: "18px" }}
																				onClick={() =>
																					onClickDuplicateImage(
																						_items.id,
																						_items.image_name.substring(
																							0,
																							_items.image_name.lastIndexOf(".")
																						)
																						// getImageExtension(image_details_view.path)
																					)
																				}
																			>
																				<img
																					src={
																						hoveredItemId === _items.id
																							? BlackEdit
																							: EditImageOriginal
																					}
																					alt="Pencil Icon"
																				/>{" "}
																			</div>

																			<div
																				onClick={() => {
																					setShowModalDelete(true);
																					setRenameId(_items.id);
																					setImageName(_items.image_name);
																				}}
																			>
																				<img
																					src={
																						hoveredItemId === _items.id
																							? BlackDelete
																							: DeleteIcon
																					}
																					alt="Mindreach icons"
																				/>
																				{/* <span className="delete-icon" /> */}
																			</div>
																		</div>
																	</span>
																);
															}
														)}
												</div>
											)}
									</div>
									<div className="d-flex">
										<div>
											<div className="magnifier-holder--img">
												{/* zoom in icon */}
												<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-zoom-in" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11M13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0"/>
													<path d="M10.344 11.742q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1 6.5 6.5 0 0 1-1.398 1.4z"/>
													<path fill-rule="evenodd" d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5"/>
												</svg>
											</div>
										</div>
										<div style={{marginLeft:'10px'}}>
											<div className="magnifier-holder--img">
												{/* zoom out icon */}
												<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-zoom-in" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11M13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0"/>
													<path d="M10.344 11.742q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1 6.5 6.5 0 0 1-1.398 1.4z"/>
													<path fill-rule="evenodd" d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5"/>
												</svg>
											</div>
										</div>
										<div className="image-zoom">
											100
											{/* percentage icon */}
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-percent" viewBox="0 0 16 16">
												<path d="M13.442 2.558a.625.625 0 0 1 0 .884l-10 10a.625.625 0 1 1-.884-.884l10-10a.625.625 0 0 1 .884 0M4.5 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5m7 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5"/>
											</svg>
										</div>
										
									</div>
								</div>
								{/* <div style={{ marginLeft: "34rem" }}>
									{group_name_select === "New Settings" && (
										<Button
											variant="outline-secondary"
											className="btn-image-order-save--outlined"
											onClick={() => handleUpdateEditGroup()}
										>
											Save
										</Button>
									)}
									{group_name_select !== "" && group_name_select !== "New Settings" && (
										<Button
											variant="outline-secondary"
											className="btn-image-order-save--outlined"
											onClick={() => handleUpdateImageEditGroup()}
										>
											Save
										</Button>
									)}
								</div> */}
								<div
									className="modal-tab"
									onClick={() => {
										setShowModal(false);
										setEditorType(0);
										setViewEditGroup({});
										setBackgroundRemovalColorCode("");
										setBackgroundAdjustmentColorCode("");
										setBackgroundRemovalColorType("");
										setBackgroundAdjustmentColorType("");
										setResizingFileFormat("");
										setResizingDpiType("");
										setResizingColorMode("");
										setResizingAlignment("");
										setResizingMarginFormat("");
										setImageFileFormat("");
										setImageDpiType("");
										setImageDetailsView({});
										document.body.classList.remove("modal-open");
									}}
								>


									<Button
											variant="primary"
											className="btns"
											onClick={() =>
												handleAssignedImagesToEditor(
													sessionStorage.getItem(
														"selectImageID"
													)
												)
											}
										>
											Assign to Editor
										</Button>{" "}




									{/* <img
										src={CancelIcons}
										className="img-fluid"
										alt="mindreach images"
									/> */}
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
										<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
										<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
									</svg>
								</div>
								</div>






								
							</div>
							<div className="nim__main-area">
								<div className="modal--image">
									<div className="prev--icons" onClick={() => showPreviousPage()}>
										<div className="next-pre-btn">
											<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
												<path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
											</svg>
											{/* <img
												src={PreviousIcon}
												className="img-fluid"
												alt="mindreach images"
											/> */}
										</div>
									</div>
									<div
										//   style={{
										//     width: "90%",
										//     marginBottom: "0px",
										//     height: "37rem",
										//     overflow: "scroll",
										//     // backgroundColor: "hsl(0, 0%, 90%)",
										//   }}
										className="modal-container"
									>
										<div className="align-center">
											{image_details_view !== undefined &&
											image_details_view.image_path !== undefined &&
											image_details_view.customer !== undefined &&
											image_details_view.customer.email !== undefined ? (
												<Marker
													imageURL={`${imageURL}/${image_details_view.customer.email}/${image_details_view.batch_number}/${image_details_view.order.order_number}/images/${image_details_view.image_path}`}
													currentAnnotation={currentAnnotation}
													setCurrentAnnotation={setCurrentAnnotation}
													handleTextChange={handleTextChange}
													annotations={annotations}
													setAnnotations={setAnnotations}
													setToggleMessage={setToggleMessage}
													toggleMessage={toggleMessage}
													idSelected={image_details_view.id}
													token={token}
													setCommentsList={setCommentsList}
													showCommentList={showCommentList}
													setAnnotationsComments={setAnnotationsComments}
													annotationsComments={annotationsComments}
													image_details_view={image_details_view}
												/>
											) : (
												<Loader />
											)}
										</div>
									</div>
									<div className="next--icons" onClick={() => showNextPage()}>
										<div className="next-pre-btn">
											<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
												<path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
											</svg>
											{/* <img
												src={NextIcon}
												className="img-fluid"
												alt="mindreach images"
											/> */}
										</div>
									</div>
								</div>
							</div>
							<div className="nim__form-area">
								<div className="modal-image--order">
									<div className="image-modal--setting">
										<div className="sidebar-image-name--image-modal  d-flex">
											<div style={{ width: "100%" }}>
												<div
													className="d-flex"
													style={{
														// flexDirection: "row-reverse",
														// marginBottom: "18px",
														// marginTop: "16px",
													}}
												>
													{/* <h2
														className="side-bar-single-select--h2"
														style={{ marginLeft: "83px" }}
													>
														{selectImageName}
													</h2> */}
													{/* <div
														style={{ display: "flex", marginLeft: "auto" }}
														onClick={() => {
															setMultipleImagesId([]);
															setClickSelect(false);
														}}
													>
														<img
															src={CancelIcons}
															className="img-fluid"
															alt="mindreach images"
														/>
													</div> */}
												</div>
												
												<div className="sidebar__header" style={{padding:'12px 15px'}}>
													{sessionStorage.getItem("selectImageID") &&
														!isEditorAssigend &&
														image_details_view &&
														!checkEditorWorkProgress(image_details_view.status) && (
															<div
																// className="edit-profile-setting-container--single"
																// style={{ display:'flex', flexGrow:'1' }}
															>
																	
																		<div className="box box2 mb-2" style={{ display:'flex', flexGrow:'1' }}>
																			{/* <Button
																				variant="primary"
																				className="btns"
																				onClick={() =>
																					handleAssignedImagesToEditor(
																						sessionStorage.getItem(
																							"selectImageID"
																						)
																					)
																				}
																			>
																				Assign to Editor
																			</Button>{" "} */}
																		</div>
																	
															</div>
														)}
													<div 
													// className="edit-profile-setting-container--single"
													>
														<div className="o-flex" style={{marginBottom:'5px'}}>
															
															<div>
																<Select
																	className="settingsProfile settingsProfile-dropdown"
																	options={selectLabel(group)}
																	onChange={(e) => {
																		handleProfileChange(e);
																	}}
																	placeholder="Select Profile"
																	value={selectLabel(group).find((option) => {
																		if (
																			edit_select !== "" &&
																			edit_select !== null
																		) {
																			return option.value === edit_select;
																		} else {
																			return null;
																		}
																	})}
																	styles={customSelectStyles}
																/>
															</div>
															<div>
															 <button type="button" class="btn__save">Save</button>
																{/* <label className="form-label">Settings Profile</label> */}
															 </div>
														</div>
														<div>


														{((view_edit_group !== null &&
																view_edit_group !== undefined &&
																view_edit_group.edit_group) ||
																(view_edit_group !== undefined &&
																	view_edit_group.edit_group === null &&
																	setting_profile)) && (
																	<>
																		{view_edit_group !== null &&
																		view_edit_group !== undefined &&
																		view_edit_group.edit_group !== null &&
																		view_edit_group.edit_group !== undefined &&
																		view_edit_group.edit_group.edit_types !== null &&
																		view_edit_group.edit_group.edit_types !== undefined && (
																			<Form.Group>
																				<div style={{display:'flex', gap:'6px', marginTop:'8px'}}>
																					<Form.Label
																						for={"editFields"}
																						style={{ cursor: "pointer" }}
																					>
																						Customize Settings
																					</Form.Label>
																					<div
																						className="float-right"
																					>
																						<input
																							type="checkbox"
																							id={"editFields"}
																							onChange={(e) =>
																								toggleFieldEditing(e.target.checked)
																							}
																							checked={customizedSettings}
																						/>
																					</div>
																				</div>
																			</Form.Group>
																		)}
																		{/* <div className="sub-head">Edit Type</div> */}
																	</>
																)}
															{/* /***need to check**/}



														</div>
													</div>
												</div>

												<div>
													{group_name_select === "New Settings" && !customizedSettings ? (
														<div
															className="sidebar-settings-image--image-modal"
														>
															{/* Form groups for Edit Type*/}
															<div className="sub-head" id="et_head">Edit Type</div>
															{editTypes !== undefined &&
																editTypes.map((item) => {
																	return (
																		<Form.Group
																			className=""
																		>
																			{" "}
																			<Row
																				className="form-group  flex-grow-1"
																				sm={12}
																			>
																				<Col sm={1}>
																					<input
																						type="checkbox"
																						onChange={(e) => {
																							handleBackGroundRemovalCheckbox(
																								item.id,
																								e.target.checked
																							);
																							handleAddCheckboxSelect(e);
																						}}
																						value={item.id}
																						id={`et_${item.id}`}
																						className="et_options"
																					/>
																				</Col>
																				<Form.Label
																					className="col-sm-4 form-label form-check-label"
																					style={{ width: "auto" , marginTop:"1.4px" ,cursor:"pointer" }}
																					for={`et_${item.id}`}
																				>
																					{item.edit_type}
																				</Form.Label>
																			</Row>
																			{item.id === 1 && selected ? (
																					<Row
																						className="align-items-center"
																						style={{
																							marginLeft: "auto",
																							marginTop: "0",
																							marginBottom: "20px",
																						}}
																					>
																						<Col sm={5} className="ml_4_percent">
																							<Select
																								options={selectBackgrondRemoval}
																								onChange={(e) =>
																									setBackgroundRemovalColorType(
																										e.label
																									)
																								}
																							/>
																						</Col>
																						<Col sm={5}>
																							<Form.Control
																								type="text"
																								placeholder="#ffff"
																								autoFocus
																								onChange={(e) =>
																									setBackgroundRemovalColorCode(
																										e.target.value
																									)
																								}
																								autoComplete="off"
																							/>
																						</Col>
																					</Row>
																				
																			) : (
																				""
																			)}
																			{item.id === 2 && selected1 ? (
																					<Row
																						className="align-items-center"
																						style={{
																							marginLeft: "auto",
																							marginTop: "0",
																							marginBottom: "20px",
																						}}
																					>
																						<Col sm={5} className="ml_4_percent">
																							<Select
																								options={selectBackgrondRemoval}
																								onChange={(e) =>
																									setBackgroundAdjustmentColorType(
																										e.label
																									)
																								}
																							/>
																						</Col>
																						<Col sm={5}>
																							<Form.Control
																								type="text"
																								placeholder="#ffff"
																								autoFocus
																								onChange={(e) =>
																									setBackgroundAdjustmentColorCode(
																										e.target.value
																									)
																								}
																								value={
																									background_adjustment_color_code
																								}
																								autoComplete="off"
																							/>
																						</Col>
																					</Row>
																			) : (
																				""
																			)}
																			{item.id === 3 ? (
																				selected2 ? (
																					<Row
																						className="align-items-center"
																						style={{
																							marginLeft: "auto",
																							marginRight: "auto",
																							marginTop: "0",
																							marginBottom: "0px",
																							width: " 100%",
																						}}
																					>
																						<Form.Group className="mg-bottom16">
																							<Row
																								className="form-group  flex-grow-1"
																								sm={12}
																							>
																								<Form.Label className="col-sm-12 form-label form-check-label side-form-label ml-12">
																									File Format
																								</Form.Label>
																							</Row>
																						</Form.Group>
																						<Form.Group
																							style={{ margin: "2px" }}
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Col sm={12} className="ml-2">
																										<Select
																											options={file}
																											onChange={(e) =>
																												setResizingFileFormat(
																													e.label
																												)
																											}
																										/>
																									</Col>
																								</Row>
																							</div>
																						</Form.Group>

																						<Form.Group
																							style={{ margin: "2px" }}
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Col sm={12} className="ml-8">
																										<div className="d-sm-flex align-items-center col-gap-10">
																											<div className="d-sm-flex col-gap-10 align-items-center">
																												<Form.Control
																													type="text"
																													placeholder="Height"
																													autoFocus
																													onChange={(e) =>
																														setResizingHeight(
																															e.target.value
																														)
																													}
																													autoComplete="off"
																												/>
																											</div>
																											<div className="d-sm-flex align-items-center">
																											<img
																												style={{ width: "18px" }}
																												src={cross}
																												className="img-fluid"
																												alt="mindreach images"
																											/>
																											</div>
																											<div className="d-sm-flex align-items-center col-gap-10">
																												<Form.Control
																													type="text"
																													placeholder="Width"
																													autoFocus
																													onChange={(e) =>
																														setResizingWidth(
																															e.target.value
																														)
																													}
																													autoComplete="off"
																												/>
																											</div>
																										</div>
																									</Col>
																								</Row>
																							</div>
																						</Form.Group>
																						<Form.Group className="mg-bottom16">
																							<Row
																								className="form-group  flex-grow-1"
																								sm={12}
																							>
																								<Form.Label className="col-sm-4 form-label form-check-label side-form-label ml-12">
																								DPI
																								</Form.Label>
																							</Row>
																						</Form.Group>
																						<Form.Group
																							style={{ margin: "2px" }}
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Col sm={12} className="ml-8">
																										<div className="d-sm-flex col-gap-20">
																											<div className="w-75">
																												<Form.Control
																													type="text"
																													placeholder="2000"
																													autoFocus
																													onChange={(e) =>
																														setResizingDpi(
																															e.target.value
																														)
																													}
																													autoComplete="off"
																												/>
																											</div>
																											<div className="flex-fill w-75">
																												<Select
																													options={dpi}
																													onChange={(e) =>
																														setResizingDpiType(
																															e.label
																														)
																													}
																												/>
																											</div>
																										</div>
																									</Col>
																								</Row>
																							</div>
																						</Form.Group>
																						<Form.Group className="mg-bottom16">
																							<Row
																								className="form-group  flex-grow-1"
																								sm={12}
																							>
																								<Form.Label className="col-sm-4 form-label form-check-label side-form-label ml-12">
																									Color Mode
																								</Form.Label>
																							</Row>
																						</Form.Group>
																						<Form.Group
																							style={{ margin: "2px" }}
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Col sm={12} className="ml-8">
																										<Select
																											options={ColorMode}
																											onChange={(e) =>
																												setResizingColorMode(
																													e.label
																												)
																											}
																										/>
																									</Col>
																								</Row>
																							</div>
																						</Form.Group>
																						<Form.Group className="mg-bottom16">
																							<Row
																								className="form-group  flex-grow-1"
																								sm={12}
																							>
																								<Form.Label className="col-sm-4 form-label form-check-label side-form-label ml-12">
																									Align
																								</Form.Label>
																							</Row>
																						</Form.Group>
																						<Form.Group style={{ margin: "2px" }}>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Col sm={12} className="ml-8">
																										<Select
																											options={Align}
																											onChange={(e) =>
																												setResizingAlignment(
																													e.label
																												)
																											}
																										/>
																									</Col>
																								</Row>
																							</div>
																						</Form.Group>
																						<Form.Group className="mg-bottom16">
																							<Row
																								className="form-group  flex-grow-1"
																								sm={12}
																							>
																								<Form.Label className="col-sm-4 form-label form-check-label side-form-label ml-12">
																									Margins
																								</Form.Label>
																							</Row>
																						</Form.Group>
																						<Form.Group style={{ margin: "2px" }}>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Col sm={12} className="ml-8">
																										<Select
																											options={Margin}
																											onChange={(e) =>
																												setResizingMarginFormat(
																													e.label
																												)
																											}
																										/>
																									</Col>
																									<Col
																										sm={7}
																										lg={7}
																										style={{
																											display: "flex",
																										}}
																										className="ml-8"
																									>
																										<div className="d-sm-flex col-gap-20 mt-2 ">
																											
																											<div className="d-flex">
																												<ul className="margin-list d-flex">
																													<li>
																														<Form.Control
																															type="text"
																															placeholder="T"
																															autoFocus
																															onChange={(e) =>
																																setResizingMarginTop(
																																	e.target.value
																																)
																															}
																															autoComplete="off"
																														/>
																													</li>
																													<li>
																														<Form.Control
																															type="text"
																															placeholder="B"
																															autoFocus
																															onChange={(e) =>
																																setResizingMarginBottom(
																																	e.target.value
																																)
																															}
																															autoComplete="off"
																														/>
																													</li>
																													<li>
																														<Form.Control
																															type="text"
																															placeholder="L"
																															autoFocus
																															onChange={(e) =>
																																setResizingMarginLeft(
																																	e.target.value
																																)
																															}
																															autoComplete="off"
																														/>
																													</li>
																													<li>
																														<Form.Control
																															type="text"
																															placeholder="R"
																															autoFocus
																															onChange={(e) =>
																																setResizingMarginRight(
																																	e.target.value
																																)
																															}
																															autoComplete="off"
																														/>
																													</li>
																												</ul>
																											</div>
																										
																										</div>
																									</Col>

																									<Col sm={6}></Col>
																								</Row>
																							</div>
																						</Form.Group>
																					</Row>
																				) : (
																					""
																				)
																			) : (
																				""
																			)}
																		</Form.Group>
																	);
																})}
															{/* Form groups for output specifications */}
															<div className="sub-head">
																Output Specifications
															</div>
															<Form.Group className="mg-bottom15">
																<Row className="form-group  flex-grow-1" sm={9}>
																	<Form.Label className="col-sm-4 form-label side-form-label form-check-label">
																		File Format
																	</Form.Label>
																</Row>
															</Form.Group>
															<Form.Group style={{ margin: "2px" }}>
																<div className="form-group">
																	<Row className="align-items-center">
																		<Col sm={12} className="">
																			<Select
																				options={file}
																				onChange={(e) =>
																					setImageFileFormat(e.label)
																				}
																			/>
																		</Col>
																	</Row>
																</div>
															</Form.Group>
															<Form.Group
																style={{ margin: "2px" }}
															>
																<div className="form-group">
																	<Row className="align-items-center">
																		<Col sm={12} className="">
																			<div className="d-sm-flex align-items-center col-gap-10">
																				<div className="d-sm-flex col-gap-10 align-items-center">
																					<Form.Control
																						type="text"
																						placeholder="Height"
																						autoFocus
																						onChange={(e) =>
																							setImageHeight(e.target.value)
																						}
																						autoComplete="off"
																					/>
																				</div>
																				<div className="d-sm-flex align-items-center">
																					<img
																						style={{ width: "18px" }}
																						src={cross}
																						alt="mindreach images"
																					/>
																				</div>
																				<div className="d-sm-flex align-items-center col-gap-10">
																					<Form.Control
																						type="text"
																						placeholder="Width"
																						autoFocus
																						onChange={(e) =>
																							setImageWidth(e.target.value)
																						}
																						autoComplete="off"
																					/>
																				</div>
																			</div>
																		</Col>
																	</Row>
																</div>
															</Form.Group>
															<Form.Group className="mg-bottom15">
																<Row className="form-group  flex-grow-1" sm={12}>
																	<Form.Label className="col-sm-4 form-label side-form-label form-check-label">
																		DPI
																	</Form.Label>
																</Row>
															</Form.Group>
															<Form.Group
																style={{ margin: "2px" }}
															>
																<div className="form-group">
																	<Row className="align-items-center">
																		<Col sm={12} className="">
																			<div className="d-sm-flex col-gap-20">
																				<Form.Control
																					type="text"
																					placeholder="2000"
																					autoFocus
																					onChange={(e) =>
																						setImageDpi(e.target.value)
																					}
																					autoComplete="off"
																					className="w-50"
																				/>

																				<div className="flex-fill">
																					<Select
																						options={dpi}
																						onChange={(e) =>
																							setImageDpiType(e.label)
																						}
																					/>
																				</div>
																			</div>
																		</Col>
																	</Row>
																</div>
															</Form.Group>
															<Form.Group className="mg-bottom15">
																<Row className="form-group  flex-grow-1" sm={12}>
																	<Form.Label className="col-sm-4 form-label side-form-label form-check-label">
																		Color Mode
																	</Form.Label>
																</Row>
															</Form.Group>
															<Form.Group
																style={{ margin: "2px" }}
															>
																<div className="form-group">
																	<Row className="align-items-center">
																		<Col sm={12} className="">
																			<Select
																				options={ColorMode}
																				onChange={(e) =>
																					setImageColorMode(e.label)
																				}
																			/>
																		</Col>
																	</Row>
																</div>
															</Form.Group>
															<Form.Group className="mg-bottom15">
																<Row className="form-group  flex-grow-1" sm={12}>
																	<Form.Label className="col-sm-4 form-label side-form-label form-check-label">
																		Align
																	</Form.Label>
																</Row>
															</Form.Group>
															<Form.Group style={{ margin: "2px" }}>
																<div className="form-group">
																	<Row className="align-items-center">
																		<Col sm={12} className="">
																			<Select
																				options={Align}
																				onChange={(e) =>
																					setImageAlignment(e.label)
																				}
																			/>
																		</Col>
																	</Row>
																</div>
															</Form.Group>
															<Form.Group className="mg-bottom15">
																<Row className="form-group  flex-grow-1" sm={12}>
																	<Form.Label className="col-sm-4 form-label side-form-label form-check-label">
																		Margins
																	</Form.Label>
																</Row>
															</Form.Group>
															<Form.Group style={{ margin: "2px" }}>
																<div className="form-group">
																	<Row className="align-items-center">
																		<Col sm={12} className="">
																			<Select
																				options={Margin}
																				onChange={(e) =>
																					setResizingMarginFormat(e.label)
																				}
																			/>
																		</Col>
																		<Col
																			sm={7}
																			lg={7}
																			style={{
																				display: "flex",
																			}}
																		>
																			<div className="d-sm-flex col-gap-20 mt-3  ">
																				<div className="d-flex">
																					<ul className="margin-list d-flex">
																						<li>
																							<Form.Control
																								type="text"
																								placeholder="T"
																								autoFocus
																								onChange={(e) =>
																									setImageMarginTop(
																										e.target.value
																									)
																								}
																								autoComplete="off"
																							/>
																						</li>
																						<li>
																							<Form.Control
																								type="text"
																								placeholder="B"
																								autoFocus
																								onChange={(e) =>
																									setImageMarginBottom(
																										e.target.value
																									)
																								}
																								autoComplete="off"
																							/>
																						</li>
																						<li>
																							<Form.Control
																								type="text"
																								placeholder="L"
																								autoFocus
																								onChange={(e) =>
																									setImageMarginLeft(
																										e.target.value
																									)
																								}
																								autoComplete="off"
																							/>
																						</li>
																						<li>
																							<Form.Control
																								type="text"
																								placeholder="R"
																								autoFocus
																								onChange={(e) =>
																									setImageMarginRight(
																										e.target.value
																									)
																								}
																								autoComplete="off"
																							/>
																						</li>
																					</ul>
																				</div>
																			</div>
																		</Col>
																		<Col sm={6}></Col>
																	</Row>
																</div>
															</Form.Group>
															{!isEditorAssigend &&
																image_details_view &&
																image_details_view.status &&
																!checkEditorWorkProgress(
																	image_details_view.status
																) &&
																group_name_select === "New Settings" && (
																	<Form.Group
																		style={{ margin: "2px" }}
																		controlId="exampleForm.ControlTextarea1"
																	>
																		<div className="form-group">
																			<Row className="align-items-center">
																				<div className="text-center margin-18">
																					<Button
																						variant="primary"
																						className="btns"
																						onClick={() =>
																							handleUpdateEditGroup()
																						}
																					>
																						{loader ? (
																							<div
																								style={{
																									backgroundColor: "wheat",
																								}}
																							>
																								<Loader />
																							</div>
																						) : (
																							"Save"
																						)}
																					</Button>
																				</div>
																				<Col sm={6}></Col>
																			</Row>
																		</div>
																	</Form.Group>
																)}
														</div>
													) : (
														<div className="sidebar-settings-image--image-modal"
														>
															{((view_edit_group !== null &&
																view_edit_group !== undefined &&
																view_edit_group.edit_group) ||
																(view_edit_group !== undefined &&
																	view_edit_group.edit_group === null &&
																	setting_profile)) && (
																	<>
																		<div className="sub-head">Edit Type</div>
																	</>
																)}
															{/* /***need to check**/}

															{((view_edit_group !== null &&
																view_edit_group !== undefined &&
																view_edit_group.edit_group) ||
																(view_edit_group !== undefined &&
																	view_edit_group.edit_group === null &&
																	setting_profile)) && editTypes && 
																	editTypes.map((item) => {
																		return (
																			<>
																				<Form.Group>
																					{" "}
																					<Row
																						className="form-group  flex-grow-1"
																						sm={12}
																					>
																						<Col sm={1}>
																							<input
																								type="checkbox"
																								value={item.id}
																								onChange={(e) => {
																									if (customizedSettings) {
																										handleBackGroundRemovalCheckbox(
																											item.id,
																											e.target.checked,
																											"Edit"
																										);
																										handleAddCheckboxSelect(e);
																									}
																								}}
																								checked={
																									item.id === 1
																										? selected
																										: item.id === 2
																										? selected1
																										: item.id === 3
																										? selected2
																										: selected3
																								}
																								disabled={checkisEditableField()}
																								id={`et_${item.id}`}
																								className="et_options"
																							/>
																						</Col>
																						<Form.Label
																							className="col-sm-4 form-label form-check-label"
																							style={{ width: "auto" ,cursor: "pointer", }}
																							for={`et_${item.id}`}

																						>
																							{item.edit_type}
																						</Form.Label>
																					</Row>
																					{item.id === 1 && selected ? (
																						<Row
																							className="align-items-center"
																							style={{
																								marginTop: "-2px",
																								marginBottom: "20px",
																								width: "100%",
																							}}
																						>
																							
																							<Col sm={5}>
																								<Select
																									options={selectBackgrondRemoval}
																									onChange={(e) =>
																										setBackgroundRemovalColorType(e.label)
																									}
																									defaultValue={{
																										label: `${background_removal_color_type}`,
																										id: "21",
																									}}
																									value={{
																										label:
																											background_removal_color_type, // Set the label property to the desired value
																										value:
																											background_removal_color_type, // Set the value property to the corresponding value
																									}}
																									isDisabled={checkisEditableField()}
																								/>
																							</Col>
																							<Col sm={4}>
																								<Form.Control
																									type="text"
																									placeholder="#ffff"
																									autoFocus
																									onChange={(e) =>
																										setBackgroundRemovalColorCode(
																											e.target.value
																										)
																									}
																									value={
																										background_removal_color_code
																									}
																									disabled={checkisEditableField()}
																								/>
																							</Col>
																						</Row>
																					) : (
																						""
																					)}
																					{item.id === 2 && selected1 ? (
																						<Row
																							className="align-items-center"
																							style={{
																								// marginLeft: "44px",
																								marginTop: "-2px",
																								marginBottom: "20px",
																								width: "100%",
																							}}
																						>
																							
																							<Col sm={5}>
																								<Select
																									options={selectBackgrondRemoval}
																									onChange={(e) =>
																										setBackgroundAdjustmentColorType(
																											e.label
																										)
																									}
																									value={{
																										label:
																											background_adjustment_color_type, // Set the label property to the desired value
																										value:
																											background_adjustment_color_type, // Set the value property to the corresponding value
																									}}
																									isDisabled={checkisEditableField()}
																								/>
																							</Col>
																							<Col sm={4}>
																								<Form.Control
																									type="text"
																									placeholder="#ffff"
																									autoFocus
																									onChange={(e) =>
																										setBackgroundAdjustmentColorCode(
																											e.target.value
																										)
																									}
																									value={
																										background_adjustment_color_code
																									}
																									disabled={checkisEditableField()}
																								/>
																							</Col>
																						</Row>
																					) : (
																						""
																					)}
																					{item.id === 3 && selected2 ? (
																						<Row
																							className="align-items-center"
																							style={{
																								// marginLeft: "40px",
																								marginTop: "-2px",
																								marginBottom: "20px",
																								width: "100%",
																							}}
																						>
																							{/* We might require this code again in future keep it commented */}
																							{/*<Form.Group style={{ marginBottom: "-15px" }}>
																							<Row
																								className="form-group  flex-grow-1"
																								sm={9}
																							>
																								<Col sm={1}></Col>
																								<Form.Label className="col-sm-4 form-label form-check-label ml-1">
																									File Format
																								</Form.Label>
																							</Row> 
																							</Form.Group>
																							<Form.Group style={{ margin: "2px" }}>
																								<div className="form-group">
																									<Row className="align-items-center">
																										<Form.Label className="col-sm-1">
																											
																										</Form.Label>
																										<Col sm={6}>
																											<Select
																												options={file}
																												onChange={(e) =>
																													setResizingFileFormat(e.label)
																												}
																												value={{
																													label:
																														resizing_file_format, // Set the label property to the desired value
																													value:
																														resizing_file_format, // Set the value property to the corresponding value
																												}}
																												isDisabled={checkisEditableField()}
																											/>
																										</Col>
																									</Row>
																								</div>
																							</Form.Group> */}

																							<Form.Group style={{ margin: "2px" }}>
																								<div className="form-group">
																									<Row className="align-items-center">
																										<Col sm={9}>
																											<div className="d-sm-flex align-items-center col-gap-10">
																												<div className="d-sm-flex col-gap-10 align-items-center">
																													<Form.Control
																														type="text"
																														placeholder="2000"
																														autoFocus
																														onChange={(e) =>
																															setResizingHeight(
																																e.target.value
																															)
																														}
																														value={
																															resizing_height
																														}
																														disabled={checkisEditableField()}
																													/>
																												</div>
																												<div className="d-sm-flex align-items-center">
																												<img
																													style={{ width: "18px" }}
																													src={cross}
																													alt="mindreach images"
																												/>
																												</div>
																												<div className="d-sm-flex align-items-center col-gap-10">
																													<Form.Control
																														type="text"
																														placeholder="2000"
																														autoFocus
																														onChange={(e) =>
																															setResizingWidth(
																																e.target.value
																															)
																														}
																														value={resizing_width}
																														disabled={checkisEditableField()}
																													/>
																												</div>
																											</div>
																										</Col>
																									</Row>
																								</div>
																							</Form.Group>
																							{/* We might require this code again in future keep it commented */}
																							{/* <Form.Group className="mg-bottom15">
																								<Row className="form-group  flex-grow-1" sm={9}>
																									<Col sm={1}></Col>
																									<Form.Label className="col-sm-4 form-label form-check-label">
																										DPI
																									</Form.Label>
																								</Row>
																							</Form.Group>
																							<Form.Group style={{ margin: "2px" }}>
																								<div className="form-group">
																									<Row className="align-items-center">
																										<Form.Label className="col-sm-1">
																											
																										</Form.Label>
																										<Col sm={10}>
																											<div className="d-sm-flex col-gap-20">
																													<Form.Control
																														type="text"
																														placeholder="2000"
																														autoFocus
																														onChange={(e) =>
																															setResizingDpi(
																																e.target.value
																															)
																														}
																														value={resizing_dpi}
																														disabled={checkisEditableField()}
																														className="w-50"
																													/>
																												<div className="flex-fill">
																													<Select
																														options={dpi}
																														onChange={(e) =>
																															setResizingDpiType(
																																e.label
																															)
																														}
																														value={{
																															label:
																																resizing_dpi_type, // Set the label property to the desired value
																															value:
																																resizing_dpi_type, // Set the value property to the corresponding value
																														}}
																														isDisabled={checkisEditableField()}
																													/>
																												</div>
																											</div>
																										</Col>
																									</Row>
																								</div>
																							</Form.Group>
																							<Form.Group className="mg-bottom15">
																								<Row className="form-group  flex-grow-1" sm={9}>
																									<Col sm={1}></Col>
																									<Form.Label className="col-sm-4 form-label form-check-label">
																										Color Mode
																									</Form.Label>
																								</Row>
																							</Form.Group>
																							<Form.Group
																								style={{ margin: "2px" }}
																							>
																								<div className="form-group">
																									<Row className="align-items-center">
																										
																										<Col sm={9}>
																											<Select
																												options={ColorMode}
																												onChange={(e) =>
																													setResizingColorMode(
																														e.label
																													)
																												}
																												value={{
																													label:
																														resizing_color_mode, // Set the label property to the desired value
																													value:
																														resizing_color_mode, // Set the value property to the corresponding value
																												}}
																												isDisabled={checkisEditableField()}
																											/>
																										</Col>
																									</Row>
																								</div>
																							</Form.Group>

																							<Form.Group className="mg-bottom15">
																								<Row className="form-group  flex-grow-1" sm={9}>
																									<Col sm={1}></Col>
																									<Form.Label className="col-sm-4 form-label form-check-label">
																										Align
																									</Form.Label>
																								</Row>
																							</Form.Group>
																							<Form.Group
																								style={{ margin: "2px" }}
																							>
																								<div className="form-group">
																									<Row className="align-items-center">
																										
																										<Col sm={9}>
																											<Select
																												options={Align}
																												onChange={(e) =>
																													setResizingAlignment(
																														e.label
																													)
																												}
																												value={{
																													label:
																														resizing_alignment, // Set the label property to the desired value
																													value:
																														resizing_alignment, // Set the value property to the corresponding value
																												}}
																												isDisabled={checkisEditableField()}
																											/>
																										</Col>
																									</Row>
																								</div>
																							</Form.Group>
																							<Form.Group className="mg-bottom15">
																								<Row className="form-group  flex-grow-1" sm={9}>
																									<Col sm={1}></Col>
																									<Form.Label className="col-sm-4 form-label form-check-label">
																										Margins
																									</Form.Label>
																								</Row>
																							</Form.Group>					
																							<Form.Group
																								style={{ margin: "2px" }}
																							>
																								<div className="form-group">
																									<Row className="align-items-center">
																										<Form.Label
																											className="col-sm-1"
																											style={{
																												marginTop: "-53px",
																											}}
																										></Form.Label>
																										<Col sm={9}>
																											<div className="d-sm-flex col-gap-20 flex-xxl-column">
																												<div
																													style={{
																														width: "100%",
																														marginBottom: "23px",
																													}}
																												>
																													<Select
																														options={Margin}
																														onChange={(e) =>
																															setResizingMarginFormat(
																																e.label
																															)
																														}
																														defaultValue={{
																															label: `${resizing_margin_format}`,
																															id: "21",
																														}}
																														value={{
																															label:
																																resizing_margin_format, // Set the label property to the desired value
																															value:
																																resizing_margin_format, // Set the value property to the corresponding value
																														}}
																														isDisabled={checkisEditableField()}
																													/>
																												</div>

																												<ul className="margin-list">
																													<li>
																														<Form.Control
																															type="text"
																															placeholder="T"
																															autoFocus
																															onChange={(e) =>
																																setResizingMarginTop(
																																	e.target.value
																																)
																															}
																															value={
																																resizing_margin_top
																															}
																															disabled={checkisEditableField()}
																														/>
																													</li>
																													<li>
																														<Form.Control
																															type="text"
																															placeholder="B"
																															autoFocus
																															onChange={(e) =>
																																setResizingMarginBottom(
																																	e.target.value
																																)
																															}
																															value={
																																resizing_margin_bottom
																															}
																															disabled={checkisEditableField()}
																														/>
																													</li>
																													<li>
																														<Form.Control
																															type="text"
																															placeholder="L"
																															autoFocus
																															onChange={(e) =>
																																setResizingMarginLeft(
																																	e.target.value
																																)
																															}
																															value={
																																resizing_margin_left
																															}
																															disabled={checkisEditableField()}
																														/>
																													</li>
																													<li>
																														<Form.Control
																															type="text"
																															placeholder="R"
																															autoFocus
																															onChange={(e) =>
																																setResizingMarginRight(
																																	e.target.value
																																)
																															}
																															value={
																																resizing_margin_right
																															}
																															disabled={checkisEditableField()}
																														/>
																													</li>
																												</ul>
																											</div>
																										</Col>

																										<Col sm={6}></Col>
																									</Row>
																								</div>
																							</Form.Group> */}
																						</Row>
																					) : (
																						""
																					)}
																				</Form.Group>
																			</>
																		);
																	}
																)}
															{/* Form groups for output specifications */}

															{view_edit_group !== undefined &&
																view_edit_group.edit_group !== undefined &&
																view_edit_group.edit_group !== null &&
																view_edit_group.edit_group.resizing_settings &&
																view_edit_group.edit_group.resizing_settings.map(
																	(items, index) => {
																		return (
																			<>
																				<div className="sub-head">
																					Output Specifications
																				</div>
																				<Form.Group className="mg-bottom15">
																					<Row className="form-group  flex-grow-1" sm={12}>
																						<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																							File Format
																						</Form.Label>
																					</Row>
																				</Form.Group>
																				<Form.Group
																					style={{ margin: "2px" }}
																					controlId="exampleForm.ControlTextarea1"
																				>
																					<div className="form-group">
																						<Row className="align-items-center">
																							
																							<Col sm={12} className="pl-2">
																								<Select
																								options={file}
																								onChange={(e) =>
																									setImageFileFormat(e.label)
																								}
																								value={{
																									label:
																										image_file_format !== ""
																											? image_file_format
																											: items.file_format, // Set the label property to the desired value
																									value:
																										image_file_format !== ""
																											? image_file_format
																											: items.file_format, // Set the value property to the corresponding value
																								}}
																								isDisabled={checkisEditableField()}
																								/>
																							</Col>
																						</Row>
																					</div>
																				</Form.Group>
																				<Form.Group className="mg-bottom15">
																					<Row className="form-group  flex-grow-1" sm={12}>
																						<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																							Size
																						</Form.Label>
																					</Row>
																				</Form.Group>					
																				<Form.Group
																					style={{ margin: "2px" }}
																				>
																					<div className="form-group">
																						<Row className="align-items-center">
																							
																							<Col sm={12} className="pl-7">
																								<div className="d-sm-flex align-items-center col-gap-10">
																									<div className="d-sm-flex col-gap-10 align-items-center">
																										<Form.Control
																											type="text"
																											placeholder="Height"
																											onChange={(e) =>
																												setImageHeight(e.target.value)
																											}
																											disabled={checkisEditableField()}
																											value={
																												image_height !== ""
																													? image_height
																													: items.height
																											}
																										/>
																									</div>
																									<div className="d-sm-flex align-items-center">
																										<img
																											style={{ width: "18px" }}
																											src={cross}
																											alt="mindreach images"
																										/>
																									</div>
																									<div className="d-sm-flex align-items-center col-gap-10">
																										<Form.Control
																											type="text"
																											placeholder="Width"
																											onChange={(e) =>
																												setImageWidth(e.target.value)
																											}
																											disabled={checkisEditableField()}
																											value={
																												image_width !== ""
																													? image_width
																													: items.width
																											}
																										/>
																									</div>
																								</div>
																							</Col>
																						</Row>
																					</div>
																				</Form.Group>
																				<Form.Group className="mg-bottom15">
																					<Row className="form-group  flex-grow-1" sm={12}>
																						<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																							DPI
																						</Form.Label>
																					</Row>
																				</Form.Group>
																				<Form.Group
																					style={{ margin: "2px" }}
																					controlId="exampleForm.ControlTextarea1"
																				>
																					<div className="form-group">
																						<Row className="align-items-center">
																							
																							<Col sm={12} className="pl-7">
																								<div className="d-sm-flex col-gap-20">
																										<Form.Control
																									type="text"
																									placeholder="2000"
																									autoComplete="off"
																									className="w-50"
																									value={
																										image_dpi !== ""
																											? image_dpi
																											: items.dpi
																									}
																									disabled={checkisEditableField()}
																									onChange={(e) =>
																										setImageDpi(e.target.value)
																									}
																									/>
																									<div className="flex-fill">
																										<Select
																											options={dpi}
																											onChange={(e) =>
																												setImageDpiType(e.label)
																											}
																											value={{
																												label:
																													image_dpi_type !== ""
																														? image_dpi_type
																														: items.dpi_type, // Set the label property to the desired value
																												value:
																													image_dpi_type !== ""
																														? image_dpi_type
																														: items.dpi_type, // Set the value property to the corresponding value
																											}}
																											isDisabled={checkisEditableField()}
																										/>
																									</div>
																								</div>
																							</Col>
																						</Row>
																					</div>
																				</Form.Group>
																				<Form.Group className="mg-bottom15">
																					<Row className="form-group  flex-grow-1" sm={12}>
																						<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																							Color Mode
																						</Form.Label>
																					</Row>
																				</Form.Group>
																				<Form.Group
																					style={{ margin: "2px" }}
																				>
																					<div className="form-group">
																						<Row className="align-items-center">
																							
																							<Col sm={12} className="pl-7">
																								<Select
																									options={ColorMode}
																									onChange={(e) =>
																										setImageColorMode(e.label)
																									}
																									value={{
																										label:
																											image_color_mode !== ""
																												? image_color_mode
																												: items.color_mode, // Set the label property to the desired value
																										value:
																											image_color_mode !== ""
																												? image_color_mode
																												: items.color_mode, // Set the value property to the corresponding value
																									}}
																									isDisabled={checkisEditableField()}
																								/>
																							</Col>
																						</Row>
																					</div>
																				</Form.Group>
																				<Form.Group className="mg-bottom15">
																					<Row className="form-group  flex-grow-1" sm={12}>
																						<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																							Align
																						</Form.Label>
																					</Row>
																				</Form.Group>
																				<Form.Group
																					style={{ margin: "2px" }}
																				>
																					<div className="form-group">
																						<Row className="align-items-center">
																							
																							<Col sm={12} className="pl-7">
																								<Select
																									options={Align}
																									onChange={(e) =>
																										setImageAlignment(e.label)
																									}
																									value={{
																										label:
																											image_alignment !== ""
																												? image_alignment
																												: items.alignment, // Set the label property to the desired value
																										value:
																											image_alignment !== ""
																												? image_alignment
																												: items.alignment, // Set the value property to the corresponding value
																									}}
																									isDisabled={checkisEditableField()}
																								/>
																							</Col>
																						</Row>
																					</div>
																				</Form.Group>
																				<Form.Group className="mg-bottom15">
																				<Row className="form-group  flex-grow-1" sm={12}>
																					<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																						Margins
																					</Form.Label>
																				</Row>
																			</Form.Group>
																			<Form.Group style={{ margin: "2px" }}>
																				<div className="form-group">
																					<Row className="align-items-center">
																						
																						<Col sm={12} className="pl-7">
																							<Select
																								options={Margin}
																								onChange={(e) =>
																									setImageMarginFormat(e.label)
																								}
																								value={{
																									label:
																										image_margin_format !== ""
																											? image_margin_format
																											: items.margin_format, // Set the label property to the desired value
																									value:
																										image_margin_format !== ""
																											? image_margin_format
																											: items.margin_format, // Set the value property to the corresponding value
																								}}
																								isDisabled={checkisEditableField()}
																							/>
																						</Col>
																						<Col
																							sm={7}
																							lg={7}
																							style={{
																								display: "flex",
																								marginLeft: "2rem",
																								marginTop: "12px",
																							}}
																						>
																							<div className="d-sm-flex col-gap-20 mt-2">
																								<div className="d-flex">
																									<ul className="margin-list d-flex" style={{paddingLeft:"3px"}}>
																										<li className={`${checkisEditableField() ? "disabled" :""}`}>
																											<Form.Control
																												type="text"
																												placeholder="T"
																												value={
																													image_margin_top !== ""
																														? image_margin_top
																														: items.margin_top
																												}
																												disabled={checkisEditableField()}
																												onChange={(e) =>
																													setImageMarginTop(
																														e.target.value
																													)
																												}
																											/>
																										</li>
																										<li className={`${checkisEditableField() ? "disabled" :""}`}>
																											<Form.Control
																												type="text"
																												placeholder="B"
																												value={
																													image_margin_bottom !== ""
																														? image_margin_bottom
																														: items.margin_bottom
																												}
																												disabled={checkisEditableField()}
																												onChange={(e) =>
																													setImageMarginBottom(
																														e.target.value
																													)
																												}
																											/>
																										</li>
																										<li className={`${checkisEditableField() ? "disabled" :""}`}>
																											<Form.Control
																												type="text"
																												placeholder="L"
																												value={
																													image_margin_left !== ""
																														? image_margin_left
																														: items.margin_left
																												}
																												disabled={checkisEditableField()}
																												onChange={(e) =>
																													setImageMarginLeft(
																														e.target.value
																													)
																												}
																											/>
																										</li>
																										<li className={`${checkisEditableField() ? "disabled" :""}`}>
																											<Form.Control
																												type="text"
																												placeholder="R"
																												value={
																													image_margin_right !== ""
																														? image_margin_right
																														: items.margin_right
																												}
																												disabled={checkisEditableField()}
																												onChange={(e) =>
																													setImageMarginRight(
																														e.target.value
																													)
																												}
																											/>
																										</li>
																									</ul>
																								</div>
																							</div>
																						</Col>
																						<Col sm={6}></Col>
																					</Row>
																				</div>
																			</Form.Group>
																			</>
																		);
																	}
																)}
															
															{view_edit_group !== undefined &&
															view_edit_group.edit_group === null &&
															setting_profile &&
															setting_profile.resizing_settings &&
															setting_profile.resizing_settings.map((items) => {
																		return (
																			<>
																				<div className="sub-head">
																					Output Specifications
																				</div>
																				<Form.Group className="mg-bottom15">
																					<Row className="form-group  flex-grow-1" sm={9}>
																						<Col sm={1}></Col>
																						<Form.Label className="col-sm-4 form-label form-check-label">
																							File Format
																						</Form.Label>
																					</Row>
																				</Form.Group>
																				<Form.Group
																					style={{ margin: "2px" }}
																					controlId="exampleForm.ControlTextarea1"
																				>
																					<div className="form-group">
																						<Row className="align-items-center">
																							
																							<Col sm={6} className="pl-2">
																								<Select
																									options={file}
																									onChange={(e) =>
																										setImageFileFormat(e.label)
																									}
																									value={{
																										label:
																											image_file_format !== ""
																												? image_file_format
																												: items.file_format, // Set the label property to the desired value
																										value:
																											image_file_format !== ""
																												? image_file_format
																												: items.file_format, // Set the value property to the corresponding value
																									}}
																									isDisabled={checkisEditableField()}
																								/>
																							</Col>
																						</Row>
																					</div>
																				</Form.Group>
																				<Form.Group className="mg-bottom15">
																					<Row className="form-group  flex-grow-1" sm={9}>
																						<Col sm={1}></Col>
																						<Form.Label className="col-sm-4 form-label form-check-label">
																							Size
																						</Form.Label>
																					</Row>
																				</Form.Group>					
																				<Form.Group
																					style={{ margin: "2px" }}
																				>
																					<div className="form-group">
																						<Row className="align-items-center">
																							
																							<Col sm={9} className="pl-7">
																								<div className="d-sm-flex align-items-center col-gap-10">
																									<div className="d-sm-flex col-gap-10 align-items-center">
																									<Form.Control
																										type="text"
																										placeholder="Height"
																										onChange={(e) =>
																											setImageHeight(e.target.value)
																										}
																										disabled={checkisEditableField()}
																										value={
																											image_height !== ""
																												? image_height
																												: items.height
																										}
																									/>
																									</div>
																									<div className="d-sm-flex align-items-center">
																										<img
																											style={{ width: "18px" }}
																											src={cross}
																											alt="mindreach images"
																										/>
																									</div>
																									<div className="d-sm-flex align-items-center col-gap-10">
																										<Form.Control
																											type="text"
																											placeholder="Width"
																											onChange={(e) =>
																												setImageWidth(e.target.value)
																											}
																											disabled={checkisEditableField()}
																											value={
																												image_width !== ""
																													? image_width
																													: items.width
																											}
																										/>
																									</div>
																								</div>
																							</Col>
																						</Row>
																					</div>
																				</Form.Group>
																				<Form.Group className="mg-bottom15">
																					<Row className="form-group  flex-grow-1" sm={9}>
																						<Col sm={1}></Col>
																						<Form.Label className="col-sm-4 form-label form-check-label">
																							DPI
																						</Form.Label>
																					</Row>
																				</Form.Group>
																				<Form.Group style={{ margin: "2px" }}>
																					<div className="form-group">
																						<Row className="align-items-center">
																							
																							<Col sm={10} className="pl-7">
																								<div className="d-sm-flex col-gap-20">
																									<Form.Control
																										type="text"
																										placeholder="2000"
																										autoComplete="off"
																										className="w-50"
																										value={
																											image_dpi !== "" ? image_dpi : items.dpi
																										}
																										disabled={checkisEditableField()}
																										onChange={(e) =>
																											setImageDpi(e.target.value)
																										}
																									/>
																									<div className="flex-fill">
																										<Select
																											options={dpi}
																											onChange={(e) =>
																												setImageDpiType(e.label)
																											}
																											value={{
																												label:
																													image_dpi_type !== ""
																														? image_dpi_type
																														: items.dpi_type, // Set the label property to the desired value
																												value:
																													image_dpi_type !== ""
																														? image_dpi_type
																														: items.dpi_type, // Set the value property to the corresponding value
																											}}
																											isDisabled={checkisEditableField()}
																										/>
																									</div>
																								</div>
																							</Col>
																						</Row>
																					</div>
																				</Form.Group>
																				<Form.Group className="mg-bottom15">
																					<Row className="form-group  flex-grow-1" sm={9}>
																						<Col sm={1}></Col>
																						<Form.Label className="col-sm-4 form-label form-check-label">
																							Color Mode
																						</Form.Label>
																					</Row>
																				</Form.Group>
																				<Form.Group
																					style={{ margin: "2px" }}
																				>
																					<div className="form-group">
																						<Row className="align-items-center">
																							
																							<Col sm={9} className="pl-7">
																								<Select
																									options={ColorMode}
																									onChange={(e) =>
																										setImageColorMode(e.label)
																									}
																									value={{
																										label:
																											image_color_mode !== ""
																												? image_color_mode
																												: items.color_mode, // Set the label property to the desired value
																										value:
																											image_color_mode !== ""
																												? image_color_mode
																												: items.color_mode, // Set the value property to the corresponding value
																									}}
																									isDisabled={checkisEditableField()}
																								/>
																							</Col>
																						</Row>
																					</div>
																				</Form.Group>
																				<Form.Group className="mg-bottom15">
																					<Row className="form-group  flex-grow-1" sm={9}>
																						<Col sm={1}></Col>
																						<Form.Label className="col-sm-4 form-label form-check-label">
																							Align
																						</Form.Label>
																					</Row>
																				</Form.Group>
																				<Form.Group
																					style={{ margin: "2px" }}
																				>
																					<div className="form-group">
																						<Row className="align-items-center">
																							
																							<Col sm={9} className="pl-7">
																								<Select
																									options={Align}
																									onChange={(e) =>
																										setImageAlignment(e.label)
																									}
																									value={{
																										label:
																											image_alignment !== ""
																												? image_alignment
																												: items.alignment, // Set the label property to the desired value
																										value:
																											image_alignment !== ""
																												? image_alignment
																												: items.alignment, // Set the value property to the corresponding value
																									}}
																									isDisabled={checkisEditableField()}
																								/>
																							</Col>
																						</Row>
																					</div>
																				</Form.Group>
																				<Form.Group className="mg-bottom15">
																				<Row className="form-group  flex-grow-1" sm={9}>
																					<Col sm={1}></Col>
																					<Form.Label className="col-sm-4 form-label form-check-label">
																						Margins
																					</Form.Label>
																				</Row>
																			</Form.Group>
																			<Form.Group style={{ margin: "2px" }}>
																				<div className="form-group">
																					<Row className="align-items-center">
																						
																						<Col sm={9} className="pl-7">
																							<Select
																								options={Margin}
																								onChange={(e) =>
																									setImageMarginFormat(e.label)
																								}
																								value={{
																									label:
																										image_margin_format !== ""
																											? image_margin_format
																											: items.margin_format, // Set the label property to the desired value
																									value:
																										image_margin_format !== ""
																											? image_margin_format
																											: items.margin_format, // Set the value property to the corresponding value
																								}}
																								isDisabled={checkisEditableField()}
																							/>
																						</Col>
																						<Col
																							sm={7}
																							lg={7}
																							style={{
																								display: "flex",
																								marginLeft: "2rem",
																								marginTop: "12px",
																							}}
																						>
																							<div className="d-sm-flex col-gap-20 mt-2">
																								<div className="d-flex">
																									<ul className="margin-list d-flex" style={{paddingLeft:"3px"}}>
																										<li className={`${checkisEditableField() ? "disabled" :""}`}>
																											<Form.Control
																												type="text"
																												placeholder="T"
																												value={
																													image_margin_top !== ""
																													? image_margin_top
																													: items.margin_top
																												}
																												disabled={checkisEditableField()}
																												onChange={(e) =>
																													setImageMarginTop(e.target.value)
																												}
																											/>
																										</li>
																										<li className={`${checkisEditableField() ? "disabled" :""}`}>
																											<Form.Control
																												type="text"
																												placeholder="B"
																												value={
																													image_margin_bottom !== ""
																														? image_margin_bottom
																														: items.margin_bottom
																												}
																												disabled={checkisEditableField()}
																												onChange={(e) =>
																													setImageMarginBottom(
																														e.target.value
																													)
																												}
																											/>
																										</li>
																										<li className={`${checkisEditableField() ? "disabled" :""}`}>
																											<Form.Control
																												type="text"
																												placeholder="L"
																												value={
																													image_margin_left !== ""
																														? image_margin_left
																														: items.margin_left
																												}
																												disabled={checkisEditableField()}
																												onChange={(e) =>
																													setImageMarginLeft(e.target.value)
																												}
																											/>
																										</li>
																										<li className={`${checkisEditableField() ? "disabled" :""}`}>
																											<Form.Control
																												type="text"
																												placeholder="R"
																												value={
																													image_margin_right !== ""
																														? image_margin_right
																														: items.margin_right
																												}
																												disabled={checkisEditableField()}
																												onChange={(e) =>
																													setImageMarginRight(
																														e.target.value
																													)
																												}
																											/>
																										</li>
																									</ul>
																								</div>
																							</div>
																						</Col>
																						<Col sm={6}></Col>
																					</Row>
																				</div>
																			</Form.Group>
																			</>
																		);
																	}
																)}

															{image_details_view !== undefined &&
															image_details_view.status !== undefined &&
															(checkEditorWorkProgress(
																image_details_view.status
															) ||
																isEditorAssigend) ? (
																<div></div>
															) : (
																<div className="text-center margin-18">
																	{(edit_select > 0 || customizedSettings) && (
																		<>
																			<Button
																				variant="primary"
																				className="btns"
																				onClick={() =>
																					handleUpdateImageEditGroup()
																				}
																			>
																				{loader ? (
																					<div>
																						<Loader />
																						Loading
																					</div>
																				) : (
																					"Save"
																				)}
																			</Button>
																		</>
																	)}
																</div>
															)}

															<Col sm={6}></Col>
														</div>
													)}
												</div>


											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						
						{/* <div
							className="modal-container--image"
							onClick={handleOverlayClick}
						>
						</div> */}
						{/* <RenameModal
							show={renameModalShow}
							onHide={() => {
								setRenameModalShow(false);
							}}
							title="Rename"
							text={renameTextDuplicate}
							setRenameTextDuplicate={setRenameTextDuplicate}
							imageName={imageName}
							setImageName={setImageName}
							imageExtensions={imageExtensions}
							handleRename={handleRenameImage}
						/>
						<DeleteModalConfirmation
							show={showModalDelete}
							onHide={() => {
								setShowModalDelete(false);
							}}
							branch={"image"}
							name={imageName}
							title={"Delete Image"}
							handleConfirm={() => handleDelete()}
						/> */}
					</div>
				)}
			</div>
		);
	}
};

export default Modal;
