import React, { useCallback, useEffect, useState, useRef } from "react";
//Importing drag and select Module
import {
	Box,
	boxesIntersect,
	useSelectionContainer,
} from "@air/react-drag-to-select";
import { isEqual } from "lodash";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Loader from "../../../components/Loader";
import StatusModal from "../../../components/Modal/StatusModal";

import ImageModal from "./../Components/ImageModal";
import { imageURL } from "../../../data/filepath";
import axios from "axios";
import { saveAs } from "file-saver";

import { useOutsideAlerter } from "./../../../utils/OutSideDivDeselect/QCDashboardOutsideClick";

import uploaded from "../../../assets/Images/uploaded.svg";
import CrossIcon from "./../../../assets/Images/actionsIcons/redTick.svg";
import psd from "../../../assets/Images/psd.svg";

// Importing API related files and routes
import { client } from "../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../../data/filepath";

import { QC_DOWNLOAD_PSD_FILE } from "../../../config/apiRoutes.api";

const EditorOrderDetails = ({
	images,
	setOriginalImageId,
	selectImageName,
	setSelectedImageName,
	setMultipleImagesId,
	multiple_images_id,
	setImageID,
	imageID,
	setSelectAll,
	wrapper,
	editGroups,
	editTypes,
	token,
	qcId,
	getEditorImages,
	setting_profile,
	handleFetchProfileSettings,
	setCtrlClickedSelect,
	timer,
	setTimer,
	elapsedTime,
	setElapsedTime,
	startTimer,
	stopTimer,
	startTime,
	setStartTime,
	handleCustomerLogged,
}) => {
	// Create a FormData object for request payload
	const bodyFormData = new FormData();

	const [clickSelect, setClickSelect] = useState(false);
	const [ctrlClicked, setCtrlClicked] = useState(false);
	const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
	const [firstSelectedIndex, setFirstSelectedIndex] = useState(0);
	const [selectedIndexes, setSelectedIndexes] = useState([]);
	const [image_details_view, setImageDetailsView] = useState({});
	const [image_details_view_id, setImageDetailsViewId] = useState("");
	const [secondSelectedIndex, setSecondSelectedIndex] = useState(0);
	const [showModal, setShowModal] = useState(false); // Image Modal
	const [dragAndSelectEnabled, setDragAndSelectEnabled] = useState(true);
	const [selectionBox, setSelectionBox] = useState();
	const [clickSelectOutside, setClickSelectOutside] = useState(false);
	const [currentDownloadId, setCurrentDownloadId] = useState(0);
	const [statusModal, setStatusModal] = useState(false);
	const [statusText, setStatusText] = useState("");
	const [batchId, setBatchID] = useState(""); // To set the batch id

	const selectableItems = useRef([]);
	const elementsContainerRef = useRef(null);
	localStorage.removeItem("clickSelect");
	localStorage.removeItem("ctrlClicked");
	localStorage.removeItem("selected_all");

	useOutsideAlerter(
		wrapper,
		setMultipleImagesId,
		setClickSelect,
		images,
		setCtrlClickedSelect,
		setSelectedIndexes,
		setDragAndSelectEnabled,
		setClickSelectOutside
	); // Outside clickable wrapper
	// Define the logic  for drag and select
	const { DragSelection } = useSelectionContainer({
		eventsElement: document.getElementById("root"),
		onSelectionChange: (box) => {
			/**
			 * Here we make sure to adjust the box's left and top with the scroll position of the window
			 * @see https://github.com/AirLabsTeam/react-drag-to-select/#scrolling
			 */
			const scrollAwareBox = {
				...box,
				top: box.top + window.scrollY,
				left: box.left + window.scrollX,
			};

			setSelectionBox(scrollAwareBox);
			const indexesToSelect = [];
			selectableItems.current.forEach((item, index) => {
				if (boxesIntersect(scrollAwareBox, item)) {
					indexesToSelect.push(Number(index));
				}
			});

			setSelectedIndexes(indexesToSelect);
		},
		onSelectionStart: () => {
			console.log("OnSelectionStart");
		},
		onSelectionEnd: () => {
			console.log("OnSelectionEnd");
			const imagesIds = [];
			const folderIds = [];
			const intersected = document.querySelectorAll(".selected");
			intersected.forEach((element) => {
				console.log(element.classList);
				if (element.classList.contains("order_images")) {
					imagesIds.push(String(element.getAttribute("id")));
				} else {
					if (
						element.classList.contains("folder") &&
						element.hasAttribute("id")
					) {
						folderIds.push(element.getAttribute("id"));
					}
				}
			});
			setMultipleImagesId(imagesIds);
		},
		selectionProps: {
			style: {
				border: "1px solid #c1c1c1",
				borderRadius: 2,
				// backgroundColor: "rgba(20, 137, 189, 0.5)",
				backgroundColor: "#c1c1c1",
				opacity: 0.6,
				zIndex: 99,
			},
		},
		isEnabled: dragAndSelectEnabled,
	});

	// Define the logic to set the images in ref
	useEffect(() => {
		if (elementsContainerRef.current && images.length > 0) {
			Array.from(elementsContainerRef.current.children).forEach((item) => {
				if (item.innerHTML) {
					console.log(" item = ", item);
					const { left, top, width, height } = item.getBoundingClientRect();
					selectableItems.current.push({
						left,
						top,
						width,
						height,
					});
				}
			});
		}
	}, [images]);

	const handleKeyUp = useCallback(
		(event) => {
			if (event.key === "Control" || event.key === "Meta") {
				setCtrlClicked(true);
			}
		},
		[ctrlClicked]
	);

	const handleChangeSelected = useCallback(
		(changeEvent, item, id, index, itemList) => {
			setSelectedImageName(item.path);
			setOriginalImageId(item.id);

			setCtrlClicked(false);
			setClickSelect(false);
			setCtrlClickedSelect(false);
			localStorage.removeItem("clickSelect");
			localStorage.removeItem("ctrlClicked");

			let itemId = [];
			const selectedItemCheck = changeEvent.currentTarget.id;
			console.log("typeof ", typeof id, id);

			// switch (true) {
			if (
				!changeEvent.ctrlKey ||
				!changeEvent.metaKey ||
				!changeEvent.shiftKey
			) {
				setDragAndSelectEnabled(true);
			}
			const allSelected = multiple_images_id.length === images.length;
			if ((allSelected && changeEvent.ctrlKey) || changeEvent.metaKey) {
				// Deselect all if all are currently selected
				console.log("call");
				setDragAndSelectEnabled(false);
				setCtrlClicked(true);
				localStorage.setItem("ctrlClicked", true);
				changeEvent.persist();
				const selectedItemsArray = Array.from(multiple_images_id);

				const customEquality = (item1, item2) => {
					return Number(item1) === Number(item2);
				};

				setMultipleImagesId((prevSelectedItems) => {
					console.log("prevSelectedItems:", prevSelectedItems);

					const isItemAlreadySelected = selectedItemsArray.some((item) =>
						isEqual(item, selectedItemCheck)
					);
					console.log("isItemAlreadySelected:", isItemAlreadySelected);

					const updatedItems = isItemAlreadySelected
						? prevSelectedItems.filter(
								(item) => !isEqual(item, selectedItemCheck)
						  )
						: Array.from(new Set([...prevSelectedItems, selectedItemCheck]));
					console.log("updatedItems:", updatedItems);

					return updatedItems;
				});
			}
			if (changeEvent.ctrlKey || changeEvent.metaKey) {
				changeEvent.persist();
				// Ctrl key is pressed
				setCtrlClicked(true);
				setDragAndSelectEnabled(false);
				localStorage.setItem("ctrlClicked", true);
				console.log("id selected", id);

				const selectedItemsArray = Array.from(multiple_images_id);
				const customEquality = (item1, item2) => {
					// Assuming items are objects and have an 'id' property
					return item1 === item2;
				};
				setMultipleImagesId((prevSelectedItems) => {
					const isItemAlreadySelected = selectedItemsArray.some((item) =>
						customEquality(item, selectedItemCheck)
					);

					const updatedItems = isItemAlreadySelected
						? prevSelectedItems.filter(
								(item) => !customEquality(item, selectedItemCheck)
						  )
						: Array.from(new Set([...prevSelectedItems, selectedItemCheck]));

					return updatedItems;
				});
			} else if (changeEvent.shiftKey) {
				itemId.push(...selectedItemCheck);
				setDragAndSelectEnabled(false);
				if (itemId.length === 0) {
					setMultipleImagesId([String(id)]);
				}
				// Shift key is pressed
				else {
					const selectedItemsArray = Array.from(multiple_images_id);
					const selectedIndicesArray = selectedItemsArray.map((itemId) =>
						itemList.findIndex((item) => item.id === itemId)
					);

					const currentIndex = index;
					const minSelectedIndex = Math.min(...selectedIndicesArray);
					const maxSelectedIndex = Math.max(...selectedIndicesArray);

					const start = Math.min(minSelectedIndex, currentIndex);
					const end = Math.max(maxSelectedIndex, currentIndex);

					const rangeIndices = Array.from(
						{ length: end - start + 1 },
						(_, i) => start + i
					);
					const rangeIds = rangeIndices.map((idx) => {
						// Check if itemList[idx] is defined before accessing its id
						if (itemList[idx] && itemList[idx].id) {
							return String(itemList[idx].id);
						}
					});

					setMultipleImagesId(rangeIds);
				}
			} else {
				// ... (other parts of the code)
				if (changeEvent.type === "click") {
					setSelectedIndexes([]);
					setMultipleImagesId([]);

					// const newClickSelect = !clickSelect;
					const newClickSelect = true;
					if (
						// changeEvent.currentTarget.classList.contains("ds-selectable") ||
						!changeEvent.currentTarget.classList.contains("border-image--card")
					) {
						setMultipleImagesId([String(id)]);
						setImageID(id);
						setDragAndSelectEnabled(false);
					}
					setClickSelect(newClickSelect);
					localStorage.setItem("clickSelect", true);
				}
			}
		},
		[
			firstSelectedIndex,
			lastSelectedIndex,
			multiple_images_id,
			images,
			// handleKeyUp,
			setMultipleImagesId,
			// ,
			// setClickSelect,
			clickSelect,
			wrapper,
			// changeEvent,
			elementsContainerRef,
			ctrlClicked,
			setDragAndSelectEnabled,
		]
	);
	const handleClickImageDetails = (items, id) => {
		setImageDetailsViewId(id);
		setImageDetailsView(items);
		setShowModal(true);
		let order_images = document.querySelectorAll(".order_images");
		for (var i = 0; i < order_images.length; i++) {
			order_images[i].style.zIndex = 1;
		}
		document.body.classList.add("modal-open");
	};
	const seledtedClass = useCallback(
		(items_Selected, id) => {
			return items_Selected.length &&
				items_Selected.find((selectedRow) => selectedRow == id)
				? "box exclude-click border-image--card layer-image--selector "
				: "box exclude-click";
		},
		[multiple_images_id]
	);

	const selectedLayer = useCallback(
		(items_Selected, id) => {
			return items_Selected.length &&
				items_Selected.find((selectedRow) => selectedRow == id)
				? ` card-button--selected`
				: ``;
		},
		[images]
	);
	const seledtedClassCheckbox = useCallback(
		(items_Selected, id) => {
			return items_Selected.length &&
				items_Selected.find((selectedRow) => selectedRow == id)
				? true
				: false;
		},
		[multiple_images_id]
	);
	const removeFileExtension = (filename) => {
		const lastDotIndex = filename.lastIndexOf(".");

		if (lastDotIndex !== -1) {
			return filename.substring(0, lastDotIndex);
		} else {
			return filename; // No file extension found
		}
	};

	const handleDownloadPSDFile = async (event, item) => {
		event.preventDefault();
		setCurrentDownloadId(item.id);

		const fileName = item.image_path;
		const headers = {
			Authorization: token
				? "Bearer " + `${token.slice(1, token.length - 1)}`
				: "",
		};

		const bodyFormData = new FormData();
		bodyFormData.append("qc_id", qcId);
		bodyFormData.append("image_id", Number(item.id));

		try {
			const response = await axios.post(
				apiURL + QC_DOWNLOAD_PSD_FILE,
				bodyFormData,
				{
					headers: headers,
				}
			);

			if (response.data.message === "Psd file not uploaded yet") {
				setStatusText(response.data.message);
				setStatusModal(true);
				setCurrentDownloadId(0);
			} else {
				const downloadResponse = await axios.post(
					apiURL + QC_DOWNLOAD_PSD_FILE,
					bodyFormData,
					{
						headers: headers,
						responseType: "blob",
					}
				);

				saveAs(downloadResponse.data, `${removeFileExtension(fileName)}.psd`);
				setCurrentDownloadId(0);
				setSelectedImageName("");
			}
		} catch (error) {
			setCurrentDownloadId(0);
			console.error("Error downloading file:", error);
		}
	};

	useEffect(() => {
		if (multiple_images_id.length === 0) {
			setDragAndSelectEnabled(true);
		}
	}, [multiple_images_id]);
	useEffect(() => {
		if (!clickSelectOutside) {
			setSelectAll(false);
		}
	}, [clickSelectOutside]);

	const handleOutsideClick = (e) => {
		const modal = document.querySelector(".my-modal");

		if (modal && !modal.contains(e.target)) {
			setStatusModal(false);
		}
	};
	useEffect(() => {
		document.addEventListener("mousedown", handleOutsideClick);

		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, []);
	const svgCode = (count) => {
		return `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20" fill="none">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M6 4.5V0H10.5C10.8978 0 11.2794 0.158035 11.5607 0.43934C11.842 0.720644 12 1.10218 12 1.5V3H9L3 9V16H1.5C1.10218 16 0.720644 15.842 0.43934 15.5607C0.158035 15.2794 0 14.8978 0 14.5V6H4.5C4.89782 6 5.27936 5.84196 5.56066 5.56066C5.84196 5.27936 6 4.89782 6 4.5ZM5 4.5V0.25L0.25 5H4.5C4.63261 5 4.75979 4.94732 4.85355 4.85355C4.94732 4.75979 5 4.63261 5 4.5Z" fill="white"/>
		<path d="M10 4V8.5C10 8.89782 9.84196 9.27936 9.56066 9.56066C9.27936 9.84196 8.89782 10 8.5 10H4V18.5C4 18.8978 4.15804 19.2794 4.43934 19.5607C4.72064 19.842 5.10218 20 5.5 20H14.5C14.8978 20 15.2794 19.842 15.5607 19.5607C15.842 19.2794 16 18.8978 16 18.5V5.5C16 5.10218 15.842 4.72064 15.5607 4.43934C15.2794 4.15804 14.8978 4 14.5 4H10ZM9 4.25V8.5C9 8.63261 8.94732 8.75979 8.85355 8.85355C8.75979 8.94732 8.63261 9 8.5 9H4.25L9 4.25Z" fill="white"/>
		<!-- Use a <style> element to set the font size -->
			<style>
			  text {
				font-size: 9px;
				font-weight:500;
			  }
			</style>
			<!-- Adjust the y attribute to add margin on the top -->
			<text x="6" y="18" fill="green">${count}</text>
	  </svg>`;
	};
	const renderContent = useCallback(
		(item) => {
			return (
				<div key={item.id}>
					{item.duplication_settings.length !== 0 && (
						<span className="checkbox-order--duplicate">
							<div
								dangerouslySetInnerHTML={{
									__html: svgCode(
										(item.duplication_settings || []).length.toString()
									),
								}}
							/>
						</span>
					)}
				</div>
			);
		},
		[images]
	);

	return (
		<>
			<DragSelection />
			<div
				className="editororderdetails editor editororderdetails-new image noselect"
				style={{
					overflowY: "auto",
				}}
			>
				<div className="detailslist coordibox" ref={elementsContainerRef}>
					{images.map((items, index) => {
						return (
							<div
								className={
									"cardsContainer order_images" +
									(selectedIndexes.indexOf(Number(index)) >= 0
										? " selected"
										: "")
								}
								data-data="111"
								style={{ zIndex: 1 }}
								id={items.id}
								custom-id={items.id}
							>
								<Card
									id={items.id}
									key={index}
									className={seledtedClass(multiple_images_id, items.id)}
									// ref={wrapper}
									onDoubleClick={() => handleClickImageDetails(items, items.id)}
									onClick={(event) => {
										handleChangeSelected(event, items, items.id, index, images);
										// multiple_images_id.length === 0
										// 	? setFirstSelectedIndex(items.id)
										// 	: setSecondSelectedIndex(items.id);

										localStorage.setItem("click_type", "image");
									}}
								>
									<div
										style={{
											height: "auto",
											background: "#ffff",
											overflow: "hidden",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
										id={items.id}
										key={index}
										ref={wrapper}
									>
										<img
											id={items.id}
											variant="top"
											// width={200}
											// height={100}
											className="image-rendering"
											loading="lazy"
											alt="Mindreach"
											// layout="fill"
											src={`${imageURL}/${items.customer.email}/${items.batch_number}/${items.order.order_number}/images/${items.image_path}`}
										/>
									</div>
									<input
										type="checkbox"
										className="checkbox-order--sub"
										value={items.id}
										id={items.id}
										checked={seledtedClassCheckbox(
											multiple_images_id,
											items.id
										)}
										key={index}
									/>
									{renderContent(items)}
									<Card.Body>
										<Card.Text>{items.path}</Card.Text>

										<div className="d-sm-flex flex-sm-wrap align-items-center justify-content-center btn-gap">
											<div
												className="d-sm-flex flex-sm-wrap align-items-center justify-content-center btn-gap"
												style={{ marginTop: "3px" }}
											>
												<div
													className={
														currentDownloadId !== items.id
															? "bothbtn-download"
															: ""
													}
													style={{
														marginRight:
															(currentDownloadId === items.id ? "7" : "0") +
															"px",
													}}
													onClick={(e) => {
														e.stopPropagation();
														handleDownloadPSDFile(e, items);
													}}
												>
													{currentDownloadId !== items.id ? (
														<>
															<span>
																<img
																	src={psd}
																	className="img-fluid "
																	alt="mindreach images"
																	style={{
																		margin: "0px 5px 0px 5px",
																		width: "17px",
																	}}
																/>
															</span>
															PSD
														</>
													) : (
														<span>
															<Loader
																id={`loader_${items.id}`}
																className={"image_loader"}
															/>
														</span>
													)}
												</div>
												<div className="bothbtn">
													<span>
														{items.status === "completed" ? (
															<img
																src={uploaded}
																className="img-fluid"
																alt="mindreach images"
															/>
														) : items.status === "reassigned" ? (
															<img
																src={CrossIcon}
																className="img-fluid"
																alt="mindreach images"
															/>
														) : (
															""
														)}
													</span>
													{items.status === "completed"
														? "Approved"
														: items.status === "reassigned"
														? "Revision"
														: "Pending"}
												</div>
											</div>
										</div>
									</Card.Body>
								</Card>
							</div>
						);
					})}
					<ImageModal
						setShowModal={setShowModal}
						showModal={showModal}
						image_details_view={image_details_view}
						setImageDetailsView={setImageDetailsView}
						editTypes={editTypes}
						editGroups={editGroups}
						// setMultipleImagesId={setMultipleImagesId}
						// selectImageName={selectImageName}
						token={token}
						setImageID={setImageID}
						imageID={imageID}
						images={images}
						qcId={qcId}
						getEditorImages={getEditorImages}
						handleFetchProfileSettings={handleFetchProfileSettings}
						setting_profile={setting_profile}
						timer={timer}
						setTimer={setTimer}
						elapsedTime={elapsedTime}
						setElapsedTime={setElapsedTime}
						startTimer={startTimer}
						stopTimer={stopTimer}
						startTime={startTime}
						setStartTime={setStartTime}
						handleCustomerLogged={handleCustomerLogged}
					/>
					<StatusModal
						show={statusModal}
						onHide={() => setStatusModal(false)}
						title="PSD Download"
						status={`${statusText}`}
						className="modal-body-body--success"
					/>
				</div>
			</div>
		</>
	);
};

export default EditorOrderDetails;
