export const closeEditType = ({
	setSettingsProfile,
	setImageID,
	setMultipleImagesId,
	setChecked,
	setChecked1,
	setChecked2,
	setChecked3,
}) => {
	return () => {
		console.log("closeEditType function called");

		// setMultipleImagesId([]);
		// setFolderNameFirst([]);
		// setFolderListName([]);
		// setSelectedIndexes([]);
		// setFolderSelect([]);
		// setFoldersPath([]);
		// setClickSelect(false);
		// setReassignedModal(false);
		// setSelectAll(false);
		// setDragAndSelectEnabled(true);
		localStorage.removeItem("clickSelect");
		localStorage.removeItem("ctrlClicked");
		localStorage.removeItem("draggedCalled");
		localStorage.removeItem("select_all");
		setChecked(false);
		setChecked1(false);
		setChecked2(false);
		setChecked3(false);

		console.log("State has been reset");
	};
};
