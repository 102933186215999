import React from "react";
import ColorJson from "./../../json/colorCodeHexa.json";

// Generate initials from the name
const getInitials = (name) => {
	const nameParts = name.split(" ");
	if (nameParts.length === 1) {
		return nameParts[0][0].toUpperCase();
	}
	return nameParts[0][0].toUpperCase() + nameParts[1][0].toUpperCase();
};

// A hash function to generate a consistent value from a string
const hashStringToNumber = (str) => {
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	return hash;
};

// Generate a consistent color for a given name
const getColorFromName = (name) => {
	const colors = ColorJson;
	const hash = hashStringToNumber(name);
	const colorIndex = Math.abs(hash) % colors.length; // Ensures the index is within bounds
	return colors[colorIndex];
};

const Avatar = ({ name }) => {
	const initials = getInitials(name);
	const backgroundColor = getColorFromName(name);

	return (
		<div
			style={{
				backgroundColor,
				borderRadius: "50%",
				width: "40px",
				height: "40px",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				fontSize: "17px",
				textTransform: "uppercase",
				color: "#f7f7f7",
			}}
		>
			{initials}
		</div>
	);
};

export default Avatar;
