import React, { useRef, useState, useEffect } from "react";

const CustomPanViewer = ({
	zoom = 1.0,
	setZoom,
	dx,
	dy,
	children,
	className,
	style = {},
	isDraggingCanvas,
}) => {
	const viewerRef = useRef(null);
	const [isPanning, setIsPanning] = useState(false);
	const [panOffset, setPanOffset] = useState({ dx: dx, dy: dy });
	const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });

	const handleMouseDown = (e) => {
		if (isDraggingCanvas) {
			e.preventDefault();
			setIsPanning(true);
			setStartPoint({
				x: e.clientX - panOffset.dx,
				y: e.clientY - panOffset.dy,
			});
		}
	};

	const handleMouseMove = (e) => {
		if (isDraggingCanvas) {
			if (isPanning) {
				const dx = e.clientX - startPoint.x;
				const dy = e.clientY - startPoint.y;
				setPanOffset({ dx, dy });
			}
		}
	};

	const handleMouseUp = () => {
		if (isDraggingCanvas) {
			setIsPanning(false);
			// Optionally update the zoom state when the panning ends
			setZoom((prevZoom) => ({
				...prevZoom,
				dx: panOffset.dx,
				dy: panOffset.dy,
			}));
		}
	};

	useEffect(() => {
		const viewer = viewerRef.current;
		if (viewer && isDraggingCanvas) {
			viewer.addEventListener("mousedown", handleMouseDown);
			window.addEventListener("mousemove", handleMouseMove);
			window.addEventListener("mouseup", handleMouseUp);

			return () => {
				viewer.removeEventListener("mousedown", handleMouseDown);
				window.removeEventListener("mousemove", handleMouseMove);
				window.removeEventListener("mouseup", handleMouseUp);
			};
		}
	}, [isDraggingCanvas]);

	return (
		<div
			ref={viewerRef}
			style={{
				// overflow: "auto",
				cursor: isPanning ? "crosshair" : "crosshair",
				transform: `matrix(${isDraggingCanvas ? panOffset.dx : 0}px, ${
					isDraggingCanvas ? panOffset.dy : 0
				}px,1,1)`,
				scale: `${zoom}`,
				...style,
			}}
			className={className}
		>
			{children}
		</div>
	);
};

export default CustomPanViewer;
