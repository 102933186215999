import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import MyVerticallyCenteredModal from "../../../components/Modal";
import Loader from "../../../components/Loader";

import { client } from "../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../../data/filepath";
import {
  ADMIN_UPDATE_TERMS_AND_CONDITION,
  ADMIN_GET_TERMS_AND_CONDITION,
} from "./../../../config/apiRoutes.api";
import { TERMS_AND_CONDITION_VIEW_PAGE } from "../../../config/routes";

const EditField = () => {
  const navigate = useNavigate();
  const [fieldValue, setFieldValue] = useState("");
  const [responseMessage, setResponse] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const bodyFormData = new FormData();
  const token = sessionStorage.getItem("token");
  const request = client(token);

  const handleChange = (value) => {
    setFieldValue(value);
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    if (token) {
      bodyFormData.append("value", fieldValue);
      event.preventDefault();
      const request = client(token);

      request
        .post(apiURL + `${ADMIN_UPDATE_TERMS_AND_CONDITION}`, bodyFormData)
        .then((response) => {
          console.log(response);
          setResponse(response.data.message ? response.data.message : "");
          setModalShow(true);
          setDisabled(false);
        })
        .catch((err) => {
          console.log(err);
          setDisabled(false);
        });
    }
  };

  useEffect(() => {
    request
      .get(apiURL + `${ADMIN_GET_TERMS_AND_CONDITION}`)
      .then((response) => {
        console.log("response ==>", response.data.data);
        if (response.data.status) {
          setFieldValue(response.data.data.value);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  if (!fieldValue && typeof fieldValue !== undefined) return <Loader />;

  // console.log(!!dataEdit.length);
  return (
    <>
      <div>
        <div className="rightbar pr-11">
          <div className="section-title">
            <h2>Update Terms and Condition</h2>
          </div>
          <div>
          <Row>
            <Col sm={9} lg={5}>
              <Form className="sp_form">
                <Form.Group className="mb-3">
                  <div className="form-group">
                    <ReactQuill value={fieldValue} onChange={handleChange} />
                  </div>
                </Form.Group>
              </Form>
            </Col>
          </Row>
          </div>
          <Row>
            <Col sm={9} lg={5}>
              <Form>
                <Form.Group className="mb-3">
                  <div className="form-group">
                    <div className=" justify-content-between btn-cls flex-row--reverse">
                      <div className="">
                        <Button
                          variant=""
                          className="puple btns mr-3"
                          type="submit"
                          onClick={() => navigate(TERMS_AND_CONDITION_VIEW_PAGE)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          className="btns"
                          type="submit"
                          disabled={disabled}
                          onClick={(e) => handleSubmit(e)}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          navigate(TERMS_AND_CONDITION_VIEW_PAGE);
        }}
        heading={"Success"}
        message={responseMessage}
        className="modal-body-body--success"
      />
    </>
  );
};

export default EditField;
