import React, { useCallback, useEffect, useState } from "react";
//Importing the bootstrap components
import {
	Button,
	Row,
	Col,
	Form,
	Tab,
	Tabs,
	Dropdown,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";
//Importing the images
import CanceIcon from "./../../../../assets/Images/actionsIcons/cancel_Final.svg";
import Pencil from "./../../../../assets/Images/coordinator_actions_icon/pencil_edit.svg";
import DeleteIcon from "./../../../../assets/Images/delete_white_icons.svg";
import ArrowDown from "./../../../../assets/Images/coordinator_actions_icon/Arrow_down.svg";
//Importing the react select components
import Select from "react-select";

import ToastAlert from "../../../../components/ToastAlert";
//Importing the Loader components
import Loader from "./../../../../components/Loader";
import cross from "./../../../../assets/Images/cross-3.svg";
import { closeEditType } from "../../../../utils/CloseTheEditType";

// Importing API related files and routes

import { client } from "./../../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "./../../../../data/filepath";
import {
	CO_ORDINATOR_VIEW_EDIT_GROUP,
	CO_ORDINATOR_UPDATE_EDIT_GROUP,
	CO_ORDINATOR_DUPLICATE_SETTINGS_BY_ID,
	CO_ORDINATOR_DUPLICATE_IMAGES,
	ADMIN_GET_COORDINATORS_DETAILS,
} from "../../../../config/apiRoutes.api";

const customSelectStyles = {
	menuList: (provided) => {
		return {
			...provided,
			"& :first-child": {
				fontWeight: "bold",
				fontSize: "13px",
			},
		};
	},
};

const customSelectStyle = {
	menuList: (provided) => {
		return {
			...provided,
			fontSize: "13px",
		};
	},
};

const SingleSelectorRightSideBar = (props) => {
	let {
		image_details_view,
		editTypes,
		setEditTypes,
		setMultipleImagesId,
		selectImageName,
		token,
		id,
		idSelected,
		setClickSelect,
		setSelectAll,
		setFolderListName,
		setFoldersPath,
		customer_id,
		setMergedImageModal,
		setDuplicateImageModal,
		setEditorType,
		edit_select,
		setting_profile,
		setRenameModalShow,
		renameModalShow,
		renameTextDuplicate,
		setRenameTextDuplicate,
		setImageExtensions,
		imageExtensions,
		setImageName,
		imageName,
		setRenameId,
		renameId,
		showModalDelete,
		setShowModalDelete,
		getImageExtension,
		onClickDuplicateImage,
		handleFetchProfileSettings,
		imageID,
		setFolderName,
		setParentFolderDetails,
		setBatchNumber,
		setImages,
		setFolderList,
		setFolder,
		setImageMainFolder,
		setFolderMainFolder,
		setMainImageCount,
		setMainImageSettingsCount,
		setOrderNumber,
		setSubFolderOpen,
		setDisplay,
		batchId,
		setImageID,
		editGroups,
		copyAmounts,
		fetchOrderDuplicateSettings,
		setSettingsProfile,
		setEditId,
		edit_id,
		fetchDataImages,
		fetchFolder,
		setDataLoader,
		determineCustomerView,
		checked,
		checked1,
		checked2,
		checked3,
		setChecked,
		setChecked1,
		setChecked2,
		setChecked3,
		newSettingsClick,
		setNewSettingsClicked,
	} = props;
	// Create a FormData object for request payload
	const bodyFormData = new FormData();

	//Role Checking
	const roleJson = sessionStorage.getItem("role");

	const role = roleJson ? JSON.parse(roleJson) : null;
	const currentUserRoles = [role !== null ? role.role : "login"];
	const currentUserId = [role !== null ? role.pivot.user_id : ""];

	// Create an HTTP client with the token
	const request = client(token);

	//Declarations of state
	const [error, setError] = useState("");
	const [status, setStatus] = useState("");
	const [loader, setLoader] = useState(false); //Loader State

	const [groupSelect, setGroupSelect] = useState(false);

	const [selected, setSelected] = useState(false);
	const [selected1, setSelected1] = useState(false);
	const [selected2, setSelected2] = useState(false);
	const [selected3, setSelected3] = useState(false);
	const [selectedDuplicateSettings, setSelectedDuplicateSettings] =
		useState(false);
	const [selectedDuplicateSettings1, setSelectedDuplicateSettings1] =
		useState(false);
	const [selectedDuplicateSettings2, setSelectedDuplicateSettings2] =
		useState(false);
	const [selectedDuplicateSettings3, setSelectedDuplicateSettings3] =
		useState(false);
	const [customizedSettings, customizedSettingsEnabled] = useState(false);
	const [previousSelectedValue, setPreviousSelectedValue] = useState("");

	//To update the select group
	const [group_name_select, setEditGroupNameSelect] = useState("New Settings");
	// To set the state for add
	const [background_removal_color_type, setBackgroundRemovalColorType] =
		useState("");
	const [background_removal_color_code, setBackgroundRemovalColorCode] =
		useState("");
	const [background_adjustment_color_code, setBackgroundAdjustmentColorCode] =
		useState("");
	const [background_adjustment_color_type, setBackgroundAdjustmentColorType] =
		useState("");
	const [
		background_removal_color_type_duplicate,
		setBackgroundRemovalColorTypeDuplicate,
	] = useState("");
	const [
		background_removal_color_code_duplicate,
		setBackgroundRemovalColorCodeDuplicate,
	] = useState("");
	const [
		background_adjustment_color_code_duplicate,
		setBackgroundAdjustmentColorCodeDuplicate,
	] = useState("");
	const [resizing_height_duplicate, setResizingHeightDuplicate] = useState("");
	const [resizing_width_duplicate, setResizingWidthDuplicate] = useState("");
	const [
		background_adjustment_color_type_duplicate,
		setBackgroundAdjustmentColorTypeDuplicate,
	] = useState("");
	const [view_edit_group, setViewEditGroup] = useState({});
	const [group_name, setGroupName] = useState("");
	const [new_edit_group_name, setNewEditGroupName] = useState("");
	const [resizing_file_format, setResizingFileFormat] = useState("");
	const [resizing_height, setResizingHeight] = useState("");
	const [resizing_width, setResizingWidth] = useState("");
	const [resizing_dpi, setResizingDpi] = useState("");
	const [resizing_dpi_type, setResizingDpiType] = useState("");
	const [resizing_color_mode, setResizingColorMode] = useState("");
	const [resizing_alignment, setResizingAlignment] = useState("");
	const [resizing_margin_format, setResizingMarginFormat] = useState("");
	const [resizing_margin_top, setResizingMarginTop] = useState("");
	const [resizing_margin_bottom, setResizingMarginBottom] = useState("");
	const [resizing_margin_right, setResizingMarginRight] = useState("");
	const [resizing_margin_left, setResizingMarginLeft] = useState("");
	const [image_file_format, setImageFileFormat] = useState("");
	const [image_height, setImageHeight] = useState("");
	const [image_width, setImageWidth] = useState("");
	const [image_dpi, setImageDpi] = useState("");
	const [image_dpi_type, setImageDpiType] = useState("");
	const [image_color_mode, setImageColorMode] = useState("");
	const [image_alignment, setImageAlignment] = useState("");
	const [image_margin_format, setImageMarginFormat] = useState("");
	const [image_margin_top, setImageMarginTop] = useState("");
	const [image_margin_bottom, setImageMarginBottom] = useState("");
	const [image_margin_right, setImageMarginRight] = useState("");
	const [image_margin_left, setImageMarginLeft] = useState("");
	const [group, setGroup] = useState([]);
	const [isDropdownOpen, setDropdownOpen] = useState(false);
	const [toggleMessage, setToggleMessage] = useState(false);
	const [duplicateImageName, setDuplicateImageName] = useState("");
	const [duplicateImageId, setDuplicateImageId] = useState("");
	const [viewDuplicateModal, setViewDuplicateModal] = useState(false);
	const [viewDuplicateSettingsById, setViewDuplicateSettings] = useState({});
	const [fileFormatDuplicate, setFileFormatDuplicate] = useState("");
	const [dpiTypeDuplicate, setDpiTypeDuplicate] = useState("");
	const [colorModeDuplicate, setColorModeDuplicate] = useState("");
	const [alignModeDuplicate, setAlignModeDuplicate] = useState("");
	const [marginFormatDuplicate, setMarginFormatDuplicate] = useState("");
	const [heightDuplicate, setHeightDuplicate] = useState("");
	const [widthDuplicate, setWidthDuplicate] = useState("");
	const [dpiDuplicate, setDpiDuplicate] = useState("");
	const [marginTopDuplicate, setMarginTopDuplicate] = useState("");
	const [marginBottomDuplicate, setMarginBottomDuplicate] = useState("");
	const [marginRightDuplicate, setMarginRightDuplicate] = useState("");
	const [marginLeftDuplicate, setMarginLeftDuplicate] = useState("");
	const [previousImageSelected, previousImageID] = useState("");
	const [resizingOriginalSize, setResizingOriginalSize] = useState(false);
	const [originalSize, setOriginalSize] = useState(false);
	const [file_format_duplicate_size, setFileFormatDuplicateSize] = useState("");
	const [margin_format_duplicate, setMarginFormatDuplicateSize] = useState("");
	const [margin_top_duplicateSize, setMarginTopDuplicateSize] = useState("");
	const [margin_bottom_duplicateSize, setMarginBottomDuplicateSize] =
		useState("");
	const [margin_left_duplicateSize, setMarginLeftDuplicateSize] = useState("");
	const [margin_right_duplicateSize, setMarginRightDuplicateSize] =
		useState("");

	const [copyTabEditTypeSelected, setCopyTabEditTypes] = useState([]);
	const [disableResizing, setDisableResizing] = useState([]);
	const [disableOutputSpecifications, setDisableOutputSpecifications] =
		useState([]);
	const [fieldArray, setFieldArray] = useState({});
	const [fieldError, setFieldError] = useState({});

	const [errorTabs, setErrorTabs] = useState("");
	const [isFormSubmitted, setFormSubmitted] = useState(false);
	const [isCheckedOutputSpecifications, setIsCheckedOutputSpecification] =
		useState([]);
	const [isCheckedResizing, setIsCheckedResizing] = useState([]);

	let editTypeTempArray = [];

	const goToTop = () =>
		window.scroll({
			behavior: "smooth", // Optional: for smooth scrolling
			block: "start", // Align to the top of the scrollable ancestor
			top: 0,
			left: 0,
		});
	/**
	 * Transform an array of customer edit types into an array of objects with label, value, and additional properties.
	 * @param {Array} customerEditTypes - Array of customer edit types.
	 * @returns {Array} - Transformed array of objects with label, value, and additional properties.
	 */
	const selectLabel = (customerEditTypes) =>
		customerEditTypes.map(({ group_name: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of array as label and value return a new array of object like [{value:"",label:""}]
	const selectLabelDuplicate = (customerDuplicateImages) =>
		customerDuplicateImages &&
		customerDuplicateImages.map(
			({ image_name: label, id: value, ...rest }) => ({
				label,
				value,
				...rest,
			})
		);
	function LocalImageLoader({ src, width, quality }) {
		return <Loader />;
	}
	const fieldValidationRules = {
		file_format: "File Format is required",
		height: "",
		width: "",
		dpi: "",
		dpi_type_Value: "",
		color_mode_Value: "",
		alignment_Value: "",
		margin_format: "",
		margin_top: "",
		margin_bottom: "",
		margin_right: "",
		margin_left: "",
	};
	const file = [
		{ label: "JPEG", value: 355 },
		{ label: "PNG", value: 54 },
		{ label: "JPG", value: 54 },
	];
	const selectBackgrondRemoval = [
		{ label: "Custom", value: 355 },
		{ label: "Transparent", value: 54 },
	];
	const selectedAmount = [
		{ label: "1", value: 355 },
		{ label: "2", value: 54 },
		{ label: "3", value: 54 },
	];
	const dpi = [
		{ label: "JPEG", value: 355 },
		{ label: "PNG", value: 54 },
		{ label: "JPG", value: 54 },
	];

	const ColorMode = [
		{ label: "Original", value: 355 },
		{ label: "Edited", value: 54 },
	];
	const Align = [
		{ label: "center", value: 355 },
		{ label: "start", value: 54 },
		{ label: "end", value: 55 },
	];

	const Margin = [
		{ label: "pixel", value: 355 },
		{ label: "em", value: 54 },
		{ label: "rem", value: 55 },
		{ label: "%", value: 56 },
	];
	function removeDuplicatesWithSet(array) {
		return [...new Set(array.map((item) => item.toString()))];
	}

	const handleCloseEditTypes = closeEditType({
		setSettingsProfile,
		setImageID,
		setMultipleImagesId,
		setChecked,
		setChecked1,
		setChecked2,
		setChecked3,
	});

	useEffect(() => {
		if (isEmptyObject(viewDuplicateSettingsById) === true) {
			setBackgroundRemovalColorTypeDuplicate(
				viewDuplicateSettingsById.background_removal_color_type
			);
			setBackgroundRemovalColorCodeDuplicate(
				viewDuplicateSettingsById.background_removal_color_code
			);
			setBackgroundAdjustmentColorCodeDuplicate(
				viewDuplicateSettingsById.background_adjustment_color_code
			);
			setBackgroundAdjustmentColorTypeDuplicate(
				viewDuplicateSettingsById.background_adjustment_color_type
			);
			setResizingHeightDuplicate(viewDuplicateSettingsById.resizing_height);
			setResizingWidthDuplicate(viewDuplicateSettingsById.resizing_width);
			setResizingOriginalSize(
				viewDuplicateSettingsById.same_as_original_resizing === 0 ? false : true
			);
			setFileFormatDuplicateSize(viewDuplicateSettingsById.file_format);
			setMarginFormatDuplicateSize(viewDuplicateSettingsById.margin_format);
			setMarginTopDuplicateSize(viewDuplicateSettingsById.margin_top);
			setMarginBottomDuplicateSize(viewDuplicateSettingsById.margin_bottom);
			setMarginLeftDuplicateSize(viewDuplicateSettingsById.margin_left);
			setMarginRightDuplicateSize(viewDuplicateSettingsById.margin_right);
			setEditId(viewDuplicateSettingsById.extra_edit_types);
		}
	}, [viewDuplicateSettingsById]);

	/**
	 * Handles check change event
	 * @param {Number} item - The edit type id.
	 * @param {Number} index - The tab index.
	 */
	const checkIsDisabledResizing = (index) => {
		if (disableResizing && disableResizing && disableResizing.length > 0) {
			return disableResizing.includes(index);
		}
		return;
	};

	/**
	 * Handles check change event
	 * @param {String} value - The field object.
	 * @param {String} field - The actual field name.
	 * @param {Number} tabIndex - The selected tab index.
	 * Return {} the new state of error and success
	 */

	const updateFieldState = (value, field, tabIndex) => {
		let fieldArrayTemp = [...fieldArray];
		let fieldErrorTemp = [...fieldError];
		let tempFieldArray = { ...fieldArrayTemp[tabIndex] };
		let tempFieldError = { ...fieldErrorTemp[tabIndex] };
		if (value) {
			tempFieldArray[field] = value;
			tempFieldError[field] = "";
		} else {
			tempFieldArray[field] = "";
			tempFieldError[field] = fieldValidationRules[field];
		}
		fieldArrayTemp[tabIndex] = tempFieldArray;
		fieldErrorTemp[tabIndex] = tempFieldError;
		setFieldArray(fieldArrayTemp);
		setFieldError(fieldErrorTemp);
		validateCopyForm();
	};

	/**
	 * Handles check change event
	 * @param {Number} item - The edit type id.
	 * @param {Number} index - The tab index.
	 */
	const checkDisableOutputSpecifications = (index) => {
		if (
			disableOutputSpecifications &&
			disableOutputSpecifications &&
			disableOutputSpecifications.length > 0
		) {
			return disableOutputSpecifications.includes(index);
		}
		return;
	};

	const validateCopyForm = () => {
		let isError = false;
		let formFields = [...fieldArray];
		let errorFields = [...fieldError];
		let errorTabName = [];
		errorFields.some((obj, index) => {
			Object.keys(obj).some((field) => {
				if (!checkDisableOutputSpecifications(index)) {
					if (
						formFields[index]["margin_format"] !== "" &&
						formFields[index]["margin_format"] !== "" &&
						(field === "margin_top" ||
							field === "margin_bottom" ||
							field === "margin_left" ||
							field === "margin_right")
					) {
						if (isNaN(parseInt(formFields[index][field]))) {
							isError = true;
							errorTabName.push(copyAmounts[index]);
						}
					} else {
						if (
							(checkEdityTypeSelected(3, index) ||
								checkEdityTypeSelected("3", index)) &&
							(formFields[index]["height"] === "" ||
								formFields[index]["width"] === "") &&
							(field === "height" || field === "width")
						) {
							isError = true;
							errorTabName.push(copyAmounts[index]);
						} else {
							if (obj[field] !== "" && formFields[index][field] === "") {
								isError = true;
								errorTabName.push(copyAmounts[index]);
							}
						}
					}
				} else {
					if (
						(checkEdityTypeSelected(3, index) ||
							checkEdityTypeSelected("3", index)) &&
						(formFields[index]["height"] === "" ||
							formFields[index]["width"] === "") &&
						(field === "height" || field === "width")
					) {
						isError = true;
						errorTabName.push(copyAmounts[index]);
					} else {
						if (obj[field] !== "" && formFields[index][field] === "") {
							isError = true;
							errorTabName.push(copyAmounts[index]);
						}
						// fetchOrder();
					}
				}
			});
		});
		errorTabName = errorTabName.filter(
			(value, idex, array) => array.indexOf(value) === idex
		);
		setErrorTabs(errorTabName.join(","));
		return isError;
	};
	// /**
	//  * Handles to return the checkbox field editbale state is set or not.
	//  */
	// const checkisEditableField = () => {
	// 	return !customizedSettings;
	// };
	/**
	 * Handles checkbox change event
	 * @param {Event} e - The changed event object.
	 * @param {Number} item - The edit type id.
	 * @param {Number} index - The tab index.
	 * Return the newly set disabled resizing based on tab.
	 */
	const handleKeepOriginalImageResizing = (e, item, index) => {
		let tempdisableResizing = [...disableResizing];
		let tempcheckedResizing = [...isCheckedResizing];
		let fieldArrayTemp = [...fieldArray];
		let fieldErrorTemp = [...fieldError];
		let tempFieldArray = { ...fieldArrayTemp[index] };
		let tempFieldError = { ...fieldErrorTemp[index] };
		if (e.target.checked) {
			tempFieldArray["height"] = resizing_height;
			tempFieldError["height"] = "";
			tempFieldArray["width"] = resizing_width;
			tempFieldError["width"] = "";
			setDisableResizing((oldArray) => [...oldArray, index]);
			setIsCheckedResizing((oldArray) => {
				const newArray = [...oldArray];
				newArray[item] = "1";
				return newArray ? newArray : "";
			});
		} else {
			setDisableResizing(tempdisableResizing.filter((i) => i !== index));
			setIsCheckedResizing((oldArray) => {
				const newArray = [...oldArray];
				newArray[item] = "0";
				return newArray ? newArray : "";
			});
			tempFieldArray["height"] = "";
			tempFieldError["height"] = fieldValidationRules["height"];
			tempFieldArray["width"] = "";
			tempFieldError["width"] = fieldValidationRules["width"];
		}
		fieldArrayTemp[index] = tempFieldArray;
		fieldErrorTemp[index] = tempFieldError;
		setFieldArray(fieldArrayTemp);
		setFieldError(fieldErrorTemp);
	};
	/**
	 * Handle the selection of checkboxes and update the `editId` state accordingly.
	 * @param {ChangeEvent} changeEvent - The change event object.
	 */
	const handleAddCheckboxSelect = (changeEvent) => {
		changeEvent.persist();

		const editItem = changeEvent.target.value;

		// Update the state
		setEditId((prevState = []) => {
			// Check if the item is already in the state array
			if (prevState.includes(editItem)) {
				// If the item is in the array, filter it out and return the new array
				return prevState.filter((el) => el !== editItem);
			}
			// If the item is not in the array, add it to the array and return the new array
			return [...prevState, editItem];
		});
	};

	/**
	 * Handles to check the option value has changed and reset the state for all the checkboxes.
	 * @param {Number} id - The id to determine the checkbox dependent field which to reset.
	 * @returns {void}
	 */
	const resetFields = (id) => {
		if (id === 1) {
			setBackgroundRemovalColorType("");
			setBackgroundRemovalColorCode("");
		} else if (id === 2) {
			setBackgroundAdjustmentColorCode("");
			setBackgroundAdjustmentColorType("");
		} else if (id === 3) {
			setResizingFileFormat("");
			setResizingHeight("");
			setResizingWidth("");
			setResizingDpi("");
			setResizingDpiType("");
			setResizingColorMode("");
			setResizingAlignment("");
			setResizingMarginFormat("");
			setResizingMarginTop("");
			setResizingMarginBottom("");
			setResizingMarginRight("");
			setResizingMarginLeft("");
		}
	};
	const resetFieldsDuplicate = (id) => {
		if (id === 1) {
			setBackgroundRemovalColorTypeDuplicate("");
			setBackgroundRemovalColorCodeDuplicate("");
		} else if (id === 2) {
			setBackgroundAdjustmentColorCodeDuplicate("");
			setBackgroundAdjustmentColorTypeDuplicate("");
		} else if (id === 3) {
			setResizingHeightDuplicate("");
			setResizingWidthDuplicate("");
		}
	};
	/**
	 * Handle the checkbox state and perform actions based on the given id.
	 * @param {Number} id - The id to determine the checkbox action.
	 * @param {Boolean} checked - The checked/unchecked after action of checkbox clicked.
	 */
	const handleBackGroundRemovalCheckbox = (
		id,
		checked,
		action = "",
		customizedSettings
	) => {
		console.log("checked", checked, id, customizedSettings);
		if (Number(id) === 1) {
			if (checked === false) {
				setBackgroundRemovalColorType("");
				setBackgroundRemovalColorCode("");
			}

			setSelected((checked) => !checked);
			setChecked((val) => !val);
		}
		if (Number(id) === 2) {
			if (checked === false) {
				setBackgroundAdjustmentColorCode("");
				setBackgroundAdjustmentColorType("");
			}
			setSelected1(checked);
			setChecked1((val) => !val);
		}
		if (Number(id) === 3) {
			if (checked === false) {
				setResizingFileFormat("");
				setResizingHeight("");
				setResizingWidth("");
				setResizingDpi("");
				setResizingDpiType("");
				setResizingColorMode("");
				setResizingAlignment("");
				setResizingMarginFormat("");
				setResizingMarginTop("");
				setResizingMarginBottom("");
				setResizingMarginRight("");
				setResizingMarginLeft("");
			}
			setSelected2(checked);
			setChecked2((val) => !val);
		}
		if (Number(id) === 6 || Number(id) === 15) {
			setSelected3(checked);
			setChecked3((val) => !val);
		}
		if (action !== "Edit") {
			resetFields(id);
			customizedSettingsEnabled(true);
		}
	};
	const handleBackGroundRemovalCheckboxDuplicate = (
		id,
		checked = false,
		action = ""
	) => {
		switch (id) {
			case 1:
				setSelectedDuplicateSettings(checked);
				break;
			case 2:
				setSelectedDuplicateSettings1(checked);
				break;
			case 3:
				setSelectedDuplicateSettings2(checked);
				break;
			case 15:
				setSelectedDuplicateSettings3(checked);
				break;
			case 6:
				setSelectedDuplicateSettings3(checked);
				break;
			default:
				break;
		}

		// Optionally reset fields based on action
		if (!checked) {
			resetFieldsDuplicate(id);
		}
	};
	/**
	 * Handles to get all the checked values of checkbox by class name.
	 * @returns {void}
	 */
	const getEditTypeValues = () => {
		const checkedValues = document.querySelectorAll(".et_options");
		const checkedValue = [];

		checkedValues.forEach((element) => {
			if (element.checked) {
				checkedValue.push(element.value);
			}
		});

		// Remove duplicates by converting to a Set and back to an array
		const uniqueValues = Array.from(new Set(checkedValue));

		// Join the unique values into a comma-separated string
		return uniqueValues.length > 0 ? uniqueValues.join(",") : "";
	};
	/**
	 * Handles the update of an image edit group by sending a POST request to the server.
	 * @returns {void}
	 */
	console.log("editTypeId", edit_id, edit_select, customizedSettings);
	const handleUpdateImageEditGroup = () => {
		if (token) {
			// setLoader(true);
			// Append form data with the necessary parameters
			const editTypeId = getEditTypeValues();
			bodyFormData.append("customer_id", customer_id);
			bodyFormData.append("image_ids", idSelected);

			bodyFormData.append("is_overright", 1);
			if (!customizedSettings) {
				bodyFormData.append("edit_group_id", edit_select);
			} else if (customizedSettings === true) {
				bodyFormData.append("custom_edit_group_id", edit_select);
				bodyFormData.append("is_customised", customizedSettings);
				bodyFormData.append(
					"extra_edit_type_ids",
					removeDuplicatesWithSet(edit_id)
				);
			}

			bodyFormData.append(
				"background_removal_color_type",
				background_removal_color_type
			);
			bodyFormData.append(
				"background_removal_color_code",
				background_removal_color_code
			);

			bodyFormData.append(
				"background_adjustment_color_type",
				background_adjustment_color_type !== null &&
					background_adjustment_color_type !== undefined
					? background_adjustment_color_type
					: ""
			);

			bodyFormData.append(
				"background_adjustment_color_code",
				background_adjustment_color_code !== null &&
					background_adjustment_color_code !== undefined
					? background_adjustment_color_code
					: ""
			);

			// bodyFormData.append("resizing_file_format", resizing_file_format);
			bodyFormData.append(
				"resizing_height",
				resizing_height &&
					resizing_height !== undefined &&
					resizing_height !== "undefined"
					? resizing_height
					: ""
			);
			bodyFormData.append(
				"resizing_width",
				resizing_width &&
					resizing_width !== undefined &&
					resizing_width !== "undefined"
					? resizing_width
					: ""
			);

			bodyFormData.append("image_file_format", image_file_format);
			bodyFormData.append("image_height", image_height);
			bodyFormData.append("image_width", image_width);
			bodyFormData.append("image_dpi", image_dpi);
			bodyFormData.append("image_dpi_type", image_dpi_type);
			bodyFormData.append("image_color_mode", image_color_mode);
			bodyFormData.append("image_alignment", image_alignment);
			bodyFormData.append("image_margin_format", image_margin_format);
			bodyFormData.append("image_margin_top", image_margin_top);
			bodyFormData.append("image_margin_bottom", image_margin_bottom);
			bodyFormData.append("image_margin_right", image_margin_right);
			bodyFormData.append("image_margin_left", image_margin_left);

			request
				.post(apiURL + CO_ORDINATOR_UPDATE_EDIT_GROUP, bodyFormData)
				.then((response) => {
					// handleCloseEditTypes();
					//Set the success from the response
					ToastAlert({
						message: "Settings saved.",
						autoClose: 2000,
						messageType: "success",
					});

					console.log("response", response);
					setStatus("Successfully Group updated");
					setDataLoader(true);
					fetchOrderDuplicateSettings();
					fetchFolder();
					handleFetchProfileSettings(imageID);
					setLoader(false);
					fetchDataImages();
					setMultipleImagesId([]);
					setClickSelect(false);
					setSelectAll(false);

					if (response.data.status) {
						reloadBatch();
						setImageID("");
					}
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					// setError(message);
					// ToastAlert({
					// 	title: "Error",
					// 	message: "Error",
					// 	autoClose: 2000,
					// 	messageType: "error",
					// });
				});
		}
	};

	/**
	 * Handles the refresh the current batch.
	 * @returns {void}
	 */
	const reloadBatch = () => {};

	const handleUpdateEditGroup = () => {
		if (token) {
			// setLoader(true);
			// Append form data with the necessary parameters
			const editTypeId = getEditTypeValues();
			bodyFormData.append("customer_id", customer_id);
			bodyFormData.append("image_ids", idSelected);
			bodyFormData.append("is_overright", 1);
			bodyFormData.append("extra_edit_type_ids", editTypeId);

			bodyFormData.append(
				"background_removal_color_type",
				background_removal_color_type
			);
			bodyFormData.append(
				"background_removal_color_code",
				background_removal_color_code
			);
			bodyFormData.append(
				"background_adjustment_color_type",
				background_adjustment_color_type
			);
			bodyFormData.append(
				"background_adjustment_color_code",
				background_adjustment_color_code
			);
			// bodyFormData.append("resizing_file_format", resizing_file_format);
			bodyFormData.append("resizing_height", resizing_height);
			bodyFormData.append("resizing_width", resizing_width);

			bodyFormData.append("image_file_format", image_file_format);
			bodyFormData.append("image_height", image_height);
			bodyFormData.append("image_width", image_width);
			bodyFormData.append("image_dpi", image_dpi);
			bodyFormData.append("image_dpi_type", image_dpi_type);
			bodyFormData.append("image_color_mode", image_color_mode);
			bodyFormData.append("image_alignment", image_alignment);
			bodyFormData.append("image_margin_format", image_margin_format);
			bodyFormData.append("image_margin_top", image_margin_top);
			bodyFormData.append("image_margin_bottom", image_margin_bottom);
			bodyFormData.append("image_margin_right", image_margin_right);
			bodyFormData.append("image_margin_left", image_margin_left);

			request
				.post(apiURL + CO_ORDINATOR_UPDATE_EDIT_GROUP, bodyFormData)
				.then((response) => {
					//Set the success from the response

					ToastAlert({
						message: "Settings saved.",
						autoClose: 2000,
						messageType: "success",
					});

					setDataLoader(true);
					console.log("response", response);

					setStatus("Successfully Group updated");
					setLoader(false);
					fetchDataImages();
					setMultipleImagesId([]);
					setClickSelect(false);
					setSelectAll(false);

					// fetchCheckoutPrice();
					setImageID("");
					reloadBatch();
					// }
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	console.log("edit id hk", edit_id, setting_profile);
	const isCheckedEditIdtoString = useCallback(() => {
		let tempFieldArray = edit_id ? [...edit_id] : [];
		let checkedValue = "";
		if (edit_id !== undefined) {
			tempFieldArray.some((element) => {
				const val = Number(element);
				if (element !== "") {
					checkedValue += val + ",";
				}
			});
			return checkedValue ? `${checkedValue}` : "";
		}
	}, [edit_id]);

	useEffect(() => {
		// Create a new array by combining existing group values and the new object
		if (editGroups) {
			const updatedGroup = [
				// { id: "Customized Settings", group_name: "Customized Settings" },
				{ id: "New Settings", group_name: "New Settings" },
				...editGroups,
			];
			setGroup(updatedGroup);
			setSelected(false);
			setSelected1(false);
			setSelected2(false);
			setSelected3(false);
			// goToTop();
		}
	}, [editGroups]);

	useEffect(() => {
		// Create a new array by combining existing group values and the new object
		if (
			setting_profile &&
			// customizedSettings &&
			setting_profile.id > 0 &&
			setting_profile.edit_group_id === null &&
			setting_profile.extra_edit_types &&
			setting_profile.id
		) {
			if (editGroups || props.editGroups) {
				let copygGroup = editGroups ? editGroups : props.editGroups;
				const updatedGroup = [
					// { id: "Customized Settings", group_name: "Customized Settings" },
					{ id: "New Settings", group_name: "New Settings" },
					...copygGroup,
				];
				// goToTop();
				setGroup(updatedGroup);
				customizedSettingsEnabled(true);
			}
			setEditGroupNameSelect("");

			setBackgroundRemovalColorCode(
				setting_profile.background_settings[0] &&
					setting_profile.background_settings[0].edit_type_id === 1
					? setting_profile.background_settings[0].color_code
					: ""
			);
			setBackgroundAdjustmentColorCode(
				setting_profile.background_settings[1] &&
					setting_profile.background_settings[1].edit_type_id === 2 &&
					setting_profile.background_settings[1].color_code
			);
			setBackgroundRemovalColorType(
				setting_profile.background_settings[0] &&
					setting_profile.background_settings[0].edit_type_id === 1
					? setting_profile.background_settings[0].color_type
					: ""
			);
			setBackgroundAdjustmentColorType(
				setting_profile.background_settings[1] &&
					setting_profile.background_settings[1].edit_type_id === 2 &&
					setting_profile.background_settings[1].color_type
			);

			if (setting_profile) {
				setResizingHeight(setting_profile.height);
				setResizingWidth(setting_profile.width);

				// Output specification fields
				setImageFileFormat(setting_profile.file_format);
				setImageHeight(setting_profile.height);
				setImageWidth(setting_profile.width);
				setImageDpi(setting_profile.dpi);
				setImageDpiType(setting_profile.dpi_type);
				setImageColorMode(setting_profile.color_mode);
				setImageAlignment(setting_profile.alignment);
				setImageMarginFormat(setting_profile.margin_format);
				setImageMarginTop(setting_profile.margin_top);
				setImageMarginBottom(setting_profile.margin_bottom);
				setImageMarginRight(setting_profile.margin_right);
				setImageMarginLeft(setting_profile.margin_left);
			}
		}
	}, [setting_profile.id]);

	useEffect(() => {
		// Fetch data from the API endpointedit_select
		if (edit_select !== "") {
			request
				.get(apiURL + CO_ORDINATOR_VIEW_EDIT_GROUP + `/${edit_select}`)
				.then((response) => {
					const et_head = document.getElementById("et_head");
					if (et_head) {
						et_head.scrollTop({
							behavior: "smooth", // Optional: for smooth scrolling
							block: "start", // Align to the top of the scrollable ancestor
							top: 0,
						});
					}

					//Set View  for edit group from the response
					let data = response.data.data;

					let ids = [];

					if (data && data.edit_group && data.edit_group.edit_types) {
						ids = data.edit_group.edit_types.map((item) => item.id);
					}

					console.log("call data", data);
					const idCheck = (data, id, setChecked) => {
						if (data && Array.isArray(data)) {
							data.forEach((item) => {
								if (item.id === id) {
									setChecked(true);
									return; // Exit early once a match is found
								}
							});
						}
					};

					setEditId(removeDuplicatesWithSet(edit_id));

					if (editTypes === undefined) {
						if (data && data.edit_group && data.edit_group.edit_types) {
							setEditTypes(data.edit_group.edit_types);
						}
					}

					// goToTop();
					setViewEditGroup(data);

					if (
						data &&
						data.edit_group &&
						// data.edit_group.background_settings &&
						data.edit_group.background_settings.length
					) {
						setBackgroundRemovalColorType(
							data.edit_group.background_settings[0].color_type
						);
					}

					if (
						data &&
						data.edit_group &&
						// data.edit_group.background_settings &&
						data.edit_group.background_settings[1]
					) {
						setBackgroundRemovalColorCode(
							data.edit_group.background_settings[1].color_code
						);
					}

					// if (
					// 	data &&
					// 	data.edit_group &&
					// 	data.edit_group.background_settings &&
					// 	data.edit_group.background_settings[0]
					// ) {
					// 	setBackgroundRemovalColorType(
					// 		data.edit_group.background_settings[0].color_type
					// 	);
					// } else {
					// 	// Handle the case where the value doesn't exist (optional)
					// 	setBackgroundRemovalColorType(null); // or any default value
					// }

					if (
						data &&
						data.edit_group &&
						data.edit_group.background_settings &&
						data.edit_group.background_settings[1]
					) {
						setBackgroundAdjustmentColorType(
							data.edit_group.background_settings[1].color_type
						);
					} else {
						// Handle the case where the value doesn't exist (optional)
						setBackgroundAdjustmentColorType(null); // or any default value
					}

					if (data && data.edit_group && data.edit_group.setting) {
						const setting = data.edit_group.setting;

						setImageFileFormat(setting.file_format);
						setImageDpiType(setting.dpi_type);
						setImageColorMode(setting.color_mode);
						setImageAlignment(setting.alignment);
						setImageMarginFormat(setting.margin_format);
						setImageHeight(setting.height);
						setImageWidth(setting.width);
						setImageDpi(setting.dpi);
						setImageMarginTop(setting.margin_top);
						setImageMarginBottom(setting.margin_bottom);
						setImageMarginRight(setting.margin_right);
						setImageMarginLeft(setting.margin_left);
					}

					if (data && data.edit_group && data.edit_group.edit_types) {
						idCheck(data.edit_group.edit_types, 1, setChecked);
						idCheck(data.edit_group.edit_types, 2, setChecked1);
					}

					if (data.edit_group.resizing_settings.length === 1) {
						setChecked2(true);
						setResizingHeight(data.edit_group.resizing_settings[0].height);
						setResizingWidth(data.edit_group.resizing_settings[0].width);
					} else {
						setChecked3(false);
						setResizingHeight("");
						setResizingWidth("");
					}
					if (!data.edit_group.resizing_settings.length) {
						setChecked2(false);
					}
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	}, [edit_select]);
	useEffect(() => {
		if (viewDuplicateSettingsById.extra_edit_types !== undefined) {
			const extraEditTypes =
				viewDuplicateSettingsById.extra_edit_types.map(Number);

			setSelectedDuplicateSettings(extraEditTypes.includes(1));
			setSelectedDuplicateSettings1(extraEditTypes.includes(2));
			setSelectedDuplicateSettings2(extraEditTypes.includes(3));
			setSelectedDuplicateSettings3(extraEditTypes.includes(15));
			setSelectedDuplicateSettings3(extraEditTypes.includes(6));
			// goToTop();
		}
	}, [viewDuplicateSettingsById]);
	useEffect(() => {
		if (token && duplicateImageId) {
			request
				.get(
					apiURL +
						CO_ORDINATOR_DUPLICATE_SETTINGS_BY_ID +
						`/${duplicateImageId}`
				)
				.then((response) => {
					//Set View  for edit group from the response
					setViewDuplicateSettings(response.data.setting);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	}, [duplicateImageId, setViewDuplicateSettings]);

	useEffect(() => {
		if (!resizingOriginalSize) {
			setResizingHeightDuplicate(viewDuplicateSettingsById.resizing_height);
			setResizingWidthDuplicate(viewDuplicateSettingsById.resizing_width);
		} else {
			setResizingHeightDuplicate(resizing_height);
			setResizingWidthDuplicate(resizing_width);
		}
	}, [resizingOriginalSize]);
	useEffect(() => {
		if (!originalSize) {
			setFileFormatDuplicateSize(viewDuplicateSettingsById.file_format);
			setMarginFormatDuplicateSize(viewDuplicateSettingsById.margin_format);
		} else {
			setFileFormatDuplicateSize(image_file_format);
			setMarginFormatDuplicateSize(image_margin_format);
		}
	}, [originalSize]);

	/**
	 * Create an array of checked values for the edit_types in view_edit_group
	 */
	function getEditTypesIds(view_edit_group) {
		if (
			view_edit_group &&
			view_edit_group.edit_group &&
			view_edit_group.edit_group.edit_types &&
			Array.isArray(view_edit_group.edit_group.edit_types)
		) {
			return view_edit_group.edit_group.edit_types.map((items, index) => {
				return items.id;
			});
		} else {
			// Handle the case where any of the properties is null or not an array
			// You can return a default value or handle the error appropriately.
			return []; // Returning an empty array as a default value
		}
	}

	// Usage
	let updateChecked = getEditTypesIds(view_edit_group);
	const toggleDropdown = () => {
		setDropdownOpen(!isDropdownOpen);
		setViewDuplicateModal(false);
	};

	const svgCode = (count) => {
		return `<svg width="28" height="23" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path fill-rule="evenodd" clip-rule="evenodd" d="M6 4.5V0H10.5C10.8978 0 11.2794 0.158035 11.5607 0.43934C11.842 0.720644 12 1.10218 12 1.5V3H9L3 9V16H1.5C1.10218 16 0.720644 15.842 0.43934 15.5607C0.158035 15.2794 0 14.8978 0 14.5V6H4.5C4.89782 6 5.27936 5.84196 5.56066 5.56066C5.84196 5.27936 6 4.89782 6 4.5ZM5 4.5V0.25L0.25 5H4.5C4.63261 5 4.75979 4.94732 4.85355 4.85355C4.94732 4.75979 5 4.63261 5 4.5Z" fill="white" stroke="#474747" stroke-width="1"/>
	<path d="M10 4V8.5C10 8.89782 9.84196 9.27936 9.56066 9.56066C9.27936 9.84196 8.89782 10 8.5 10H4V18.5C4 18.8978 4.15804 19.2794 4.43934 19.5607C4.72064 19.842 5.10218 20 5.5 20H14.5C14.8978 20 15.2794 19.842 15.5607 19.5607C15.842 19.2794 16 18.8978 16 18.5V5.5C16 5.10218 15.842 4.72064 15.5607 4.43934C15.2794 4.15804 14.8978 4 14.5 4H10" fill="white" stroke="#474747" stroke-width="1"/>
	<!-- Use a <style> element to set the font size -->
	<style>
	  text {
		font-size: 8px;
		font-weight:500;
	  }
	</style>
	<!-- Adjust the y attribute to add margin on the top -->
	<text x="6" y="18" fill="green">${count}</text>
  </svg>
`;
	};

	const handleOutsideClick = (e) => {
		if (e.target.classList.contains("dropdown--duplicate")) {
			// Check if the click occurred on an element with the "modal" class
			setDropdownOpen(false);
			setViewDuplicateModal(false);
		}
	};
	useEffect(() => {
		// Add event listener to detect clicks outside the modal
		window.addEventListener("mousedown", handleOutsideClick);

		return () => {
			// Remove the event listener when the component unmounts
			window.removeEventListener("mousedown", handleOutsideClick);
		};
	}, []);
	useEffect(() => {
		setFileFormatDuplicate(viewDuplicateSettingsById.file_format);
		setDpiTypeDuplicate(viewDuplicateSettingsById.dpi_type);
		setColorModeDuplicate(viewDuplicateSettingsById.color_mode);
		setAlignModeDuplicate(viewDuplicateSettingsById.alignment);
		setMarginFormatDuplicate(viewDuplicateSettingsById.margin_format);
		setHeightDuplicate(viewDuplicateSettingsById.height);
		setWidthDuplicate(viewDuplicateSettingsById.height);
		setDpiDuplicate(viewDuplicateSettingsById.dpi);
		setMarginTopDuplicate(viewDuplicateSettingsById.margin_top);
		setMarginBottomDuplicate(viewDuplicateSettingsById.margin_bottom);
		setMarginLeftDuplicate(viewDuplicateSettingsById.margin_left);
		setMarginRightDuplicate(viewDuplicateSettingsById.margin_right);
		setOriginalSize(
			viewDuplicateSettingsById.same_as_original === 0 ? false : true
		);
	}, [viewDuplicateSettingsById]);

	/******
	 * viewDuplicateSettingsById, id
	 * @return {id} if it matches with edit id
	 ****/
	const editIdSettings = useCallback(
		(viewDuplicateSettingsById, id) => {
			if (viewDuplicateSettingsById !== undefined) {
				return (
					viewDuplicateSettingsById !== undefined &&
					viewDuplicateSettingsById.extra_edit_types !== undefined &&
					viewDuplicateSettingsById.extra_edit_types.some((item) => {
						if (Number(item) === id) {
							// foundId = item;
							return true; // stops the iteration
						}
						return false;
					})
				);
				// return foundId;
			}
			// return "";
		},
		[viewDuplicateSettingsById]
	);

	const handleUpdateDuplicateSettings = () => {
		if (!token) {
			return; // Exit early if there's no token
		}
		const formDataValues = {
			// customer_id: customer_id || "",
			image_ids: imageID || "",
			image_name: duplicateImageName ? `${duplicateImageName},` : "",
			image_duplication_id: duplicateImageId ? `${duplicateImageId},` : "",
			edit_types: isCheckedEditIdtoString() || "",
			same_as_original: originalSize ? "1," : "0,",
			same_as_original_resizing: resizingOriginalSize ? "1," : "0,",
			background_removal_color_type: background_removal_color_type_duplicate,
			background_removal_color_code: background_removal_color_code_duplicate,

			background_adjustment_color_type:
				background_adjustment_color_type_duplicate,
			background_adjustment_color_code:
				background_adjustment_color_code_duplicate,

			resizing_height: resizing_height_duplicate
				? `${resizing_height_duplicate},`
				: "",
			resizing_width: resizing_width_duplicate
				? `${resizing_width_duplicate},`
				: "",
			file_format: file_format_duplicate_size
				? `${file_format_duplicate_size},`
				: "",
			margin_format: margin_left_duplicateSize
				? `${margin_left_duplicateSize},`
				: "",
			margin_top: margin_top_duplicateSize
				? `${margin_top_duplicateSize},`
				: "",
			margin_bottom: margin_bottom_duplicateSize
				? `${margin_bottom_duplicateSize},`
				: "",
			margin_right: margin_right_duplicateSize
				? `${margin_right_duplicateSize},`
				: "",
			margin_left: margin_left_duplicateSize
				? `${margin_left_duplicateSize},`
				: "",
		};

		const bodyFormData = new FormData();

		for (const key in formDataValues) {
			if (formDataValues.hasOwnProperty(key)) {
				bodyFormData.append(key, formDataValues[key]);
			}
		}

		request
			.post(apiURL + CO_ORDINATOR_DUPLICATE_IMAGES, bodyFormData)
			.then((response) => {
				// Handle success
				ToastAlert({
					message: "Duplicate Settings saved.",
					autoClose: 2000,
					messageType: "success",
				});
				closeEditType(
					setMultipleImagesId,
					setImageID,
					setSettingsProfile,
					setChecked,
					setChecked1,
					setChecked2,
					setChecked3
				);
				fetchFolder();
				setDropdownOpen(false);
				setViewDuplicateModal(false);

				// handleFetchProfileSettings(setting_profile.id);
			})
			.catch((err) => {
				// Handle errors
				let message =
					typeof err.response !== "undefined"
						? err.response.data.message
						: err.message;
				setError(message);
			});
	};
	const customStyles = {
		placeholder: (provided) => ({
			// Custom styles for the placeholder text
			...provided,
			color: "#212529", // Change the placeholder text color to red
		}),
		menuList: (base) => ({
			...base,
			fontSize: "13px",
		}),
	};
	function getSelectComponent(options, setState, file) {
		return (
			<Select
				options={file}
				placeholder={options}
				styles={customStyles}
				onChange={(e) => setState(e.label)}
			/>
		);
	}

	/**
	 * Handles to set the state.
	 * @param {Boolean} checked - The checked/unchecked after action of checkbox clicked.
	 */
	const toggleFieldEditing = (checked) => {
		customizedSettingsEnabled(checked);
		if (checked) {
			if (edit_select > 0) {
				setPreviousSelectedValue(edit_select);
			}
			if (props.editGroups) {
				const updatedGroup = [
					// { id: "Customized Settings", group_name: "Customized Settings" },
					{ id: "New Settings", group_name: "New Settings" },
					...props.editGroups,
				];
				setGroup(updatedGroup);
			}
		}
	};

	const toggleFieldCustomized = (checked, setting_profile) => {
		customizedSettingsEnabled(checked);
		handleProfileChange(selectLabel([setting_profile.custom_edit_group]));
		if (setting_profile && setting_profile.custom_edit_group) {
			setEditorType(setting_profile.custom_edit_group.id);
			setEditGroupNameSelect(setting_profile.custom_edit_group.group_name);
		}

		setNewSettingsClicked(false);
		setGroupSelect((val) => !val);
	};

	/**
	 * Handles to return the checkbox field editbale state is set or not.
	 */
	const checkisEditableField = () => {
		return !customizedSettings;
	};

	/**
	 * Handles to return the checkbox check/uncheck based on id
	 * @param {Number} id - The checked/unchecked after action of checkbox clicked.
	 */
	const checkEditCheckbox = (id) => {
		if (id === 1) {
			return selected;
		} else if (id === 2) {
			return selected1;
		} else if (id === 3) {
			return selected2;
		} else if (id === 6) {
			return selected3;
		}
	};

	/**
	 * Handles profile change event
	 * @param {Event} e - The changed event object.
	 */
	const handleProfileChange = (e) => {
		setEditGroupNameSelect(e.label);
		setEditorType(e.value);
		setGroupSelect((val) => !val);
	};
	/**
	 * Handles check edit type selected or not
	 * @param {Number} item - The edit type id.
	 * @param {Number} index - The tab index.
	 */
	const checkEdityTypeSelected = (item, index) => {
		if (
			copyTabEditTypeSelected &&
			copyTabEditTypeSelected[index] &&
			copyTabEditTypeSelected[index].length > 0
		) {
			return copyTabEditTypeSelected[index].includes(item);
		}
		return false;
	};

	/**
	 * Handles checkbox change event
	 * @param {Event} e - The changed event object.
	 * @param {Number} item - The edit type id.
	 * @param {Number} index - The tab index.
	 * Return the newly set edit type state based on tab
	 */
	const handleCopyCheckboxCheck = (e, item, index) => {
		let editTypeArray = [...copyTabEditTypeSelected];
		if (!e.target.checked) {
			let copyByIndex = editTypeArray[index];
			copyByIndex = copyByIndex.filter((i) => {
				return i !== item;
			});
			editTypeArray[index] = copyByIndex;
			setCopyTabEditTypes(editTypeArray);
			handleKeepOriginalImageResizing(e, item, index);
		} else {
			if (editTypeArray && editTypeArray[index]) {
				editTypeTempArray = editTypeArray[index];
			} else {
				editTypeTempArray = editTypeArray[index] = [];
			}
			editTypeTempArray.push(item);
			editTypeArray[index] = editTypeTempArray;
			setCopyTabEditTypes(editTypeArray);
		}
		// validateCopyForm();
	};

	/**
	 * Handles image change  to add in the prev selected image state
	 */
	useEffect(() => {
		if (imageID !== "") {
			if (props.editGroups) {
				const updatedGroup = [
					// { id: "Customized Settings", group_name: "Customized Settings" },
					{ id: "New Settings", group_name: "New Settings" },
					...props.editGroups,
				];
				setGroup(updatedGroup);
				const et_head = document.getElementById("et_head");
				if (et_head) {
					et_head.scrollTo({
						top: 0,
						behavior: "smooth", // Optional: for smooth scrolling
					});
				}
			}
			previousImageID(imageID);
		}
	}, [imageID]);
	useEffect(() => {
		if (
			group_name_select === "New Settings" ||
			group_name_select === "Customized Settings" ||
			group_name_select === ""
		) {
			const et_head = document.getElementById("et_head");
			if (et_head) {
				et_head.scrollTo({
					behavior: "smooth", // Optional: for smooth scrolling
					top: 0,
				});
			}
		}
	}, [group_name_select]);

	// console.log("customizedSettings", customizedSettings, edit_select);
	useEffect(() => {
		if (
			edit_select === null &&
			setting_profile &&
			setting_profile.edit_group_id === null &&
			setting_profile.extra_edit_types !== null
		) {
			console.log("view-edit", setting_profile);
			// setViewEditGroup(setting_profile);
			// setEditGroupNameSelect("Customised");
			setEditId(removeDuplicatesWithSet(setting_profile.extra_edit_types));

			if (setting_profile.custom_edit_group) {
				setGroupName(setting_profile.custom_edit_group.group_name);
			}

			if (
				setting_profile.background_settings &&
				setting_profile.background_settings[0]
			) {
				setBackgroundRemovalColorCode(
					setting_profile.background_settings[0].color_code
				);
				setBackgroundRemovalColorType(
					setting_profile.background_settings[0].color_type
				);
			}

			if (
				setting_profile.background_settings &&
				setting_profile.background_settings[1]
			) {
				setBackgroundAdjustmentColorCode(
					setting_profile.background_settings[1].color_code
				);
				setBackgroundAdjustmentColorType(
					setting_profile.background_settings[1].color_type
				);
			}

			if (
				setting_profile.resizing_settings &&
				setting_profile.resizing_settings[0]
			) {
				setResizingFileFormat(
					setting_profile.resizing_settings[0].file_format ||
						"Select File Format"
				);
				setResizingDpiType(
					setting_profile.resizing_settings[0].dpi_type || "Select File Format"
				);
				setResizingColorMode(
					setting_profile.resizing_settings[0].color_mode ||
						"Select File Format"
				);
				setResizingAlignment(
					setting_profile.resizing_settings[0].alignment || "Select File Format"
				);
				setResizingMarginFormat(
					setting_profile.resizing_settings[0].margin_format ||
						"Select File Format"
				);
				setResizingHeight(setting_profile.resizing_settings[0].height || null);
				setResizingWidth(setting_profile.resizing_settings[0].width || null);
			} else {
				// Handle the case where the values don't exist (optional)
				setResizingHeight(null);
				setResizingWidth(null);
			}

			if (
				setting_profile.background_settings &&
				setting_profile.background_settings[0]
			) {
				setChecked(true);
			}

			if (
				setting_profile.background_settings &&
				setting_profile.background_settings[1]
			) {
				setChecked1(true);
			}

			if (
				setting_profile.resizing_settings &&
				setting_profile.resizing_settings.length
			) {
				setChecked2(true);
			}

			setImageFileFormat(setting_profile.file_format || "Select file format");
			setImageDpiType(setting_profile.dpi_type || "Select Dpi type");
			setImageColorMode(setting_profile.color_mode || "Select color mode");
			setImageAlignment(setting_profile.alignment || "Select Align");
			setImageMarginFormat(
				setting_profile.margin_format || "Select Margin Format"
			);
			setImageMarginTop(setting_profile.margin_top);
			setImageMarginBottom(setting_profile.margin_bottom);
			setImageMarginRight(setting_profile.margin_right);
			setImageMarginLeft(setting_profile.margin_left);
		}
	}, [imageID, setting_profile, setEditId]);

	useEffect(() => {
		// Ensure props.editGroups is an array before using it
		const editGroupsJson = Array.isArray(editGroups) ? editGroups : [];

		// Create a new array by combining existing group values and the new object
		const updatedGroup = [
			// { id: "Customized Settings", group_name: "Customized Settings" },
			{ id: "New Settings", group_name: "New Settings" },
			...editGroupsJson,
		];

		setGroup(updatedGroup);
		setSelected(false);
		setSelected1(false);
		setSelected3(false);
	}, [editGroups]);

	useEffect(() => {
		// Fetch data from the API endpoint
		if (edit_select !== null) {
			request
				.get(apiURL + CO_ORDINATOR_VIEW_EDIT_GROUP + `/${edit_select}`)
				.then((response) => {
					//Set View  for edit group from the response
					let { data } = response.data;

					let ids = [];

					if (
						data &&
						data.edit_group &&
						Array.isArray(data.edit_group.edit_types)
					) {
						ids = data.edit_group.edit_types.map((item) => item.id);
					}

					setEditId(removeDuplicatesWithSet(ids));

					if (editTypes === undefined) {
						setEditTypes(data.edit_group.edit_types);
					}
					setViewEditGroup(data);
					setBackgroundRemovalColorCode(
						data.edit_group.background_settings[0].color_code
					);
					setBackgroundAdjustmentColorCode(
						data.edit_group.background_settings[1].color_code
					);
					setBackgroundRemovalColorType(
						data.edit_group.background_settings[0] !== undefined
							? data.edit_group.background_settings[0].color_type
							: "Select Color Type"
					);
					setBackgroundAdjustmentColorType(
						data.edit_group.background_settings[1] !== undefined
							? data.edit_group.background_settings[1].color_type
							: "Select color type"
					);
					setResizingFileFormat(
						data.edit_group.resizing_settings[0].file_format !== undefined
							? data.edit_group.resizing_settings[0].file_format
							: "Select File Format"
					);
					setResizingDpiType(
						data.edit_group.resizing_settings[0] !== undefined
							? data.edit_group.resizing_settings[0].dpi_type
							: "Select File Format"
					);
					setResizingColorMode(
						data.edit_group.resizing_settings[0] !== undefined
							? data.edit_group.resizing_settings[0].color_mode
							: "Select File Format"
					);
					setResizingAlignment(
						data.edit_group.resizing_settings[0] !== undefined
							? data.edit_group.resizing_settings[0].alignment
							: "Select File Format"
					);
					setResizingMarginFormat(
						data.edit_group.resizing_settings[0] !== undefined
							? data.edit_group.resizing_settings[0].margin_format
							: "Select File Format"
					);
					setImageFileFormat(
						data.edit_group.setting.file_format !== undefined
							? data.edit_group.setting.file_format
							: "Select file format"
					);
					setImageDpiType(
						data.edit_group.setting.dpi_type !== null
							? data.edit_group.setting.dpi_type
							: "Select Dpi type"
					);
					setImageColorMode(
						data.edit_group.setting !== null
							? data.edit_group.setting.color_mode
							: "Select color mode"
					);
					setImageAlignment(
						data.edit_group.setting !== null
							? data.edit_group.setting.alignment
							: "Select Align"
					);
					setImageMarginFormat(
						data.edit_group.setting !== null
							? data.edit_group.setting.margin_format
							: "Margin"
					);
					if (data.edit_group.background_settings.length) {
						setChecked(true);
						setChecked1(true);
					}
					if (data.edit_group.resizing_settings.length) {
						setChecked2(true);
						setResizingHeight(data.edit_group.resizing_settings[0].height);
						setResizingWidth(data.edit_group.resizing_settings[0].width);
					}
					if (!data.edit_group.resizing_settings.length) {
						setChecked2(false);
					}
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	}, [edit_select, token]);
	useEffect(() => {
		if (
			edit_select === null &&
			// imageID !== "" &&
			setting_profile &&
			setting_profile.edit_group_id === null &&
			setting_profile.extra_edit_types === null
		) {
			setEditGroupNameSelect("New Settings");
		} else if (
			edit_select === null &&
			// imageID !== "" &&
			setting_profile &&
			setting_profile.edit_group_id === null &&
			setting_profile.extra_edit_types !== null &&
			newSettingsClick === false
		) {
			const et_head = document.getElementById("et_head");
			if (et_head) {
				et_head.scrollTo({
					top: 0,
					behavior: "smooth", // Optional: for smooth scrolling
				});
			}
			setEditGroupNameSelect("Customized Settings");
			setGroupSelect("Customized Settings");
			if (setting_profile && setting_profile.custom_edit_group) {
				setGroupName(setting_profile.custom_edit_group.group_name);
			}

			customizedSettingsEnabled(true);
			setEditId(setting_profile.extra_edit_types);

			if (
				setting_profile &&
				setting_profile.extra_edit_types &&
				setting_profile.extra_edit_types.includes("1")
			) {
				setChecked(true);
			}

			if (
				setting_profile &&
				setting_profile.extra_edit_types &&
				setting_profile.extra_edit_types.includes("2")
			) {
				setChecked1(true);
			}

			if (
				setting_profile &&
				setting_profile.extra_edit_types &&
				setting_profile.extra_edit_types.includes("3")
			) {
				if (
					setting_profile.resizing_settings &&
					setting_profile.resizing_settings[0]
				) {
					setResizingHeight(setting_profile.resizing_settings[0].height);
					setResizingWidth(setting_profile.resizing_settings[0].width);
				}
				setChecked2(true);
			}

			if (
				setting_profile &&
				setting_profile.extra_edit_types &&
				setting_profile.extra_edit_types.includes("15")
			) {
				setChecked3(true);
			}
		} else if (
			// edit_select === null &&
			group_name_select === "New Settings" &&
			newSettingsClick === true
		) {
			setEditGroupNameSelect("New Settings");
		} else {
			setEditGroupNameSelect("");
			customizedSettingsEnabled(false);
			setEditId(removeDuplicatesWithSet(edit_id));
			// setChecked(false);
			// setChecked1(false);
			// setChecked2(false);
			// setChecked3(false);
			if (edit_id && edit_id.includes("1")) {
				setChecked(true);
			}

			if (edit_id && edit_id.includes("2")) {
				setChecked1(true);
			}

			if (edit_id && edit_id.includes("3")) {
				setChecked2(true);
			}

			if (edit_id && edit_id.includes("15")) {
				setChecked3(true);
			}
		}
	}, [
		edit_select,
		setting_profile.length,
		setEditGroupNameSelect,
		group_name_select,
		// newSettingsClick,
	]);
	const getDisabledStatus = (group_name_select) => {
		if (group_name_select === "New Settings" && newSettingsClick === true) {
			return true;
		} else if (
			group_name_select === "New Settings" &&
			newSettingsClick === false
		) {
			return true;
		} else if (group_name_select === "") {
			return false;
		} else if (group_name_select === "Customized Settings") {
			return false;
		} else {
			return true; // default value if none of the conditions are met
		}
	};
	const isEmptyObject = (obj) => {
		return Object.keys(obj).length !== 0;
	};
	const options = selectLabel(group);
	const onCustomizedGroupSelect = options.find((option) => {
		if (group_name_select === "Customized Settings" && edit_select === null) {
			// If you need to return a specific option when conditions match, ensure it aligns with the option structure
			return option.value === "custom"; // Adjust based on your actual option structure
		} else if (edit_select !== "" && edit_select !== null) {
			return option.value === edit_select;
		}
		return false;
	});
	const selectedOption = () => {
		if (!customizedSettings) {
			return options.find((option) => {
				if (
					group_name_select === "Customized Settings" &&
					edit_select === null
				) {
					// If you need to return a specific option when conditions match, ensure it aligns with the option structure
					return option.value === "custom"; // Adjust based on your actual option structure
				} else if (edit_select !== "" && edit_select !== null) {
					return option.value === edit_select;
				}
				return false;
			});
		} else if (customizedSettings) {
			if (onCustomizedGroupSelect) {
				return [
					{
						label: `${onCustomizedGroupSelect.label} (custom)`,
						id: onCustomizedGroupSelect.label,
					},
				];
			}
		}
	};
	const value =
		group_name_select === "Customized Settings" && edit_select === null
			? [
					{
						label: `${group_name + "  "}  (custom)`,
						id: group_name,
					},
			  ]
			: group_name_select === "New Settings"
			? [
					{
						label: "New Settings",
						id: "New Settings",
					},
			  ]
			: selectedOption();

	console.log("edit id", edit_id);

	return (
		<div className="editororderdetails_1 exclude-click">
			{determineCustomerView === 0 ? (
				<div
					className="sidebar-image--name"
					onMouseDown={(e) => e.stopPropagation()}
				>
					<div className="sidebar--select sidebar--select-sm mb-0">
						<div className="sidebar__header sidebar__header--margin">
							<div className="d-flex"></div>
							{/* Duplicate settings */}
							<div
								className="form-group edit-profile-setting-container--single"
								style={{ border: "none" }}
							>
								<Row className="align-items-center edit-setting-sidebar  ">
									<Col sm={12}>
										<div
											className={`dropdown--duplicate dropdown--duplicate--coo__align ${
												isDropdownOpen ? "open" : ""
											}`}
										>
											<div
												className="dropdown-button--duplicate"
												onClick={toggleDropdown}
											>
												<span>
													<div
														dangerouslySetInnerHTML={{
															__html: svgCode(
																setting_profile !== null &&
																	setting_profile !== undefined &&
																	setting_profile.duplication_settings !==
																		undefined &&
																	setting_profile.duplication_settings !==
																		null &&
																	setting_profile.duplication_settings
																		.length !== undefined
																	? parseInt(
																			setting_profile.duplication_settings.length
																				.toString()
																				.substr(0, 2)
																	  )
																	: "0"
															),
														}}
													/>
												</span>
												<OverlayTrigger
													placement="top"
													overlay={
														<Tooltip id={`tooltip-${setting_profile.id}`}>
															{setting_profile.path}
														</Tooltip>
													}
												>
													<span className="order-text--outlined extra-name-hide ">
														{setting_profile.path}
													</span>
												</OverlayTrigger>
												<span
													style={{
														display: "flex",
														marginLeft: "auto",
														marginRight: "10px",
													}}
												>
													<div
														style={{ marginRight: "18px" }}
														onClick={() =>
															onClickDuplicateImage(
																setting_profile.id,
																setting_profile.path.substring(
																	0,
																	setting_profile.path.lastIndexOf(".")
																)
																// getImageExtension(image_details_view.path)
															)
														}
													>
														<img src={Pencil} alt="Pencil Icon" />{" "}
													</div>
												</span>
												<span
													className={
														setting_profile &&
														setting_profile.duplication_settings &&
														setting_profile.duplication_settings.length === 0
															? "disabled_drop__down"
															: ""
													}
												>
													<img width={22} src={ArrowDown} alt="Arrow Down" />{" "}
												</span>
											</div>
											{isDropdownOpen && (
												<div
													className="dropdown-content"
													style={{
														top: "6px",
														left: "0px",
														scrollbarWidth: "thin",
														overflowX: "hidden",
													}}
												>
													{setting_profile !== undefined &&
														setting_profile.duplication_settings !==
															undefined &&
														setting_profile.duplication_settings.map(
															(_items) => {
																return (
																	<span>
																		<h2
																			className="dropdown-duplicate-image--text small-name2"
																			onClick={() => {
																				// setting the state of the image
																				setDuplicateImageId(_items.id);
																				setViewDuplicateModal(true);
																				setDropdownOpen(false);
																				customizedSettingsEnabled(false);
																				setDuplicateImageName(
																					_items.image_name.substring(
																						0,
																						_items.image_name.lastIndexOf(".")
																					)
																				);
																			}}
																		>
																			{_items.image_name}
																		</h2>
																		<div className="d-flex">
																			<div
																				style={{ marginRight: "18px" }}
																				onClick={() =>
																					onClickDuplicateImage(
																						_items.id,
																						_items.image_name.substring(
																							0,
																							_items.image_name.lastIndexOf(".")
																						)
																						// getImageExtension(image_details_view.path)
																					)
																				}
																			>
																				<img src={Pencil} alt="Pencil Icon" />{" "}
																			</div>

																			<div
																				onClick={() => {
																					setShowModalDelete(true);
																					setRenameId(_items.id);
																					setImageName(
																						_items.image_name.substring(
																							0,
																							_items.image_name.lastIndexOf(".")
																						)
																					);
																				}}
																			>
																				<img
																					src={DeleteIcon}
																					alt="Mindreach icons"
																				/>
																			</div>
																		</div>
																	</span>
																);
															}
														)}
												</div>
											)}
										</div>
									</Col>
								</Row>
							</div>
							{/***End ***/}
							<div
								className="form-group edit-profile-setting-container--single"
								style={{ border: "none", height: "50px" }}
							>
								<Row className="align-items-center edit-setting-sidebar  ">
									<Col className="o-flex">
										<div>
											<Select
												defaultValue={{
													label: "New Settings",
													id: "New Settings",
												}}
												options={selectLabel(group)}
												onChange={(e) => {
													if (e.label === "New Settings") {
														console.log(
															"select dropdown",
															e,
															edit_select === null
														);

														// Update states when "New Settings" is selected
														setEditGroupNameSelect(e.label);
														setNewSettingsClicked(true);
														customizedSettingsEnabled(true);
														setChecked(false);
														setChecked1(false);
														setChecked2(false);
														setChecked3(false);
														setGroupSelect((val) => !val);
														setEditId([]);
														setEditorType(null);
													} else if (
														e.label !== "New Settings" &&
														(onCustomizedGroupSelect === undefined ||
															e.label !== onCustomizedGroupSelect.label)
													) {
														// Handle other selections
														handleProfileChange(e);
														setEditGroupNameSelect(e.label);

														// setNewSettingsClicked(false);
														setGroupSelect(e.value);
														customizedSettingsEnabled(false);
													} else if (
														e.label ===
														(onCustomizedGroupSelect
															? onCustomizedGroupSelect.label
															: undefined)
													) {
														customizedSettingsEnabled(false);
													}
													// Optional: If you want to toggle the group selection in both cases, uncomment the line below
													// setGroupSelect((val) => !val);
												}}
												placeholder="Select Profile"
												value={value} // Set the selected value by ID
												styles={customSelectStyles}
											/>
										</div>
										<div>
											<Button
												variant=""
												className="privious-btn btn btns btn btn-primary"
												style={{ padding: "10px", width: "100%" }}
												onClick={() => {
													if (
														isEmptyObject(viewDuplicateSettingsById) === true
													) {
														handleUpdateDuplicateSettings();
													} else {
														if (group_name_select === "New Settings") {
															handleUpdateEditGroup();
															// handleCloseEditTypes();
														} else if (group_name_select === "") {
															handleUpdateImageEditGroup();
															// handleCloseEditTypes();
														} else if (
															group_name_select === "Customized Settings"
														) {
															handleUpdateImageEditGroup();
															// handleCloseEditTypes();
														}
													}
												}}
											>
												{loader ? (
													<div>
														<Loader />
														Loading
													</div>
												) : (
													"Save"
												)}
											</Button>
										</div>
									</Col>
									{/* <hr style={{ width: "100%", margin: "15px 0" }} /> */}
								</Row>
							</div>

							<Form.Group style={{ paddingLeft: "13px" }}>
								<div>
									<div className="customizeSettings">
										{group_name_select === "Customized Settings" && (
											<>
												<Form.Label
													className={
														getDisabledStatus(group_name_select) === true
															? `disabled_text`
															: ""
													}
													for={"editFields"}
													style={{ cursor: "pointer", marginBottom: "0px" }}
												>
													Customize Settings
												</Form.Label>
												<input
													type="checkbox"
													id={"editFields"}
													onClick={(e) =>
														toggleFieldCustomized(
															e.target.checked,
															setting_profile
														)
													}
													checked={customizedSettings}
													// disabled={getDisabledStatus(group_name_select)}
												/>
											</>
										)}
										{group_name_select === "New Settings" && (
											<>
												<Form.Label
													className={`disabled_text`}
													for={"editFields"}
													style={{ cursor: "pointer" }}
												>
													Customize Settings
												</Form.Label>
												<input
													type="checkbox"
													id={"editFields"}
													// onClick={(e) => toggleFieldEditing(e.target.checked)}
													checked={false}
													disabled={true}
												/>
											</>
										)}{" "}
										{group_name_select === "" && (
											<>
												<Form.Label
													className={
														getDisabledStatus(group_name_select) === true
															? `disabled_text`
															: ""
													}
													for={"editFields"}
													style={{ cursor: "pointer" }}
												>
													Customize Settings
												</Form.Label>
												<input
													type="checkbox"
													id={"editFields"}
													onClick={(e) => toggleFieldEditing(e.target.checked)}
													checked={customizedSettings}
													disabled={getDisabledStatus(group_name_select)}
												/>
											</>
										)}
									</div>
								</div>
							</Form.Group>
						</div>
						{/* <hr style={{ width: "100%", marginBottom: "0px" }} /> */}
						{!viewDuplicateModal ? (
							<div className="sidebar-specifications">
								{group_name_select === "New Settings" &&
									newSettingsClick === true && (
										// !customizedSettings &&
										<div className="sidebar-select--editType " id="et_head">
											{/* Form groups for Edit Type*/}
											<div className="form-sub-head">Edit Type</div>

											{editTypes !== undefined &&
												editTypes.map((item, i) => {
													return (
														<Form.Group className="" key={item.id}>
															{" "}
															<Row className="form-group  flex-grow-1" sm={9}>
																<Col sm={1}>
																	<input
																		type="checkbox"
																		onClick={(e) => {
																			if (
																				group_name_select === "New Settings"
																			) {
																			}
																			handleBackGroundRemovalCheckbox(
																				item.id,
																				e.target.checked,
																				"Edit"
																			);
																			handleAddCheckboxSelect(e);
																		}}
																		value={item.id}
																		id={`et_${item.id}`}
																		className="et_options"
																	/>
																</Col>
																<Form.Label
																	className="col-sm-4 form-label form-check-label"
																	style={{
																		width: "auto",
																		marginTop: "1.7px",
																		cursor: "pointer",
																	}}
																	for={`et_${item.id}`}
																>
																	{item.edit_type}
																</Form.Label>
															</Row>
															{item.id === 1 && selected ? (
																<Row
																	className="align-items-center"
																	style={{
																		marginLeft: "auto",
																		marginTop: "0",
																		marginBottom: "10px",
																	}}
																>
																	<Col sm={5} className="ml_4_percent">
																		<Select
																			options={selectBackgrondRemoval}
																			onChange={(e) =>
																				setBackgroundRemovalColorType(e.label)
																			}
																			styles={customSelectStyle}
																		/>
																	</Col>
																	<Col sm={3}>
																		<Form.Control
																			type="text"
																			placeholder="#ffff"
																			autoFocus
																			onChange={(e) =>
																				setBackgroundRemovalColorCode(
																					e.target.value
																				)
																			}
																			autoComplete="off"
																		/>
																	</Col>
																</Row>
															) : (
																""
															)}
															{item.id === 2 && selected1 ? (
																<Row
																	className="align-items-center"
																	style={{
																		marginLeft: "auto",
																		marginTop: "0",
																		marginBottom: "20px",
																	}}
																>
																	<Col sm={5} className="ml_4_percent">
																		<Select
																			options={selectBackgrondRemoval}
																			onChange={(e) =>
																				setBackgroundAdjustmentColorType(
																					e.label
																				)
																			}
																			styles={customSelectStyle}
																		/>
																	</Col>
																	<Col sm={3}>
																		<Form.Control
																			type="text"
																			placeholder="#ffff"
																			autoFocus
																			onChange={(e) =>
																				setBackgroundAdjustmentColorCode(
																					e.target.value
																				)
																			}
																			value={background_adjustment_color_code}
																			autoComplete="off"
																		/>
																	</Col>
																</Row>
															) : (
																""
															)}
															{item.id === 3 && selected2 ? (
																<Row
																	className="align-items-center"
																	style={{
																		marginLeft: "auto",
																		marginRight: "auto",
																		marginTop: "0",
																		marginBottom: "10px",
																		width: " 94%",
																	}}
																>
																	<Form.Group style={{ margin: "2px" }}>
																		<div className="form-group">
																			<Row className="align-items-center">
																				<Col sm={12} className="ml-1">
																					<div className="d-sm-flex align-items-center col-gap-10">
																						<div className="d-sm-flex col-gap-10 align-items-center">
																							<Form.Control
																								type="text"
																								placeholder="Height"
																								autoFocus
																								onChange={(e) =>
																									setResizingHeight(
																										e.target.value
																									)
																								}
																								autoComplete="off"
																							/>
																						</div>
																						<div className="d-sm-flex align-items-center">
																							<img
																								style={{ width: "18px" }}
																								src={cross}
																								className="img-fluid"
																								alt="mindreach images"
																							/>
																						</div>
																						<div className="d-sm-flex align-items-center col-gap-10">
																							<Form.Control
																								type="text"
																								placeholder="Width"
																								autoFocus
																								onChange={(e) =>
																									setResizingWidth(
																										e.target.value
																									)
																								}
																								autoComplete="off"
																							/>
																						</div>
																					</div>
																				</Col>
																			</Row>
																		</div>
																	</Form.Group>
																</Row>
															) : (
																""
															)}
														</Form.Group>
													);
												})}
											{/* Form groups for output specifications */}
											<div className="form-sub-head">Output Specifications</div>
											<Form.Group className="mg-bottom15">
												<Row className="form-group  flex-grow-1">
													<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
														File Format
													</Form.Label>
												</Row>
											</Form.Group>
											<Form.Group>
												<div className="form-group">
													<Row className="align-items-center">
														<Col sm={12} className="pl-2">
															<Select
																options={file}
																onChange={(e) => setImageFileFormat(e.label)}
																styles={customSelectStyle}
															/>
														</Col>
													</Row>
												</div>
											</Form.Group>
											<Form.Group className="mg-bottom15">
												<Row className="form-group  flex-grow-1" sm={12}>
													<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
														Size
													</Form.Label>
												</Row>
											</Form.Group>
											<Form.Group>
												<div className="form-group">
													<Row className="align-items-center">
														<Col sm={12} style={{ marginLeft: "-3px" }}>
															<div className="d-sm-flex align-items-center col-gap-10">
																<div className="d-sm-flex col-gap-10 align-items-center">
																	<Form.Control
																		type="text"
																		placeholder="Height"
																		autoFocus
																		onChange={(e) =>
																			setImageHeight(e.target.value)
																		}
																		autoComplete="off"
																	/>
																</div>
																<div className="d-sm-flex align-items-center">
																	{" "}
																	<img
																		style={{ width: "18px" }}
																		src={cross}
																		alt="mindreach images"
																	/>
																</div>
																<div className="d-sm-flex align-items-center col-gap-10">
																	<Form.Control
																		type="text"
																		placeholder="Width"
																		autoFocus
																		onChange={(e) =>
																			setImageWidth(e.target.value)
																		}
																		autoComplete="off"
																	/>
																</div>
															</div>
														</Col>
													</Row>
												</div>
											</Form.Group>
											<Form.Group className="mg-bottom15">
												<Row className="form-group  flex-grow-1" sm={12}>
													<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
														DPI
													</Form.Label>
												</Row>
											</Form.Group>
											<Form.Group>
												<div className="form-group">
													<Row className="align-items-center">
														<Col sm={12}>
															<div className="d-sm-flex col-gap-20">
																<Form.Control
																	type="text"
																	placeholder="2000"
																	autoFocus
																	onChange={(e) => setImageDpi(e.target.value)}
																	autoComplete="off"
																	className="w-50"
																/>

																<div className="flex-fill">
																	<Select
																		options={dpi}
																		onChange={(e) => setImageDpiType(e.label)}
																		styles={customSelectStyle}
																	/>
																</div>
															</div>
														</Col>
													</Row>
												</div>
											</Form.Group>
											<Form.Group className="mg-bottom15">
												<Row className="form-group  flex-grow-1" sm={12}>
													<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
														Color Mode
													</Form.Label>
												</Row>
											</Form.Group>
											<Form.Group>
												<div className="form-group">
													<Row className="align-items-center">
														<Col sm={12}>
															<Select
																options={ColorMode}
																onChange={(e) => setImageColorMode(e.label)}
																styles={customSelectStyle}
															/>
														</Col>
													</Row>
												</div>
											</Form.Group>
											<Form.Group className="mg-bottom15">
												<Row className="form-group  flex-grow-1" sm={12}>
													<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
														Align
													</Form.Label>
												</Row>
											</Form.Group>
											<Form.Group>
												<div className="form-group">
													<Row className="align-items-center">
														<Col sm={12}>
															<Select
																options={Align}
																onChange={(e) => setImageAlignment(e.label)}
																styles={customSelectStyle}
															/>
														</Col>
													</Row>
												</div>
											</Form.Group>
											<Form.Group className="mg-bottom15">
												<Row className="form-group  flex-grow-1" sm={12}>
													<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
														Margins
													</Form.Label>
												</Row>
											</Form.Group>
											<Form.Group>
												<div className="form-group">
													<Row className="align-items-center">
														<Col sm={12}>
															<Select
																options={Margin}
																onChange={(e) =>
																	setResizingMarginFormat(e.label)
																}
																styles={customSelectStyle}
															/>
														</Col>
														<Col
															sm={12}
															lg={12}
															style={{
																display: "flex",
																marginTop: "12px",
															}}
														>
															<div className="d-sm-flex col-gap-20 mt-2 ">
																<div className="d-flex">
																	<ul className="margin-list d-flex">
																		<li>
																			<Form.Control
																				type="text"
																				placeholder="T"
																				autoFocus
																				onChange={(e) =>
																					setImageMarginTop(e.target.value)
																				}
																				autoComplete="off"
																			/>
																		</li>
																		<li>
																			<Form.Control
																				type="text"
																				placeholder="B"
																				autoFocus
																				onChange={(e) =>
																					setImageMarginBottom(e.target.value)
																				}
																				autoComplete="off"
																			/>
																		</li>
																		<li>
																			<Form.Control
																				type="text"
																				placeholder="L"
																				autoFocus
																				onChange={(e) =>
																					setImageMarginLeft(e.target.value)
																				}
																				autoComplete="off"
																			/>
																		</li>
																		<li>
																			<Form.Control
																				type="text"
																				placeholder="R"
																				autoFocus
																				onChange={(e) =>
																					setImageMarginRight(e.target.value)
																				}
																				autoComplete="off"
																			/>
																		</li>
																	</ul>
																</div>
															</div>
														</Col>

														<Col sm={6}></Col>
													</Row>
												</div>
											</Form.Group>
										</div>
									)}
								{group_name_select === "New Settings" &&
									newSettingsClick === false &&
									// goToTop &&
									edit_select === null && (
										// !customizedSettings &&
										<div className="sidebar-select--editType " id="et_head">
											{/* Form groups for Edit Type*/}
											<div className="form-sub-head">Edit Type</div>

											{editTypes !== undefined &&
												editTypes.map((item, i) => {
													return (
														<Form.Group className="" key={item.id}>
															{" "}
															<Row className="form-group  flex-grow-1" sm={9}>
																<Col sm={1}>
																	<input
																		type="checkbox"
																		onClick={(e) => {
																			if (
																				group_name_select === "New Settings"
																			) {
																			}
																			handleBackGroundRemovalCheckbox(
																				item.id,
																				e.target.checked,
																				"Edit"
																			);
																			handleAddCheckboxSelect(e);
																		}}
																		value={item.id}
																		id={`et_${item.id}`}
																		className="et_options"
																	/>
																</Col>
																<Form.Label
																	className="col-sm-4 form-label form-check-label"
																	style={{
																		width: "auto",
																		marginTop: "1.7px",
																		cursor: "pointer",
																	}}
																	for={`et_${item.id}`}
																>
																	{item.edit_type}
																</Form.Label>
															</Row>
															{item.id === 1 && selected ? (
																<Row
																	className="align-items-center"
																	style={{
																		marginLeft: "auto",
																		marginTop: "0",
																		marginBottom: "10px",
																	}}
																>
																	<Col sm={5} className="ml_4_percent">
																		<Select
																			options={selectBackgrondRemoval}
																			onChange={(e) =>
																				setBackgroundRemovalColorType(e.label)
																			}
																			styles={customSelectStyle}
																		/>
																	</Col>
																	<Col sm={3}>
																		<Form.Control
																			type="text"
																			placeholder="#ffff"
																			autoFocus
																			onChange={(e) =>
																				setBackgroundRemovalColorCode(
																					e.target.value
																				)
																			}
																			autoComplete="off"
																		/>
																	</Col>
																</Row>
															) : (
																""
															)}
															{item.id === 2 && selected1 ? (
																<Row
																	className="align-items-center"
																	style={{
																		marginLeft: "auto",
																		marginTop: "0",
																		marginBottom: "10px",
																	}}
																>
																	<Col sm={5} className="ml_4_percent">
																		<Select
																			options={selectBackgrondRemoval}
																			onChange={(e) =>
																				setBackgroundAdjustmentColorType(
																					e.label
																				)
																			}
																			styles={customSelectStyle}
																		/>
																	</Col>
																	<Col sm={3}>
																		<Form.Control
																			type="text"
																			placeholder="#ffff"
																			autoFocus
																			onChange={(e) =>
																				setBackgroundAdjustmentColorCode(
																					e.target.value
																				)
																			}
																			value={background_adjustment_color_code}
																			autoComplete="off"
																		/>
																	</Col>
																</Row>
															) : (
																""
															)}
															{item.id === 3 && selected2 ? (
																<Row
																	className="align-items-center"
																	style={{
																		marginLeft: "auto",
																		marginRight: "auto",
																		marginTop: "0",
																		marginBottom: "10px",
																		width: " 94%",
																	}}
																>
																	<Form.Group style={{ margin: "2px" }}>
																		<div className="form-group">
																			<Row className="align-items-center">
																				<Col sm={12} className="ml-1">
																					<div className="d-sm-flex align-items-center col-gap-10">
																						<div className="d-sm-flex col-gap-10 align-items-center">
																							<Form.Control
																								type="text"
																								placeholder="Height"
																								autoFocus
																								onChange={(e) =>
																									setResizingHeight(
																										e.target.value
																									)
																								}
																								autoComplete="off"
																							/>
																						</div>
																						<div className="d-sm-flex align-items-center">
																							<img
																								style={{ width: "18px" }}
																								src={cross}
																								className="img-fluid"
																								alt="mindreach images"
																							/>
																						</div>
																						<div className="d-sm-flex align-items-center col-gap-10">
																							<Form.Control
																								type="text"
																								placeholder="Width"
																								autoFocus
																								onChange={(e) =>
																									setResizingWidth(
																										e.target.value
																									)
																								}
																								autoComplete="off"
																							/>
																						</div>
																					</div>
																				</Col>
																			</Row>
																		</div>
																	</Form.Group>
																</Row>
															) : (
																""
															)}
														</Form.Group>
													);
												})}
											{/* Form groups for output specifications */}
											<div className="form-sub-head">Output Specifications</div>
											<Form.Group className="mg-bottom15">
												<Row className="form-group  flex-grow-1" sm={12}>
													<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
														File Format
													</Form.Label>
												</Row>
											</Form.Group>
											<Form.Group>
												<div className="form-group">
													<Row className="align-items-center">
														<Col sm={12} className="pl-2">
															<Select
																options={file}
																onChange={(e) => setImageFileFormat(e.label)}
																styles={customSelectStyle}
															/>
														</Col>
													</Row>
												</div>
											</Form.Group>
											<Form.Group className="mg-bottom15">
												<Row className="form-group  flex-grow-1" sm={12}>
													<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
														Size
													</Form.Label>
												</Row>
											</Form.Group>
											<Form.Group>
												<div className="form-group">
													<Row className="align-items-center">
														<Col sm={12} style={{ marginLeft: "-3px" }}>
															<div className="d-sm-flex align-items-center col-gap-10">
																<div className="d-sm-flex col-gap-10 align-items-center">
																	<Form.Control
																		type="text"
																		placeholder="Height"
																		autoFocus
																		onChange={(e) =>
																			setImageHeight(e.target.value)
																		}
																		autoComplete="off"
																	/>
																</div>
																<div className="d-sm-flex align-items-center">
																	{" "}
																	<img
																		style={{ width: "18px" }}
																		src={cross}
																		alt="mindreach images"
																	/>
																</div>
																<div className="d-sm-flex align-items-center col-gap-10">
																	<Form.Control
																		type="text"
																		placeholder="Width"
																		autoFocus
																		onChange={(e) =>
																			setImageWidth(e.target.value)
																		}
																		autoComplete="off"
																	/>
																</div>
															</div>
														</Col>
													</Row>
												</div>
											</Form.Group>
											<Form.Group className="mg-bottom15">
												<Row className="form-group  flex-grow-1" sm={12}>
													<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
														DPI
													</Form.Label>
												</Row>
											</Form.Group>
											<Form.Group>
												<div className="form-group">
													<Row className="align-items-center">
														<Col sm={12}>
															<div className="d-sm-flex col-gap-20">
																<Form.Control
																	type="text"
																	placeholder="2000"
																	autoFocus
																	onChange={(e) => setImageDpi(e.target.value)}
																	autoComplete="off"
																	className="w-50"
																/>

																<div className="flex-fill">
																	<Select
																		options={dpi}
																		onChange={(e) => setImageDpiType(e.label)}
																		styles={customSelectStyle}
																	/>
																</div>
															</div>
														</Col>
													</Row>
												</div>
											</Form.Group>
											<Form.Group className="mg-bottom15">
												<Row className="form-group  flex-grow-1" sm={12}>
													<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
														Color Mode
													</Form.Label>
												</Row>
											</Form.Group>
											<Form.Group>
												<div className="form-group">
													<Row className="align-items-center">
														<Col sm={12}>
															<Select
																options={ColorMode}
																onChange={(e) => setImageColorMode(e.label)}
																styles={customSelectStyle}
															/>
														</Col>
													</Row>
												</div>
											</Form.Group>
											<Form.Group className="mg-bottom15">
												<Row className="form-group  flex-grow-1" sm={12}>
													<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
														Align
													</Form.Label>
												</Row>
											</Form.Group>
											<Form.Group>
												<div className="form-group">
													<Row className="align-items-center">
														<Col sm={12}>
															<Select
																options={Align}
																onChange={(e) => setImageAlignment(e.label)}
																styles={customSelectStyle}
															/>
														</Col>
													</Row>
												</div>
											</Form.Group>
											<Form.Group className="mg-bottom15">
												<Row className="form-group  flex-grow-1" sm={12}>
													<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
														Margins
													</Form.Label>
												</Row>
											</Form.Group>
											<Form.Group>
												<div className="form-group">
													<Row className="align-items-center">
														<Col sm={12}>
															<Select
																options={Margin}
																onChange={(e) =>
																	setResizingMarginFormat(e.label)
																}
																styles={customSelectStyle}
															/>
														</Col>
														<Col
															sm={12}
															lg={12}
															style={{
																display: "flex",
																marginTop: "12px",
															}}
														>
															<div className="d-sm-flex col-gap-20 mt-2 ">
																<div className="d-flex">
																	<ul className="margin-list d-flex">
																		<li>
																			<Form.Control
																				type="text"
																				placeholder="T"
																				autoFocus
																				onChange={(e) =>
																					setImageMarginTop(e.target.value)
																				}
																				autoComplete="off"
																			/>
																		</li>
																		<li>
																			<Form.Control
																				type="text"
																				placeholder="B"
																				autoFocus
																				onChange={(e) =>
																					setImageMarginBottom(e.target.value)
																				}
																				autoComplete="off"
																			/>
																		</li>
																		<li>
																			<Form.Control
																				type="text"
																				placeholder="L"
																				autoFocus
																				onChange={(e) =>
																					setImageMarginLeft(e.target.value)
																				}
																				autoComplete="off"
																			/>
																		</li>
																		<li>
																			<Form.Control
																				type="text"
																				placeholder="R"
																				autoFocus
																				onChange={(e) =>
																					setImageMarginRight(e.target.value)
																				}
																				autoComplete="off"
																			/>
																		</li>
																	</ul>
																</div>
															</div>
														</Col>

														<Col sm={6}></Col>
													</Row>
												</div>
											</Form.Group>
										</div>
									)}

								{group_name_select === "" && (
									<div className="sidebar-select--editType  editSettings">
										{((view_edit_group !== null &&
											view_edit_group !== undefined) ||
											// view_edit_group.edit_group) ||
											(view_edit_group !== undefined &&
												view_edit_group.edit_group === null &&
												setting_profile &&
												setting_profile.resizing_settings)) && (
											<>
												<div
													className="form-sub-head"
													style={{ marginTop: "5px" }}
												>
													Edit Type
												</div>
											</>
										)}
										{/* /***need to check**/}
										{editTypes &&
											editTypes.map((item) => {
												return (
													<>
														<Form.Group>
															{" "}
															<Row className="form-group  flex-grow-1" sm={12}>
																<Col sm={1}>
																	<input
																		type="checkbox"
																		value={item.id}
																		onChange={(e) => {
																			// if (customizedSettings) {
																			handleBackGroundRemovalCheckbox(
																				item.id,
																				e.target.checked,
																				"Edit",
																				customizedSettings
																			);
																			handleAddCheckboxSelect(e);
																			if (customizedSettings) {
																				setGroupName("New Settings");
																			}
																			// }
																		}}
																		checked={
																			item.id === 1
																				? checked
																				: item.id === 2
																				? checked1
																				: item.id === 3
																				? checked2
																				: checked3
																		}
																		id={`et_${item.id}`}
																		className="et_options"
																		disabled={checkisEditableField()}
																	/>
																</Col>
																<Form.Label
																	className="col-sm-4 form-label form-check-label"
																	style={{
																		width: "auto",
																		marginTop: "1.4px",
																		cursor: "pointer",
																	}}
																	for={`et_${item.id}`}
																>
																	{item.edit_type}
																</Form.Label>
															</Row>
															{item.id === 1 && checked ? (
																<Row
																	className="align-items-center"
																	style={{
																		// marginLeft: "44px",
																		marginTop: "-2px",
																		marginBottom: "10px",
																		width: "100%",
																	}}
																>
																	<Col sm={6}>
																		<Select
																			options={selectBackgrondRemoval}
																			onChange={(e) =>
																				setBackgroundRemovalColorType(e.label)
																			}
																			defaultValue={{
																				label: `${background_removal_color_type}`,
																				id: "21",
																			}}
																			value={{
																				label: background_removal_color_type, // Set the label property to the desired value
																				value: background_removal_color_type, // Set the value property to the corresponding value
																			}}
																			isDisabled={checkisEditableField()}
																			styles={customSelectStyle}
																		/>
																	</Col>
																	<Col sm={6}>
																		<Form.Control
																			type="text"
																			placeholder="#ffff"
																			autoFocus
																			onChange={(e) =>
																				setBackgroundRemovalColorCode(
																					e.target.value
																				)
																			}
																			disabled={checkisEditableField()}
																			value={background_removal_color_code}
																		/>
																	</Col>
																</Row>
															) : (
																""
															)}
															{item.id === 2 && checked1 ? (
																<Row
																	className="align-items-center"
																	style={{
																		// marginLeft: "44px",
																		marginTop: "-2px",
																		marginBottom: "10px",
																		width: "100%",
																	}}
																>
																	<Col sm={6}>
																		<Select
																			options={selectBackgrondRemoval}
																			onChange={(e) =>
																				setBackgroundAdjustmentColorType(
																					e.label
																				)
																			}
																			value={{
																				label: background_adjustment_color_type, // Set the label property to the desired value
																				value: background_adjustment_color_type, // Set the value property to the corresponding value
																			}}
																			isDisabled={checkisEditableField()}
																			styles={customSelectStyle}
																		/>
																	</Col>
																	<Col sm={6}>
																		<Form.Control
																			type="text"
																			placeholder="#ffff"
																			autoFocus
																			disabled={checkisEditableField()}
																			onChange={(e) =>
																				setBackgroundAdjustmentColorCode(
																					e.target.value
																				)
																			}
																			value={background_adjustment_color_code}
																		/>
																	</Col>
																</Row>
															) : (
																""
															)}
															{item.id === 3 && checked2 ? (
																<Row
																	className="align-items-center"
																	style={{
																		// marginLeft: "40px",
																		marginTop: "-2px",
																		marginBottom: "10px",
																		width: "100%",
																	}}
																>
																	<Form.Group
																		style={{ margin: "2px" }}
																		controlId="exampleForm.ControlTextarea1"
																	>
																		<div className="form-group">
																			<Row className="align-items-center">
																				<Col sm={12}>
																					<div className="d-sm-flex align-items-center col-gap-10">
																						<div className="d-sm-flex col-gap-10 align-items-center">
																							<Form.Control
																								type="text"
																								placeholder="Height"
																								autoFocus
																								onChange={(e) =>
																									setResizingHeight(
																										e.target.value
																									)
																								}
																								value={resizing_height}
																								disabled={checkisEditableField()}
																							/>
																						</div>
																						<div className="d-sm-flex align-items-center">
																							<img
																								style={{ width: "18px" }}
																								src={cross}
																								alt="mindreach images"
																							/>
																						</div>
																						<div className="d-sm-flex align-items-center col-gap-10">
																							<Form.Control
																								type="text"
																								placeholder="Width"
																								autoFocus
																								onChange={(e) =>
																									setResizingWidth(
																										e.target.value
																									)
																								}
																								value={resizing_width}
																								disabled={checkisEditableField()}
																							/>
																						</div>
																					</div>
																				</Col>
																			</Row>
																		</div>
																	</Form.Group>
																</Row>
															) : (
																""
															)}
														</Form.Group>
													</>
												);
											})}
										{/* Form groups for output specifications */}

										{edit_select !== null && (
											<>
												<div
													className="form-sub-head"
													style={{ marginTop: "15px" }}
												>
													Output Specifications
												</div>
												<Form.Group className="mg-bottom15">
													<Row className="form-group  flex-grow-1" sm={12}>
														<Form.Label className="col-sm-4 form-label side-form-label">
															File Format
														</Form.Label>
													</Row>
												</Form.Group>
												<Form.Group>
													<div className="form-group">
														<Row className="align-items-center">
															<Col sm={12} className="pl-2">
																<Select
																	options={file}
																	onChange={(e) => setImageFileFormat(e.label)}
																	value={{
																		label: image_file_format,
																		value: image_file_format, // Set the value property to the corresponding value
																	}}
																	isDisabled={checkisEditableField()}
																/>
															</Col>
														</Row>
													</div>
												</Form.Group>
												<Form.Group className="mg-bottom15">
													<Row className="form-group  flex-grow-1" sm={12}>
														<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
															Size
														</Form.Label>
													</Row>
												</Form.Group>
												<Form.Group>
													<div className="form-group">
														<Row className="align-items-center">
															<Col sm={12}>
																<div className="d-sm-flex align-items-center col-gap-10">
																	<div className="d-sm-flex col-gap-10 align-items-center">
																		<Form.Control
																			type="text"
																			placeholder="Height"
																			onChange={(e) =>
																				setImageHeight(e.target.value)
																			}
																			disabled={checkisEditableField()}
																			value={image_height}
																		/>
																	</div>
																	<div className="d-sm-flex align-items-center">
																		<img
																			style={{ width: "18px" }}
																			src={cross}
																			alt="mindreach images"
																		/>
																	</div>
																	<div className="d-sm-flex align-items-center col-gap-10">
																		<Form.Control
																			type="text"
																			placeholder="Width"
																			onChange={(e) =>
																				setImageWidth(e.target.value)
																			}
																			disabled={checkisEditableField()}
																			value={image_width}
																		/>
																	</div>
																</div>
															</Col>
														</Row>
													</div>
												</Form.Group>
												<Form.Group className="mg-bottom15">
													<Row className="form-group  flex-grow-1" sm={12}>
														<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
															DPI
														</Form.Label>
													</Row>
												</Form.Group>
												<Form.Group>
													<div className="form-group">
														<Row className="align-items-center">
															<Col sm={12}>
																<div className="d-sm-flex col-gap-20">
																	<Form.Control
																		type="text"
																		placeholder="2000"
																		autoComplete="off"
																		className="w-50"
																		value={image_dpi}
																		disabled={checkisEditableField()}
																		onChange={(e) =>
																			setImageDpi(e.target.value)
																		}
																	/>
																	<div className="flex-fill">
																		<Select
																			options={dpi}
																			onChange={(e) => setImageDpiType(e.label)}
																			value={{
																				label: image_dpi_type,
																				value: image_dpi_type,
																			}}
																			isDisabled={checkisEditableField()}
																		/>
																	</div>
																</div>
															</Col>
														</Row>
													</div>
												</Form.Group>
												<Form.Group className="mg-bottom15">
													<Row className="form-group  flex-grow-1" sm={12}>
														<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
															Color Mode
														</Form.Label>
													</Row>
												</Form.Group>
												<Form.Group>
													<div className="form-group">
														<Row className="align-items-center">
															<Col sm={12}>
																<Select
																	options={ColorMode}
																	onChange={(e) => setImageColorMode(e.label)}
																	value={{
																		label: image_color_mode,
																		value: image_color_mode,
																	}}
																	isDisabled={checkisEditableField()}
																/>
															</Col>
														</Row>
													</div>
												</Form.Group>
												<Form.Group className="mg-bottom15">
													<Row className="form-group  flex-grow-1" sm={12}>
														<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
															Align
														</Form.Label>
													</Row>
												</Form.Group>
												<Form.Group>
													<div className="form-group">
														<Row className="align-items-center">
															<Col sm={12}>
																<Select
																	options={Align}
																	onChange={(e) => setImageAlignment(e.label)}
																	value={{
																		label: image_alignment, // Set the label property to the desired value
																		value: image_alignment, // Set the value property to the corresponding value
																	}}
																	isDisabled={checkisEditableField()}
																/>
															</Col>
														</Row>
													</div>
												</Form.Group>
												<Form.Group className="mg-bottom15">
													<Row className="form-group  flex-grow-1" sm={12}>
														<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
															Margins
														</Form.Label>
													</Row>
												</Form.Group>
												<Form.Group>
													<div className="form-group">
														<Row className="align-items-center">
															<Col sm={12}>
																<Select
																	options={Margin}
																	onChange={(e) =>
																		setImageMarginFormat(e.label)
																	}
																	value={{
																		label: image_margin_format, // Set the label property to the desired value
																		value: image_margin_format, // Set the value property to the corresponding value
																	}}
																	isDisabled={checkisEditableField()}
																/>
															</Col>
															<Col
																sm={7}
																lg={7}
																style={{
																	display: "flex",
																	marginTop: "12px",
																}}
															>
																<div className="d-sm-flex col-gap-20 mt-2 ">
																	<div className="d-flex">
																		<ul className="margin-list d-flex">
																			<li
																				className={`${
																					checkisEditableField()
																						? "disabled"
																						: ""
																				}`}
																			>
																				<Form.Control
																					type="text"
																					placeholder="T"
																					value={image_margin_top}
																					disabled={checkisEditableField()}
																					onChange={(e) =>
																						setImageMarginTop(e.target.value)
																					}
																				/>
																			</li>
																			<li
																				className={`${
																					checkisEditableField()
																						? "disabled"
																						: ""
																				}`}
																			>
																				<Form.Control
																					type="text"
																					placeholder="B"
																					value={image_margin_bottom}
																					disabled={checkisEditableField()}
																					onChange={(e) =>
																						setImageMarginBottom(e.target.value)
																					}
																				/>
																			</li>
																			<li
																				className={`${
																					checkisEditableField()
																						? "disabled"
																						: ""
																				}`}
																			>
																				<Form.Control
																					type="text"
																					placeholder="L"
																					value={image_margin_left}
																					disabled={checkisEditableField()}
																					onChange={(e) =>
																						setImageMarginLeft(e.target.value)
																					}
																				/>
																			</li>
																			<li
																				className={`${
																					checkisEditableField()
																						? "disabled"
																						: ""
																				}`}
																			>
																				<Form.Control
																					type="text"
																					placeholder="R"
																					value={image_margin_right}
																					disabled={checkisEditableField()}
																					onChange={(e) =>
																						setImageMarginRight(e.target.value)
																					}
																				/>
																			</li>
																		</ul>
																	</div>
																</div>
															</Col>
															<Col sm={6}></Col>
														</Row>
													</div>
												</Form.Group>
											</>
										)}
										{view_edit_group !== undefined &&
											view_edit_group.edit_group === null &&
											setting_profile &&
											setting_profile.setting && (
												<>
													<div className="sub-head">Output Specifications</div>
													<Form.Group className="mg-bottom15">
														<Row className="form-group  flex-grow-1" sm={9}>
															<Col sm={1}></Col>
															<Form.Label className="col-sm-4 form-label form-check-label">
																File Format
															</Form.Label>
														</Row>
													</Form.Group>
													<Form.Group style={{ margin: "2px" }}>
														<div className="form-group">
															<Row className="align-items-center">
																<Form.Label className="col-sm-1"></Form.Label>
																<Col sm={6} className="pl-2">
																	<Select
																		options={file}
																		onChange={(e) =>
																			setImageFileFormat(e.label)
																		}
																		value={{
																			label: image_file_format, // Set the label property to the desired value
																			value: image_file_format, // Set the value property to the corresponding value
																		}}
																		isDisabled={checkisEditableField()}
																	/>
																</Col>
															</Row>
														</div>
													</Form.Group>
													<Form.Group className="mg-bottom15">
														<Row className="form-group  flex-grow-1" sm={9}>
															<Col sm={1}></Col>
															<Form.Label className="col-sm-4 form-label form-check-label">
																Size
															</Form.Label>
														</Row>
													</Form.Group>
													<Form.Group style={{ margin: "2px" }}>
														<div className="form-group">
															<Row className="align-items-center">
																<Form.Label className="col-sm-1"></Form.Label>
																<Col sm={9}>
																	<div className="d-sm-flex align-items-center col-gap-10">
																		<div className="d-sm-flex col-gap-10 align-items-center">
																			<Form.Control
																				type="text"
																				placeholder="Height"
																				disabled={checkisEditableField()}
																				value={image_height}
																				onChange={(e) =>
																					setImageHeight(e.target.value)
																				}
																			/>
																		</div>
																		<div className="d-sm-flex align-items-center">
																			<img
																				style={{ width: "18px" }}
																				src={cross}
																				alt="mindreach images"
																			/>
																		</div>
																		<div className="d-sm-flex align-items-center col-gap-10">
																			<Form.Control
																				type="text"
																				placeholder="Width"
																				disabled={checkisEditableField()}
																				value={image_width}
																				onChange={(e) =>
																					setImageWidth(e.target.value)
																				}
																			/>
																		</div>
																	</div>
																</Col>
															</Row>
														</div>
													</Form.Group>
													<Form.Group className="mg-bottom15">
														<Row className="form-group  flex-grow-1" sm={9}>
															<Col sm={1}></Col>
															<Form.Label className="col-sm-4 form-label form-check-label">
																DPI
															</Form.Label>
														</Row>
													</Form.Group>
													<Form.Group style={{ margin: "2px" }}>
														<div className="form-group">
															<Row className="align-items-center">
																<Form.Label className="col-sm-1"></Form.Label>
																<Col sm={10}>
																	<div className="d-sm-flex col-gap-20">
																		<Form.Control
																			type="text"
																			placeholder="2000"
																			autoComplete="off"
																			className="w-50"
																			value={image_dpi}
																			disabled={checkisEditableField()}
																			onChange={(e) =>
																				setImageDpi(e.target.value)
																			}
																		/>
																		<div className="flex-fill">
																			<Select
																				options={dpi}
																				onChange={(e) =>
																					setImageDpiType(e.label)
																				}
																				value={{
																					label: image_dpi_type, // Set the label property to the desired value
																					value: image_dpi_type, // Set the value property to the corresponding value
																				}}
																				isDisabled={checkisEditableField()}
																			/>
																		</div>
																	</div>
																</Col>
															</Row>
														</div>
													</Form.Group>
													<Form.Group className="mg-bottom15">
														<Row className="form-group  flex-grow-1" sm={9}>
															<Col sm={1}></Col>
															<Form.Label className="col-sm-4 form-label form-check-label">
																Color Mode
															</Form.Label>
														</Row>
													</Form.Group>
													<Form.Group style={{ margin: "2px" }}>
														<div className="form-group">
															<Row className="align-items-center">
																<Form.Label className="col-sm-1"></Form.Label>
																<Col sm={9}>
																	<Select
																		options={ColorMode}
																		onChange={(e) => setImageColorMode(e.label)}
																		value={{
																			label: image_color_mode, // Set the label property to the desired value
																			value: image_color_mode, // Set the value property to the corresponding value
																		}}
																		isDisabled={checkisEditableField()}
																	/>
																</Col>
															</Row>
														</div>
													</Form.Group>
													<Form.Group className="mg-bottom15">
														<Row className="form-group  flex-grow-1" sm={9}>
															<Col sm={1}></Col>
															<Form.Label className="col-sm-4 form-label form-check-label">
																Align
															</Form.Label>
														</Row>
													</Form.Group>
													<Form.Group style={{ margin: "2px" }}>
														<div className="form-group">
															<Row className="align-items-center">
																<Form.Label className="col-sm-1"></Form.Label>
																<Col sm={9}>
																	<Select
																		options={Align}
																		onChange={(e) => setImageAlignment(e.label)}
																		value={{
																			label: image_alignment, // Set the label property to the desired value
																			value: image_alignment, // Set the value property to the corresponding value
																		}}
																		isDisabled={checkisEditableField()}
																	/>
																</Col>
															</Row>
														</div>
													</Form.Group>
													<Form.Group className="mg-bottom15">
														<Row className="form-group  flex-grow-1" sm={9}>
															<Col sm={1}></Col>
															<Form.Label className="col-sm-4 form-label form-check-label">
																Margins
															</Form.Label>
														</Row>
													</Form.Group>
													<Form.Group style={{ margin: "2px" }}>
														<div className="form-group">
															<Row className="align-items-center">
																<Form.Label className="col-sm-1"></Form.Label>
																<Col sm={9}>
																	<Select
																		options={Margin}
																		onChange={(e) =>
																			setImageMarginFormat(e.label)
																		}
																		value={{
																			label: image_margin_format, // Set the label property to the desired value
																			value: image_margin_format, // Set the value property to the corresponding value
																		}}
																		isDisabled={checkisEditableField()}
																	/>
																</Col>
																<Col
																	sm={7}
																	lg={7}
																	style={{
																		display: "flex",
																		marginLeft: "2rem",
																		marginTop: "12px",
																	}}
																>
																	<div className="d-sm-flex col-gap-20 mt-2 ">
																		<div className="d-flex">
																			<ul className="margin-list d-flex">
																				<li
																					className={`${
																						checkisEditableField()
																							? "disabled"
																							: ""
																					}`}
																				>
																					<Form.Control
																						type="text"
																						placeholder="T"
																						value={image_margin_top}
																						disabled={checkisEditableField()}
																						onChange={(e) =>
																							setImageMarginTop(e.target.value)
																						}
																					/>
																				</li>
																				<li
																					className={`${
																						checkisEditableField()
																							? "disabled"
																							: ""
																					}`}
																				>
																					<Form.Control
																						type="text"
																						placeholder="B"
																						value={image_margin_bottom}
																						disabled={checkisEditableField()}
																						onChange={(e) =>
																							setImageMarginBottom(
																								e.target.value
																							)
																						}
																					/>
																				</li>
																				<li
																					className={`${
																						checkisEditableField()
																							? "disabled"
																							: ""
																					}`}
																				>
																					<Form.Control
																						type="text"
																						placeholder="L"
																						value={image_margin_left}
																						disabled={checkisEditableField()}
																						onChange={(e) =>
																							setImageMarginLeft(e.target.value)
																						}
																					/>
																				</li>
																				<li
																					className={`${
																						checkisEditableField()
																							? "disabled"
																							: ""
																					}`}
																				>
																					<Form.Control
																						type="text"
																						placeholder="R"
																						value={image_margin_right}
																						disabled={checkisEditableField()}
																						onChange={(e) =>
																							setImageMarginRight(
																								e.target.value
																							)
																						}
																					/>
																				</li>
																			</ul>
																		</div>
																	</div>
																</Col>
																<Col sm={6}></Col>
															</Row>
														</div>
													</Form.Group>
												</>
											)}

										<Col sm={6}></Col>
									</div>
								)}
								{group_name_select === "Customized Settings" &&
									edit_select === null && (
										// view_edit_group !== null &&
										<div
											className="sidebar-select--editType  editSettings"
											id="et_head"
										>
											{setting_profile &&
												setting_profile.edit_group_id === null &&
												setting_profile.extra_edit_types !== null &&
												setting_profile.resizing_settings && (
													<>
														<div className="form-sub-head">Edit Type</div>
													</>
												)}

											{/* /***need to check**/}
											{setting_profile &&
												setting_profile.edit_group_id === null &&
												setting_profile.extra_edit_types !== null &&
												setting_profile.resizing_settings &&
												editTypes.map((item) => {
													return (
														<>
															<Form.Group>
																{" "}
																<Row
																	className="form-group  flex-grow-1"
																	sm={12}
																>
																	<Col sm={1}>
																		<input
																			type="checkbox"
																			value={item.id}
																			onClick={(e) => {
																				if (customizedSettings) {
																					handleBackGroundRemovalCheckbox(
																						item.id,
																						e.target.checked,
																						"Edit",
																						customizedSettings
																					);
																					handleAddCheckboxSelect(e);
																				}
																			}}
																			checked={
																				item.id === 1
																					? checked
																					: item.id === 2
																					? checked1
																					: item.id === 3
																					? checked2
																					: checked3
																			}
																			id={`et_${item.id}`}
																			className="et_options"
																			disabled={checkisEditableField()}
																		/>
																	</Col>
																	<Form.Label
																		className="col-sm-4 form-label form-check-label"
																		style={{
																			width: "auto",
																			marginTop: "1.4px",
																			cursor: "pointer",
																		}}
																		for={`et_${item.id}`}
																	>
																		{item.edit_type}
																	</Form.Label>
																</Row>
																{item.id === 1 && checked ? (
																	<Row
																		className="align-items-center"
																		style={{
																			// marginLeft: "44px",
																			marginTop: "-2px",
																			marginBottom: "10px",
																			width: "100%",
																		}}
																	>
																		<Col sm={6}>
																			<Select
																				options={selectBackgrondRemoval}
																				onChange={(e) =>
																					setBackgroundRemovalColorType(e.label)
																				}
																				defaultValue={{
																					label: `${background_removal_color_type}`,
																					id: "21",
																				}}
																				value={{
																					label: background_removal_color_type, // Set the label property to the desired value
																					value: background_removal_color_type, // Set the value property to the corresponding value
																				}}
																				isDisabled={checkisEditableField()}
																				styles={customSelectStyle}
																			/>
																		</Col>
																		<Col sm={6}>
																			<Form.Control
																				type="text"
																				placeholder="#ffff"
																				autoFocus
																				onChange={(e) =>
																					setBackgroundRemovalColorCode(
																						e.target.value
																					)
																				}
																				disabled={checkisEditableField()}
																				value={background_removal_color_code}
																			/>
																		</Col>
																	</Row>
																) : (
																	""
																)}
																{item.id === 2 && checked1 ? (
																	<Row
																		className="align-items-center"
																		style={{
																			// marginLeft: "44px",
																			marginTop: "-2px",
																			marginBottom: "10px",
																			width: "100%",
																		}}
																	>
																		<Col sm={6}>
																			<Select
																				options={selectBackgrondRemoval}
																				onChange={(e) =>
																					setBackgroundAdjustmentColorType(
																						e.label
																					)
																				}
																				defaultValue={{
																					label: `${background_adjustment_color_type}`,
																					id: "21",
																				}}
																				value={{
																					label:
																						background_adjustment_color_type, // Set the label property to the desired value
																					value:
																						background_adjustment_color_type, // Set the value property to the corresponding value
																				}}
																				isDisabled={checkisEditableField()}
																				styles={customSelectStyle}
																			/>
																		</Col>
																		<Col sm={6}>
																			<Form.Control
																				type="text"
																				placeholder="#ffff"
																				autoFocus
																				disabled={checkisEditableField()}
																				onChange={(e) =>
																					setBackgroundAdjustmentColorCode(
																						e.target.value
																					)
																				}
																				value={background_adjustment_color_code}
																			/>
																		</Col>
																	</Row>
																) : (
																	""
																)}
																{item.id === 3 && checked2 ? (
																	<Row
																		className="align-items-center"
																		style={{
																			// marginLeft: "40px",
																			marginTop: "-2px",
																			marginBottom: "10px",
																			width: "100%",
																		}}
																	>
																		<Form.Group
																			style={{ margin: "2px" }}
																			controlId="exampleForm.ControlTextarea1"
																		>
																			<div className="form-group">
																				<Row className="align-items-center">
																					<Col sm={12}>
																						<div className="d-sm-flex align-items-center col-gap-10">
																							<div className="d-sm-flex col-gap-10 align-items-center">
																								<Form.Control
																									type="text"
																									placeholder="Height"
																									autoFocus
																									onChange={(e) =>
																										setResizingHeight(
																											e.target.value
																										)
																									}
																									value={resizing_height}
																									disabled={checkisEditableField()}
																								/>
																							</div>
																							<div className="d-sm-flex align-items-center">
																								<img
																									style={{ width: "18px" }}
																									src={cross}
																									alt="mindreach images"
																								/>
																							</div>
																							<div className="d-sm-flex align-items-center col-gap-10">
																								<Form.Control
																									type="text"
																									placeholder="Width"
																									autoFocus
																									onChange={(e) =>
																										setResizingWidth(
																											e.target.value
																										)
																									}
																									value={resizing_width}
																									disabled={checkisEditableField()}
																								/>
																							</div>
																						</div>
																					</Col>
																				</Row>
																			</div>
																		</Form.Group>
																	</Row>
																) : (
																	""
																)}
															</Form.Group>
														</>
													);
												})}
											{/* Form groups for output specifications */}

											{setting_profile &&
												setting_profile.edit_group_id === null &&
												setting_profile.extra_edit_types !== null && (
													<>
														<div className="form-sub-head">
															Output Specifications
														</div>
														<Form.Group className="mg-bottom15">
															<Row className="form-group  flex-grow-1" sm={12}>
																<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																	File Format
																</Form.Label>
															</Row>
														</Form.Group>
														<Form.Group style={{ margin: "2px" }}>
															<div className="form-group">
																<Row className="align-items-center">
																	<Col sm={12} className="pl-2">
																		<Select
																			options={file}
																			onChange={(e) =>
																				setImageFileFormat(e.label)
																			}
																			value={{
																				label: image_file_format,
																				value: image_file_format, // Set the value property to the corresponding value
																			}}
																			isDisabled={checkisEditableField()}
																		/>
																	</Col>
																</Row>
															</div>
														</Form.Group>
														<Form.Group className="mg-bottom15">
															<Row className="form-group  flex-grow-1" sm={12}>
																<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																	Size
																</Form.Label>
															</Row>
														</Form.Group>
														<Form.Group style={{ margin: "2px" }}>
															<div className="form-group">
																<Row className="align-items-center">
																	<Form.Label className="col-sm-1"></Form.Label>
																	<Col sm={12}>
																		<div className="d-sm-flex align-items-center col-gap-10">
																			<div className="d-sm-flex col-gap-10 align-items-center">
																				<Form.Control
																					type="text"
																					placeholder="Height"
																					onChange={(e) =>
																						setImageHeight(e.target.value)
																					}
																					disabled={checkisEditableField()}
																					value={image_height}
																				/>
																			</div>
																			<div className="d-sm-flex align-items-center">
																				<img
																					style={{ width: "18px" }}
																					src={cross}
																					alt="mindreach images"
																				/>
																			</div>
																			<div className="d-sm-flex align-items-center col-gap-10">
																				<Form.Control
																					type="text"
																					placeholder="Width"
																					onChange={(e) =>
																						setImageWidth(e.target.value)
																					}
																					disabled={checkisEditableField()}
																					value={image_width}
																				/>
																			</div>
																		</div>
																	</Col>
																</Row>
															</div>
														</Form.Group>
														<Form.Group className="mg-bottom15">
															<Row className="form-group  flex-grow-1" sm={12}>
																<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																	DPI
																</Form.Label>
															</Row>
														</Form.Group>
														<Form.Group style={{ margin: "2px" }}>
															<div className="form-group">
																<Row className="align-items-center">
																	<Col sm={12}>
																		<div className="d-sm-flex col-gap-20">
																			<Form.Control
																				type="text"
																				placeholder="2000"
																				autoComplete="off"
																				className="w-50"
																				value={image_dpi}
																				disabled={checkisEditableField()}
																				onChange={(e) =>
																					setImageDpi(e.target.value)
																				}
																			/>
																			<div className="flex-fill">
																				<Select
																					options={dpi}
																					onChange={(e) =>
																						setImageDpiType(e.label)
																					}
																					value={{
																						label: image_dpi_type,
																						value: image_dpi_type,
																					}}
																					isDisabled={checkisEditableField()}
																				/>
																			</div>
																		</div>
																	</Col>
																</Row>
															</div>
														</Form.Group>
														<Form.Group className="mg-bottom15">
															<Row className="form-group  flex-grow-1" sm={12}>
																<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																	Color Mode
																</Form.Label>
															</Row>
														</Form.Group>
														<Form.Group style={{ margin: "2px" }}>
															<div className="form-group">
																<Row className="align-items-center">
																	<Col sm={12}>
																		<Select
																			options={ColorMode}
																			onChange={(e) =>
																				setImageColorMode(e.label)
																			}
																			value={{
																				label: image_color_mode,
																				value: image_color_mode,
																			}}
																			isDisabled={checkisEditableField()}
																		/>
																	</Col>
																</Row>
															</div>
														</Form.Group>
														<Form.Group className="mg-bottom15">
															<Row className="form-group  flex-grow-1" sm={12}>
																<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																	Align
																</Form.Label>
															</Row>
														</Form.Group>
														<Form.Group style={{ margin: "2px" }}>
															<div className="form-group">
																<Row className="align-items-center">
																	<Col sm={12}>
																		<Select
																			options={Align}
																			onChange={(e) =>
																				setImageAlignment(e.label)
																			}
																			value={{
																				label: image_alignment, // Set the label property to the desired value
																				value: image_alignment, // Set the value property to the corresponding value
																			}}
																			isDisabled={checkisEditableField()}
																		/>
																	</Col>
																</Row>
															</div>
														</Form.Group>
														<Form.Group className="mg-bottom15">
															<Row className="form-group  flex-grow-1" sm={12}>
																<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																	Margins
																</Form.Label>
															</Row>
														</Form.Group>
														<Form.Group style={{ margin: "2px" }}>
															<div className="form-group">
																<Row className="align-items-center">
																	<Col sm={12}>
																		<Select
																			options={Margin}
																			onChange={(e) =>
																				setImageMarginFormat(e.label)
																			}
																			value={{
																				label: image_margin_format, // Set the label property to the desired value
																				value: image_margin_format, // Set the value property to the corresponding value
																			}}
																			isDisabled={checkisEditableField()}
																		/>
																	</Col>
																	<Col
																		sm={7}
																		lg={7}
																		style={{
																			display: "flex",
																			marginTop: "12px",
																		}}
																	>
																		<div className="d-sm-flex col-gap-20 mt-2 ">
																			<div className="d-flex">
																				<ul className="margin-list d-flex">
																					<li
																						className={`${
																							checkisEditableField()
																								? "disabled"
																								: ""
																						}`}
																					>
																						<Form.Control
																							type="text"
																							placeholder="T"
																							value={image_margin_top}
																							disabled={checkisEditableField()}
																							onChange={(e) =>
																								setImageMarginTop(
																									e.target.value
																								)
																							}
																						/>
																					</li>
																					<li
																						className={`${
																							checkisEditableField()
																								? "disabled"
																								: ""
																						}`}
																					>
																						<Form.Control
																							type="text"
																							placeholder="B"
																							value={image_margin_bottom}
																							disabled={checkisEditableField()}
																							onChange={(e) =>
																								setImageMarginBottom(
																									e.target.value
																								)
																							}
																						/>
																					</li>
																					<li
																						className={`${
																							checkisEditableField()
																								? "disabled"
																								: ""
																						}`}
																					>
																						<Form.Control
																							type="text"
																							placeholder="L"
																							value={image_margin_left}
																							disabled={checkisEditableField()}
																							onChange={(e) =>
																								setImageMarginLeft(
																									e.target.value
																								)
																							}
																						/>
																					</li>
																					<li
																						className={`${
																							checkisEditableField()
																								? "disabled"
																								: ""
																						}`}
																					>
																						<Form.Control
																							type="text"
																							placeholder="R"
																							value={image_margin_right}
																							disabled={checkisEditableField()}
																							onChange={(e) =>
																								setImageMarginRight(
																									e.target.value
																								)
																							}
																						/>
																					</li>
																				</ul>
																			</div>
																		</div>
																	</Col>
																	<Col sm={6}></Col>
																</Row>
															</div>
														</Form.Group>
													</>
												)}
											{view_edit_group !== undefined &&
												view_edit_group.edit_group === null &&
												setting_profile &&
												setting_profile.setting && (
													<>
														<div className="sub-head">
															Output Specifications
														</div>
														<Form.Group className="mg-bottom15">
															<Row className="form-group  flex-grow-1" sm={9}>
																<Col sm={1}></Col>
																<Form.Label className="col-sm-4 form-label form-check-label">
																	File Format
																</Form.Label>
															</Row>
														</Form.Group>
														<Form.Group style={{ margin: "2px" }}>
															<div className="form-group">
																<Row className="align-items-center">
																	<Form.Label className="col-sm-1"></Form.Label>
																	<Col sm={6} className="pl-2">
																		<Select
																			options={file}
																			onChange={(e) =>
																				setImageFileFormat(e.label)
																			}
																			value={{
																				label: image_file_format, // Set the label property to the desired value
																				value: image_file_format, // Set the value property to the corresponding value
																			}}
																			isDisabled={checkisEditableField()}
																		/>
																	</Col>
																</Row>
															</div>
														</Form.Group>
														<Form.Group className="mg-bottom15">
															<Row className="form-group  flex-grow-1" sm={9}>
																<Col sm={1}></Col>
																<Form.Label className="col-sm-4 form-label form-check-label">
																	Size
																</Form.Label>
															</Row>
														</Form.Group>
														<Form.Group style={{ margin: "2px" }}>
															<div className="form-group">
																<Row className="align-items-center">
																	<Form.Label className="col-sm-1"></Form.Label>
																	<Col sm={9}>
																		<div className="d-sm-flex align-items-center col-gap-10">
																			<div className="d-sm-flex col-gap-10 align-items-center">
																				<Form.Control
																					type="text"
																					placeholder="Height"
																					disabled={checkisEditableField()}
																					value={image_height}
																					onChange={(e) =>
																						setImageHeight(e.target.value)
																					}
																				/>
																			</div>
																			<div className="d-sm-flex align-items-center">
																				<img
																					style={{ width: "18px" }}
																					src={cross}
																					alt="mindreach images"
																				/>
																			</div>
																			<div className="d-sm-flex align-items-center col-gap-10">
																				<Form.Control
																					type="text"
																					placeholder="Width"
																					disabled={checkisEditableField()}
																					value={image_width}
																					onChange={(e) =>
																						setImageWidth(e.target.value)
																					}
																				/>
																			</div>
																		</div>
																	</Col>
																</Row>
															</div>
														</Form.Group>
														<Form.Group className="mg-bottom15">
															<Row className="form-group  flex-grow-1" sm={9}>
																<Col sm={1}></Col>
																<Form.Label className="col-sm-4 form-label form-check-label">
																	DPI
																</Form.Label>
															</Row>
														</Form.Group>
														<Form.Group style={{ margin: "2px" }}>
															<div className="form-group">
																<Row className="align-items-center">
																	<Form.Label className="col-sm-1"></Form.Label>
																	<Col sm={10}>
																		<div className="d-sm-flex col-gap-20">
																			<Form.Control
																				type="text"
																				placeholder="2000"
																				autoComplete="off"
																				className="w-50"
																				value={image_dpi}
																				disabled={checkisEditableField()}
																				onChange={(e) =>
																					setImageDpi(e.target.value)
																				}
																			/>
																			<div className="flex-fill">
																				<Select
																					options={dpi}
																					onChange={(e) =>
																						setImageDpiType(e.label)
																					}
																					value={{
																						label: image_dpi_type, // Set the label property to the desired value
																						value: image_dpi_type, // Set the value property to the corresponding value
																					}}
																					isDisabled={checkisEditableField()}
																				/>
																			</div>
																		</div>
																	</Col>
																</Row>
															</div>
														</Form.Group>
														<Form.Group className="mg-bottom15">
															<Row className="form-group  flex-grow-1" sm={9}>
																<Col sm={1}></Col>
																<Form.Label className="col-sm-4 form-label form-check-label">
																	Color Mode
																</Form.Label>
															</Row>
														</Form.Group>
														<Form.Group style={{ margin: "2px" }}>
															<div className="form-group">
																<Row className="align-items-center">
																	<Form.Label className="col-sm-1"></Form.Label>
																	<Col sm={9}>
																		<Select
																			options={ColorMode}
																			onChange={(e) =>
																				setImageColorMode(e.label)
																			}
																			value={{
																				label: image_color_mode, // Set the label property to the desired value
																				value: image_color_mode, // Set the value property to the corresponding value
																			}}
																			isDisabled={checkisEditableField()}
																		/>
																	</Col>
																</Row>
															</div>
														</Form.Group>
														<Form.Group className="mg-bottom15">
															<Row className="form-group  flex-grow-1" sm={9}>
																<Col sm={1}></Col>
																<Form.Label className="col-sm-4 form-label form-check-label">
																	Align
																</Form.Label>
															</Row>
														</Form.Group>
														<Form.Group style={{ margin: "2px" }}>
															<div className="form-group">
																<Row className="align-items-center">
																	<Form.Label className="col-sm-1"></Form.Label>
																	<Col sm={9}>
																		<Select
																			options={Align}
																			onChange={(e) =>
																				setImageAlignment(e.label)
																			}
																			value={{
																				label: image_alignment, // Set the label property to the desired value
																				value: image_alignment, // Set the value property to the corresponding value
																			}}
																			isDisabled={checkisEditableField()}
																		/>
																	</Col>
																</Row>
															</div>
														</Form.Group>
														<Form.Group className="mg-bottom15">
															<Row className="form-group  flex-grow-1" sm={9}>
																<Col sm={1}></Col>
																<Form.Label className="col-sm-4 form-label form-check-label">
																	Margins
																</Form.Label>
															</Row>
														</Form.Group>
														<Form.Group style={{ margin: "2px" }}>
															<div className="form-group">
																<Row className="align-items-center">
																	<Form.Label className="col-sm-1"></Form.Label>
																	<Col sm={9}>
																		<Select
																			options={Margin}
																			onChange={(e) =>
																				setImageMarginFormat(e.label)
																			}
																			value={{
																				label: image_margin_format, // Set the label property to the desired value
																				value: image_margin_format, // Set the value property to the corresponding value
																			}}
																			isDisabled={checkisEditableField()}
																		/>
																	</Col>
																	<Col
																		sm={7}
																		lg={7}
																		style={{
																			display: "flex",
																			marginLeft: "2rem",
																			marginTop: "12px",
																		}}
																	>
																		<div className="d-sm-flex col-gap-20 mt-2 ">
																			<div className="d-flex">
																				<ul className="margin-list d-flex">
																					<li
																						className={`${
																							checkisEditableField()
																								? "disabled"
																								: ""
																						}`}
																					>
																						<Form.Control
																							type="text"
																							placeholder="T"
																							value={image_margin_top}
																							disabled={checkisEditableField()}
																							onChange={(e) =>
																								setImageMarginTop(
																									e.target.value
																								)
																							}
																						/>
																					</li>
																					<li
																						className={`${
																							checkisEditableField()
																								? "disabled"
																								: ""
																						}`}
																					>
																						<Form.Control
																							type="text"
																							placeholder="B"
																							value={image_margin_bottom}
																							disabled={checkisEditableField()}
																							onChange={(e) =>
																								setImageMarginBottom(
																									e.target.value
																								)
																							}
																						/>
																					</li>
																					<li
																						className={`${
																							checkisEditableField()
																								? "disabled"
																								: ""
																						}`}
																					>
																						<Form.Control
																							type="text"
																							placeholder="L"
																							value={image_margin_left}
																							disabled={checkisEditableField()}
																							onChange={(e) =>
																								setImageMarginLeft(
																									e.target.value
																								)
																							}
																						/>
																					</li>
																					<li
																						className={`${
																							checkisEditableField()
																								? "disabled"
																								: ""
																						}`}
																					>
																						<Form.Control
																							type="text"
																							placeholder="R"
																							value={image_margin_right}
																							disabled={checkisEditableField()}
																							onChange={(e) =>
																								setImageMarginRight(
																									e.target.value
																								)
																							}
																						/>
																					</li>
																				</ul>
																			</div>
																		</div>
																	</Col>
																	<Col sm={6}></Col>
																</Row>
															</div>
														</Form.Group>
													</>
												)}

											<Col sm={6}></Col>
										</div>
									)}
							</div>
						) : (
							<div
								className="side-bar-duplicate-settings--view"
								style={{ padding: "0px 15px" }}
							>
								{viewDuplicateSettingsById !== undefined && (
									<>
										<div className="sub-head mt-2" id="et_head">
											Additional Editing
										</div>
										{editTypes !== undefined &&
											editTypes.map((item) => {
												return (
													<>
														<Form.Group>
															{" "}
															<Row className="form-group  flex-grow-1" sm={9}>
																<Col sm={1}>
																	<input
																		type="checkbox"
																		value={item.id}
																		onChange={(e) => {
																			if (customizedSettings) {
																				handleBackGroundRemovalCheckboxDuplicate(
																					item.id,
																					e.target.checked,
																					"Edit"
																				);
																				handleAddCheckboxSelect(e);
																			}
																		}}
																		checked={
																			item.id === 1 && selectedDuplicateSettings
																				? true
																				: item.id === 2 &&
																				  selectedDuplicateSettings1
																				? true
																				: item.id === 3 &&
																				  selectedDuplicateSettings2
																				? true
																				: item.id === 15 ||
																				  (item.id === 6 &&
																						selectedDuplicateSettings3)
																				? true
																				: false
																		}
																		id={`et_${item.id}`}
																		className="et_options"
																		disabled={checkisEditableField()}
																	/>
																</Col>
																<Form.Label
																	className="col-sm-4 form-label form-check-label"
																	style={{
																		width: "auto",
																		marginTop: "1.4px",
																		cursor: "pointer",
																	}}
																	for={`et_${item.id}`}
																>
																	{item.edit_type}
																</Form.Label>
															</Row>
															{item.id === 1 && selectedDuplicateSettings ? (
																<Row
																	className="align-items-center"
																	style={{
																		// marginLeft: "44px",
																		marginTop: "-2px",
																		marginBottom: "10px",
																		width: "100%",
																	}}
																>
																	<Form.Label className="col-sm-1"></Form.Label>
																	<Col sm={5}>
																		<Select
																			options={selectBackgrondRemoval}
																			onChange={(e) =>
																				setBackgroundRemovalColorTypeDuplicate(
																					e.label
																				)
																			}
																			defaultValue={{
																				label: `${background_removal_color_type_duplicate}`,
																				value: "21",
																			}}
																			value={{
																				label:
																					background_removal_color_type_duplicate, // Set the label property to the desired value
																				value:
																					background_removal_color_type_duplicate !==
																					null
																						? background_removal_color_type_duplicate
																						: "", // Set the value property to the corresponding value
																			}}
																			isDisabled={checkisEditableField()}
																			styles={customSelectStyle}
																		/>
																	</Col>
																	<Col sm={4}>
																		<Form.Control
																			type="text"
																			placeholder="#ffff"
																			autoFocus
																			onChange={(e) =>
																				setBackgroundRemovalColorCodeDuplicate(
																					e.target.value
																				)
																			}
																			disabled={checkisEditableField()}
																			value={
																				background_removal_color_code_duplicate
																			}
																		/>
																	</Col>
																</Row>
															) : (
																""
															)}
															{item.id === 2 && selectedDuplicateSettings1 ? (
																<Row
																	className="align-items-center"
																	style={{
																		// marginLeft: "44px",
																		marginTop: "-2px",
																		marginBottom: "10px",
																		width: "100%",
																	}}
																>
																	<Form.Label className="col-sm-1"></Form.Label>
																	<Col sm={5}>
																		<Select
																			options={selectBackgrondRemoval}
																			onChange={(e) =>
																				setBackgroundAdjustmentColorTypeDuplicate(
																					e.label
																				)
																			}
																			// defaultValue={{
																			// 	label: `${background_adjustment_color_type}`,
																			// 	id: "21",
																			// }}
																			value={{
																				label:
																					background_adjustment_color_type_duplicate, // Set the label property to the desired value
																				value:
																					background_adjustment_color_type_duplicate, // Set the value property to the corresponding value
																			}}
																			isDisabled={checkisEditableField()}
																			styles={customSelectStyle}
																		/>
																	</Col>
																	<Col sm={4}>
																		<Form.Control
																			type="text"
																			placeholder="#ffff"
																			autoFocus
																			disabled={checkisEditableField()}
																			onChange={(e) =>
																				setBackgroundAdjustmentColorCodeDuplicate(
																					e.target.value
																				)
																			}
																			value={
																				background_adjustment_color_code_duplicate
																			}
																		/>
																	</Col>
																</Row>
															) : (
																""
															)}
															{item.id === 3 && selectedDuplicateSettings2 ? (
																<Row
																	className="align-items-center"
																	style={{
																		// marginLeft: "40px",
																		marginTop: "-2px",
																		marginBottom: "10px",
																		width: "100%",
																	}}
																>
																	<Form.Group
																		style={{ margin: "2px" }}
																		controlId="exampleForm.ControlTextarea1"
																	>
																		<div className="form-group">
																			<Row className="align-items-center">
																				<Form.Label className="col-sm-1"></Form.Label>
																				<Col sm={9}>
																					<div className="d-sm-flex align-items-center col-gap-10">
																						<div className="d-sm-flex col-gap-10 align-items-center">
																							<Form.Control
																								type="text"
																								placeholder="Height"
																								autoFocus
																								onChange={(e) =>
																									setResizingHeightDuplicate(
																										e.target.value
																									)
																								}
																								value={
																									resizing_height_duplicate
																								}
																								disabled={resizingOriginalSize}
																							/>
																						</div>
																						<div className="d-sm-flex align-items-center">
																							<img
																								style={{ width: "18px" }}
																								src={cross}
																								alt="mindreach images"
																							/>
																						</div>
																						<div className="d-sm-flex align-items-center col-gap-10">
																							<Form.Control
																								type="text"
																								placeholder="Width"
																								autoFocus
																								onChange={(e) =>
																									setResizingWidthDuplicate(
																										e.target.value
																									)
																								}
																								value={resizing_width_duplicate}
																								disabled={resizingOriginalSize}
																							/>
																						</div>
																					</div>
																				</Col>
																			</Row>
																		</div>
																	</Form.Group>
																	<Row
																		className="align-items-center"
																		style={{
																			marginLeft: "auto",
																			marginRight: "auto",
																			marginTop: "0",
																			marginBottom: "10px",
																			width: " 94%",
																		}}
																	>
																		<Form.Group style={{ margin: "2px" }}>
																			<div className="form-group">
																				<Row className="align-items-center">
																					<Col sm={12} className="ml-1">
																						<div className="d-sm-flex align-items-center col-gap-10">
																							<div className="d-sm-flex col-gap-10 align-items-center">
																								<input
																									type="checkbox"
																									onChange={() => {
																										setResizingOriginalSize(
																											(val) => !val
																										);
																									}}
																									checked={resizingOriginalSize}
																								/>
																							</div>

																							<div className="d-sm-flex align-items-center">
																								<Form.Label
																									className="col-sm-12 form-label form-check-label"
																									// for={`resizing_${index}_${i}`}
																									style={{
																										cursor: "pointer",
																										marginTop: "1px",
																										paddingLeft: "6px",
																									}}
																								>
																									Same as original image
																								</Form.Label>
																							</div>
																						</div>
																					</Col>
																				</Row>
																			</div>
																		</Form.Group>
																	</Row>
																</Row>
															) : (
																""
															)}
														</Form.Group>
													</>
												);
											})}
										{/* Form groups for output specifications */}
										<div className="sub-head">Output Specifications</div>
										<Form.Group style={{ margin: "2px" }} className="pt-2">
											<div className="form-group">
												<Row className="align-items-center">
													<Col sm={12} className="ml-1">
														<div className="d-sm-flex align-items-center col-gap-10">
															<div className="d-sm-flex col-gap-10 align-items-center">
																<input
																	type="checkbox"
																	onChange={() =>
																		setOriginalSize((val) => !val)
																	}
																	className="output_spec_fields"
																	checked={originalSize}
																/>
															</div>

															<div className="d-sm-flex align-items-center">
																<Form.Label
																	className="col-sm-12 form-label form-check-label"
																	style={{
																		cursor: "pointer",
																		marginTop: "1px",
																		paddingLeft: "6px",
																	}}
																>
																	Same as original image
																</Form.Label>
															</div>
														</div>
													</Col>
												</Row>
											</div>
										</Form.Group>
										<Form.Group className="mg-bottom15 pt-1">
											<Row
												className="form-group  flex-grow-1 padding-left-3"
												sm={9}
											>
												<Form.Label
													className={`col-sm-4 form-label form-check-label mb-3 ${
														isFormSubmitted
															? "duplicate-compulsory-field-error"
															: "duplicate-compulsory-field"
													}`}
												>
													File Format <span>*</span>
												</Form.Label>
											</Row>
										</Form.Group>
										<Form.Group style={{ margin: "2px" }}>
											<div className="form-group">
												<Row className="align-items-center">
													<Col sm={6}>
														<Select
															options={file}
															placeholder="Select"
															onChange={(e) => {
																const selectedFormat = e.label; // Assuming e.label holds the selected file format

																setFileFormatDuplicateSize(selectedFormat);
															}}
															value={{
																label: file_format_duplicate_size,
																value: file_format_duplicate_size,
															}}
															isDisabled={originalSize}
															styles={{
																control: (provided) => ({
																	...provided,
																	height: 52,
																	border: `${
																		isFormSubmitted
																			? "1px solid #a92322"
																			: "1px solid #e9e9e9"
																	}`,
																	boxShadow: "none",
																	"&:hover": {
																		border: `${
																			isFormSubmitted
																				? "1px solid #a92322"
																				: "1px solid #e9e9e9"
																		}`,
																	},
																}),
															}}
														/>
													</Col>
												</Row>
											</div>
										</Form.Group>

										<Form.Group className="mg-bottom15">
											<Row
												className="form-group  flex-grow-1 padding-left-3"
												sm={9}
											>
												<Form.Label className="col-sm-4 form-label form-check-label mb-3 side-form-label">
													Margins
												</Form.Label>
											</Row>
										</Form.Group>

										<Form.Group style={{ margin: "2px" }}>
											<div className="form-group">
												<Row className="align-items-center">
													<Col sm={6} style={{ paddingLeft: "13px" }}>
														<Select
															options={Margin}
															placeholder="Select"
															onChange={(e) => {
																const selectedFormat = e.label; // Assuming e.label holds the selected file format
																setMarginFormatDuplicateSize(selectedFormat);
															}}
															isDisabled={originalSize}
															value={{
																label: margin_format_duplicate,
																value: margin_format_duplicate,
															}}
														/>
													</Col>
													<Col
														sm={7}
														lg={7}
														style={{
															display: "flex",

															marginTop: "12px",
														}}
													>
														<div className="d-sm-flex col-gap-20 mt-2">
															<div className="d-flex">
																<ul className={`margin-list d-flex `}>
																	<li>
																		<Form.Control
																			type="text"
																			autoFocus
																			onChange={(e) => {
																				const selectedFormat = e.target.value;
																				setMarginTopDuplicateSize(
																					selectedFormat
																				);
																			}}
																			autoComplete="off"
																			className={`text-center ${
																				originalSize
																					? "placeholder-dark"
																					: "placeholder-light"
																			}`}
																			disabled={originalSize}
																			value={margin_top_duplicateSize}
																		/>
																	</li>
																	<li>
																		<Form.Control
																			type="text"
																			autoFocus
																			onChange={(e) => {
																				const selectedFormat = e.target.value;
																				setMarginBottomDuplicateSize(
																					selectedFormat
																				);
																			}}
																			autoComplete="off"
																			className={`text-center ${
																				originalSize
																					? "placeholder-dark"
																					: "placeholder-light"
																			}`}
																			value={margin_bottom_duplicateSize}
																			disabled={originalSize}
																		/>
																	</li>
																	<li>
																		<Form.Control
																			type="text"
																			autoFocus
																			onChange={(e) => {
																				const selectedFormat = e.target.value;
																				setMarginRightDuplicateSize(
																					selectedFormat
																				);
																			}}
																			autoComplete="off"
																			className={`text-center ${
																				originalSize
																					? "placeholder-dark"
																					: "placeholder-light"
																			}`}
																			value={margin_right_duplicateSize}
																			disabled={originalSize}
																		/>
																	</li>
																	<li>
																		<Form.Control
																			type="text"
																			autoFocus
																			onChange={(e) => {
																				const selectedFormat = e.target.value;
																				setMarginLeftDuplicateSize(
																					selectedFormat
																				);
																			}}
																			autoComplete="off"
																			className={`text-center ${
																				originalSize
																					? "placeholder-dark"
																					: "placeholder-light"
																			}`}
																			value={margin_left_duplicateSize}
																			disabled={originalSize}
																		/>
																	</li>
																</ul>
															</div>
														</div>
													</Col>
													<Col sm={6}></Col>
												</Row>
											</div>
										</Form.Group>
									</>
								)}
							</div>
						)}
					</div>
				</div>
			) : (
				<div className="sidebar--select">
					<h2 className="determined-settings--text">File Settings</h2>
					<h4 className="side-bar-single-select--h2 determined-settings-text--container">
						Image has been set for Pipeline to set the editing requirements.
					</h4>
				</div>
			)}
		</div>
	);
	// }
};

export default SingleSelectorRightSideBar;
