import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Importing toastify module
import { ToastContainer } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

import "./../../../css/style.css";
import "./../../../css/responsive.css";
import "./../../../css/navbar.css";
import logo from "./../../../assets/Images/logo.svg";
import user from "../../../assets/Images/user/no-profile.png";
import notifys from "./../../../assets/Images/notify.svg";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Avatar from "../../Avatar";
import Form from "react-bootstrap/Form";
import { BsBoxArrowRight, BsGearFill } from "react-icons/bs";
import { AuthContext } from "../../../context/Login/Auth.context";
import { client } from "./../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../../data/filepath";

const MainNav = (token, tokenAccess) => {
	const navigate = useNavigate();
	let roles, data, customer;

	customer = sessionStorage.getItem("customer");
	roles = sessionStorage.getItem("role");
	data = JSON.parse(roles);
	customer = JSON.parse(customer);
	const dropdownRef = useRef(null);

	let tokenString = token.tokenAccess ? token.tokenAccess !== null : "";
	let tokenSubString = tokenString;

	const tokenDestroy = sessionStorage.getItem("token");

	const [dropdown, setIsDropdown] = React.useState(false);

	const { dispatch } = React.useContext(AuthContext);
	const handleClickSignOut = (e) => {
		if (tokenDestroy !== null) {
			e.preventDefault();
			const request = client(tokenDestroy);
			request
				.post(apiURL + "/logout")
				.then((response) => {
					navigate("/login");
					window.location.reload();
					dispatch({
						type: "LOGOUT",
					});
				})
				.catch((err) => console.log(err));
		}
	};
	function getCustomerFullName(customer, full_name) {
		if (
			customer && // Check if customer is truthy (not null or undefined)
			full_name !== undefined && // Check if customer's full_name is defined
			full_name !== null // Check if customer's full_name is not null
		) {
			return full_name; // If all conditions are met, return customer's full_name
		} else {
			return null; // Return null if any of the conditions are not met
		}
	}
	const toggleDropdown = () => {
		setIsDropdown(!dropdown);
	};
	const handleOutsideClick = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsDropdown(false);
		}
	};

	useEffect(() => {
		if (dropdown) {
			document.addEventListener("mousedown", handleOutsideClick);
		} else {
			document.removeEventListener("mousedown", handleOutsideClick);
		}

		// Cleanup the event listener on component unmount
		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, [dropdown]);

	return (
		<>
			<ToastContainer
				limit={1}
				theme="colored"
				progressClassName="toastProgress"
				bodyClassName="toastBody"
			/>
			<Navbar collapseOnSelect expand="lg">
				<Navbar.Brand href="#home">
					<img
						src={logo}
						alt="logo"
						className="img-fluid"
						// alt="mindreach images"
					/>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse aria-controls="responsive-navbar-nav">
					{tokenSubString !== "" && (
						<>
							<Nav className="ms-auto" onClick={() => toggleDropdown()}>
								<Nav.Link className="notify">
									<img src={notifys} alt="notifys" className="img-fluid" />
									<span className="count-symbol bg-danger"></span>
								</Nav.Link>

								<Form
									className="nav-link  d-sm-flex"
									id="basic-nav-dropdown"
									ata-bs-toggle="dropdown"
									data-toggle="collapse"
									data-target="#navbar"
									aria-controls="navbar"
									aria-expanded="true"
								>
									<div>
										<div className="nav-profile-img">
											<Avatar name={customer && customer.full_name} />
										</div>
									</div>
									<div className="nav-profile-text">
										<p className="user-name overflow__none">
											{customer &&
												customer.full_name !== null &&
												getCustomerFullName(customer, customer.full_name)}
										</p>
										<p className="user-designation">
											{data && data.role !== null && data.role}
										</p>
									</div>
									<NavDropdown
										// title="Link"
										id="profileDropdown"
										className="nav-link d-sm-flex dropdown-menu.show "
										style={{ background: "#0a0a0a00;" }}
									>
										<NavDropdown.Item
											style={{ color: "black", marginTop: "0" }}
											onClick={() => navigate("/edit-overview")}
										>
											<div className="navbar-data-toggle--popover">
												<div>
													<BsGearFill />
												</div>
												<div>Edit Profile</div>
											</div>
										</NavDropdown.Item>
										<NavDropdown.Item
											onClick={(e) => handleClickSignOut(e)}
											style={{ color: "black", marginTop: "0" }}
										>
											<div className="navbar-data-toggle--popover">
												<div>
													<BsBoxArrowRight />
												</div>
												<div>Sign out</div>
											</div>
										</NavDropdown.Item>
									</NavDropdown>
								</Form>
							</Nav>
							{dropdown && (
								<div ref={dropdownRef} class="dropdown-menu2">
									<ul>
										<li>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="16"
												height="16"
												fill="currentColor"
												class="bi bi-person"
												viewBox="0 0 16 16"
											>
												<path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"></path>
											</svg>
											Profile
										</li>
										<li>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="16"
												height="16"
												fill="currentColor"
												class="bi bi-gear"
												viewBox="0 0 16 16"
											>
												<path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0"></path>
												<path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z"></path>
											</svg>
											Settings
										</li>
										<li onClick={(e) => handleClickSignOut(e)}>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="16"
												height="16"
												fill="currentColor"
												class="bi bi-box-arrow-right"
												viewBox="0 0 16 16"
											>
												<path
													fill-rule="evenodd"
													d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"
												></path>
												<path
													fill-rule="evenodd"
													d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"
												></path>
											</svg>
											Logout
										</li>
									</ul>
								</div>
							)}
						</>
					)}
				</Navbar.Collapse>
			</Navbar>
		</>
	);
};

export default MainNav;
