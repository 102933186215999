import React, { useCallback, useEffect, useState } from "react";
import {
	Button,
	Row,
	Col,
	Form,
	Modal,
	Tab,
	Tabs,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";
import ToastAlert from "../../../../components/ToastAlert";

import Select from "react-select";
import Loader from "../../../../components/Loader";

//Importing the image components
import cross from "./../../../../assets/Images/cross-3.svg";

import { client } from "./../../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "./../../../../data/filepath";
import {
	CO_ORDINATOR_VIEW_EDIT_GROUP,
	CO_ORDINATOR_UPDATE_EDIT_GROUP,
	CO_ORDINATOR_DUPLICATE_IMAGES,
} from "../../../../config/apiRoutes.api";

const customSelectStyles = {
	menuList: (provided) => {
		return {
			...provided,
			"& :first-child": {
				fontWeight: "bold",
				fontSize: "13px",
			},
		};
	},
	menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

let is_profile_selected = false;
let editTypeTempArray = [];

const DuplicateSettings = ({
	setting_profile,
	selectImageName,
	token,
	currentUserId,
	idSelected,
	edit_select,
	setEditorType,
	editTypes,
	editGroups,
	setEditGroups,
	setEditTypes,
	customer_id,
	setMultipleImagesId,
	setClickSelect,
	setSelectAll,
	title,
	onHide,
	show,
	handleFetchProfileSettings,
	duplicate_amount,
	copyAmounts,
	setDuplicateAmount,
	setDuplicateSettings,
	fetchOrderDuplicateSettings,
	batch_id_query,
	...props
}) => {
	const renderTooltip = (text) => <Tooltip id="button-tooltip">{text}</Tooltip>;
	/**
	 * Transform an array of customer edit types into an array of objects with label, value, and additional properties.
	 * @param {Array} customerEditTypes - Array of customer edit types.
	 * @returns {Array} - Transformed array of objects with label, value, and additional properties.
	 */
	const selectLabel = (customerEditTypes) =>
		customerEditTypes.map(({ group_name: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of array as label and value return a new array of object like [{value:"",label:""}]
	// Create a FormData object for request payload
	const bodyFormData = new FormData();

	// Create an HTTP client with the token
	const request = client(token);

	//Declarations of const
	const file = [
		{ label: "JPEG", value: 355 },
		{ label: "PNG", value: 54 },
		{ label: "JPG", value: 54 },
	];
	const selectBackgrondRemoval = [
		{ label: "Custom", value: 355 },
		{ label: "Transparent", value: 54 },
	];
	const selectedAmount = [
		{ label: "1", value: 355 },
		{ label: "2", value: 54 },
		{ label: "3", value: 54 },
	];
	const dpi = [
		{ label: "JPEG", value: 355 },
		{ label: "PNG", value: 54 },
		{ label: "JPG", value: 54 },
	];

	const ColorMode = [
		{ label: "Original", value: 355 },
		{ label: "Edited", value: 54 },
	];
	const Align = [
		{ label: "center", value: 355 },
		{ label: "start", value: 54 },
		{ label: "end", value: 55 },
	];

	const Margin = [
		{ label: "pixel", value: 355 },
		{ label: "em", value: 54 },
		{ label: "rem", value: 55 },
		{ label: "%", value: 56 },
	];

	//Declarations of state
	const [error, setError] = useState("");
	const [status, setStatus] = useState("");
	const [loader, setLoader] = useState(false); //Loader State
	const [groupSelect, setGroupSelect] = useState(false);
	const [checked, setChecked] = useState(false);
	const [checked1, setChecked1] = useState(false);
	const [checked2, setChecked2] = useState(false);
	const [checked3, setChecked3] = useState(false);
	const [selected, setSelected] = useState(false);
	const [selected1, setSelected1] = useState(false);
	const [selected2, setSelected2] = useState(false);
	const [selected3, setSelected3] = useState(false);
	const [customizedSettings, customizedSettingsEnabled] = useState(false);
	const [previousSelectedValue, setPreviousSelectedValue] = useState("");
	const [copyTabEditTypeSelected, setCopyTabEditTypes] = useState([]);
	const [disableResizing, setDisableResizing] = useState([]);
	const [disableOutputSpecifications, setDisableOutputSpecifications] =
		useState([]);
	const [fieldArray, setFieldArray] = useState({});
	const [fieldError, setFieldError] = useState({});

	const [amount, setAmount] = useState([]);
	const [edit_id, setEditId] = useState([]);
	const [edit_type_toggle, setToggleEditType] = useState(false);
	//To update the select group
	const [group_name_select, setEditGroupNameSelect] = useState("Select");
	// To set the state for add
	const [background_removal_color_type, setBackgroundRemovalColorType] =
		useState("");
	const [background_removal_color_code, setBackgroundRemovalColorCode] =
		useState("");
	const [background_adjustment_color_code, setBackgroundAdjustmentColorCode] =
		useState("");
	const [background_adjustment_color_type, setBackgroundAdjustmentColorType] =
		useState("");
	const [view_edit_group, setViewEditGroup] = useState({});
	const [group_name, setGroupName] = useState("");
	const [new_edit_group_name, setNewEditGroupName] = useState("");

	const [resizing_file_format, setResizingFileFormat] = useState("");
	const [resizing_height, setResizingHeight] = useState("");
	const [resizing_width, setResizingWidth] = useState("");
	const [resizing_dpi, setResizingDpi] = useState("");
	const [resizing_dpi_type, setResizingDpiType] = useState("");
	const [resizing_color_mode, setResizingColorMode] = useState("");
	const [resizing_alignment, setResizingAlignment] = useState("");
	const [resizing_margin_format, setResizingMarginFormat] = useState("");
	const [resizing_margin_top, setResizingMarginTop] = useState("");
	const [resizing_margin_bottom, setResizingMarginBottom] = useState("");
	const [resizing_margin_right, setResizingMarginRight] = useState("");
	const [resizing_margin_left, setResizingMarginLeft] = useState("");
	const [edit_group_name, setEditGroupName] = useState("");
	const [edit_type_ids, setEditTypeId] = useState("");
	const [image_file_format, setImageFileFormat] = useState("");
	const [image_height, setImageHeight] = useState("");
	const [image_width, setImageWidth] = useState("");
	const [image_dpi, setImageDpi] = useState("");
	const [image_dpi_type, setImageDpiType] = useState("");
	const [image_color_mode, setImageColorMode] = useState("");
	const [image_alignment, setImageAlignment] = useState("");
	const [image_margin_format, setImageMarginFormat] = useState("");
	const [image_margin_top, setImageMarginTop] = useState("");
	const [image_margin_bottom, setImageMarginBottom] = useState("");
	const [image_margin_right, setImageMarginRight] = useState("");
	const [image_margin_left, setImageMarginLeft] = useState("");
	const [group, setGroup] = useState([]);
	const [key, setKey] = useState(0); // Initialize active tab to '1'
	const [activeTab, setActiveTab] = useState(1); // Initialize activeTab state to 0
	const [newImageEditType, setNewImageEditType] = useState([]);
	const [errorImageEditType, setErrorImageEditType] = useState([]);
	const [imageNamePath, setImageNamePath] = useState("");
	const [errorTabs, setErrorTabs] = useState("");
	const [isFormSubmitted, setFormSubmitted] = useState(false);
	const [isCheckedOutputSpecifications, setIsCheckedOutputSpecification] =
		useState([]);
	const [isCheckedResizing, setIsCheckedResizing] = useState([]);

	// State variables for form data and errors
	const [data, setData] = useState({
		file_format: "",
		height: "",
		width: "",
		dpi: "",
		dpi_type: "",
		color_mode: "",
		alignment: "",
		margin_format: "",
		margin_top: "",
		margin_bottom: "",
		margin_right: "",
		margin_left: "",
	});
	const [errors, setErrors] = useState({});
	const [isDisabled, setIsDisabled] = useState(false);

	const fieldValidationRules = {
		file_format: "File Format is required",
		height: "",
		width: "",
		dpi: "",
		dpi_type_Value: "",
		color_mode_Value: "",
		alignment_Value: "",
		margin_format: "",
		margin_top: "",
		margin_bottom: "",
		margin_right: "",
		margin_left: "",
	};

	/**
	 * Handle the selection of checkboxes and update the `editId` state accordingly.
	 * @param {ChangeEvent} changeEvent - The change event object.
	 */
	const handleAddCheckboxSelect = (changeEvent) => {
		changeEvent.persist();
		setEditId((prevState) => {
			const editItem = changeEvent.target.value;

			if (prevState.includes(editItem)) {
				// If the selected editItem is already in the prevState array,
				// remove it from the array and return the updated array.
				return prevState.filter((el) => el !== editItem);
			}
			// If the selected editItem is not in the prevState array,
			// add it to the array and return the updated array.
			return [...prevState, editItem];
		});
	};
	/* Handle the checkbox state and perform actions based on the given id.
	 * @param {number} id - The id to determine the checkbox action.
	 */
	const handleBackGroundRemovalCheckbox = (id) => {
		if (id === 1) {
			setSelected((val) => !val);
			setChecked((val) => !val);
		}
		if (id === 2) {
			setSelected1((val) => !val);
			setChecked1((val) => !val);
		}
		if (id === 3) {
			setSelected2((val) => !val);
			setChecked2((val) => !val);
		}
		if (id === 15) {
			setSelected3((val) => !val);
			setChecked3((val) => !val);
		}
	};
	/**
	 * Create an array of checked values for the edit_types in view_edit_group
	 */
	function getEditTypesIds(view_edit_group) {
		if (
			view_edit_group &&
			view_edit_group.edit_group &&
			view_edit_group.edit_group.edit_types &&
			Array.isArray(view_edit_group.edit_group.edit_types)
		) {
			return view_edit_group.edit_group.edit_types.map((items, index) => {
				return items.id;
			});
		} else {
			// Handle the case where any of the properties is null or not an array
			// You can return a default value or handle the error appropriately.
			return []; // Returning an empty array as a default value
		}
	}

	/**
	 * Handles to get all the checked values of checkbox by class name.
	 * @returns {void}
	 */
	const getEditTypeValues = () => {
		const checkedValues = document.querySelectorAll(".et_options");
		const checkedValue = [];
		checkedValues.forEach((element) => {
			if (element.checked) {
				checkedValue.push(element.value);
			}
		});
		return checkedValue.length > 0 ? checkedValue.join(",") : "";
	};

	/**
	 * Handles to get all the checked values of checkbox by class name.
	 * @param {String} className - class name by which we need fetch the additional editing checkboxes.
	 * @param {Number} index - The active index of the tab.
	 * @returns {Object} returns the object array of check boxes based on checked values of each tab
	 */
	const getAdditionalEditTypeValues = (className = "", index) => {
		const checkedValues = document.querySelectorAll(
			className && className !== "" ? "." + className : ".et_options"
		);
		let checkedValue = "";
		let fieldArrayTemp = [...fieldArray];
		checkedValues.forEach((element) => {
			const val = element.value;
			if (element.checked) {
				checkedValue += val + ",";
			}
		});
		return checkedValue ? `"${checkedValue.replace(/,\s*$/, "")}"` : "";
	};

	/**
	 * Handles the update of an image edit group by sending a POST request to the server.
	 * @returns {void}
	 */
	const handleUpdateImageEditGroup = () => {
		if (token) {
			setLoader(true);
			// Append form data with the necessary parameters
			const editTypeId = getEditTypeValues();
			bodyFormData.append("customer_id", customer_id);
			bodyFormData.append("image_ids", idSelected);
			bodyFormData.append("batch_number", batch_id_query);

			bodyFormData.append("is_overright", 1);
			if (edit_select > 0) {
				bodyFormData.append("edit_group_id", edit_select);
			} else {
				bodyFormData.append(
					"extra_edit_type_ids",
					group_name_select === "New Settings" ||
						group_name_select === "Customized Settings"
						? editTypeId
						: ""
				);
			}
			bodyFormData.append(
				"background_removal_color_type",
				background_removal_color_type
			);
			bodyFormData.append(
				"background_removal_color_code",
				background_removal_color_code
			);
			bodyFormData.append(
				"background_adjustment_color_type",
				background_adjustment_color_type
			);
			bodyFormData.append(
				"background_adjustment_color_code",
				background_adjustment_color_code
			);
			bodyFormData.append("resizing_height", resizing_height);
			bodyFormData.append("resizing_width", resizing_width);

			bodyFormData.append("image_file_format", image_file_format);
			bodyFormData.append("image_height", image_height);
			bodyFormData.append("image_width", image_width);
			bodyFormData.append("image_dpi", image_dpi);
			bodyFormData.append("image_dpi_type", image_dpi_type);
			bodyFormData.append("image_color_mode", image_color_mode);
			bodyFormData.append("image_alignment", image_alignment);
			bodyFormData.append("image_margin_format", image_margin_format);
			bodyFormData.append("image_margin_top", image_margin_top);
			bodyFormData.append("image_margin_bottom", image_margin_bottom);
			bodyFormData.append("image_margin_right", image_margin_right);
			bodyFormData.append("image_margin_left", image_margin_left);

			request
				.post(apiURL + CO_ORDINATOR_UPDATE_EDIT_GROUP, bodyFormData)
				.then((response) => {
					//Set the success from the response
					ToastAlert({
						title: response.data.status ? "Success" : "Error",
						message: response.data.status ? "Group updated" : "Error",
						autoClose: 2000,
						messageType: response.data.status ? "success" : "error",
					});
					setLoader(false);
					setMultipleImagesId([]);
					setClickSelect(false);
					setSelectAll(false);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	const handleUpdateEditGroup = () => {
		if (token) {
			setLoader(true);
			// Append form data with the necessary parameters
			const editTypeId = getEditTypeValues();
			bodyFormData.append("customer_id", customer_id);
			bodyFormData.append("image_ids", idSelected);
			bodyFormData.append("batch_number", batch_id_query);
			bodyFormData.append("is_overright", 1);
			bodyFormData.append("extra_edit_type_ids", editTypeId);

			bodyFormData.append(
				"background_removal_color_type",
				background_removal_color_type
			);
			bodyFormData.append(
				"background_removal_color_code",
				background_removal_color_code
			);
			bodyFormData.append(
				"background_adjustment_color_type",
				background_adjustment_color_type
			);
			bodyFormData.append(
				"background_adjustment_color_code",
				background_adjustment_color_code
			);
			bodyFormData.append("resizing_height", resizing_height);
			bodyFormData.append("resizing_width", resizing_width);

			bodyFormData.append("image_file_format", image_file_format);
			bodyFormData.append("image_height", image_height);
			bodyFormData.append("image_width", image_width);
			bodyFormData.append("image_dpi", image_dpi);
			bodyFormData.append("image_dpi_type", image_dpi_type);
			bodyFormData.append("image_color_mode", image_color_mode);
			bodyFormData.append("image_alignment", image_alignment);
			bodyFormData.append("image_margin_format", image_margin_format);
			bodyFormData.append("image_margin_top", image_margin_top);
			bodyFormData.append("image_margin_bottom", image_margin_bottom);
			bodyFormData.append("image_margin_right", image_margin_right);
			bodyFormData.append("image_margin_left", image_margin_left);

			request
				.post(apiURL + CO_ORDINATOR_UPDATE_EDIT_GROUP, bodyFormData)
				.then((response) => {
					//Set the success from the response
					ToastAlert({
						title: response.data.status ? "Success" : "Error",
						message: response.data.status ? "Succesfully saved" : "Error",
						autoClose: 2000,
						messageType: response.data.status ? "success" : "error",
					});
					setLoader(false);
					setMultipleImagesId([]);
					setClickSelect(false);
					setSelectAll(false);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	useEffect(() => {
		// Create a new array by combining existing group values and the new object
		const updatedGroup = editGroups ? [...editGroups] : [];
		setGroup(updatedGroup);
		setSelected(false);
		setSelected1(false);
		setSelected3(false);
	}, [editGroups !== undefined]);

	/**
	 * Handles to check the option value has changed and reset the state for all the checkboxes.
	 * @returns {void}
	 */

	useEffect(() => {
		if (edit_select > 0) {
			customizedSettingsEnabled(false);
		} else {
			if (!customizedSettings || edit_select === "New Settings") {
				setSelected(false);
				setSelected1(false);
				setSelected2(false);
				setSelected3(false);
			}
			if (edit_select === "New Settings") {
				customizedSettingsEnabled(false);
			}
			setTimeout(() => {
				const et_head = document.getElementById("et_head");
				if (et_head) {
					et_head.scrollIntoView();
				}
			}, 50);
		}
	}, [edit_select]);

	useEffect(() => {
		// Create a new array by combining existing group values and the new object
		if (
			setting_profile &&
			setting_profile.id > 0 &&
			setting_profile.edit_group_id === null &&
			setting_profile.extra_edit_types &&
			idSelected.indexOf(",") < 0
		) {
			if (editGroups || props.editGroups) {
				let copygGroup = editGroups ? editGroups : props.editGroups;
				const updatedGroup = [
					{ id: "Customized Settings", group_name: "Customized Settings" },
					// { id: "New Settings", group_name: "New Settings" },
					...copygGroup,
				];
				setGroup(updatedGroup);
				customizedSettingsEnabled(true);
			}
			is_profile_selected = true;
			setEditGroupNameSelect("Customized Settings");
			setEditorType("Customized Settings");
			setViewEditGroup({ edit_group: setting_profile });
			if (
				setting_profile &&
				setting_profile.extra_edit_types &&
				setting_profile.extra_edit_types.indexOf("1") >= 0
			) {
				setSelected(true);
			} else {
				setSelected(false);
			}
			if (
				setting_profile &&
				setting_profile.extra_edit_types &&
				setting_profile.extra_edit_types.indexOf("2") >= 0
			) {
				setSelected1(true);
			} else {
				setSelected1(false);
			}

			if (
				setting_profile &&
				setting_profile.extra_edit_types &&
				setting_profile.extra_edit_types.indexOf("3") >= 0
			) {
				setSelected2(true);
			} else {
				setSelected2(false);
			}
			if (
				setting_profile &&
				setting_profile.extra_edit_types &&
				(setting_profile.extra_edit_types.indexOf("6") >= 0 ||
					setting_profile.extra_edit_types.indexOf("15") >= 0)
			) {
				setSelected3(true);
			} else {
				setSelected3(false);
			}
			setBackgroundRemovalColorCode(
				setting_profile.background_settings[0] &&
					setting_profile.background_settings[0].edit_type_id === 1
					? setting_profile.background_settings[0].color_code
					: ""
			);
			setBackgroundAdjustmentColorCode(
				setting_profile.background_settings[1] &&
					setting_profile.background_settings[1].edit_type_id === 2
					? setting_profile.background_settings[1].color_code
					: ""
			);
			setBackgroundRemovalColorType(
				setting_profile.background_settings[0] &&
					setting_profile.background_settings[0].edit_type_id === 1
					? setting_profile.background_settings[0].color_type
					: ""
			);
			setBackgroundAdjustmentColorType(
				setting_profile.background_settings[1] &&
					setting_profile.background_settings[1].edit_type_id === 2
					? setting_profile.background_settings[1].color_type
					: ""
			);

			setResizingHeight(
				setting_profile.resizing_settings[0] &&
					setting_profile.resizing_settings[0].height
					? setting_profile.resizing_settings[0].height
					: ""
			);
			setResizingWidth(
				setting_profile.resizing_settings[0] &&
					setting_profile.resizing_settings[0].width
					? setting_profile.resizing_settings[0].width
					: ""
			);
			// Output specification fields
			setImageFileFormat(
				setting_profile.file_format ? setting_profile.file_format : ""
			);
			setImageHeight(setting_profile.height ? setting_profile.height : "");
			setImageWidth(setting_profile.width ? setting_profile.width : "");
			setImageDpi(setting_profile.dpi ? setting_profile.dpi : "");
			setImageDpiType(setting_profile.dpi_type ? setting_profile.dpi_type : "");
			setImageColorMode(
				setting_profile.color_mode ? setting_profile.color_mode : ""
			);
			setImageAlignment(
				setting_profile.alignment ? setting_profile.alignment : ""
			);
			setImageMarginFormat(
				setting_profile.margin_format ? setting_profile.margin_format : ""
			);
			setImageMarginTop(
				setting_profile.margin_top ? setting_profile.margin_top : ""
			);
			setImageMarginBottom(
				setting_profile.margin_bottom ? setting_profile.margin_bottom : ""
			);
			setImageMarginRight(
				setting_profile.margin_right ? setting_profile.margin_right : ""
			);
			setImageMarginLeft(
				setting_profile.margin_left ? setting_profile.margin_left : ""
			);
		}
	}, [setting_profile]);

	useEffect(() => {
		// Fetch data from the API endpoint
		if (token && edit_select > 0 && idSelected.indexOf(",") < 0) {
			request
				.get(apiURL + CO_ORDINATOR_VIEW_EDIT_GROUP + `/${edit_select}`)
				.then((response) => {
					customizedSettingsEnabled(false);
					setEditGroupNameSelect("");
					//Set View  for edit group from the response
					let data = response.data;
					setViewEditGroup(data);
					setBackgroundRemovalColorCode(
						data.edit_group.background_settings[0].edit_type_id === 1
							? data.edit_group.background_settings[0].color_code
							: data.edit_group.background_settings[1].color_code
					);
					setBackgroundAdjustmentColorCode(
						data.edit_group.background_settings[0] !== undefined &&
							data.edit_group.background_settings[0].edit_type_id === 2
							? data.edit_group.background_settings[0].color_code
							: data.edit_group.background_settings[1] !== undefined &&
							  data.edit_group.background_settings[1].edit_type_id === 2
							? data.edit_group.background_settings[1].color_code
							: ""
					);
					setBackgroundRemovalColorType(
						data.edit_group.background_settings[0] !== undefined &&
							data.edit_group.background_settings[0].edit_type_id === 1
							? data.edit_group.background_settings[0].color_type
							: data.edit_group.background_settings[1] !== undefined &&
							  data.edit_group.background_settings[1].edit_type_id === 1
							? data.edit_group.background_settings[1].color_type
							: "Select Color Type"
					);
					setBackgroundAdjustmentColorType(
						data.edit_group.background_settings[0] !== undefined &&
							data.edit_group.background_settings[0].edit_type_id === 2
							? data.edit_group.background_settings[0].color_type
							: data.edit_group.background_settings[1] !== undefined &&
							  data.edit_group.background_settings[1].edit_type_id === 2
							? data.edit_group.background_settings[1].color_type
							: "Select Color Type"
					);

					setImageFileFormat(
						data.edit_group.setting.file_format !== undefined
							? data.edit_group.setting.file_format
							: "Select file format"
					);

					setImageDpi(
						data.edit_group.setting && data.edit_group.setting.dpi !== null
							? data.edit_group.setting.dpi
							: ""
					);
					setImageDpiType(
						data.edit_group.setting.dpi_type !== null
							? data.edit_group.setting.dpi_type
							: "Select Dpi type"
					);
					setImageColorMode(
						data.edit_group.setting !== null
							? data.edit_group.setting.color_mode
							: "Select color mode"
					);
					setImageAlignment(
						data.edit_group.setting !== null
							? data.edit_group.setting.alignment
							: "Select Align"
					);
					setImageMarginFormat(
						data.edit_group.setting !== null
							? data.edit_group.setting.margin_format
							: "Margin"
					);
					setImageHeight(
						data.edit_group.setting.height !== undefined
							? data.edit_group.setting.height
							: ""
					);
					setImageWidth(
						data.edit_group.setting.width !== undefined
							? data.edit_group.setting.width
							: ""
					);
					setImageMarginTop(
						data.edit_group.setting !== null
							? data.edit_group.setting.margin_top
							: ""
					);
					setImageMarginBottom(
						data.edit_group.setting !== null
							? data.edit_group.setting.margin_bottom
							: ""
					);
					setImageMarginRight(
						data.edit_group.setting !== null
							? data.edit_group.setting.margin_right
							: ""
					);
					setImageMarginLeft(
						data.edit_group.setting !== null
							? data.edit_group.setting.margin_left
							: ""
					);
					if (data.edit_group.background_settings.length) {
						setSelected(true);
						setSelected1(true);
					} else {
						setSelected(false);
						setSelected1(false);
					}
					if (
						data.edit_group.resizing_settings &&
						data.edit_group.resizing_settings[0]
					) {
						setSelected2(true);
						setResizingHeight(
							data.edit_group.resizing_settings[0] &&
								data.edit_group.resizing_settings[0].height
								? data.edit_group.resizing_settings[0].height
								: ""
						);
						setResizingWidth(
							data.edit_group.resizing_settings[0] &&
								data.edit_group.resizing_settings[0].width
								? data.edit_group.resizing_settings[0].width
								: ""
						);
					} else {
						setSelected2(false);
						setResizingHeight("");
						setResizingWidth("");
					}
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		} else {
			if (!customizedSettings && (!setting_profile || !setting_profile.id)) {
				setViewEditGroup({});
				setBackgroundRemovalColorCode("");
				setBackgroundAdjustmentColorCode("");
				setBackgroundRemovalColorType("");
				setBackgroundAdjustmentColorType("");
				setResizingFileFormat("");
				setResizingDpiType("");
				setResizingColorMode("");
				setResizingAlignment("");
				setResizingMarginFormat("");
				setImageFileFormat("");
				setImageDpiType("");
			}
		}
	}, [edit_select, token, is_profile_selected]);
	// Usage
	let updateChecked = getEditTypesIds(setting_profile);
	useEffect(() => {
		setAmount(duplicate_amount);
		if (idSelected && idSelected.indexOf(",") >= 0) {
			setEditGroupNameSelect("Select");
			setEditorType("Select");
		}
	}, []);
	const getFilteredSet = (propertyName, dataArray) => {
		return (
			dataArray !== undefined &&
			dataArray
				.map((items) => items[propertyName])
				.filter((value) => value !== undefined)
		);
	};
	const newErrors = {};
	const fileFormatSet = getFilteredSet("file_format", amount);
	const heightSet = getFilteredSet("height", amount);
	const widthSet = getFilteredSet("width", amount);
	const dpiSet = getFilteredSet("dpi", amount);
	const dpiTypeSet = getFilteredSet("dpi_type", amount);
	const colorModeSet = getFilteredSet("color_mode", amount);
	const alignmentSet = getFilteredSet("alignment", amount);
	const marginFormatSet = getFilteredSet("margin_format", amount);
	const marginTopSet = getFilteredSet("margin_top", amount);
	const marginBottomSet = getFilteredSet("margin_bottom", amount);
	const marginRightSet = getFilteredSet("margin_right", amount);
	const marginLeftSet = getFilteredSet("margin_left", amount);
	// Validation function
	const validateField = (fieldName, value) => {
		// Implement your validation logic here
		// For simplicity, let's assume required fields cannot be empty
		if (!value) {
			return `${fieldName} is required`;
		}
		// Additional validation rules can be added as needed
		return "";
	};
	// Function to validate the form fields

	const validate = () => {
		let newErrors = {};

		// Combine margin errors into a single error message
		const marginFields = [
			"margin_top",
			"margin_bottom",
			"margin_right",
			"margin_left",
		];
		if (
			marginFields.some(
				(field) =>
					newImageEditType[field] !== undefined &&
					newImageEditType[field].length !== amount.length
			)
		) {
			newErrors.margin = "All margin fields are required";
		}

		// Validate and set errors for individual fields
		for (const field in fieldValidationRules) {
			if (
				newImageEditType[field] !== undefined &&
				newImageEditType[field].length !== amount.length
			) {
				newErrors[field] = fieldValidationRules[field];
			}
		}

		// Check if there are any validation errors
		const missingFields = Object.keys(fieldValidationRules).filter(
			(field) =>
				newImageEditType[field] === undefined ||
				newImageEditType[field].length !== amount.length
		);

		if (missingFields.length > 0) {
			newErrors.all = `Please fill the required details for ${missingFields
				.map((field) => field.replace(/_/g, " "))
				.join(", ")}`;
		}

		return newErrors;
	};

	useEffect(() => {
		if (
			amount &&
			amount.length > 0 &&
			fieldArray &&
			Object.keys(fieldArray).length !== amount.length
		) {
			let dupliateFieldArray = [];
			let fieldError = [];
			amount.forEach((obj) => {
				dupliateFieldArray.push({
					bg_removal_type: "",
					bg_removal_color: "",
					bg_adjustment_type: "",
					bg_adjustment_color: "",
					height: "",
					width: "",
					file_format: "",
					margin_format: "",
					margin_top: "",
					margin_bottom: "",
					margin_right: "",
					margin_left: "",
					isFormSubmitted: false,
					isCheckedResizing: "",
				});
				fieldError.push({
					heigth: "",
					width: "",
					file_format: fieldValidationRules["file_format"],
					margin_format: fieldValidationRules["margin_format"],
					margin_top: fieldValidationRules["margin_top"],
					margin_bottom: fieldValidationRules["margin_bottom"],
					margin_right: fieldValidationRules["margin_right"],
					margin_left: fieldValidationRules["margin_left"],
				});
			});
			setFieldArray(dupliateFieldArray);
			setFieldError(fieldError);
		}
	}, [amount]);

	/**
	 * Handles to tab form submit.
	 * @param {Number} tabIndex - tab index which form submmited.
	 */
	console.log("fieldArray", fieldArray);
	const handleFormSubmit = (tabIndex) => {
		const settings = getSettings();
		// Check if there are any validation errors
		const checkFieldError = validateCopyForm();
		const edit_types = getAdditionalEdityTypes();
		const file_format = settings.file_format;
		const margin_format = settings.margin_format;
		const margin_right = settings.margin_right;
		const margin_left = settings.margin_left;
		const margin_top = settings.margin_top;
		const margin_bottom = settings.margin_bottom;
		const resizing_height = settings.height;
		const resizing_width = settings.width;
		const same_as_original = isCheckedOutputSpecificationstoString();
		const same_as_original_resizing = isCheckedResizingtoString();
		const bg_removal_type = settings.bg_removal_type;
		const bg_removal_color = settings.bg_removal_color;
		const bg_adjustment_type = settings.bg_adjustment_type;
		const bg_adjustment_color = settings.bg_adjustment_color;

		setFormSubmitted(true);
		if (checkFieldError) {
			return;
		}
		// Continue with tab navigation
		if (key < amount.length) {
			setErrorImageEditType([]);

			setKey(Number(activeTab) + Number(key));
		}

		const formatAndAppendValue = (formData, property, value) => {
			if (value !== undefined) {
				const formattedValue =
					value !== undefined
						? value
								.filter((id) => id) // Filter out non-numeric values
								.map((id) => id)
								.join(",")
						: "";
				formData.append(property, formattedValue);
			}
		};
		// Validate each field and update errors
		const validateAndSetError = (field, value) => {
			if (!value) {
				newErrors[field] = `${field} is required`;
			} else {
				delete newErrors[field];
			}
		};

		// Perform field validations
		validateAndSetError("file_format", newImageEditType.file_format);

		validateAndSetError("margin_format", newImageEditType.margin_format);
		validateAndSetError("margin_top", newImageEditType.margin_top);
		validateAndSetError("margin_bottom", newImageEditType.margin_bottom);
		validateAndSetError("margin_right", newImageEditType.margin_right);
		validateAndSetError("margin_left", newImageEditType.margin_left);
		setErrors((prevErrors) => ({
			...prevErrors,
			file_format: newImageEditType.file_format,
			margin_format: newImageEditType.margin_format,
			margin_top: newImageEditType.margin_top,
			margin_bottom: newImageEditType.margin_bottom,
			margin_right: newImageEditType.margin_right,
			margin_left: newImageEditType.margin_left,
		}));
		if (!error) {
			setData((prevData) => ({
				...prevData,
				file_format: fileFormatSet,
				margin_format: marginFormatSet,
				margin_top: marginTopSet,
				margin_bottom: marginBottomSet,
				margin_right: marginRightSet,
				margin_left: marginLeftSet,
			}));
		}
		// Continue with API request
		bodyFormData.append("image_ids", setting_profile.id);
		bodyFormData.append("customer_id", customer_id);
		bodyFormData.append("batch_number", batch_id_query);
		bodyFormData.append("image_name", imageNamePath);
		bodyFormData.append("edit_types", edit_types);
		bodyFormData.append("file_format", file_format);
		bodyFormData.append("margin_format", margin_format);
		bodyFormData.append("margin_right", margin_right);
		bodyFormData.append("margin_left", margin_left);
		bodyFormData.append("margin_top", margin_top);
		bodyFormData.append("margin_bottom", margin_bottom);
		bodyFormData.append("resizing_height", resizing_height);
		bodyFormData.append("resizing_width", resizing_width);
		bodyFormData.append("same_as_original", same_as_original);
		bodyFormData.append("same_as_original_resizing", same_as_original_resizing);
		bodyFormData.append("background_removal_color_type", bg_removal_type);
		bodyFormData.append("background_removal_color_code", bg_removal_color);
		bodyFormData.append("background_adjustment_color_type", bg_adjustment_type);
		bodyFormData.append(
			"background_adjustment_color_code",
			bg_adjustment_color
		);

		// Format and append values to bodyFormData
		formatAndAppendValue(
			bodyFormData,
			"file_format",
			newImageEditType.file_format
		);
		formatAndAppendValue(bodyFormData, "height", newImageEditType.height);
		formatAndAppendValue(bodyFormData, "width", newImageEditType.width);
		formatAndAppendValue(bodyFormData, "dpi", newImageEditType.dpi);
		formatAndAppendValue(
			bodyFormData,
			"dpi_type",
			newImageEditType.dpi_type_Value
		);
		formatAndAppendValue(
			bodyFormData,
			"color_mode",
			newImageEditType.color_mode_Value
		);
		formatAndAppendValue(
			bodyFormData,
			"alignment",
			newImageEditType.alignment_Value
		);
		formatAndAppendValue(
			bodyFormData,
			"margin_format",
			newImageEditType.margin_format
		);
		formatAndAppendValue(
			bodyFormData,
			"margin_top",
			newImageEditType.margin_top
		);
		formatAndAppendValue(
			bodyFormData,
			"margin_bottom",
			newImageEditType.margin_bottom
		);
		formatAndAppendValue(
			bodyFormData,
			"margin_right",
			newImageEditType.margin_right
		);
		formatAndAppendValue(
			bodyFormData,
			"margin_left",
			newImageEditType.margin_left
		);

		// Send an API request
		request
			.post(apiURL + CO_ORDINATOR_DUPLICATE_IMAGES, bodyFormData)
			.then((response) => {
				// Handle success
				ToastAlert({
					title: response.data.status === true ? "Success" : "Error",
					message:
						response.data.status === true
							? "Succesfully saved"
							: response.data.message,
					autoClose: 2000,
					messageType: response.data.status === true ? "success" : "error",
				});
				fetchOrderDuplicateSettings();
				onHide();
				handleFetchProfileSettings(props.imageID);
			})
			.catch((err) => {
				// Handle errors
				let message =
					typeof err.response !== "undefined"
						? err.response.data.message
						: err.message;
				setError(message);
			});
	};
	useEffect(() => {
		try {
			const image_name =
				copyAmounts !== undefined ? copyAmounts.join(", ") : "";

			setImageNamePath(image_name);
		} catch (error) {
			console.error("Error in useEffect:", error);
		}
	}, [copyAmounts]);

	/**
	 * Handles to set the state.
	 * @param {Boolean} checked - The checked/unchecked after action of checkbox clicked.
	 */
	const toggleFieldEditing = (checked = false) => {
		customizedSettingsEnabled(checked);
		if (checked) {
			is_profile_selected = false;
			if (edit_select > 0) {
				setPreviousSelectedValue(edit_select);
			}
			if (editGroups) {
				const updatedGroup = [...editGroups];
				setGroup(updatedGroup);
				setEditGroupNameSelect("Customized Settings");
				setEditorType("Customized Settings");
			}
		} else {
			is_profile_selected = true;
			setEditGroupNameSelect("");
			setEditorType(previousSelectedValue);
			setImageFileFormat("");
			setImageHeight("");
			setImageWidth("");
			setImageDpi("");
			setImageDpiType("");
			setImageColorMode("");
			setImageAlignment("");
			setImageMarginFormat("");
			setImageMarginTop("");
			setImageMarginBottom("");
			setImageMarginRight("");
			setImageMarginLeft("");
		}
	};

	/**
	 * Handles to return the checkbox field editbale state is set or not.
	 */
	const checkisEditableField = () => {
		if (customizedSettings) {
			return is_profile_selected;
		} else {
			return !customizedSettings || !is_profile_selected;
		}
	};

	/**
	 * Handles profile change event
	 * @param {Event} e - The changed event object.
	 */
	const handleProfileChange = (e) => {
		setEditGroupNameSelect(e.label);
		setEditorType(e.value);
		setGroupSelect((val) => !val);
		is_profile_selected = e.value > 0 ? true : false;
		if (
			(group[0] &&
				group[0].group_name === "Customized Settings" &&
				e.label === "New Settings") ||
			e.value > 0
		) {
			customizedSettingsEnabled(false);
		}
	};

	/**
	 * Handles checkbox change event
	 * @param {Event} e - The changed event object.
	 * @param {Number} item - The edit type id.
	 * @param {Number} index - The tab index.
	 * Return the newly set edit type state based on tab
	 */
	const handleCopyCheckboxCheck = (e, item, index) => {
		let editTypeArray = [...copyTabEditTypeSelected];
		if (!e.target.checked) {
			let copyByIndex = editTypeArray[index];
			copyByIndex = copyByIndex.filter((i) => {
				return i !== item;
			});
			editTypeArray[index] = copyByIndex;
			setCopyTabEditTypes(editTypeArray);
			handleKeepOriginalImageResizing(e, item, index);
		} else {
			if (editTypeArray && editTypeArray[index]) {
				editTypeTempArray = editTypeArray[index];
			} else {
				editTypeTempArray = editTypeArray[index] = [];
			}
			editTypeTempArray.push(item);
			editTypeArray[index] = editTypeTempArray;
			setCopyTabEditTypes(editTypeArray);
		}
		validateCopyForm();
	};

	/**
	 * Handles checkbox change event
	 * @param {Event} e - The changed event object.
	 * @param {Number} item - The edit type id.
	 * @param {Number} index - The tab index.
	 * Return the newly set disabled resizing based on tab.
	 */
	const handleKeepOriginalImageResizing = (e, item, index) => {
		let tempdisableResizing = [...disableResizing];
		let tempcheckedResizing = [...isCheckedResizing];
		let fieldArrayTemp = [...fieldArray];
		let fieldErrorTemp = [...fieldError];
		let tempFieldArray = { ...fieldArrayTemp[index] };
		let tempFieldError = { ...fieldErrorTemp[index] };
		if (e.target.checked) {
			tempFieldArray["height"] = resizing_height;
			tempFieldError["height"] = "";
			tempFieldArray["width"] = resizing_width;
			tempFieldError["width"] = "";
			setDisableResizing((oldArray) => [...oldArray, index]);
			setIsCheckedResizing((oldArray) => {
				const newArray = [...oldArray];
				newArray[item] = "1";
				return newArray ? newArray : "";
			});
		} else {
			setDisableResizing(tempdisableResizing.filter((i) => i !== index));
			setIsCheckedResizing((oldArray) => {
				const newArray = [...oldArray];
				newArray[item] = "0";
				return newArray ? newArray : "";
			});
			tempFieldArray["height"] = "";
			tempFieldError["height"] = fieldValidationRules["height"];
			tempFieldArray["width"] = "";
			tempFieldError["width"] = fieldValidationRules["width"];
		}
		fieldArrayTemp[index] = tempFieldArray;
		fieldErrorTemp[index] = tempFieldError;
		setFieldArray(fieldArrayTemp);
		setFieldError(fieldErrorTemp);
	};

	/**
	 * Handles checkbox change event
	 * @param {Event} e - The changed event object.
	 * @param {Number} index - The tab index.
	 * Return the newly set disabled resizing based on tab.
	 */
	const handleKeepOriginalImageOutputSpec = (e, index) => {
		let tempdisableOutputSpecifications = [...disableOutputSpecifications];
		let tempisCheckedOutputSpecifications = [...isCheckedOutputSpecifications];
		let fieldArrayTemp = [...fieldArray];
		let fieldErrorTemp = [...fieldError];
		let tempFieldArray = { ...fieldArrayTemp[index] };
		let tempFieldError = { ...fieldErrorTemp[index] };
		if (e.target.checked) {
			setDisableOutputSpecifications((oldArray) => [...oldArray, index]);
			setIsCheckedOutputSpecification((oldArray) => {
				const newArray = [...oldArray];
				newArray[index] = "1";
				return newArray;
			});
			tempFieldArray["file_format"] = image_file_format;
			tempFieldArray["margin_format"] = image_margin_format;
			tempFieldArray["margin_top"] = image_margin_top;
			tempFieldArray["margin_right"] = image_margin_right;
			tempFieldArray["margin_bottom"] = image_margin_bottom;
			tempFieldArray["margin_left"] = image_margin_left;
		} else {
			setDisableOutputSpecifications(
				tempdisableOutputSpecifications.filter((i) => i !== index)
			);
			setIsCheckedOutputSpecification((oldArray) => {
				const newArray = [...oldArray];
				newArray[index] = "0";
				return newArray;
			});
			tempFieldArray["file_format"] = "";
			tempFieldArray["margin_format"] = "";
			tempFieldArray["margin_top"] = "";
			tempFieldArray["margin_right"] = "";
			tempFieldArray["margin_bottom"] = "";
			tempFieldArray["margin_left"] = "";
		}
		fieldArrayTemp[index] = tempFieldArray;
		fieldErrorTemp[index] = tempFieldError;
		setFieldArray(fieldArrayTemp);
		setFieldError(fieldErrorTemp);
	};

	/**
	 * Handles check edit type selected or not
	 * @param {Number} item - The edit type id.
	 * @param {Number} index - The tab index.
	 */
	const checkEdityTypeSelected = (item, index) => {
		if (
			copyTabEditTypeSelected &&
			copyTabEditTypeSelected[index] &&
			copyTabEditTypeSelected[index].length > 0
		) {
			return copyTabEditTypeSelected[index].includes(item);
		}
		return false;
	};

	/**
	 * Handles check change event
	 * @param {Number} item - The edit type id.
	 * @param {Number} index - The tab index.
	 */
	const checkIsDisabledResizing = (index) => {
		if (disableResizing && disableResizing && disableResizing.length > 0) {
			return disableResizing.includes(index);
		}
		return;
	};

	/**
	 * Handles check change event
	 * @param {Number} item - The edit type id.
	 * @param {Number} index - The tab index.
	 */
	const checkDisableOutputSpecifications = (index) => {
		if (
			disableOutputSpecifications &&
			disableOutputSpecifications &&
			disableOutputSpecifications.length > 0
		) {
			return disableOutputSpecifications.includes(index);
		}
		return;
	};

	const checkFieldError = () => {
		let isError = false;
		Object.keys(fieldError[key - 1]).some((field) => {
			if (fieldError[key - 1][field] && !isError) {
				isError = true;
			}
		});
		return isError;
	};

	useEffect(() => {
		let errorTabName = [];
		fieldError.length > 0 &&
			fieldError.some((obj, index) => {
				Object.keys(obj).some((field) => {
					if (!checkDisableOutputSpecifications(index)) {
						if (
							fieldArray[index]["margin_format"] !== "" &&
							fieldArray[index]["margin_format"] !== "" &&
							(field === "margin_top" ||
								field === "margin_bottom" ||
								field === "margin_left" ||
								field === "margin_right")
						) {
							if (isNaN(parseInt(fieldArray[index][field]))) {
								errorTabName.push(copyAmounts[index]);
							}
						} else {
							if (
								(checkEdityTypeSelected(3, index) ||
									checkEdityTypeSelected("3", index)) &&
								(fieldArray[index]["height"] === "" ||
									fieldArray[index]["width"] === "") &&
								(field === "height" || field === "width")
							) {
								errorTabName.push(copyAmounts[index]);
							} else {
								if (obj[field] !== "" && fieldArray[index][field] === "") {
									errorTabName.push(copyAmounts[index]);
								}
							}
						}
					} else {
						if (
							(checkEdityTypeSelected(3, index) ||
								checkEdityTypeSelected("3", index)) &&
							(fieldArray[index]["height"] === "" ||
								fieldArray[index]["width"] === "") &&
							(field === "height" || field === "width")
						) {
							errorTabName.push(copyAmounts[index]);
						} else {
							if (obj[field] !== "" && fieldArray[index][field] === "") {
								errorTabName.push(copyAmounts[index]);
							}
						}
					}
				});
			});
		errorTabName = errorTabName.filter(
			(value, idex, array) => array.indexOf(value) === idex
		);
		setErrorTabs(errorTabName.join(","));
	}, [fieldArray, fieldError]);

	const validateCopyForm = () => {
		let isError = false;
		let formFields = [...fieldArray];
		let errorFields = [...fieldError];
		let errorTabName = [];
		errorFields.some((obj, index) => {
			Object.keys(obj).some((field) => {
				if (!checkDisableOutputSpecifications(index)) {
					if (
						formFields[index]["margin_format"] !== "" &&
						formFields[index]["margin_format"] !== "" &&
						(field === "margin_top" ||
							field === "margin_bottom" ||
							field === "margin_left" ||
							field === "margin_right")
					) {
						if (isNaN(parseInt(formFields[index][field]))) {
							isError = true;
							errorTabName.push(copyAmounts[index]);
						}
					} else {
						if (
							(checkEdityTypeSelected(3, index) ||
								checkEdityTypeSelected("3", index)) &&
							(formFields[index]["height"] === "" ||
								formFields[index]["width"] === "") &&
							(field === "height" || field === "width")
						) {
							isError = true;
							errorTabName.push(copyAmounts[index]);
						} else {
							if (obj[field] !== "" && formFields[index][field] === "") {
								isError = true;
								errorTabName.push(copyAmounts[index]);
							}
						}
					}
				} else {
					if (
						(checkEdityTypeSelected(3, index) ||
							checkEdityTypeSelected("3", index)) &&
						(formFields[index]["height"] === "" ||
							formFields[index]["width"] === "") &&
						(field === "height" || field === "width")
					) {
						isError = true;
						errorTabName.push(copyAmounts[index]);
					} else {
						if (obj[field] !== "" && formFields[index][field] === "") {
							isError = true;
							errorTabName.push(copyAmounts[index]);
						}
						fetchOrderDuplicateSettings();
					}
				}
			});
		});
		errorTabName = errorTabName.filter(
			(value, idex, array) => array.indexOf(value) === idex
		);
		setErrorTabs(errorTabName.join(","));
		return isError;
	};

	const getAdditionalEdityTypes = () => {
		let errorFields = [...fieldError];
		let fieldArrayTemp = [...fieldArray];
		let additionalEdityTypes = "";
		errorFields.some((obj, index) => {
			additionalEdityTypes += `${getAdditionalEditTypeValues(
				`copy_et_options_${index}`,
				index
			)},`;
		});
		return additionalEdityTypes ? additionalEdityTypes : "";
	};

	/**
	 * Handles check change event
	 * @param {String} value - The field object.
	 * @param {String} field - The actual field name.
	 * @param {Number} tabIndex - The selected tab index.
	 */
	const updateError = (value, field, tabIndex) => {
		let checkError = fieldError[tabIndex];
		if (value) {
			checkError[field] = "";
		} else {
			checkError[field] = fieldValidationRules[field];
		}
	};

	/**
	 * Handles check change event
	 * @param {String} value - The field object.
	 * @param {String} field - The actual field name.
	 * @param {Number} tabIndex - The selected tab index.
	 * Return {} the new state of error and success
	 */

	const updateFieldState = (value, field, tabIndex) => {
		let fieldArrayTemp = [...fieldArray];
		let fieldErrorTemp = [...fieldError];
		let tempFieldArray = { ...fieldArrayTemp[tabIndex] };
		let tempFieldError = { ...fieldErrorTemp[tabIndex] };
		if (value) {
			tempFieldArray[field] = value;
			tempFieldError[field] = "";
		} else {
			tempFieldArray[field] = "";
			tempFieldError[field] = fieldValidationRules[field];
		}
		fieldArrayTemp[tabIndex] = tempFieldArray;
		fieldErrorTemp[tabIndex] = tempFieldError;
		setFieldArray(fieldArrayTemp);
		setFieldError(fieldErrorTemp);
		validateCopyForm();
	};
	const getSettings = () => {
		// let errorFields = [...fieldError];
		let fieldArrayTemp = fieldArray ? [...fieldArray] : [];

		let file_format = "";
		let margin_format = "";
		let margin_top = "";
		let margin_bottom = "";
		let margin_right = "";
		let margin_left = "";
		let same_as_original = "";
		let height = "";
		let width = "";
		let bg_removal_color = "";
		let bg_removal_type = "";
		let bg_adjustment_color = "";
		let bg_adjustment_type = "";

		fieldArrayTemp.forEach((obj) => {
			file_format += `${obj.file_format},`;
			margin_format += `${obj.margin_format},`;
			margin_top += `${obj.margin_top},`;
			margin_bottom += `${obj.margin_bottom},`;
			margin_right += `${obj.margin_right},`;
			margin_left += `${obj.margin_left},`;
			same_as_original += `${obj.same_as_original},`;
			height += `${obj.height},`;
			width += `${obj.width},`;
			bg_removal_color += `${obj.bg_removal_color},`;
			bg_removal_type += `${obj.bg_removal_type},`;
			bg_adjustment_color += `${obj.bg_adjustment_color},`;
			bg_adjustment_type += `${obj.bg_adjustment_type},`;
		});

		// Remove the trailing commas
		file_format = file_format;
		margin_format = margin_format;
		margin_top = margin_top;
		margin_bottom = margin_bottom;
		margin_right = margin_right;
		margin_left = margin_left;
		same_as_original = same_as_original;
		height = height;
		width = width;
		bg_removal_color = bg_removal_color;
		bg_removal_type = bg_removal_type;
		bg_adjustment_color = bg_adjustment_color;
		bg_adjustment_type = bg_adjustment_type;

		return {
			file_format,
			margin_format,
			margin_top,
			margin_bottom,
			margin_right,
			margin_left,
			same_as_original,
			height,
			width,
			bg_removal_color,
			bg_removal_type,
			bg_adjustment_color,
			bg_adjustment_type,
		};
	};
	const isCheckedOutputSpecificationstoString = useCallback(() => {
		let tempFieldArray = fieldArray ? [...fieldArray] : [];
		let isCheckedArray = [];

		// Fill isCheckedArray based on fieldArray length, replacing undefined with 0
		for (let i = 0; i < tempFieldArray.length; i++) {
			if (
				i < isCheckedOutputSpecifications.length &&
				isCheckedOutputSpecifications[i] !== undefined
			) {
				isCheckedArray.push(isCheckedOutputSpecifications[i]);
			} else {
				isCheckedArray.push(0);
			}
		}

		// Construct the isChecked string
		let isChecked = isCheckedArray.join(",");

		return isChecked + `,`;
	}, [fieldArray, isCheckedOutputSpecifications]);

	const isCheckedResizingtoString = useCallback(() => {
		let tempFieldArray = fieldArray ? [...fieldArray] : [];
		let isChecked = "";

		// Filter out undefined values from isCheckedResizing
		let filteredIsCheckedResizing = isCheckedResizing.filter(
			(val) => val !== undefined
		);

		// Insert 0 dependent on the length of fieldArray
		while (filteredIsCheckedResizing.length < tempFieldArray.length) {
			filteredIsCheckedResizing.push(0);
		}

		if (!filteredIsCheckedResizing.length) {
			tempFieldArray.some((obj) => {
				isChecked += `${obj.isCheckedResizing},`;
			});
		} else {
			filteredIsCheckedResizing.some((val) => {
				isChecked += `${val},`;
			});
		}

		return isChecked; // Remove the trailing comma
	}, [fieldArray, isCheckedResizing]);

	console.log("isCheckedOutputSpecifications", customer_id);

	return (
		<>
			<Modal
				{...props}
				show={show}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter box exclude-click"
				centered
			>
				<Modal.Header onHide={onHide} closeButton>
					<Modal.Title
						id="contained-modal-title-vcenter"
						className="section-title fonts"
					>
						{title}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body
					className="section-title fonts duplicate-setting--Modal coordinator"
					style={{ paddingBottom: "0px" }}
				>
					<div className="form-group d-flex">
						<div className="detailstab">
							<Tabs
								defaultActiveKey={0} // Set "Original" as the default tab
								id="justify-tab-example"
								className="stafftab tabs-wrap--no-wrap "
								justify
								activeKey={key}
								onSelect={(k) => setKey(k)}
							>
								<Tab
									eventKey={0}
									title={
										<OverlayTrigger
											placement="right"
											delay={{ show: 250, hide: 400 }}
											overlay={<Tooltip id={"Original"}>Original</Tooltip>}
										>
											<span>
												{Number(key) !== 0
													? "Original".substring(0, 5) + "..."
													: "Original"}
											</span>
										</OverlayTrigger>
									}
								>
									<div className="duplicate-setting-image--content d-flex">
										{/* Keep below class name commented might be we require same in future */}
										<div
											className="width-35rem"
											style={{ marginRight: "unset" }}
										>
											<div className="form-group edit-profile-setting-container--single">
												<Row className="align-items-center edit-setting-select--duplicate duplicate-settings-profile--select ml-auto">
													<Form.Label className="col-sm-5">
														Settings Profile
													</Form.Label>
													<Col sm={7}>
														<Select
															options={selectLabel(group)}
															onChange={(e) => {
																handleProfileChange(e);
															}}
															placeholder="Select Profile"
															value={selectLabel(group).find((option) => {
																if (
																	edit_select !== "" &&
																	edit_select !== null
																) {
																	return option.value === edit_select;
																} else {
																	return null;
																}
															})} // Set the selected value by ID
															menuPortalTarget={document.body}
															styles={customSelectStyles}
															defaultValue={{
																label: `Select`,
																id: "Select",
															}}
															isDisabled={true}
														/>
													</Col>
												</Row>
											</div>
											<div>
												{group_name_select === "New Settings" &&
												!customizedSettings ? (
													<div
														className="width-35rem  scroll-y--axis"
														style={{ height: "26.1rem" }}
													>
														{/* Form groups for Edit Type*/}
														<div className="sub-head mt-2" id="et_head">
															Edit Type
														</div>

														{editTypes !== undefined &&
															editTypes.map((item, i) => {
																return (
																	<Form.Group>
																		{" "}
																		<Row
																			className="form-group flex-grow-1 padding-left-3"
																			style={{
																				paddingTop:
																					(i === 0 ? "10" : "1.4") + "px",
																			}}
																			sm={9}
																		>
																			<Col
																				sm={1}
																				style={{
																					paddingTop:
																						(i === 0 ? "2" : "0.8") + "px",
																				}}
																			>
																				<input
																					type="checkbox"
																					onChange={(e) => {
																						handleBackGroundRemovalCheckbox(
																							item.id
																						);
																						handleAddCheckboxSelect(e);
																					}}
																					value={item.id}
																					id={`et_${item.id}`}
																					className="et_options"
																				/>
																			</Col>
																			<Form.Label
																				className="col-sm-4 form-label form-check-label"
																				style={{
																					width: "auto",
																					cursor: "pointer",
																				}}
																				for={`et_${item.id}`}
																			>
																				{item.edit_type}
																			</Form.Label>
																		</Row>
																		{item.id === 1 && selected && (
																			<Row
																				className="align-items-center"
																				style={{
																					marginLeft: "auto",
																					marginTop: "0",
																					marginBottom: "20px",
																				}}
																			>
																				<Col sm={5} className="ml_4_percent">
																					<Select
																						options={selectBackgrondRemoval}
																						onChange={(e) =>
																							setBackgroundRemovalColorType(
																								e.label
																							)
																						}
																					/>
																				</Col>
																				<Col sm={3}>
																					<Form.Control
																						type="text"
																						placeholder="#ffff"
																						autoFocus
																						onChange={(e) =>
																							setBackgroundRemovalColorCode(
																								e.target.value
																							)
																						}
																						autoComplete="off"
																						className="placeholder-light"
																					/>
																				</Col>
																			</Row>
																		)}
																		{item.id === 2 && selected1 && (
																			<Row
																				className="align-items-center"
																				style={{
																					marginLeft: "auto",
																					marginTop: "0",
																					marginBottom: "20px",
																				}}
																			>
																				<Col sm={5} className="ml_4_percent">
																					<Select
																						options={selectBackgrondRemoval}
																						onChange={(e) =>
																							setBackgroundAdjustmentColorType(
																								e.label
																							)
																						}
																					/>
																				</Col>
																				<Col sm={3}>
																					<Form.Control
																						type="text"
																						placeholder="#ffff"
																						autoFocus
																						onChange={(e) =>
																							setBackgroundAdjustmentColorCode(
																								e.target.value
																							)
																						}
																						autoComplete="off"
																						className="placeholder-light"
																					/>
																				</Col>
																			</Row>
																		)}
																		{item.id === 3 && selected2 && (
																			<Row
																				className="align-items-center"
																				style={{
																					marginLeft: "auto",
																					marginRight: "auto",
																					marginTop: "0",
																					marginBottom: "20px",
																					width: " 94%",
																				}}
																			>
																				<Form.Group style={{ margin: "2px" }}>
																					<div className="form-group">
																						<Row className="align-items-center">
																							<Col sm={12} className="ml-1">
																								<div className="d-sm-flex align-items-center col-gap-10">
																									<div className="d-sm-flex col-gap-10 align-items-center">
																										<Form.Control
																											type="text"
																											placeholder="Height"
																											autoFocus
																											onChange={(e) =>
																												setResizingHeight(
																													e.target.value
																												)
																											}
																											autoComplete="off"
																											className="placeholder-light"
																										/>
																									</div>
																									<div className="d-sm-flex align-items-center">
																										<img
																											style={{ width: "18px" }}
																											src={cross}
																											className="img-fluid"
																											alt="mindreach images"
																										/>
																									</div>
																									<div className="d-sm-flex align-items-center col-gap-10">
																										<Form.Control
																											type="text"
																											placeholder="Width"
																											autoFocus
																											onChange={(e) =>
																												setResizingWidth(
																													e.target.value
																												)
																											}
																											autoComplete="off"
																											className="placeholder-light"
																										/>
																									</div>
																								</div>
																							</Col>
																						</Row>
																					</div>
																				</Form.Group>
																			</Row>
																		)}
																	</Form.Group>
																);
															})}
														{/* Form groups for output specifications */}
														<div className="sub-head">
															Output Specifications
														</div>
														<Form.Group className="">
															<Row
																className="form-group  flex-grow-1 padding-left-3"
																sm={9}
															>
																<Form.Label className="col-sm-4 form-label form-check-label">
																	File Format
																</Form.Label>
															</Row>
														</Form.Group>

														<Form.Group style={{ margin: "2px" }}>
															<div className="form-group">
																<Row className="align-items-center padding-left-3">
																	<Col sm={6} className="pl-2">
																		<Select
																			options={file}
																			onChange={(e) =>
																				setImageFileFormat(e.label)
																			}
																		/>
																	</Col>
																</Row>
															</div>
														</Form.Group>
														<Form.Group className="">
															<Row
																className="form-group  flex-grow-1 padding-left-3"
																sm={9}
															>
																<Form.Label className="col-sm-4 form-label form-check-label">
																	Size
																</Form.Label>
															</Row>
														</Form.Group>

														<Form.Group style={{ margin: "2px" }}>
															<div className="form-group">
																<Row className="align-items-center padding-left-3">
																	<Col sm={10} style={{ marginLeft: "-3px" }}>
																		<div className="d-sm-flex align-items-center col-gap-10">
																			<div className="d-sm-flex col-gap-10 align-items-center">
																				<Form.Control
																					type="text"
																					placeholder="Height"
																					autoFocus
																					onChange={(e) =>
																						setImageHeight(e.target.value)
																					}
																					autoComplete="off"
																					className="placeholder-light"
																				/>
																			</div>
																			<div className="d-sm-flex align-items-center">
																				<img
																					style={{ width: "18px" }}
																					src={cross}
																					alt="mindreach images"
																				/>
																			</div>
																			<div className="d-sm-flex align-items-center col-gap-10">
																				<Form.Control
																					type="text"
																					placeholder="Width"
																					autoFocus
																					onChange={(e) =>
																						setImageWidth(e.target.value)
																					}
																					autoComplete="off"
																					className="placeholder-light"
																				/>
																			</div>
																		</div>
																	</Col>
																</Row>
															</div>
														</Form.Group>
														<Form.Group className="">
															<Row
																className="form-group flex-grow-1 padding-left-4"
																sm={9}
															>
																<Form.Label className="col-sm-4 form-label form-check-label">
																	DPI
																</Form.Label>
															</Row>
														</Form.Group>
														<Form.Group style={{ margin: "2px" }}>
															<div className="form-group">
																<Row className="align-items-center padding-left-3">
																	<Col sm={10}>
																		<div className="d-sm-flex col-gap-20">
																			<Form.Control
																				type="text"
																				placeholder="2000"
																				autoFocus
																				onChange={(e) =>
																					setImageDpi(e.target.value)
																				}
																				autoComplete="off"
																				className="w-50 placeholder-light"
																			/>

																			<div className="flex-fill">
																				<Select
																					options={dpi}
																					onChange={(e) =>
																						setImageDpiType(e.label)
																					}
																				/>
																			</div>
																		</div>
																	</Col>
																</Row>
															</div>
														</Form.Group>

														<Form.Group className="">
															<Row
																className="form-group flex-grow-1 padding-left-4"
																sm={9}
															>
																<Form.Label className="col-sm-4 form-label form-check-label">
																	Color Mode
																</Form.Label>
															</Row>
														</Form.Group>

														<Form.Group style={{ margin: "2px" }}>
															<div className="form-group padding-left-3">
																<Row className="align-items-center">
																	<Col sm={9}>
																		<Select
																			options={ColorMode}
																			onChange={(e) =>
																				setImageColorMode(e.label)
																			}
																		/>
																	</Col>
																</Row>
															</div>
														</Form.Group>

														<Form.Group className="">
															<Row
																className="form-group  flex-grow-1 padding-left-4"
																sm={9}
															>
																<Form.Label className="col-sm-4 form-label form-check-label">
																	Align
																</Form.Label>
															</Row>
														</Form.Group>

														<Form.Group style={{ margin: "2px" }}>
															<div className="form-group">
																<Row className="align-items-center  padding-left-4">
																	<Col sm={9}>
																		<Select
																			options={Align}
																			onChange={(e) =>
																				setImageAlignment(e.label)
																			}
																		/>
																	</Col>
																</Row>
															</div>
														</Form.Group>
														<Form.Group className="">
															<Row
																className="form-group  flex-grow-1 padding-left-3"
																sm={9}
															>
																<Form.Label className="col-sm-4 form-label form-check-label">
																	Margins
																</Form.Label>
															</Row>
														</Form.Group>
														<Form.Group style={{ margin: "2px" }}>
															<div className="form-group">
																<Row className="align-items-center padding-left-3">
																	<Col sm={9}>
																		<Select
																			options={Margin}
																			onChange={(e) =>
																				setResizingMarginFormat(e.label)
																			}
																		/>
																	</Col>
																	<Col
																		sm={7}
																		lg={7}
																		style={{
																			display: "flex",
																			marginTop: "12px",
																		}}
																	>
																		<div className="d-sm-flex col-gap-20 mt-2 ">
																			<div className="d-flex">
																				<ul className="margin-list d-flex">
																					<li>
																						<Form.Control
																							type="text"
																							placeholder="T"
																							autoFocus
																							onChange={(e) =>
																								setImageMarginTop(
																									e.target.value
																								)
																							}
																							autoComplete="off"
																							className="placeholder-light"
																						/>
																					</li>
																					<li>
																						<Form.Control
																							type="text"
																							placeholder="B"
																							autoFocus
																							onChange={(e) =>
																								setImageMarginBottom(
																									e.target.value
																								)
																							}
																							autoComplete="off"
																							className="placeholder-light"
																						/>
																					</li>
																					<li>
																						<Form.Control
																							type="text"
																							placeholder="L"
																							autoFocus
																							onChange={(e) =>
																								setImageMarginLeft(
																									e.target.value
																								)
																							}
																							autoComplete="off"
																							className="placeholder-light"
																						/>
																					</li>
																					<li>
																						<Form.Control
																							type="text"
																							placeholder="R"
																							autoFocus
																							onChange={(e) =>
																								setImageMarginRight(
																									e.target.value
																								)
																							}
																							autoComplete="off"
																							className="placeholder-light"
																						/>
																					</li>
																				</ul>
																			</div>
																		</div>
																	</Col>

																	<Col sm={6}></Col>
																</Row>
															</div>
														</Form.Group>
													</div>
												) : (
													<div>
														{group_name_select === "New Settings" &&
														!customizedSettings ? (
															<div className="sidebar-select--editType ">
																{/* Form groups for Edit Type*/}
																<div className="sub-head" id="et_head">
																	Edit Type
																</div>

																{editTypes !== undefined &&
																	editTypes.map((item, i) => {
																		return (
																			<Form.Group className="" key={item.id}>
																				{" "}
																				<Row
																					className="form-group  flex-grow-1"
																					sm={9}
																				>
																					<Col sm={1}>
																						<input
																							type="checkbox"
																							onChange={(e) => {
																								handleBackGroundRemovalCheckbox(
																									item.id,
																									e.target.checked
																								);
																								handleAddCheckboxSelect(e);
																							}}
																							value={item.id}
																							id={`et_${item.id}`}
																							className="et_options"
																						/>
																					</Col>
																					<Form.Label
																						className="col-sm-4 form-label form-check-label"
																						style={{
																							width: "auto",
																							marginTop: "1.4px",
																							cursor: "pointer",
																						}}
																						for={`et_${item.id}`}
																					>
																						{item.edit_type}
																					</Form.Label>
																				</Row>
																				{item.id === 1 && selected === true ? (
																					<Row
																						className="align-items-center"
																						style={{
																							marginLeft: "auto",
																							marginTop: "0",
																							marginBottom: "20px",
																						}}
																					>
																						<Col
																							sm={5}
																							className="ml_4_percent"
																						>
																							<Select
																								options={selectBackgrondRemoval}
																								onChange={(e) =>
																									setBackgroundRemovalColorType(
																										e.label
																									)
																								}
																							/>
																						</Col>
																						<Col sm={3}>
																							<Form.Control
																								type="text"
																								placeholder="#ffff"
																								autoFocus
																								onChange={(e) =>
																									setBackgroundRemovalColorCode(
																										e.target.value
																									)
																								}
																								autoComplete="off"
																								className="placeholder-light"
																							/>
																						</Col>
																					</Row>
																				) : (
																					""
																				)}
																				{item.id === 2 ? (
																					selected1 ? (
																						<Row
																							className="align-items-center"
																							style={{
																								marginLeft: "auto",
																								marginTop: "0",
																								marginBottom: "20px",
																							}}
																						>
																							<Col
																								sm={5}
																								className="ml_4_percent"
																							>
																								<Select
																									options={
																										selectBackgrondRemoval
																									}
																									onChange={(e) =>
																										setBackgroundAdjustmentColorType(
																											e.label
																										)
																									}
																								/>
																							</Col>
																							<Col sm={3}>
																								<Form.Control
																									type="text"
																									placeholder="#ffff"
																									autoFocus
																									onChange={(e) =>
																										setBackgroundAdjustmentColorCode(
																											e.target.value
																										)
																									}
																									value={
																										background_adjustment_color_code
																									}
																									autoComplete="off"
																									className="placeholder-light"
																								/>
																							</Col>
																						</Row>
																					) : (
																						""
																					)
																				) : (
																					""
																				)}
																				{item.id === 3 ? (
																					selected2 ? (
																						<Row
																							className="align-items-center"
																							style={{
																								marginLeft: "auto",
																								marginRight: "auto",
																								marginTop: "0",
																								marginBottom: "20px",
																								width: " 94%",
																							}}
																						>
																							<Form.Group className="mg-bottom16">
																								<Row
																									className="form-group  flex-grow-1"
																									sm={9}
																								>
																									<Form.Label className="col-sm-4 form-label form-check-label ml-1">
																										File Format
																									</Form.Label>
																								</Row>
																							</Form.Group>
																							<Form.Group
																								style={{ margin: "2px" }}
																							>
																								<div className="form-group">
																									<Row className="align-items-center">
																										<Col
																											sm={6}
																											className="ml-1"
																										>
																											<Select
																												options={file}
																												onChange={(e) =>
																													setResizingFileFormat(
																														e.label
																													)
																												}
																											/>
																										</Col>
																									</Row>
																								</div>
																							</Form.Group>
																							<Form.Group
																								style={{ margin: "2px" }}
																							>
																								<div className="form-group">
																									<Row className="align-items-center">
																										<Col
																											sm={12}
																											className="ml-1"
																										>
																											<div className="d-sm-flex align-items-center col-gap-10">
																												<div className="d-sm-flex col-gap-10 align-items-center">
																													<Form.Control
																														type="text"
																														placeholder="Height"
																														autoFocus
																														onChange={(e) =>
																															setResizingHeight(
																																e.target.value
																															)
																														}
																														autoComplete="off"
																														className="placeholder-light"
																													/>
																												</div>
																												<div className="d-sm-flex align-items-center"></div>
																												<div className="d-sm-flex align-items-center col-gap-10">
																													<Form.Control
																														type="text"
																														placeholder="Width"
																														autoFocus
																														onChange={(e) =>
																															setResizingWidth(
																																e.target.value
																															)
																														}
																														autoComplete="off"
																														className="placeholder-light"
																													/>
																												</div>
																											</div>
																										</Col>
																									</Row>
																								</div>
																							</Form.Group>

																							<Form.Group className="mg-bottom16">
																								<Row
																									className="form-group  flex-grow-1"
																									sm={9}
																								>
																									<Form.Label className="col-sm-4 form-label form-check-label ml-1">
																										DPI
																									</Form.Label>
																								</Row>
																							</Form.Group>
																							<Form.Group
																								style={{ margin: "2px" }}
																							>
																								<div className="form-group">
																									<Row className="align-items-center">
																										<Col
																											sm={12}
																											className="ml-1"
																										>
																											<div className="d-sm-flex col-gap-20">
																												<div className="w-75">
																													<Form.Control
																														type="text"
																														placeholder="2000"
																														autoFocus
																														onChange={(e) =>
																															setResizingDpi(
																																e.target.value
																															)
																														}
																														autoComplete="off"
																														className="placeholder-light"
																													/>
																												</div>
																												<div className="flex-fill w-75">
																													<Select
																														options={dpi}
																														onChange={(e) =>
																															setResizingDpiType(
																																e.label
																															)
																														}
																													/>
																												</div>
																											</div>
																										</Col>
																									</Row>
																								</div>
																							</Form.Group>
																							<Form.Group className="mg-bottom16">
																								<Row
																									className="form-group  flex-grow-1"
																									sm={9}
																								>
																									<Form.Label className="col-sm-4 form-label form-check-label ml-1">
																										Color Mode
																									</Form.Label>
																								</Row>
																							</Form.Group>
																							<Form.Group
																								style={{ margin: "2px" }}
																								controlId="exampleForm.ControlTextarea1"
																							>
																								<div className="form-group">
																									<Row className="align-items-center">
																										<Col
																											sm={9}
																											className="ml-1"
																										>
																											<Select
																												options={ColorMode}
																												onChange={(e) =>
																													setResizingColorMode(
																														e.label
																													)
																												}
																											/>
																										</Col>
																									</Row>
																								</div>
																							</Form.Group>
																							<Form.Group className="mg-bottom16">
																								<Row
																									className="form-group  flex-grow-1"
																									sm={9}
																								>
																									<Form.Label className="col-sm-4 form-label form-check-label ml-1">
																										Align
																									</Form.Label>
																								</Row>
																							</Form.Group>
																							<Form.Group
																								style={{ margin: "2px" }}
																							>
																								<div className="form-group">
																									<Row className="align-items-center">
																										<Col
																											sm={9}
																											className="ml-1"
																										>
																											<Select
																												options={Align}
																												onChange={(e) =>
																													setResizingAlignment(
																														e.label
																													)
																												}
																											/>
																										</Col>
																									</Row>
																								</div>
																							</Form.Group>
																							<Form.Group className="mg-bottom16">
																								<Row
																									className="form-group  flex-grow-1"
																									sm={9}
																								>
																									<Form.Label className="col-sm-4 form-label form-check-label ml-1">
																										Margins
																									</Form.Label>
																								</Row>
																							</Form.Group>
																							<Form.Group
																								style={{ margin: "2px" }}
																							>
																								<div className="form-group">
																									<Row className="align-items-center">
																										<Col
																											sm={9}
																											className="ml-1"
																										>
																											<Select
																												options={Margin}
																												onChange={(e) =>
																													setResizingMarginFormat(
																														e.label
																													)
																												}
																											/>
																										</Col>
																										<Col
																											sm={7}
																											lg={7}
																											style={{
																												display: "flex",
																												marginTop: "12px",
																												marginLeft: "4px",
																											}}
																										>
																											<div className="d-sm-flex col-gap-20 mt-2 ">
																												<div className="d-flex">
																													<ul className="margin-list d-flex">
																														<li>
																															<Form.Control
																																type="text"
																																placeholder="T"
																																autoFocus
																																onChange={(e) =>
																																	setResizingMarginTop(
																																		e.target
																																			.value
																																	)
																																}
																																autoComplete="off"
																																className="placeholder-light"
																															/>
																														</li>
																														<li>
																															<Form.Control
																																type="text"
																																placeholder="B"
																																autoFocus
																																onChange={(e) =>
																																	setResizingMarginBottom(
																																		e.target
																																			.value
																																	)
																																}
																																autoComplete="off"
																																className="placeholder-light"
																															/>
																														</li>
																														<li>
																															<Form.Control
																																type="text"
																																placeholder="L"
																																autoFocus
																																onChange={(e) =>
																																	setResizingMarginLeft(
																																		e.target
																																			.value
																																	)
																																}
																																autoComplete="off"
																																className="placeholder-light"
																															/>
																														</li>
																														<li>
																															<Form.Control
																																type="text"
																																placeholder="R"
																																autoFocus
																																onChange={(e) =>
																																	setResizingMarginRight(
																																		e.target
																																			.value
																																	)
																																}
																																autoComplete="off"
																																className="placeholder-light"
																															/>
																														</li>
																													</ul>
																												</div>
																											</div>
																										</Col>

																										<Col sm={6}></Col>
																									</Row>
																								</div>
																							</Form.Group>
																						</Row>
																					) : (
																						""
																					)
																				) : (
																					""
																				)}
																			</Form.Group>
																		);
																	})}
																{/* Form groups for output specifications */}
																<div className="sub-head">
																	Output Specifications
																</div>
																<Form.Group className="">
																	<Row
																		className="form-group  flex-grow-1"
																		sm={9}
																	>
																		<Col sm={1}></Col>
																		<Form.Label className="col-sm-4 form-label form-check-label">
																			File Format
																		</Form.Label>
																	</Row>
																</Form.Group>
																<Form.Group style={{ margin: "2px" }}>
																	<div className="form-group">
																		<Row className="align-items-center">
																			<Form.Label className="col-sm-1"></Form.Label>
																			<Col sm={6} className="pl-2">
																				<Select
																					options={file}
																					onChange={(e) =>
																						setImageFileFormat(e.label)
																					}
																				/>
																			</Col>
																		</Row>
																	</div>
																</Form.Group>
																<Form.Group className="">
																	<Row
																		className="form-group  flex-grow-1"
																		sm={9}
																	>
																		<Col sm={1}></Col>
																		<Form.Label className="col-sm-4 form-label form-check-label">
																			Size
																		</Form.Label>
																	</Row>
																</Form.Group>
																<Form.Group style={{ margin: "2px" }}>
																	<div className="form-group">
																		<Row className="align-items-center">
																			<Form.Label className="col-sm-1"></Form.Label>
																			<Col
																				sm={10}
																				style={{ marginLeft: "-3px" }}
																			>
																				<div className="d-sm-flex align-items-center col-gap-10">
																					<div className="d-sm-flex col-gap-10 align-items-center">
																						<Form.Control
																							type="text"
																							placeholder="Height"
																							autoFocus
																							onChange={(e) =>
																								setImageHeight(e.target.value)
																							}
																							autoComplete="off"
																							className="placeholder-light"
																						/>
																					</div>
																					<div className="d-sm-flex align-items-center"></div>
																					<div className="d-sm-flex align-items-center col-gap-10">
																						<Form.Control
																							type="text"
																							placeholder="Width"
																							autoFocus
																							onChange={(e) =>
																								setImageWidth(e.target.value)
																							}
																							autoComplete="off"
																							className="placeholder-light"
																						/>
																					</div>
																				</div>
																			</Col>
																		</Row>
																	</div>
																</Form.Group>
																<Form.Group className="">
																	<Row
																		className="form-group  flex-grow-1"
																		sm={9}
																	>
																		<Col sm={1}></Col>
																		<Form.Label className="col-sm-4 form-label form-check-label">
																			DPI
																		</Form.Label>
																	</Row>
																</Form.Group>
																<Form.Group style={{ margin: "2px" }}>
																	<div className="form-group">
																		<Row className="align-items-center">
																			<Form.Label className="col-sm-1"></Form.Label>
																			<Col sm={10}>
																				<div className="d-sm-flex col-gap-20">
																					<Form.Control
																						type="text"
																						placeholder="2000"
																						autoFocus
																						onChange={(e) =>
																							setImageDpi(e.target.value)
																						}
																						autoComplete="off"
																						className="w-50 placeholder-light"
																					/>

																					<div className="flex-fill">
																						<Select
																							options={dpi}
																							onChange={(e) =>
																								setImageDpiType(e.label)
																							}
																						/>
																					</div>
																				</div>
																			</Col>
																		</Row>
																	</div>
																</Form.Group>
																<Form.Group className="">
																	<Row
																		className="form-group  flex-grow-1"
																		sm={9}
																	>
																		<Col sm={1}></Col>
																		<Form.Label className="col-sm-4 form-label form-check-label">
																			Color Mode
																		</Form.Label>
																	</Row>
																</Form.Group>
																<Form.Group style={{ margin: "2px" }}>
																	<div className="form-group">
																		<Row className="align-items-center">
																			<Form.Label className="col-sm-1"></Form.Label>
																			<Col sm={9}>
																				<Select
																					options={ColorMode}
																					onChange={(e) =>
																						setImageColorMode(e.label)
																					}
																				/>
																			</Col>
																		</Row>
																	</div>
																</Form.Group>
																<Form.Group className="">
																	<Row
																		className="form-group  flex-grow-1"
																		sm={9}
																	>
																		<Col sm={1}></Col>
																		<Form.Label className="col-sm-4 form-label form-check-label">
																			Align
																		</Form.Label>
																	</Row>
																</Form.Group>
																<Form.Group style={{ margin: "2px" }}>
																	<div className="form-group">
																		<Row className="align-items-center">
																			<Form.Label className="col-sm-1"></Form.Label>
																			<Col sm={9}>
																				<Select
																					options={Align}
																					onChange={(e) =>
																						setImageAlignment(e.label)
																					}
																				/>
																			</Col>
																		</Row>
																	</div>
																</Form.Group>
																<Form.Group className="">
																	<Row
																		className="form-group  flex-grow-1"
																		sm={9}
																	>
																		<Col sm={1}></Col>
																		<Form.Label className="col-sm-4 form-label form-check-label">
																			Margins
																		</Form.Label>
																	</Row>
																</Form.Group>
																<Form.Group style={{ margin: "2px" }}>
																	<div className="form-group">
																		<Row className="align-items-center">
																			<Form.Label className="col-sm-1"></Form.Label>
																			<Col sm={9}>
																				<Select
																					options={Margin}
																					onChange={(e) =>
																						setResizingMarginFormat(e.label)
																					}
																				/>
																			</Col>
																			<Col
																				sm={7}
																				lg={7}
																				style={{
																					display: "flex",
																					marginLeft: "2rem",
																					marginTop: "12px",
																				}}
																			>
																				<div className="d-sm-flex col-gap-20 mt-2 ">
																					<div className="d-flex">
																						<ul className="margin-list d-flex">
																							<li>
																								<Form.Control
																									type="text"
																									placeholder="T"
																									autoFocus
																									onChange={(e) =>
																										setImageMarginTop(
																											e.target.value
																										)
																									}
																									autoComplete="off"
																									className="placeholder-light"
																								/>
																							</li>
																							<li>
																								<Form.Control
																									type="text"
																									placeholder="B"
																									autoFocus
																									onChange={(e) =>
																										setImageMarginBottom(
																											e.target.value
																										)
																									}
																									autoComplete="off"
																									className="placeholder-light"
																								/>
																							</li>
																							<li>
																								<Form.Control
																									type="text"
																									placeholder="L"
																									autoFocus
																									onChange={(e) =>
																										setImageMarginLeft(
																											e.target.value
																										)
																									}
																									autoComplete="off"
																									className="placeholder-light"
																								/>
																							</li>
																							<li>
																								<Form.Control
																									type="text"
																									placeholder="R"
																									autoFocus
																									onChange={(e) =>
																										setImageMarginRight(
																											e.target.value
																										)
																									}
																									autoComplete="off"
																									className="placeholder-light"
																								/>
																							</li>
																						</ul>
																					</div>
																				</div>
																			</Col>

																			<Col sm={6}></Col>
																		</Row>
																	</div>
																</Form.Group>
																<Form.Group style={{ margin: "2px" }}>
																	<div className="form-group">
																		<Row className="align-items-center">
																			<div className="text-center">
																				<Button
																					variant="primary"
																					className="btns btn"
																					onClick={() =>
																						handleUpdateEditGroup()
																					}
																				>
																					{loader ? (
																						<div
																							style={{
																								backgroundColor: "wheat",
																							}}
																						>
																							<Loader />
																						</div>
																					) : (
																						"Save"
																					)}
																				</Button>
																			</div>
																			<Col sm={6}></Col>
																		</Row>
																	</div>
																</Form.Group>
															</div>
														) : (
															<>
																<div
																	className="width-35rem scroll-y--axis"
																	style={{ height: "calc(100vh - 250px)" }}
																>
																	{view_edit_group !== undefined &&
																		view_edit_group.edit_group !== null &&
																		view_edit_group.edit_group !== undefined &&
																		((view_edit_group.edit_group.edit_types !==
																			null &&
																			view_edit_group.edit_group.edit_types !==
																				undefined) ||
																			(view_edit_group.edit_group
																				.extra_edit_types !== null &&
																				view_edit_group.edit_group
																					.extra_edit_types !== undefined)) && (
																			<>
																				<div className="sub-head mb-4">
																					Edit Type
																				</div>
																			</>
																		)}
																	{/* /***need to check**/}
																	{view_edit_group !== null &&
																		view_edit_group !== undefined &&
																		view_edit_group.edit_group !== null &&
																		view_edit_group.edit_group !== undefined &&
																		((view_edit_group.edit_group.edit_types !==
																			null &&
																			view_edit_group.edit_group.edit_types !==
																				undefined) ||
																			(view_edit_group.edit_group
																				.extra_edit_types !== null &&
																				view_edit_group.edit_group
																					.extra_edit_types !== undefined)) &&
																		editTypes.map((item, index) => {
																			return (
																				<>
																					<Form.Group>
																						{" "}
																						<Row
																							className="form-group  flex-grow-1 padding-left-3"
																							sm={9}
																						>
																							<Col sm={1}>
																								<input
																									type="checkbox"
																									value={item.id}
																									onChange={(e) => {
																										if (customizedSettings) {
																											handleBackGroundRemovalCheckbox(
																												item.id,
																												e.target.checked,
																												"Edit"
																											);
																											handleAddCheckboxSelect(
																												e
																											);
																										}
																									}}
																									checked={
																										item.id === 1
																											? selected
																											: item.id === 2
																											? selected1
																											: item.id === 3
																											? selected2
																											: selected3
																									}
																									id={`et_${item.id}`}
																									className="et_options"
																									disabled={checkisEditableField()}
																								/>
																							</Col>
																							<Form.Label
																								className="col-sm-4 form-label form-check-label"
																								style={{
																									width: "auto",
																									marginTop: "1px",
																									cursor: "pointer",
																								}}
																								for={`et_${item.id}`}
																							>
																								{item.edit_type}
																							</Form.Label>
																						</Row>
																						{item.id === 1 && selected && (
																							<Row
																								className="align-items-center"
																								style={{
																									marginTop: "-2px",
																									marginBottom: "20px",
																									width: "100%",
																								}}
																							>
																								<Form.Label className="col-sm-1"></Form.Label>
																								<Col sm={5}>
																									<Select
																										options={
																											selectBackgrondRemoval
																										}
																										onChange={(e) =>
																											setBackgroundRemovalColorType(
																												e.label
																											)
																										}
																										defaultValue={{
																											label: `${background_removal_color_type}`,
																											id: "21",
																										}}
																										value={{
																											label:
																												background_removal_color_type, // Set the label property to the desired value
																											value:
																												background_removal_color_type, // Set the value property to the corresponding value
																										}}
																										isDisabled={checkisEditableField()}
																									/>
																								</Col>
																								<Col sm={4}>
																									<Form.Control
																										type="text"
																										placeholder="#ffff"
																										autoFocus
																										onChange={(e) =>
																											setBackgroundRemovalColorCode(
																												e.target.value
																											)
																										}
																										disabled={checkisEditableField()}
																										value={
																											background_removal_color_code
																										}
																										className="placeholder-light"
																									/>
																								</Col>
																							</Row>
																						)}
																						{item.id === 2 && selected1 && (
																							<Row
																								className="align-items-center"
																								style={{
																									// marginLeft: "44px",
																									marginTop: "-2px",
																									marginBottom: "20px",
																									width: "100%",
																								}}
																							>
																								<Form.Label className="col-sm-1"></Form.Label>
																								<Col sm={5}>
																									<Select
																										options={
																											selectBackgrondRemoval
																										}
																										onChange={(e) =>
																											setBackgroundAdjustmentColorType(
																												e.label
																											)
																										}
																										value={{
																											label:
																												background_adjustment_color_type, // Set the label property to the desired value
																											value:
																												background_adjustment_color_type, // Set the value property to the corresponding value
																										}}
																										isDisabled={checkisEditableField()}
																									/>
																								</Col>
																								<Col sm={4}>
																									<Form.Control
																										type="text"
																										placeholder="#ffff"
																										autoFocus
																										disabled={checkisEditableField()}
																										onChange={(e) =>
																											setBackgroundAdjustmentColorCode(
																												e.target.value
																											)
																										}
																										value={
																											background_adjustment_color_code
																										}
																										className="placeholder-light"
																									/>
																								</Col>
																							</Row>
																						)}
																						{item.id === 3 && selected2 && (
																							<Row
																								className="align-items-center"
																								style={{
																									// marginLeft: "40px",
																									marginTop: "-2px",
																									marginBottom: "20px",
																									width: "100%",
																								}}
																							>
																								<Form.Group
																									style={{ margin: "2px" }}
																								>
																									<div className="form-group">
																										<Row className="align-items-center">
																											<Form.Label className="col-sm-1"></Form.Label>
																											<Col sm={9}>
																												<div className="d-sm-flex align-items-center col-gap-10">
																													<div className="d-sm-flex col-gap-10 align-items-center">
																														<Form.Control
																															type="text"
																															placeholder="Height"
																															autoFocus
																															onChange={(e) =>
																																setResizingHeight(
																																	e.target.value
																																)
																															}
																															value={
																																resizing_height
																															}
																															disabled={checkisEditableField()}
																															className="placeholder-light"
																														/>
																													</div>
																													<div className="d-sm-flex align-items-center">
																														<img
																															style={{
																																width: "18px",
																															}}
																															src={cross}
																															alt="mindreach images"
																														/>
																													</div>
																													<div className="d-sm-flex align-items-center col-gap-10">
																														<Form.Control
																															type="text"
																															placeholder="Width"
																															autoFocus
																															onChange={(e) =>
																																setResizingWidth(
																																	e.target.value
																																)
																															}
																															value={
																																resizing_width
																															}
																															disabled={checkisEditableField()}
																															className="placeholder-light"
																														/>
																													</div>
																												</div>
																											</Col>
																										</Row>
																									</div>
																								</Form.Group>
																							</Row>
																						)}
																					</Form.Group>
																				</>
																			);
																		})}
																	{/* Form groups for output specifications */}

																	{view_edit_group !== null &&
																		view_edit_group !== undefined &&
																		view_edit_group.edit_group !== null &&
																		view_edit_group.edit_group !== undefined &&
																		view_edit_group.edit_group.setting !==
																			null &&
																		(view_edit_group.edit_group.setting !==
																			undefined ||
																			view_edit_group.edit_group.file_format !==
																				"") && (
																			<>
																				<div className="sub-head">
																					Output Specifications
																				</div>
																				<Form.Group className="">
																					<Row
																						className="form-group  flex-grow-1 padding-left-3 "
																						sm={9}
																					>
																						<Form.Label className="col-sm-4 form-label form-check-label">
																							File Format
																						</Form.Label>
																					</Row>
																				</Form.Group>
																				<Form.Group style={{ margin: "2px" }}>
																					<div className="form-group padding-left-3 ">
																						<Row className="align-items-center">
																							<Col sm={6} className="pl-2">
																								<Select
																									options={file}
																									onChange={(e) =>
																										setImageFileFormat(e.label)
																									}
																									value={{
																										label: image_file_format,
																										value: image_file_format,
																									}}
																									isDisabled={checkisEditableField()}
																								/>
																							</Col>
																						</Row>
																					</div>
																				</Form.Group>
																				<Form.Group className="">
																					<Row
																						className="form-group  flex-grow-1 padding-left-3"
																						sm={9}
																					>
																						<Form.Label className="col-sm-4 form-label form-check-label">
																							Size
																						</Form.Label>
																					</Row>
																				</Form.Group>
																				<Form.Group style={{ margin: "2px" }}>
																					<div className="form-group padding-left-3">
																						<Row className="align-items-center">
																							<Col sm={9}>
																								<div className="d-sm-flex align-items-center col-gap-10">
																									<div className="d-sm-flex col-gap-10 align-items-center">
																										<Form.Control
																											type="text"
																											placeholder="Height"
																											onChange={(e) =>
																												setImageHeight(
																													e.target.value
																												)
																											}
																											disabled={checkisEditableField()}
																											value={image_height}
																											className="placeholder-light"
																										/>
																									</div>
																									<div className="d-sm-flex align-items-center"></div>
																									<div className="d-sm-flex align-items-center col-gap-10">
																										<Form.Control
																											type="text"
																											placeholder="Width"
																											onChange={(e) =>
																												setImageWidth(
																													e.target.value
																												)
																											}
																											disabled={checkisEditableField()}
																											value={image_width}
																											className="placeholder-light"
																										/>
																									</div>
																								</div>
																							</Col>
																						</Row>
																					</div>
																				</Form.Group>
																				<Form.Group className="">
																					<Row
																						className="form-group  flex-grow-1 padding-left-3"
																						sm={9}
																					>
																						<Form.Label className="col-sm-4 form-label form-check-label">
																							DPI
																						</Form.Label>
																					</Row>
																				</Form.Group>
																				<Form.Group style={{ margin: "2px" }}>
																					<div className="form-group padding-left-3">
																						<Row className="align-items-center">
																							<Col sm={10}>
																								<div className="d-sm-flex col-gap-20">
																									<Form.Control
																										type="text"
																										placeholder="2000"
																										autoComplete="off"
																										className="w-50 placeholder-light"
																										value={image_dpi}
																										disabled={checkisEditableField()}
																										onChange={(e) =>
																											setImageDpi(
																												e.target.value
																											)
																										}
																									/>

																									<div className="flex-fill">
																										<Select
																											options={dpi}
																											onChange={(e) =>
																												setImageDpiType(e.label)
																											}
																											value={{
																												label: image_dpi_type, // Set the label property to the desired value
																												value: image_dpi_type, // Set the value property to the corresponding value
																											}}
																											isDisabled={checkisEditableField()}
																										/>
																									</div>
																								</div>
																							</Col>
																						</Row>
																					</div>
																				</Form.Group>
																				<Form.Group className="">
																					<Row
																						className="form-group  flex-grow-1 padding-left-3"
																						sm={9}
																					>
																						<Form.Label className="col-sm-4 form-label form-check-label">
																							Color Mode
																						</Form.Label>
																					</Row>
																				</Form.Group>
																				<Form.Group style={{ margin: "2px" }}>
																					<div className="form-group padding-left-3">
																						<Row className="align-items-center">
																							<Col sm={9}>
																								<Select
																									options={ColorMode}
																									onChange={(e) =>
																										setImageColorMode(e.label)
																									}
																									value={{
																										label: image_color_mode, // Set the label property to the desired value
																										value: image_color_mode, // Set the value property to the corresponding value
																									}}
																									isDisabled={checkisEditableField()}
																								/>
																							</Col>
																						</Row>
																					</div>
																				</Form.Group>
																				<Form.Group className="">
																					<Row
																						className="form-group  flex-grow-1 padding-left-3"
																						sm={9}
																					>
																						<Form.Label className="col-sm-4 form-label form-check-label">
																							Align
																						</Form.Label>
																					</Row>
																				</Form.Group>
																				<Form.Group style={{ margin: "2px" }}>
																					<div className="form-group padding-left-3">
																						<Row className="align-items-center">
																							<Col sm={9}>
																								<Select
																									options={Align}
																									onChange={(e) =>
																										setImageAlignment(e.label)
																									}
																									value={{
																										label: image_alignment, // Set the label property to the desired value
																										value: image_alignment, // Set the value property to the corresponding value
																									}}
																									isDisabled={checkisEditableField()}
																								/>
																							</Col>
																						</Row>
																					</div>
																				</Form.Group>
																				<Form.Group className="">
																					<Row
																						className="form-group  flex-grow-1 padding-left-3"
																						sm={9}
																					>
																						<Form.Label className="col-sm-4 form-label form-check-label">
																							Margins
																						</Form.Label>
																					</Row>
																				</Form.Group>
																				<Form.Group style={{ margin: "2px" }}>
																					<div className="form-group padding-left-3">
																						<Row className="align-items-center">
																							<Col sm={9}>
																								<Select
																									options={Margin}
																									onChange={(e) =>
																										setImageMarginFormat(
																											e.label
																										)
																									}
																									value={{
																										label: image_margin_format, // Set the label property to the desired value
																										value: image_margin_format, // Set the value property to the corresponding value
																									}}
																									isDisabled={checkisEditableField()}
																								/>
																							</Col>
																							<Col
																								sm={7}
																								lg={7}
																								style={{
																									display: "flex",
																									marginTop: "12px",
																								}}
																							>
																								<div className="d-sm-flex col-gap-20 mt-2">
																									<div className="d-flex">
																										<ul className="margin-list d-flex">
																											<li
																												className={`${
																													checkisEditableField()
																														? "disabled"
																														: ""
																												}`}
																											>
																												<Form.Control
																													type="text"
																													placeholder="T"
																													value={
																														image_margin_top
																													}
																													disabled={checkisEditableField()}
																													onChange={(e) =>
																														setImageMarginTop(
																															e.target.value
																														)
																													}
																													className="placeholder-light"
																												/>
																											</li>
																											<li
																												className={`${
																													checkisEditableField()
																														? "disabled"
																														: ""
																												}`}
																											>
																												<Form.Control
																													type="text"
																													placeholder="B"
																													value={
																														image_margin_bottom
																													}
																													disabled={checkisEditableField()}
																													onChange={(e) =>
																														setImageMarginBottom(
																															e.target.value
																														)
																													}
																													className="placeholder-light"
																												/>
																											</li>
																											<li
																												className={`${
																													checkisEditableField()
																														? "disabled"
																														: ""
																												}`}
																											>
																												<Form.Control
																													type="text"
																													placeholder="L"
																													value={
																														image_margin_left
																													}
																													disabled={checkisEditableField()}
																													onChange={(e) =>
																														setImageMarginLeft(
																															e.target.value
																														)
																													}
																													className="placeholder-light"
																												/>
																											</li>
																											<li
																												className={`${
																													checkisEditableField()
																														? "disabled"
																														: ""
																												}`}
																											>
																												<Form.Control
																													type="text"
																													placeholder="R"
																													value={
																														image_margin_right
																													}
																													disabled={checkisEditableField()}
																													onChange={(e) =>
																														setImageMarginRight(
																															e.target.value
																														)
																													}
																													className="placeholder-light"
																												/>
																											</li>
																										</ul>
																									</div>
																								</div>
																							</Col>
																							<Col sm={6}></Col>
																						</Row>
																					</div>
																				</Form.Group>
																			</>
																		)}
																</div>
																<Col sm={6}></Col>
															</>
														)}
													</div>
												)}
											</div>
										</div>
									</div>
								</Tab>
								{amount.map((data, index) => {
									const tabKey = index + 1;
									return (
										<Tab
											eventKey={index + 1}
											title={
												<OverlayTrigger
													placement="right"
													delay={{ show: 250, hide: 400 }}
													overlay={
														<Tooltip id={`tooltip-${index}`}>
															{`${copyAmounts[index]}`}
														</Tooltip>
													}
												>
													<span>{copyAmounts[index]}</span>
												</OverlayTrigger>
											}
										>
											<div className="duplicate-setting-image--content d-flex">
												<div
													className="width-35rem"
													style={{ marginRight: "unset" }}
												>
													<div
														className="width-35rem scroll-y--axis"
														style={{ height: "calc(100vh - 215px)" }}
													>
														{/* Form groups for Edit Type*/}
														<div className="sub-head mt-2" id="et_head">
															Additional Editing
														</div>
														{editTypes !== undefined &&
															editTypes.map((item, i) => {
																return (
																	<Form.Group>
																		{" "}
																		<Row
																			className="form-group flex-grow-1 padding-left-3"
																			style={{
																				paddingTop:
																					(i === 0 ? "10" : "1.4") + "px",
																			}}
																			sm={9}
																		>
																			<Col
																				sm={1}
																				style={{
																					paddingTop:
																						(i === 0 ? "2" : "0.8") + "px",
																				}}
																			>
																				<input
																					type="checkbox"
																					onChange={(e) => {
																						handleCopyCheckboxCheck(
																							e,
																							item.id,
																							index
																						);
																					}}
																					value={item.id}
																					id={`copy_et_${index}_${item.id}`}
																					className={`copy_et_options copy_et_options_${index}`}
																				/>
																			</Col>
																			<Form.Label
																				className={`col-sm-4 form-label form-check-label ${
																					fieldArray[key - 1] &&
																					(fieldArray[key - 1]["height"] ===
																						"" ||
																						fieldArray[key - 1]["width"] ===
																							"") &&
																					isFormSubmitted
																						? "duplicate-compulsory-field-error"
																						: "duplicate-compulsory-field"
																				}`}
																				style={{
																					width: "auto",
																					cursor: "pointer",
																				}}
																				for={`copy_et_${index}_${item.id}`}
																			>
																				{item.edit_type}
																				&nbsp;
																				{item.id === 3 &&
																					checkEdityTypeSelected(
																						item.id,
																						index
																					) && <span>*</span>}
																			</Form.Label>
																		</Row>
																		{item.id === 1 &&
																			checkEdityTypeSelected(
																				item.id,
																				index
																			) && (
																				<Row
																					className="align-items-center"
																					style={{
																						marginLeft: "auto",
																						marginTop: "0",
																						marginBottom: "20px",
																					}}
																				>
																					<Col sm={5} className="ml_4_percent">
																						<Select
																							options={selectBackgrondRemoval}
																							onChange={(e) => {
																								setBackgroundRemovalColorType(
																									e.label
																								);
																								updateFieldState(
																									e.label,
																									"bg_removal_type",
																									key - 1
																								);
																							}}
																						/>
																					</Col>
																					<Col sm={3}>
																						<Form.Control
																							type="text"
																							placeholder="#ffff"
																							autoFocus
																							onChange={(e) => {
																								setBackgroundRemovalColorCode(
																									e.target.value
																								);
																								updateFieldState(
																									e.target.value,
																									"bg_removal_color",
																									key - 1
																								);
																							}}
																							autoComplete="off"
																							className="placeholder-light"
																						/>
																					</Col>
																				</Row>
																			)}
																		{item.id === 2 &&
																			checkEdityTypeSelected(
																				item.id,
																				index
																			) && (
																				<Row
																					className="align-items-center"
																					style={{
																						marginLeft: "auto",
																						marginTop: "0",
																						marginBottom: "20px",
																					}}
																				>
																					<Col sm={5} className="ml_4_percent">
																						<Select
																							options={selectBackgrondRemoval}
																							onChange={(e) => {
																								setBackgroundAdjustmentColorType(
																									e.label
																								);
																								updateFieldState(
																									e.label,
																									"bg_adjustment_type",
																									key - 1
																								);
																							}}
																						/>
																					</Col>
																					<Col sm={3}>
																						<Form.Control
																							type="text"
																							placeholder="#ffff"
																							autoFocus
																							onChange={(e) => {
																								setBackgroundAdjustmentColorCode(
																									e.target.value
																								);
																								updateFieldState(
																									e.target.value,
																									"bg_adjustment_color",
																									key - 1
																								);
																							}}
																							autoComplete="off"
																							className="placeholder-light"
																						/>
																					</Col>
																				</Row>
																			)}
																		{item.id === 3 &&
																			checkEdityTypeSelected(
																				item.id,
																				index
																			) && (
																				<>
																					<Row
																						className="align-items-center"
																						style={{
																							marginLeft: "auto",
																							marginRight: "auto",
																							marginTop: "0",
																							marginBottom: "20px",
																							width: " 94%",
																						}}
																					>
																						<Form.Group
																							style={{ margin: "2px" }}
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Col sm={12} className="ml-1">
																										<div className="d-sm-flex align-items-center col-gap-10">
																											<div
																												className={`d-sm-flex col-gap-10 align-items-center${
																													fieldArray[key - 1] &&
																													fieldArray[key - 1][
																														"height"
																													] === "" &&
																													isFormSubmitted
																														? " duplicate-compulsory-field-error"
																														: ""
																												}`}
																											>
																												<Form.Control
																													type="text"
																													placeholder={"Height"}
																													autoFocus
																													onChange={(e) => {
																														// data.height = e.target.value;
																														updateFieldState(
																															e.target.value,
																															"height",
																															key - 1
																														);
																														validateCopyForm();
																													}}
																													autoComplete="off"
																													className={
																														checkIsDisabledResizing(
																															index
																														)
																															? "placeholder-dark"
																															: "placeholder-light"
																													}
																													id={`height_${index}_${i}`}
																													disabled={checkIsDisabledResizing(
																														index
																													)}
																													value={
																														fieldArray[key - 1]
																															? fieldArray[
																																	key - 1
																															  ]["height"]
																															: ""
																													}
																												/>
																											</div>
																											<div
																												className={`d-sm-flex align-items-center`}
																											>
																												<img
																													style={{
																														width: "18px",
																													}}
																													src={cross}
																													className="img-fluid"
																													alt="mindreach images"
																												/>
																											</div>
																											<div
																												className={`d-sm-flex align-items-center col-gap-10${
																													fieldArray[key - 1] &&
																													fieldArray[key - 1][
																														"width"
																													] === "" &&
																													isFormSubmitted
																														? " duplicate-compulsory-field-error"
																														: ""
																												}`}
																											>
																												<Form.Control
																													type="text"
																													placeholder={"Width"}
																													autoFocus
																													onChange={(e) => {
																														// data.width = e.target.value;
																														updateFieldState(
																															e.target.value,
																															"width",
																															key - 1
																														);
																														validateCopyForm();
																													}}
																													autoComplete="off"
																													className={
																														checkIsDisabledResizing(
																															index
																														)
																															? "placeholder-dark"
																															: "placeholder-light"
																													}
																													id={`width_${index}_${i}`}
																													disabled={checkIsDisabledResizing(
																														index
																													)}
																													value={
																														fieldArray[key - 1]
																															? fieldArray[
																																	key - 1
																															  ]["width"]
																															: ""
																													}
																												/>
																											</div>
																										</div>
																									</Col>
																								</Row>
																							</div>
																						</Form.Group>
																					</Row>
																					<Row
																						className="align-items-center"
																						style={{
																							marginLeft: "auto",
																							marginRight: "auto",
																							marginTop: "0",
																							marginBottom: "20px",
																							width: " 94%",
																						}}
																					>
																						<Form.Group
																							style={{ margin: "2px" }}
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Col sm={12} className="ml-1">
																										<div className="d-sm-flex align-items-center col-gap-10">
																											<div className="d-sm-flex col-gap-10 align-items-center">
																												<input
																													type="checkbox"
																													onChange={(e) => {
																														handleKeepOriginalImageResizing(
																															e,
																															item.id,
																															index
																														);
																														validateCopyForm();
																													}}
																													id={`resizing_${index}_${i}`}
																												/>
																											</div>

																											<div className="d-sm-flex align-items-center">
																												<Form.Label
																													className="col-sm-12 form-label form-check-label"
																													for={`resizing_${index}_${i}`}
																													style={{
																														cursor: "pointer",
																														marginTop: "1px",
																														paddingLeft: "6px",
																													}}
																												>
																													Same as original image
																												</Form.Label>
																											</div>
																										</div>
																									</Col>
																								</Row>
																							</div>
																						</Form.Group>
																					</Row>
																				</>
																			)}
																	</Form.Group>
																);
															})}
														{/* Form groups for output specifications */}
														<div className="sub-head">
															Output Specifications
														</div>
														<Form.Group
															style={{ margin: "2px" }}
															className="pt-2"
														>
															<div className="form-group">
																<Row className="align-items-center">
																	<Col sm={12} className="ml-1">
																		<div className="d-sm-flex align-items-center col-gap-10">
																			<div className="d-sm-flex col-gap-10 align-items-center">
																				<input
																					type="checkbox"
																					onChange={(e) => {
																						handleKeepOriginalImageOutputSpec(
																							e,
																							index
																						);
																					}}
																					id={`output_spec_fields_${index}`}
																					className="output_spec_fields"
																				/>
																			</div>

																			<div className="d-sm-flex align-items-center">
																				<Form.Label
																					className="col-sm-12 form-label form-check-label"
																					for={`output_spec_fields_${index}`}
																					style={{
																						cursor: "pointer",
																						marginTop: "1px",
																						paddingLeft: "6px",
																					}}
																				>
																					Same as original image
																				</Form.Label>
																			</div>
																		</div>
																	</Col>
																</Row>
															</div>
														</Form.Group>
														<Form.Group className=" pt-1">
															<Row
																className="form-group  flex-grow-1 padding-left-3"
																sm={9}
															>
																<Form.Label
																	className={`col-sm-4 form-label form-check-label ${
																		!checkDisableOutputSpecifications(index) &&
																		fieldArray[key - 1] &&
																		fieldArray[key - 1]["file_format"] === "" &&
																		fieldArray[key - 1] &&
																		isFormSubmitted
																			? "duplicate-compulsory-field-error"
																			: "duplicate-compulsory-field"
																	}`}
																>
																	File Format <span>*</span>
																</Form.Label>
															</Row>
														</Form.Group>
														<Form.Group style={{ margin: "2px" }}>
															<div className="form-group">
																<Row className="align-items-center">
																	<Col sm={6}>
																		<Select
																			options={file}
																			placeholder="Select"
																			onChange={(e) => {
																				const selectedFormat = e.label; // Assuming e.label holds the selected file format
																				data.file_format = selectedFormat;
																				setAmount([...amount]);
																				updateFieldState(
																					selectedFormat,
																					"file_format",
																					key - 1
																				);
																			}}
																			value={{
																				label:
																					fieldArray[key - 1] &&
																					fieldArray[key - 1]["file_format"]
																						? fieldArray[key - 1] &&
																						  fieldArray[key - 1]["file_format"]
																						: "Select", // Set the label property to the desired value
																				value:
																					fieldArray[key - 1] &&
																					fieldArray[key - 1]["file_format"]
																						? fieldArray[key - 1] &&
																						  fieldArray[key - 1]["file_format"]
																						: "Select", // Set the value property to the corresponding value
																			}}
																			isDisabled={checkDisableOutputSpecifications(
																				index
																			)}
																			styles={{
																				control: (provided) => ({
																					...provided,
																					height: 52,
																					border: `${
																						!checkDisableOutputSpecifications(
																							index
																						) &&
																						fieldArray[key - 1] &&
																						fieldArray[key - 1][
																							"file_format"
																						] === "" &&
																						isFormSubmitted
																							? "1px solid #a92322"
																							: "1px solid #e9e9e9"
																					}`,
																					boxShadow: "none",
																					"&:hover": {
																						border: `${
																							!checkDisableOutputSpecifications(
																								index
																							) &&
																							fieldArray[key - 1] &&
																							fieldArray[key - 1][
																								"file_format"
																							] === "" &&
																							isFormSubmitted
																								? "1px solid #a92322"
																								: "1px solid #e9e9e9"
																						}`,
																					},
																				}),
																			}}
																		/>
																	</Col>
																</Row>
															</div>
														</Form.Group>

														<Form.Group className="">
															<Row
																className="form-group  flex-grow-1 padding-left-3"
																sm={9}
															>
																<Form.Label className="col-sm-4 form-label form-check-label">
																	Margins
																</Form.Label>
															</Row>
														</Form.Group>

														<Form.Group style={{ margin: "2px" }}>
															<div className="form-group">
																<Row className="align-items-center">
																	<Col sm={6} style={{ paddingLeft: "13px" }}>
																		<Select
																			options={Margin}
																			placeholder="Select"
																			onChange={(e) => {
																				const selectedFormat = e.label; // Assuming e.label holds the selected file format
																				data.margin_format = selectedFormat;
																				setAmount([...amount]);
																				updateFieldState(
																					selectedFormat,
																					"margin_format",
																					key - 1
																				);
																			}}
																			value={{
																				label:
																					fieldArray[key - 1] &&
																					fieldArray[key - 1]["margin_format"]
																						? fieldArray[key - 1] &&
																						  fieldArray[key - 1][
																								"margin_format"
																						  ]
																						: "Select", // Set the label property to the desired value
																				value:
																					fieldArray[key - 1] &&
																					fieldArray[key - 1]["margin_format"]
																						? fieldArray[key - 1] &&
																						  fieldArray[key - 1][
																								"margin_format"
																						  ]
																						: "Select", // Set the value property to the corresponding value
																			}}
																			isDisabled={checkDisableOutputSpecifications(
																				index
																			)}
																		/>
																	</Col>
																	<Col
																		sm={7}
																		lg={7}
																		style={{
																			display: "flex",
																			marginTop: "12px",
																		}}
																	>
																		<div className="d-sm-flex col-gap-20 mt-2">
																			<div className="d-flex">
																				<ul
																					className={`margin-list d-flex ${
																						!checkDisableOutputSpecifications(
																							key - 1
																						) &&
																						fieldArray[key - 1] &&
																						fieldArray[key - 1][
																							"margin_format"
																						] !== "" &&
																						isFormSubmitted &&
																						(isNaN(
																							parseInt(
																								fieldArray[key - 1][
																									"margin_top"
																								]
																							)
																						) ||
																							isNaN(
																								parseInt(
																									fieldArray[key - 1][
																										"margin_right"
																									]
																								)
																							) ||
																							isNaN(
																								parseInt(
																									fieldArray[key - 1][
																										"margin_bottom"
																									]
																								)
																							) ||
																							isNaN(
																								parseInt(
																									fieldArray[key - 1][
																										"margin_left"
																									]
																								)
																							))
																							? "duplicate-compulsory-field-error"
																							: ""
																					}`}
																				>
																					<li>
																						<Form.Control
																							type="text"
																							placeholder={
																								checkDisableOutputSpecifications(
																									index
																								)
																									? "-"
																									: "T"
																							}
																							autoFocus
																							onChange={(e) => {
																								const selectedFormat =
																									e.target.value;
																								data.margin_top =
																									selectedFormat;
																								setAmount([...amount]);
																								updateFieldState(
																									selectedFormat,
																									"margin_top",
																									key - 1
																								);
																							}}
																							autoComplete="off"
																							className={`text-center ${
																								checkDisableOutputSpecifications(
																									index
																								)
																									? "placeholder-dark"
																									: "placeholder-light"
																							}`}
																							disabled={checkDisableOutputSpecifications(
																								index
																							)}
																							value={
																								fieldArray[key - 1] &&
																								fieldArray[key - 1][
																									"margin_top"
																								]
																									? fieldArray[key - 1][
																											"margin_top"
																									  ]
																									: ""
																							}
																						/>
																					</li>
																					<li>
																						<Form.Control
																							type="text"
																							placeholder={
																								checkDisableOutputSpecifications(
																									index
																								)
																									? "-"
																									: "B"
																							}
																							autoFocus
																							onChange={(e) => {
																								const selectedFormat =
																									e.target.value;
																								data.margin_bottom =
																									selectedFormat;
																								setAmount([...amount]);
																								updateFieldState(
																									selectedFormat,
																									"margin_bottom",
																									key - 1
																								);
																							}}
																							autoComplete="off"
																							className={`text-center ${
																								checkDisableOutputSpecifications(
																									index
																								)
																									? "placeholder-dark"
																									: "placeholder-light"
																							}`}
																							disabled={checkDisableOutputSpecifications(
																								index
																							)}
																							value={
																								fieldArray[key - 1] &&
																								fieldArray[key - 1][
																									"margin_bottom"
																								]
																									? fieldArray[key - 1][
																											"margin_bottom"
																									  ]
																									: ""
																							}
																						/>
																					</li>
																					<li>
																						<Form.Control
																							type="text"
																							placeholder={
																								checkDisableOutputSpecifications(
																									index
																								)
																									? "-"
																									: "R"
																							}
																							autoFocus
																							onChange={(e) => {
																								const selectedFormat =
																									e.target.value;
																								data.margin_right =
																									selectedFormat;
																								setAmount([...amount]);
																								updateFieldState(
																									selectedFormat,
																									"margin_right",
																									key - 1
																								);
																							}}
																							autoComplete="off"
																							className={`text-center ${
																								checkDisableOutputSpecifications(
																									index
																								)
																									? "placeholder-dark"
																									: "placeholder-light"
																							}`}
																							disabled={checkDisableOutputSpecifications(
																								index
																							)}
																							value={
																								fieldArray[key - 1] &&
																								fieldArray[key - 1][
																									"margin_right"
																								]
																									? fieldArray[key - 1][
																											"margin_right"
																									  ]
																									: ""
																							}
																						/>
																					</li>
																					<li>
																						<Form.Control
																							type="text"
																							placeholder={
																								checkDisableOutputSpecifications(
																									index
																								)
																									? "-"
																									: "L"
																							}
																							autoFocus
																							onChange={(e) => {
																								const selectedFormat =
																									e.target.value;
																								data.margin_left =
																									selectedFormat;
																								setAmount([...amount]);
																								updateFieldState(
																									selectedFormat,
																									"margin_left",
																									key - 1
																								);
																							}}
																							autoComplete="off"
																							className={`text-center ${
																								checkDisableOutputSpecifications(
																									index
																								)
																									? "placeholder-dark"
																									: "placeholder-light"
																							}`}
																							disabled={checkDisableOutputSpecifications(
																								index
																							)}
																							value={
																								fieldArray[key - 1] &&
																								fieldArray[key - 1][
																									"margin_left"
																								]
																									? fieldArray[key - 1][
																											"margin_left"
																									  ]
																									: ""
																							}
																						/>
																					</li>
																				</ul>
																			</div>
																		</div>
																	</Col>
																	<Col sm={6}></Col>
																</Row>
															</div>
														</Form.Group>
													</div>
												</div>
											</div>
										</Tab>
									);
								})}
							</Tabs>
						</div>
					</div>
				</Modal.Body>
				{Number(key) !== Number(0) && (
					<Modal.Footer
						style={{ marginTop: "-12px" }}
						className={`${
							Number(key) === Number(0) ? "duplicate-footer-custom-height" : ""
						}`}
					>
						{Number(key) !== 0 ? (
							<Row className="align-items-center" style={{ width: "100%" }}>
								<Col sm={12}>
									<Button
										variant=""
										className="puple btns mr-2"
										onClick={onHide}
										style={{
											marginLeft: "unset",
											marginLeft: "-6px",
											padding: "10px 28px",
										}}
									>
										Cancel
									</Button>

									<Button
										variant="primary"
										className="btns"
										onClick={() => {
											handleFormSubmit(Number(key) - 1);
										}}
										style={{ marginLeft: "unset", padding: "10px 28px" }}
									>
										Create
									</Button>
								</Col>
								<Col sm={7} style={{ width: "auto" }}>
									{errorTabs && isFormSubmitted && (
										<>
											<div className="duplicate-settings-error-box">
												* Missing required field: {errorTabs}
											</div>
										</>
									)}
								</Col>
							</Row>
						) : (
							<div style={{ display: "none" }}>
								{group_name_select === "New Settings" && !customizedSettings ? (
									<Button
										variant="primary"
										className="btns btn"
										onClick={() => handleUpdateEditGroup()}
									>
										{loader ? (
											<div style={{ backgroundColor: "wheat" }}>
												<Loader />
											</div>
										) : (
											"Save"
										)}
									</Button>
								) : (
									<>
										<Button
											variant="primary"
											className="btns btn"
											onClick={() => handleUpdateImageEditGroup()}
											disabled={checkisEditableField() && view_edit_group}
											style={{ display: "none" }}
										>
											{loader ? (
												<div>
													<Loader />
													Loading
												</div>
											) : (
												"Save"
											)}
										</Button>
									</>
								)}
							</div>
						)}
					</Modal.Footer>
				)}
			</Modal>
		</>
	);
};

export default DuplicateSettings;
