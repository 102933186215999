// import React, { useState, useRef, useEffect } from "react";
// import { PanViewer } from "react-image-pan-zoom-rotate";

// import Form from "react-bootstrap/Form";
// import DeleteModalConfirmation from "../../../../../components/Modal/DeleteModal";
// import MyVerticallyCenteredModal from "../../../../../components/Modal";
// import ImageLoader from "../../../../../components/ImageLoader";
// import Draggable, { DraggableCore } from "react-draggable";
// import MoreIcon from "./../../../../../assets/Images/coordinator_actions_icon/more-Final.svg";
// import cross from "./../../../../../assets/Images/cross-3.svg";
// import send_message from "./../../../../../assets/Images/send-message.svg";
// import send_message_blue from "./../../../../../assets/Images/send-messge-blue.svg";
// import { client } from "./../../../../../data/API/GobalClientAxiosRequest";
// import { imageURL, apiURL } from "../../../../../data/filepath";

// import {
//   ADMIN_GET_COMMENTS_TO_VIEW_COORDINATOR_IMAGE,
//   ADMIN_UPDATE_COMMENTS_TO_POST_COORDINATOR_IMAGE,
//   ADMIN_DELETE_COMMENTS_COORDINATOR_IMAGE,
// } from "../../../../../config/apiRoutes.api";

// Check all the status of editor assigned
// const editorWorkingStatus = [
// 	"assign_to_editor",
// 	"assigned",
// 	"started",
// 	"hold",
// 	"qc",
// 	"error",
// 	"completed",
// 	"finished",
// 	"reassigned",
// 	"temporary-reassigned",
// 	"temporary-completed",
// ];

// var is_dragging = false;
// Default max height for comment box
// let standard_top_position = 560;

// const ImageAnnotation = ({
//   imageURL,
//   currentAnnotation,
//   setCurrentAnnotation,
//   annotations,
//   setAnnotations,
//   // handleTextChange,
//   setToggleMessage,
//   toggleMessage,
//   idSelected,
//   token,
//   setCommentsList,
//   showCommentList,
//   annotationsComments,
//   setAnnotationsComments,
//   image_details_view
// }) => {
//   // Create a FormData object for request payload
//   const bodyFormData = new FormData();

//   // Create an HTTP client with the token
//   const request = client(token);
//   const [dx, setDx] = useState(0);
//   const [dy, setDy] = useState(0);
//   const [zoom, setZoom] = useState(1);
//   const [panning, setPanning] = useState(false);
//   const [panStart, setPanStart] = useState({ x: 0, y: 0 });
//   const imageRefZoom = useRef(null);

//   const [rotation, setRotation] = useState(0);
//   const [flip, setFlip] = useState(false);
//   const [dragging, setDragging] = useState(false);
//   const [isDragging, setIsDragging] = useState(false);
//   const [draggedElement, setdraggedElement] = useState(false);
//   const [defaultPosition, setDefaultPosition] = useState(false);
//   const [deleteIndex, setDeleteIndex] = useState(-1);

//   const roleJson = sessionStorage.getItem("role");
//   const role = roleJson ? JSON.parse(roleJson) : null;
//   const currentUserId = [role !== null ? role.id : "login"];
//   const LoginUserId = role !== null && role.pivot ? role.pivot.user_id : "";
//   const [error, setError] = useState("");
//   const [x_axis, setXAxis] = useState("");
//   const [y_axis, setYAxis] = useState("");
//   const [comment, setComments] = useState("");
//   const [editCommentValue, setEditCommentValue] = useState("");
//   // const [annotationsComments, setAnnotationsComments] = useState([]);
//   const [dropDown, setDropDown] = useState(false);
//   const [deleteId, setDeleteId] = useState("");
//   const inputRef = useRef(null);
//   const imageRef = useRef();
//   const styleType = "px";
//   // const styleType = "%";
//   const [show, setShow] = useState(false);
//   const [modalShow, setModalShow] = useState(false);
//   const [deleteResponse, setDeleteResponse] = useState("");
//   const [deleteHeading, setDeleteHeading] = useState("");
//   const [deleteCommentType, setDeleteCommentType] = useState("Delete Comment");
//   const [deleteCommentTypeDetails, setDeleteCommentTypeDetails] =
//     useState("comment");
//   const [editComment, setEditcomment] = useState([]);
//   const [activeCommentIndex, setActiveCommentIndex] = useState(-1);

//   /**
//    * Handles to return the number from the string.
//    * @param {String} string.
//    * @returns {} returns the numbers from string.
//    */

//   const getNumbers = (string) => {
//     let numb = string.match(/\d/g);
//     return Number(numb.join(""));
//   };

//   /**
//    * Handles to parent element top css position.
//    * @param {Number} index.
//    * @returns {} return the top css.
//    */

//   const getElementTopPosition = (index) => {
//     const element = document.getElementById(`parent_comment_holder_${index}`);
//     if (element) {
//       return getNumbers(element.style.top);
//     }
//     return 0;
//   };

//   /**
//    * Handles to set max height.
//    * @param {Number} index.
//    * @returns {} return the max height.
//    */
//   const setCommentBoxMaxHeight = (index) => {
//     setTimeout(() => {
//       let top = standard_top_position;
//       const element_top = getElementTopPosition(index);
//       const matrixCSS = getMatrix(
//         document.getElementById(`parent_comment_holder_${index}`)
//       );
//       if (matrixCSS && matrixCSS.length > 1) {
//         if (matrixCSS[1] > -1) {
//           top = top - element_top - matrixCSS[1];
//         } else {
//           top = top - element_top + Math.abs(matrixCSS[1]);
//         }
//         document.getElementById(
//           `comments_${index}`
//         ).style.maxHeight = `${top}px`;
//       }
//     }, 10);
//   };

//   /**
//    * Handles to get max height.
//    * @param {Number} index.
//    * @returns {} return the max height.
//    */
//   const getCommentBoxMaxHeight = (index) => {
//     return standard_top_position - getElementTopPosition(index);
//   };

//   // Function to handle spacebar key press

//   useEffect(() => {
//     const handleSpaceBarPress = (event) => {
//       if (
//         event.keyCode === 32 &&
//         !event.target.classList.contains("form-control")
//       ) {
//         // 32 is the keycode for the spacebar
//         const moveDistance = 10; // Adjust this value as needed
//         setDx(dx + moveDistance);
//       }
//     };

//     document.addEventListener("keydown", handleSpaceBarPress);

//     return () => {
//       document.removeEventListener("keydown", handleSpaceBarPress);
//     };
//   }, [dx]);
//   const handleWheel = (e) => {
//     if (e.altKey) {
//       // Zoom in/out when Option (Alt) key is held while scrolling
//       e.preventDefault();
//       setZoom((prevZoom) => prevZoom + (e.deltaY > 0 ? -0.1 : 0.1));
//     }
//   };

//   const handleMouseDown = (e) => {
//     if (e.key === " ") {
//       // Start panning when the Space key is pressed
//       setPanning(true);
//       setPanStart({ x: e.clientX, y: e.clientY });
//     }
//   };

//   const handleMouseUp = () => {
//     // Stop panning when the mouse button is released
//     setPanning(false);
//   };

//   const handleMouseMove = (e) => {
//     if (panning) {
//       // Adjust the panning position
//       const dx = e.clientX - panStart.x;
//       const dy = e.clientY - panStart.y;
//       // Update the pan position (You'll need to update dx and dy in your state)
//       // pandx(dx);
//       // pandy(dy);
//       setPanStart({ x: e.clientX, y: e.clientY });
//     }
//   };

//   useEffect(() => {
//     // Add event listeners when the component mounts
//     window.addEventListener("wheel", handleWheel, { passive: false });
//     document.addEventListener("keydown", handleMouseDown);
//     document.addEventListener("keyup", handleMouseUp);
//     window.addEventListener("mousemove", handleMouseMove);

//     return () => {
//       // Clean up event listeners when the component unmounts
//       window.removeEventListener("wheel", handleWheel);
//       document.removeEventListener("keydown", handleMouseDown);
//       document.removeEventListener("keyup", handleMouseUp);
//       window.removeEventListener("mousemove", handleMouseMove);
//     };
//   }, []);

//   const handleImageClick = (event, inputText) => {
//     setActiveCommentIndex(-1);
//     const x = event.nativeEvent.offsetX;
//     const y = event.nativeEvent.offsetY;
//     setXAxis(x);
//     setYAxis(y);
//     setComments(inputText);
//     setDefaultPosition(x);
//     // // Create a new annotation object with the input text
//     const newAnnotation = {
//       x,
//       y,
//       text: inputText, // Use the inputText provided as the annotation text
//       image_id: `${idSelected}`,
//       user_id: `${currentUserId}`,
//     };
//     // Hide all the add comment boxes
//     showHideElement("addcomment", "none");

//     // Hide all the comment boxes
//     showHideElement("showcomments", "none");

//     // Reset Zindex for all the parent comment boxes
//     document.querySelectorAll(".parent_comments").forEach((element) => {
//       element.style.zIndex = 0;
//     });
//     // Hide all the hover comment boxes
//     showHideElement("hover-show-comments", "none");

//     // Show all first letter  boxes
//     showHideElement("callout", "block");
//     setToggleMessage(true);
//     // // Add the new annotation to the annotations array
//     setAnnotations([...annotations, newAnnotation]);
//   };

//   /**
//    * Handles all the elements based on selector class.
//    * @param {String} selector class.
//    * @param {String} display hide/show.
//    * @returns {JSX.Element} Hide/Show all the elements based on display and selector.
//    */
//   const showHideElement = (selector, display) => {
//     document.querySelectorAll(`.${selector}`).forEach((element) => {
//       element.style.display = display;
//     });
//   };

//   const handleTextChange = (updatedText) => {
//     setComments(updatedText);
//   };
//   const fetchComments = (setdrag = false) => {
//     if (token) {
//       request
//         .get(
//           apiURL +
//             ADMIN_GET_COMMENTS_TO_VIEW_COORDINATOR_IMAGE +
//             `/${idSelected}`
//         )
//         .then((response) => {
//           //Set the success from the response
//           setAnnotationsComments([]);
//           setAnnotationsComments(response.data.comments);
//           if (setdrag) {
//             let existingComments = [...annotationsComments];
//             let commentIndex = existingComments.findIndex(function (item) {
//               return item.comment_id == setdrag;
//             });
//             if (commentIndex >= 0) {
//               let element = document.getElementById(
//                 `parent_comment_holder_${commentIndex}`
//               );
//               element.setAttribute("dragged", "completed");
//               // setTimeout(() => {
//               //   element.classList.remove("react-draggable-dragged"); // Remove class
//               // }, 1000);
//             }
//             setdraggedElement(true);
//           }
//           if (deleteIndex >= 0) {
//             setTimeout(() => {
//               showComments(deleteIndex);
//             }, 20);
//           }
//         })
//         .catch((err) => {
//           let message =
//             typeof err.response !== "undefined"
//               ? err.response.data.message
//               : err.message;
//           setError(message);
//         });
//     }
//   };

//   const hideAllAddCommentBox = () => {
//     // Hide all the add comment boxes
//     let elements = document.querySelectorAll(".addcomment");
//     for (let i = 0; i < elements.length; i++) {
//       elements[i].style.display = "none";
//     }
//   };

//   /**
//    * Handles to update the  the comment x_axis and y_axis.
//    * @param {Number} comment_id .
//    * @returns {JSX.Element} Submit the form and post the data with API.
//    */
//   const updateXYCoordinates = (comment_id, comment_val, x_axis, y_axis) => {
//     if (
//       token &&
//       comment_id > 0 &&
//       comment_val &&
//       Math.abs(x_axis) >= 0 &&
//       Math.abs(y_axis) >= 0
//     ) {
//       bodyFormData.append("image_id", idSelected);
//       bodyFormData.append("x_axis", x_axis);
//       bodyFormData.append("y_axis", y_axis);
//       bodyFormData.append("comment", comment_val);
//       bodyFormData.append("perant_id", Number(0));
//       bodyFormData.append("comment_id", comment_id);
//       request
//         .post(
//           apiURL + ADMIN_UPDATE_COMMENTS_TO_POST_COORDINATOR_IMAGE,
//           bodyFormData
//         )
//         .then((response) => {
//           setXAxis("");
//           setYAxis("");
//           setComments("");
//           //Set the success from the response
//           // fetchComments();
//           // setCommentsList(true);
//         })
//         .catch((err) => {
//           let message =
//             typeof err.response !== "undefined"
//               ? err.response.data.message
//               : err.message;
//           setError(message);
//         });
//     }
//   };
//   /**
//    * Handles to submit the comment box.
//    * @param {Event} e - On Click event.
//    * @returns {JSX.Element} Submit the form and post the data with API.
//    */
//   const submitCommentForm = (
//     e,
//     action = "edit",
//     parent_id = Number(0),
//     edit_comment_id = Number(0),
//     annotation = {},
//     index = 0
//   ) => {
//     let comment_val = "";
//     if (action === "edit") {
//       comment_val =
//         edit_comment_id > 0 &&
//         document.getElementById(`edit_comment_${edit_comment_id}`)
//           ? document.getElementById(`edit_comment_${edit_comment_id}`).value
//           : comment;
//     } else {
//       comment_val = comment;
//     }

//     e.preventDefault();
//     let x_axs = !x_axis ? annotation.x_axis : x_axis;
//     let y_axs = !y_axis ? annotation.y_axis : y_axis;

//     if (token && idSelected && x_axs >= 0 && y_axs >= 0 && comment_val) {
//       setDeleteIndex(-1);
//       if (action === "edit") {
//         if (document.getElementById(`edit_comment_img_${edit_comment_id}`)) {
//           document.getElementById(
//             `edit_comment_img_${edit_comment_id}`
//           ).style.display = "none";
//           if (document.getElementById(`spinner_comment_${edit_comment_id}`)) {
//             document.getElementById(
//               `spinner_comment_${edit_comment_id}`
//             ).style.display = "block";
//           }
//         } else {
//           if (document.getElementById(`src_reply_comment_${parent_id}`)) {
//             document.getElementById(
//               `src_reply_comment_${parent_id}`
//             ).style.display = "none";
//             if (document.getElementById(`spinner_reply_comment_${parent_id}`)) {
//               document.getElementById(
//                 `spinner_reply_comment_${parent_id}`
//               ).style.display = "block";
//             }
//           }
//         }
//       } else {
//         let id = Number(e.target.id.toString().replace("src_add_comment_", ""));
//         if (document.getElementById(`src_add_comment_${id}`)) {
//           document.getElementById(`src_add_comment_${id}`).style.display =
//             "none";
//         }
//         if (document.getElementById(`spinner_add_comment_${id}`)) {
//           document.getElementById(`spinner_add_comment_${id}`).style.display =
//             "block";
//         }
//         // x_axs -= 8;
//         // y_axs -= 19;
//       }

//       // setCommentsList(false);
//       bodyFormData.append("image_id", idSelected);
//       bodyFormData.append("x_axis", x_axs);
//       bodyFormData.append("y_axis", y_axs);
//       bodyFormData.append("comment", comment_val);
//       bodyFormData.append("perant_id", parent_id);
//       bodyFormData.append("comment_id", edit_comment_id);

//       request
//         .post(
//           apiURL + ADMIN_UPDATE_COMMENTS_TO_POST_COORDINATOR_IMAGE,
//           bodyFormData
//         )
//         .then((response) => {
//           setXAxis("");
//           setYAxis("");
//           setComments("");
//           //Set the success from the response
//           fetchComments(parent_id);
//           setCommentsList(true);
//           if (action === "add") {
//             // Hide all the add comment boxes
//             let elements = document.querySelectorAll(".addcomment");
//             for (let i = 0; i < elements.length; i++) {
//               elements[i].style.display = "none";
//             }
//           } else {
//             if (edit_comment_id > 0) {
//               setTimeout(() => {
//                 var existingComments = [...editComment];
//                 var commentIndex = existingComments.findIndex(
//                   (p) => p.comment_id == edit_comment_id
//                 );
//                 existingComments.splice(commentIndex, 1);
//                 setEditcomment(existingComments);
//               }, 700);
//               setTimeout(() => {
//                 showComments(index);
//               }, 800);
//             } else {
//               setTimeout(() => {
//                 if (document.getElementById(`src_reply_comment_${parent_id}`)) {
//                   document.getElementById(
//                     `src_reply_comment_${parent_id}`
//                   ).style.display = "block";
//                   if (
//                     document.getElementById(
//                       `spinner_reply_comment_${parent_id}`
//                     )
//                   ) {
//                     document.getElementById(
//                       `spinner_reply_comment_${parent_id}`
//                     ).style.display = "none";
//                   }
//                   if (document.getElementById(`comments_${index}`)) {
//                     document.getElementById(`comments_${index}`).style.display =
//                       "block";
//                   }
//                 }
//               }, 1000);
//             }
//           }
//         })
//         .catch((err) => {
//           let message =
//             typeof err.response !== "undefined"
//               ? err.response.data.message
//               : err.message;
//           setError(message);
//         });
//     }
//   };
//   useEffect(() => {
//     if (token && showCommentList) {
//       fetchComments();
//     }
//     setAnnotationsComments([]);
//     setAnnotations([]);
//     setActiveCommentIndex(-1);
//     // setdraggedElement(false);
//   }, [showCommentList]);

//   useEffect(() => {
//     setAnnotationsComments([]);
//     setAnnotations([]);
//     setActiveCommentIndex(-1);
//   }, [idSelected]);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (
//         inputRef.current &&
//         !inputRef.current.contains(event.target) &&
//         annotations.length === 1
//       ) {
//         // Clicked outside the input box, so clear the annotations
//         setAnnotations([]);
//         setToggleMessage(false);
//       }
//     };
//     // Add a click event listener to the document
//     document.addEventListener("click", handleClickOutside);

//     return () => {
//       // Remove the event listener when the component unmounts
//       document.removeEventListener("click", handleClickOutside);
//     };
//   }, [setAnnotations, annotations, setToggleMessage]);

//   /**
//    * Handles to return the first letter of the word.
//    * @param {String} sentence - The string of name.
//    * @returns {} return first letter of name.
//    */
//   function getFirstLetter(sentence) {
//     // Split the sentence into words
//     const words = sentence.trim().split(/\s+/);
//     if (words.length > 0) {
//       // Get the first word
//       const firstWord = words[0];
//       // Get the first character of the first word
//       const firstLetter = firstWord.charAt(0);
//       return firstLetter;
//     } else {
//       // Handle the case where the input sentence is empty
//       return null;
//     }
//   }

//   /**
//    * Handles to generate the date time with am/pm format.
//    * @param {Date} isoDateString - The date  of comment.
//    * @returns {Date} Returns the formatted date with am/pm.
//    */

//   const formatDateAMPM = (isoDateString) => {
//     const dateObject = new Date(isoDateString);
//     let hours = dateObject.getHours();
//     let minutes = dateObject.getMinutes();
//     const day = dateObject.getUTCDate();
//     const month = dateObject.getUTCMonth() + 1; // Months are 0-indexed
//     const year = dateObject.getUTCFullYear();
//     let ampm = hours >= 12 ? "pm" : "am";
//     hours = hours % 12;
//     hours = hours ? hours : 12; // the hour '0' should be '12'
//     minutes = minutes < 10 ? "0" + minutes : minutes;
//     let strTime = hours + ":" + minutes + ampm;
//     const formattedDate = `${day}/${month}/${year} ${strTime}`;
//     return formattedDate;
//   };

//   /**
//    * Handles to show the div based on click hover.
//    * @param {Id} id - The event id  of comment.
//    * @returns {} Returns the div based on id.
//    */
//   const showComments = (id) => {
//     if (dragging === true) {
//       return;
//     }
//     setComments("");
//     // Hide all the parent comment boxes
//     let hover_elements = document.querySelectorAll(".hover-show-comments");
//     for (let i = 0; i < hover_elements.length; i++) {
//       hover_elements[i].style.display = "none";
//     }
//     // Hide all the parent comment boxes
//     let parent_elements = document.querySelectorAll(".parent_comments");
//     for (let i = 0; i < parent_elements.length; i++) {
//       parent_elements[i].style.zIndex = 0;
//     }
//     // Hide all the comment boxes
//     let elements = document.querySelectorAll(".showcomments");
//     for (let i = 0; i < elements.length; i++) {
//       elements[i].style.display = "none";
//     }
//     // Hide all first letter  boxes
//     let calloutElements = document.querySelectorAll(".callout");
//     for (let i = 0; i < calloutElements.length; i++) {
//       calloutElements[i].style.display = "block";
//     }
//     // Hide all the add comment boxes
//     let addElements = document.querySelectorAll(".addcomment");
//     for (let i = 0; i < addElements.length; i++) {
//       addElements[i].style.display = "none";
//     }
//     hideCommentsTint(id);
//     setEditcomment([]);
//     document.getElementById(`parent_comment_holder_${id}`).style.zIndex = 1;
//     document.getElementById(`comment_name_${id}`).style.display = "block";
//     document.getElementById(`comments_${id}`).style.display = "block";
//     setTimeout(() => {
//       document.getElementById(`comments_hover_${id}`).style.display = "none";
//     }, 10);
//   };

//   /**
//    * Handles to show the div based on mouse hover.
//    * @param {Id} id - The event id  of comment.
//    * @returns {} Returns the div based on id.
//    */
//   const showCommentsTint = (id) => {
//     // Hide all the parent comment boxes
//     if (document.getElementById(`comments_${id}`).style.display !== "block") {
//       let parent_elements = document.querySelectorAll(".parent_comments");
//       for (let i = 0; i < parent_elements.length; i++) {
//         parent_elements[i].style.display = "block";
//         parent_elements[i].style.zIndex = 0;
//         document.getElementById(`comment_name_${i}`).style.display = "block";
//         document.getElementById(`comment_name_${i}`).style.zIndex = -1;
//         // document.getElementById(`comments_${i}`).style.display = "none";
//       }
//       // Hide all the hover comment boxes
//       let hover_elements = document.querySelectorAll(".hover-show-comments");
//       for (let i = 0; i < hover_elements.length; i++) {
//         hover_elements[i].style.display = "none";
//         parent_elements[i].style.zIndex = 0;
//       }
//       if (
//         document.getElementById(`parent_comment_holder_${activeCommentIndex}`)
//       ) {
//         document.getElementById(
//           `parent_comment_holder_${activeCommentIndex}`
//         ).style.zIndex = 11;
//       }
//       setTimeout(() => {
//         document.getElementById(
//           `parent_comment_holder_${id}`
//         ).style.zIndex = 111;
//         if (document.getElementById(`comment_name_${id}`)) {
//           document.getElementById(`comment_name_${id}`).style.zIndex = -1;
//         }
//         document.getElementById(`comments_hover_${id}`).style.zIndex = 9999999;
//         document.getElementById(`comments_hover_${id}`).style.display = "block";
//       }, 10);
//     }
//   };

//   /**
//    * Handles to show hide all the comments based on mouse out.
//    * @returns {} Hide all the the div based on class.
//    */
//   const hideCommentsTint = (id) => {
//     // Hide all the hover comment boxes
//     let hover_elements = document.querySelectorAll(".hover-show-comments");
//     for (let i = 0; i < hover_elements.length; i++) {
//       hover_elements[i].style.display = "none";
//       hover_elements[i].style.zIndex = 0;
//     }
//   };
//   /**
//    * Handles to hide the div based on id.
//    * @param {Id} id - The event id  of comment.
//    * @returns {} Hides the comment box based on id.
//    */
//   const hideCommentBox = (id) => {
//     document.getElementById(`comments_${id}`).style.display = "none";
//     let elements = document.querySelectorAll(".callout");
//     for (let i = 0; i < elements.length; i++) {
//       elements[i].style.display = "block";
//     }
//   };

//   /**
//    * Handles to hide the div based on id.
//    * @param {Id} id - The event id  of comment.
//    * @returns {} Hides the comment box based on id.
//    */
//   const hideAddCommentBox = (id) => {
//     document.getElementById(id).style.display = "none";
//   };

//   /**
//    * Handle the remove unwanted space from the string from the start.
//    * @param {String} str - String of user input.
//    * @returns {String} - Transformed removed space from the begining.
//    */
//   const ltrim = (str) => {
//     if (!str) return str;
//     return str.replace(/^\s+/g, "");
//   };

//   /**
//    * Handle the show the comment message icon.
//    * @param {Number} comment_id - comment id.
//    * @returns {String} - returns the send message icon based on message.
//    */
//   const getMessageIcon = () => {
//     return !comment ? send_message : send_message_blue;
//   };

//   /**
//    * Handle the change of show confirm box.
//    *  @param {Number} id - Set the status based on id.
//    * @returns {Boolean} - return and set the status of state to show/hide the confirm box.
//    */

//   const handleDeleteConfirmation = (id) => {
//     setDeleteId(id);
//     setShow(true);
//   };

//   const handleCommentDelete = () => {
//     if (deleteId > 0) {
//       request
//         .get(apiURL + ADMIN_DELETE_COMMENTS_COORDINATOR_IMAGE + `/${deleteId}`)
//         .then((response) => {
//           setModalShow(true);
//           // print the response
//           if (response.data.status) {
//             fetchComments();
//             setDeleteId("");
//             setShow(false);
//             setDeleteHeading("Success");
//           } else {
//             setDeleteHeading("Error");
//           }
//           setDeleteResponse(response.data.message);
//           // setTimeZone(response.data.gmt);
//         })
//         .catch((err) => {
//           console.log(err);
//           setDeleteResponse("Oops! Something went wrong!");
//         });
//     }
//   };

//   /**
//    * Handle the to set/unset the value in the array.
//    * @param {Number} comment_id - comment id.
//    * @param {String} comment - comment string.
//    * @returns {Object} - Returns Set/Unset number state object.
//    */

//   const setCommentId = (comment_id, comment) => {
//     const isExist = checkCommentExist(comment_id);
//     if (isExist) {
//       let existingComments = editComment;
//       let index = editComment.findIndex((p) => p.comment_id == comment_id);
//       existingComments[index].value = comment;
//       setEditcomment(existingComments);
//     } else {
//       setEditcomment([
//         ...editComment,
//         {
//           comment_id: comment_id,
//           value: comment,
//         },
//       ]);
//     }
//     setTimeout(() => {
//       let exising_value = document.getElementById(
//         `edit_comment_${comment_id}`
//       ).value;
//       document.getElementById(`edit_comment_${comment_id}`).value =
//         exising_value.trimStart()
//           ? exising_value
//           : comment.trimStart()
//           ? comment
//           : "";
//       let message_icon = document
//         .getElementById(`edit_comment_${comment_id}`)
//         .value.trimStart()
//         ? send_message_blue
//         : send_message;
//       document.getElementById(`edit_comment_img_${comment_id}`).src =
//         message_icon;
//     }, 20);
//   };

//   /**
//    * Handle the to get the x/y coordinates from element.
//    * @param {Object} element - element to be passed.
//    * @returns {Object} - Returns x/y coordinates from element.
//    */
//   const getMatrix = (element) => {
//     if (!element) {
//       return;
//     }
//     var transform = window
//       .getComputedStyle(element, null)
//       .getPropertyValue("-webkit-transform");
//     var results = transform.match(
//       /matrix(?:(3d)\(-{0,1}\d+(?:, -{0,1}\d+)*(?:, (-{0,1}\d+))(?:, (-{0,1}\d+))(?:, (-{0,1}\d+)), -{0,1}\d+\)|\(-{0,1}\d+(?:, -{0,1}\d+)*(?:, (-{0,1}.+))(?:, (-{0,1}.+))\))/
//     );

//     if (!results) return [0, 0, 0];
//     if (results[1] == "3d") return results.slice(2, 5);

//     results.push(0);
//     return results.slice(5, 8); // returns the [X,Y,Z,1] values
//   };

//   /**
//    * Handle the function to check array value exist or not .
//    * @param {Number} comment_id - comment id.
//    * @returns {Object} - return object based on condition.
//    */

//   const checkCommentExist = (comment_id) => {
//     return editComment.find((o) => o.comment_id === comment_id);
//   };

//   /**
//    * Handle the function to swt the edit comment.
//    * @param {Object} e - event object.
//    * @param {Object} annotation - annotation object.
//    * @returns {Object} - return object based on condition.
//    */
//   const setEditValue = (e, annotation) => {
//     setXAxis(annotation.x_axis);
//     setYAxis(annotation.y_axis);
//     setCommentId(
//       annotation.comment_id,
//       e.target.value.trimStart() ? e.target.value : ""
//     );
//     handleTextChange("");
//     if (e.target.value.trimStart()) {
//       setEditCommentValue(ltrim(e.target.value));
//     } else {
//       setEditCommentValue("");
//     }
//   };

//   const updateComment = (e) => {
//     setEditCommentValue("");
//     if (e.target.value.trimStart()) {
//       handleTextChange(ltrim(e.target.value));
//     } else {
//       handleTextChange("");
//     }
//   };

//   /**
//    * Handles to hide the particulat comment window.
//    * @param {Id} Number - The comment id.
//    * @returns {} Hide the comment window.
//    */

//   const hideEditCommentBox = (comment_id) => {
//     let existingComments = [...editComment];
//     let commentIndex = existingComments.findIndex(
//       (p) => p.comment_id == comment_id
//     );
//     existingComments.splice(commentIndex, 1);
//     setEditcomment(existingComments);
//   };

//   /**
//    * Handles the event on drag start.
//    * @param {Object} event - The event object of dragged element.
//    * @param {Number} index - The index of the element.
//    * @returns {} Get the x and y offset at the drag start.
//    */

//   const onStarteventHandler = (e, data) => {
//     e.stopPropagation();
//     e.preventDefault();
//     console.log("Clicked Element =", e.target.classList);
//     if (e.target.classList.contains("form-control")) {
//       e.target.focus();
//     }
//   };

//   const ondraggEventHandler = (e, data) => {
//     e.preventDefault();
//     setDragging(true);
//     setdraggedElement(false);
//     is_dragging = true;
//     console.log("Dragging...");
//     if (data.node.id.includes("parent_comment_holder_")) {
//       let element = document.getElementById(data.node.id);
//       if (element.hasAttribute("dragged")) {
//         element.removeAttribute("dragged");
//       }
//       // Adjust the Comment box height as per dragg
//       setCommentBoxMaxHeight(
//         data.node.id.replace("parent_comment_holder_", "")
//       );
//       // let id = data.node.id.replace("parent_comment_holder_", "");
//       // if (
//       //   document.getElementById(`comments_${id}`).style.display === "block" &&
//       //   !e.target.classList.contains("skip")
//       // ) {
//       //   setdraggedElement(true);
//       // } else {
//       //   setdraggedElement(false);
//       // }
//     } else {
//       setdraggedElement(false);
//     }

//     if (e.type === "mousemove" || e.type === "touchmove") {
//       setIsDragging(true);
//     }
//     // e.stopPropagation();
//     // e.preventDefault();
//     let element = document.getElementById(data.node.id);
//     let x = Number(element.style.left.replace("px", ""));
//     let y = Number(element.style.top.replace("px", ""));
//     if (Number(data.x) >= 0) {
//       x = x + Number(data.x);
//     } else {
//       x -= Math.abs(Number(data.x));
//     }

//     if (Number(data.y) >= 0) {
//       y = Number(y) + Number(data.y);
//     } else {
//       y -= Math.abs(Number(data.y));
//     }
//     setXAxis(x);
//     setYAxis(y);
//   };

//   /**
//    * Handles the event on drag complete.
//    * @param {Object} event - The event object of dragged element.
//    * @param {Object} data - The data of the element.
//    * @returns {} Get the x and y offset at the drag complete.
//    */
//   const onDraggComplete = (e, data) => {
//     e.preventDefault();
//     if (e.type === "mouseup" || e.type === "touchend") {
//       setTimeout(() => {
//         setIsDragging(false);
//       }, 100);
//     }
//     console.log({ e, data });
//     let element = document.getElementById(data.node.id);
//     let x = Number(element.style.left.replace("px", ""));
//     let y = Number(element.style.top.replace("px", ""));
//     if (Number(data.x) >= 0) {
//       x = x + Number(data.x);
//     } else {
//       x -= Math.abs(Number(data.x));
//     }

//     if (Number(data.y) >= 0) {
//       y = Number(y) + Number(data.y);
//     } else {
//       y -= Math.abs(Number(data.y));
//     }
//     setXAxis(x);
//     setYAxis(y);
//   };

//   /**
//    * Handles the event on drag complete.
//    * @param {Object} event - The event object of dragged element.
//    * @param {Object} data - The data of the element.
//    * @returns {} Get the x and y offset at the drag complete.
//    */
//   const StopEditCompleteEventHandler = (e, data) => {
//     if (dragging === false) {
//       // e.preventDefault();
//       return;
//     }
//     e.preventDefault();

//     if (is_dragging) {
//       // e.preventDefault();
//       console.log({ e, data });
//       // console.log("data in over => ", data);
//       setDragging(false);

//       if (e.type === "mouseup" || e.type === "touchend") {
//         setTimeout(() => {
//           setIsDragging(false);
//         }, 100);
//       }
//       let element = document.getElementById(data.node.id);
//       let element_id = Number(element.id.replace("parent_comment_holder_", ""));
//       // Adjust the Comment box height as per dragg
//       setCommentBoxMaxHeight(element_id);
//       let comment_id = element.getAttribute("db-id");
//       let x = Number(element.style.left.replace("px", ""));
//       let y = Number(element.style.top.replace("px", ""));
//       // if (
//       //   document.getElementById(`comments_${element_id}`).style.display !==
//       //   "block"
//       // ) {
//       if (!e.target.classList.contains("img-fluid-image-details--view")) {
//         if (Number(data.x) >= 0) {
//           x = x + Number(data.x);
//         } else {
//           x -= Math.abs(Number(data.x));
//         }
//         if (Number(data.y) >= 0) {
//           y = Number(y) + Number(data.y);
//         } else {
//           y -= Math.abs(Number(data.y));
//         }
//         let existingComments = [...annotationsComments];
//         let commentIndex = existingComments.findIndex(function (item) {
//           return item.comment_id == comment_id;
//         });
//         if (commentIndex !== -1) {
//           showCommentsTint(element_id);
//           updateXYCoordinates(
//             comment_id,
//             existingComments[commentIndex].comment,
//             x,
//             y
//           );
//           // setTimeout(() => {
//           //   element.classList.remove("react-draggable-dragged"); // Remove class
//           // }, 500);
//           // setdraggedElement((prevState) => [Number(comment_id), ...prevState]);
//           setTimeout(() => {
//             // element.style.transform = "translate3d(0px, 0px, 0px)";
//             // existingComments[commentIndex].x_axis = x;
//             // existingComments[commentIndex].y_axis = y;
//             // setAnnotationsComments(existingComments);
//           }, 10);
//         }
//       } else {
//         setdraggedElement(true);
//       }
//       is_dragging = false;
//     }

//     // }
//   };

//   /**
//    * Handles to left margin.
//    * @param {Object} object.
//    * @returns {} return margin based on string length.
//    */
//   const getThreadMargin = (object) => {
//     let left = 0;
//     const commentLength = object.comment_by.length;
//     if (commentLength < 7) {
//       left = 18;
//     } else if (commentLength >= 7 && commentLength <= 10) {
//       left = 16;
//     } else if (commentLength >= 11 && commentLength <= 14) {
//       left = 5;
//     } else if (commentLength > 13 && commentLength < 20) {
//       left = 5;
//     } else {
//       left = 2;
//     }
//     return left + "%";
//   };

//   /**
//    * Handles to left margin.
//    * @param {Object} object.
//    * @returns {} return margin based on string length.
//    */
//   const getMargin = (object) => {
//     let left = 11;
//     const commentLength = object.comment_by.length;
//     if (object.user_id === LoginUserId) {
//       if (commentLength < 7) {
//         left = 27;
//       } else if (commentLength >= 7 && commentLength <= 10) {
//         left = 25;
//       } else if (commentLength >= 11 && commentLength <= 14) {
//         left = 15;
//       } else if (commentLength > 14 && commentLength < 17) {
//         left = 11;
//       } else if (commentLength > 17 && commentLength < 20) {
//         left = 10;
//       } else {
//         left = 8;
//       }
//     }
//     return left + "%";
//   };

//   /**
// 	 * Handles to check the editor assign status/progress for task status.
// 	 * @param {String} status - The status String image status.
// 	 * @returns {} return the matching string or undefined if not matched.
// 	 */

// 	const checkEditorWorkProgress = (status) => {
// 		return editorWorkingStatus.find((item) => item == status);
// 	};

//   const generateMarkup = (annotation, index) => {
//     return (
//       <div
//         className="parent_comments"
//         id={`parent_comment_holder_${index}`}
//         db-id={annotation.comment_id}
//         key={annotation.x_axis + annotation.y_axis}
//         style={{
//           position: "absolute",
//           left: annotation.x_axis + styleType,
//           top: annotation.y_axis + styleType,
//           cursor: "pointer",
//           zIndex: 1,
//         }}
//       >
//         <div
//           className={
//             "callout" +
//             (activeCommentIndex === index ? " active" : "") +
//             (annotation.user_id !== LoginUserId ? " other_comment" : "")
//           }
//           onMouseEnter={(e) => showCommentsTint(index)}
//           id={`comment_name_${index}`}
//           style={{ zIndex: 1 }}
//         >
//           <div
//             className={
//               "firstletter " +
//               (annotation.user_id === LoginUserId
//                 ? "mycomment"
//                 : "other_comment")
//             }
//           >
//             {getFirstLetter(annotation.comment_by)}
//           </div>
//           {/* <div className="triangle-down-right"></div> */}
//           <div
//             className={
//               activeCommentIndex === index
//                 ? annotation.user_id === LoginUserId
//                   ? "my"
//                   : "other"
//                 : ""
//             }
//             style={{ marginTop: "-26px", marginLeft: "-30px" }}
//           >
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               version="1.1"
//               class="svg-triangle"
//               width="25"
//               height="25"
//             >
//               <polygon
//                 points="0,21 30,5 30,20"
//                 style={{
//                   fill: "#fff",
//                   stroke:
//                     annotation.user_id === LoginUserId ? "#6082b6" : "#639c2b",
//                   "stroke-dasharray": "1 0 12.8 0",
//                   "stroke-width": activeCommentIndex === index ? 1.7 : 0.9,
//                 }}
//               />
//             </svg>
//           </div>
//         </div>
//         <div
//           className={"showcomments"}
//           style={{ maxHeight: getCommentBoxMaxHeight(index) }}
//           id={`comments_${index}`}
//           onClick={(e) => {
//             // e.preventDefault();
//             // e.stopPropagation();
//           }}
//           onMouseDown={(e) => {
//             // e.stopPropagation();
//             e.preventDefault();
//           }}
//         >
//           <div>
//             <div>
//               <div
//                 className={
//                   "inner_first_letter skip " +
//                   (annotation.user_id === LoginUserId
//                     ? "mycomment"
//                     : "other_comment")
//                 }
//               >
//                 {getFirstLetter(annotation.comment_by)}
//               </div>
//               <span className="commented_by">
//                 <b>{annotation.comment_by}</b>
//               </span>

//               <div
//                 className="dd"
//                 style={{
//                   display: "inline-block",
//                   zIndex: "9",
//                   position: "relative",
//                   top: "15px",
//                   right: "18px",
//                   float: "right",
//                   // marginLeft: getThreadMargin(annotation),
//                   // Please ignore this line we need this function in future else will remove it
//                 }}
//                 key={"dd_" + index}
//               >
//                 {annotation.user_id === LoginUserId && !checkEditorWorkProgress(image_details_view.status) && (
//                   <div
//                     onClick={() => setDropDown((val) => !val)}
//                     className="img-fluid--icons skip"
//                     style={{
//                       justifyContent: "center",
//                       cursor: "pointer",
//                       position: "absolute",
//                     }}
//                     id="dropdownMenuButton"
//                     data-toggle="dropdown"
//                     aria-haspopup="true"
//                     aria-expanded="false"
//                   >
//                     <img
//                       src={MoreIcon}
//                       alt="mindreach images"
//                       className="add_more_options skip"
//                     />
//                   </div>
//                 )}
//                 <>
//                   <img
//                     style={{
//                       marginLeft:
//                         annotation.user_id === LoginUserId ? "18px" : "33px",
//                     }}
//                     src={cross}
//                     alt="mindreach images"
//                     className="add_close_comment skip"
//                     onClick={() => {
//                       hideCommentBox(index);
//                       setActiveCommentIndex(-1);
//                     }}
//                   />
//                 </>
//                 {annotation.user_id === LoginUserId && (
//                   <div
//                     className="dropdown-menu skip"
//                     aria-labelledby="dropdownMenuButton"
//                   >
//                     <a
//                       className="dropdown-item skip"
//                       style={{
//                         color: "black",
//                         fontSize: "0.8rem",
//                         padding: "0.25rem 1.5rem",
//                       }}
//                       onClick={() => {
//                         setCommentId(annotation.comment_id, annotation.comment);
//                       }}
//                     >
//                       Edit Comment
//                     </a>
//                     <a
//                       className="dropdown-item skip"
//                       style={{
//                         color: "black",
//                         fontSize: "0.8rem",
//                         padding: "0.25rem 1.5rem",
//                       }}
//                       onClick={() => {
//                         handleDeleteConfirmation(annotation.comment_id);
//                         setDeleteCommentType("Delete Thread");
//                         setDeleteCommentTypeDetails("thread");
//                         setDeleteIndex(-1);
//                       }}
//                     >
//                       Delete Thread
//                     </a>
//                   </div>
//                 )}
//               </div>
//             </div>
//             <div className="comment_date">
//               {formatDateAMPM(annotation.commented_at)}
//             </div>
//             {!checkCommentExist(annotation.comment_id) ? (
//               <div
//                 className="comment_details mt-3"
//                 id={`comment_${annotation.comment_id}`}
//               >
//                 {annotation.comment}
//               </div>
//             ) : (
//               <form
//                 className="comment_edit mt-3"
//                 onSubmit={(e) => {
//                   submitCommentForm(
//                     e,
//                     "edit",
//                     Number(0),
//                     annotation.comment_id,
//                     annotation,
//                     index
//                   );
//                 }}
//               >
//                 <Form.Control
//                   type="text"
//                   autoComplete="off"
//                   placeholder="Edit Comment"
//                   autoFocus
//                   onChange={(e) => {
//                     setEditValue(e, annotation);
//                   }}
//                   id={`edit_comment_${annotation.comment_id}`}
//                 />
//                 <img
//                   id={`edit_comment_img_${annotation.comment_id}`}
//                   src={
//                     checkCommentExist(annotation.comment_id) &&
//                     checkCommentExist(annotation.comment_id).value
//                       ? send_message_blue
//                       : send_message
//                   }
//                   className="trigger skip"
//                   onClick={(e) => {
//                     submitCommentForm(
//                       e,
//                       "edit",
//                       Number(0),
//                       annotation.comment_id,
//                       annotation,
//                       index
//                     );
//                   }}
//                   style={{ display: "block" }}
//                 />
//                 <ImageLoader id={`spinner_comment_${annotation.comment_id}`} />
//                 <img
//                   style={{}}
//                   src={cross}
//                   alt="mindreach images"
//                   className="edit_close_comment skip"
//                   onClick={(e) => {
//                     hideEditCommentBox(annotation.comment_id);
//                   }}
//                 />
//               </form>
//             )}
//             {annotation.reply &&
//               annotation.reply.length > 0 &&
//               annotation.reply.map((replyObj) => {
//                 return (
//                   <>
//                     <div>
//                       <div
//                         className={
//                           "inner_first_letter " +
//                           (replyObj.user_id === LoginUserId ? "mycomment" : "")
//                         }
//                       >
//                         {getFirstLetter(replyObj.comment_by)}
//                       </div>
//                       <span className="commented_by">
//                         <b>{replyObj.comment_by}</b>
//                       </span>
//                       {replyObj.user_id === LoginUserId && !checkEditorWorkProgress(image_details_view.status) && (
//                         <>
//                           <div
//                             className=" dd"
//                             style={{
//                               position: "initital",
//                               display: "inline-block",
//                               marginLeft: "10%",
//                             }}
//                             key={"dd_" + index}
//                           >
//                             <div
//                               onClick={() => setDropDown((val) => !val)}
//                               className="img-fluid--icons skip"
//                               style={{
//                                 justifyContent: "center",
//                                 cursor: "pointer",
//                                 position: "initial",
//                                 height: "36px",
//                               }}
//                               id="dropdownMenuButton"
//                               data-toggle="dropdown"
//                               aria-haspopup="true"
//                               aria-expanded="false"
//                             >
//                               <img
//                                 src={MoreIcon}
//                                 alt="mindreach images"
//                                 className="add_more_options skip"
//                                 style={{ marginLeft: "4px" }}
//                               />
//                             </div>

//                             <div
//                               className="dropdown-menu skip"
//                               aria-labelledby="dropdownMenuButton"
//                             >
//                               <a
//                                 className="dropdown-item skip"
//                                 style={{
//                                   color: "black",
//                                   fontSize: "0.8rem",
//                                   padding: "0.25rem 1.5rem",
//                                 }}
//                                 onClick={() => {
//                                   setCommentId(
//                                     replyObj.comment_id,
//                                     replyObj.comment
//                                   );
//                                 }}
//                               >
//                                 Edit
//                               </a>
//                               <a
//                                 className="dropdown-item"
//                                 style={{
//                                   color: "black",
//                                   fontSize: "0.8rem",
//                                   padding: "0.25rem 1.5rem",
//                                 }}
//                                 onClick={() => {
//                                   handleDeleteConfirmation(replyObj.comment_id);
//                                   setDeleteCommentType("Delete Comment");
//                                   setDeleteCommentTypeDetails("comment");
//                                   setDeleteIndex(index);
//                                 }}
//                               >
//                                 Delete
//                               </a>
//                             </div>
//                           </div>
//                         </>
//                       )}
//                     </div>
//                     <div className="comment_date">
//                       {formatDateAMPM(replyObj.commented_at)}
//                     </div>
//                     {!checkCommentExist(replyObj.comment_id) ? (
//                       <div
//                         className="comment_details mt-3"
//                         id={`comment_${replyObj.comment_id}`}
//                       >
//                         {replyObj.comment}
//                       </div>
//                     ) : (
//                       <form
//                         className="comment_edit mt-3"
//                         onSubmit={(e) => {
//                           submitCommentForm(
//                             e,
//                             "edit",
//                             annotation.comment_id,
//                             replyObj.comment_id,
//                             replyObj,
//                             index
//                           );
//                         }}
//                       >
//                         <Form.Control
//                           type="text"
//                           autoComplete="off"
//                           placeholder="Edit Comment"
//                           autoFocus
//                           onChange={(e) => {
//                             setEditValue(e, replyObj);
//                           }}
//                           id={`edit_comment_${replyObj.comment_id}`}
//                         />
//                         <img
//                           id={`edit_comment_img_${replyObj.comment_id}`}
//                           src={
//                             checkCommentExist(replyObj.comment_id) &&
//                             checkCommentExist(replyObj.comment_id).value
//                               ? send_message_blue
//                               : send_message
//                           }
//                           className="trigger skip"
//                           onClick={(e) => {
//                             submitCommentForm(
//                               e,
//                               "edit",
//                               annotation.comment_id,
//                               replyObj.comment_id,
//                               replyObj,
//                               index
//                             );
//                           }}
//                         />
//                         <ImageLoader
//                           id={`spinner_comment_${replyObj.comment_id}`}
//                         />
//                         <img
//                           style={{}}
//                           src={cross}
//                           alt="mindreach images"
//                           className="edit_close_comment"
//                           onClick={(e) => {
//                             hideEditCommentBox(replyObj.comment_id);
//                           }}
//                         />
//                       </form>
//                     )}
//                   </>
//                 );
//               })}
//               {
//                 image_details_view &&
//                 !checkEditorWorkProgress(image_details_view.status) && (
//                 <div className="comment_add" style={{ marginTop: "15px" }}>
//                   <form
//                     onSubmit={(e) => {
//                       submitCommentForm(
//                         e,
//                         "edit",
//                         annotation.comment_id,
//                         Number(0),
//                         annotation,
//                         index
//                       );
//                     }}
//                   >
//                     <Form.Control
//                       type="text"
//                       autoComplete="off"
//                       placeholder="Reply"
//                       style={{ width: "90%" }}
//                       value={comment}
//                       autoFocus
//                       onChange={(e) => {
//                         updateComment(e);
//                         setXAxis(annotation.x_axis);
//                         setYAxis(annotation.y_axis);
//                       }}
//                       onClick={(e) => {
//                         e.currentTarget.focus();
//                       }}
//                       className="skip"
//                     />
//                     <img
//                       id={`src_reply_comment_${annotation.comment_id}`}
//                       src={getMessageIcon()}
//                       className="trigger skip"
//                       onClick={(e) => {
//                         submitCommentForm(
//                           e,
//                           "edit",
//                           annotation.comment_id,
//                           Number(0),
//                           annotation,
//                           index
//                         );
//                       }}
//                     />
//                     <ImageLoader
//                       id={`spinner_reply_comment_${annotation.comment_id}`}
//                     />
//                   </form>
//                 </div>
//              )}
//             {/* <div className="comment-triangle-down-right"></div> */}
//           </div>
//         </div>
//         <div
//           className={"hover-show-comments"}
//           id={`comments_hover_${index}`}
//           onMouseLeave={() => hideCommentsTint(index)}
//           onClick={(e) => {
//             e.stopPropagation();
//             e.preventDefault();
//             if (!isDragging) {
//               setActiveCommentIndex(index);
//               setdraggedElement(false);
//               setDragging(false);
//               showComments(index);
//               return;
//             }
//             return;
//           }}
//           onMouseDown={(e) => {
//             // e.stopPropagation();
//             e.preventDefault();
//           }}
//         >
//           <div>
//             <div style={{ paddingTop: "2px" }}>
//               <div
//                 className={
//                   "inner_first_letter" +
//                   (annotation.user_id === LoginUserId ? " mycomment" : "")
//                 }
//                 style={{ marginLeft: "12px" }}
//               >
//                 {getFirstLetter(annotation.comment_by)}
//               </div>
//               <span className="commented_by" style={{ fontSize: "14px" }}>
//                 <b>{annotation.comment_by}</b>
//               </span>
//               <p className="comm_date" style={{}}>
//                 {" "}
//                 {formatDateAMPM(annotation.commented_at)}
//               </p>
//             </div>
//             <div
//               className="comment_details mt-2"
//               style={{ marginLeft: "3.1rem", paddingBottom: "10px" }}
//             >
//               {annotation.comment}
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   };
//   // console.log("annotations", annotations, idSelected);
//   return (
//     <div
//       style={{
//         zIndex: 998,
//       }}
//     >
//       <div
//         style={{
//           position: "relative",
//           display: "inline-block",
//           maxWidth: "100%",
//           maxHeight: "100%",
//         }}
//         id={"image_holder"}
//       >
//         <PanViewer
//           style={{
//             width: "100%",
//             height: "100%",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             zIndex: 1,
//           }}
//           zoom={zoom}
//           setZoom={handleWheel}
//           pandx={dx}
//           pandy={dy}
//           key={dx}
//         >
//           <img
//             variant="top"
//             layout="fill"
//             objectFit="contain"
//             className="img-fluid-image-details--view"
//             src={imageURL}
//             alt="Annotated Image"
//             onClick={showCommentList && !checkEditorWorkProgress(image_details_view.status) ? handleImageClick : ""}
//             style={{ maxWidth: "100%" }}
//             ref={imageRefZoom}
//             onMouseDown={handleMouseDown}
//             onMouseUp={handleMouseUp}
//             onMouseMove={handleMouseMove}
//           />
//         </PanViewer>
//         {annotations !== undefined &&
//           toggleMessage === true &&
//           annotations.map((annotation, index) => (
//             <>
//               <Draggable
//                 key={"add_c" + annotation.x + annotation.y}
//                 onDrag={ondraggEventHandler}
//                 onStart={onStarteventHandler}
//                 onStop={onDraggComplete}
//                 bounds="parent"
//               >
//                 <div
//                   key={annotation.x + annotation.y}
//                   style={{
//                     position: "absolute",
//                     left: annotation.x + styleType,
//                     top: annotation.y + styleType,
//                     cursor: "pointer",
//                     zIndex: 1,
//                   }}
//                   id={`add_comment_parent_${index}`}
//                   className={`add_comment_parent`}
//                   // onClick={() => setCurrentAnnotation(annotation)}
//                 >
//                   {" "}
//                   <div className="">
//                     <div
//                       id={`addcomment_${index}`}
//                       className={
//                         "addcomment" +
//                         (defaultPosition > 640 ? " move_right" : "")
//                       }
//                     >
//                       <div>
//                         <div style={{ paddingTop: "17px" }}>
//                           <div className=""></div>
//                           <span
//                             className="commented_by"
//                             style={{ marginLeft: "22px", fontSize: "15px" }}
//                           >
//                             <b>Add Comment</b>
//                           </span>

//                           <div
//                             className=" dd"
//                             style={{
//                               position: "initital",
//                               display: "inline-block",
//                               marginLeft: "23%",
//                             }}
//                             key={"dd_" + index}
//                           >
//                             <>
//                               <img
//                                 src={cross}
//                                 alt="mindreach images"
//                                 className="add_close_comment"
//                                 onClick={() => {
//                                   hideAddCommentBox(`addcomment_${index}`);
//                                 }}
//                               />
//                             </>
//                             <div
//                               className="dropdown-menu"
//                               aria-labelledby="dropdownMenuButton"
//                             >
//                               <a
//                                 className="dropdown-item"
//                                 style={{
//                                   color: "black",
//                                   fontSize: "0.8rem",
//                                   padding: "0.25rem 1.5rem",
//                                 }}
//                                 onClick={() => handleDeleteConfirmation(true)}
//                               >
//                                 Delete
//                               </a>
//                             </div>
//                           </div>
//                         </div>

//                         <div className="comment_details mt-3"></div>
//                         <div className="comment_add mt-4">
//                           <form
//                             onSubmit={(e) => {
//                               submitCommentForm(e, "add");
//                             }}
//                             id={index}
//                           >
//                             <Form.Control
//                               type="text"
//                               autoComplete="off"
//                               placeholder="Reply"
//                               style={{ width: "90%" }}
//                               value={comment}
//                               autoFocus
//                               onChange={(e) => {
//                                 updateComment(e);
//                               }}
//                               className="skip"
//                             />
//                             <img
//                               id={`src_add_comment_${index}`}
//                               src={getMessageIcon()}
//                               className="trigger "
//                               onClick={(e) => {
//                                 submitCommentForm(e, "add");
//                               }}
//                             />
//                             <ImageLoader id={`spinner_add_comment_${index}`} />
//                           </form>
//                         </div>
//                       </div>
//                       {defaultPosition < 640 ? (
//                         <div className="addcomment-triangle-down-left"></div>
//                       ) : (
//                         <div className="addcomment-triangle-down-right"></div>
//                       )}
//                     </div>
//                   </div>
//                 </div>
//               </Draggable>
//             </>
//           ))}

//         {annotationsComments !== undefined && showCommentList === true ? (
//           <>
//             {annotationsComments.map((annotation, index) => (
//               <>
//                 <Draggable
//                   allowAnyClick={true}
//                   // offsetParent="image_holder"
//                   disabled={annotation.user_id !== LoginUserId || checkEditorWorkProgress(image_details_view.status)}
//                   // handle=`comments_hover_` + index
//                   onDrag={ondraggEventHandler}
//                   onStart={onStarteventHandler}
//                   onStop={StopEditCompleteEventHandler}
//                   // bounds="parent"
//                   position={
//                     draggedElement ||
//                     (document.getElementById(
//                       `parent_comment_holder_${index}`
//                     ) &&
//                       document
//                         .getElementById(`parent_comment_holder_${index}`)
//                         .hasAttribute("dragged"))
//                       ? { x: 0, y: 0 }
//                       : undefined
//                   }
//                 >
//                   {generateMarkup(annotation, index)}
//                 </Draggable>
//               </>
//             ))}
//           </>
//         ) : (
//           <>{/* <Loader /> */}</>
//         )}
//       </div>
//       <DeleteModalConfirmation
//         show={show}
//         onHide={() => {
//           setShow(false);
//         }}
//         branch={deleteCommentTypeDetails}
//         name={""}
//         title={deleteCommentType}
//         handleConfirm={() => handleCommentDelete()}
//       />
//       <MyVerticallyCenteredModal
//         show={modalShow}
//         onHide={() => {
//           setModalShow(false);
//         }}
//         heading={deleteHeading}
//         message={deleteResponse}
//         className="modal-body-body--success"
//       />
//     </div>
//   );
// };

// export default ImageAnnotation;
import React, { useState, useEffect, useRef, useCallback } from "react";
import Draggable from "react-draggable";
// import NextImage from "next/image"; // Rename the Next.js image component
import PanViewer from "../../../../../components/PanViewer";
import Form from "react-bootstrap/Form";

// import { PanViewer } from "react-image-pan-zoom-rotate";

import cross from "./../../../../../assets/Images/cross-3.svg";
import send_message from "./../../../../../assets/Images/send-message.svg";
import send_message_blue from "./../../../../../assets/Images/send-messge-blue.svg";
import MoreIcon from "./../../../../../assets/Images/coordinator_actions_icon/more-Final.svg";
import Bg from "./../../../../../assets/Images/orders/transparent.jpg";
import Thumbnail from "./../../../../../assets/Images/orders/thumbnail.svg";

import ImageLoader from "./../../../../../components/ImageLoader";
import MyVerticallyCenteredModal from "../../../../../components/Modal";
import DeleteModalConfirmation from "../../../../../components/Modal/DeleteModal";

import { client } from "./../../../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "./../../../../../data/filepath";

import {
	// CUSTOMER_GET_COMMENTS_IMAGE,
	// CUSTOMER_UPDATE_COMMENTS_TO_POST_IMAGE,
	// CUSTOMER_DELETE_COMMENTS_IMAGE,
	ADMIN_GET_COMMENTS_TO_VIEW_COORDINATOR_IMAGE,
	ADMIN_UPDATE_COMMENTS_TO_POST_COORDINATOR_IMAGE,
	ADMIN_DELETE_COMMENTS_COORDINATOR_IMAGE,
} from "./../../../../../config/apiRoutes.api";
var is_dragging = false;
let drag = false;
// Default max height for comment box
let standard_top_position = 580;

let containerWidth = 800; // Set your image container width
let containerHeight = "800"; // Set your image container height
let scrollPosition = 10; // Set the default position to check if the mouse is scrolling to up or down position
// 1 is down and -1 is for up position

function ImageAnnotation({
	imageURL,
	startDrawing,
	rectangleDrawing,
	selectedColor,
	image_url,
	setImageUrl,
	backgroundRemovalColorCode,
	setBackgroundRemoval,
	// canvasRef,
	image_details_view,
	setCurrentImage,
	currentImage,
	setTransformPosition,
	defaultTransformPosition,
	isDrawing,
	snapShotRef,
	isDragging,
	setIsDragging,
	idSelected,
	annotations,
	setAnnotations,
	setToggleMessage,
	toggleMessage,
	setCommentsList,
	showCommentList,
	annotationsComments,
	setAnnotationsComments,
	setIsDrawingCompleted,
	isDrawingCompleted,
	token,
	setZoomPercentage,
	zoomPercentage,
	zoom,
	setZoom,
	activeTab,
	dx,
	dy,
	setDy,
	setDx,
	drawingMode,
	setDrawingMode,
	isDraggingCanvas,
	setIsDraggingCanvas,
	setCommentsMarker,
	commentsMarker,
	setCommentsAddSections,
	commemntsAddSections,
	setCommentId,
	commentsId,
	overlayLayerActive,
	setOverlayLayerActive,
	path,
	getFileExtension,
}) {
	// Create a FormData object for request payload
	const bodyFormData = new FormData();

	const canvasRef = useRef(null);
	const request = client(token);
	const ctxRef = useRef(null);

	const imageRef = useRef(null);
	// const [dx, setDx] = useState(0);
	// const [dy, setDy] = useState(0);

	const [panning, setPanning] = useState(false);
	const [panStart, setPanStart] = useState({ x: 0, y: 0 });
	const imageRefZoom = useRef(null);
	const startPosition = useRef({ x: 0, y: 0 });

	const inputRef = useRef(null);
	const [rectangleWidthBox, setRectagleWidthBox] = useState("");
	const [rectangleHeightBox, setRectangleHegithBox] = useState("");
	const [rotation, setRotation] = useState(0);

	// const [drawings, setDrawings] = useState([]); // State to store drawings

	const [textInput, setTextInput] = useState(""); // State to store text input
	const [position, setPosition] = useState({ x: 0, y: 0 });
	const [flip, setFlip] = React.useState(false);

	const [dragging, setDragging] = useState(false);
	const [localDragging, setLocalDragging] = useState(false);
	const [draggedElement, setdraggedElement] = useState(false);
	const [defaultPosition, setDefaultPosition] = useState(false);
	const [deleteIndex, setDeleteIndex] = useState(-1);
	const roleJson = sessionStorage.getItem("role");
	const role = roleJson ? JSON.parse(roleJson) : null;
	const currentUserId = [role !== null ? role.id : "login"];
	const LoginUserId = role !== null && role.pivot ? role.pivot.user_id : "";
	const [error, setError] = useState("");
	const [x_axis, setXAxis] = useState("");
	const [y_axis, setYAxis] = useState("");
	console.log("startPosition", defaultTransformPosition);
	const [comment, setComments] = useState("");
	const [editCommentValue, setEditCommentValue] = useState("");
	const [dropDown, setDropDown] = useState(false);
	const [deleteId, setDeleteId] = useState("");
	const styleType = "px";
	// const styleType = "%";
	const [show, setShow] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [deleteResponse, setDeleteResponse] = useState("");
	const [deleteHeading, setDeleteHeading] = useState("");
	const [deleteCommentType, setDeleteCommentType] = useState("Delete Comment");
	const [deleteCommentTypeDetails, setDeleteCommentTypeDetails] =
		useState("comment");
	const [editComment, setEditcomment] = useState([]);
	const [activeCommentIndex, setActiveCommentIndex] = useState(-1);

	const supportedExtensions = [
		".psd",
		".psb",
		".tiff",
		".tif",
		".nef",
		".fig",
		".cr2",
		".cr3",
		".ai",
		".otf",
		".ttf",
		".eip",
	];

	const isSupported = supportedExtensions.some(
		(ext) => path !== undefined && path.toLowerCase().endsWith(ext)
	);

	const handleWheel = useCallback(
		(e) => {
			if (!e) e = window.event;
			if (e.stopPropagation) e.stopPropagation();
			if (e.preventDefault) e.preventDefault();

			if (e.altKey) {
				// Zoom in/out when the Alt key is held while scrolling

				// Define minimum and maximum zoom values
				const minZoom = 0.5; // 50%
				const maxZoom = 3.0; // 300%
				const increment = 0.01; // 10% increment

				setZoom((prevZoom) => {
					const deltaZoom = e.deltaY > 0 ? -increment : increment;
					let newZoom = prevZoom + deltaZoom;

					// Ensure that the newZoom is within the bounds
					newZoom = Math.max(minZoom, Math.min(maxZoom, newZoom));

					// Map the newZoom value to the percentage range 50% to 300%
					const zoomPercentage = Math.round(
						((newZoom - minZoom) / (maxZoom - minZoom)) * 250 + 50
					);

					// Display the zoom percentage using a toast notification
					setZoomPercentage(`${zoomPercentage}%`);

					return newZoom;
				});

				setIsDragging(false);
				setRotation(0);
				setFlip(false);
			} else {
				// Handle scroll position when Alt key is not held
				const scrollPosition = Math.sign(e.deltaY);
			}
		},
		[] // Dependencies removed, since zoomPercentage and zoom are now handled within the state setter
	);

	const handleKeyUp = (e) => {
		if (e.key === "Alt") {
			// Reset zoom when the Alt key is released
			// setZoom(1);
			setIsDragging(false);
			setFlip(false);
		}
	};

	// Clean up the event listeners when the component is unmounted
	useEffect(() => {
		if (zoom !== 0.500000000000042) {
			document.addEventListener("wheel", handleWheel);
			document.addEventListener("keyup", handleKeyUp);
		} else if (zoom === 0.500000000000042) {
			setZoom(1.189999999999966);
			setZoomPercentage("88%");
		}

		return () => {
			if (zoom !== 0.500000000000042) {
				document.removeEventListener("wheel", handleWheel);
				document.removeEventListener("keyup", handleKeyUp);
			}
		};
	}, [zoom]);

	/**
	 * Handles to return the number from the string.
	 * @param {String} string.
	 * @returns {} returns the numbers from string.
	 */

	const getNumbers = (string) => {
		let numb = string.match(/\d/g);
		return Number(numb.join(""));
	};

	/**
	 * Handles all the elements based on selector class.
	 * @param {String} selector class.
	 * @param {String} display hide/show.
	 * @returns {JSX.Element} Hide/Show all the elements based on display and selector.
	 */
	const showHideElement = (selector, display) => {
		document.querySelectorAll(`.${selector}`).forEach((element) => {
			element.style.display = display;
		});
	};

	/**
	 * Handles to parent element top css position.
	 * @param {Number} index.
	 * @returns {} return the top css.
	 */

	const getElementTopPosition = (index) => {
		const element = document.getElementById(`parent_comment_holder_${index}`);
		if (element) {
			return getNumbers(element.style.top);
		}
		return 0;
	};

	/**
	 * Handle the to get the x/y coordinates from element.
	 * @param {Object} element - element to be passed.
	 * @returns {Object} - Returns x/y coordinates from element.
	 */
	const getMatrixStyle = (element) => {
		// if (!element) {
		// 	return;
		// }
		const transform = element.style.transform;
		return transform
			.toString()
			.replace("matrix(", "")
			.replace(")", "")
			.split(","); // returns the [X,Y,Z,1] values
	};

	/**
	 * Handle the to get the x/y coordinates from element.
	 * @param {Object} element - element to be passed.
	 * @returns {Object} - Returns x/y coordinates from element.
	 */
	const getMatrix = (element) => {
		if (!element) {
			return;
		}
		const transform = window
			.getComputedStyle(element, null)
			.getPropertyValue("-webkit-transform");
		const results = transform.match(
			/matrix(?:(3d)\(-{0,1}\d+(?:, -{0,1}\d+)*(?:, (-{0,1}\d+))(?:, (-{0,1}\d+))(?:, (-{0,1}\d+)), -{0,1}\d+\)|\(-{0,1}\d+(?:, -{0,1}\d+)*(?:, (-{0,1}.+))(?:, (-{0,1}.+))\))/
		);

		if (!results) return [0, 0, 0];
		if (results[1] == "3d") {
			return results.slice(2, 5);
		}

		results.push(0);
		return results.slice(5, 8); // returns the [X,Y,Z,1] values
	};

	/**
	 * Handles to set max height.
	 * @param {Number} index.
	 * @returns {} return the max height.
	 */
	const setCommentBoxMaxHeight = (index) => {
		setTimeout(() => {
			let top = standard_top_position;
			const element_top = getElementTopPosition(index);
			const matrixCSS = getMatrix(
				document.getElementById(`parent_comment_holder_${index}`)
			);
			if (matrixCSS && matrixCSS.length > 1) {
				if (matrixCSS[1] > -1) {
					top = top - element_top - matrixCSS[1];
				} else {
					top = top - element_top + Math.abs(matrixCSS[1]);
				}
				document.getElementById(
					`comments_${index}`
				).style.maxHeight = `${top}px`;
			}
		}, 10);
	};

	/**
	 * Handles to get max height.
	 * @param {Number} index.
	 * @returns {} return the max height.
	 */
	const getCommentBoxMaxHeight = (index) => {
		return standard_top_position - getElementTopPosition(index);
	};

	useEffect(() => {
		const handleSpaceBarPress = (event) => {
			if (event.keyCode === 32) {
				// setIsDragging(true);
				setPanStart({ x: event.clientX, y: event.clientY });
			} else {
				// setIsDragging(false);
			}
		};

		const handleSpaceBarRelease = (event) => {
			if (event.keyCode === 32) {
				// setIsDragging(false);
				// setZoom(1);
			}
		};

		document.addEventListener("keydown", handleSpaceBarPress, {
			passive: false,
		});

		document.addEventListener("keyup", handleSpaceBarRelease);

		return () => {
			document.removeEventListener("keydown", handleSpaceBarPress);
			document.removeEventListener("keyup", handleSpaceBarRelease);
			// setIsDragging(false);
			setBackgroundRemoval("#ffffff");
		};
	}, [setIsDragging, setPanning, setPanStart, setBackgroundRemoval]);

	const svgContent = `
	<svg xmlns="http://www.w3.org/2000/svg" width="900" height="900">
	  <circle cx="50" cy="50" r="40" stroke="black" stroke-width="3" fill="red" />
	</svg>`;
	// Throttle function to control redraw rate
	const throttle = (callback, limit) => {
		let waiting = false;
		return function (...args) {
			if (!waiting) {
				callback.apply(this, args);
				waiting = true;
				setTimeout(() => (waiting = false), limit);
			}
		};
	};
	let currentDrawing = null;
	// let linesData = [...commentsMarker];
	const canvas = document.getElementById("zoom_canvas");
	const modal = document.querySelector(".container-fluid");
	const ctx = canvas !== null && canvas.getContext("2d");
	const image = new Image();
	let newWidth, newHeight;
	let selectedSquareId = null; // Store the currently selected square's ID
	let mouseMoveHandler;

	const redrawCanvas = useCallback(() => {
		if (drawingMode !== "square") return;
		commentsMarker.forEach((line) => {
			if (line.type === "pen") {
				// Drawing pen lines
				ctx.beginPath();
				line.points.forEach((point, index) => {
					if (index === 0) {
						ctx.moveTo(point.x, point.y);
					} else {
						ctx.lineTo(point.x, point.y);
					}
				});
				ctx.stroke();
			} else if (line.type === "circle") {
				// Drawing circles
				ctx.beginPath();
				ctx.arc(line.center.x, line.center.y, line.radius, 0, Math.PI * 2);
				ctx.stroke();
			} else if (line.type === "square") {
				console.log(
					`Drawing square with comment_id ${line.comment_id} and id ${line.id}`
				);

				// Get color based on the current selection
				const fillColor = getColorByCommentId(line.comment_id, commentsId);
				console.log(`Filling square with color: ${fillColor}`);

				// Set the fill style and fill the square
				ctx.fillStyle = fillColor;
				ctx.fillRect(line.x_axis, line.y_axis, line.width, line.height); // Fill the square

				// Stroke the square border
				ctx.beginPath();
				ctx.rect(line.x_axis, line.y_axis, line.width, line.height);
				ctx.stroke();
			} else if (line.type === "line") {
				// Drawing straight lines
				ctx.beginPath();
				ctx.moveTo(line.startX, line.startY);
				ctx.lineTo(line.endX, line.endY);
				ctx.stroke();
			}
		});
	}, [commentsMarker, commentsId, commemntsAddSections]);
	const handleSquareClick = throttle((comment_id) => {
		if (activeTab === "square") {
			// Update the selected square ID on click
			if (selectedSquareId === comment_id) {
				// If the square is already selected, deselect it
				selectedSquareId = null;
			} else {
				selectedSquareId = comment_id; // Select the clicked square
			}

			// Redraw the canvas to reflect the new selection
			redrawCanvas();
		}
		return;
	}, 50);
	function handleCanvasClick(event) {
		const rect = canvas.getBoundingClientRect();
		const x = event.clientX - rect.left;
		const y = event.clientY - rect.top;

		// ctx.clearRect(0, 0, canvas.width, canvas.height);
		// ctx.drawImage(image, 0, 0, newWidth, newHeight);
		// ctx.strokeStyle = "red";
		// ctx.lineWidth = 2;

		// Check if the click is inside any square
		let squareClicked = false;
		Array.isArray(commentsMarker) &&
			commentsMarker.forEach((line) => {
				if (line.type === "square") {
					if (
						x >= line.x_axis &&
						x <= line.x_axis + line.width &&
						y >= line.y_axis &&
						y <= line.y_axis + line.height
					) {
						// Click is inside this square
						console.log("Square clicked:", line);
						handleSquareClick(line.comment_id);
						squareClicked = true;
					}
				}
			});
	}

	const getColorByCommentId = (comment_id, id) => {
		console.log("Comparing comment_id:", comment_id, " with id:", id);

		const defaultColor = "rgba(255, 255, 0, 0.01)"; // Default color for unselected squares
		const selectedColor = "rgba(255, 165, 0, 0.3)"; // Orange color for the selected square

		// Check if the square is selected
		if (comment_id === commentsId) {
			return selectedColor; // Highlight the selected square
		} else {
			return defaultColor; // Return default color for unselected squares
		}
	};
	const thumbnailReturn = ({ image, path }) => {
		// Check if the image path is a TIFF file
		if (
			path.toLowerCase().endsWith(".tif") ||
			path.toLowerCase().endsWith(".tiff")
		) {
			// Return the thumbnail URL for TIFF images
			return `https://fastly.picsum.photos/id/1005/200/300.jpg?hmac=ZygrmRTuNYz9HivXcWqFGXDRVJxIHzaS-8MA0I3NKBw`;
		} else {
			// Return the default thumbnail URL for other image types or cases
			return image;
		}
	};

	useEffect(() => {
		if (activeTab === "square" && !isSupported) {
			const backgroundImage = new Image();
			backgroundImage.crossOrigin = "*";
			backgroundImage.src = Bg;
			let dragging = false;
			let drawing = false;
			let overlayActive = false; // Flag to control the dark overlay
			let animationFrameId = null;

			let imgX = 0,
				imgY = 0;

			let startX, startY;

			// Handle mouseup and mouseleave events
			const stopDragging = () => {
				dragging = false;
				// ctx.clearRect(0, 0, canvas.width, canvas.height);
				// ctx.drawImage(image, imgX, imgY, canvas.width, canvas.height);
				drawing = false;
			};

			// let linesData = [...savedLinesData];
			// setCommentsMarker(linesData);

			// Set canvas dimensions as needed
			backgroundImage.onload = () => {
				const pattern = ctx.createPattern(backgroundImage, "repeat");
				ctx.fillStyle = pattern;

				canvas.width = image.naturalWidth * 2;
				canvas.height = image.naturalHeight * 2;

				ctx.fillRect(0, 0, canvas.width, canvas.height);
			};

			const handleMouseMove = throttle((e) => {
				if (drawingMode === "square" && drawing && !dragging) {
					dragging = false;
					console.log("square called");

					modal.removeEventListener("mousemove", handleMouseMove);
					// Update the canvas by clearing and redrawing the image at the new position
					ctx.clearRect(0, 0, canvas.width, canvas.height);
					ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
				}
			}, 50); // Throttling mousemove to improve performance;
			const handleMouseDown = throttle((e) => {
				if (drawingMode === "square" && !dragging) {
					const rect = modal.getBoundingClientRect();
					startX = e.clientX - rect.left - imgX;
					startY = e.clientY - rect.top - imgY;
					dragging = false; // Enable dragging
				}
			}, 50);

			// Load image and set up events
			if (drawingMode === "square") {
				// dragging = false;
				// modal.removeEventListener("mousedown", handleMouseDown);
				// modal.removeEventListener("mousemove", handleMouseMove);

				const image = new Image();
				image.crossOrigin = "*";

				if (isSupported) {
					// Return the thumbnail URL for TIFF images
					image.src = `${Thumbnail.src}`;
				} else {
					// Return the default thumbnail URL for other image types or cases
					image.src = `${imageURL}`;
				}

				let x_axis = 0;
				let y_axis = 0;

				let imageDrawn = false;

				const getMousePos = (e) => {
					const rect = canvas.getBoundingClientRect();
					return {
						x: e.clientX - rect.left,
						y: e.clientY - rect.top,
					};
				};

				canvas.addEventListener("click", (event) => {
					handleCanvasClick(event);
				});

				image.onload = () => {
					const imgWidthCanvas = image.naturalWidth;
					const imgHeightCanvas = image.naturalHeight;

					const maxWidth = 600;
					const maxHeight = 500;

					let scalingFactor = Math.min(
						maxWidth / imgWidthCanvas,
						maxHeight / imgHeightCanvas
					);

					newWidth = imgWidthCanvas * scalingFactor;
					newHeight = imgHeightCanvas * scalingFactor;

					canvas.width = newWidth;
					canvas.height = newHeight;

					ctx.clearRect(0, 0, canvas.width, canvas.height);
					ctx.drawImage(image, 0, 0, newWidth, newHeight);
					ctx.strokeStyle = "red";
					ctx.lineWidth = 2;

					imageDrawn = true;

					redrawCanvas();

					function handleMouseMove(e) {
						if (!drawing) return;
						dragging = false;

						const pos = getMousePos(e);

						ctx.clearRect(0, 0, canvas.width, canvas.height);
						ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

						if (!currentDrawing) {
							currentDrawing = {};
							// setCommentsAddSections(false);
						}

						if (
							currentDrawing.width > 1 &&
							currentDrawing.height > 1 &&
							currentDrawing.height < 0
						) {
							setCommentsAddSections(true);
						}
						if (!currentDrawing.width && !currentDrawing.height) {
							setCommentsAddSections(false);
						}

						// Handle different drawing modes
						switch (drawingMode) {
							case "pen":
								ctx.lineTo(pos.x, pos.y);
								ctx.stroke();
								if (currentDrawing.type === "pen") {
									currentDrawing.points.push({ x: pos.x, y: pos.y });
								}
								break;

							case "circle":
								const dx = pos.x - currentDrawing.x_axis;
								const dy = pos.y - currentDrawing.y_axis;
								const radius = Math.sqrt(dx * dx + dy * dy);
								ctx.beginPath();
								ctx.arc(
									currentDrawing.x_axis,
									currentDrawing.y_axis,
									radius,
									0,
									Math.PI * 2
								);
								ctx.stroke();
								currentDrawing.radius = radius;
								currentDrawing.center = {
									x: currentDrawing.x_axis,
									y: currentDrawing.y_axis,
								};
								break;

							case "square":
								if (overlayActive) {
									ctx.fillStyle = "rgba(0, 0, 0, 0.7)";
									ctx.fillRect(0, 0, canvas.width, canvas.height);
								}
								const width = pos.x - currentDrawing.x_axis;
								const height = pos.y - currentDrawing.y_axis;
								ctx.fillStyle = "rgba(255, 255, 255, 0.21)";
								ctx.beginPath();
								ctx.rect(
									currentDrawing.x_axis,
									currentDrawing.y_axis,
									width,
									height
								);
								ctx.fill();
								ctx.stroke();
								currentDrawing.width = width;
								currentDrawing.height = height;
								currentDrawing.backgroundColor = "#ffffff00";
								setCommentsAddSections(true);
								break;

							case "line":
								ctx.beginPath();
								ctx.moveTo(currentDrawing.x_axis, currentDrawing.y_axis);
								ctx.lineTo(pos.x, pos.y);
								ctx.stroke();
								currentDrawing.endX = pos.x;
								currentDrawing.endY = pos.y;
								break;
						}

						redrawCanvas();
					}

					canvas.addEventListener("mousedown", (e) => {
						if (!imageDrawn) return;
						ctx.clearRect(0, 0, canvas.width, canvas.height);
						ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

						const pos = getMousePos(e);
						drawing = true;
						overlayActive = true;
						x_axis = pos.x;
						y_axis = pos.y;

						if (drawingMode === "pen") {
							currentDrawing = {
								type: "pen",
								points: [{ x: x_axis, y: y_axis }],
							};
						} else if (
							drawingMode === "circle" ||
							drawingMode === "square" ||
							drawingMode === "line"
						) {
							currentDrawing = { type: drawingMode, x_axis, y_axis };
						}
					});

					canvas.addEventListener("mousemove", throttle(handleMouseMove));

					canvas.addEventListener("mouseup", () => {
						drawing = false;
						if (!commentsMarker.length) return;

						if (currentDrawing) {
							setCommentsMarker((prevCommentsMarker) => {
								// Create a new array to avoid mutating the previous state
								return [...prevCommentsMarker, currentDrawing];
							});

							// Reset the current drawing after adding
							currentDrawing = null;
						}

						// ctx.fillStyle = "rgba(255, 255, 255, 0.1)";
						// ctx.clearRect(0, 0, canvas.width, canvas.height);
						// ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
						setCommentsMarker([]);

						// sessionStorage.setItem("linesData", JSON.stringify(linesData));
						setCommentsMarker((prev) => {
							const combinedData = [prev, ...commentsMarker];

							// Create a map to track unique objects based on `comment_id`
							const uniqueComments = new Map();

							combinedData.forEach((comment) => {
								// Assuming `comment_id` is the unique identifier
								uniqueComments.set(comment.comment_id, comment);
							});

							// Convert the map values back into an array
							return Array.from(uniqueComments.values());
						});
					});

					// Detect click outside canvas
					document.addEventListener("click", (e) => {
						if (!canvas.contains(e.target)) {
							// redrawImageWithoutOverlay();
							redrawCanvas();
						}
					});
				};
			}
			canvas.removeEventListener("mousemove", mouseMoveHandler);

			return () => {
				modal.removeEventListener("mousedown", handleMouseDown);
				modal.removeEventListener("mousemove", handleMouseMove);
				modal.removeEventListener("mouseup", stopDragging);
				modal.removeEventListener("mouseleave", stopDragging);
				canvas.removeEventListener("mousemove", mouseMoveHandler);
			};
		}
	}, [
		imageURL,
		setCommentsMarker,
		commentsId,
		// linesData,
		!commemntsAddSections,
		commentsMarker,
		// redrawCanvas,
	]);

	useEffect(() => {
		if (activeTab === "drag" && !isSupported) {
			currentDrawing = null;
			setCommentsMarker([]);

			const canvas = document.getElementById("zoom_canvas");
			const modal = document.querySelector(".container-fluid");
			const ctx = canvas.getContext("2d");
			const image = new Image();
			const backgroundImage = new Image();
			backgroundImage.crossOrigin = "*";
			backgroundImage.src = Bg;
			let dragging = false;
			let drawing = false;
			let overlayActive = false; // Flag to control the dark overlay
			let animationFrameId = null;
			// let currentDrawing = null;
			let imgX = 0,
				imgY = 0;

			let startX, startY;
			canvas.removeEventListener("mousemove", mouseMoveHandler);

			// Handle mouseup and mouseleave events
			const stopDragging = () => {
				dragging = false;
				ctx.clearRect(0, 0, canvas.width, canvas.height);
				ctx.drawImage(image, imgX, imgY, canvas.width, canvas.height);
				drawing = false;
			};

			// Set canvas dimensions as needed
			backgroundImage.onload = () => {
				const pattern = ctx.createPattern(backgroundImage, "repeat");
				ctx.fillStyle = pattern;

				canvas.width = image.naturalWidth * 2;
				canvas.height = image.naturalHeight * 2;

				ctx.fillRect(0, 0, canvas.width, canvas.height);
			};

			const drawImageEvent = (newWidth, newHeight) => {
				ctx.fillStyle = "#ffffff00";
				ctx.clearRect(0, 0, canvas.width, canvas.height);
				ctx.fillRect(0, 0, canvas.width, canvas.height);
				ctx.drawImage(image, imgX, imgY, newWidth, newHeight);
			};

			const handleEvent = (e, newWidth, newHeight) => {
				if (drawingMode === "square" && !dragging) {
					dragging = false;
					console.log("square called");

					// modal.removeEventListener("mousemove", handleMouseMove);
				} else if (drawingMode === "drag" && dragging) {
					const rect = modal.getBoundingClientRect();
					imgX = e.clientX - rect.left - x_axis;
					imgY = e.clientY - rect.top - y_axis;
					currentDrawing = null;
					// dragging = true;

					if (!animationFrameId) {
						animationFrameId = requestAnimationFrame(() => {
							drawImageEvent(newWidth, newHeight);
							animationFrameId = null;
						});
						console.log("animations called", drawingMode);
					}
				}
			};

			// if (!squareClicked) {
			// 	// Hide the overlay if no square is clicked
			// 	const overlay = document.getElementById("overlay");
			// 	overlay.style.display = "none";
			// }
			// });

			const handleMouseMove = (e) => {
				if (dragging && drawingMode === "drag") {
					currentDrawing = null;
					console.log("call the canvas");
					const rect = modal.getBoundingClientRect();
					const mouseX = e.clientX - rect.left;
					const mouseY = e.clientY - rect.top;

					// Calculate the new position for the image
					const dx = mouseX - startX;
					const dy = mouseY - startY;

					// Update image position
					imgX += dx;
					imgY += dy;

					// Update the canvas by clearing and redrawing the image at the new position
					ctx.clearRect(0, 0, canvas.width, canvas.height);
					ctx.drawImage(image, imgX, imgY, canvas.width, canvas.height);

					// Update the start position for the next move
					startX = mouseX;
					startY = mouseY;
				}
			};
			const handleMouseDown = (e) => {
				if (drawingMode === "drag") {
					const rect = modal.getBoundingClientRect();
					startX = e.clientX - rect.left - imgX;
					startY = e.clientY - rect.top - imgY;
					dragging = true; // Enable dragging
					currentDrawing = null;
					ctx.clearRect(0, 0, canvas.width, canvas.height);
					ctx.drawImage(image, imgX, imgY, canvas.width, canvas.height);
					drawing = false;
				}
			};

			// Load image and set up events
			if (activeTab === "drag") {
				currentDrawing = null;
				drawing = false;
				// dragging = false;

				image.crossOrigin = "*";
				if (isSupported) {
					// Return the thumbnail URL for TIFF images
					image.src = `${Thumbnail.src}`;
				} else {
					// Return the default thumbnail URL for other image types or cases
					image.src = `${imageURL}`;
				}
				ctx.beginPath();
				ctx.clearRect(0, 0, canvas.width, canvas.height);

				image.onload = () => {
					const imgWidthCanvas = image.naturalWidth;
					const imgHeightCanvas = image.naturalHeight;

					const maxWidth = 600;
					const maxHeight = 500;

					let scalingFactor = Math.min(
						maxWidth / imgWidthCanvas,
						maxHeight / imgHeightCanvas
					);
					const newWidth = imgWidthCanvas * scalingFactor;
					const newHeight = imgHeightCanvas * scalingFactor;

					canvas.width = newWidth;
					canvas.height = newHeight;
					// ctx.fillStyle = "#ffffff00";

					const compressAndResizeImage = () => {
						const compressionCanvas = document.createElement("canvas");
						const compressionCtx = compressionCanvas.getContext("2d");

						compressionCanvas.width = newWidth;
						compressionCanvas.height = newHeight;

						compressionCtx.fillStyle = "#ffffff00";
						compressionCtx.drawImage(image, 0, 0, newWidth, newHeight);

						return new Promise((resolve) => {
							compressionCanvas.toBlob(
								(blob) => {
									const compressedImageURL = URL.createObjectURL(blob);
									resolve(compressedImageURL);
								},
								"image/jpeg",
								0.7
							);
						});
					};

					compressAndResizeImage().then((compressedImageURL) => {
						image.src = compressedImageURL;

						if (modal) {
							modal.addEventListener("mousedown", handleMouseDown);
							modal.addEventListener("mousemove", handleMouseMove);
							modal.addEventListener("mouseup", stopDragging);
							modal.addEventListener("mouseleave", stopDragging);
							modal.addEventListener("click", (e) => {
								if (!canvas.contains(e.target)) {
									ctx.clearRect(0, 0, canvas.width, canvas.height);
									ctx.drawImage(image, imgX, imgY, canvas.width, canvas.height);
									drawing = false;
									dragging = false;
									currentDrawing = false;
								}
							});

							image.onload = () => {
								ctx.drawImage(image, imgX, imgY, canvas.width, canvas.height);
								currentDrawing = false;
							};
						}
					});
				};
			}

			return () => {
				modal.removeEventListener("mousedown", handleMouseDown);
				modal.removeEventListener("mousemove", handleMouseMove);
				modal.removeEventListener("mouseup", stopDragging);
				modal.removeEventListener("mouseleave", stopDragging);
				canvas.removeEventListener("mousemove", mouseMoveHandler);
				// cancelAnimationFrame(animationFrameId);
			};
		}
	}, [activeTab, drawingMode, imageURL, !currentDrawing]);

	const fetchComments = (setdrag = false) => {
		if (token) {
			request
				.get(
					apiURL +
						ADMIN_GET_COMMENTS_TO_VIEW_COORDINATOR_IMAGE +
						`/${idSelected}`
				)
				.then((response) => {
					//Set the success from the response
					setAnnotationsComments([]);
					setIsDrawingCompleted(false);
					setAnnotationsComments(response.data.comments);
					if (setdrag) {
						let existingComments = [...annotationsComments];
						let commentIndex = existingComments.findIndex(function (item) {
							return item.comment_id == setdrag;
						});
						if (commentIndex >= 0) {
							let element = document.getElementById(
								`parent_comment_holder_${commentIndex}`
							);
							element.setAttribute("dragged", "completed");
						}
						setdraggedElement(true);
					}
					if (deleteIndex >= 0) {
						setTimeout(() => {
							showComments(deleteIndex);
						}, 20);
					}
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};

	const hideAllAddCommentBox = () => {
		// Hide all the add comment boxes
		let elements = document.querySelectorAll(".addcomment");
		elements.forEach((element) => (element.style.display = "none"));
	};

	/**
	 * Handles to update the  the comment x_axis and y_axis.
	 * @param {Number} comment_id .
	 * @returns {JSX.Element} Submit the form and post the data with API.
	 */
	const updateXYCoordinates = (comment_id, comment_val, x_axis, y_axis) => {
		if (
			token &&
			comment_id > 0 &&
			comment_val &&
			Math.abs(x_axis) >= 0 &&
			Math.abs(y_axis) >= 0
		) {
			bodyFormData.append("image_id", idSelected);
			bodyFormData.append("x_axis", x_axis);
			bodyFormData.append("y_axis", y_axis);
			bodyFormData.append("comment", comment_val);
			bodyFormData.append("perant_id", Number(0));
			bodyFormData.append("comment_id", comment_id);
			request
				.post(
					apiURL + ADMIN_UPDATE_COMMENTS_TO_POST_COORDINATOR_IMAGE,
					bodyFormData
				)
				.then((response) => {
					setXAxis("");
					setYAxis("");
					setComments("");
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};

	const submitCommentForm = (
		e,
		action = "edit",
		parent_id = Number(0),
		edit_comment_id = Number(0),
		annotation = {},
		index = 0
	) => {
		let comment_val = "";
		if (action === "edit") {
			comment_val =
				edit_comment_id > 0 &&
				document.getElementById(`edit_comment_${edit_comment_id}`)
					? document.getElementById(`edit_comment_${edit_comment_id}`).value
					: comment;
		} else {
			comment_val = comment;
		}

		e.preventDefault();
		let x_axs = !x_axis ? annotation.x_axis : x_axis;
		let y_axs = !y_axis ? annotation.y_axis : y_axis;

		if (token && idSelected && x_axs >= 0 && y_axs >= 0 && comment_val) {
			setDeleteIndex(-1);
			if (action === "edit") {
				if (document.getElementById(`edit_comment_img_${edit_comment_id}`)) {
					document.getElementById(
						`edit_comment_img_${edit_comment_id}`
					).style.display = "none";
					if (document.getElementById(`spinner_comment_${edit_comment_id}`)) {
						document.getElementById(
							`spinner_comment_${edit_comment_id}`
						).style.display = "block";
					}
				} else {
					if (document.getElementById(`src_reply_comment_${parent_id}`)) {
						document.getElementById(
							`src_reply_comment_${parent_id}`
						).style.display = "none";
						if (document.getElementById(`spinner_reply_comment_${parent_id}`)) {
							document.getElementById(
								`spinner_reply_comment_${parent_id}`
							).style.display = "block";
						}
					}
				}
			} else {
				let id = Number(e.target.id.toString().replace("src_add_comment_", ""));
				if (document.getElementById(`src_add_comment_${id}`)) {
					document.getElementById(`src_add_comment_${id}`).style.display =
						"none";
				}
				if (document.getElementById(`spinner_add_comment_${id}`)) {
					document.getElementById(`spinner_add_comment_${id}`).style.display =
						"block";
				}
				// x_axs -= 8;
				// y_axs -= 19;
			}

			// setCommentsList(false);
			bodyFormData.append("image_id", idSelected);
			bodyFormData.append("x_axis", x_axs);
			bodyFormData.append("y_axis", y_axs);
			bodyFormData.append("comment", comment_val);
			bodyFormData.append("perant_id", parent_id);
			bodyFormData.append("comment_id", edit_comment_id);

			request
				.post(
					apiURL + ADMIN_UPDATE_COMMENTS_TO_POST_COORDINATOR_IMAGE,
					bodyFormData
				)
				.then((response) => {
					setXAxis("");
					setYAxis("");
					setComments("");
					//Set the success from the response
					fetchComments(parent_id);
					setCommentsList(true);
					if (action === "add") {
						// Hide all the add comment boxes
						let elements = document.querySelectorAll(".addcomment");
						elements.forEach((element) => {
							element.style.display = "none";
						});
					} else {
						if (edit_comment_id > 0) {
							setTimeout(() => {
								var existingComments = [...editComment];
								var commentIndex = existingComments.findIndex(
									(p) => p.comment_id == edit_comment_id
								);
								existingComments.splice(commentIndex, 1);
								setEditcomment(existingComments);
							}, 700);
							setTimeout(() => {
								showComments(index);
							}, 800);
						} else {
							setTimeout(() => {
								if (document.getElementById(`src_reply_comment_${parent_id}`)) {
									document.getElementById(
										`src_reply_comment_${parent_id}`
									).style.display = "block";
									if (
										document.getElementById(
											`spinner_reply_comment_${parent_id}`
										)
									) {
										document.getElementById(
											`spinner_reply_comment_${parent_id}`
										).style.display = "none";
									}
									if (document.getElementById(`comments_${index}`)) {
										document.getElementById(`comments_${index}`).style.display =
											"block";
									}
								}
							}, 1000);
						}
					}
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	useEffect(() => {
		if (token && showCommentList) {
			fetchComments();
		}
		setAnnotationsComments([]);
		setAnnotations([]);
		setActiveCommentIndex(-1);
		// setdraggedElement(false);
	}, [showCommentList]);

	useEffect(() => {
		setAnnotationsComments([]);
		setAnnotations([]);
		setActiveCommentIndex(-1);
		setCommentsList(false);
	}, [idSelected]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				inputRef.current &&
				!inputRef.current.contains(event.target) &&
				annotations.length === 1
			) {
				// Clicked outside the input box, so clear the annotations
				// setAnnotations([]);
				// setToggleMessage(false);
			}
		};
		// Add a click event listener to the document
		document.addEventListener("click", handleClickOutside);
		return () => {
			// Remove the event listener when the component unmounts
			document.removeEventListener("click", handleClickOutside);
		};
	}, [setAnnotations, annotations, setToggleMessage]);

	/**
	 * Handles to return the first letter of the word.
	 * @param {String} sentence - The string of name.
	 * @returns {} return first letter of name.
	 */
	function getFirstLetter(sentence) {
		// Split the sentence into words
		const words = sentence.trim().split(/\s+/);
		if (words.length > 0) {
			// Get the first word
			const firstWord = words[0];
			// Get the first character of the first word
			const firstLetter = firstWord.charAt(0);
			return firstLetter;
		} else {
			// Handle the case where the input sentence is empty
			return null;
		}
	}

	/**
	 * Handles to generate the date time with am/pm format.
	 * @param {Date} isoDateString - The date  of comment.
	 * @returns {Date} Returns the formatted date with am/pm.
	 */

	const formatDateAMPM = (isoDateString) => {
		const dateObject = new Date(isoDateString);
		let hours = dateObject.getHours();
		let minutes = dateObject.getMinutes();
		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1; // Months are 0-indexed
		const year = dateObject.getUTCFullYear();
		let ampm = hours >= 12 ? "pm" : "am";
		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		minutes = minutes < 10 ? "0" + minutes : minutes;
		let strTime = hours + ":" + minutes + ampm;
		const formattedDate = `${day}/${month}/${year} ${strTime}`;
		return formattedDate;
	};

	/**
	 * Handles to show the div based on click hover.
	 * @param {Id} id - The event id  of comment.
	 * @returns {} Returns the div based on id.
	 */
	const showComments = (id) => {
		if (dragging === true) {
			return;
		}
		setComments("");
		// Hide all the parent comment boxes
		// let hover_elements = document.querySelectorAll(".hover-show-comments");
		// for (let i = 0; i < hover_elements.length; i++) {
		//   hover_elements[i].style.display = "none";
		// }
		// Hide all the parent comment boxes
		let parent_elements = document.querySelectorAll(".parent_comments");
		for (let i = 0; i < parent_elements.length; i++) {
			parent_elements[i].style.zIndex = 0;
		}
		// Hide all the comment boxes
		// let elements = document.querySelectorAll(".showcomments");
		// for (let i = 0; i < elements.length; i++) {
		//   elements[i].style.display = "none";
		// }
		// Hide all first letter  boxes
		let calloutElements = document.querySelectorAll(".callout");
		for (let i = 0; i < calloutElements.length; i++) {
			calloutElements[i].style.display = "block";
		}
		// Hide all the add comment boxes
		let addElements = document.querySelectorAll(".addcomment");
		for (let i = 0; i < addElements.length; i++) {
			addElements[i].style.display = "none";
		}
		setEditcomment([]);
		document.getElementById(`parent_comment_holder_${id}`).style.zIndex = 1;
		document.getElementById(`comment_name_${id}`).style.display = "block";
		document.getElementById(`comments_${id}`).style.display = "block";
		setTimeout(() => {
			document.getElementById(`comments_hover_${id}`).style.display = "none";
		}, 10);
	};

	/**
	 * Handles to show the div based on mouse hover.
	 * @param {Id} id - The event id  of comment.
	 * @returns {} Returns the div based on id.
	 */
	const showCommentsTint = (id) => {
		// Hide all the parent comment boxes
		if (document.getElementById(`comments_${id}`).style.display !== "block") {
			let parent_elements = document.querySelectorAll(".parent_comments");
			parent_elements.forEach((element, i) => {
				element.style.display = "block";
				element.style.zIndex = 0;
				let comment_name_box = document.getElementById(`comment_name_${i}`);
				if (comment_name_box) {
					comment_name_box.style.display = "block";
					comment_name_box.style.zIndex = -1;
				}
			});

			let activeCommentInfo = document.getElementById(
				`parent_comment_holder_${activeCommentIndex}`
			);

			if (activeCommentInfo) {
				activeCommentInfo.style.zIndex = 11;
			}
			setTimeout(() => {
				let parent_comment_holder = document.getElementById(
					`parent_comment_holder_${id}`
				);
				if (parent_comment_holder) {
					parent_comment_holder.style.zIndex = 111;
				}
				let comment_name_box = document.getElementById(`comment_name_${id}`);
				let comment_hover_box = document.getElementById(`comments_hover_${id}`);
				if (comment_name_box) {
					comment_name_box.style.zIndex = -1;
				}
				if (comment_name_box) {
					comment_hover_box.style.zIndex = 9999;
					comment_hover_box.style.display = "block";
				}
			}, 10);
		}
	};

	/**
	 * Handles to show hide all the comments based on mouse out.
	 * @returns {} Hide all the the div based on class.
	 */
	const hideCommentsTint = (id) => {
		// Hide all the hover comment boxes
		let hover_elements = document.querySelectorAll(".hover-show-comments");
		for (let i = 0; i < hover_elements.length; i++) {
			hover_elements[i].style.display = "none";
			hover_elements[i].style.zIndex = 0;
		}
	};
	/**
	 * Handles to hide the div based on id.
	 * @param {Id} id - The event id  of comment.
	 * @returns {} Hides the comment box based on id.
	 */
	const hideCommentBox = (id) => {
		document.getElementById(`comments_${id}`).style.display = "none";
		let elements = document.querySelectorAll(".callout");
		for (let i = 0; i < elements.length; i++) {
			elements[i].style.display = "block";
		}
	};

	/**
	 * Handles to hide the div based on id.
	 * @param {Id} id - The event id  of comment.
	 * @returns {} Hides the comment box based on id.
	 */
	const hideAddCommentBox = (id) => {
		document.getElementById(id).style.display = "none";
	};

	/**
	 * Handle the remove unwanted space from the string from the start.
	 * @param {String} str - String of user input.
	 * @returns {String} - Transformed removed space from the begining.
	 */
	const ltrim = (str) => {
		if (!str) return str;
		return str.replace(/^\s+/g, "");
	};

	/**
	 * Handle the show the comment message icon.
	 * @param {Number} comment_id - comment id.
	 * @returns {String} - returns the send message icon based on message.
	 */
	const getMessageIcon = () => {
		return !comment ? send_message : send_message_blue;
	};

	/**
	 * Handle the change of show confirm box.
	 *  @param {Number} id - Set the status based on id.
	 * @returns {Boolean} - return and set the status of state to show/hide the confirm box.
	 */

	const handleDeleteConfirmation = (id) => {
		setDeleteId(id);
		setShow(true);
	};

	const handleCommentDelete = () => {
		if (deleteId > 0) {
			request
				.get(apiURL + ADMIN_DELETE_COMMENTS_COORDINATOR_IMAGE + `/${deleteId}`)
				.then((response) => {
					setModalShow(true);
					// print the response
					if (response.data.status) {
						fetchComments();
						setDeleteId("");
						setShow(false);
						setDeleteHeading("Success");
					} else {
						setDeleteHeading("Error");
					}
					setDeleteResponse(response.data.message);
				})
				.catch((err) => {
					console.log(err);
					setDeleteResponse("Oops! Something went wrong!");
				});
		}
	};

	/**
	 * Handle the to set/unset the value in the array.
	 * @param {Number} comment_id - comment id.
	 * @param {String} comment - comment string.
	 * @returns {Object} - Returns Set/Unset number state object.
	 */

	// const setCommentId = (comment_id, comment) => {
	// 	const isExist = checkCommentExist(comment_id);
	// 	if (isExist) {
	// 		let existingComments = editComment;
	// 		let index = editComment.findIndex((p) => p.comment_id == comment_id);
	// 		existingComments[index].value = comment;
	// 		setEditcomment(existingComments);
	// 	} else {
	// 		setEditcomment([
	// 			...editComment,
	// 			{
	// 				comment_id: comment_id,
	// 				value: comment,
	// 			},
	// 		]);
	// 	}
	// 	setTimeout(() => {
	// 		let exising_value = document.getElementById(
	// 			`edit_comment_${comment_id}`
	// 		).value;
	// 		document.getElementById(`edit_comment_${comment_id}`).value =
	// 			exising_value.trimStart()
	// 				? exising_value
	// 				: comment.trimStart()
	// 				? comment
	// 				: "";
	// 		let message_icon = document
	// 			.getElementById(`edit_comment_${comment_id}`)
	// 			.value.trimStart()
	// 			? send_message_blue
	// 			: send_message;
	// 		document.getElementById(`edit_comment_img_${comment_id}`).src =
	// 			message_icon;
	// 	}, 20);
	// };

	const handleTextChange = (updatedText) => {
		setComments(updatedText);
	};

	/**
	 * Handle the function to check array value exist or not .
	 * @param {Number} comment_id - comment id.
	 * @returns {Object} - return object based on condition.
	 */

	const checkCommentExist = (comment_id) => {
		return editComment.find((o) => o.comment_id === comment_id);
	};

	/**
	 * Handle the function to swt the edit comment.
	 * @param {Object} e - event object.
	 * @param {Object} annotation - annotation object.
	 * @returns {Object} - return object based on condition.
	 */
	// const setEditValue = (e, annotation) => {
	// 	setXAxis(annotation.x_axis);
	// 	setYAxis(annotation.y_axis);
	// 	setCommentId(
	// 		annotation.comment_id,
	// 		e.target.value.trimStart() ? e.target.value : ""
	// 	);
	// 	handleTextChange("");
	// 	if (e.target.value.trimStart()) {
	// 		setEditCommentValue(ltrim(e.target.value));
	// 	} else {
	// 		setEditCommentValue("");
	// 	}
	// };

	const updateComment = (e) => {
		setEditCommentValue("");
		if (e.target.value.trimStart()) {
			handleTextChange(ltrim(e.target.value));
		} else {
			handleTextChange("");
		}
	};

	/**
	 * Handles to hide the particulat comment window.
	 * @param {Id} Number - The comment id.
	 * @returns {} Hide the comment window.
	 */

	const hideEditCommentBox = (comment_id) => {
		let existingComments = [...editComment];
		let commentIndex = existingComments.findIndex(
			(p) => p.comment_id == comment_id
		);
		existingComments.splice(commentIndex, 1);
		setEditcomment(existingComments);
	};

	/**
	 * Handles the event on drag start.
	 * @param {Object} event - The event object of dragged element.
	 * @param {Number} index - The index of the element.
	 * @returns {} Get the x and y offset at the drag start.
	 */

	const onStarteventHandler = (e, data) => {
		e.stopPropagation();
		e.preventDefault();
		if (e.target.classList.contains("form-control")) {
			e.target.focus();
		}
	};

	const ondraggEventHandler = (e, data) => {
		e.stopPropagation();
		e.preventDefault();
		setDragging(true);
		setdraggedElement(false);
		is_dragging = true;
		console.log("Dragging...");
		if (data.node.id.includes("parent_comment_holder_")) {
			let element = document.getElementById(data.node.id);
			if (element.hasAttribute("dragged")) {
				element.removeAttribute("dragged");
			}
			// Adjust the Comment box height as per dragg
			setCommentBoxMaxHeight(
				data.node.id.replace("parent_comment_holder_", "")
			);
		} else {
			setdraggedElement(false);
		}

		if (e.type === "mousemove" || e.type === "touchmove") {
			setLocalDragging(true);
		}
		// e.stopPropagation();
		// e.preventDefault();
		let element = document.getElementById(data.node.id);
		let x = Number(element.style.left.replace("px", ""));
		let y = Number(element.style.top.replace("px", ""));
		if (Number(data.x) >= 0) {
			x = x + Number(data.x);
		} else {
			x -= Math.abs(Number(data.x));
		}

		if (Number(data.y) >= 0) {
			y = Number(y) + Number(data.y);
		} else {
			y -= Math.abs(Number(data.y));
		}
		setXAxis(x);
		setYAxis(y);
	};

	/**
	 * Handles the event on drag complete.
	 * @param {Object} event - The event object of dragged element.
	 * @param {Object} data - The data of the element.
	 * @returns {} Get the x and y offset at the drag complete.
	 */
	// const onDraggComplete = (e, data) => {
	// 	e.preventDefault();
	// 	if (e.type === "mouseup" || e.type === "touchend") {
	// 		setTimeout(() => {
	// 			setLocalDragging(false);
	// 		}, 100);
	// 	}
	// 	let element = document.getElementById(data.node.id);
	// 	let x = Number(element.style.left.replace("px", ""));
	// 	let y = Number(element.style.top.replace("px", ""));
	// 	if (Number(data.x) >= 0) {
	// 		x = x + Number(data.x);
	// 	} else {
	// 		x -= Math.abs(Number(data.x));
	// 	}

	// 	if (Number(data.y) >= 0) {
	// 		y = Number(y) + Number(data.y);
	// 	} else {
	// 		y -= Math.abs(Number(data.y));
	// 	}
	// 	setXAxis(x);
	// 	setYAxis(y);
	// };

	/**
	 * Handles the event on drag complete.
	 * @param {Object} event - The event object of dragged element.
	 * @param {Object} data - The data of the element.
	 * @returns {} Get the x and y offset at the drag complete.
	 */
	// const StopEditCompleteEventHandler = (e, data) => {
	// 	if (dragging === false) {
	// 		// e.preventDefault();
	// 		return;
	// 	}
	// 	e.stopPropagation();
	// 	e.preventDefault();

	// 	if (is_dragging) {
	// 		// e.preventDefault();
	// 		setDragging(false);

	// 		if (e.type === "mouseup" || e.type === "touchend") {
	// 			setTimeout(() => {
	// 				setLocalDragging(false);
	// 			}, 100);
	// 		}
	// 		let element = document.getElementById(data.node.id);
	// 		let element_id = Number(element.id.replace("parent_comment_holder_", ""));
	// 		// Adjust the Comment box height as per dragg
	// 		setCommentBoxMaxHeight(element_id);
	// 		let comment_id = element.getAttribute("db-id");
	// 		let x = Number(element.style.left.replace("px", ""));
	// 		let y = Number(element.style.top.replace("px", ""));
	// 		// if (
	// 		//   document.getElementById(`comments_${element_id}`).style.display !==
	// 		//   "block"
	// 		// ) {
	// 		if (!e.target.classList.contains("img-fluid-image-details--view")) {
	// 			if (Number(data.x) >= 0) {
	// 				x = x + Number(data.x);
	// 			} else {
	// 				x -= Math.abs(Number(data.x));
	// 			}
	// 			if (Number(data.y) >= 0) {
	// 				y = Number(y) + Number(data.y);
	// 			} else {
	// 				y -= Math.abs(Number(data.y));
	// 			}
	// 			let existingComments = [...annotationsComments];
	// 			let commentIndex = existingComments.findIndex(function (item) {
	// 				return item.comment_id == comment_id;
	// 			});
	// 			if (commentIndex !== -1) {
	// 				showCommentsTint(element_id);
	// 				updateXYCoordinates(
	// 					comment_id,
	// 					existingComments[commentIndex].comment,
	// 					x,
	// 					y
	// 				);
	// 			}
	// 		} else {
	// 			setdraggedElement(true);
	// 		}
	// 		is_dragging = false;
	// 	}

	// 	// }
	// };

	/**
	 * Handles to left margin.
	 * @param {Object} object.
	 * @returns {} return margin based on string length.
	 */
	const getThreadMargin = (object) => {
		let left = 0;
		const commentLength = object.comment_by.length;
		if (commentLength < 7) {
			left = 12;
		} else if (commentLength >= 7 && commentLength <= 10) {
			left = 10;
		} else if (commentLength >= 11 && commentLength <= 14) {
			left = 6.5;
		} else if (commentLength > 13 && commentLength < 20) {
			left = 5;
		} else {
			left = 2;
		}
		return left + "%";
	};

	/**
	 * Handles to scroll to the position of the element as per mouse wheel.
	 * @param {Object} element.
	 * @returns {} scroll to the position of the element.
	 */

	const scrollCanvas = (element) => {
		const commentListBox = document.getElementById(
			`${element.currentTarget.id}`
		);
		const position = Math.sign(element.deltaY);
		// Check if scrolling for the mouse wheel to down
		if (position === 1) {
			// Add scroll effect to move down
			commentListBox.scrollBy({
				top: 100 * 1.5,
				behavior: "smooth",
			});
		} else {
			// Add scroll effect to move up
			// else scrolling to up
			commentListBox.scrollBy({
				top: -100 * 1.6,
				behavior: "smooth",
			});
		}
	};
	/**
	 * Handles to scroll to the position of the element as per mouse wheel.
	 * @param {Object} element.
	 * @returns {} scroll to the position of the element.
	 */

	const scrollCommentListBox = (element) => {
		setTimeout(() => {
			if (element) {
				// Check if scrolling for the mouse wheel to down
				if (scrollPosition === 1) {
					// Add scroll effect to move down
					element.scrollBy({
						top: 100 * 1.5,
						behavior: "smooth",
					});
				} else {
					// Add scroll effect to move up
					// else scrolling to up
					element.scrollBy({
						top: -100 * 1.6,
						behavior: "smooth",
					});
				}
				// Reset the position variable
				scrollPosition = 0;
			}
		}, 3);
	};

	/**
	 * Handles to left margin.
	 * @param {Object} object.
	 * @returns {} return margin based on string length.
	 */
	const getMargin = (object) => {
		let left = 11;
		const commentLength = object.comment_by.length;
		if (commentLength < 7) {
			left = 27;
		} else if (commentLength >= 7 && commentLength <= 10) {
			left = 25;
		} else if (commentLength >= 11 && commentLength <= 14) {
			left = 15;
		} else if (commentLength > 14 && commentLength < 17) {
			left = 11;
		} else if (commentLength > 17 && commentLength < 20) {
			left = 10;
		} else {
			left = 8;
		}
		return left + "%";
	};

	/**
	 * Handles to generate the reply list from the comments list.
	 * @param {Object} object.
	 * @returns {} return reply list from the comments list.
	 */

	// const generateMarkup = (annotation, index) => {
	// 	return (
	// 		<div
	// 			className="parent_comments"
	// 			id={`parent_comment_holder_${index}`}
	// 			db-id={annotation.comment_id}
	// 			key={annotation.x_axis + annotation.y_axis}
	// 			style={{
	// 				position: "absolute",
	// 				left: annotation.x_axis + styleType,
	// 				top: annotation.y_axis + styleType,
	// 				cursor: "pointer",
	// 				zIndex: 1,
	// 			}}
	// 		>
	// 			<div
	// 				className={
	// 					"callout" +
	// 					(activeCommentIndex === index ? " active" : "") +
	// 					(annotation.user_id !== LoginUserId ? " other_comment" : "")
	// 				}
	// 				onMouseEnter={(e) => showCommentsTint(index)}
	// 				id={`comment_name_${index}`}
	// 				style={{ zIndex: -1 }}
	// 			>
	// 				<div
	// 					className={
	// 						"firstletter " +
	// 						(annotation.user_id === LoginUserId
	// 							? "mycomment"
	// 							: "other_comment")
	// 					}
	// 				>
	// 					{getFirstLetter(annotation.comment_by)}
	// 				</div>
	// 				{/* <div className="triangle-down-right"></div> */}
	// 				<div
	// 					className={
	// 						activeCommentIndex === index
	// 							? annotation.user_id === LoginUserId
	// 								? "my"
	// 								: "other"
	// 							: ""
	// 					}
	// 					style={{ marginTop: "-26px", marginLeft: "-30px" }}
	// 				>
	// 					<svg
	// 						xmlns="http://www.w3.org/2000/svg"
	// 						version="1.1"
	// 						class="svg-triangle"
	// 						width="25"
	// 						height="25"
	// 					>
	// 						<polygon
	// 							points="0,21 30,5 30,20"
	// 							style={{
	// 								fill: "#fff",
	// 								stroke:
	// 									annotation.user_id === LoginUserId ? "#6082b6" : "#639c2b",
	// 								"stroke-dasharray": "1 0 12.8 0",
	// 								"stroke-width": activeCommentIndex === index ? 1.7 : 0.9,
	// 							}}
	// 						/>
	// 					</svg>
	// 				</div>
	// 			</div>
	// 			<div
	// 				className={"showcomments"}
	// 				style={{ maxHeight: getCommentBoxMaxHeight(index) }}
	// 				id={`comments_${index}`}
	// 				onMouseDown={(e) => {
	// 					// e.stopPropagation(); // to disable the comments list drag effect
	// 					if (annotation.user_id !== LoginUserId) {
	// 						e.stopPropagation();
	// 					}
	// 					e.preventDefault();
	// 				}}
	// 				onWheel={(e) => {
	// 					e.preventDefault();
	// 					// e.stopPropagation();
	// 					scrollCommentListBox(e.currentTarget);
	// 				}}
	// 			>
	// 				<div>
	// 					<div>
	// 						<div
	// 							className={
	// 								"inner_first_letter skip " +
	// 								(annotation.user_id === LoginUserId
	// 									? "mycomment"
	// 									: "other_comment")
	// 							}
	// 						>
	// 							{getFirstLetter(annotation.comment_by)}
	// 						</div>
	// 						<span className="commented_by">
	// 							<b>{annotation.comment_by}</b>
	// 						</span>

	// 						<div
	// 							className="dd"
	// 							style={{
	// 								display: "inline-block",
	// 								zIndex: "9",
	// 								position: "relative",
	// 								top: "15px",
	// 								right: "18px",
	// 								float: "right",
	// 								// marginLeft: getThreadMargin(annotation),
	// 								// Please ignore this line we need this function in future else will remove it
	// 							}}
	// 							key={`dd_comment_${index}`}
	// 						>
	// 							{annotation.user_id === LoginUserId && (
	// 								<div
	// 									onClick={() => setDropDown((val) => !val)}
	// 									className="img-fluid--icons skip"
	// 									style={{
	// 										justifyContent: "center",
	// 										cursor: "pointer",
	// 										position: "absolute",
	// 									}}
	// 									id="dropdownMenuButton"
	// 									data-toggle="dropdown"
	// 									aria-haspopup="true"
	// 									aria-expanded="false"
	// 								>
	// 									<NextImage
	// 										src={MoreIcon}
	// 										alt="mindreach images"
	// 										className="add_more_options skip"
	// 									/>
	// 								</div>
	// 							)}
	// 							<>
	// 								<NextImage
	// 									style={{
	// 										marginLeft:
	// 											annotation.user_id === LoginUserId ? "18px" : "33px",
	// 									}}
	// 									src={cross}
	// 									alt="mindreach images"
	// 									className="add_close_comment skip"
	// 									onClick={() => {
	// 										hideCommentBox(index);
	// 										setActiveCommentIndex(-1);
	// 									}}
	// 								/>
	// 							</>
	// 							{annotation.user_id === LoginUserId && (
	// 								<div
	// 									className="dropdown-menu skip"
	// 									aria-labelledby="dropdownMenuButton"
	// 								>
	// 									<a
	// 										className="dropdown-item skip"
	// 										style={{
	// 											color: "black",
	// 											fontSize: "0.8rem",
	// 											padding: "0.25rem 1.5rem",
	// 										}}
	// 										onClick={() => {
	// 											setCommentId(annotation.comment_id, annotation.comment);
	// 										}}
	// 									>
	// 										Edit Comment
	// 									</a>
	// 									<a
	// 										className="dropdown-item skip"
	// 										style={{
	// 											color: "black",
	// 											fontSize: "0.8rem",
	// 											padding: "0.25rem 1.5rem",
	// 										}}
	// 										onClick={() => {
	// 											handleDeleteConfirmation(annotation.comment_id);
	// 											setDeleteCommentType("Delete Thread");
	// 											setDeleteCommentTypeDetails("thread");
	// 											setDeleteIndex(-1);
	// 										}}
	// 									>
	// 										Delete Thread
	// 									</a>
	// 								</div>
	// 							)}
	// 						</div>
	// 					</div>
	// 					<div className="comment_date">
	// 						{formatDateAMPM(annotation.commented_at)}
	// 					</div>
	// 					{!checkCommentExist(annotation.comment_id) ? (
	// 						<div
	// 							className="comment_details mt-3"
	// 							id={`comment_${annotation.comment_id}`}
	// 						>
	// 							{annotation.comment}
	// 						</div>
	// 					) : (
	// 						<form
	// 							className="comment_edit mt-3"
	// 							onSubmit={(e) => {
	// 								submitCommentForm(
	// 									e,
	// 									"edit",
	// 									Number(0),
	// 									annotation.comment_id,
	// 									annotation,
	// 									index
	// 								);
	// 							}}
	// 						>
	// 							<Form.Control
	// 								type="text"
	// 								autoComplete="off"
	// 								placeholder="Edit Comment"
	// 								autoFocus
	// 								onChange={(e) => {
	// 									setEditValue(e, annotation);
	// 								}}
	// 								id={`edit_comment_${annotation.comment_id}`}
	// 							/>
	// 							<NextImage
	// 								id={`edit_comment_img_${annotation.comment_id}`}
	// 								src={
	// 									checkCommentExist(annotation.comment_id) &&
	// 									checkCommentExist(annotation.comment_id).value
	// 										? send_message_blue
	// 										: send_message
	// 								}
	// 								className="trigger skip"
	// 								onClick={(e) => {
	// 									submitCommentForm(
	// 										e,
	// 										"edit",
	// 										Number(0),
	// 										annotation.comment_id,
	// 										annotation,
	// 										index
	// 									);
	// 								}}
	// 								style={{ display: "block" }}
	// 							/>
	// 							<ImageLoader id={`spinner_comment_${annotation.comment_id}`} />
	// 							<NextImage
	// 								style={{}}
	// 								src={cross}
	// 								alt="mindreach images"
	// 								className="edit_close_comment skip"
	// 								onClick={(e) => {
	// 									hideEditCommentBox(annotation.comment_id);
	// 								}}
	// 							/>
	// 						</form>
	// 					)}
	// 					{annotation.reply &&
	// 						annotation.reply.length > 0 &&
	// 						annotation.reply.map((replyObj) => {
	// 							return (
	// 								<>
	// 									<div>
	// 										<div
	// 											className={
	// 												"inner_first_letter " +
	// 												(replyObj.user_id === LoginUserId ? "mycomment" : "")
	// 											}
	// 										>
	// 											{getFirstLetter(replyObj.comment_by)}
	// 										</div>
	// 										<span className="commented_by">
	// 											<b>{replyObj.comment_by}</b>
	// 										</span>
	// 										{replyObj.user_id === LoginUserId && (
	// 											<>
	// 												<div
	// 													className=" dd"
	// 													style={{
	// 														position: "relative",
	// 														display: "inline-block",
	// 														marginLeft: getMargin(replyObj),
	// 													}}
	// 													key={`dd_reply_${index}`}
	// 												>
	// 													<div
	// 														onClick={() => setDropDown((val) => !val)}
	// 														className="img-fluid--icons skip"
	// 														style={{
	// 															justifyContent: "center",
	// 															cursor: "pointer",
	// 															position: "initial",
	// 															height: "36px",
	// 														}}
	// 														id="dropdownMenuButton"
	// 														data-toggle="dropdown"
	// 														aria-haspopup="true"
	// 														aria-expanded="false"
	// 													>
	// 														<NextImage
	// 															src={MoreIcon}
	// 															alt="mindreach images"
	// 															className="add_more_options skip"
	// 															style={{ marginLeft: "4px" }}
	// 														/>
	// 													</div>

	// 													<div
	// 														className="dropdown-menu skip"
	// 														aria-labelledby="dropdownMenuButton"
	// 													>
	// 														<a
	// 															className="dropdown-item skip"
	// 															style={{
	// 																color: "black",
	// 																fontSize: "0.8rem",
	// 																padding: "0.25rem 1.5rem",
	// 															}}
	// 															onClick={() => {
	// 																setCommentId(
	// 																	replyObj.comment_id,
	// 																	replyObj.comment
	// 																);
	// 															}}
	// 														>
	// 															Edit
	// 														</a>
	// 														<a
	// 															className="dropdown-item"
	// 															style={{
	// 																color: "black",
	// 																fontSize: "0.8rem",
	// 																padding: "0.25rem 1.5rem",
	// 															}}
	// 															onClick={() => {
	// 																handleDeleteConfirmation(replyObj.comment_id);
	// 																setDeleteCommentType("Delete Comment");
	// 																setDeleteCommentTypeDetails("comment");
	// 																setDeleteIndex(index);
	// 															}}
	// 														>
	// 															Delete
	// 														</a>
	// 													</div>
	// 												</div>
	// 											</>
	// 										)}
	// 									</div>
	// 									<div className="comment_date">
	// 										{formatDateAMPM(replyObj.commented_at)}
	// 									</div>
	// 									{!checkCommentExist(replyObj.comment_id) ? (
	// 										<div
	// 											className="comment_details mt-3"
	// 											id={`comment_${replyObj.comment_id}`}
	// 										>
	// 											{replyObj.comment}
	// 										</div>
	// 									) : (
	// 										<form
	// 											className="comment_edit mt-3"
	// 											onSubmit={(e) => {
	// 												submitCommentForm(
	// 													e,
	// 													"edit",
	// 													annotation.comment_id,
	// 													replyObj.comment_id,
	// 													replyObj,
	// 													index
	// 												);
	// 											}}
	// 										>
	// 											<Form.Control
	// 												type="text"
	// 												autoComplete="off"
	// 												placeholder="Edit Comment"
	// 												autoFocus
	// 												onChange={(e) => {
	// 													setEditValue(e, replyObj);
	// 												}}
	// 												id={`edit_comment_${replyObj.comment_id}`}
	// 											/>
	// 											<NextImage
	// 												id={`edit_comment_img_${replyObj.comment_id}`}
	// 												src={
	// 													checkCommentExist(replyObj.comment_id) &&
	// 													checkCommentExist(replyObj.comment_id).value
	// 														? send_message_blue
	// 														: send_message
	// 												}
	// 												className="trigger skip"
	// 												onClick={(e) => {
	// 													submitCommentForm(
	// 														e,
	// 														"edit",
	// 														annotation.comment_id,
	// 														replyObj.comment_id,
	// 														replyObj,
	// 														index
	// 													);
	// 												}}
	// 											/>
	// 											<ImageLoader
	// 												id={`spinner_comment_${replyObj.comment_id}`}
	// 											/>
	// 											<NextImage
	// 												style={{}}
	// 												src={cross}
	// 												alt="mindreach images"
	// 												className="edit_close_comment"
	// 												onClick={(e) => {
	// 													hideEditCommentBox(replyObj.comment_id);
	// 												}}
	// 											/>
	// 										</form>
	// 									)}
	// 								</>
	// 							);
	// 						})}
	// 					<div className="comment_add" style={{ marginTop: "15px" }}>
	// 						<form
	// 							onSubmit={(e) => {
	// 								submitCommentForm(
	// 									e,
	// 									"edit",
	// 									annotation.comment_id,
	// 									Number(0),
	// 									annotation,
	// 									index
	// 								);
	// 							}}
	// 						>
	// 							<Form.Control
	// 								type="text"
	// 								autoComplete="off"
	// 								placeholder="Reply"
	// 								style={{ width: "90%" }}
	// 								value={comment}
	// 								autoFocus
	// 								onChange={(e) => {
	// 									updateComment(e);
	// 									setXAxis(annotation.x_axis);
	// 									setYAxis(annotation.y_axis);
	// 								}}
	// 								onClick={(e) => {
	// 									e.currentTarget.focus();
	// 									// e.stopPropagation();
	// 								}}
	// 								className="skip"
	// 							/>
	// 							<NextImage
	// 								id={`src_reply_comment_${annotation.comment_id}`}
	// 								src={getMessageIcon()}
	// 								className="trigger skip"
	// 								onClick={(e) => {
	// 									submitCommentForm(
	// 										e,
	// 										"edit",
	// 										annotation.comment_id,
	// 										Number(0),
	// 										annotation,
	// 										index
	// 									);
	// 								}}
	// 							/>
	// 							<ImageLoader
	// 								id={`spinner_reply_comment_${annotation.comment_id}`}
	// 							/>
	// 						</form>
	// 					</div>
	// 					{/* <div className="comment-triangle-down-right"></div> */}
	// 				</div>
	// 			</div>
	// 			<div
	// 				className={"hover-show-comments"}
	// 				id={`comments_hover_${index}`}
	// 				style={{ display: "block" }}
	// 				// onMouseLeave={() => hideCommentsTint(index)}
	// 				onClick={(e) => {
	// 					e.stopPropagation();
	// 					e.preventDefault();
	// 					if (!localDragging) {
	// 						setActiveCommentIndex(index);
	// 						setdraggedElement(false);
	// 						setDragging(false);
	// 						showComments(index);
	// 						return;
	// 					}
	// 					return;
	// 				}}
	// 				onMouseDown={(e) => {
	// 					if (annotation.user_id !== LoginUserId) {
	// 						e.stopPropagation();
	// 					}
	// 					e.preventDefault();
	// 				}}
	// 			>
	// 				<div>
	// 					<div style={{ paddingTop: "2px" }}>
	// 						<div
	// 							className={
	// 								"inner_first_letter" +
	// 								(annotation.user_id === LoginUserId ? " mycomment" : "")
	// 							}
	// 							style={{ marginLeft: "12px" }}
	// 						>
	// 							{getFirstLetter(annotation.comment_by)}
	// 						</div>
	// 						<span className="commented_by" style={{ fontSize: "14px" }}>
	// 							<b>{annotation.comment_by}</b>
	// 						</span>
	// 						<p className="comm_date" style={{}}>
	// 							{" "}
	// 							{formatDateAMPM(annotation.commented_at)}
	// 						</p>
	// 					</div>
	// 					<div className="comment_d_parent">
	// 						<div
	// 							className="comment_details mt-2"
	// 							style={{ marginLeft: "3.1rem", paddingBottom: "10px" }}
	// 						>
	// 							{annotation.comment}
	// 						</div>
	// 					</div>
	// 				</div>
	// 			</div>
	// 		</div>
	// 	);
	// };

	/**
	 * Handles to add the new comment box.
	 * @param {Object} object.
	 * @returns {} return add the new comment box.
	 */

	// const getAddCommentPopupBox = () => {
	// 	return annotations.map((annotation, index) => (
	// 		<Draggable
	// 			key={`add_c_${annotation.x + annotation.y}`}
	// 			onDrag={ondraggEventHandler}
	// 			onStart={onStarteventHandler}
	// 			onStop={onDraggComplete}
	// 			bounds="parent"
	// 		>
	// 			<div
	// 				key={annotation.x + annotation.y}
	// 				style={{
	// 					position: "absolute",
	// 					// left: annotation.x,
	// 					top: annotation.y,
	// 					cursor: "pointer",
	// 					zIndex: 1,
	// 				}}
	// 				id={`add_comment_parent_${index}`}
	// 				className={`add_comment_parent`}
	// 			>
	// 				{" "}
	// 				<div className="">
	// 					<div
	// 						id={`addcomment_${index}`}
	// 						className={
	// 							"addcomment" + (defaultPosition > 640 ? " move_right" : "")
	// 						}
	// 						style={{
	// 							display: annotations.length === index + 1 ? "block" : "none",
	// 						}}
	// 					>
	// 						<div>
	// 							<div style={{ paddingTop: "17px" }}>
	// 								<div className=""></div>
	// 								<span
	// 									className="commented_by"
	// 									style={{ marginLeft: "22px", fontSize: "15px" }}
	// 								>
	// 									<b>Add Comment</b>
	// 								</span>

	// 								<div
	// 									className=" dd"
	// 									style={{
	// 										position: "initital",
	// 										display: "inline-block",
	// 										// marginLeft: "23%",
	// 									}}
	// 									key={"dd_" + index}
	// 								>
	// 									<>
	// 										<NextImage
	// 											src={cross}
	// 											alt="mindreach images"
	// 											className="add_close_comment"
	// 											onClick={(e) => {
	// 												e.stopPropagation();
	// 												hideAddCommentBox(`addcomment_${index}`);
	// 											}}
	// 										/>
	// 									</>
	// 								</div>
	// 							</div>

	// 							<div className="comment_details mt-3"></div>
	// 							<div className="comment_add mt-4">
	// 								<form
	// 									onSubmit={(e) => {
	// 										submitCommentForm(e, "add");
	// 									}}
	// 									id={index}
	// 								>
	// 									<Form.Control
	// 										type="text"
	// 										autoComplete="off"
	// 										placeholder="Reply"
	// 										style={{ width: "90%" }}
	// 										value={comment}
	// 										autoFocus
	// 										onChange={(e) => {
	// 											updateComment(e);
	// 										}}
	// 										className="skip"
	// 									/>
	// 									<NextImage
	// 										id={`src_add_comment_${index}`}
	// 										src={getMessageIcon()}
	// 										className="trigger "
	// 										onClick={(e) => {
	// 											submitCommentForm(e, "add");
	// 										}}
	// 									/>
	// 									<ImageLoader id={`spinner_add_comment_${index}`} />
	// 								</form>
	// 							</div>
	// 						</div>
	// 						{defaultPosition < 640 ? (
	// 							<div className="addcomment-triangle-down-left"></div>
	// 						) : (
	// 							<div className="addcomment-triangle-down-right"></div>
	// 						)}
	// 					</div>
	// 				</div>
	// 			</div>
	// 		</Draggable>
	// 	));
	// };

	/**
	 * Handles to get the comments list from API.
	 * @param {Object} object.
	 * @returns {} return comments list from API.
	 */

	// const getCommentList = () => {
	// 	return annotationsComments !== undefined && showCommentList === true ? (
	// 		<>
	// 			{annotationsComments.map((annotation, index) => (
	// 				<>
	// 					<Draggable
	// 						allowAnyClick={true}
	// 						disabled={annotation.user_id !== LoginUserId}
	// 						onDrag={ondraggEventHandler}
	// 						onStart={onStarteventHandler}
	// 						onStop={StopEditCompleteEventHandler}
	// 						bounds="parent"
	// 						position={
	// 							draggedElement ||
	// 							(document.getElementById(`parent_comment_holder_${index}`) &&
	// 								document
	// 									.getElementById(`parent_comment_holder_${index}`)
	// 									.hasAttribute("dragged"))
	// 								? { x: 0, y: 0 }
	// 								: undefined
	// 						}
	// 					>
	// 						{generateMarkup(annotation, index)}
	// 					</Draggable>
	// 				</>
	// 			))}
	// 		</>
	// 	) : (
	// 		<>{/* <Loader /> */}</>
	// 	);
	// };

	// console.log("defaultTransformPosition =>", defaultTransformPosition);
	// const PanViewerContainer = () => {
	// 	if (showCommentList && isDragging) {
	// 		return <PanViewer />;
	// 	}
	// 	return <PanViewer_1 />;
	// };
	console.log("annotations", showCommentList);
	return (
		<div
			id="image_holder"
			className="modal-canvas--image  "
			onWheel={(e) => {
				scrollCanvas(e);
			}}
		>
			{isSupported ? (
				<div className="thumbnailimage">
					<img src={Thumbnail} alt="" />
					<p>{`${getFileExtension(
						path
					)} images are currently not supported to preview`}</p>
				</div>
			) : (
				<PanViewer
					style={{
						width: "100%!important",
						height: "100%!important",
						display: "flex",
						// justifyContent: "center",
						// alignItems: "center",
						// zIndex: 1,
						// webkitTransform: "none",
						// transform: "none",
					}}
					className="pan_container"
					zoom={zoom}
					setZoom={handleWheel}
					// matrixData={(dx, dy, 0, 0)}
					pandx={dx}
					pandy={dy}
					key={dx}
					isDraggingCanvas={isDraggingCanvas}
				>
					{overlayLayerActive && (
						<div id="overlay" className="overlayLayer"></div>
					)}
					<canvas
						// style={{}}
						id="zoom_canvas"
						ref={canvasRef}
						className={`modal-bg---image${
							drawingMode === "drag"
								? " pan_viewer  canvas"
								: "pan_viewer canvas_drawing"
						}`}
						style={{
							// top: 0,
							// left: 0,
							pointerEvents: "painted",
							// transform: 0,
						}}
						// onMouseDown={(e) => {
						// 	if (showCommentList && isDragging) {
						// 		e.stopPropagation();
						// 		e.preventDefault();
						// 		handleCanvasMouseDown(e);
						// 	}
						// 	setIsDraggingCanvas(false);
						// }}
						// onMouseMove={(e) => {
						// 	if (showCommentList && isDragging) {
						// 		e.stopPropagation();
						// 		e.preventDefault();
						// 		handleCanvasMouseMove(e);
						// 	}
						// 	setIsDraggingCanvas(true);
						// }}
						// onMouseUp={(e) => {
						// 	if (showCommentList && isDragging) {
						// 		e.stopPropagation();
						// 		e.preventDefault();
						// 		handleCanvasMouseUp(e);
						// 	}
						// 	setIsDraggingCanvas(false);
						// }}
						// onClick={(e) => {
						// 	const canvasParentElementDetails = getMatrixStyle(
						// 		canvasRef.current.parentElement
						// 	);
						// 	if (
						// 		showCommentList &&
						// 		!drag &&
						// 		Number(defaultTransformPosition.x) ===
						// 			Number(canvasParentElementDetails[4]) &&
						// 		Number(defaultTransformPosition.y) ===
						// 			Number(canvasParentElementDetails[5]) &&
						// 		((!isDrawing.current && !isDragging) ||
						// 			(isDrawing.current && isDragging))
						// 	) {
						// 		e.stopPropagation();
						// 		e.preventDefault();
						// 		handleCanvasClick(canvasRef.current, e);
						// 	} else {
						// 		if (
						// 			canvasParentElementDetails.length > 5 &&
						// 			(defaultTransformPosition.x !== canvasParentElementDetails[4] ||
						// 				defaultTransformPosition.y !==
						// 					canvasParentElementDetails[5]) &&
						// 			(Number(canvasParentElementDetails[4]) !== Number(0) ||
						// 				Number(canvasParentElementDetails[5]) !== Number(0))
						// 		) {
						// 			setTransformPosition({
						// 				x: canvasParentElementDetails[4],
						// 				y: canvasParentElementDetails[5],
						// 			});
						// 		}
						// 	}
						// 	return false;
						// }}
					/>
				</PanViewer>
			)}
			<DeleteModalConfirmation
				show={show}
				onHide={() => {
					setShow(false);
				}}
				branch={deleteCommentTypeDetails}
				name={""}
				title={deleteCommentType}
				handleConfirm={() => handleCommentDelete()}
			/>
			<MyVerticallyCenteredModal
				show={modalShow}
				onHide={() => {
					setModalShow(false);
				}}
				heading={deleteHeading}
				message={deleteResponse}
				className="modal-body-body--success"
			/>
		</div>
	);
}

export default ImageAnnotation;
