import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Pagination,
	Form,
	Row,
	Col,
	Table,
	Button,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";

// Importing components and images
import Loader from "../../../components/Loader";
import ReactHtmlParser from 'react-html-parser';

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

// Importing API related files and routes
import { client } from "../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../../data/filepath";

import {
	ADMIN_GET_TERMS_AND_CONDITION,
	DELETE_ENTERPRISE_FIELD,
	DELETE_BULK_ENTERPRISE_FIELD,
	DUPLICATE_BULK_ENTERPRISE_FIELD,
} from "../../../config/apiRoutes.api";
import {
	TERMS_AND_CONDITION_EDIT_PAGE,
} from "../../../config/routes";

const ViewTermsConditionFields = () => {
	// Retrieve the token from the session storage
	const token = sessionStorage.getItem("token");
	// Create a request instance using the token
	const request = client(token);
	// Get the navigation function from the useNavigate hook
	const navigate = useNavigate();
	// Create a new instance of FormData
	const bodyFormData = new FormData();
	//Declarations of state variable
	const [fieldsData, setTermsConditionFieldsData] = useState([]);

	useEffect(() => {
		if (token) {
			request
				.get(apiURL + ADMIN_GET_TERMS_AND_CONDITION )
				.then((response) => {
					// Log the response to the console
					console.log(" response =>", response.data.data);
					console.log(" response ID =>", response.data.data.id);
					// Update the fieldsData state with the response data
					setTermsConditionFieldsData(response.data.data);

					console.log("fieldsData =>", fieldsData);

				})
				.catch((err) => console.log(err));
		}
	}, []);


	console.log("fieldsData =>", fieldsData);
	if (!fieldsData && typeof fieldsData !== undefined) return <Loader />;

	else {
		// Render the actual content once fieldsData is available
		return (
			<>
				<div>
					<div className="rightbar pr-11">
						<div className="section-title">
							<h2>Terms And Conditions</h2>
						</div>
						<div
							className="flex-sm-wrap col-gap-30-sm mt-2-branch"
							style={{ height: "37px" }}
						>
							<div className="customer-search">

								<Button
									variant="primary"
									onClick={() => navigate(`${TERMS_AND_CONDITION_EDIT_PAGE}/${fieldsData.id}`)}
									className="pull-right"
									style={{ margin: "0px 9px  0px 0px" }}
								>
									<span></span>
									&nbsp;&nbsp; Update &nbsp;&nbsp;
								</Button>
							</div>
							<div></div>
						</div>

						{fieldsData.key_name !== undefined ? (
							<>
								<div
									className={`table-responsive table-b custom-new-table `}
									style={{ marginTop: "3rem !important" }}
								>
									{ ReactHtmlParser(fieldsData.value) }
								</div>

							</>
						) : (
							<Loader />
						)}
					</div>
				</div>

			</>
		);
	}
};

export default ViewTermsConditionFields;
