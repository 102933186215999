import React, { useState, useEffect, useRef, useCallback } from "react";

//Importing the bootstrap components
import { Button, Row, Col, Form, Tab, Tabs, Dropdown } from "react-bootstrap";
//Importing the react select components
import Select from "react-select";
import Marker from "./Marker";
import Loader from "./../../../../components/Loader";
import PanViewer from "./../../../../components/PanViewer";
import Avatar from "../../../../components/Avatar";
import DeleteModalConfirmation from "./../../../../components/Modal/DeleteModal";
import ToastAlert from "../../../../components/ToastAlert";

//Importing the images
import TickGreenIcon from "./../../../../assets/Images/greenTick.svg";
import threeDots from "./../../../../assets/Images/coordinator_actions_icon/three_dots.svg";
import PencilThin from "./../../../../assets/Images/coordinator_actions_icon/pencil.svg";
import Zoomout from "./../../../../assets/Images/coordinator_actions_icon/zoomout.svg";
import ZoomIn from "./../../../../assets/Images/coordinator_actions_icon/zoomIn.svg";
import cross from "./../../../../assets/Images/cross-3.svg";
import CloseBtn from "./../../../../assets/Images/coordinator_actions_icon/close.svg";
import PreviousIcon from "./../../../../assets/Images/coordinator_actions_icon/prev-icons.svg";
import EditImageOriginal from "./../../../../assets/Images/coordinator_actions_icon/Edit_Original.svg";
import NextIcon from "./../../../../assets/Images/coordinator_actions_icon/next-icons.svg";
import duplicate from "./../../../../assets/Images/coordinator_actions_icon/Doc_Duplicate.svg";
import Pencil from "./../../../../assets/Images/coordinator_actions_icon/pencil_edit.svg";
import DeleteIcon from "./../../../../assets/Images/coordinator_actions_icon/white_delete.svg";
import BlackDelete from "./../../../../assets/Images/coordinator_actions_icon/black_delete.svg";
import BlackEdit from "./../../../../assets/Images/coordinator_actions_icon/pencil_edit.svg";

import ChatIcons from "./../../../../assets/Images/coordinator_actions_icon/Chat.svg";
import BlueChatIcon from "./../../../../assets/Images/coordinator_actions_icon/BlueChatIcon.svg";

import CancelIcons from "./../../../../assets/Images/coordinator_actions_icon/cancel_image.svg";
import Send from "./../../../../assets/Images/coordinator_actions_icon/Send.svg";
import ArrowDown from "./../../../../assets/Images/coordinator_actions_icon/White_Arrow_Down.svg";
import RenameModal from "../RenameModal";
import SettingsModal from "../../../../components/Modal/StatusModal";
import NoComment from "./../../../../assets/Images/actionsIcons/NoComment.svg";
/// Copy
// import EditImageOriginal from "./../../../../assets/Images/coordinator_actions_icon/Edit_Original.svg";
// import NextIcon from "./../../../../assets/Images/coordinator_actions_icon/next-icons.svg";
// import duplicate from "./../../../../assets/Images/coordinator_actions_icon/Doc_Duplicate.svg";
// import Pencil from "./../../../../assets/Images/coordinator_actions_icon/pencil_edit.svg";
// import DeleteIcon from "./../../../../assets/Images/coordinator_actions_icon/white_delete.svg";
// import BlackDelete from "./../../../../assets/Images/coordinator_actions_icon/black_delete.svg";
// import BlackEdit from "./../../../../assets/Images/coordinator_actions_icon/pencil_edit.svg";
// import TickGreenIcon from "./../../../../assets/Images/greenTick.svg";
// import threeDots from "./../../../../assets/Images/coordinator_actions_icon/three_dots.svg";
// import PencilThin from "./../../../../assets/Images/coordinator_actions_icon/pencil.svg";
// import Zoomout from "./../../../../assets/Images/coordinator_actions_icon/zoomout.svg";
// import ZoomIn from "./../../../../assets/Images/coordinator_actions_icon/zoomIn.svg";
import Percentage from "./../../../../assets/Images/coordinator_actions_icon/percentage.svg";
import Previous from "./../../../../assets/Images/coordinator_actions_icon/previous.svg";
import Next from "./../../../../assets/Images/coordinator_actions_icon/next.svg";
// import CloseBtn from "./../../../../assets/Images/coordinator_actions_icon/close.svg";
import Coursor from "./../../../../assets/Images/coordinator_actions_icon/cursor.svg";
import cursorBlue from "./../../../../assets/Images/coordinator_actions_icon/cursorBlue.svg";
import Back from "./../../../../assets/Images/coordinator_actions_icon/back.svg";

// import ChatIcons from "./../../../../assets/Images/coordinator_actions_icon/Chat.svg";
// import BlueChatIcon from "./../../../../assets/Images/coordinator_actions_icon/BlueChatIcon.svg";

// import CancelIcons from "./../../../../assets/Images/coordinator_actions_icon/CancelIConBlack.svg";
// import Send from "./../../../../assets/Images/coordinator_actions_icon/Send.svg";
// import ArrowDown from "./../../../../assets/Images/coordinator_actions_icon/White_Arrow_Down.svg";

// Importing API related files and routes

import { client } from "./../../../../data/API/GobalClientAxiosRequest";
import { imageURL, apiURL } from "../../../../data/filepath";

import {
	CO_ORDINATOR_VIEW_PROFILE_SETTINGS,
	CO_ORDINATOR_VIEW_EDIT_GROUP,
	CO_ORDINATOR_UPDATE_EDIT_GROUP,
	CO_ORDINATOR_DUPLICATE_IMAGE_RENAME,
	CO_ORDINATOR_DELETE_DUPLICATE_IMAGE,
	CO_ORDINATOR_ORIGINAL_IMAGE_RENAME,
	CO_ORDINATOR_ASSIGNED_TO_EDITOR,
	ADMIN_GET_COORDINATORS_DETAILS,
	CO_ORDINATOR_DUPLICATE_IMAGES,
} from "./../../../../config/apiRoutes.api";

// Check all the status of editor assigned
const editorWorkingStatus = [
	"assign_to_editor",
	"assigned",
	"started",
	"hold",
	"qc",
	"error",
	"completed",
	"finished",
	"reassigned",
	"temporary-reassigned",
	"temporary-completed",
];

const Modal = ({
	showModal,
	setShowModal,
	image_details_view,
	setImageDetailsView,
	editGroups,
	editTypes,
	customer_id,
	setMultipleImagesId,
	selectImageName,
	token,
	id,
	idSelected,
	setClickSelect,
	setSelectAll,
	customer_status,
	images,
	modalRef,
	setting_profile,
	handleFetchProfileSettings,
	setFolderListName,
	setFolderSelect,
	setFoldersPath,
	setDuplicateImageModal,
	setDuplicateAmount,
	setAmount,
	setImageID,
	imageID,
	setParentFolderDetails,
	setFolderName,
	setBatchNumber,
	setImages,
	setFolderList,
	setFolder,
	setImageMainFolder,
	setFolderMainFolder,
	setMainImageCount,
	setMainImageSettingsCount,
	setOrderNumber,
	setSubFolderOpen,
	setDisplay,
	setClickSelectParent,
	multiple_images_id,
	statusNew,
	setImageEditorResponse,
	setStatusModal,
	batchId,
	setStatusNew,
	determineCustomerView,
	allImages,
	// setCommentsList,
	newSettingsClick,
	setNewSettingsClicked,
	setEditTypes,
	...props
}) => {
	const customSelectStyles = {
		menuList: (provided) => {
			return {
				...provided,
				"& :first-child": {
					fontWeight: "bold",
					fontSize: "13px",
				},
			};
		},
	};

	const customSelectStyle = {
		menuList: (provided) => {
			return {
				...provided,
				fontSize: "13px",
			};
		},
	};
	// Create a FormData object for request payload
	const bodyFormData = new FormData();

	const roleJson = sessionStorage.getItem("role");

	const role = roleJson ? JSON.parse(roleJson) : null;
	const currentUserRoles = [role !== null ? role.role : "login"];
	const currentUserId = [role !== null ? role.pivot.user_id : ""];

	// Create an HTTP client with the token
	const request = client(token);
	const modalRefImage = useRef(null);

	//Declarations of state
	const [currentImage, setCurrentImage] = useState("");
	const [isFormSubmitted, setFormSubmitted] = useState(false);
	const [drawingMode, setDrawingMode] = useState("");
	const [togglePaintIcons, setTogglePaintIcons] = useState(false);
	const [togglePaintColor, setTogglePaintColor] = useState(false);
	const [toggleLineSize, setToggleLineSize] = useState(false);
	const [activeTab, setActivateTab] = useState("");
	const [activeSubTab, setActivateSubTab] = useState("");
	const [commentsText, setCommentsText] = useState("");
	const [commentsMarker, setCommentsMarker] = useState([]);
	const [commemntsAddSections, setCommentsAddSections] = useState(false);
	const [commentsId, setCommentId] = useState("");
	const [overlayLayerActive, setOverlayLayerActive] = useState(false);
	const [edit_comments, setEditComments] = useState("");
	const [menu_comments, setMenuComments] = useState(false);
	const [updateCommentsBoxOpen, setUpdatedCommentsBox] = useState("");
	const [editcommentsText, setEditCommentsText] = useState("");
	const [isDisabledUpdatedCommentText, setIsDisabledUpdatedCommentText] =
		useState(true);
	const [toggleReply, setToggleReply] = useState(true);
	const [rotation, setRotation] = useState(0);
	const [replyCommentsText, setReplyCommentsText] = useState("");
	const [dx, setDx] = useState(0);
	const [dy, setDy] = useState(0);
	const [defaultTransformPosition, setTransformPosition] = useState({
		x: 0,
		y: 0,
	});
	const [isDragging, setIsDragging] = useState(false);
	const [zoomPercentage, setZoomPercentage] = useState("36%");
	const [zoom, setZoom] = useState(0.8);
	const [handleProfileChangedValue, setHandledProfileChanged] = useState(false);
	const [isDraggingCanvas, setIsDraggingCanvas] = useState(false);
	const [isEditorAssigend, setEditorAssigned] = useState(false);
	const [viewDuplicateModal, setViewDuplicateModal] = useState(false);
	const [duplicateImageId, setDuplicateImageId] = useState("");
	const [error, setError] = useState("");
	const [status, setStatus] = useState("");
	const [loader, setLoader] = useState(false); //Loader State
	const [groupSelect, setGroupSelect] = useState(false);
	const [checked, setChecked] = useState(false);
	const [checked1, setChecked1] = useState(false);
	const [checked2, setChecked2] = useState(false);
	const [checked3, setChecked3] = useState(false);
	const [selected, setSelected] = useState(false);
	const [selected1, setSelected1] = useState(false);
	const [selected2, setSelected2] = useState(false);
	const [selected3, setSelected3] = useState(false);
	const [customizedSettings, customizedSettingsEnabled] = useState(false);
	const [edit_select, setEditorType] = useState("");
	const [edit_id, setEditId] = useState([]);
	const [previousSelectedValue, setPreviousSelectedValue] = useState("");
	const [currentAnnotation, setCurrentAnnotation] = useState(null);
	const [annotations, setAnnotations] = useState([]);
	const [edit_type_toggle, setToggleEditType] = useState(false);
	//To update the select group
	const [group_name_select, setEditGroupNameSelect] = useState("");
	const [imageExtensions, setImageExtensions] = useState("");
	const [imageName, setImageName] = useState("");
	// To set the state for add
	const [background_removal_color_type, setBackgroundRemovalColorType] =
		useState("");
	const [background_removal_color_code, setBackgroundRemovalColorCode] =
		useState("");
	const [background_adjustment_color_code, setBackgroundAdjustmentColorCode] =
		useState("");
	const [background_adjustment_color_type, setBackgroundAdjustmentColorType] =
		useState("");
	const [view_edit_group, setViewEditGroup] = useState({});
	const [group_name, setGroupName] = useState("");
	const [new_edit_group_name, setNewEditGroupName] = useState("");
	const [toggleMessage, setToggleMessage] = useState(false);
	const [flip, setFlip] = React.useState(false);
	const [undo, setUndo] = useState(0);
	// const [edit_select, setEditorType] = useState("");
	// const [edit_group_name_select, setEditGroupNameSelect] = useState("");
	const [resizing_file_format, setResizingFileFormat] = useState("");
	const [resizing_height, setResizingHeight] = useState("");
	const [resizing_width, setResizingWidth] = useState("");
	const [resizing_dpi, setResizingDpi] = useState("");
	const [resizing_dpi_type, setResizingDpiType] = useState("");
	const [resizing_color_mode, setResizingColorMode] = useState("");
	const [resizing_alignment, setResizingAlignment] = useState("");
	const [resizing_margin_format, setResizingMarginFormat] = useState("");
	const [resizing_margin_top, setResizingMarginTop] = useState("");
	const [resizing_margin_bottom, setResizingMarginBottom] = useState("");
	const [resizing_margin_right, setResizingMarginRight] = useState("");
	const [resizing_margin_left, setResizingMarginLeft] = useState("");
	const [edit_group_name, setEditGroupName] = useState("");
	const [edit_type_ids, setEditTypeId] = useState("");
	const [image_file_format, setImageFileFormat] = useState("");
	const [image_height, setImageHeight] = useState("");
	const [image_width, setImageWidth] = useState("");
	const [image_dpi, setImageDpi] = useState("");
	const [image_dpi_type, setImageDpiType] = useState("");
	const [image_color_mode, setImageColorMode] = useState("");
	const [image_alignment, setImageAlignment] = useState("");
	const [image_margin_format, setImageMarginFormat] = useState("");
	const [image_margin_top, setImageMarginTop] = useState("");
	const [image_margin_bottom, setImageMarginBottom] = useState("");
	const [image_margin_right, setImageMarginRight] = useState("");
	const [image_margin_left, setImageMarginLeft] = useState("");
	const [group, setGroup] = useState([]);
	const [message, setMessage] = useState("");
	const [comments, setComments] = useState([]);
	const [isDropdownOpen, setDropdownOpen] = useState(false);
	const [renameModalShow, setRenameModalShow] = useState(false);
	const [renameTextDuplicate, setRenameTextDuplicate] = useState("");
	const [renameId, setRenameId] = useState("");
	const [showModalDelete, setShowModalDelete] = useState(false);
	const [hoveredItems, setHoveredItems] = useState(false);
	const [hoveredItemId, setHoveredItemId] = useState(null);
	const [showCommentList, setCommentsList] = useState(false);
	const [annotationsComments, setAnnotationsComments] = useState([]);
	const [previousImageSelected, previousImageID] = useState("");
	const [viewDuplicateSettingsById, setViewDuplicateSettings] = useState({});
	const [duplicateImageName, setDuplicateImageName] = useState("");
	const [selectedDuplicateSettings, setSelectedDuplicateSettings] =
		useState(false);
	const [selectedDuplicateSettings1, setSelectedDuplicateSettings1] =
		useState(false);
	const [selectedDuplicateSettings2, setSelectedDuplicateSettings2] =
		useState(false);
	const [selectedDuplicateSettings3, setSelectedDuplicateSettings3] =
		useState(false);

	const [file_format_duplicate_size, setFileFormatDuplicateSize] = useState("");
	const [margin_format_duplicate, setMarginFormatDuplicateSize] = useState("");
	const [margin_top_duplicateSize, setMarginTopDuplicateSize] = useState("");
	const [margin_bottom_duplicateSize, setMarginBottomDuplicateSize] =
		useState("");
	const [margin_left_duplicateSize, setMarginLeftDuplicateSize] = useState("");
	const [margin_right_duplicateSize, setMarginRightDuplicateSize] =
		useState("");

	const [resizingOriginalSize, setResizingOriginalSize] = useState(false);
	const [originalSize, setOriginalSize] = useState(false);
	const [backgroundRemovalColorCode, setBackgroundRemoval] =
		useState("#ffffff");

	const [resizing_height_duplicate, setResizingHeightDuplicate] = useState("");
	const [resizing_width_duplicate, setResizingWidthDuplicate] = useState("");

	const [
		background_removal_color_type_duplicate,
		setBackgroundRemovalColorTypeDuplicate,
	] = useState("");
	const [
		background_removal_color_code_duplicate,
		setBackgroundRemovalColorCodeDuplicate,
	] = useState("");
	const [
		background_adjustment_color_code_duplicate,
		setBackgroundAdjustmentColorCodeDuplicate,
	] = useState("");
	const [
		background_adjustment_color_type_duplicate,
		setBackgroundAdjustmentColorTypeDuplicate,
	] = useState("");

	const canvasRef = useRef(null);
	const snapShotRef = useRef(null);
	const inputRef = useRef(null); // Reference to the input element
	const isDrawing = useRef(false);

	/**
	 * Transform an array of customer edit types into an array of objects with label, value, and additional properties.
	 * @param {Array} customerEditTypes - Array of customer edit types.
	 * @returns {Array} - Transformed array of objects with label, value, and additional properties.
	 */
	const selectLabel = (customerEditTypes) =>
		customerEditTypes.map(({ group_name: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of array as label and value return a new array of object like [{value:"",label:""}]

	const handleUpdateComments = (id) => {
		setEditComments(id);
	};
	const isCheckedEditIdtoString = useCallback(() => {
		let tempFieldArray = edit_id ? [...edit_id] : [];
		let checkedValue = "";
		if (edit_id !== undefined) {
			tempFieldArray.some((element) => {
				const val = Number(element);
				if (element !== "") {
					checkedValue += val + ",";
				}
			});
			return checkedValue ? `${checkedValue}` : "";
		}
	}, [edit_id]);
	function removeDuplicatesWithSet(array) {
		return [...new Set(array)];
	}
	const updatedCommentsText = (
		x_axis,
		y_axis,
		width,
		height,
		commentText,
		comment_id
	) => {
		// Ensure token is available
		if (!token) return;

		// Initialize FormData
		bodyFormData.append("image_id", image_details_view.id);
		bodyFormData.append("x_axis", x_axis);
		bodyFormData.append("y_axis", y_axis);
		bodyFormData.append("width", width);
		bodyFormData.append("height", height);
		bodyFormData.append("comment", commentText);
		bodyFormData.append("parent_id", 0);
		bodyFormData.append("comment_id", comment_id);

		// Make the API request
		// request
		// 	.post(apiURL + CUSTOMER_UPDATE_COMMENTS_TO_POST_IMAGE, bodyFormData)
		// 	.then((response) => {
		// 		fetchCommentsMarker(image_details_view.id);
		// 		setCommentsAddSections(false);
		// 		setEditComments("");
		// 		setUpdatedCommentsBox("");
		// 	})
		// 	.catch((err) => {
		// 		let message =
		// 			typeof err.response !== "undefined"
		// 				? err.response.data.message
		// 				: err.message;
		// 		setError(message);
		// 	});
	};
	const replyCommentsBasedCommentsId = (
		x_axis,
		y_axis,
		width,
		height,
		commentText,
		comment_id
	) => {
		// Ensure token is available
		if (!token) return;

		// Initialize FormData
		bodyFormData.append("image_id", image_details_view.id);
		bodyFormData.append("x_axis", x_axis);
		bodyFormData.append("y_axis", y_axis);
		bodyFormData.append("width", width);
		bodyFormData.append("height", height);
		bodyFormData.append("comment", commentText);
		bodyFormData.append("parent_id", comment_id);
		bodyFormData.append("comment_id", 0);

		// Make the API request
		// request
		// 	.post(apiURL + CUSTOMER_UPDATE_COMMENTS_TO_POST_IMAGE, bodyFormData)
		// 	.then((response) => {
		// 		fetchCommentsMarker(image_details_view.id);
		// 		setCommentsAddSections(false);
		// 		setEditComments("");
		// 		setUpdatedCommentsBox("");
		// 	})
		// 	.catch((err) => {
		// 		let message =
		// 			typeof err.response !== "undefined"
		// 				? err.response.data.message
		// 				: err.message;
		// 		setError(message);
		// 	});
	};
	const replyUpdatedCommentsText = (
		x_axis,
		y_axis,
		width,
		height,
		commentText,
		parent_id,
		comment_id
	) => {
		// Ensure token is available
		if (!token) return;

		// Initialize FormData
		bodyFormData.append("image_id", image_details_view.id);
		bodyFormData.append("x_axis", x_axis);
		bodyFormData.append("y_axis", y_axis);
		bodyFormData.append("width", width);
		bodyFormData.append("height", height);
		bodyFormData.append("comment", commentText);
		bodyFormData.append("parent_id", parent_id);
		bodyFormData.append("comment_id", comment_id);

		// Make the API request
		// request
		// 	.post(apiURL + CUSTOMER_UPDATE_COMMENTS_TO_POST_IMAGE, bodyFormData)
		// 	.then((response) => {
		// 		fetchCommentsMarker(image_details_view.id);
		// 		setCommentsAddSections(false);
		// 		setEditComments("");
		// 		setUpdatedCommentsBox("");
		// 	})
		// 	.catch((err) => {
		// 		let message =
		// 			typeof err.response !== "undefined"
		// 				? err.response.data.message
		// 				: err.message;
		// 		setError(message);
		// 	});
	};
	const handleCommentDelete = (deleteId) => {
		if (!token) return;
		// request
		// 	.get(apiURL + CUSTOMER_DELETE_COMMENTS_IMAGE + `/${deleteId}`)
		// 	.then((response) => {
		// 		fetchCommentsMarker(image_details_view.id);
		// 		setCommentsAddSections(false);
		// 		setEditComments("");
		// 		setUpdatedCommentsBox("");
		// 		// ToastAlert({
		// 		// 	message: `${response.data.data.message}`,
		// 		// 	autoClose: 2000,
		// 		// 	messageType: "success",
		// 		// });
		// 	})
		// 	.catch((err) => {
		// 		console.log(err);
		// 		// setDeleteResponse("Oops! Something went wrong!");
		// 	});
	};

	const handleWheel = useCallback(
		(e) => {
			if (!e) e = window.event;
			if (e.stopPropagation) e.stopPropagation();
			if (e.preventDefault) e.preventDefault();

			if (e.altKey) {
				// Zoom in/out when the Alt key is held while scrolling

				// Define minimum and maximum zoom values
				const minZoom = 0.5; // 50%
				const maxZoom = 3.0; // 300%
				const increment = 0.01; // 10% increment

				setZoom((prevZoom) => {
					const deltaZoom = e.deltaY > 0 ? -increment : increment;
					let newZoom = prevZoom + deltaZoom;

					// Ensure that the newZoom is within the bounds
					newZoom = Math.max(minZoom, Math.min(maxZoom, newZoom));

					// Map the newZoom value to the percentage range 50% to 300%
					const zoomPercentage = Math.round(
						((newZoom - minZoom) / (maxZoom - minZoom)) * 250 + 50
					);

					// Display the zoom percentage using a toast notification
					setZoomPercentage(`${zoomPercentage}%`);

					return newZoom;
				});

				setIsDragging(false);
				setRotation(0);
				setFlip(false);
			} else {
				// Handle scroll position when Alt key is not held
				const scrollPosition = Math.sign(e.deltaY);
			}
		},
		[] // Dependencies removed, since zoomPercentage and zoom are now handled within the state setter
	);
	// useEffect(() => {
	// 	if (!showModal) {
	// 		setZoomPercentage("");
	// 	}
	// 	setZoom(1);
	// 	setZoomPercentage("100%");
	// }, [showModal]);

	const handleKeyUp = (e) => {
		if (e.key === "Alt") {
			// Reset zoom when the Alt key is released
			setIsDragging(false);
			setFlip(false);
		}
	};
	// Clean up the event listeners when the component is unmounted
	// Clean up the event listeners when the component is unmounted
	// useEffect(() => {
	// 	if (zoom !== 0.500000000000042) {
	// 		document.addEventListener("wheel", handleWheel);
	// 		document.addEventListener("keyup", handleKeyUp);
	// 	} else if (zoom === 0.500000000000042) {
	// 		setZoom(1.189999999999966);
	// 		setZoomPercentage("88%");
	// 	}

	// 	return () => {
	// 		if (zoom !== 0.500000000000042) {
	// 			document.removeEventListener("wheel", handleWheel);
	// 			document.removeEventListener("keyup", handleKeyUp);
	// 		}
	// 	};
	// }, [zoom]);
	const options = selectLabel(group);
	const onCustomizedGroupSelect =
		// Array.isArray(options) &&
		group.find((option) => {
			if (group_name_select === "Customized Settings" && edit_select === null) {
				// If you need to return a specific option when conditions match, ensure it aligns with the option structure
				return option.value === "custom"; // Adjust based on your actual option structure
			} else if (edit_select !== "" && edit_select !== null) {
				return option.group_name === edit_select;
			}
			// return false;
		});
	const selectedOption = () => {
		if (!customizedSettings) {
			return (
				Array.isArray(group) &&
				group.find((option) => {
					if (
						group_name_select === "Customized Settings" &&
						edit_select === null
					) {
						// If you need to return a specific option when conditions match, ensure it aligns with the option structure
						return option.group_name === "custom"; // Adjust based on your actual option structure
					} else if (edit_select !== "" && edit_select !== null) {
						return option.group_name === edit_select;
					}
					return false;
				})
			);
		} else if (customizedSettings && onCustomizedGroupSelect) {
			return [
				{
					label: `${onCustomizedGroupSelect.group_name} (custom)`,
					id: onCustomizedGroupSelect.group_name,
				},
			];
		}
	};
	const value =
		group_name_select === "Customized Settings" && edit_select === null
			? [
					{
						label: `${group_name + "  "}  (custom)`,
						id: group_name,
					},
			  ]
			: group_name_select === "New Settings"
			? [
					{
						label: "New Settings",
						id: "New Settings",
					},
			  ]
			: selectedOption();

	const handleUpdateDuplicateSettings = () => {
		if (!token) {
			return; // Exit early if there's no token
		}
		const formDataValues = {
			// customer_id: customer_id || "",
			image_ids: image_details_view.id || "",
			image_name: duplicateImageName ? `${duplicateImageName},` : "",
			image_duplication_id: duplicateImageId ? `${duplicateImageId},` : "",
			edit_types: isCheckedEditIdtoString() || "",
			same_as_original: originalSize ? "1," : "0,",
			same_as_original_resizing: resizingOriginalSize ? "1," : "0,",
			background_removal_color_type: background_adjustment_color_type_duplicate,
			background_removal_color_code: background_removal_color_code_duplicate,
			background_adjustment_color_type:
				background_adjustment_color_type_duplicate,
			background_adjustment_color_code:
				background_adjustment_color_code_duplicate,
			resizing_height: resizing_height_duplicate
				? `${resizing_height_duplicate},`
				: "",
			resizing_width: resizing_width_duplicate
				? `${resizing_width_duplicate},`
				: "",
			file_format: file_format_duplicate_size
				? `${file_format_duplicate_size},`
				: "",
			margin_format: margin_left_duplicateSize
				? `${margin_left_duplicateSize},`
				: "",
			margin_top: margin_top_duplicateSize
				? `${margin_top_duplicateSize},`
				: "",
			margin_bottom: margin_bottom_duplicateSize
				? `${margin_bottom_duplicateSize},`
				: "",
			margin_right: margin_right_duplicateSize
				? `${margin_right_duplicateSize},`
				: "",
			margin_left: margin_left_duplicateSize
				? `${margin_left_duplicateSize},`
				: "",
		};

		const bodyFormData = new FormData();

		for (const key in formDataValues) {
			if (formDataValues.hasOwnProperty(key)) {
				bodyFormData.append(key, formDataValues[key]);
			}
		}

		request
			.post(apiURL + CO_ORDINATOR_DUPLICATE_IMAGES, bodyFormData)
			.then((response) => {
				// Handle success
				setDropdownOpen(false);
				setViewDuplicateModal(false);
				if (response.data.flag)
					ToastAlert({
						title: "Success",
						message: "Settings saved",
						autoClose: 2000,
						messageType: "success",
					});

				// handleFetchProfileSettings(setting_profile.id);
			})
			.catch((err) => {
				// Handle errors
				let message =
					typeof err.response !== "undefined"
						? err.response.data.message
						: err.message;
				setError(message);
			});
	};

	function formatTimestampToCustom(dateString) {
		const date = new Date(dateString);

		// Get current date for comparison
		const currentDate = new Date();

		// Format the time
		let hours = date.getUTCHours();
		let minutes = date.getUTCMinutes();
		const ampm = hours >= 12 ? "PM" : "AM";
		hours = hours % 12 || 12; // Convert to 12-hour format
		minutes = minutes < 10 ? "0" + minutes : minutes; // Add leading zero if needed

		// Determine if the date is "Today"
		const isToday = date.toDateString() === currentDate.toDateString();

		return `${hours}.${minutes} ${ampm} ${isToday ? "Today" : ""}`;
	}

	const fetchCommentsMarker = (id) => {
		if (token) {
			// request
			// 	.get(apiURL + CUSTOMER_GET_COMMENTS_IMAGE + `/${id}`)
			// 	.then((response) => {
			// 		//Set the success from the response
			// 		if (Array.isArray(response.data.data.comments)) {
			// 			const updatedComments = response.data.data.comments.map(
			// 				(comment) => {
			// 					// Assign the string "square" to the `type` property
			// 					comment.type = "square";
			// 					// Add the `backgroundColor` field with the value "#ffffff00"
			// 					comment.backgroundColor = "#ffffff00";
			// 					return comment;
			// 				}
			// 			);
			// 			setDrawingMode("square");
			// 			setCommentsMarker([...updatedComments]);
			// 			// setCommentsMarker((prev) => {
			// 			// 	const combinedData = [prev, ...updatedComments];
			// 			// 	// Create a map to track unique objects based on `comment_id`
			// 			// 	const uniqueComments = new Map();
			// 			// 	combinedData.forEach((comment) => {
			// 			// 		// Assuming `comment_id` is the unique identifier
			// 			// 		uniqueComments.set(comment.comment_id, comment);
			// 			// 	});
			// 			// 	// Convert the map values back into an array
			// 			// 	return Array.from(uniqueComments.values());
			// 			// });
			// 		} else {
			// 			// Handle the case where comments is not an array (if necessary)
			// 			console.error("comments is not an array");
			// 		}
			// 		// if (setdrag) {
			// 		// 	let existingComments = [...annotationsComments];
			// 		// 	let commentIndex = existingComments.findIndex(function (item) {
			// 		// 		return item.comment_id == setdrag;
			// 		// 	});
			// 		// 	if (commentIndex >= 0) {
			// 		// 		let element = document.getElementById(
			// 		// 			`parent_comment_holder_${commentIndex}`
			// 		// 		);
			// 		// 		element.setAttribute("dragged", "completed");
			// 		// 	}
			// 		// 	setdraggedElement(true);
			// 		// }
			// 		// if (deleteIndex >= 0) {
			// 		// 	setTimeout(() => {
			// 		// 		showComments(deleteIndex);
			// 		// 	}, 20);
			// 		// }
			// 	})
			// 	.catch((err) => {
			// 		let message =
			// 			typeof err.response !== "undefined"
			// 				? err.response.data.message
			// 				: err.message;
			// 		setError(message);
			// 	});
		}
	};

	const returntheDrawnJson = () => {
		// Filter out objects where `customer_id` is not 2
		const filteredComments = commentsMarker.filter(
			(comment) => !comment.customer_id
		);

		// Create a map to track unique objects based on `comment_id`
		const uniqueComments = new Map();

		filteredComments.forEach((comment) => {
			// Assuming `comment_id` is the unique identifier
			uniqueComments.set(comment.comment_id, comment);
		});

		// Convert the map values back into an array of unique comments
		return Array.from(uniqueComments.values());
	};

	const filteredComments = useCallback(
		(commentMark) => {
			return commentMark.filter((item) => {
				// Only include items where comment_id is present
				return !!item.comment_id;
			});
		},
		[commentsMarker]
	);

	const handleBackGroundRemovalCheckboxDuplicate = (
		id,
		checked = false,
		action = ""
	) => {
		switch (id) {
			case 1:
				setSelectedDuplicateSettings(checked);
				break;
			case 2:
				setSelectedDuplicateSettings1(checked);
				break;
			case 3:
				setSelectedDuplicateSettings2(checked);
				break;
			case 15:
				setSelectedDuplicateSettings3(checked);
				break;
			case 6:
				setSelectedDuplicateSettings3(checked);
				break;
			default:
				break;
		}

		// Optionally reset fields based on action
		if (!checked) {
			resetFieldsDuplicate(id);
		}
	};
	const getDisabledStatus = (group_name_select) => {
		if (group_name_select === "New Settings" && newSettingsClick === true) {
			return true;
		} else if (
			group_name_select === "New Settings" &&
			newSettingsClick === false
		) {
			return true;
		} else if (group_name_select === "") {
			return false;
		} else if (group_name_select === "Customized Settings") {
			return false;
		} else {
			return true; // default value if none of the conditions are met
		}
	};
	const toggleFieldCustomized = (checked, setting_profile) => {
		if (Array.isArray(setting_profile)) {
			customizedSettingsEnabled(checked);
			handleProfileChange(selectLabel([setting_profile.custom_edit_group]));
			setEditorType(setting_profile.custom_edit_group.id);
			setEditGroupNameSelect(setting_profile.custom_edit_group.group_name);

			setNewSettingsClicked(false);
			setGroupSelect((val) => !val);
		}
		return;
	};

	const handleAddComments = () => {
		if (!token) return;

		const formDataList = returntheDrawnJson().map((item) => {
			bodyFormData.append("image_id", image_details_view.id);
			bodyFormData.append("x_axis", item.x_axis);
			bodyFormData.append("y_axis", item.y_axis);
			bodyFormData.append("width", item.width);
			bodyFormData.append("height", item.height);
			bodyFormData.append("comment", commentsText);
			bodyFormData.append("parent_id", 0);
			bodyFormData.append("comment_id", 0);
			return bodyFormData;
		});

		// Promise.all(
		// 	formDataList.map((formData) =>
		// 		request
		// 			.post(apiURL + CUSTOMER_UPDATE_COMMENTS_TO_POST_IMAGE, formData)
		// 			.then((response) => {
		// 				fetchCommentsMarker(image_details_view.id);
		// 				setCommentsAddSections(false);
		// 			})
		// 			.catch((err) => {
		// 				let message =
		// 					typeof err.response !== "undefined"
		// 						? err.response.data.message
		// 						: err.message;
		// 				setError(message);
		// 			})
		// 	)
		// )
		// 	.then((responses) => {
		// 		// Handle successful responses if needed
		// 	})
		// 	.catch((err) => {
		// 		const message = err.response?.data?.message || err.message;
		// 		setError(message);
		// 	});
	};
	useEffect(() => {
		if (drawingMode === "square") {
			fetchCommentsMarker(image_details_view.id);
		} else if (drawingMode === "drag") {
			setCommentsMarker([]);
		}
	}, [image_details_view.id, drawingMode]);

	const handleMouseEnter = (itemId) => {
		// Set the ID of the item to be hovered
		setHoveredItemId(itemId);
	};

	const handleMouseLeave = () => {
		// Clear the hovered item when leaving the element
		setHoveredItemId(null);
	};

	const toggleDropdown = () => {
		setDropdownOpen(!isDropdownOpen);
	};

	function LocalImageLoader({ src, width, quality }) {
		return <Loader />;
	}
	const isEmptyObject = (obj) => {
		return Object.keys(obj).length !== 0;
	};
	const activateDragMode = () => {
		setDrawingMode("drag");
		setActivateTab("drag");
		resetCanvas();
	};
	// Retrieve customer information from sessionStorage
	let customerData = sessionStorage.getItem("customer");
	let customer = { full_name: "" };
	if (customerData !== undefined && customerData !== null) {
		try {
			customer = JSON.parse(customerData);
		} catch (error) {
			console.error("Error parsing customer data:", error);
			// sessionStorage.clear();
		}
	}

	const file = [
		{ label: "JPEG", value: 355 },
		{ label: "PNG", value: 54 },
		{ label: "JPG", value: 54 },
	];
	const selectBackgrondRemoval = [
		{ label: "Custom", value: 355 },
		{ label: "Transparent", value: 54 },
	];
	const selectedAmount = [
		{ label: "1", value: 355 },
		{ label: "2", value: 54 },
		{ label: "3", value: 54 },
	];
	const dpi = [
		{ label: "JPEG", value: 355 },
		{ label: "PNG", value: 54 },
		{ label: "JPG", value: 54 },
	];

	const ColorMode = [
		{ label: "Original", value: 355 },
		{ label: "Edited", value: 54 },
	];
	const Align = [
		{ label: "center", value: 355 },
		{ label: "start", value: 54 },
		{ label: "end", value: 55 },
	];

	const Margin = [
		{ label: "pixel", value: 355 },
		{ label: "em", value: 54 },
		{ label: "rem", value: 55 },
		{ label: "%", value: 56 },
	];
	useEffect(() => {
		if (isEmptyObject(viewDuplicateSettingsById) === true) {
			setBackgroundRemovalColorTypeDuplicate(
				viewDuplicateSettingsById.background_removal_color_type
			);
			setBackgroundRemovalColorCodeDuplicate(
				viewDuplicateSettingsById.background_removal_color_code
			);
			setBackgroundAdjustmentColorCodeDuplicate(
				viewDuplicateSettingsById.background_adjustment_color_code
			);
			setBackgroundAdjustmentColorTypeDuplicate(
				viewDuplicateSettingsById.background_adjustment_color_type
			);
			setResizingHeightDuplicate(viewDuplicateSettingsById.resizing_height);
			setResizingWidthDuplicate(viewDuplicateSettingsById.resizing_width);
			setResizingOriginalSize(
				viewDuplicateSettingsById.same_as_original_resizing === 0 ? false : true
			);
			setFileFormatDuplicateSize(viewDuplicateSettingsById.file_format);
			setMarginFormatDuplicateSize(viewDuplicateSettingsById.margin_format);
			setMarginTopDuplicateSize(viewDuplicateSettingsById.margin_top);
			setMarginBottomDuplicateSize(viewDuplicateSettingsById.margin_bottom);
			setMarginLeftDuplicateSize(viewDuplicateSettingsById.margin_left);
			setMarginRightDuplicateSize(viewDuplicateSettingsById.margin_right);
			setEditId(viewDuplicateSettingsById.extra_edit_types);
		}
	}, [viewDuplicateSettingsById]);
	useEffect(() => {
		setDrawingMode("drag");
		setActivateTab("drag");
		setIsDraggingCanvas(true);
		setIsDragging(true);
	}, []);

	const handleTextChange = (updatedText) => {
		if (currentAnnotation) {
			setCurrentAnnotation({
				...currentAnnotation,
				text: updatedText,
			});

			// Create a copy of annotations with the updated text
			const updatedAnnotations = annotations.map((annotation) =>
				annotation === currentAnnotation
					? { ...annotation, text: updatedText }
					: annotation
			);

			setAnnotations(updatedAnnotations);
		}
	};
	/**
	 * Handle the selection of checkboxes and update the `editId` state accordingly.
	 * @param {ChangeEvent} changeEvent - The change event object.
	 */
	const handleAddCheckboxSelect = (changeEvent) => {
		changeEvent.persist();

		const editItem = changeEvent.target.value;

		// Update the state
		setEditId((prevState = []) => {
			// Check if the item is already in the state array
			if (prevState.includes(editItem)) {
				// If the item is in the array, filter it out and return the new array
				return prevState.filter((el) => el !== editItem);
			}
			// If the item is not in the array, add it to the array and return the new array
			return [...prevState, editItem];
		});
	};
	const resetCanvas = () => {
		const canvas = canvasRef.current;
		setDx(0);
		setDy(0);
		setZoom(1);
		if (!canvas) {
			console.error("Canvas not found!");
			return;
		}
		setCurrentImage("");

		const context = canvas.getContext("2d");

		// Debugging logs
		console.log("Canvas width:", canvas.width);
		console.log("Canvas height:", canvas.height);
		console.log("Context:", context);

		// Reset the transformation matrix
		context.setTransform(1, 0, 0, 1, 0, 0);
		console.log("Transformation reset");

		// Clear the canvas
		context.clearRect(0, 0, canvas.width, canvas.height);
		context.fillRect(0, 0, canvas.width, canvas.height);

		console.log("Canvas cleared");

		// Reset states
		setTransformPosition({ x: 0, y: 0 });
		// setZoomLevel(defaultZoomLevel);
		setIsDragging(false);
		isDrawing.current = false;
		console.log("States reset");
	};
	// useEffect(() => {
	// 	resetCanvas();
	// }, []);
	/**
	 * Handles to check the option value has changed and reset the state for all the checkboxes.
	 * @param {Number} id - The id to determine the checkbox dependent field which to reset.
	 * @returns {void}
	 */
	const resetFields = (id) => {
		if (id === 1) {
			setBackgroundRemovalColorType("");
			setBackgroundRemovalColorCode("");
		} else if (id === 2) {
			setBackgroundAdjustmentColorCode("");
			setBackgroundAdjustmentColorType("");
		} else if (id === 3) {
			setResizingFileFormat("");
			setResizingHeight("");
			setResizingWidth("");
			setResizingDpi("");
			setResizingDpiType("");
			setResizingColorMode("");
			setResizingAlignment("");
			setResizingMarginFormat("");
			setResizingMarginTop("");
			setResizingMarginBottom("");
			setResizingMarginRight("");
			setResizingMarginLeft("");
		}
	};
	const resetFieldsDuplicate = (id) => {
		if (id === 1) {
			setBackgroundRemovalColorTypeDuplicate("");
			setBackgroundRemovalColorCodeDuplicate("");
		} else if (id === 2) {
			setBackgroundAdjustmentColorCodeDuplicate("");
			setBackgroundAdjustmentColorTypeDuplicate("");
		} else if (id === 3) {
			setResizingHeightDuplicate("");
			setResizingWidthDuplicate("");
		}
	};
	/* Handle the checkbox state and perform actions based on the given id.
	 * @param {Number} id - The id to determine the checkbox action.
	 * @param {Boolean} checked - The checked/unchecked after action of checkbox clicked.
	 */
	const handleBackGroundRemovalCheckbox = (id, checked = false) => {
		if (id === 1) {
			setSelected(checked);
			setChecked((val) => !val);
		}
		if (id === 2) {
			setSelected1(checked);
			setChecked1((val) => !val);
		}
		if (id === 3) {
			setSelected2(checked);
			setChecked2((val) => !val);
		}
		if (id === 6 || id === 15) {
			setSelected3(checked);
			setChecked3((val) => !val);
		}
		// if(action!=="Edit"){
		resetFields(id);
		// }
	};
	/**
	 * Handles to get all the checked values of checkbox by class name.
	 * @returns {void}
	 */
	const getEditTypeValues = () => {
		const checkedValues = document.querySelectorAll(".et_options");
		const checkedValue = [];
		checkedValues.forEach((element) => {
			if (element.checked) {
				checkedValue.push(element.value);
			}
		});
		return checkedValue.length > 0 ? checkedValue.join(",") : "";
	};

	/**
	 * Handles the update of an image edit group by sending a POST request to the server.
	 * @returns {void}
	 */
	const handleUpdateImageEditGroup = () => {
		if (token) {
			setLoader(true);
			// Append form data with the necessary parameters
			const editTypeId = getEditTypeValues();
			bodyFormData.append("customer_id", customer_id);
			bodyFormData.append(
				"image_ids",
				image_details_view !== undefined &&
					JSON.stringify(image_details_view.id)
			);

			if (edit_select > 0) {
				bodyFormData.append("edit_group_id", edit_select);
			} else {
				bodyFormData.append(
					"extra_edit_type_ids",
					group_name_select === "New Settings" ||
						group_name_select === "Customized Settings"
						? editTypeId
						: ""
				);
			}

			bodyFormData.append("is_overright", 1);
			bodyFormData.append(
				"background_removal_color_type",
				background_removal_color_type
			);
			bodyFormData.append(
				"background_removal_color_code",
				background_removal_color_code
			);
			bodyFormData.append(
				"background_adjustment_color_type",
				background_adjustment_color_type
			);
			bodyFormData.append(
				"background_adjustment_color_code",
				background_adjustment_color_code
			);
			bodyFormData.append("resizing_height", resizing_height);
			bodyFormData.append("resizing_width", resizing_width);

			bodyFormData.append("image_file_format", image_file_format);
			bodyFormData.append("image_height", image_height);
			bodyFormData.append("image_width", image_width);
			bodyFormData.append("image_dpi", image_dpi);
			bodyFormData.append("image_dpi_type", image_dpi_type);
			bodyFormData.append("image_color_mode", image_color_mode);
			bodyFormData.append("image_alignment", image_alignment);
			bodyFormData.append("image_margin_format", image_margin_format);
			bodyFormData.append("image_margin_top", image_margin_top);
			bodyFormData.append("image_margin_bottom", image_margin_bottom);
			bodyFormData.append("image_margin_right", image_margin_right);
			bodyFormData.append("image_margin_left", image_margin_left);

			request
				.post(apiURL + CO_ORDINATOR_UPDATE_EDIT_GROUP, bodyFormData)
				.then((response) => {
					//Set the success from the response

					setStatus("Successfully Group updated");
					setLoader(false);
					ToastAlert({
						title: response.data.status ? "Success" : "Error",
						message: response.data.status ? "Settings saved" : "Error",
						autoClose: 2000,
						messageType: response.data.status ? "success" : "error",
					});

					if (response.data.status) {
						request
							.get(
								apiURL +
									ADMIN_GET_COORDINATORS_DETAILS +
									`/${currentUserId}` +
									`?batch_number=${batchId}`
							)
							.then((response) => {
								setParentFolderDetails(null);
								setFolderName("");
								// setEditGroups(response.data.data.editGroups);
								// setEditTypes(response.data.data.editTypes);
								setBatchNumber(response.data.data.batchNumbers);
								setImages(response.data.data.images);
								setFolderList(response.data.data.folders);
								setFolder(response.data.data.folders);
								setImageMainFolder(response.data.data.images);
								setFolderMainFolder(response.data.data.folders);
								setMainImageCount(response.data.data.mainImageCount);
								setMainImageSettingsCount(
									response.data.data.mainImageSettingsCount
								);
								setOrderNumber(response.data.data.batchNumbers[0].order_id);
								setSubFolderOpen(false); // Clear subFolderDetails as we are at the parent level
								setDisplay(false);
								setMultipleImagesId([]);
								setClickSelect(false);
							})
							.catch((err) => console.log(err));
					}
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	const handleUpdateEditGroup = () => {
		if (token) {
			setLoader(true);
			// Append form data with the necessary parameters
			const editTypeId = getEditTypeValues();
			bodyFormData.append("customer_id", customer_id);
			bodyFormData.append(
				"image_ids",
				image_details_view !== undefined &&
					JSON.stringify(image_details_view.id)
			);
			bodyFormData.append("is_overright", 1);
			bodyFormData.append("extra_edit_type_ids", editTypeId);

			bodyFormData.append(
				"background_removal_color_type",
				background_removal_color_type
			);
			bodyFormData.append(
				"background_removal_color_code",
				background_removal_color_code
			);
			bodyFormData.append(
				"background_adjustment_color_type",
				background_adjustment_color_type
			);
			bodyFormData.append(
				"background_adjustment_color_code",
				background_adjustment_color_code
			);
			bodyFormData.append("resizing_height", resizing_height);
			bodyFormData.append("resizing_width", resizing_width);

			bodyFormData.append("image_file_format", image_file_format);
			bodyFormData.append("image_height", image_height);
			bodyFormData.append("image_width", image_width);
			bodyFormData.append("image_dpi", image_dpi);
			bodyFormData.append("image_dpi_type", image_dpi_type);
			bodyFormData.append("image_color_mode", image_color_mode);
			bodyFormData.append("image_alignment", image_alignment);
			bodyFormData.append("image_margin_format", image_margin_format);
			bodyFormData.append("image_margin_top", image_margin_top);
			bodyFormData.append("image_margin_bottom", image_margin_bottom);
			bodyFormData.append("image_margin_right", image_margin_right);
			bodyFormData.append("image_margin_left", image_margin_left);

			request
				.post(apiURL + CO_ORDINATOR_UPDATE_EDIT_GROUP, bodyFormData)
				.then((response) => {
					//Set the success from the response

					setStatus("Successfully Group updated");
					setLoader(false);
					ToastAlert({
						title: response.data.status ? "Success" : "Error",
						message: response.data.status ? "Settings saved" : "Error",
						autoClose: 2000,
						messageType: response.data.status ? "success" : "error",
					});
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	useEffect(() => {
		if (idSelected) {
			sessionStorage.setItem("selectImageID", idSelected);
			setEditorAssigned(false);
		}
	}, [idSelected]);
	useEffect(() => {
		if (editGroups !== undefined) {
			// Create a new array by combining existing group values and the new object
			const updatedGroup = [
				...editGroups,
				{ id: "", group_name: "New Settings" },
			];
			setGroup(updatedGroup);
			setSelected(false);
			setSelected1(false);
			setSelected3(false);
		}
	}, [editGroups]);
	useEffect(() => {
		if (handleProfileChangedValue && edit_select > 0) {
			request
				.get(`${apiURL}${CO_ORDINATOR_VIEW_EDIT_GROUP}/${edit_select}`)
				.then((response) => {
					const et_head = document.getElementById("et_head");
					if (et_head) {
						et_head.scrollTo({ top: 0, behavior: "smooth" });
					}

					const data = Array.isArray(response.data.data)
						? response.data.data
						: [];
					console.log("Settings called based on id", response.data);
					setEditGroupNameSelect("");

					// Extract IDs safely
					const editTypesArray =
						data && data.edit_group && Array.isArray(data.edit_group.edit_types)
							? data.edit_group.edit_types
							: [];
					const ids = editTypesArray.map((item) => item.id);
					setEditId(removeDuplicatesWithSet(ids));

					// Set edit types
					if (!editTypes) {
						setEditTypes(editTypesArray);
					}

					// Set edit group and related settings
					setViewEditGroup(data);

					const backgroundSettings =
						data &&
						data.edit_group &&
						Array.isArray(data.edit_group.background_settings)
							? data.edit_group.background_settings
							: [];
					setBackgroundRemovalColorCode(
						(backgroundSettings[0] && backgroundSettings[0].color_code) || ""
					);
					setBackgroundAdjustmentColorCode(
						(backgroundSettings[1] && backgroundSettings[1].color_code) || ""
					);
					setBackgroundRemovalColorType(
						(backgroundSettings[0] && backgroundSettings[0].color_type) || ""
					);
					setBackgroundAdjustmentColorType(
						(backgroundSettings[1] && backgroundSettings[1].color_type) || ""
					);

					const settings =
						data && data.edit_group && data.edit_group.setting
							? data.edit_group.setting
							: {};
					setImageFileFormat(settings.file_format || "");
					setImageDpiType(settings.dpi_type || "");
					setImageColorMode(settings.color_mode || "");
					setImageAlignment(settings.alignment || "");
					setImageMarginFormat(settings.margin_format || "");
					setImageHeight(settings.height || "");
					setImageWidth(settings.width || "");
					setImageDpi(settings.dpi || "");
					setImageMarginTop(settings.margin_top || "");
					setImageMarginBottom(settings.margin_bottom || "");
					setImageMarginRight(settings.margin_right || "");
					setImageMarginLeft(settings.margin_left || "");

					// Handle ID checks
					const idCheck = (types, id, setChecked) => {
						const exists = types && types.some((item) => item.id === id);
						setChecked(!!exists);
					};

					idCheck(editTypesArray, 1, setChecked);
					idCheck(editTypesArray, 2, setChecked1);

					// Resizing settings
					const resizingSettings =
						data &&
						data.edit_group &&
						Array.isArray(data.edit_group.resizing_settings)
							? data.edit_group.resizing_settings
							: [];
					if (resizingSettings.length === 1) {
						setChecked2(true);
						setResizingHeight(
							(resizingSettings[0] && resizingSettings[0].height) || ""
						);
						setResizingWidth(
							(resizingSettings[0] && resizingSettings[0].width) || ""
						);
					} else {
						setChecked2(false);
						setChecked3(false);
						setResizingHeight("");
						setResizingWidth("");
					}

					if (!resizingSettings.length) {
						setChecked2(false);
					}
				})
				.catch((err) => {
					const message =
						err &&
						err.response &&
						err.response.data &&
						err.response.data.message
							? err.response.data.message
							: err.message || "An error occurred";
					setError(message);
				});
		}
	}, [edit_select]);

	useEffect(() => {
		// Fetch data from the API endpoint
		if (edit_select > 0) {
			request
				.get(
					`${apiURL}` + `${CO_ORDINATOR_VIEW_EDIT_GROUP}` + `/${edit_select}`
				)
				.then((response) => {
					// Set View for edit group from the response
					const data = response.data;
					setViewEditGroup(data);

					const backgroundSettings =
						data.edit_group &&
						Array.isArray(data.edit_group.background_settings)
							? data.edit_group.background_settings
							: [];

					setBackgroundRemovalColorCode(
						backgroundSettings[0] && backgroundSettings[0].color_code
							? backgroundSettings[0].color_code
							: ""
					);
					setBackgroundAdjustmentColorCode(
						backgroundSettings[1] && backgroundSettings[1].color_code
							? backgroundSettings[1].color_code
							: ""
					);

					setBackgroundRemovalColorType(
						backgroundSettings[0] && backgroundSettings[0].color_type
							? backgroundSettings[0].color_type
							: "Select Color Type"
					);
					setBackgroundAdjustmentColorType(
						backgroundSettings[1] && backgroundSettings[1].color_type
							? backgroundSettings[1].color_type
							: "Select Color Type"
					);

					const resizingSettings =
						data.edit_group && Array.isArray(data.edit_group.resizing_settings)
							? data.edit_group.resizing_settings
							: [];

					setResizingFileFormat(
						resizingSettings[0] && resizingSettings[0].file_format
							? resizingSettings[0].file_format
							: "Select File Format"
					);
					setResizingDpiType(
						resizingSettings[0] && resizingSettings[0].dpi_type
							? resizingSettings[0].dpi_type
							: "Select Dpi Type"
					);
					setResizingColorMode(
						resizingSettings[0] && resizingSettings[0].color_mode
							? resizingSettings[0].color_mode
							: "Select Color Mode"
					);
					setResizingAlignment(
						resizingSettings[0] && resizingSettings[0].alignment
							? resizingSettings[0].alignment
							: "Select Alignment"
					);
					setResizingMarginFormat(
						resizingSettings[0] && resizingSettings[0].margin_format
							? resizingSettings[0].margin_format
							: "Select Margin Format"
					);

					const settings =
						data.edit_group && data.edit_group.setting
							? data.edit_group.setting
							: {};

					setImageFileFormat(settings.file_format || "Select File Format");
					setImageDpiType(settings.dpi_type || "Select Dpi Type");
					setImageColorMode(settings.color_mode || "Select Color Mode");
					setImageAlignment(settings.alignment || "Select Alignment");
					setImageMarginFormat(settings.margin_format || "Select Margin");

					if (backgroundSettings.length) {
						setSelected(true);
						setSelected1(true);
					}

					if (resizingSettings.length) {
						setSelected2(true);
					}
				})
				.catch((err) => {
					const message =
						err &&
						err.response &&
						err.response.data &&
						err.response.data.message
							? err.response.data.message
							: err.message || "An error occurred";
					setError(message);
				});
		} else {
			if (!customizedSettings) {
				// Reset states if edit_select is empty
				// setViewEditGroup({});
				// setBackgroundRemovalColorCode("");
				// setBackgroundAdjustmentColorCode("");
				// setBackgroundRemovalColorType("Select Color Type");
				// setBackgroundAdjustmentColorType("Select Color Type");
				// setResizingFileFormat("Select File Format");
				// setResizingDpiType("Select Dpi Type");
				// setResizingColorMode("Select Color Mode");
				// setResizingAlignment("Select Alignment");
				// setResizingMarginFormat("Select Margin Format");
				// setImageFileFormat("Select File Format");
				// setImageDpiType("Select Dpi Type");
				// setImageColorMode("Select Color Mode");
				// setImageAlignment("Select Alignment");
				// setImageMarginFormat("Select Margin");
			}
		}
	}, [edit_select, token]);

	useEffect(() => {
		if (
			setting_profile &&
			setting_profile.id &&
			setting_profile.edit_group_id === null &&
			setting_profile.extra_edit_types
		) {
			// Create a new array by combining existing group values and the new object
			if (editGroups || props.editGroups) {
				const copygGroup =
					editGroups !== undefined ? editGroups : props.editGroups;
				const updatedGroup = [
					{ id: "Customized Settings", group_name: "Customized Settings" },
					{ id: "New Settings", group_name: "New Settings" },
					...copygGroup,
				];
				setGroup(updatedGroup);
			}

			setEditGroupNameSelect("Customized Settings");
			setEditorType("Customized Settings");
			setViewEditGroup(setting_profile !== undefined && setting_profile);
			if (
				setting_profile !== undefined &&
				setting_profile &&
				setting_profile.extra_edit_types &&
				setting_profile.extra_edit_types.indexOf("1") >= 0
			) {
				setSelected(true);
			} else {
				setSelected(false);
			}
			if (
				setting_profile !== undefined &&
				setting_profile &&
				setting_profile.extra_edit_types &&
				setting_profile.extra_edit_types.indexOf("2") >= 0
			) {
				setSelected1(true);
			} else {
				setSelected1(false);
			}
			if (
				setting_profile !== undefined &&
				setting_profile &&
				setting_profile.extra_edit_types &&
				setting_profile.extra_edit_types.indexOf("3") >= 0
			) {
				setSelected2(true);
			} else {
				setSelected2(false);
			}
			if (
				setting_profile !== undefined &&
				setting_profile &&
				setting_profile.extra_edit_types &&
				(setting_profile.extra_edit_types.indexOf("6") >= 0 ||
					setting_profile.extra_edit_types.indexOf("15") >= 0)
			) {
				setSelected3(true);
			} else {
				setSelected3(false);
			}
			customizedSettingsEnabled(true);
			setBackgroundRemovalColorCode(
				setting_profile !== undefined &&
					setting_profile.background_settings[0] &&
					setting_profile.background_settings[0].edit_type_id === 1
					? setting_profile.background_settings[0].color_code
					: ""
			);
			setBackgroundAdjustmentColorCode(
				setting_profile !== undefined &&
					setting_profile.background_settings[1] &&
					setting_profile.background_settings[1].edit_type_id === 2
					? setting_profile.background_settings[1].color_code
					: ""
			);
			setBackgroundRemovalColorType(
				setting_profile !== undefined &&
					setting_profile.background_settings[0] &&
					setting_profile.background_settings[0].edit_type_id === 1
					? setting_profile.background_settings[0].color_type
					: ""
			);
			setBackgroundAdjustmentColorType(
				setting_profile !== undefined &&
					setting_profile.background_settings[1] &&
					setting_profile.background_settings[1].edit_type_id === 2
					? setting_profile.background_settings[1].color_type
					: ""
			);
			// setResizingFileFormat("");
			// setResizingDpiType("");
			// setResizingColorMode("");
			// setResizingAlignment("");
			// setResizingMarginFormat("");
			// setImageFileFormat("");
			// setImageDpiType("");
			setResizingHeight(
				setting_profile !== undefined &&
					setting_profile.resizing_settings[0] &&
					setting_profile.resizing_settings[0].height
					? setting_profile.resizing_settings[0].height
					: ""
			);
			setResizingWidth(
				setting_profile !== undefined &&
					setting_profile.resizing_settings[0] &&
					setting_profile.resizing_settings[0].width
					? setting_profile.resizing_settings[0].width
					: ""
			);
			// Output specification fields
			setImageFileFormat(
				setting_profile !== undefined && setting_profile.file_format
					? setting_profile.file_format
					: ""
			);
			setImageHeight(
				setting_profile !== undefined && setting_profile.height
					? setting_profile.height
					: ""
			);
			setImageWidth(
				setting_profile !== undefined && setting_profile.width
					? setting_profile.width
					: ""
			);
			setImageDpi(
				setting_profile !== undefined && setting_profile.dpi
					? setting_profile.dpi
					: ""
			);
			setImageDpiType(
				setting_profile !== undefined && setting_profile.dpi_type
					? setting_profile.dpi_type
					: ""
			);
			setImageColorMode(
				setting_profile !== undefined && setting_profile.color_mode
					? setting_profile.color_mode
					: ""
			);
			setImageAlignment(
				setting_profile !== undefined && setting_profile.alignment
					? setting_profile.alignment
					: ""
			);
			setImageMarginFormat(
				setting_profile !== undefined && setting_profile.margin_format
					? setting_profile.margin_format
					: ""
			);
			setImageMarginTop(
				setting_profile !== undefined && setting_profile.margin_top
					? setting_profile.margin_top
					: ""
			);
			setImageMarginBottom(
				setting_profile !== undefined && setting_profile.margin_bottom
					? setting_profile.margin_bottom
					: ""
			);
			setImageMarginRight(
				setting_profile !== undefined && setting_profile.margin_right
					? setting_profile.margin_right
					: ""
			);
			setImageMarginLeft(
				setting_profile !== undefined && setting_profile.margin_left
					? setting_profile.margin_left
					: ""
			);
		}
	}, [image_details_view.id]);

	useEffect(() => {
		if (
			image_details_view !== undefined &&
			image_details_view.status !== undefined &&
			// image_details_view.status === "assign_to_editor" &&
			image_details_view.edit_group_id !== null
			// images !== undefined
		) {
			setEditorType(image_details_view.edit_group_id);
		} else {
			// setViewEditGroup({});
			// setBackgroundRemovalColorCode("");
			// setBackgroundAdjustmentColorCode("");
			// setBackgroundRemovalColorType("");
			// setBackgroundAdjustmentColorType("");
			// setResizingFileFormat("");
			// setResizingDpiType("");
			// setResizingColorMode("");
			// setResizingAlignment("");
			// setResizingMarginFormat("");
			// setImageFileFormat("");
			// setImageDpiType("");
			// setImageColorMode(
			// 	data.edit_group.setting !== null
			// 		? data.edit_group.setting.color_mode
			// 		: "Select color mode"
			// );
			// setImageAlignment(
			// 	[]
			// );
			// setImageMarginFormat(
			// 	[]
			// );
		}
	}, [image_details_view, setEditorType, setViewEditGroup]);

	/**
	 * Handles to check the option value has changed and reset the state for all the checkboxes.
	 * @returns {void}
	 */

	useEffect(() => {
		if (edit_select > 0) {
			customizedSettingsEnabled(false);
		} else {
			if (!customizedSettings) {
				setSelected(false);
				setSelected1(false);
				setSelected2(false);
				setSelected3(false);
			}
			const et_head = document.getElementById("et_head");
			if (et_head) {
				et_head.scrollIntoView();
			}
		}
	}, [edit_select]);

	/**
	 * Create an array of checked values for the edit_types in view_edit_group
	 */
	function getEditTypesIds(view_edit_group) {
		if (
			view_edit_group &&
			view_edit_group.edit_group &&
			view_edit_group.edit_group.edit_types &&
			Array.isArray(view_edit_group.edit_group.edit_types)
		) {
			return view_edit_group.edit_group.edit_types.map((items, index) => {
				return items.id;
			});
		} else {
			// Handle the case where any of the properties is null or not an array
			// You can return a default value or handle the error appropriately.
			return []; // Returning an empty array as a default value
		}
	}

	// Usage
	let updateChecked = getEditTypesIds(view_edit_group);

	const toggleModal = () => {
		setShowModal(!showModal);
	};
	// const fetchComments = () => {
	// 	if (token) {
	// 		request
	// 			.get(apiURL + CUSTOMER_COMMENTS_MESSAGES + `/${image_details_view.id}`)
	// 			.then((response) => {
	// 				//Set the success from the response

	// 				setComments(response.data.data);
	// 			})
	// 			.catch((err) => {
	// 				let message =
	// 					typeof err.response !== "undefined"
	// 						? err.response.data.message
	// 						: err.message;
	// 				setError(message);
	// 			});
	// 	}
	// };
	// useEffect(() => {
	// 	fetchComments();
	// }, [image_details_view.id]);
	// const SendMessageAsaCustomer = () => {
	// 	if (token) {
	// 		bodyFormData.append("image_id", image_details_view.id);
	// 		bodyFormData.append("comment", message);

	// 		request
	// 			.post(apiURL + CUSTOMER_SEND_MESSAGES, bodyFormData)
	// 			.then((response) => {
	// 				//Set the success from the response

	// 				setStatus("Successfully Group updated");
	// 				fetchComments();
	// 				setMessage("");
	// 			})
	// 			.catch((err) => {
	// 				let message =
	// 					typeof err.response !== "undefined"
	// 						? err.response.data.message
	// 						: err.message;
	// 				setError(message);
	// 			});
	// 	}
	// };
	function parseDateString(dateString) {
		const dateObj = new Date(dateString);

		const day = dateObj.toLocaleString("en-US", { weekday: "long" });
		const date = dateObj.toLocaleDateString("en-US", {
			year: "numeric",
			month: "long",
			day: "numeric",
		});
		const time = dateObj.toLocaleTimeString("en-US", {
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
			timeZoneName: "short",
		});

		return {
			day,
			date,
			time,
		};
	}
	// Listen for the "keydown" event
	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.keyCode === 27) {
				// Escape key is pressed
				// Update state variables here
				localStorage.setItem("clickSelect", false);
				document.body.classList.remove("modal-open");
				setShowModal(false);
				setClickSelect(false);

				setEditorType(null);
				setViewEditGroup({});
				setBackgroundRemovalColorCode("");
				setBackgroundAdjustmentColorCode("");
				setBackgroundRemovalColorType("");
				setBackgroundAdjustmentColorType("");
				setResizingFileFormat("");
				setResizingDpiType("");
				setResizingColorMode("");
				setResizingAlignment("");
				setResizingMarginFormat("");
				setImageFileFormat("");
				setImageDpiType("");
				setViewDuplicateModal(false);

				setDropdownOpen(false);
			}
		};

		// Add event listener when the component mounts
		window.addEventListener("keydown", handleKeyDown);

		// Remove event listener when the component unmounts
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [modalRefImage, images]); // Empty dependency array means this effect runs once, like componentDidMount
	// Function to close the modal
	const closeModal = useCallback(() => {
		// Update state variables here
		localStorage.setItem("clickSelect", false);
		document.body.classList.remove("modal-open");
		setShowModal(false);
		setClickSelect(false);

		setEditorType(null);
		setViewEditGroup({});
		setBackgroundRemovalColorCode("");
		setBackgroundAdjustmentColorCode("");
		setBackgroundRemovalColorType("");
		setBackgroundAdjustmentColorType("");
		setResizingFileFormat("");
		setResizingDpiType("");
		setResizingColorMode("");
		setResizingAlignment("");
		setResizingMarginFormat("");
		setImageFileFormat("");
		setImageDpiType("");
		setViewDuplicateModal(false);
		setDrawingMode("drag");
		setActivateTab("drag");

		setDropdownOpen(false);
	}, [modalRefImage, images]);

	const handleOverlayClick = (e) => {
		if (e.target.classList.contains("modal-container--image")) {
			closeModal();
		} else if (e.target.classList.contains("close-icons-order--image")) {
			closeModal();
		}
	};
	const onChange = (text) => {
		setRenameTextDuplicate(text !== undefined && text);
	};
	const onSubmitDublicateImage = () => {
		if (token && renameTextDuplicate !== "" && renameId !== "") {
			bodyFormData.append("image_id", renameId);
			bodyFormData.append("name", renameTextDuplicate);
			request
				.post(apiURL + CO_ORDINATOR_DUPLICATE_IMAGE_RENAME, bodyFormData)
				.then((response) => {
					handleFetchProfileSettings(setting_profile.id);
					setRenameModalShow(false);
					//Set the success from the response
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	const onClickDuplicateImage = (id, imageName, imageExtensions) => {
		setRenameId(id);
		setImageName(imageName);
		setImageExtensions(imageExtensions);
		setRenameModalShow((val) => !val);
	};
	const svgCode = (count) => {
		return `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20" fill="none">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M6 4.5V0H10.5C10.8978 0 11.2794 0.158035 11.5607 0.43934C11.842 0.720644 12 1.10218 12 1.5V3H9L3 9V16H1.5C1.10218 16 0.720644 15.842 0.43934 15.5607C0.158035 15.2794 0 14.8978 0 14.5V6H4.5C4.89782 6 5.27936 5.84196 5.56066 5.56066C5.84196 5.27936 6 4.89782 6 4.5ZM5 4.5V0.25L0.25 5H4.5C4.63261 5 4.75979 4.94732 4.85355 4.85355C4.94732 4.75979 5 4.63261 5 4.5Z" fill="white"/>
		<path d="M10 4V8.5C10 8.89782 9.84196 9.27936 9.56066 9.56066C9.27936 9.84196 8.89782 10 8.5 10H4V18.5C4 18.8978 4.15804 19.2794 4.43934 19.5607C4.72064 19.842 5.10218 20 5.5 20H14.5C14.8978 20 15.2794 19.842 15.5607 19.5607C15.842 19.2794 16 18.8978 16 18.5V5.5C16 5.10218 15.842 4.72064 15.5607 4.43934C15.2794 4.15804 14.8978 4 14.5 4H10ZM9 4.25V8.5C9 8.63261 8.94732 8.75979 8.85355 8.85355C8.75979 8.94732 8.63261 9 8.5 9H4.25L9 4.25Z" fill="white"/>
		<!-- Use a <style> element to set the font size -->
			<style>
			  text {
				font-size: 9px;
				font-weight:500;
			  }
			</style>
			<!-- Adjust the y attribute to add margin on the top -->
			<text x="6" y="18" fill="green">${count}</text>
	  </svg>`;
	};
	const getImageExtension = (imageName) => {
		const lastDotIndex = imageName.lastIndexOf(".");
		if (lastDotIndex !== -1) {
			const extension = imageName.substring(lastDotIndex + 1);
			return extension;
		}
		return "No extension found";
	};
	const handleDelete = () => {
		if (!token) {
			return null;
		}
		// Make an HTTP POST request to delete the selected duplicate image
		request
			.delete(apiURL + CO_ORDINATOR_DELETE_DUPLICATE_IMAGE + `/${renameId}`)
			.then((response) => {
				// Log the response to the console
				handleFetchProfileSettings(setting_profile.id);

				// Close the delete confirmation modal
				setShowModalDelete(false);
			})
			.catch((err) => console.log(err));
	};
	const handleRenameOriginalImage = () => {
		if (token && renameTextDuplicate !== "" && renameId !== "") {
			bodyFormData.append("image_id", setting_profile.id);
			bodyFormData.append("name", renameTextDuplicate);
			request
				.post(apiURL + CO_ORDINATOR_ORIGINAL_IMAGE_RENAME, bodyFormData)
				.then((response) => {
					handleFetchProfileSettings(setting_profile.id);
					setRenameModalShow(false);
					//Set the success from the response
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	const handleRenameImage = () => {
		if (renameId === setting_profile.id) {
			return handleRenameOriginalImage();
		} else {
			onSubmitDublicateImage();
		}
	};

	//handled next item
	function getNextPage(data, currentId, itemsPerPage) {
		let startIndex = currentId
			? data.findIndex((item) => item.id === currentId)
			: -1;

		if (startIndex === -1) {
			// If the current ID is not found or not provided, start from the beginning.
			startIndex = 0;
		} else {
			startIndex += 1;
		}

		const endIndex = startIndex + itemsPerPage;
		const nextPage = data.slice(startIndex, endIndex);

		// Determine the next ID or wrap around to the first ID if necessary.
		let nextPageId = null;

		if (nextPage.length > 0) {
			nextPageId = data[startIndex].id;
		} else if (data.length > 0) {
			nextPageId = data[0].id;
		}

		return { nextPage, nextPageId };
	}
	// handled Previous Items
	function getPreviousPage(data, currentId, itemsPerPage) {
		let startIndex = currentId
			? data.findIndex((item) => item.id === currentId)
			: -1;
		setCommentsList(false);
		setCommentsMarker([]);

		if (startIndex === -1) {
			// If the current ID is not found or not provided, start from the last item.
			startIndex = data.length - itemsPerPage;
		} else {
			if (startIndex < 1) {
				startIndex = 0;
			} else {
				startIndex -= 1;
			}
		}

		const endIndex = startIndex + itemsPerPage;
		const previousPage = data.slice(startIndex, endIndex);

		// Determine the previous ID or wrap around to the last ID if necessary.
		let previousPageId = null;

		if (previousPage.length > 0) {
			previousPageId = previousPage[0].id;
		} else if (data.length > 0) {
			previousPageId = data[startIndex].id;
		}

		return { previousPage, previousPageId };
	}

	function showNextPage() {
		const { nextPage, nextPageId } = getNextPage(
			allImages,
			image_details_view.id,
			allImages.length
		);
		setCommentsList(false);
		setCommentsMarker([]);

		if (nextPage.length > 0) {
			// Display the nextPage data
			console.log(nextPage);

			// Update the current page ID
			setImageID(nextPageId);
			const filterImages =
				allImages !== undefined &&
				allImages.filter((obj) => obj.id === nextPageId)[0];
			setImageDetailsView(filterImages);
			resetCanvas();
			setViewEditGroup({});
		} else {
			// No more data to show
			console.log("No more data to display.");
		}
	}
	function showPreviousPage() {
		// if (images && images.length > 0) {
		const { previousPage, previousPageId } = getPreviousPage(
			allImages,
			image_details_view.id,
			allImages.length
		);

		setCommentsList(false);
		setCommentsMarker([]);

		if (previousPage.length > 0) {
			// Display the previousPage data
			console.log(previousPage);

			// Update the current page ID
			setImageID(previousPageId);
			const filterImages =
				allImages !== undefined &&
				allImages.filter((obj) => obj.id === previousPageId)[0];
			setImageDetailsView(filterImages);
			setViewEditGroup({});
			resetCanvas();
		} else {
			// No more data to show
			console.log("No more data to display.");
		}
	}

	/**
	 * Handle the to assign the editor .
	 * @param {Number} idSelected - selected image id.
	 */

	const handleAssignedImagesToEditor = (idSelected) => {
		if (token) {
			const assignEditorFormData = new FormData();
			assignEditorFormData.append("image_ids", idSelected);
			request
				.post(apiURL + CO_ORDINATOR_ASSIGNED_TO_EDITOR, assignEditorFormData)
				.then((response) => {
					ToastAlert({
						title: response.data.status ? "Success" : "Error",
						message: response.data.status ? "Successfully assigned" : "Error",
						autoClose: 2000,
						messageType: response.data.status ? "success" : "error",
					});
					if (response.data.status) {
						setEditorAssigned(true);
						request
							.get(
								apiURL +
									ADMIN_GET_COORDINATORS_DETAILS +
									`/${currentUserId}` +
									`?batch_number=${batchId}`
							)
							.then((response) => {
								setParentFolderDetails(null);
								setFolderName("");
								// setEditGroups(response.data.data.editGroups);
								// setEditTypes(response.data.data.editTypes);
								setBatchNumber(response.data.data.batchNumbers);
								setImages(response.data.data.images);
								setFolderList(response.data.data.folders);
								setFolder(response.data.data.folders);
								setImageMainFolder(response.data.data.images);
								setFolderMainFolder(response.data.data.folders);
								setMainImageCount(response.data.data.mainImageCount);
								setMainImageSettingsCount(
									response.data.data.mainImageSettingsCount
								);
								setOrderNumber(response.data.data.batchNumbers[0].order_id);
								setSubFolderOpen(false); // Clear subFolderDetails as we are at the parent level
								setDisplay(false);
								setMultipleImagesId([]);
								setClickSelect(false);
							})
							.catch((err) => console.log(err));
					}
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};

	/**
	 * Handles to check the editor assign status/progress for task status.
	 * @param {String} status - The status String image status.
	 * @returns {} return the matching string or undefined if not matched.
	 */

	const checkEditorWorkProgress = (status) => {
		return editorWorkingStatus.find((item) => item == status);
	};

	/**
	 * Handles to disable/enable item based on condition.
	 * @returns {} return true/false based on condition.
	 */

	const checkDisableInput = () => {
		return (
			isEditorAssigend || checkEditorWorkProgress(image_details_view.status)
		);
	};

	/**
	 * Handles to set the state.
	 * @param {Boolean} checked - The checked/unchecked after action of checkbox clicked.
	 */
	const toggleFieldEditing = (checked = false) => {
		customizedSettingsEnabled(checked);
		if (checked) {
			if (edit_select > 0) {
				setPreviousSelectedValue(edit_select);
			}
			if (props.editGroups) {
				const updatedGroup = [
					// { id: "Customized Settings", group_name: "Customized Settings" },
					{ id: "New Settings", group_name: "New Settings" },
					...props.editGroups,
				];
				setGroup(updatedGroup);
			}
		}
	};
	useEffect(() => {
		if (image_details_view.id !== undefined) {
			if (props.editGroups) {
				const updatedGroup = [
					// { id: "Customized Settings", group_name: "Customized Settings" },
					{ id: "New Settings", group_name: "New Settings" },
					...props.editGroups,
				];
				setGroup(updatedGroup);
			}
			previousImageID(image_details_view.id);
		}
	}, [image_details_view.id]);

	useEffect(() => {
		if (
			edit_select === null &&
			setting_profile.edit_group_id === null &&
			setting_profile.extra_edit_types !== null &&
			setting_profile.id
		) {
			setGroupName(
				setting_profile.custom_edit_group &&
					setting_profile.custom_edit_group.group_name
			);

			const backgroundSettings = setting_profile.background_settings || [];
			setBackgroundRemovalColorCode(
				backgroundSettings[0] && backgroundSettings[0].color_code
					? backgroundSettings[0].color_code
					: ""
			);
			setBackgroundAdjustmentColorCode(
				backgroundSettings[1] && backgroundSettings[1].color_code
					? backgroundSettings[1].color_code
					: ""
			);
			setBackgroundRemovalColorType(
				backgroundSettings[0] && backgroundSettings[0].color_type
					? backgroundSettings[0].color_type
					: "Select Color Type"
			);
			setBackgroundAdjustmentColorType(
				backgroundSettings[1] && backgroundSettings[1].color_type
					? backgroundSettings[1].color_type
					: "Select Color Type"
			);

			const resizingSettings = setting_profile.resizing_settings || [];
			setResizingFileFormat(
				resizingSettings[0] && resizingSettings[0].file_format
					? resizingSettings[0].file_format
					: "Select File Format"
			);
			setResizingDpiType(
				resizingSettings[0] && resizingSettings[0].dpi_type
					? resizingSettings[0].dpi_type
					: "Select File Format"
			);
			setResizingColorMode(
				resizingSettings[0] && resizingSettings[0].color_mode
					? resizingSettings[0].color_mode
					: "Select File Format"
			);
			setResizingAlignment(
				resizingSettings[0] && resizingSettings[0].alignment
					? resizingSettings[0].alignment
					: "Select File Format"
			);
			setResizingMarginFormat(
				resizingSettings[0] && resizingSettings[0].margin_format
					? resizingSettings[0].margin_format
					: "Select File Format"
			);

			setImageFileFormat(setting_profile.file_format || "Select file format");
			setImageDpiType(
				setting_profile.dpi_type !== null
					? setting_profile.dpi_type
					: "Select Dpi type"
			);
			setImageColorMode(setting_profile.color_mode || "Select color mode");
			setImageAlignment(setting_profile.alignment || "Select Align");
			setImageMarginFormat(setting_profile.margin_format || "");

			setResizingHeight(
				resizingSettings[0] && resizingSettings[0].height
					? resizingSettings[0].height
					: ""
			);
			setResizingWidth(
				resizingSettings[0] && resizingSettings[0].width
					? resizingSettings[0].width
					: ""
			);

			if (backgroundSettings[0]) setChecked(true);
			if (backgroundSettings[1]) setChecked1(true);

			if (resizingSettings.length) {
				setChecked2(true);
				setResizingHeight(
					resizingSettings[0] && resizingSettings[0].height
						? resizingSettings[0].height
						: ""
				);
				setResizingWidth(
					resizingSettings[0] && resizingSettings[0].width
						? resizingSettings[0].width
						: ""
				);
			}

			setImageMarginTop(setting_profile.margin_top || "");
			setImageMarginBottom(setting_profile.margin_bottom || "");
			setImageMarginRight(setting_profile.margin_right || "");
			setImageMarginLeft(setting_profile.margin_left || "");
		}
	}, [image_details_view.id, setEditId]);

	useEffect(() => {
		// Ensure props.editGroups is an array before using it
		const editGroupsJson = Array.isArray(editGroups) ? editGroups : [];

		// Create a new array by combining existing group values and the new object
		const updatedGroup = [
			{ id: "New Settings", group_name: "New Settings" },
			...editGroupsJson,
		];

		setGroup(updatedGroup);
		setSelected(false);
		setSelected1(false);
		setSelected3(false);
	}, [editGroups]);

	useEffect(() => {
		if (
			edit_select === null &&
			setting_profile &&
			setting_profile.edit_group_id === null &&
			setting_profile.extra_edit_types === null
		) {
			setEditGroupNameSelect("New Settings");
		} else if (
			edit_select === null &&
			image_details_view.id &&
			setting_profile &&
			setting_profile.edit_group_id === null &&
			setting_profile.extra_edit_types !== null &&
			newSettingsClick === false
		) {
			setEditGroupNameSelect("Customized Settings");
			setGroupSelect("Customized Settings");
			setGroupName(
				setting_profile.custom_edit_group &&
					setting_profile.custom_edit_group.group_name
			);

			customizedSettingsEnabled(true);
			setEditId(setting_profile.extra_edit_types);

			if (
				setting_profile.extra_edit_types &&
				setting_profile.extra_edit_types.includes("1")
			) {
				setChecked(true);
			}
			if (
				setting_profile.extra_edit_types &&
				setting_profile.extra_edit_types.includes("2")
			) {
				setChecked1(true);
			}

			if (
				setting_profile.extra_edit_types &&
				setting_profile.extra_edit_types.includes("3")
			) {
				const resizingSettings = setting_profile.resizing_settings || [];
				setResizingHeight(
					resizingSettings[0] && resizingSettings[0].height
						? resizingSettings[0].height
						: ""
				);
				setResizingWidth(
					resizingSettings[0] && resizingSettings[0].width
						? resizingSettings[0].width
						: ""
				);
				setChecked2(true);
			}

			if (
				setting_profile.extra_edit_types &&
				setting_profile.extra_edit_types.includes("15")
			) {
				setChecked3(true);
			}
		} else if (
			group_name_select === "New Settings" &&
			newSettingsClick === true
		) {
			setEditGroupNameSelect("New Settings");
		} else if (
			edit_select !== null &&
			image_details_view.id &&
			setting_profile &&
			setting_profile.edit_group_id !== null &&
			setting_profile.extra_edit_types === null &&
			!handleProfileChangedValue
		) {
			setEditGroupNameSelect("");
			setGroupName(
				setting_profile.custom_edit_group &&
					setting_profile.custom_edit_group.group_name
			);
			customizedSettingsEnabled(false);

			// Collect edit type IDs
			const ids =
				(setting_profile.edit_group && setting_profile.edit_group.edit_types) ||
				[];
			const editTypeIds = ids.map((item) => item.id);
			setEditId(removeDuplicatesWithSet(editTypeIds));

			if (!editTypes) {
				setEditTypes(ids);
			}

			setViewEditGroup(setting_profile);

			const backgroundSettings =
				(setting_profile.edit_group &&
					setting_profile.edit_group.background_settings) ||
				[];
			setBackgroundRemovalColorCode(
				backgroundSettings[0] ? backgroundSettings[0].color_code : ""
			);
			setBackgroundAdjustmentColorCode(
				backgroundSettings[1] ? backgroundSettings[1].color_code : ""
			);
			setBackgroundRemovalColorType(
				backgroundSettings[0] ? backgroundSettings[0].color_type : ""
			);
			setBackgroundAdjustmentColorType(
				backgroundSettings[1] ? backgroundSettings[1].color_type : ""
			);

			const groupSetting =
				setting_profile.edit_group && setting_profile.edit_group.setting;
			setImageFileFormat(
				groupSetting && groupSetting.file_format ? groupSetting.file_format : ""
			);
			setImageDpiType(
				groupSetting && groupSetting.dpi_type ? groupSetting.dpi_type : ""
			);
			setImageColorMode(
				groupSetting && groupSetting.color_mode ? groupSetting.color_mode : ""
			);
			setImageAlignment(
				groupSetting && groupSetting.alignment ? groupSetting.alignment : ""
			);
			setImageMarginFormat(
				groupSetting && groupSetting.margin_format
					? groupSetting.margin_format
					: ""
			);
			setImageHeight(
				groupSetting && groupSetting.height ? groupSetting.height : ""
			);
			setImageWidth(
				groupSetting && groupSetting.width ? groupSetting.width : ""
			);
			setImageDpi(groupSetting && groupSetting.dpi ? groupSetting.dpi : "");
			setImageMarginTop(
				groupSetting && groupSetting.margin_top ? groupSetting.margin_top : ""
			);
			setImageMarginBottom(
				groupSetting && groupSetting.margin_bottom
					? groupSetting.margin_bottom
					: ""
			);
			setImageMarginRight(
				groupSetting && groupSetting.margin_right
					? groupSetting.margin_right
					: ""
			);
			setImageMarginLeft(
				groupSetting && groupSetting.margin_left ? groupSetting.margin_left : ""
			);

			// Check edit type IDs for specific settings
			const idCheck = (data, id, setCheckedFn) => {
				if (data.some((item) => item.id === id)) {
					setCheckedFn(true);
				}
			};
			idCheck(ids, 1, setChecked);
			idCheck(ids, 2, setChecked1);

			const resizingSettings =
				(setting_profile.edit_group &&
					setting_profile.edit_group.resizing_settings) ||
				[];
			if (resizingSettings.length === 1) {
				setChecked2(true);
				setResizingHeight(resizingSettings[0].height || "");
				setResizingWidth(resizingSettings[0].width || "");
			} else {
				setChecked3(false);
				setResizingHeight("");
				setResizingWidth("");
			}
		}
	}, [edit_select, setting_profile, image_details_view.id]);

	/**
	 * Handles to return the checkbox field editbale state is set or not.
	 */
	const checkisEditableField = () => {
		return !customizedSettings;
	};

	/**
	 * Handles profile change event
	 * @param {Event} e - The changed event object.
	 */
	const handleProfileChange = (e) => {
		setEditGroupNameSelect(e.label);
		setEditorType(e.value);
		setGroupSelect((val) => !val);
		if (
			group[0] &&
			group[0].group_name === "Customized Settings" &&
			e.label === "New Settings"
		) {
			const updatedGroup = [
				{ id: "New Settings", group_name: "New Settings" },
				...editGroups,
			];
			setGroup(updatedGroup);
			customizedSettingsEnabled(false);
		} else if (e.label === "Customized Settings") {
			setImageID("");
			setTimeout(() => {
				setImageID(previousImageSelected);
			}, 20);
		}
	};

	/**
	 * Handles image change  to add in the prev selected image state
	 */
	useEffect(() => {
		if (image_details_view.id !== "") {
			previousImageID(image_details_view.id);
			handleFetchProfileSettings(image_details_view.id);
		}
	}, [image_details_view.id]);
	useEffect(() => {
		const handleKeyPress = (event) => {
			// Check if the '<' key is pressed
			if (event.keyCode === 37 && event.key === "ArrowLeft") {
				showPreviousPage();
			}
			// Check if the '>' key is pressed
			if (event.keyCode === 39 && event.key === "ArrowRight") {
				showNextPage();
			}
		};

		// Add event listener for keydown
		window.addEventListener("keydown", handleKeyPress);

		// Cleanup the event listener on component unmount
		return () => {
			window.removeEventListener("keydown", handleKeyPress);
		};
	}, [image_details_view.id]);

	// Validate duplication_settings beforehand
	const hasDuplicationSettings =
		Array.isArray(setting_profile) &&
		Array.isArray(setting_profile.duplication_settings) &&
		setting_profile.duplication_settings.length > 0;
	const supportedExtensions = [
		".psd",
		".psb",
		".tiff",
		".tif",
		".nef",
		".fig",
		".cr2",
		".cr3",
		".ai",
		".otf",
		".ttf",
		".eip",
	];

	const isSupported = supportedExtensions.some(
		(ext) =>
			image_details_view !== undefined &&
			image_details_view.image_path !== undefined &&
			image_details_view.image_path.toLowerCase().endsWith(ext)
	);
	// Function to get the file extension
	const getFileExtension = (path) => {
		return path ? path.split(".").pop().toUpperCase() : null;
	};
	const thumbnailReturn = (path) => {
		// Check if the image path is a TIFF file
		if (isSupported) {
			// Return the thumbnail URL for TIFF images
			return false;
		} else {
			// Return the default thumbnail URL for other image types or cases
			return true;
		}
	};
	const handleKeyDown = (e) => {
		const isControlOrCommand = e.ctrlKey || e.metaKey;
		const isOneKey = e.key === "1";

		if (isControlOrCommand && isOneKey) {
			e.preventDefault();
			setZoom(1); // Set zoom to 100%
			setZoomPercentage("100%");

			if (inputRef.current) {
				inputRef.current.value = "100%";
			}
		}
	};
	useEffect(() => {
		window.addEventListener("keydown", handleKeyDown);

		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, []);
	useEffect(() => {
		switch (activeTab) {
			case "":
			case "drag":
				setDrawingMode("drag");
				setActivateTab("drag");
				setIsDraggingCanvas(true);
				setIsDragging(true);
				break;

			case "square":
				setDrawingMode("square");
				setActivateTab("square");
				setIsDraggingCanvas(false);
				setIsDragging(false);
				//

				break;

			default:
				// Handle other cases or keep it empty if there's no other logic.
				break;
		}
	}, [activeTab, image_details_view.id]);

	console.log("determineCustomerView", setting_profile);

	if (image_details_view.length === null) return <Loader />;
	else {
		return (
			<div>
				{showModal && (
					<div className="modal-overlay--order">
						<div className="nim">
							<div className="nim__icon-area">
								<div
									// className="modal-image-icons"
									onClick={() => {
										setToggleMessage((val) => !val);
										setCommentsList((val) => !val);
										if (!showCommentList) {
											setAnnotationsComments([]);
										}
									}}
									style={
										{
											//  background: showCommentList ? "#f5f5f5" : "#fff"
										}
									}
								>
									{/* <img
										src={showCommentList ? BlueChatIcon : ChatIcons}
										className="img-fluid image--comment"
										alt="mindreach images"
									/> */}

									<div className="modal-image-icons__area">
										<div className="modal-image-icons__each modal-image-icons__each--active">
											<svg
												width="19"
												height="19"
												viewBox="0 0 23 23"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M15.8777 21.2582C16.2126 21.2612 16.5388 21.152 16.8045 20.9481C17.0702 20.7442 17.26 20.4573 17.3437 20.133L22.2389 1.91716C22.3129 1.6422 22.308 1.35199 22.2249 1.07965C22.1418 0.807313 21.9838 0.563829 21.769 0.376995C21.5541 0.19016 21.291 0.0675049 21.0098 0.0230314C20.7286 -0.0214422 20.4405 0.0140584 20.1785 0.12548L2.81819 7.49792C2.50996 7.62901 2.25373 7.85833 2.08939 8.15018C1.92505 8.44204 1.86182 8.78004 1.90956 9.11156C1.95729 9.44308 2.1133 9.74952 2.3533 9.98316C2.5933 10.2168 2.90381 10.3645 3.2365 10.4033L12.1463 11.4404L14.4087 20.1194C14.4897 20.4458 14.678 20.7355 14.9435 20.942C15.2089 21.1486 15.5361 21.2599 15.8724 21.2582H15.8777ZM20.7722 1.51934L15.8769 19.7398L13.6161 11.0608C13.5405 10.7632 13.376 10.4958 13.1444 10.2942C12.9129 10.0926 12.6253 9.96646 12.3201 9.93263L3.41035 8.89558L20.7722 1.51934Z"
													fill="#5A616B"
												/>
												<path
													d="M12.5181 11.0483L1.89453 21.6719"
													stroke="#5A616B"
													stroke-width="2"
													stroke-linecap="round"
												/>
											</svg>
										</div>
										<div className="modal-image-icons__each">
											<img
												src={showCommentList ? BlueChatIcon : ChatIcons}
												alt="mindreach images"
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="nim__header-area">
								<div
									className="d-flex justify-content-between w-full"
									onClick={handleOverlayClick}
									ref={modalRefImage}
								>
									<div
										className="d-flex align-items-center"
										style={{ gap: "30px" }}
									>
										{/* <Button
										variant="outline-secondary"
										className="btn-image-order--outlined"
									>
										<span className="order-text--outlined">
											{image_details_view.batch_number}
										</span>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										<span className="order-text-outlined--name">
											{image_details_view.path}
										</span>
									</Button> */}
										<div
											className={`dropdown-duplicate-image--modal dropdown-duplicate-image--newmodal   ${
												isDropdownOpen ? "open" : ""
											}`}
										>
											<div
												className="dropdown-button--duplicate"
												onClick={toggleDropdown}
											>
												<span>
													<div
														dangerouslySetInnerHTML={{
															__html: svgCode(
																setting_profile !== null &&
																	setting_profile !== undefined &&
																	setting_profile.duplication_settings !==
																		undefined &&
																	setting_profile.duplication_settings !==
																		null &&
																	setting_profile.duplication_settings
																		.length !== undefined
																	? parseInt(
																			setting_profile.duplication_settings.length
																				.toString()
																				.substr(0, 2)
																	  )
																	: "0"
															),
														}}
														style={{ marginLeft: "18px" }}
													/>
												</span>
												<span className="order-text--outlined">
													{image_details_view.batch_number}
												</span>
												<span
													className="order-text--outlined small-name"
													style={{ marginLeft: "12px" }}
												>
													{image_details_view.path}
												</span>
												<span style={{ marginLeft: "auto" }}>
													<div
														style={{ marginRight: "18px", marginLeft: "12px" }}
														onClick={() =>
															onClickDuplicateImage(
																setting_profile.id,
																setting_profile.path.substring(
																	0,
																	setting_profile.path.lastIndexOf(".")
																)
																// getImageExtension(image_details_view.path)
															)
														}
													>
														<img src={EditImageOriginal} alt="Pencil Icon" />{" "}
													</div>
												</span>
												{hasDuplicationSettings &&
													setting_profile.duplication_settings.length !== 0 && (
														<span style={{ marginRight: "23px" }}>
															<img src={ArrowDown} alt="Arrow Down" />{" "}
														</span>
													)}
											</div>
											{isDropdownOpen &&
												hasDuplicationSettings &&
												setting_profile.duplication_settings.length > 0 && (
													<div
														className="dropdown-content-duplicate-image--modal dropdown-content-duplicate-image--modal-sm"
														style={{ bottom: "-110px" }}
													>
														{hasDuplicationSettings &&
															setting_profile.duplication_settings.map(
																(_items, index) => {
																	// const isItemHovered =
																	// 	hoveredItems[_items.id] || false;
																	return (
																		<span
																			// key={_items.id}
																			// onMouseEnter={() =>
																			// 	handleMouseEnter(_items.id)
																			// }
																			// onMouseLeave={handleMouseLeave}
																			key={_items.id}
																			onMouseEnter={() =>
																				handleMouseEnter(_items.id)
																			}
																			onMouseLeave={handleMouseLeave}
																			onClick={() => {
																				setDuplicateImageId(_items.id);
																				setViewDuplicateModal(true);
																				setDropdownOpen(false);
																			}}
																		>
																			<h2
																				className="dropdown-duplicate-image--text small-name2"
																				// onClick={() =>
																				// 	onClickDuplicateImage(
																				// 		_items.id,
																				// 		_items.image_name.substring(
																				// 			0,
																				// 			_items.image_name.lastIndexOf(".")
																				// 		)
																				// 		// getImageExtension(image_details_view.path)
																				// 	)
																				// }
																			>
																				{_items.image_name}
																			</h2>
																			<div className="d-flex">
																				<div
																					style={{ marginRight: "18px" }}
																					onClick={() =>
																						onClickDuplicateImage(
																							_items.id,
																							_items.image_name.substring(
																								0,
																								_items.image_name.lastIndexOf(
																									"."
																								)
																							)
																							// getImageExtension(image_details_view.path)
																						)
																					}
																				>
																					<img
																						src={
																							hoveredItemId === _items.id
																								? BlackEdit
																								: EditImageOriginal
																						}
																						alt="Pencil Icon"
																					/>{" "}
																				</div>

																				<div
																					onClick={() => {
																						setShowModalDelete(true);
																						setRenameId(_items.id);
																						setImageName(_items.image_name);
																					}}
																				>
																					<img
																						src={
																							hoveredItemId === _items.id
																								? BlackDelete
																								: DeleteIcon
																						}
																						alt="Mindreach icons"
																					/>
																					{/* <span className="delete-icon" /> */}
																				</div>
																			</div>
																		</span>
																	);
																}
															)}
													</div>
												)}
										</div>

										<div className="d-flex">
											<div>
												<div className="magnifier-holder--img">
													{/* zoom in icon */}
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="20"
														height="20"
														fill="currentColor"
														class="bi bi-zoom-in"
														viewBox="0 0 16 16"
													>
														<path
															fill-rule="evenodd"
															d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11M13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0"
														/>
														<path d="M10.344 11.742q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1 6.5 6.5 0 0 1-1.398 1.4z" />
														<path
															fill-rule="evenodd"
															d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5"
														/>
													</svg>
												</div>
											</div>
											<div style={{ marginLeft: "10px" }}>
												<div className="magnifier-holder--img">
													{/* zoom out icon */}
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="20"
														height="20"
														fill="currentColor"
														class="bi bi-zoom-in"
														viewBox="0 0 16 16"
													>
														<path
															fill-rule="evenodd"
															d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11M13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0"
														/>
														<path d="M10.344 11.742q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1 6.5 6.5 0 0 1-1.398 1.4z" />
														<path
															fill-rule="evenodd"
															d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5"
														/>
													</svg>
												</div>
											</div>
											<div className="image-zoom">
												100
												{/* percentage icon */}
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="16"
													height="16"
													fill="currentColor"
													class="bi bi-percent"
													viewBox="0 0 16 16"
												>
													<path d="M13.442 2.558a.625.625 0 0 1 0 .884l-10 10a.625.625 0 1 1-.884-.884l10-10a.625.625 0 0 1 .884 0M4.5 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5m7 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
												</svg>
											</div>
										</div>
									</div>
									{/* <div style={{ marginLeft: "34rem" }}>
									{group_name_select === "New Settings" && (
										<Button
											variant="outline-secondary"
											className="btn-image-order-save--outlined"
											onClick={() => handleUpdateEditGroup()}
										>
											Save
										</Button>
									)}
									{group_name_select !== "" && group_name_select !== "New Settings" && (
										<Button
											variant="outline-secondary"
											className="btn-image-order-save--outlined"
											onClick={() => handleUpdateImageEditGroup()}
										>
											Save
										</Button>
									)}
								</div> */}
									<div
										className="modal-tab"
										onClick={() => {
											setShowModal(false);
											setEditorType(0);
											setViewEditGroup({});
											setBackgroundRemovalColorCode("");
											setBackgroundAdjustmentColorCode("");
											setBackgroundRemovalColorType("");
											setBackgroundAdjustmentColorType("");
											setResizingFileFormat("");
											setResizingDpiType("");
											setResizingColorMode("");
											setResizingAlignment("");
											setResizingMarginFormat("");
											setImageFileFormat("");
											setImageDpiType("");
											setImageDetailsView({});
											document.body.classList.remove("modal-open");
										}}
									>
										<Button
											variant="primary"
											className="btns"
											onClick={() =>
												handleAssignedImagesToEditor(
													sessionStorage.getItem("selectImageID")
												)
											}
										>
											Assign to Editor
										</Button>{" "}
										{/* <img
										src={CancelIcons}
										className="img-fluid"
										alt="mindreach images"
									/> */}
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="20"
											height="20"
											fill="currentColor"
											class="bi bi-x-circle"
											viewBox="0 0 16 16"
										>
											<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
											<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
										</svg>
									</div>
								</div>
							</div>
							<div className="nim__main-area">
								<div className="modal--image">
									<div className="prev--icons">
										<div
											className="next-pre-btn"
											onClick={() => showPreviousPage()}
											style={{ cursor: "pointer" }}
										>
											<img src={Previous} alt="previous icon" />
										</div>
									</div>
									{/* on click event Remove this transform property with null  */}
									<div>
										<PanViewer
											style={{
												width: "100%!important",
												height: "100%!important",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												zIndex: 1,
												webkitTransform: "none",
												transform: "none",

												// transform: !isDragging
												// 	? `rotate(${0 * 90}deg) scaleX(${flip ? -1 : 1})`
												// 	: false,
												// marginBottom: "10em",
											}}
											zoom={zoom}
											setZoom={handleWheel}
											matrixData={0}
											rotation={rotation}
											pandx={dx}
											pandy={dy}
											key={dx}
										>
											<div
												className="modal-container"
												// style={{ transform: "scale(0.899)" }}
												// style={
												// 	isDraggingCanvas === false
												// 		? { transform: "scale(.8)" }
												// 		: null
												// }
												style={{ backgroundColor: "#ffff" }}
											>
												{image_details_view !== undefined &&
												image_details_view.image_path !== undefined &&
												image_details_view.customer !== undefined &&
												image_details_view.customer.email !== undefined ? (
													<Marker
														imageURL={`${imageURL}/${image_details_view.customer.email}/${image_details_view.batch_number}/${image_details_view.order.order_number}/images/${image_details_view.image_path}`}
														// imageURL={imageUrlonClick}
														currentAnnotation={currentAnnotation}
														setCurrentAnnotation={setCurrentAnnotation}
														handleTextChange={handleTextChange}
														annotations={annotations}
														setAnnotations={setAnnotations}
														setToggleMessage={setToggleMessage}
														toggleMessage={toggleMessage}
														idSelected={image_details_view.id}
														// isDrawingCompleted={isDrawingCompleted}
														// selectedColor={selectedColor}
														image_url={`${imageURL}/${image_details_view.customer.email}/${image_details_view.batch_number}/${image_details_view.order.order_number}/images/${image_details_view.image_path}`}
														// setImageUrl={setImageUrl}
														backgroundRemovalColorCode={
															backgroundRemovalColorCode
														}
														setBackgroundRemoval={setBackgroundRemoval}
														canvasRef={canvasRef}
														image_details_view={image_details_view}
														setCurrentImage={setCurrentImage}
														currentImage={currentImage}
														setTransformPosition={setTransformPosition}
														defaultTransformPosition={defaultTransformPosition}
														isDrawing={isDrawing}
														snapShotRef={snapShotRef}
														isDragging={isDragging}
														setIsDragging={setIsDragging}
														showCommentList={showCommentList}
														setCommentsList={setCommentsList}
														annotationsComments={annotationsComments}
														setAnnotationsComments={setAnnotationsComments}
														token={token}
														undo={undo}
														setZoomPercentage={setZoomPercentage}
														zoomPercentage={zoomPercentage}
														zoom={zoom}
														setZoom={setZoom}
														dx={dx}
														dy={dy}
														setDy={setDy}
														setDx={setDx}
														isDraggingCanvas={isDraggingCanvas}
														setIsDraggingCanvas={setIsDraggingCanvas}
														drawingMode={drawingMode}
														activeTab={activeTab}
														setCommentsMarker={setCommentsMarker}
														commentsMarker={commentsMarker}
														setCommentsAddSections={setCommentsAddSections}
														commemntsAddSections={commemntsAddSections}
														setCommentId={setCommentId}
														commentsId={commentsId}
														overlayLayerActive={overlayLayerActive}
														setOverlayLayerActive={setOverlayLayerActive}
														path={image_details_view.image_path}
														getFileExtension={getFileExtension}
													/>
												) : (
													<Loader />
												)}
											</div>
										</PanViewer>
									</div>
									<div className="next--icons">
										<div
											className="next-pre-btn"
											onClick={() => showNextPage()}
											style={{ cursor: "pointer" }}
										>
											<img src={Next} alt="next icon" />
										</div>
									</div>
								</div>
							</div>
							<div className="nim__form-area">
								{determineCustomerView === 0 ? (
									<div
										className="form-group edit-profile-setting-container--single"
										style={{ border: "none" }}
									>
										{drawingMode === "square" && commentsMarker.length > 0 && (
											<>
												<Row className="align-items-center edit-setting-sidebar">
													<div
														className="sidebar__header sidebar__flex"
														style={{ padding: "15px 25px 12px 15px" }}
													>
														<div style={{ fontSize: "20px" }}>Comments</div>
														<div onClick={activateDragMode}>
															<img src={CloseBtn} alt="close btn" />
														</div>
													</div>
													<Col
														sm={12}
														className="sidebar-settings-image--modal sidebar-settings-image--comment-modal"
													>
														{commemntsAddSections && (
															<div className="commentcard commentcard--bg">
																<div className="commentcard__profile">
																	<div>
																		<Avatar
																			name={customer && customer.full_name}
																		/>
																	</div>
																	<div className="commentcard__name">
																		{customer && customer.full_name}
																	</div>
																</div>
																<div>
																	<input
																		placeholder="Comments or add others with @"
																		className="commentcard__input"
																		onChange={(e) =>
																			setCommentsText(e.target.value)
																		}
																	/>
																</div>
																<div className="commentcard__btn-area">
																	<div
																		onClick={() => {
																			setCommentId("");
																			setCommentsAddSections(false);
																		}}
																	>
																		Cancel
																	</div>
																	<div>
																		<button
																			className="next-btn privious-btn next-btn-sm btn"
																			onClick={() => {
																				handleAddComments();
																			}}
																			disabled={
																				commentsText !== "" ? false : true
																			}
																		>
																			Comment
																		</button>
																	</div>
																</div>
															</div>
														)}
														{commentsMarker.map((item, key) => {
															if (!item.comment_id) {
																return;
															}
															console.log(
																"comments id check",
																item,
																commentsId,
																commentsId === Number(item.comment_id)
															);
															return (
																<div
																	className="commentcard"
																	key={key}
																	onClick={(e) => {
																		e.preventDefault();
																		setCommentId(item.comment_id);
																		setCommentsAddSections(false);
																		setDrawingMode("square");
																		setActivateTab("square");
																		setCommentsMarker(
																			filteredComments(commentsMarker)
																		);
																		// setOverlayLayerActive(true);
																	}}
																>
																	<div className="commentcard__mt">
																		<div className="commentcard__dropdown">
																			<div className="content">
																				<div className="commentcard__profile">
																					<div>
																						<Avatar name={item.comment_by} />
																					</div>
																					<div className="commentcard__name">
																						{item.comment_by}
																						<div className="commentcard__time">
																							{formatTimestampToCustom(
																								item.commented_at
																							)}
																						</div>
																					</div>
																				</div>
																			</div>
																			<div
																				className="position--relative "
																				id="edit"
																				onClick={(e) => {
																					setMenuComments((val) => !val);
																					if (!menu_comments) {
																						handleUpdateComments(
																							item.comment_id
																						);
																					} else {
																						setEditComments("");
																					}
																				}}
																			>
																				{commentsId ===
																					Number(item.comment_id) && (
																					<img
																						src={TickGreenIcon}
																						alt="Green Tick Icon"
																						style={{ marginRight: "15px" }}
																					/>
																				)}
																				{item.comment_id === edit_comments && (
																					<div className="commentcard__dropdown-area commentcard__dropdown-area--visible">
																						<div
																							className="commentcard__dropdownlist"
																							onClick={() => {
																								setUpdatedCommentsBox(
																									item.comment_id
																								);
																								setEditCommentsText(
																									item.comment
																								);
																							}}
																						>
																							<div>
																								<svg
																									xmlns="http://www.w3.org/2000/svg"
																									width="12"
																									height="12"
																									fill="currentColor"
																									class="bi bi-pencil-square"
																									viewBox="0 0 16 16"
																								>
																									<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
																									<path
																										fill-rule="evenodd"
																										d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
																									/>
																								</svg>
																							</div>
																							<div>Edit</div>
																						</div>
																						<div
																							className="commentcard__dropdownlist"
																							onClick={() => {
																								handleCommentDelete(
																									item.comment_id
																								);
																							}}
																						>
																							<div>
																								<svg
																									xmlns="http://www.w3.org/2000/svg"
																									width="12"
																									height="12"
																									fill="currentColor"
																									class="bi bi-trash3"
																									viewBox="0 0 16 16"
																								>
																									<path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
																								</svg>
																							</div>
																							<div>Delete</div>
																						</div>
																					</div>
																				)}

																				<img
																					src={threeDots}
																					alt="Mindreach icons"
																				/>
																			</div>
																		</div>
																		<div className="commentcard__comment">
																			{updateCommentsBoxOpen ===
																			item.comment_id ? (
																				<div>
																					<input
																						placeholder="Comments or add others with @"
																						className="commentcard__input"
																						value={editcommentsText}
																						onChange={(e) => {
																							const value = e.target.value;
																							setEditCommentsText(value);

																							setIsDisabledUpdatedCommentText(
																								value.trim() === ""
																							);
																						}}
																					/>
																				</div>
																			) : (
																				<p>{item.comment}</p>
																			)}
																			{/* New Update */}
																			{updateCommentsBoxOpen ===
																				item.comment_id && (
																				<div className="commentcard__btn-area">
																					<div
																						onClick={() => {
																							setUpdatedCommentsBox("");
																						}}
																					>
																						Cancel
																					</div>
																					<div>
																						<button
																							className="next-btn privious-btn next-btn-sm btn"
																							onClick={() => {
																								updatedCommentsText(
																									item.x_axis,
																									item.y_axis,
																									item.width,
																									item.height,
																									editcommentsText,
																									item.comment_id
																								);
																							}}
																							disabled={
																								isDisabledUpdatedCommentText
																							}
																						>
																							Comment
																						</button>
																					</div>
																				</div>
																			)}
																		</div>
																	</div>

																	{/* Reply Chat List */}
																	{item.reply.map((replyItem, key) => {
																		return (
																			<div className="commentcard__mt">
																				<div className="commentcard__dropdown">
																					<div className="content">
																						<div className="commentcard__profile">
																							<div>
																								<Avatar
																									name={replyItem.comment_by}
																								/>
																							</div>
																							<div className="commentcard__name">
																								{replyItem.comment_by}
																								<div className="commentcard__time">
																									{formatTimestampToCustom(
																										replyItem.commented_at
																									)}
																								</div>
																							</div>
																						</div>
																					</div>
																					<div
																						className="position--relative "
																						id="edit"
																						onClick={(e) => {
																							setMenuComments((val) => !val);
																							if (!menu_comments) {
																								handleUpdateComments(
																									replyItem.comment_id
																								);
																							} else {
																								setEditComments("");
																							}
																						}}
																					>
																						{/* {commentsId ===
																							Number(replyItem.comment_id) && (
																							<img
																								src={TickGreenIcon}
																								alt="Green Tick Icon"
																								style={{ marginRight: "15px" }}
																							/>
																						)} */}
																						{replyItem.comment_id ===
																							edit_comments && (
																							<div className="commentcard__dropdown-area commentcard__dropdown-area--visible">
																								<div
																									className="commentcard__dropdownlist"
																									onClick={() => {
																										setUpdatedCommentsBox(
																											replyItem.comment_id
																										);
																										setEditCommentsText(
																											replyItem.comment
																										);
																									}}
																								>
																									<div>
																										<svg
																											xmlns="http://www.w3.org/2000/svg"
																											width="12"
																											height="12"
																											fill="currentColor"
																											class="bi bi-pencil-square"
																											viewBox="0 0 16 16"
																										>
																											<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
																											<path
																												fill-rule="evenodd"
																												d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
																											/>
																										</svg>
																									</div>
																									<div>Edit</div>
																								</div>
																								<div
																									className="commentcard__dropdownlist"
																									onClick={() => {
																										handleCommentDelete(
																											replyItem.comment_id
																										);
																									}}
																								>
																									<div>
																										<svg
																											xmlns="http://www.w3.org/2000/svg"
																											width="12"
																											height="12"
																											fill="currentColor"
																											class="bi bi-trash3"
																											viewBox="0 0 16 16"
																										>
																											<path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
																										</svg>
																									</div>
																									<div>Delete</div>
																								</div>
																							</div>
																						)}

																						<img
																							src={threeDots}
																							alt="Mindreach icons"
																						/>
																					</div>
																				</div>
																				<div className="commentcard__comment">
																					{updateCommentsBoxOpen ===
																					replyItem.comment_id ? (
																						<div>
																							<input
																								placeholder="Comments or add others with @"
																								className="commentcard__input"
																								value={editcommentsText}
																								onChange={(e) => {
																									const value = e.target.value;
																									setEditCommentsText(value);

																									setIsDisabledUpdatedCommentText(
																										value.trim() === ""
																									);
																								}}
																							/>
																						</div>
																					) : (
																						<p>{replyItem.comment}</p>
																					)}
																					{updateCommentsBoxOpen ===
																						replyItem.comment_id && (
																						<div className="commentcard__btn-area">
																							<div
																								onClick={() => {
																									setUpdatedCommentsBox("");
																								}}
																							>
																								Cancel
																							</div>
																							<div>
																								<button
																									className="next-btn privious-btn next-btn-sm btn"
																									onClick={() => {
																										replyUpdatedCommentsText(
																											replyItem.x_axis,
																											replyItem.y_axis,
																											replyItem.width,
																											replyItem.height,
																											editcommentsText,
																											item.comment_id,
																											replyItem.comment_id
																										);
																									}}
																									disabled={
																										isDisabledUpdatedCommentText
																									}
																								>
																									Comment
																								</button>
																							</div>
																						</div>
																					)}
																				</div>
																			</div>
																		);
																	})}

																	{/* Chat List */}

																	{/* New Update */}
																	{commentsId === Number(item.comment_id) ? (
																		<>
																			<div>
																				<input
																					placeholder="Reply"
																					class="commentcard__input"
																					onClick={() => setToggleReply(true)}
																					onChange={(e) =>
																						setReplyCommentsText(e.target.value)
																					}
																					value={replyCommentsText}
																				/>
																			</div>
																			{toggleReply && (
																				<div class="commentcard__btn-area">
																					<div
																						onClick={() => {
																							setToggleReply(false);
																						}}
																					>
																						Cancel
																					</div>
																					<div>
																						<button
																							class="next-btn privious-btn next-btn-sm btn"
																							onClick={() => {
																								replyCommentsBasedCommentsId(
																									item.x_axis,
																									item.y_axis,
																									item.width,
																									item.height,
																									replyCommentsText,
																									item.comment_id
																								);
																								setReplyCommentsText("");
																							}}
																						>
																							Reply
																						</button>
																					</div>
																				</div>
																			)}
																		</>
																	) : (
																		""
																	)}
																</div>
															);
														})}
														{/* // : commentsMarker.map((item, key) => {
															// 		if (!item.comment_id) {
															// 			return;
															// 		}
															// 		return (
															// 			<div
															// 				className="commentcard commentcard--bg"
															// 				key={key}
															// 			>
															// 				<div className="commentcard__dropdown">
															// 					<div>
															// 						<div className="commentcard__profile">
															// 							<div>
															// 								<Avatar name={item.comment_by} />
															// 							</div>
															// 							<div className="commentcard__name">
															// 								{item?.comment_by}
															// 								<div className="commentcard__time">
															// 									{formatTimestampToCustom(
															// 										item?.commented_at
															// 									)}
															// 								</div>
															// 							</div>
															// 						</div>
															// 					</div>
															// 					<div style={{ position: "relative" }}>
															// 						<div className="commentcard__dropdown-area">
															// 							<div className="commentcard__dropdownlist">
															// 								<div>
															// 									<svg
															// 										xmlns="http://www.w3.org/2000/svg"
															// 										width="12"
															// 										height="12"
															// 										fill="currentColor"
															// 										class="bi bi-pencil-square"
															// 										viewBox="0 0 16 16"
															// 									>
															// 										<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
															// 										<path
															// 											fill-rule="evenodd"
															// 											d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
															// 										/>
															// 									</svg>
															// 								</div>
															// 								<div>Edit</div>
															// 							</div>
															// 							<div className="commentcard__dropdownlist">
															// 								{commentsId ===
															// 									item.comment_id && (
															// 									<div>
															// 										<svg
															// 											xmlns="http://www.w3.org/2000/svg"
															// 											width="12"
															// 											height="12"
															// 											fill="currentColor"
															// 											class="bi bi-trash3"
															// 											viewBox="0 0 16 16"
															// 										>
															// 											<path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
															// 										</svg>
															// 									</div>
															// 								)}
															// 								<div>Delete</div>
															// 							</div>
															// 						</div>
															// 						<span style={{ marginRight: "15px" }}>
															// 							<svg
															// 								xmlns="http://www.w3.org/2000/svg"
															// 								width="20"
															// 								height="20"
															// 								fill="#125ECB"
															// 								class="bi bi-check2"
															// 								viewBox="0 0 16 16"
															// 							>
															// 								<path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
															// 							</svg>
															// 						</span>
															// 						<svg
															// 							width="4"
															// 							height="14"
															// 							viewBox="0 0 4 14"
															// 							fill="none"
															// 							xmlns="http://www.w3.org/2000/svg"
															// 						>
															// 							<path
															// 								d="M1.75 7.75C2.16421 7.75 2.5 7.41421 2.5 7C2.5 6.58579 2.16421 6.25 1.75 6.25C1.33579 6.25 1 6.58579 1 7C1 7.41421 1.33579 7.75 1.75 7.75Z"
															// 								stroke="#0B0B0B"
															// 								stroke-width="1.5"
															// 								stroke-linecap="round"
															// 								stroke-linejoin="round"
															// 							/>
															// 							<path
															// 								d="M1.75 2.5C2.16421 2.5 2.5 2.16421 2.5 1.75C2.5 1.33579 2.16421 1 1.75 1C1.33579 1 1 1.33579 1 1.75C1 2.16421 1.33579 2.5 1.75 2.5Z"
															// 								stroke="#0B0B0B"
															// 								stroke-width="1.5"
															// 								stroke-linecap="round"
															// 								stroke-linejoin="round"
															// 							/>
															// 							<path
															// 								d="M1.75 13C2.16421 13 2.5 12.6642 2.5 12.25C2.5 11.8358 2.16421 11.5 1.75 11.5C1.33579 11.5 1 11.8358 1 12.25C1 12.6642 1.33579 13 1.75 13Z"
															// 								stroke="#0B0B0B"
															// 								stroke-width="1.5"
															// 								stroke-linecap="round"
															// 								stroke-linejoin="round"
															// 							/>
															// 						</svg>
															// 					</div>
															// 				</div>

															// 				<div>
															// 					<svg
															// 						width="4"
															// 						height="14"
															// 						viewBox="0 0 4 14"
															// 						fill="none"
															// 						xmlns="http://www.w3.org/2000/svg"
															// 					>
															// 						<path
															// 							d="M1.75 7.75C2.16421 7.75 2.5 7.41421 2.5 7C2.5 6.58579 2.16421 6.25 1.75 6.25C1.33579 6.25 1 6.58579 1 7C1 7.41421 1.33579 7.75 1.75 7.75Z"
															// 							stroke="#0B0B0B"
															// 							stroke-width="1.5"
															// 							stroke-linecap="round"
															// 							stroke-linejoin="round"
															// 						/>
															// 						<path
															// 							d="M1.75 2.5C2.16421 2.5 2.5 2.16421 2.5 1.75C2.5 1.33579 2.16421 1 1.75 1C1.33579 1 1 1.33579 1 1.75C1 2.16421 1.33579 2.5 1.75 2.5Z"
															// 							stroke="#0B0B0B"
															// 							stroke-width="1.5"
															// 							stroke-linecap="round"
															// 							stroke-linejoin="round"
															// 						/>
															// 						<path
															// 							d="M1.75 13C2.16421 13 2.5 12.6642 2.5 12.25C2.5 11.8358 2.16421 11.5 1.75 11.5C1.33579 11.5 1 11.8358 1 12.25C1 12.6642 1.33579 13 1.75 13Z"
															// 							stroke="#0B0B0B"
															// 							stroke-width="1.5"
															// 							stroke-linecap="round"
															// 							stroke-linejoin="round"
															// 						/>
															// 					</svg>
															// 				</div>

															// 				<div className="commentcard__comment">
															// 					<p>{item?.comment}</p>
															// 				</div>
															// 				<div>
															// 					<input
															// 						placeholder="Comments or add others with @"
															// 						className="commentcard__input"
															// 					/>
															// 				</div>
															// 				<div className="commentcard__btn-area">
															// 					<div>Cancel</div>
															// 					<div>
															// 						<button className="next-btn privious-btn next-btn-sm btn">
															// 							Comment
															// 						</button>
															// 					</div>
															// 				</div>
															// 			</div>
															// 		);
															//   }) */}

														{/* <div className="commentcard">
															<div className="commentcard__dropdown">
																<div>
																	<div className="commentcard__profile">
																		<div>
																			<div className="commentcard__dp"></div>
																		</div>
																		<div className="commentcard__name">
																			Kaustav Karmakar
																			<div className="commentcard__time">
																				1.16 PM Today
																			</div>
																		</div>
																	</div>
																</div>
																<div>
																	<svg
																		width="4"
																		height="14"
																		viewBox="0 0 4 14"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M1.75 7.75C2.16421 7.75 2.5 7.41421 2.5 7C2.5 6.58579 2.16421 6.25 1.75 6.25C1.33579 6.25 1 6.58579 1 7C1 7.41421 1.33579 7.75 1.75 7.75Z"
																			stroke="#0B0B0B"
																			stroke-width="1.5"
																			stroke-linecap="round"
																			stroke-linejoin="round"
																		/>
																		<path
																			d="M1.75 2.5C2.16421 2.5 2.5 2.16421 2.5 1.75C2.5 1.33579 2.16421 1 1.75 1C1.33579 1 1 1.33579 1 1.75C1 2.16421 1.33579 2.5 1.75 2.5Z"
																			stroke="#0B0B0B"
																			stroke-width="1.5"
																			stroke-linecap="round"
																			stroke-linejoin="round"
																		/>
																		<path
																			d="M1.75 13C2.16421 13 2.5 12.6642 2.5 12.25C2.5 11.8358 2.16421 11.5 1.75 11.5C1.33579 11.5 1 11.8358 1 12.25C1 12.6642 1.33579 13 1.75 13Z"
																			stroke="#0B0B0B"
																			stroke-width="1.5"
																			stroke-linecap="round"
																			stroke-linejoin="round"
																		/>
																	</svg>
																</div>
															</div>
															<div className="commentcard__comment">
																<p>
																	Brightness & Contrast Adjustment: Increase
																	brightness and contrast to make the colors
																	pop.
																</p>
															</div>
														</div> */}
														{/*<div className="commentcard commentcard--bg">
															<div className="commentcard__dropdown">
																<div>
																	<div className="commentcard__profile">
																		<div>
																			<div className="commentcard__dp"></div>
																		</div>
																		<div className="commentcard__name">
																			Kaustav Karmakar
																			<div className="commentcard__time">
																				1.16 PM Today
																			</div>
																		</div>
																	</div>
																</div>
																<div style={{ position: "relative" }}>
																	<div className="commentcard__dropdown-area commentcard__dropdown-area--visible">
																		<div className="commentcard__dropdownlist">
																			<div>
																				<svg
																					xmlns="http://www.w3.org/2000/svg"
																					width="12"
																					height="12"
																					fill="currentColor"
																					class="bi bi-pencil-square"
																					viewBox="0 0 16 16"
																				>
																					<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
																					<path
																						fill-rule="evenodd"
																						d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
																					/>
																				</svg>
																			</div>
																			<div>Edit</div>
																		</div>
																		<div className="commentcard__dropdownlist">
																			<div>
																				<svg
																					xmlns="http://www.w3.org/2000/svg"
																					width="12"
																					height="12"
																					fill="currentColor"
																					class="bi bi-trash3"
																					viewBox="0 0 16 16"
																				>
																					<path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
																				</svg>
																			</div>
																			<div>Delete</div>
																		</div>
																	</div>
																	<svg
																		width="4"
																		height="14"
																		viewBox="0 0 4 14"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M1.75 7.75C2.16421 7.75 2.5 7.41421 2.5 7C2.5 6.58579 2.16421 6.25 1.75 6.25C1.33579 6.25 1 6.58579 1 7C1 7.41421 1.33579 7.75 1.75 7.75Z"
																			stroke="#0B0B0B"
																			stroke-width="1.5"
																			stroke-linecap="round"
																			stroke-linejoin="round"
																		/>
																		<path
																			d="M1.75 2.5C2.16421 2.5 2.5 2.16421 2.5 1.75C2.5 1.33579 2.16421 1 1.75 1C1.33579 1 1 1.33579 1 1.75C1 2.16421 1.33579 2.5 1.75 2.5Z"
																			stroke="#0B0B0B"
																			stroke-width="1.5"
																			stroke-linecap="round"
																			stroke-linejoin="round"
																		/>
																		<path
																			d="M1.75 13C2.16421 13 2.5 12.6642 2.5 12.25C2.5 11.8358 2.16421 11.5 1.75 11.5C1.33579 11.5 1 11.8358 1 12.25C1 12.6642 1.33579 13 1.75 13Z"
																			stroke="#0B0B0B"
																			stroke-width="1.5"
																			stroke-linecap="round"
																			stroke-linejoin="round"
																		/>
																	</svg>
																</div>
															</div>
															<div className="commentcard__comment">
																<p>
																	Brightness & Contrast Adjustment: Increase
																	brightness and contrast to make the colors
																	pop.
																</p>
															</div>
															<div>
																<input
																	placeholder="Comments or add others with @"
																	className="commentcard__input"
																/>
															</div>
															<div className="commentcard__btn-area">
																<div>Cancel</div>
																<div>
																	<button className="next-btn privious-btn next-btn-sm btn">
																		Comment
																	</button>
																</div>
															</div>
														</div>
														<div className="commentcard">
															<div className="commentcard__dropdown">
																<div>
																	<div className="commentcard__profile">
																		<div>
																			<div className="commentcard__dp"></div>
																		</div>
																		<div className="commentcard__name">
																			Kaustav Karmakar
																			<div className="commentcard__time">
																				1.16 PM Today
																			</div>
																		</div>
																	</div>
																</div>
																<div>
																	<svg
																		width="4"
																		height="14"
																		viewBox="0 0 4 14"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M1.75 7.75C2.16421 7.75 2.5 7.41421 2.5 7C2.5 6.58579 2.16421 6.25 1.75 6.25C1.33579 6.25 1 6.58579 1 7C1 7.41421 1.33579 7.75 1.75 7.75Z"
																			stroke="#0B0B0B"
																			stroke-width="1.5"
																			stroke-linecap="round"
																			stroke-linejoin="round"
																		/>
																		<path
																			d="M1.75 2.5C2.16421 2.5 2.5 2.16421 2.5 1.75C2.5 1.33579 2.16421 1 1.75 1C1.33579 1 1 1.33579 1 1.75C1 2.16421 1.33579 2.5 1.75 2.5Z"
																			stroke="#0B0B0B"
																			stroke-width="1.5"
																			stroke-linecap="round"
																			stroke-linejoin="round"
																		/>
																		<path
																			d="M1.75 13C2.16421 13 2.5 12.6642 2.5 12.25C2.5 11.8358 2.16421 11.5 1.75 11.5C1.33579 11.5 1 11.8358 1 12.25C1 12.6642 1.33579 13 1.75 13Z"
																			stroke="#0B0B0B"
																			stroke-width="1.5"
																			stroke-linecap="round"
																			stroke-linejoin="round"
																		/>
																	</svg>
																</div>
															</div>
															<div className="commentcard__comment">
																<p>
																	Brightness & Contrast Adjustment: Increase
																	brightness and contrast to make the colors
																	pop.
																</p>
															</div>
														</div>
														<div className="commentcard">
															<div className="commentcard__dropdown">
																<div>
																	<div className="commentcard__profile">
																		<div>
																			<div className="commentcard__dp"></div>
																		</div>
																		<div className="commentcard__name">
																			Kaustav Karmakar
																			<div className="commentcard__time">
																				1.16 PM Today
																			</div>
																		</div>
																	</div>
																</div>
																<div>
																	<svg
																		width="4"
																		height="14"
																		viewBox="0 0 4 14"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M1.75 7.75C2.16421 7.75 2.5 7.41421 2.5 7C2.5 6.58579 2.16421 6.25 1.75 6.25C1.33579 6.25 1 6.58579 1 7C1 7.41421 1.33579 7.75 1.75 7.75Z"
																			stroke="#0B0B0B"
																			stroke-width="1.5"
																			stroke-linecap="round"
																			stroke-linejoin="round"
																		/>
																		<path
																			d="M1.75 2.5C2.16421 2.5 2.5 2.16421 2.5 1.75C2.5 1.33579 2.16421 1 1.75 1C1.33579 1 1 1.33579 1 1.75C1 2.16421 1.33579 2.5 1.75 2.5Z"
																			stroke="#0B0B0B"
																			stroke-width="1.5"
																			stroke-linecap="round"
																			stroke-linejoin="round"
																		/>
																		<path
																			d="M1.75 13C2.16421 13 2.5 12.6642 2.5 12.25C2.5 11.8358 2.16421 11.5 1.75 11.5C1.33579 11.5 1 11.8358 1 12.25C1 12.6642 1.33579 13 1.75 13Z"
																			stroke="#0B0B0B"
																			stroke-width="1.5"
																			stroke-linecap="round"
																			stroke-linejoin="round"
																		/>
																	</svg>
																</div>
															</div>
															<div className="commentcard__comment">
																<p>
																	Brightness & Contrast Adjustment: Increase
																	brightness and contrast to make the colors
																	pop.
																</p>
															</div>
														</div> */}
													</Col>
												</Row>
											</>
										)}
										{drawingMode === "drag" && (
											<>
												<Row className="align-items-center edit-setting-sidebar">
													<div
														className="sidebar__header"
														style={{ padding: "15px 15px 8px 15px" }}
													>
														<Col className="o-flex">
															<Select
																defaultValue={{
																	label: "New Settings",
																	id: "New Settings",
																}}
																options={selectLabel(group)}
																onChange={(e) => {
																	console.log(
																		"e call",
																		e.value
																		// onCustomizedGroupSelect.label
																	);
																	if (e.label === "New Settings") {
																		console.log(
																			"select dropdown",
																			e,
																			edit_select === null
																		);

																		// Update states when "New Settings" is selected
																		setEditGroupNameSelect(e.label);
																		setNewSettingsClicked(true);
																		customizedSettingsEnabled(true);
																		setChecked(false);
																		setChecked1(false);
																		setChecked2(false);
																		setChecked3(false);
																		setGroupSelect((val) => !val);
																		setEditId([]);
																		setEditorType(null);
																	} else if (
																		!selectLabel(group).includes(e.label)
																	) {
																		// Handle other selections
																		handleProfileChange(e);
																		setEditGroupNameSelect(e.label);
																		setEditGroupName("");
																		// setNewSettingsClicked(false);
																		setGroupSelect(e.value);
																		customizedSettingsEnabled(false);
																	} else if (
																		selectLabel(group).includes(e.label)
																	) {
																		customizedSettingsEnabled(false);
																		setGroupSelect(e.value);
																	}
																	// Optional: If you want to toggle the group selection in both cases, uncomment the line below
																	// setGroupSelect((val) => !val);
																}}
																placeholder="Select Profile"
																value={value} // Set the selected value by ID
																styles={customSelectStyles}
															/>
															<Button
																variant=""
																className="btns sidebar__header__save-btn privious-btn btn btn "
																onClick={() => {
																	if (
																		isEmptyObject(viewDuplicateSettingsById) ===
																		true
																	) {
																		handleUpdateDuplicateSettings();
																	} else {
																		if (group_name_select === "New Settings") {
																			handleUpdateEditGroup();
																		} else if (group_name_select === "") {
																			handleUpdateImageEditGroup();
																		} else if (
																			group_name_select ===
																			"Customized Settings"
																		) {
																			handleUpdateImageEditGroup();
																		}
																	}
																}}
															>
																{loader ? (
																	<div
																		style={{
																			backgroundColor: "wheat",
																		}}
																	>
																		<Loader />
																	</div>
																) : (
																	"Save"
																)}
															</Button>
														</Col>
														<Col>
															<div className="customizeSettings mt-2">
																{group_name_select ===
																	"Customized Settings" && (
																	<>
																		<Form.Label
																			className={
																				getDisabledStatus(group_name_select) ===
																				true
																					? `disabled_text`
																					: ""
																			}
																			for={"editFields"}
																			style={{ cursor: "pointer" }}
																		>
																			Customize Settings
																		</Form.Label>
																		<input
																			type="checkbox"
																			id={"editFields"}
																			onClick={(e) =>
																				toggleFieldCustomized(
																					e.target.checked,
																					setting_profile
																				)
																			}
																			checked={customizedSettings}
																		/>
																	</>
																)}
																{group_name_select === "New Settings" && (
																	<>
																		<Form.Label
																			className={`disabled_text`}
																			for={"editFields"}
																			style={{ cursor: "pointer" }}
																		>
																			Customize Settings
																		</Form.Label>
																		<input
																			type="checkbox"
																			id={"editFields"}
																			checked={false}
																			disabled={true}
																		/>
																	</>
																)}{" "}
																{group_name_select === "" && (
																	<>
																		<Form.Label
																			className={
																				getDisabledStatus(group_name_select) ===
																				true
																					? `disabled_text`
																					: ""
																			}
																			for={"editFields"}
																			style={{ cursor: "pointer" }}
																		>
																			Customize Settings
																		</Form.Label>
																		<input
																			type="checkbox"
																			id={"editFields"}
																			onClick={(e) =>
																				toggleFieldEditing(e.target.checked)
																			}
																			checked={customizedSettings}
																			disabled={getDisabledStatus(
																				group_name_select
																			)}
																		/>
																	</>
																)}
															</div>
														</Col>
													</div>

													<Col
														sm={12}
														className="sidebar-settings-image--modal sidebar-settings-image--image-modal"
													>
														{!viewDuplicateModal ? (
															<div className="sidebar-specifications--modal">
																{group_name_select === "New Settings" &&
																	// goToTop() &&
																	newSettingsClick === true && (
																		<div
																			className="sidebar-select-editType--modal editSettings"
																			id="et_head"
																		>
																			{/* Form groups for Edit Type*/}
																			<div className="form-sub-head">
																				Edit Type
																			</div>

																			{editTypes !== undefined &&
																				editTypes.map((item, i) => {
																					return (
																						<Form.Group
																							className=""
																							key={item.id}
																						>
																							{" "}
																							<Row
																								className="form-group  flex-grow-1"
																								sm={9}
																							>
																								<Col sm={1}>
																									<input
																										type="checkbox"
																										onChange={(e) => {
																											handleBackGroundRemovalCheckbox(
																												item.id,
																												e.target.checked
																											);
																											handleAddCheckboxSelect(
																												e
																											);
																										}}
																										value={item.id}
																										id={`et_${item.id}`}
																										className="et_options"
																									/>
																								</Col>
																								<Form.Label
																									className="col-sm-4 form-label form-check-label"
																									style={{
																										width: "auto",
																										marginTop: "1.7px",
																										cursor: "pointer",
																									}}
																									for={`et_${item.id}`}
																								>
																									{item.edit_type}
																								</Form.Label>
																							</Row>
																							{item.id === 1 && selected ? (
																								<Row
																									className="align-items-center"
																									style={{
																										marginLeft: "auto",
																										marginTop: "0",
																										marginBottom: "20px",
																									}}
																								>
																									<Col
																										sm={5}
																										className="ml_4_percent"
																									>
																										<Select
																											options={
																												selectBackgrondRemoval
																											}
																											onChange={(e) =>
																												setBackgroundRemovalColorType(
																													e.label
																												)
																											}
																											styles={customSelectStyle}
																										/>
																									</Col>
																									<Col sm={3}>
																										<Form.Control
																											type="text"
																											placeholder="#ffff"
																											autoFocus
																											onChange={(e) =>
																												setBackgroundRemovalColorCode(
																													e.target.value
																												)
																											}
																											autoComplete="off"
																										/>
																									</Col>
																								</Row>
																							) : (
																								""
																							)}
																							{item.id === 2 && selected1 ? (
																								<Row
																									className="align-items-center"
																									style={{
																										marginLeft: "auto",
																										marginTop: "0",
																										marginBottom: "20px",
																									}}
																								>
																									<Col
																										sm={5}
																										className="ml_4_percent"
																									>
																										<Select
																											options={
																												selectBackgrondRemoval
																											}
																											onChange={(e) =>
																												setBackgroundAdjustmentColorType(
																													e.label
																												)
																											}
																											styles={customSelectStyle}
																										/>
																									</Col>
																									<Col sm={3}>
																										<Form.Control
																											type="text"
																											placeholder="#ffff"
																											autoFocus
																											onChange={(e) =>
																												setBackgroundAdjustmentColorCode(
																													e.target.value
																												)
																											}
																											value={
																												background_adjustment_color_code
																											}
																											autoComplete="off"
																										/>
																									</Col>
																								</Row>
																							) : (
																								""
																							)}
																							{item.id === 3 && selected2 ? (
																								<Row
																									className="align-items-center"
																									style={{
																										marginLeft: "auto",
																										marginRight: "auto",
																										marginTop: "0",
																										marginBottom: "20px",
																										width: " 94%",
																									}}
																								>
																									<Form.Group
																										style={{ margin: "2px" }}
																									>
																										<div className="form-group">
																											<Row className="align-items-center">
																												<Col
																													sm={12}
																													className="ml-1"
																												>
																													<div className="d-sm-flex align-items-center col-gap-10">
																														<div className="d-sm-flex col-gap-10 align-items-center">
																															<Form.Control
																																type="text"
																																placeholder="Height"
																																autoFocus
																																onChange={(e) =>
																																	setResizingHeight(
																																		e.target
																																			.value
																																	)
																																}
																																autoComplete="off"
																															/>
																														</div>
																														<div className="d-sm-flex align-items-center">
																															<img
																																style={{
																																	width: "18px",
																																}}
																																src={cross}
																																className="img-fluid"
																																alt="mindreach images"
																															/>
																														</div>
																														<div className="d-sm-flex align-items-center col-gap-10">
																															<Form.Control
																																type="text"
																																placeholder="Width"
																																autoFocus
																																onChange={(e) =>
																																	setResizingWidth(
																																		e.target
																																			.value
																																	)
																																}
																																autoComplete="off"
																															/>
																														</div>
																													</div>
																												</Col>
																											</Row>
																										</div>
																									</Form.Group>
																								</Row>
																							) : (
																								""
																							)}
																						</Form.Group>
																					);
																				})}
																			{/* Form groups for output specifications */}
																			<div className="form-sub-head">
																				Output Specifications
																			</div>
																			<Form.Group className="mg-bottom15">
																				<Row
																					className="form-group  flex-grow-1"
																					sm={12}
																				>
																					<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																						File Format
																					</Form.Label>
																				</Row>
																			</Form.Group>
																			<Form.Group>
																				<div className="form-group">
																					<Row className="align-items-center">
																						<Col sm={12} className="pl-2">
																							<Select
																								options={file}
																								onChange={(e) =>
																									setImageFileFormat(e.label)
																								}
																								styles={customSelectStyle}
																							/>
																						</Col>
																					</Row>
																				</div>
																			</Form.Group>
																			<Form.Group className="mg-bottom15">
																				<Row
																					className="form-group  flex-grow-1"
																					sm={12}
																				>
																					<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																						Size
																					</Form.Label>
																				</Row>
																			</Form.Group>
																			<Form.Group>
																				<div className="form-group">
																					<Row className="align-items-center">
																						<Col
																							sm={12}
																							style={{ marginLeft: "-3px" }}
																						>
																							<div className="d-sm-flex align-items-center col-gap-10">
																								<div className="d-sm-flex col-gap-10 align-items-center">
																									<Form.Control
																										type="text"
																										placeholder="Height"
																										autoFocus
																										onChange={(e) =>
																											setImageHeight(
																												e.target.value
																											)
																										}
																										autoComplete="off"
																									/>
																								</div>
																								<div className="d-sm-flex align-items-center">
																									{" "}
																									<img
																										style={{ width: "18px" }}
																										src={cross}
																										alt="mindreach images"
																									/>
																								</div>
																								<div className="d-sm-flex align-items-center col-gap-10">
																									<Form.Control
																										type="text"
																										placeholder="Width"
																										autoFocus
																										onChange={(e) =>
																											setImageWidth(
																												e.target.value
																											)
																										}
																										autoComplete="off"
																									/>
																								</div>
																							</div>
																						</Col>
																					</Row>
																				</div>
																			</Form.Group>
																			<Form.Group className="mg-bottom15">
																				<Row
																					className="form-group  flex-grow-1"
																					sm={12}
																				>
																					<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																						DPI
																					</Form.Label>
																				</Row>
																			</Form.Group>
																			<Form.Group>
																				<div className="form-group">
																					<Row className="align-items-center">
																						<Col sm={12}>
																							<div className="d-sm-flex col-gap-20">
																								<Form.Control
																									type="text"
																									placeholder="2000"
																									autoFocus
																									onChange={(e) =>
																										setImageDpi(e.target.value)
																									}
																									autoComplete="off"
																									className="w-50"
																								/>

																								<div className="flex-fill">
																									<Select
																										options={dpi}
																										onChange={(e) =>
																											setImageDpiType(e.label)
																										}
																										styles={customSelectStyle}
																									/>
																								</div>
																							</div>
																						</Col>
																					</Row>
																				</div>
																			</Form.Group>
																			<Form.Group className="mg-bottom15">
																				<Row
																					className="form-group  flex-grow-1"
																					sm={12}
																				>
																					<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																						Color Mode
																					</Form.Label>
																				</Row>
																			</Form.Group>
																			<Form.Group>
																				<div className="form-group">
																					<Row className="align-items-center">
																						<Col sm={12}>
																							<Select
																								options={ColorMode}
																								onChange={(e) =>
																									setImageColorMode(e.label)
																								}
																								styles={customSelectStyle}
																							/>
																						</Col>
																					</Row>
																				</div>
																			</Form.Group>
																			<Form.Group className="mg-bottom15">
																				<Row
																					className="form-group  flex-grow-1"
																					sm={12}
																				>
																					<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																						Align
																					</Form.Label>
																				</Row>
																			</Form.Group>
																			<Form.Group>
																				<div className="form-group">
																					<Row className="align-items-center">
																						<Col sm={12}>
																							<Select
																								options={Align}
																								onChange={(e) =>
																									setImageAlignment(e.label)
																								}
																								styles={customSelectStyle}
																							/>
																						</Col>
																					</Row>
																				</div>
																			</Form.Group>
																			<Form.Group className="mg-bottom15">
																				<Row
																					className="form-group  flex-grow-1"
																					sm={12}
																				>
																					<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																						Margins
																					</Form.Label>
																				</Row>
																			</Form.Group>
																			<Form.Group>
																				<div className="form-group">
																					<Row className="align-items-center">
																						<Col sm={12}>
																							<Select
																								options={Margin}
																								onChange={(e) =>
																									setResizingMarginFormat(
																										e.label
																									)
																								}
																								styles={customSelectStyle}
																							/>
																						</Col>
																						<Col
																							sm={12}
																							lg={12}
																							style={{
																								display: "flex",
																								marginTop: "12px",
																							}}
																						>
																							<div className="d-sm-flex col-gap-20 mt-2 ">
																								<div className="d-flex">
																									<ul className="margin-list d-flex">
																										<li>
																											<Form.Control
																												type="text"
																												placeholder="T"
																												autoFocus
																												onChange={(e) =>
																													setImageMarginTop(
																														e.target.value
																													)
																												}
																												autoComplete="off"
																											/>
																										</li>
																										<li>
																											<Form.Control
																												type="text"
																												placeholder="B"
																												autoFocus
																												onChange={(e) =>
																													setImageMarginBottom(
																														e.target.value
																													)
																												}
																												autoComplete="off"
																											/>
																										</li>
																										<li>
																											<Form.Control
																												type="text"
																												placeholder="L"
																												autoFocus
																												onChange={(e) =>
																													setImageMarginLeft(
																														e.target.value
																													)
																												}
																												autoComplete="off"
																											/>
																										</li>
																										<li>
																											<Form.Control
																												type="text"
																												placeholder="R"
																												autoFocus
																												onChange={(e) =>
																													setImageMarginRight(
																														e.target.value
																													)
																												}
																												autoComplete="off"
																											/>
																										</li>
																									</ul>
																								</div>
																							</div>
																						</Col>

																						<Col sm={6}></Col>
																					</Row>
																				</div>
																			</Form.Group>
																			<Form.Group>
																				<div className="form-group">
																					<Row className="align-items-center">
																						<div className="text-center">
																							{/* <Button
																								variant="primary"
																								className="btns"
																								onClick={() =>
																									handleUpdateEditGroup()
																								}
																							>
																								{loader ? (
																									<div
																										style={{
																											backgroundColor: "wheat",
																										}}
																									>
																										<Loader />
																									</div>
																								) : (
																									"Save"
																								)}
																							</Button> */}
																						</div>
																						<Col sm={6}></Col>
																					</Row>
																				</div>
																			</Form.Group>
																		</div>
																	)}
																{group_name_select === "New Settings" &&
																	newSettingsClick === false &&
																	// goToTop() &&
																	edit_select === null && (
																		<div
																			className="sidebar-select-editType--modal  editSettings"
																			id="et_head"
																		>
																			{/* Form groups for Edit Type*/}
																			<div className="form-sub-head">
																				Edit Type
																			</div>

																			{editTypes !== undefined &&
																				editTypes.map((item, i) => {
																					return (
																						<Form.Group
																							className=""
																							key={item.id}
																						>
																							{" "}
																							<Row
																								className="form-group  flex-grow-1"
																								sm={9}
																							>
																								<Col sm={1}>
																									<input
																										type="checkbox"
																										onClick={(e) => {
																											if (
																												group_name_select ===
																												"New Settings"
																											) {
																											}
																											handleBackGroundRemovalCheckbox(
																												item.id,
																												e.target.checked,
																												"Edit"
																											);
																											handleAddCheckboxSelect(
																												e
																											);
																										}}
																										value={item.id}
																										id={`et_${item.id}`}
																										className="et_options"
																									/>
																								</Col>
																								<Form.Label
																									className="col-sm-4 form-label form-check-label"
																									style={{
																										width: "auto",
																										marginTop: "1.7px",
																										cursor: "pointer",
																									}}
																									for={`et_${item.id}`}
																								>
																									{item.edit_type}
																								</Form.Label>
																							</Row>
																							{item.id === 1 && selected ? (
																								<Row
																									className="align-items-center"
																									style={{
																										marginLeft: "auto",
																										marginTop: "0",
																										marginBottom: "20px",
																									}}
																								>
																									<Col
																										sm={5}
																										className="ml_4_percent"
																									>
																										<Select
																											options={
																												selectBackgrondRemoval
																											}
																											onChange={(e) =>
																												setBackgroundRemovalColorType(
																													e.label
																												)
																											}
																											styles={customSelectStyle}
																										/>
																									</Col>
																									<Col sm={3}>
																										<Form.Control
																											type="text"
																											placeholder="#ffff"
																											autoFocus
																											onChange={(e) =>
																												setBackgroundRemovalColorCode(
																													e.target.value
																												)
																											}
																											autoComplete="off"
																										/>
																									</Col>
																								</Row>
																							) : (
																								""
																							)}
																							{item.id === 2 && selected1 ? (
																								<Row
																									className="align-items-center"
																									style={{
																										marginLeft: "auto",
																										marginTop: "0",
																										marginBottom: "20px",
																									}}
																								>
																									<Col
																										sm={5}
																										className="ml_4_percent"
																									>
																										<Select
																											options={
																												selectBackgrondRemoval
																											}
																											onChange={(e) =>
																												setBackgroundAdjustmentColorType(
																													e.label
																												)
																											}
																											styles={customSelectStyle}
																										/>
																									</Col>
																									<Col sm={3}>
																										<Form.Control
																											type="text"
																											placeholder="#ffff"
																											autoFocus
																											onChange={(e) =>
																												setBackgroundAdjustmentColorCode(
																													e.target.value
																												)
																											}
																											value={
																												background_adjustment_color_code
																											}
																											autoComplete="off"
																										/>
																									</Col>
																								</Row>
																							) : (
																								""
																							)}
																							{item.id === 3 && selected2 ? (
																								<Row
																									className="align-items-center"
																									style={{
																										marginLeft: "auto",
																										marginRight: "auto",
																										marginTop: "0",
																										marginBottom: "20px",
																										width: " 94%",
																									}}
																								>
																									<Form.Group
																										style={{ margin: "2px" }}
																									>
																										<div className="form-group">
																											<Row className="align-items-center">
																												<Col
																													sm={12}
																													className="ml-1"
																												>
																													<div className="d-sm-flex align-items-center col-gap-10">
																														<div className="d-sm-flex col-gap-10 align-items-center">
																															<Form.Control
																																type="text"
																																placeholder="Height"
																																autoFocus
																																onChange={(e) =>
																																	setResizingHeight(
																																		e.target
																																			.value
																																	)
																																}
																																autoComplete="off"
																															/>
																														</div>
																														<div className="d-sm-flex align-items-center">
																															<img
																																style={{
																																	width: "18px",
																																}}
																																src={cross}
																																className="img-fluid"
																																alt="mindreach images"
																															/>
																														</div>
																														<div className="d-sm-flex align-items-center col-gap-10">
																															<Form.Control
																																type="text"
																																placeholder="Width"
																																autoFocus
																																onChange={(e) =>
																																	setResizingWidth(
																																		e.target
																																			.value
																																	)
																																}
																																autoComplete="off"
																															/>
																														</div>
																													</div>
																												</Col>
																											</Row>
																										</div>
																									</Form.Group>
																								</Row>
																							) : (
																								""
																							)}
																						</Form.Group>
																					);
																				})}
																			{/* Form groups for output specifications */}
																			<div className="form-sub-head">
																				Output Specifications
																			</div>
																			<Form.Group className="mg-bottom15">
																				<Row
																					className="form-group  flex-grow-1"
																					sm={12}
																				>
																					<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																						File Format
																					</Form.Label>
																				</Row>
																			</Form.Group>
																			<Form.Group>
																				<div className="form-group">
																					<Row className="align-items-center">
																						<Col sm={12} className="pl-2">
																							<Select
																								options={file}
																								onChange={(e) =>
																									setImageFileFormat(e.label)
																								}
																								styles={customSelectStyle}
																							/>
																						</Col>
																					</Row>
																				</div>
																			</Form.Group>
																			<Form.Group className="mg-bottom15">
																				<Row
																					className="form-group  flex-grow-1"
																					sm={12}
																				>
																					<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																						Size
																					</Form.Label>
																				</Row>
																			</Form.Group>
																			<Form.Group>
																				<div className="form-group">
																					<Row className="align-items-center">
																						<Col
																							sm={12}
																							style={{ marginLeft: "-3px" }}
																						>
																							<div className="d-sm-flex align-items-center col-gap-10">
																								<div className="d-sm-flex col-gap-10 align-items-center">
																									<Form.Control
																										type="text"
																										placeholder="Height"
																										autoFocus
																										onChange={(e) =>
																											setImageHeight(
																												e.target.value
																											)
																										}
																										autoComplete="off"
																									/>
																								</div>
																								<div className="d-sm-flex align-items-center">
																									{" "}
																									<img
																										style={{ width: "18px" }}
																										src={cross}
																										alt="mindreach images"
																									/>
																								</div>
																								<div className="d-sm-flex align-items-center col-gap-10">
																									<Form.Control
																										type="text"
																										placeholder="Width"
																										autoFocus
																										onChange={(e) =>
																											setImageWidth(
																												e.target.value
																											)
																										}
																										autoComplete="off"
																									/>
																								</div>
																							</div>
																						</Col>
																					</Row>
																				</div>
																			</Form.Group>
																			<Form.Group className="mg-bottom15">
																				<Row
																					className="form-group  flex-grow-1"
																					sm={12}
																				>
																					<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																						DPI
																					</Form.Label>
																				</Row>
																			</Form.Group>
																			<Form.Group>
																				<div className="form-group">
																					<Row className="align-items-center">
																						<Col sm={12}>
																							<div className="d-sm-flex col-gap-20">
																								<Form.Control
																									type="text"
																									placeholder="2000"
																									autoFocus
																									onChange={(e) =>
																										setImageDpi(e.target.value)
																									}
																									autoComplete="off"
																									className="w-50"
																								/>

																								<div className="flex-fill">
																									<Select
																										options={dpi}
																										onChange={(e) =>
																											setImageDpiType(e.label)
																										}
																										styles={customSelectStyle}
																									/>
																								</div>
																							</div>
																						</Col>
																					</Row>
																				</div>
																			</Form.Group>
																			<Form.Group className="mg-bottom15">
																				<Row
																					className="form-group  flex-grow-1"
																					sm={12}
																				>
																					<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																						Color Mode
																					</Form.Label>
																				</Row>
																			</Form.Group>
																			<Form.Group>
																				<div className="form-group">
																					<Row className="align-items-center">
																						<Col sm={12}>
																							<Select
																								options={ColorMode}
																								onChange={(e) =>
																									setImageColorMode(e.label)
																								}
																								styles={customSelectStyle}
																							/>
																						</Col>
																					</Row>
																				</div>
																			</Form.Group>
																			<Form.Group className="mg-bottom15">
																				<Row
																					className="form-group  flex-grow-1"
																					sm={12}
																				>
																					<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																						Align
																					</Form.Label>
																				</Row>
																			</Form.Group>
																			<Form.Group>
																				<div className="form-group">
																					<Row className="align-items-center">
																						<Col sm={12}>
																							<Select
																								options={Align}
																								onChange={(e) =>
																									setImageAlignment(e.label)
																								}
																								styles={customSelectStyle}
																							/>
																						</Col>
																					</Row>
																				</div>
																			</Form.Group>
																			<Form.Group className="mg-bottom15">
																				<Row
																					className="form-group  flex-grow-1"
																					sm={12}
																				>
																					<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																						Margins
																					</Form.Label>
																				</Row>
																			</Form.Group>
																			<Form.Group>
																				<div className="form-group">
																					<Row className="align-items-center">
																						<Col sm={12}>
																							<Select
																								options={Margin}
																								onChange={(e) =>
																									setResizingMarginFormat(
																										e.label
																									)
																								}
																								styles={customSelectStyle}
																							/>
																						</Col>
																						<Col
																							sm={12}
																							lg={12}
																							style={{
																								display: "flex",
																								marginTop: "12px",
																							}}
																						>
																							<div className="d-sm-flex col-gap-20 mt-2 ">
																								<div className="d-flex">
																									<ul className="margin-list d-flex">
																										<li>
																											<Form.Control
																												type="text"
																												placeholder="T"
																												autoFocus
																												onChange={(e) =>
																													setImageMarginTop(
																														e.target.value
																													)
																												}
																												autoComplete="off"
																											/>
																										</li>
																										<li>
																											<Form.Control
																												type="text"
																												placeholder="B"
																												autoFocus
																												onChange={(e) =>
																													setImageMarginBottom(
																														e.target.value
																													)
																												}
																												autoComplete="off"
																											/>
																										</li>
																										<li>
																											<Form.Control
																												type="text"
																												placeholder="L"
																												autoFocus
																												onChange={(e) =>
																													setImageMarginLeft(
																														e.target.value
																													)
																												}
																												autoComplete="off"
																											/>
																										</li>
																										<li>
																											<Form.Control
																												type="text"
																												placeholder="R"
																												autoFocus
																												onChange={(e) =>
																													setImageMarginRight(
																														e.target.value
																													)
																												}
																												autoComplete="off"
																											/>
																										</li>
																									</ul>
																								</div>
																							</div>
																						</Col>

																						<Col sm={6}></Col>
																					</Row>
																				</div>
																			</Form.Group>
																		</div>
																	)}

																{group_name_select === "" && (
																	<div
																		className="sidebar-select-editType--modal  editSettings"
																		id="et_head"
																	>
																		{((view_edit_group !== null &&
																			view_edit_group !== undefined) ||
																			// view_edit_group.edit_group) ||
																			(view_edit_group !== undefined &&
																				view_edit_group.edit_group !== null &&
																				setting_profile &&
																				setting_profile.resizing_settings)) && (
																			<>
																				<div className="form-sub-head">
																					Edit Type
																				</div>
																			</>
																		)}
																		{/* /***need to check**/}
																		{
																			// view_edit_group !== null &&
																			// view_edit_group !== undefined &&
																			// view_edit_group.edit_group) ||
																			// (view_edit_group !== undefined &&
																			// 	view_edit_group.edit_group !== null &&
																			// 	setting_profile &&
																			// 	setting_profile.resizing_settings)) &&
																			editTypes &&
																				editTypes.map((item) => {
																					return (
																						<>
																							<Form.Group>
																								{" "}
																								<Row
																									className="form-group  flex-grow-1"
																									sm={12}
																								>
																									<Col sm={1}>
																										<input
																											type="checkbox"
																											value={item.id}
																											onChange={(e) => {
																												// if (customizedSettings) {
																												handleBackGroundRemovalCheckbox(
																													item.id,
																													e.target.checked,
																													"Edit",
																													customizedSettings
																												);
																												handleAddCheckboxSelect(
																													e
																												);
																												if (
																													customizedSettings
																												) {
																													setGroupSelect(
																														"New Settings"
																													);
																												}
																												// }
																											}}
																											checked={
																												item.id === 1
																													? checked
																													: item.id === 2
																													? checked1
																													: item.id === 3
																													? checked2
																													: checked3
																											}
																											id={`et_${item.id}`}
																											className="et_options"
																											disabled={checkisEditableField()}
																										/>
																									</Col>
																									<Form.Label
																										className="col-sm-4 form-label form-check-label"
																										style={{
																											width: "auto",
																											marginTop: "1.4px",
																											cursor: "pointer",
																										}}
																										for={`et_${item.id}`}
																									>
																										{item.edit_type}
																									</Form.Label>
																								</Row>
																								{item.id === 1 && checked ? (
																									<Row
																										className="align-items-center"
																										style={{
																											// marginLeft: "44px",
																											marginTop: "-2px",
																											marginBottom: "20px",
																											width: "100%",
																										}}
																									>
																										<Col sm={6}>
																											<Select
																												options={
																													selectBackgrondRemoval
																												}
																												onChange={(e) =>
																													setBackgroundRemovalColorType(
																														e.label
																													)
																												}
																												defaultValue={{
																													label: `${background_removal_color_type}`,
																													id: "21",
																												}}
																												value={{
																													label:
																														background_removal_color_type, // Set the label property to the desired value
																													value:
																														background_removal_color_type, // Set the value property to the corresponding value
																												}}
																												isDisabled={checkisEditableField()}
																												styles={
																													customSelectStyle
																												}
																											/>
																										</Col>
																										<Col sm={6}>
																											<Form.Control
																												type="text"
																												placeholder="#ffff"
																												autoFocus
																												onChange={(e) =>
																													setBackgroundRemovalColorCode(
																														e.target.value
																													)
																												}
																												disabled={checkisEditableField()}
																												value={
																													background_removal_color_code
																												}
																											/>
																										</Col>
																									</Row>
																								) : (
																									""
																								)}
																								{item.id === 2 && checked1 ? (
																									<Row
																										className="align-items-center"
																										style={{
																											// marginLeft: "44px",
																											marginTop: "-2px",
																											marginBottom: "20px",
																											width: "100%",
																										}}
																									>
																										<Col sm={6}>
																											<Select
																												options={
																													selectBackgrondRemoval
																												}
																												onChange={(e) =>
																													setBackgroundAdjustmentColorType(
																														e.label
																													)
																												}
																												// defaultValue={{
																												// 	label: `${background_adjustment_color_type}`,
																												// 	id: "21",
																												// }}
																												value={{
																													label:
																														background_adjustment_color_type, // Set the label property to the desired value
																													value:
																														background_adjustment_color_type, // Set the value property to the corresponding value
																												}}
																												isDisabled={checkisEditableField()}
																												styles={
																													customSelectStyle
																												}
																											/>
																										</Col>
																										<Col sm={6}>
																											<Form.Control
																												type="text"
																												placeholder="#ffff"
																												autoFocus
																												disabled={checkisEditableField()}
																												onChange={(e) =>
																													setBackgroundAdjustmentColorCode(
																														e.target.value
																													)
																												}
																												value={
																													background_adjustment_color_code
																												}
																											/>
																										</Col>
																									</Row>
																								) : (
																									""
																								)}
																								{item.id === 3 && checked2 ? (
																									<Row
																										className="align-items-center"
																										style={{
																											// marginLeft: "40px",
																											marginTop: "-2px",
																											marginBottom: "20px",
																											width: "100%",
																										}}
																									>
																										<Form.Group
																											style={{
																												margin: "2px",
																											}}
																											controlId="exampleForm.ControlTextarea1"
																										>
																											<div className="form-group">
																												<Row className="align-items-center">
																													<Col sm={12}>
																														<div className="d-sm-flex align-items-center col-gap-10">
																															<div className="d-sm-flex col-gap-10 align-items-center">
																																<Form.Control
																																	type="text"
																																	placeholder="Height"
																																	autoFocus
																																	onChange={(
																																		e
																																	) =>
																																		setResizingHeight(
																																			e.target
																																				.value
																																		)
																																	}
																																	value={
																																		resizing_height
																																	}
																																	disabled={checkisEditableField()}
																																/>
																															</div>
																															<div className="d-sm-flex align-items-center">
																																<img
																																	style={{
																																		width:
																																			"18px",
																																	}}
																																	src={cross}
																																	alt="mindreach images"
																																/>
																															</div>
																															<div className="d-sm-flex align-items-center col-gap-10">
																																<Form.Control
																																	type="text"
																																	placeholder="Width"
																																	autoFocus
																																	onChange={(
																																		e
																																	) =>
																																		setResizingWidth(
																																			e.target
																																				.value
																																		)
																																	}
																																	value={
																																		resizing_width
																																	}
																																	disabled={checkisEditableField()}
																																/>
																															</div>
																														</div>
																													</Col>
																												</Row>
																											</div>
																										</Form.Group>
																									</Row>
																								) : (
																									""
																								)}
																							</Form.Group>
																						</>
																					);
																				})
																		}
																		{/* Form groups for output specifications */}
																		{/* Form groups for output specifications */}

																		{edit_select !== null && (
																			<>
																				<div
																					className="form-sub-head"
																					style={{ marginTop: "15px" }}
																				>
																					Output Specifications
																				</div>
																				<Form.Group className="mg-bottom15">
																					<Row
																						className="form-group  flex-grow-1"
																						sm={12}
																					>
																						<Form.Label className="col-sm-4 form-label side-form-label">
																							File Format
																						</Form.Label>
																					</Row>
																				</Form.Group>
																				<Form.Group>
																					<div className="form-group">
																						<Row className="align-items-center">
																							<Col sm={12} className="pl-2">
																								<Select
																									options={file}
																									onChange={(e) =>
																										setImageFileFormat(e.label)
																									}
																									value={{
																										label: image_file_format,
																										value: image_file_format, // Set the value property to the corresponding value
																									}}
																									isDisabled={checkisEditableField()}
																								/>
																							</Col>
																						</Row>
																					</div>
																				</Form.Group>
																				<Form.Group className="mg-bottom15">
																					<Row
																						className="form-group  flex-grow-1"
																						sm={12}
																					>
																						<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																							Size
																						</Form.Label>
																					</Row>
																				</Form.Group>
																				<Form.Group>
																					<div className="form-group">
																						<Row className="align-items-center">
																							<Col sm={12}>
																								<div className="d-sm-flex align-items-center col-gap-10">
																									<div className="d-sm-flex col-gap-10 align-items-center">
																										<Form.Control
																											type="text"
																											placeholder="Height"
																											onChange={(e) =>
																												setImageHeight(
																													e.target.value
																												)
																											}
																											disabled={checkisEditableField()}
																											value={image_height}
																										/>
																									</div>
																									<div className="d-sm-flex align-items-center">
																										<img
																											style={{
																												width: "18px",
																											}}
																											src={cross}
																											alt="mindreach images"
																										/>
																									</div>
																									<div className="d-sm-flex align-items-center col-gap-10">
																										<Form.Control
																											type="text"
																											placeholder="Width"
																											onChange={(e) =>
																												setImageWidth(
																													e.target.value
																												)
																											}
																											disabled={checkisEditableField()}
																											value={image_width}
																										/>
																									</div>
																								</div>
																							</Col>
																						</Row>
																					</div>
																				</Form.Group>
																				<Form.Group className="mg-bottom15">
																					<Row
																						className="form-group  flex-grow-1"
																						sm={12}
																					>
																						<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																							DPI
																						</Form.Label>
																					</Row>
																				</Form.Group>
																				<Form.Group>
																					<div className="form-group">
																						<Row className="align-items-center">
																							<Col sm={12}>
																								<div className="d-sm-flex col-gap-20">
																									<Form.Control
																										type="text"
																										placeholder="2000"
																										autoComplete="off"
																										className="w-50"
																										value={image_dpi}
																										disabled={checkisEditableField()}
																										onChange={(e) =>
																											setImageDpi(
																												e.target.value
																											)
																										}
																									/>
																									<div className="flex-fill">
																										<Select
																											options={dpi}
																											onChange={(e) =>
																												setImageDpiType(e.label)
																											}
																											value={{
																												label: image_dpi_type,
																												value: image_dpi_type,
																											}}
																											isDisabled={checkisEditableField()}
																										/>
																									</div>
																								</div>
																							</Col>
																						</Row>
																					</div>
																				</Form.Group>
																				<Form.Group className="mg-bottom15">
																					<Row
																						className="form-group  flex-grow-1"
																						sm={12}
																					>
																						<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																							Color Mode
																						</Form.Label>
																					</Row>
																				</Form.Group>
																				<Form.Group>
																					<div className="form-group">
																						<Row className="align-items-center">
																							<Col sm={12}>
																								<Select
																									options={ColorMode}
																									onChange={(e) =>
																										setImageColorMode(e.label)
																									}
																									value={{
																										label: image_color_mode,
																										value: image_color_mode,
																									}}
																									isDisabled={checkisEditableField()}
																								/>
																							</Col>
																						</Row>
																					</div>
																				</Form.Group>
																				<Form.Group className="mg-bottom15">
																					<Row
																						className="form-group  flex-grow-1"
																						sm={12}
																					>
																						<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																							Align
																						</Form.Label>
																					</Row>
																				</Form.Group>
																				<Form.Group>
																					<div className="form-group">
																						<Row className="align-items-center">
																							<Col sm={12}>
																								<Select
																									options={Align}
																									onChange={(e) =>
																										setImageAlignment(e.label)
																									}
																									value={{
																										label: image_alignment, // Set the label property to the desired value
																										value: image_alignment, // Set the value property to the corresponding value
																									}}
																									isDisabled={checkisEditableField()}
																								/>
																							</Col>
																						</Row>
																					</div>
																				</Form.Group>
																				<Form.Group className="mg-bottom15">
																					<Row
																						className="form-group  flex-grow-1"
																						sm={12}
																					>
																						<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																							Margins
																						</Form.Label>
																					</Row>
																				</Form.Group>
																				<Form.Group>
																					<div className="form-group">
																						<Row className="align-items-center">
																							<Col sm={12}>
																								<Select
																									options={Margin}
																									onChange={(e) =>
																										setImageMarginFormat(
																											e.label
																										)
																									}
																									value={{
																										label: image_margin_format, // Set the label property to the desired value
																										value: image_margin_format, // Set the value property to the corresponding value
																									}}
																									isDisabled={checkisEditableField()}
																								/>
																							</Col>
																							<Col
																								sm={7}
																								lg={7}
																								style={{
																									display: "flex",
																									marginTop: "12px",
																								}}
																							>
																								<div className="d-sm-flex col-gap-20 mt-2 ">
																									<div className="d-flex">
																										<ul className="margin-list d-flex">
																											<li
																												className={`${
																													checkisEditableField()
																														? "disabled"
																														: ""
																												}`}
																											>
																												<Form.Control
																													type="text"
																													placeholder="T"
																													value={
																														image_margin_top
																													}
																													disabled={checkisEditableField()}
																													onChange={(e) =>
																														setImageMarginTop(
																															e.target.value
																														)
																													}
																												/>
																											</li>
																											<li
																												className={`${
																													checkisEditableField()
																														? "disabled"
																														: ""
																												}`}
																											>
																												<Form.Control
																													type="text"
																													placeholder="B"
																													value={
																														image_margin_bottom
																													}
																													disabled={checkisEditableField()}
																													onChange={(e) =>
																														setImageMarginBottom(
																															e.target.value
																														)
																													}
																												/>
																											</li>
																											<li
																												className={`${
																													checkisEditableField()
																														? "disabled"
																														: ""
																												}`}
																											>
																												<Form.Control
																													type="text"
																													placeholder="L"
																													value={
																														image_margin_left
																													}
																													disabled={checkisEditableField()}
																													onChange={(e) =>
																														setImageMarginLeft(
																															e.target.value
																														)
																													}
																												/>
																											</li>
																											<li
																												className={`${
																													checkisEditableField()
																														? "disabled"
																														: ""
																												}`}
																											>
																												<Form.Control
																													type="text"
																													placeholder="R"
																													value={
																														image_margin_right
																													}
																													disabled={checkisEditableField()}
																													onChange={(e) =>
																														setImageMarginRight(
																															e.target.value
																														)
																													}
																												/>
																											</li>
																										</ul>
																									</div>
																								</div>
																							</Col>
																							<Col sm={6}></Col>
																						</Row>
																					</div>
																				</Form.Group>
																			</>
																		)}
																		{view_edit_group !== undefined &&
																			view_edit_group.edit_group === null &&
																			setting_profile &&
																			setting_profile.setting && (
																				<>
																					<div className="sub-head">
																						Output Specifications
																					</div>
																					<Form.Group className="mg-bottom15">
																						<Row
																							className="form-group  flex-grow-1"
																							sm={9}
																						>
																							<Col sm={1}></Col>
																							<Form.Label className="col-sm-4 form-label form-check-label">
																								File Format
																							</Form.Label>
																						</Row>
																					</Form.Group>
																					<Form.Group style={{ margin: "2px" }}>
																						<div className="form-group">
																							<Row className="align-items-center">
																								<Form.Label className="col-sm-1"></Form.Label>
																								<Col sm={6} className="pl-2">
																									<Select
																										options={file}
																										onChange={(e) =>
																											setImageFileFormat(
																												e.label
																											)
																										}
																										value={{
																											label: image_file_format, // Set the label property to the desired value
																											value: image_file_format, // Set the value property to the corresponding value
																										}}
																										isDisabled={checkisEditableField()}
																									/>
																								</Col>
																							</Row>
																						</div>
																					</Form.Group>
																					<Form.Group className="mg-bottom15">
																						<Row
																							className="form-group  flex-grow-1"
																							sm={9}
																						>
																							<Col sm={1}></Col>
																							<Form.Label className="col-sm-4 form-label form-check-label">
																								Size
																							</Form.Label>
																						</Row>
																					</Form.Group>
																					<Form.Group style={{ margin: "2px" }}>
																						<div className="form-group">
																							<Row className="align-items-center">
																								<Form.Label className="col-sm-1"></Form.Label>
																								<Col sm={9}>
																									<div className="d-sm-flex align-items-center col-gap-10">
																										<div className="d-sm-flex col-gap-10 align-items-center">
																											<Form.Control
																												type="text"
																												placeholder="Height"
																												disabled={checkisEditableField()}
																												value={image_height}
																												onChange={(e) =>
																													setImageHeight(
																														e.target.value
																													)
																												}
																											/>
																										</div>
																										<div className="d-sm-flex align-items-center">
																											<img
																												style={{
																													width: "18px",
																												}}
																												src={cross}
																												alt="mindreach images"
																											/>
																										</div>
																										<div className="d-sm-flex align-items-center col-gap-10">
																											<Form.Control
																												type="text"
																												placeholder="Width"
																												disabled={checkisEditableField()}
																												value={image_width}
																												onChange={(e) =>
																													setImageWidth(
																														e.target.value
																													)
																												}
																											/>
																										</div>
																									</div>
																								</Col>
																							</Row>
																						</div>
																					</Form.Group>
																					<Form.Group className="mg-bottom15">
																						<Row
																							className="form-group  flex-grow-1"
																							sm={9}
																						>
																							<Col sm={1}></Col>
																							<Form.Label className="col-sm-4 form-label form-check-label">
																								DPI
																							</Form.Label>
																						</Row>
																					</Form.Group>
																					<Form.Group style={{ margin: "2px" }}>
																						<div className="form-group">
																							<Row className="align-items-center">
																								<Form.Label className="col-sm-1"></Form.Label>
																								<Col sm={10}>
																									<div className="d-sm-flex col-gap-20">
																										<Form.Control
																											type="text"
																											placeholder="2000"
																											autoComplete="off"
																											className="w-50"
																											value={image_dpi}
																											disabled={checkisEditableField()}
																											onChange={(e) =>
																												setImageDpi(
																													e.target.value
																												)
																											}
																										/>
																										<div className="flex-fill">
																											<Select
																												options={dpi}
																												onChange={(e) =>
																													setImageDpiType(
																														e.label
																													)
																												}
																												value={{
																													label: image_dpi_type, // Set the label property to the desired value
																													value: image_dpi_type, // Set the value property to the corresponding value
																												}}
																												isDisabled={checkisEditableField()}
																											/>
																										</div>
																									</div>
																								</Col>
																							</Row>
																						</div>
																					</Form.Group>
																					<Form.Group className="mg-bottom15">
																						<Row
																							className="form-group  flex-grow-1"
																							sm={9}
																						>
																							<Col sm={1}></Col>
																							<Form.Label className="col-sm-4 form-label form-check-label">
																								Color Mode
																							</Form.Label>
																						</Row>
																					</Form.Group>
																					<Form.Group style={{ margin: "2px" }}>
																						<div className="form-group">
																							<Row className="align-items-center">
																								<Form.Label className="col-sm-1"></Form.Label>
																								<Col sm={9}>
																									<Select
																										options={ColorMode}
																										onChange={(e) =>
																											setImageColorMode(e.label)
																										}
																										value={{
																											label: image_color_mode, // Set the label property to the desired value
																											value: image_color_mode, // Set the value property to the corresponding value
																										}}
																										isDisabled={checkisEditableField()}
																									/>
																								</Col>
																							</Row>
																						</div>
																					</Form.Group>
																					<Form.Group className="mg-bottom15">
																						<Row
																							className="form-group  flex-grow-1"
																							sm={9}
																						>
																							<Col sm={1}></Col>
																							<Form.Label className="col-sm-4 form-label form-check-label">
																								Align
																							</Form.Label>
																						</Row>
																					</Form.Group>
																					<Form.Group style={{ margin: "2px" }}>
																						<div className="form-group">
																							<Row className="align-items-center">
																								<Form.Label className="col-sm-1"></Form.Label>
																								<Col sm={9}>
																									<Select
																										options={Align}
																										onChange={(e) =>
																											setImageAlignment(e.label)
																										}
																										value={{
																											label: image_alignment, // Set the label property to the desired value
																											value: image_alignment, // Set the value property to the corresponding value
																										}}
																										isDisabled={checkisEditableField()}
																									/>
																								</Col>
																							</Row>
																						</div>
																					</Form.Group>
																					<Form.Group className="mg-bottom15">
																						<Row
																							className="form-group  flex-grow-1"
																							sm={9}
																						>
																							<Col sm={1}></Col>
																							<Form.Label className="col-sm-4 form-label form-check-label">
																								Margins
																							</Form.Label>
																						</Row>
																					</Form.Group>
																					<Form.Group style={{ margin: "2px" }}>
																						<div className="form-group">
																							<Row className="align-items-center">
																								<Form.Label className="col-sm-1"></Form.Label>
																								<Col sm={9}>
																									<Select
																										options={Margin}
																										onChange={(e) =>
																											setImageMarginFormat(
																												e.label
																											)
																										}
																										value={{
																											label:
																												image_margin_format, // Set the label property to the desired value
																											value:
																												image_margin_format, // Set the value property to the corresponding value
																										}}
																										isDisabled={checkisEditableField()}
																									/>
																								</Col>
																								<Col
																									sm={7}
																									lg={7}
																									style={{
																										display: "flex",
																										marginLeft: "2rem",
																										marginTop: "12px",
																									}}
																								>
																									<div className="d-sm-flex col-gap-20 mt-2 ">
																										<div className="d-flex">
																											<ul className="margin-list d-flex">
																												<li
																													className={`${
																														checkisEditableField()
																															? "disabled"
																															: ""
																													}`}
																												>
																													<Form.Control
																														type="text"
																														placeholder="T"
																														value={
																															image_margin_top
																														}
																														disabled={checkisEditableField()}
																														onChange={(e) =>
																															setImageMarginTop(
																																e.target.value
																															)
																														}
																													/>
																												</li>
																												<li
																													className={`${
																														checkisEditableField()
																															? "disabled"
																															: ""
																													}`}
																												>
																													<Form.Control
																														type="text"
																														placeholder="B"
																														value={
																															image_margin_bottom
																														}
																														disabled={checkisEditableField()}
																														onChange={(e) =>
																															setImageMarginBottom(
																																e.target.value
																															)
																														}
																													/>
																												</li>
																												<li
																													className={`${
																														checkisEditableField()
																															? "disabled"
																															: ""
																													}`}
																												>
																													<Form.Control
																														type="text"
																														placeholder="L"
																														value={
																															image_margin_left
																														}
																														disabled={checkisEditableField()}
																														onChange={(e) =>
																															setImageMarginLeft(
																																e.target.value
																															)
																														}
																													/>
																												</li>
																												<li
																													className={`${
																														checkisEditableField()
																															? "disabled"
																															: ""
																													}`}
																												>
																													<Form.Control
																														type="text"
																														placeholder="R"
																														value={
																															image_margin_right
																														}
																														disabled={checkisEditableField()}
																														onChange={(e) =>
																															setImageMarginRight(
																																e.target.value
																															)
																														}
																													/>
																												</li>
																											</ul>
																										</div>
																									</div>
																								</Col>
																								<Col sm={6}></Col>
																							</Row>
																						</div>
																					</Form.Group>
																				</>
																			)}

																		<Col sm={6}></Col>
																	</div>
																)}
																{group_name_select === "Customized Settings" &&
																	edit_select === null && (
																		// view_edit_group !== null &&
																		<div
																			className="sidebar-select-editType--modal  editSettings"
																			id="et_head"
																		>
																			{setting_profile &&
																				!setting_profile.edit_group_id &&
																				setting_profile.extra_edit_types !==
																					null &&
																				setting_profile.resizing_settings && (
																					<>
																						<div className="form-sub-head">
																							Edit Type
																						</div>
																					</>
																				)}
																			{/* /***need to check**/}
																			{setting_profile &&
																				!setting_profile.edit_group_id &&
																				setting_profile.extra_edit_types !==
																					null &&
																				// view_edit_group.edit_group === null &&
																				setting_profile &&
																				setting_profile.resizing_settings &&
																				editTypes &&
																				editTypes.map((item) => {
																					return (
																						<>
																							<Form.Group>
																								{" "}
																								<Row
																									className="form-group  flex-grow-1"
																									sm={12}
																								>
																									<Col sm={1}>
																										<input
																											type="checkbox"
																											value={item.id}
																											onClick={(e) => {
																												if (
																													customizedSettings
																												) {
																													handleBackGroundRemovalCheckbox(
																														item.id,
																														e.target.checked,
																														"Edit",
																														customizedSettings
																													);
																													handleAddCheckboxSelect(
																														e
																													);
																												}
																											}}
																											checked={
																												item.id === 1
																													? checked
																													: item.id === 2
																													? checked1
																													: item.id === 3
																													? checked2
																													: checked3
																											}
																											id={`et_${item.id}`}
																											className="et_options"
																											disabled={checkisEditableField()}
																										/>
																									</Col>
																									<Form.Label
																										className="col-sm-6 form-label form-check-label"
																										style={{
																											width: "auto",
																											marginTop: "1.4px",
																											cursor: "pointer",
																										}}
																										for={`et_${item.id}`}
																									>
																										{item.edit_type}
																									</Form.Label>
																								</Row>
																								{item.id === 1 && checked ? (
																									<Row
																										className="align-items-center"
																										style={{
																											// marginLeft: "44px",
																											marginTop: "-2px",
																											marginBottom: "20px",
																											width: "100%",
																										}}
																									>
																										<Col sm={6}>
																											<Select
																												options={
																													selectBackgrondRemoval
																												}
																												onChange={(e) =>
																													setBackgroundRemovalColorType(
																														e.label
																													)
																												}
																												defaultValue={{
																													label: `${background_removal_color_type}`,
																													id: "21",
																												}}
																												value={{
																													label:
																														background_removal_color_type, // Set the label property to the desired value
																													value:
																														background_removal_color_type, // Set the value property to the corresponding value
																												}}
																												isDisabled={checkisEditableField()}
																												styles={
																													customSelectStyle
																												}
																											/>
																										</Col>
																										<Col sm={6}>
																											<Form.Control
																												type="text"
																												placeholder="#ffff"
																												autoFocus
																												onChange={(e) =>
																													setBackgroundRemovalColorCode(
																														e.target.value
																													)
																												}
																												disabled={checkisEditableField()}
																												value={
																													background_removal_color_code
																												}
																											/>
																										</Col>
																									</Row>
																								) : (
																									""
																								)}
																								{item.id === 2 && checked1 ? (
																									<Row
																										className="align-items-center"
																										style={{
																											// marginLeft: "44px",
																											marginTop: "-2px",
																											marginBottom: "20px",
																											width: "100%",
																										}}
																									>
																										<Col sm={6}>
																											<Select
																												options={
																													selectBackgrondRemoval
																												}
																												onChange={(e) =>
																													setBackgroundAdjustmentColorType(
																														e.label
																													)
																												}
																												// defaultValue={{
																												// 	label: `${background_adjustment_color_type}`,
																												// 	id: "21",
																												// }}
																												value={{
																													label:
																														background_adjustment_color_type, // Set the label property to the desired value
																													value:
																														background_adjustment_color_type, // Set the value property to the corresponding value
																												}}
																												isDisabled={checkisEditableField()}
																												styles={
																													customSelectStyle
																												}
																											/>
																										</Col>
																										<Col sm={6}>
																											<Form.Control
																												type="text"
																												placeholder="#ffff"
																												autoFocus
																												disabled={checkisEditableField()}
																												onChange={(e) =>
																													setBackgroundAdjustmentColorCode(
																														e.target.value
																													)
																												}
																												value={
																													background_adjustment_color_code
																												}
																											/>
																										</Col>
																									</Row>
																								) : (
																									""
																								)}
																								{item.id === 3 && checked2 ? (
																									<Row
																										className="align-items-center"
																										style={{
																											// marginLeft: "40px",
																											marginTop: "-2px",
																											marginBottom: "20px",
																											width: "100%",
																										}}
																									>
																										<Form.Group
																											style={{
																												margin: "2px",
																											}}
																											controlId="exampleForm.ControlTextarea1"
																										>
																											<div className="form-group">
																												<Row className="align-items-center">
																													<Col sm={12}>
																														<div className="d-sm-flex align-items-center col-gap-10">
																															<div className="d-sm-flex col-gap-10 align-items-center">
																																<Form.Control
																																	type="text"
																																	placeholder="Height"
																																	autoFocus
																																	onChange={(
																																		e
																																	) =>
																																		setResizingHeight(
																																			e.target
																																				.value
																																		)
																																	}
																																	value={
																																		resizing_height
																																	}
																																	disabled={checkisEditableField()}
																																/>
																															</div>
																															<div className="d-sm-flex align-items-center">
																																<img
																																	style={{
																																		width:
																																			"18px",
																																	}}
																																	src={cross}
																																	alt="mindreach images"
																																/>
																															</div>
																															<div className="d-sm-flex align-items-center col-gap-10">
																																<Form.Control
																																	type="text"
																																	placeholder="Width"
																																	autoFocus
																																	onChange={(
																																		e
																																	) =>
																																		setResizingWidth(
																																			e.target
																																				.value
																																		)
																																	}
																																	value={
																																		resizing_width
																																	}
																																	disabled={checkisEditableField()}
																																/>
																															</div>
																														</div>
																													</Col>
																												</Row>
																											</div>
																										</Form.Group>
																									</Row>
																								) : (
																									""
																								)}
																							</Form.Group>
																						</>
																					);
																				})}
																			{/* Form groups for output specifications */}

																			{setting_profile &&
																				!setting_profile.edit_group_id &&
																				setting_profile.extra_edit_types !==
																					null && (
																					// view_edit_group.edit_group !== null &&
																					// view_edit_group.edit_group !== undefined &&
																					// view_edit_group.edit_group.setting !== null &&
																					// view_edit_group.edit_group.setting !== undefined && (
																					<>
																						<div className="form-sub-head">
																							Output Specifications
																						</div>
																						<Form.Group className="mg-bottom15">
																							<Row
																								className="form-group  flex-grow-1"
																								sm={12}
																							>
																								<Form.Label className="col-sm-12 form-label form-check-label side-form-label">
																									File Format
																								</Form.Label>
																							</Row>
																						</Form.Group>
																						<Form.Group
																							style={{ margin: "2px" }}
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Col sm={12} className="pl-0">
																										<Select
																											options={file}
																											onChange={(e) =>
																												setImageFileFormat(
																													e.label
																												)
																											}
																											value={{
																												label:
																													image_file_format,
																												value:
																													image_file_format, // Set the value property to the corresponding value
																											}}
																											isDisabled={checkisEditableField()}
																										/>
																									</Col>
																								</Row>
																							</div>
																						</Form.Group>
																						<Form.Group className="mg-bottom15">
																							<Row
																								className="form-group  flex-grow-1"
																								sm={12}
																							>
																								<Form.Label className="col-sm-12form-label form-check-label side-form-label">
																									Size
																								</Form.Label>
																							</Row>
																						</Form.Group>
																						<Form.Group
																							style={{ margin: "2px" }}
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Col sm={12}>
																										<div className="d-sm-flex align-items-center col-gap-12">
																											<div className="d-sm-flex col-gap-12 align-items-center">
																												<Form.Control
																													type="text"
																													placeholder="Height"
																													onChange={(e) =>
																														setImageHeight(
																															e.target.value
																														)
																													}
																													disabled={checkisEditableField()}
																													value={image_height}
																												/>
																											</div>
																											<div className="d-sm-flex align-items-center">
																												<img
																													style={{
																														width: "18px",
																													}}
																													src={cross}
																													alt="mindreach images"
																												/>
																											</div>
																											<div className="d-sm-flex align-items-center col-gap-10">
																												<Form.Control
																													type="text"
																													placeholder="Width"
																													onChange={(e) =>
																														setImageWidth(
																															e.target.value
																														)
																													}
																													disabled={checkisEditableField()}
																													value={image_width}
																												/>
																											</div>
																										</div>
																									</Col>
																								</Row>
																							</div>
																						</Form.Group>
																						<Form.Group className="mg-bottom15">
																							<Row
																								className="form-group  flex-grow-1"
																								sm={12}
																							>
																								<Form.Label className="col-sm-4 form-label form-check-label side-form-label">
																									DPI
																								</Form.Label>
																							</Row>
																						</Form.Group>
																						<Form.Group>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Col sm={12}>
																										<div className="d-sm-flex col-gap-20">
																											<Form.Control
																												type="text"
																												placeholder="2000"
																												autoComplete="off"
																												className="w-50"
																												value={image_dpi}
																												disabled={checkisEditableField()}
																												onChange={(e) =>
																													setImageDpi(
																														e.target.value
																													)
																												}
																											/>
																											<div className="flex-fill">
																												<Select
																													options={dpi}
																													onChange={(e) =>
																														setImageDpiType(
																															e.label
																														)
																													}
																													value={{
																														label:
																															image_dpi_type,
																														value:
																															image_dpi_type,
																													}}
																													isDisabled={checkisEditableField()}
																												/>
																											</div>
																										</div>
																									</Col>
																								</Row>
																							</div>
																						</Form.Group>
																						<Form.Group className="mg-bottom15">
																							<Row
																								className="form-group  flex-grow-1"
																								sm={2}
																							>
																								<Form.Label className="col-sm-12 form-label form-check-label side-form-label">
																									Color Mode
																								</Form.Label>
																							</Row>
																						</Form.Group>
																						<Form.Group
																							style={{ margin: "2px" }}
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Col sm={12}>
																										<Select
																											options={ColorMode}
																											onChange={(e) =>
																												setImageColorMode(
																													e.label
																												)
																											}
																											value={{
																												label: image_color_mode,
																												value: image_color_mode,
																											}}
																											isDisabled={checkisEditableField()}
																										/>
																									</Col>
																								</Row>
																							</div>
																						</Form.Group>
																						<Form.Group className="mg-bottom15">
																							<Row
																								className="form-group  flex-grow-1"
																								sm={12}
																							>
																								<Form.Label className="col-sm-12 form-label form-check-label side-form-label">
																									Align
																								</Form.Label>
																							</Row>
																						</Form.Group>
																						<Form.Group
																							style={{ margin: "2px" }}
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Col sm={12}>
																										<Select
																											options={Align}
																											onChange={(e) =>
																												setImageAlignment(
																													e.label
																												)
																											}
																											value={{
																												label: image_alignment, // Set the label property to the desired value
																												value: image_alignment, // Set the value property to the corresponding value
																											}}
																											isDisabled={checkisEditableField()}
																										/>
																									</Col>
																								</Row>
																							</div>
																						</Form.Group>
																						<Form.Group className="mg-bottom15">
																							<Row
																								className="form-group  flex-grow-1"
																								sm={9}
																							>
																								<Col sm={1}></Col>
																								<Form.Label className="col-sm-12 form-label form-check-label side-form-label">
																									Margins
																								</Form.Label>
																							</Row>
																						</Form.Group>
																						<Form.Group
																							style={{ margin: "2px" }}
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Col sm={12}>
																										<Select
																											options={Margin}
																											onChange={(e) =>
																												setImageMarginFormat(
																													e.label
																												)
																											}
																											value={{
																												label:
																													image_margin_format, // Set the label property to the desired value
																												value:
																													image_margin_format, // Set the value property to the corresponding value
																											}}
																											isDisabled={checkisEditableField()}
																										/>
																									</Col>
																									<Col
																										sm={7}
																										lg={7}
																										style={{
																											display: "flex",
																											marginTop: "12px",
																										}}
																									>
																										<div className="d-sm-flex col-gap-20 mt-2 ">
																											<div className="d-flex">
																												<ul className="margin-list d-flex">
																													<li
																														className={`${
																															checkisEditableField()
																																? "disabled"
																																: ""
																														}`}
																													>
																														<Form.Control
																															type="text"
																															placeholder="T"
																															value={
																																image_margin_top
																															}
																															disabled={checkisEditableField()}
																															onChange={(e) =>
																																setImageMarginTop(
																																	e.target.value
																																)
																															}
																														/>
																													</li>
																													<li
																														className={`${
																															checkisEditableField()
																																? "disabled"
																																: ""
																														}`}
																													>
																														<Form.Control
																															type="text"
																															placeholder="B"
																															value={
																																image_margin_bottom
																															}
																															disabled={checkisEditableField()}
																															onChange={(e) =>
																																setImageMarginBottom(
																																	e.target.value
																																)
																															}
																														/>
																													</li>
																													<li
																														className={`${
																															checkisEditableField()
																																? "disabled"
																																: ""
																														}`}
																													>
																														<Form.Control
																															type="text"
																															placeholder="L"
																															value={
																																image_margin_left
																															}
																															disabled={checkisEditableField()}
																															onChange={(e) =>
																																setImageMarginLeft(
																																	e.target.value
																																)
																															}
																														/>
																													</li>
																													<li
																														className={`${
																															checkisEditableField()
																																? "disabled"
																																: ""
																														}`}
																													>
																														<Form.Control
																															type="text"
																															placeholder="R"
																															value={
																																image_margin_right
																															}
																															disabled={checkisEditableField()}
																															onChange={(e) =>
																																setImageMarginRight(
																																	e.target.value
																																)
																															}
																														/>
																													</li>
																												</ul>
																											</div>
																										</div>
																									</Col>
																									<Col sm={6}></Col>
																								</Row>
																							</div>
																						</Form.Group>
																					</>
																				)}
																			{view_edit_group !== undefined &&
																				view_edit_group.edit_group === null &&
																				setting_profile &&
																				setting_profile.setting && (
																					<>
																						<div className="sub-head">
																							Output Specifications
																						</div>
																						<Form.Group className="mg-bottom15">
																							<Row
																								className="form-group  flex-grow-1"
																								sm={9}
																							>
																								<Col sm={1}></Col>
																								<Form.Label className="col-sm-4 form-label form-check-label">
																									File Format
																								</Form.Label>
																							</Row>
																						</Form.Group>
																						<Form.Group
																							style={{ margin: "2px" }}
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Form.Label className="col-sm-1"></Form.Label>
																									<Col sm={6} className="pl-2">
																										<Select
																											options={file}
																											onChange={(e) =>
																												setImageFileFormat(
																													e.label
																												)
																											}
																											value={{
																												label:
																													image_file_format, // Set the label property to the desired value
																												value:
																													image_file_format, // Set the value property to the corresponding value
																											}}
																											isDisabled={checkisEditableField()}
																										/>
																									</Col>
																								</Row>
																							</div>
																						</Form.Group>
																						<Form.Group className="mg-bottom15">
																							<Row
																								className="form-group  flex-grow-1"
																								sm={9}
																							>
																								<Col sm={1}></Col>
																								<Form.Label className="col-sm-4 form-label form-check-label">
																									Size
																								</Form.Label>
																							</Row>
																						</Form.Group>
																						<Form.Group
																							style={{ margin: "2px" }}
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Form.Label className="col-sm-1"></Form.Label>
																									<Col sm={9}>
																										<div className="d-sm-flex align-items-center col-gap-10">
																											<div className="d-sm-flex col-gap-10 align-items-center">
																												<Form.Control
																													type="text"
																													placeholder="Height"
																													disabled={checkisEditableField()}
																													value={image_height}
																													onChange={(e) =>
																														setImageHeight(
																															e.target.value
																														)
																													}
																												/>
																											</div>
																											<div className="d-sm-flex align-items-center">
																												<img
																													style={{
																														width: "18px",
																													}}
																													src={cross}
																													alt="mindreach images"
																												/>
																											</div>
																											<div className="d-sm-flex align-items-center col-gap-10">
																												<Form.Control
																													type="text"
																													placeholder="Width"
																													disabled={checkisEditableField()}
																													value={image_width}
																													onChange={(e) =>
																														setImageWidth(
																															e.target.value
																														)
																													}
																												/>
																											</div>
																										</div>
																									</Col>
																								</Row>
																							</div>
																						</Form.Group>
																						<Form.Group className="mg-bottom15">
																							<Row
																								className="form-group  flex-grow-1"
																								sm={9}
																							>
																								<Col sm={1}></Col>
																								<Form.Label className="col-sm-4 form-label form-check-label">
																									DPI
																								</Form.Label>
																							</Row>
																						</Form.Group>
																						<Form.Group
																							style={{ margin: "2px" }}
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Form.Label className="col-sm-1"></Form.Label>
																									<Col sm={10}>
																										<div className="d-sm-flex col-gap-20">
																											<Form.Control
																												type="text"
																												placeholder="2000"
																												autoComplete="off"
																												className="w-50"
																												value={image_dpi}
																												disabled={checkisEditableField()}
																												onChange={(e) =>
																													setImageDpi(
																														e.target.value
																													)
																												}
																											/>
																											<div className="flex-fill">
																												<Select
																													options={dpi}
																													onChange={(e) =>
																														setImageDpiType(
																															e.label
																														)
																													}
																													value={{
																														label:
																															image_dpi_type, // Set the label property to the desired value
																														value:
																															image_dpi_type, // Set the value property to the corresponding value
																													}}
																													isDisabled={checkisEditableField()}
																												/>
																											</div>
																										</div>
																									</Col>
																								</Row>
																							</div>
																						</Form.Group>
																						<Form.Group className="mg-bottom15">
																							<Row
																								className="form-group  flex-grow-1"
																								sm={9}
																							>
																								<Col sm={1}></Col>
																								<Form.Label className="col-sm-4 form-label form-check-label">
																									Color Mode
																								</Form.Label>
																							</Row>
																						</Form.Group>
																						<Form.Group
																							style={{ margin: "2px" }}
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Form.Label className="col-sm-1"></Form.Label>
																									<Col sm={9}>
																										<Select
																											options={ColorMode}
																											onChange={(e) =>
																												setImageColorMode(
																													e.label
																												)
																											}
																											value={{
																												label: image_color_mode, // Set the label property to the desired value
																												value: image_color_mode, // Set the value property to the corresponding value
																											}}
																											isDisabled={checkisEditableField()}
																										/>
																									</Col>
																								</Row>
																							</div>
																						</Form.Group>
																						<Form.Group className="mg-bottom15">
																							<Row
																								className="form-group  flex-grow-1"
																								sm={9}
																							>
																								<Col sm={1}></Col>
																								<Form.Label className="col-sm-4 form-label form-check-label">
																									Align
																								</Form.Label>
																							</Row>
																						</Form.Group>
																						<Form.Group
																							style={{ margin: "2px" }}
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Form.Label className="col-sm-1"></Form.Label>
																									<Col sm={9}>
																										<Select
																											options={Align}
																											onChange={(e) =>
																												setImageAlignment(
																													e.label
																												)
																											}
																											value={{
																												label: image_alignment, // Set the label property to the desired value
																												value: image_alignment, // Set the value property to the corresponding value
																											}}
																											isDisabled={checkisEditableField()}
																										/>
																									</Col>
																								</Row>
																							</div>
																						</Form.Group>
																						<Form.Group className="mg-bottom15">
																							<Row
																								className="form-group  flex-grow-1"
																								sm={9}
																							>
																								<Col sm={1}></Col>
																								<Form.Label className="col-sm-4 form-label form-check-label">
																									Margins
																								</Form.Label>
																							</Row>
																						</Form.Group>
																						<Form.Group
																							style={{ margin: "2px" }}
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Form.Label className="col-sm-1"></Form.Label>
																									<Col sm={9}>
																										<Select
																											options={Margin}
																											onChange={(e) =>
																												setImageMarginFormat(
																													e.label
																												)
																											}
																											value={{
																												label:
																													image_margin_format, // Set the label property to the desired value
																												value:
																													image_margin_format, // Set the value property to the corresponding value
																											}}
																											isDisabled={checkisEditableField()}
																										/>
																									</Col>
																									<Col
																										sm={7}
																										lg={7}
																										style={{
																											display: "flex",
																											marginLeft: "2rem",
																											marginTop: "12px",
																										}}
																									>
																										<div className="d-sm-flex col-gap-20 mt-2 ">
																											<div className="d-flex">
																												<ul className="margin-list d-flex">
																													<li
																														className={`${
																															checkisEditableField()
																																? "disabled"
																																: ""
																														}`}
																													>
																														<Form.Control
																															type="text"
																															placeholder="T"
																															value={
																																image_margin_top
																															}
																															disabled={checkisEditableField()}
																															onChange={(e) =>
																																setImageMarginTop(
																																	e.target.value
																																)
																															}
																														/>
																													</li>
																													<li
																														className={`${
																															checkisEditableField()
																																? "disabled"
																																: ""
																														}`}
																													>
																														<Form.Control
																															type="text"
																															placeholder="B"
																															value={
																																image_margin_bottom
																															}
																															disabled={checkisEditableField()}
																															onChange={(e) =>
																																setImageMarginBottom(
																																	e.target.value
																																)
																															}
																														/>
																													</li>
																													<li
																														className={`${
																															checkisEditableField()
																																? "disabled"
																																: ""
																														}`}
																													>
																														<Form.Control
																															type="text"
																															placeholder="L"
																															value={
																																image_margin_left
																															}
																															disabled={checkisEditableField()}
																															onChange={(e) =>
																																setImageMarginLeft(
																																	e.target.value
																																)
																															}
																														/>
																													</li>
																													<li
																														className={`${
																															checkisEditableField()
																																? "disabled"
																																: ""
																														}`}
																													>
																														<Form.Control
																															type="text"
																															placeholder="R"
																															value={
																																image_margin_right
																															}
																															disabled={checkisEditableField()}
																															onChange={(e) =>
																																setImageMarginRight(
																																	e.target.value
																																)
																															}
																														/>
																													</li>
																												</ul>
																											</div>
																										</div>
																									</Col>
																									<Col sm={6}></Col>
																								</Row>
																							</div>
																						</Form.Group>
																					</>
																				)}

																			<Col sm={6}></Col>
																		</div>
																	)}
															</div>
														) : (
															<div className="side-bar-duplicate-settings--view">
																{viewDuplicateSettingsById !== undefined && (
																	<>
																		<div className="sub-head mt-2" id="et_head">
																			Additional Editing
																		</div>
																		{editTypes !== undefined &&
																			editTypes.map((item) => {
																				return (
																					<>
																						<Form.Group>
																							{" "}
																							<Row
																								className="form-group  flex-grow-1"
																								sm={9}
																							>
																								<Col sm={1}>
																									<input
																										type="checkbox"
																										value={item.id}
																										onChange={(e) => {
																											if (customizedSettings) {
																												handleBackGroundRemovalCheckboxDuplicate(
																													item.id,
																													e.target.checked,
																													"Edit"
																												);
																												handleAddCheckboxSelect(
																													e
																												);
																											}
																										}}
																										checked={
																											item.id === 1 &&
																											selectedDuplicateSettings
																												? true
																												: item.id === 2 &&
																												  selectedDuplicateSettings1
																												? true
																												: item.id === 3 &&
																												  selectedDuplicateSettings2
																												? true
																												: item.id === 15 ||
																												  (item.id === 6 &&
																														selectedDuplicateSettings3)
																												? true
																												: false
																										}
																										id={`et_${item.id}`}
																										className="et_options"
																										disabled={checkisEditableField()}
																									/>
																								</Col>
																								<Form.Label
																									className="col-sm-4 form-label form-check-label"
																									style={{
																										width: "auto",
																										marginTop: "1.4px",
																										cursor: "pointer",
																									}}
																									for={`et_${item.id}`}
																								>
																									{item.edit_type}
																								</Form.Label>
																							</Row>
																							{item.id === 1 &&
																							selectedDuplicateSettings ? (
																								<Row
																									className="align-items-center"
																									style={{
																										// marginLeft: "44px",
																										marginTop: "-2px",
																										marginBottom: "20px",
																										width: "100%",
																									}}
																								>
																									<Form.Label className="col-sm-1"></Form.Label>
																									<Col sm={5}>
																										<Select
																											options={
																												selectBackgrondRemoval
																											}
																											onChange={(e) =>
																												setBackgroundRemovalColorTypeDuplicate(
																													e.label
																												)
																											}
																											defaultValue={{
																												label: `${background_removal_color_type_duplicate}`,
																												value: "21",
																											}}
																											value={{
																												label:
																													background_removal_color_type_duplicate, // Set the label property to the desired value
																												value:
																													background_removal_color_type_duplicate !==
																													null
																														? background_removal_color_type_duplicate
																														: "", // Set the value property to the corresponding value
																											}}
																											isDisabled={checkisEditableField()}
																											styles={customSelectStyle}
																										/>
																									</Col>
																									<Col sm={4}>
																										<Form.Control
																											type="text"
																											placeholder="#ffff"
																											autoFocus
																											onChange={(e) =>
																												setBackgroundRemovalColorCodeDuplicate(
																													e.target.value
																												)
																											}
																											disabled={checkisEditableField()}
																											value={
																												background_removal_color_code_duplicate
																											}
																										/>
																									</Col>
																								</Row>
																							) : (
																								""
																							)}
																							{item.id === 2 &&
																							selectedDuplicateSettings1 ? (
																								<Row
																									className="align-items-center"
																									style={{
																										// marginLeft: "44px",
																										marginTop: "-2px",
																										marginBottom: "20px",
																										width: "100%",
																									}}
																								>
																									<Form.Label className="col-sm-1"></Form.Label>
																									<Col sm={5}>
																										<Select
																											options={
																												selectBackgrondRemoval
																											}
																											onChange={(e) =>
																												setBackgroundAdjustmentColorTypeDuplicate(
																													e.label
																												)
																											}
																											// defaultValue={{
																											// 	label: `${background_adjustment_color_type}`,
																											// 	id: "21",
																											// }}
																											value={{
																												label:
																													background_adjustment_color_type_duplicate, // Set the label property to the desired value
																												value:
																													background_adjustment_color_type_duplicate, // Set the value property to the corresponding value
																											}}
																											isDisabled={checkisEditableField()}
																											styles={customSelectStyle}
																										/>
																									</Col>
																									<Col sm={4}>
																										<Form.Control
																											type="text"
																											placeholder="#ffff"
																											autoFocus
																											disabled={checkisEditableField()}
																											onChange={(e) =>
																												setBackgroundAdjustmentColorCodeDuplicate(
																													e.target.value
																												)
																											}
																											value={
																												background_adjustment_color_code_duplicate
																											}
																										/>
																									</Col>
																								</Row>
																							) : (
																								""
																							)}
																							{item.id === 3 &&
																							selectedDuplicateSettings2 ? (
																								<Row
																									className="align-items-center"
																									style={{
																										// marginLeft: "40px",
																										marginTop: "-2px",
																										marginBottom: "20px",
																										width: "100%",
																									}}
																								>
																									<Form.Group
																										style={{ margin: "2px" }}
																										controlId="exampleForm.ControlTextarea1"
																									>
																										<div className="form-group">
																											<Row className="align-items-center">
																												<Form.Label className="col-sm-1"></Form.Label>
																												<Col sm={9}>
																													<div className="d-sm-flex align-items-center col-gap-10">
																														<div className="d-sm-flex col-gap-10 align-items-center">
																															<Form.Control
																																type="text"
																																placeholder="Height"
																																autoFocus
																																onChange={(e) =>
																																	setResizingHeightDuplicate(
																																		e.target
																																			.value
																																	)
																																}
																																value={
																																	resizing_height_duplicate
																																}
																																disabled={
																																	resizingOriginalSize
																																}
																															/>
																														</div>
																														<div className="d-sm-flex align-items-center">
																															<img
																																style={{
																																	width: "18px",
																																}}
																																src={cross}
																																alt="mindreach images"
																															/>
																														</div>
																														<div className="d-sm-flex align-items-center col-gap-10">
																															<Form.Control
																																type="text"
																																placeholder="Width"
																																autoFocus
																																onChange={(e) =>
																																	setResizingWidthDuplicate(
																																		e.target
																																			.value
																																	)
																																}
																																value={
																																	resizing_width_duplicate
																																}
																																disabled={
																																	resizingOriginalSize
																																}
																															/>
																														</div>
																													</div>
																												</Col>
																											</Row>
																										</div>
																									</Form.Group>
																									<Row
																										className="align-items-center"
																										style={{
																											marginLeft: "auto",
																											marginRight: "auto",
																											marginTop: "0",
																											marginBottom: "20px",
																											width: " 94%",
																										}}
																									>
																										<Form.Group
																											style={{
																												margin: "2px",
																											}}
																										>
																											<div className="form-group">
																												<Row className="align-items-center">
																													<Col
																														sm={12}
																														className="ml-1"
																													>
																														<div className="d-sm-flex align-items-center col-gap-10">
																															<div className="d-sm-flex col-gap-10 align-items-center">
																																<input
																																	type="checkbox"
																																	onChange={() => {
																																		setResizingOriginalSize(
																																			(val) =>
																																				!val
																																		);
																																		// customizedSettingsEnabled(
																																		// 	(val) => !val
																																		// );
																																	}}
																																	// onChange={(e) => {
																																	// 	// if(e.target.checked){
																																	// 	// 	data.height = resizing_height
																																	// 	// 	data.width = resizing_width
																																	// 	// } else{
																																	// 	// 	data.height = ""
																																	// 	// 	data.width = ""
																																	// 	// }
																																	// 	handleKeepOriginalImageResizing(
																																	// 		e,
																																	// 		item.id,
																																	// 		index
																																	// 	);
																																	// 	validateCopyForm();
																																	// }}
																																	// id={`resizing_${index}_${i}`}
																																	checked={
																																		resizingOriginalSize
																																	}
																																/>
																															</div>

																															<div className="d-sm-flex align-items-center">
																																<Form.Label
																																	className="col-sm-12 form-label form-check-label"
																																	// for={`resizing_${index}_${i}`}
																																	style={{
																																		cursor:
																																			"pointer",
																																		marginTop:
																																			"1px",
																																		paddingLeft:
																																			"6px",
																																	}}
																																>
																																	Same as
																																	original image
																																</Form.Label>
																															</div>
																														</div>
																													</Col>
																												</Row>
																											</div>
																										</Form.Group>
																									</Row>
																								</Row>
																							) : (
																								""
																							)}
																						</Form.Group>
																					</>
																				);
																			})}
																		{/* Form groups for output specifications */}
																		<div className="sub-head">
																			Output Specifications
																		</div>
																		<Form.Group
																			style={{ margin: "2px" }}
																			className="pt-2"
																		>
																			<div className="form-group">
																				<Row className="align-items-center">
																					<Col sm={12} className="ml-1">
																						<div className="d-sm-flex align-items-center col-gap-10">
																							<div className="d-sm-flex col-gap-10 align-items-center">
																								<input
																									type="checkbox"
																									onChange={() =>
																										setOriginalSize(
																											(val) => !val
																										)
																									}
																									className="output_spec_fields"
																									checked={originalSize}
																								/>
																							</div>

																							<div className="d-sm-flex align-items-center">
																								<Form.Label
																									className="col-sm-12 form-label form-check-label"
																									// for={`output_spec_fields_${index}`}
																									style={{
																										cursor: "pointer",
																										marginTop: "1px",
																										paddingLeft: "6px",
																									}}
																								>
																									Same as original image
																								</Form.Label>
																							</div>
																						</div>
																					</Col>
																				</Row>
																			</div>
																		</Form.Group>
																		<Form.Group className="mg-bottom15 pt-1">
																			<Row
																				className="form-group  flex-grow-1 padding-left-3"
																				sm={9}
																			>
																				<Form.Label
																					className={`col-sm-4 form-label form-check-label ${
																						isFormSubmitted
																							? "duplicate-compulsory-field-error"
																							: "duplicate-compulsory-field"
																					}`}
																				>
																					File Format <span>*</span>
																				</Form.Label>
																			</Row>
																		</Form.Group>
																		<Form.Group style={{ margin: "2px" }}>
																			<div className="form-group">
																				<Row className="align-items-center">
																					<Col sm={6}>
																						<Select
																							options={file}
																							placeholder="Select"
																							onChange={(e) => {
																								const selectedFormat = e.label; // Assuming e.label holds the selected file format

																								setFileFormatDuplicateSize(
																									selectedFormat
																								);
																							}}
																							value={{
																								label:
																									file_format_duplicate_size,
																								value:
																									file_format_duplicate_size,
																							}}
																							isDisabled={originalSize}
																							styles={{
																								control: (provided) => ({
																									...provided,
																									height: 52,
																									border: `${
																										isFormSubmitted
																											? "1px solid #a92322"
																											: "1px solid #e9e9e9"
																									}`,
																									boxShadow: "none",
																									"&:hover": {
																										border: `${
																											isFormSubmitted
																												? "1px solid #a92322"
																												: "1px solid #e9e9e9"
																										}`,
																									},
																								}),
																							}}
																						/>
																					</Col>
																				</Row>
																			</div>
																		</Form.Group>

																		<Form.Group className="mg-bottom15">
																			<Row
																				className="form-group  flex-grow-1 padding-left-3"
																				sm={9}
																			>
																				<Form.Label className="col-sm-4 form-label form-check-label">
																					Margins
																				</Form.Label>
																			</Row>
																		</Form.Group>

																		<Form.Group style={{ margin: "2px" }}>
																			<div className="form-group">
																				<Row className="align-items-center">
																					<Col
																						sm={6}
																						style={{ paddingLeft: "13px" }}
																					>
																						<Select
																							options={Margin}
																							placeholder="Select"
																							onChange={(e) => {
																								const selectedFormat = e.label; // Assuming e.label holds the selected file format
																								setMarginFormatDuplicateSize(
																									selectedFormat
																								);
																							}}
																							isDisabled={originalSize}
																							value={{
																								label: margin_format_duplicate,
																								value: margin_format_duplicate,
																							}}
																						/>
																					</Col>
																					<Col
																						sm={7}
																						lg={7}
																						style={{
																							display: "flex",

																							marginTop: "12px",
																						}}
																					>
																						<div className="d-sm-flex col-gap-20 mt-2">
																							<div className="d-flex">
																								<ul
																									className={`margin-list d-flex `}
																								>
																									<li>
																										<Form.Control
																											type="text"
																											autoFocus
																											onChange={(e) => {
																												const selectedFormat =
																													e.target.value;
																												setMarginTopDuplicateSize(
																													selectedFormat
																												);
																											}}
																											autoComplete="off"
																											className={`text-center ${
																												originalSize
																													? "placeholder-dark"
																													: "placeholder-light"
																											}`}
																											disabled={originalSize}
																											value={
																												margin_top_duplicateSize
																											}
																										/>
																									</li>
																									<li>
																										<Form.Control
																											type="text"
																											autoFocus
																											onChange={(e) => {
																												const selectedFormat =
																													e.target.value;
																												setMarginBottomDuplicateSize(
																													selectedFormat
																												);
																											}}
																											autoComplete="off"
																											className={`text-center ${
																												originalSize
																													? "placeholder-dark"
																													: "placeholder-light"
																											}`}
																											value={
																												margin_bottom_duplicateSize
																											}
																											disabled={originalSize}
																										/>
																									</li>
																									<li>
																										<Form.Control
																											type="text"
																											autoFocus
																											onChange={(e) => {
																												const selectedFormat =
																													e.target.value;
																												setMarginRightDuplicateSize(
																													selectedFormat
																												);
																											}}
																											autoComplete="off"
																											className={`text-center ${
																												originalSize
																													? "placeholder-dark"
																													: "placeholder-light"
																											}`}
																											value={
																												margin_right_duplicateSize
																											}
																											disabled={originalSize}
																										/>
																									</li>
																									<li>
																										<Form.Control
																											type="text"
																											autoFocus
																											onChange={(e) => {
																												const selectedFormat =
																													e.target.value;
																												setMarginLeftDuplicateSize(
																													selectedFormat
																												);
																											}}
																											autoComplete="off"
																											className={`text-center ${
																												originalSize
																													? "placeholder-dark"
																													: "placeholder-light"
																											}`}
																											value={
																												margin_left_duplicateSize
																											}
																											disabled={originalSize}
																										/>
																									</li>
																								</ul>
																							</div>
																						</div>
																					</Col>
																					<Col sm={6}></Col>
																				</Row>
																			</div>
																		</Form.Group>
																	</>
																)}
															</div>
														)}
													</Col>
													{/* <hr style={{ width: "100%", margin: "15px 0" }} /> */}
												</Row>
											</>
										)}
										{drawingMode === "square" && !commentsMarker.length && (
											<>
												<Row className="align-items-center edit-setting-sidebar">
													<div
														className="sidebar__header sidebar__flex"
														style={{ padding: "15px 25px 12px 15px" }}
													>
														<div style={{ fontSize: "20px" }}>Comments</div>
													</div>
												</Row>
												<Col
													sm={12}
													className="sidebar-settings-image--modal sidebar-settings-image--comment-modal"
												>
													<div className="">
														<div
															className="commentcard__profile"
															style={{ gap: "6px" }}
														>
															<div>
																<img src={NoComment} alt="no comment icon" />
															</div>
															<div className="commentcard__name">
																No Comments Marked
															</div>
														</div>

														<div className="commentcard__btn-area"></div>
													</div>
												</Col>
											</>
										)}
									</div>
								) : (
									<div className="nim__content">
										<h3>File Settings</h3>
										<p>
											Image has been set for Pipeline to set the editing
											requirements.
										</p>
									</div>
								)}
							</div>
						</div>

						{/* <div
							className="modal-container--image"
							onClick={handleOverlayClick}
						>
						</div> */}
						{/* <RenameModal
							show={renameModalShow}
							onHide={() => {
								setRenameModalShow(false);
							}}
							title="Rename"
							text={renameTextDuplicate}
							setRenameTextDuplicate={setRenameTextDuplicate}
							imageName={imageName}
							setImageName={setImageName}
							imageExtensions={imageExtensions}
							handleRename={handleRenameImage}
						/>
						<DeleteModalConfirmation
							show={showModalDelete}
							onHide={() => {
								setShowModalDelete(false);
							}}
							branch={"image"}
							name={imageName}
							title={"Delete Image"}
							handleConfirm={() => handleDelete()}
						/> */}
					</div>
				)}
			</div>
		);
	}
};

export default Modal;
